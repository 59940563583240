import { Box, HStack, Tab, TabList, Tabs, useTheme } from '@chakra-ui/react';
import { useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import Text from '../../Text';
import { useDevSandbox } from '../DevSandboxProvider';

const PaneTabs = () => {
  const {
    paneTabs: { tabs },
    mode,
  } = useDevSandbox();

  const { opacity } = useTheme();
  const location = useLocation();
  const navigate = useNavigate();

  const currentTabIndex = useMemo(() => {
    return tabs.findIndex((tab) => location.pathname.includes(tab.screenName));
  }, [location.pathname, tabs]);

  useEffect(() => {
    if (!tabs || tabs.length === 0 || mode === 'full-page') {
      return;
    }

    const hasTabInUrl = tabs.some((tab) => location.pathname.includes(tab.screenName));
    const newTabIndex = tabs.findIndex((tab) => location.pathname.includes(tab.screenName));

    if (!hasTabInUrl) {
      navigate(tabs[0].screenName, { replace: true });
    } else if (newTabIndex !== currentTabIndex) {
      navigate(tabs[newTabIndex].screenName, { replace: true });
    }
  }, [location.pathname, currentTabIndex]);

  const handleTabChange = (index) => {
    navigate(tabs[index].screenName);
  };

  return (
    <Box width="100%" height="100%">
      <Tabs
        height="43px"
        variant="soft-rounded"
        borderBottom="1px solid"
        borderColor="whiteAlpha.200"
        width="100%"
        overflowX="auto"
        index={currentTabIndex}
        onChange={handleTabChange}
      >
        <TabList whiteSpace="nowrap">
          {tabs.map((tab) => (
            <Tab
              key={tab.screenName}
              opacity={opacity.secondary}
              _selected={{ opacity: opacity.primary }}
              _hover={{ opacity: opacity.primary }}
            >
              <HStack>
                {tab.icon}
                <Text opacity={opacity.secondary} _hover={{ opacity: opacity.primary }}>
                  {tab.title}
                </Text>
              </HStack>
            </Tab>
          ))}
        </TabList>
      </Tabs>
      <Box height="calc(100% - 45px)" overflow="auto">
        {tabs.map((tab, index) => (
          <Box
            key={tab.screenName}
            height="100%"
            display={currentTabIndex === index ? 'block' : 'none'}
          >
            {tab.content}
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default PaneTabs;
