import { HStack, IconButton, Input } from '@chakra-ui/react';
import { useMutation } from '@tanstack/react-query';
import { supabaseMutationFn, useSupabaseTypedClient } from 'hooks/reactQuery';
import { IUseRenameCodeParams, useRenameCode } from 'hooks/useRenameCode';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { CodeEditorType, EdgeFunctionName, RenameCodeParams } from 'types/edgeFunctions';

import GradientTooltip from './GradientTooltip';
import Icon from './Icon';
import Text, { TextProps } from './Text';

interface IRenameCodeInputProps {
  type: CodeEditorType;
  title: string;
  renameCodeParams: Omit<IUseRenameCodeParams, 'title' | 'type'>;
  textProps?: TextProps | null;
  isEditMode?: boolean | null;
  onEditModeChange?: (isEditMode: boolean) => void;
  onTitleChange?: (title: string) => void;
  onSuccess?: () => void;
  isMySolution: boolean;
  size?: 'xs' | 'sm';
  shortTitle?: boolean;
}

const RenameCodeInput = ({
  type,
  title,
  isEditMode,
  onEditModeChange,
  onTitleChange,
  onSuccess,
  textProps,
  renameCodeParams,
  isMySolution,
  size = 'xs',
  shortTitle,
}: IRenameCodeInputProps) => {
  const initialTitleRef = useRef(title || 'Untitled');
  const [editMode, setEditMode] = useState(isEditMode || false);
  const [value, setValue] = useState(title || 'Untitled');

  const navigate = useNavigate();

  useEffect(() => {
    if (isEditMode) {
      setEditMode(true);
    }
  }, [isEditMode]);

  const supabase = useSupabaseTypedClient();

  const { onMutate, onError, onSettled } = useRenameCode({
    ...renameCodeParams,
    title: value,
    type,
  });

  const mutation = useMutation<string, string, RenameCodeParams>({
    mutationFn: supabaseMutationFn(({ entityId, title, type, parentScreenName }) =>
      supabase.functions.invoke<string>(EdgeFunctionName.RenameCode, {
        body: { entityId, title, type, parentScreenName } as RenameCodeParams,
      })
    ),
    onMutate: async () => await onMutate?.(),
    onSuccess: (solutionUrl: string) => {
      if (!onEditModeChange) {
        onSuccess?.();
        navigate(solutionUrl, { replace: true });
      }
    },
    onError: (err, variables, context) => {
      onError?.(err, variables, context);
      setValue(initialTitleRef.current);
      onTitleChange?.(initialTitleRef.current);
      setEditMode(false);
      onEditModeChange?.(false);
    },
    onSettled: () => onSettled?.(),
  });

  const handleEdit = () => {
    setEditMode(true);
    onEditModeChange?.(true);
  };

  const handleBlur = () => {
    setValue(title);
    onTitleChange?.(title);
    setEditMode(false);
    onEditModeChange?.(false);
  };

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Escape') {
      setValue(title);
      onTitleChange?.(title);
      setEditMode(false);
      onEditModeChange?.(false);

      return;
    }

    if (event.key === 'Enter') {
      if (value.trim() === '' || value.trim() === title) {
        setValue(title);
        onTitleChange?.(title);
        setEditMode(false);
        onEditModeChange?.(false);

        return;
      }

      mutation.mutate({
        entityId: renameCodeParams.entityId,
        title: value,
        type,
        parentScreenName: renameCodeParams.parentScreenName,
      });
      setEditMode(false);
      onEditModeChange?.(false);
      onTitleChange?.(value);
    }
  };

  return (
    <HStack spacing={2}>
      {!editMode ? (
        <>
          <Text
            fontSize="small"
            fontWeight="semibold"
            {...textProps}
            isTruncated
            maxW={[
              shortTitle ? '150px' : '270px',
              shortTitle ? '150px' : '275px',
              shortTitle ? '150px' : '280px',
              '290px',
              '350px',
              '400px',
            ]}
          >
            {value}
          </Text>
          {isMySolution && (
            <GradientTooltip label="Rename title">
              <IconButton
                aria-label="Rename title"
                icon={<Icon type="PencilEdit" />}
                size={size}
                variant="ghost"
                onClick={(e) => {
                  e.stopPropagation();
                  handleEdit();
                }}
              />
            </GradientTooltip>
          )}
        </>
      ) : (
        <Input
          value={value}
          onChange={handleChange}
          onBlur={handleBlur}
          onClick={(e) => e.stopPropagation()}
          size={size}
          onKeyDown={handleKeyDown}
          autoFocus
          maxLength={80}
        />
      )}
    </HStack>
  );
};

export default RenameCodeInput;
