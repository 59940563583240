import {
  Center,
  Container,
  Stack,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Td,
  Tbody,
  useTheme,
  HStack,
  Badge,
  Spinner,
} from '@chakra-ui/react';
import { InfiniteData } from '@tanstack/react-query';
import { useGetPracticeProblemsQuery } from 'hooks/queries/problems/useGetPracticeProblemsQuery';
import { useScrollToTop } from 'hooks/useScrollToTop';
import { useState } from 'react';

import { ReactComponent as NoPracticeProblemsIllustration } from 'assets/illustrations/NoPracticeProblems.svg';
import Button from 'components/Button';
import Icon from 'components/Icon';
import Link from 'components/Link';
import PageLoader from 'components/PageLoader';
import RecentUsers from 'components/RecentUsers';
import { SEO } from 'components/SEO';
import Tabs from 'components/Tabs';
import Text from 'components/Text';
import { useMediaQueries } from 'layout/MediaQueriesProvider';
import {
  Experience_Level_Enum,
  User_Challenge_Status_Enum,
  User_Practice_Problem_Status_Enum,
} from 'types/databaseEnums';
import { PracticeProblemPreview } from 'types/edgeFunctions';
import { ERoute } from 'types/frontend';

export const StatusIconMapper = {
  [User_Practice_Problem_Status_Enum.NotStarted]: <Icon type="StatusNotStarted" size={14} />,
  [User_Practice_Problem_Status_Enum.InProgress]: <Icon type="StatusNotStarted" size={14} />,
  [User_Challenge_Status_Enum.Started]: <Icon type="StatusNotStarted" size={14} />,
  completed: <Icon type="StatusCompleted" size={14} />,
};

export const StatusTextMapper = {
  [User_Practice_Problem_Status_Enum.NotStarted]: 'Not completed',
  [User_Practice_Problem_Status_Enum.InProgress]: 'Not completed',
  [User_Challenge_Status_Enum.Started]: 'Not completed',
  completed: 'Completed',
};

export const DifficultyTextMapper = {
  [Experience_Level_Enum.Junior]: 'Easy',
  [Experience_Level_Enum.Regular]: 'Medium',
  [Experience_Level_Enum.Senior]: 'Hard',
};

export const ColorSchemeMapper = {
  [Experience_Level_Enum.Junior]: 'green',
  [Experience_Level_Enum.Regular]: 'yellow',
  [Experience_Level_Enum.Senior]: 'red',
};

interface IDifficultyMarkerProps {
  difficulty: Experience_Level_Enum;
}

export const DifficultyMarker = ({ difficulty }: IDifficultyMarkerProps) => {
  return (
    <Badge
      p={1}
      px={1.5}
      colorScheme={ColorSchemeMapper[difficulty]}
      borderRadius={999}
      fontWeight={400}
      fontSize="0.75rem"
      textTransform="none"
      border="1px solid"
      borderColor="whiteAlpha.200"
    >
      {DifficultyTextMapper[difficulty]}
    </Badge>
  );
};

const PracticeProblems = () => {
  const [tabIndex, setTabIndex] = useState(0);

  const { borderRadius } = useTheme();

  const { isMobile } = useMediaQueries();

  const { data, isFetching, ref } = useGetPracticeProblemsQuery(tabIndex, true);

  useScrollToTop();

  const practiceProblemsData = (data as unknown as InfiniteData<PracticeProblemPreview[]>) || [];
  const practiceProblems =
    practiceProblemsData.pages.reduce(
      (acc, page) => acc.concat(page),
      [] as PracticeProblemPreview[]
    ) || [];

  const renderEmptyPracticeProblems = () => {
    return (
      <Center width="100%" mt={isMobile ? 10 : '12vh'}>
        <Stack spacing={5} alignItems="center">
          <NoPracticeProblemsIllustration />
          <Text fontSize="xLarge" fontWeight="regular" textAlign="center">
            No problems found.
          </Text>
          <Button onClick={() => setTabIndex(0)}>View all problems</Button>
        </Stack>
      </Center>
    );
  };

  const filteredPracticeProblems = practiceProblems.filter((practiceProblem) => {
    if (tabIndex === 0) {
      return true;
    }

    if (tabIndex === 1) {
      return practiceProblem.status === User_Practice_Problem_Status_Enum.NotStarted;
    }

    return practiceProblem.status === User_Practice_Problem_Status_Enum.Completed;
  });

  const renderPracticeProblems = () => {
    const isFirstPage = practiceProblemsData.pages.length === 0;

    if (isFirstPage && isFetching) {
      return <PageLoader shift />;
    }

    if (filteredPracticeProblems.length === 0) {
      return renderEmptyPracticeProblems();
    }

    return (
      <>
        <TableContainer
          mt={4}
          borderTopLeftRadius={borderRadius}
          borderTopRightRadius={borderRadius}
        >
          <Table variant="simple">
            <Thead backgroundColor="whiteAlpha.200" height="56px">
              <Tr>
                <Th width="15%">
                  <Text fontWeight="semibold">Status</Text>
                </Th>
                <Th width="20%">
                  <Text fontWeight="semibold">Title</Text>
                </Th>
                <Th width="15%">
                  <Text fontWeight="semibold">Difficulty</Text>
                </Th>
                <Th width="15%" minW="151px">
                  <Text fontWeight="semibold">Type</Text>
                </Th>
                <Th width="35%">
                  <Text fontWeight="semibold">Solvers</Text>
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {filteredPracticeProblems.map(
                ({
                  id,
                  title,
                  screen_name,
                  difficulty,
                  type,
                  status,
                  recent_users,
                  users_completed_count,
                }) => {
                  return (
                    <Tr key={id}>
                      <Td>
                        <HStack>
                          {StatusIconMapper[status]}
                          <Text fontSize="small">{StatusTextMapper[status]}</Text>
                        </HStack>
                      </Td>
                      <Td>
                        <Link
                          to={`${ERoute.PracticeProblems}/${screen_name}`}
                          color="white"
                          _hover={{ color: 'purple.200' }}
                          style={{ textDecoration: 'underline' }}
                        >
                          <Text
                            fontSize="small"
                            _hover={{ color: 'purple.200' }}
                            transition="0.2s ease-out all"
                            isTruncated
                            maxW="250px"
                          >
                            {title}
                          </Text>
                        </Link>
                      </Td>
                      <Td>
                        <DifficultyMarker difficulty={difficulty} />
                      </Td>
                      <Td>
                        <Text fontSize="small">{type}</Text>
                      </Td>
                      <Td>
                        <RecentUsers data={recent_users} count={users_completed_count} />
                      </Td>
                    </Tr>
                  );
                }
              )}
            </Tbody>
          </Table>
        </TableContainer>
        <div ref={ref} />
        <Center width="100%" mt={10}>
          {isFetching && !isFirstPage && <Spinner size="lg" />}
        </Center>
      </>
    );
  };

  return (
    <Container maxW="1400px">
      <SEO
        title="BigDevSoon | Practice problems"
        description="Work on interview-like practice problems to improve your coding skills"
        url={`${process.env.REACT_APP_SITE_URL}projects`}
      />
      <Stack
        direction={['column', 'column', 'row', 'row']}
        alignItems="center"
        justifyContent="space-between"
        background="gray.900"
        py={3}
      >
        <Text fontSize="xxLarge" fontWeight="semibold">
          Problems
        </Text>
        <Tabs
          tabs={['All', 'Not completed', 'Completed']}
          tabIndex={tabIndex}
          onChange={(index) => setTabIndex(index)}
        />
      </Stack>{' '}
      {renderPracticeProblems()}
    </Container>
  );
};

export default PracticeProblems;
