import {
  Input,
  InputGroup,
  InputRightElement,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  useTheme,
  PopoverArrow,
  IconButton,
} from '@chakra-ui/react';
import { useState } from 'react';

import { useCurrentUser } from 'auth/CurrentUserProvider';
import { referralBuilder } from 'utils/strings';

import Icon from './Icon';

interface IReferralInputProps {
  link?: string;
}

export const LinkInput = ({ link }: IReferralInputProps) => {
  const [isCopied, setIsCopied] = useState(false);

  const { referralCode } = useCurrentUser();
  const { fontSizes, opacity } = useTheme();

  const value = link || referralBuilder(referralCode);

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(value);
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 300);
  };

  return (
    <div data-sl="mask" style={{ width: '100%' }}>
      <InputGroup zIndex={1}>
        <Input
          fontSize={fontSizes.small}
          value={value}
          disabled
          opacity={`${opacity.secondary} !important`}
        />
        <InputRightElement>
          <Popover isOpen={isCopied}>
            <PopoverTrigger>
              <IconButton
                onClick={handleCopyToClipboard}
                aria-label="copy link to clipboard"
                backgroundColor="whiteAlpha.300"
              >
                <Icon type="Copy" />
              </IconButton>
            </PopoverTrigger>
            <PopoverContent width="fit-content" backgroundColor="#D9D9D9">
              <PopoverArrow backgroundColor="#D9D9D9" />
              <PopoverBody color="gray.900">Copied!</PopoverBody>
            </PopoverContent>
          </Popover>
        </InputRightElement>
      </InputGroup>
    </div>
  );
};
