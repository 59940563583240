import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import {
  Box,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Wrap,
  IconButton,
  VStack,
  useTheme,
  HStack,
} from '@chakra-ui/react';
import { isEqual } from 'lodash';
import { useState } from 'react';

import Icon from 'components/Icon';
import { ProtectedElement } from 'components/ProtectedElement';
import Text from 'components/Text';

import { useDevSandbox } from './DevSandboxProvider';

const beautifyInput = (input: unknown): undefined | null | string => {
  if (input === undefined) {
    return `undefined`;
  } else if (input === null) {
    return `null`;
  } else if (typeof input === 'string') {
    return `"${input}"`;
  } else {
    return JSON.stringify(input);
  }
};

interface IStatusDotProps {
  isPassing: boolean;
}

const StatusDot = ({ isPassing }: IStatusDotProps) => {
  return (
    <Box
      width="6px"
      height="6px"
      borderRadius="50%"
      backgroundColor={isPassing ? 'green.200' : 'red.200'}
    />
  );
};

interface ITestCasesProps {
  isAccordionOpen: boolean;
  onAccordionOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const TestCases = ({ isAccordionOpen, onAccordionOpen }: ITestCasesProps) => {
  const {
    testCases: { tests, input_names },
    state: {
      practiceProblemChecks: { hasRun, outputs, passedTestsCount },
    },
    handlers: { onRunTestsClick },
  } = useDevSandbox();

  const { borderRadius } = useTheme();

  const [currentTab, setCurrentTab] = useState(1);

  return (
    <Accordion
      index={0}
      allowToggle
      reduceMotion
      position="absolute"
      bottom={0}
      width="100%"
      bg="gray.900"
      overflowY="auto"
      height={isAccordionOpen ? '44%' : '56px'}
      transition={'height 0.15s ease-out'}
    >
      <AccordionItem height="100%" borderBottom="none" borderTop="none">
        <>
          <Box borderColor="whiteAlpha.200" position="relative">
            <Box
              position="absolute"
              top={0}
              left={0}
              right={0}
              height="10px"
              onClick={(e) => e.stopPropagation()}
              cursor="default"
            />
            <AccordionButton onClick={() => onAccordionOpen((prev) => !prev)}>
              <Box as="span" flex="1" textAlign="left">
                <Text>
                  Tests ({passedTestsCount}/{tests.length})
                </Text>
              </Box>
              <HStack>
                <ProtectedElement
                  scheme="outline"
                  leftIcon={<Icon type="Play" size={20} />}
                  onClick={onRunTestsClick}
                >
                  Run Tests
                </ProtectedElement>
                {isAccordionOpen ? <ChevronDownIcon /> : <ChevronUpIcon />}
              </HStack>
            </AccordionButton>
            <Box
              position="absolute"
              top={0}
              left={0}
              right={0}
              height="10px"
              onClick={(e) => e.stopPropagation()}
              cursor="default"
            />
          </Box>
          <AccordionPanel>
            <VStack alignItems="flex-start" spacing={3} width="100%">
              <Wrap spacing={4} width="100%">
                {tests.map((test, index) => (
                  <IconButton
                    key={test.id}
                    aria-label={`Test Case ${test.id}`}
                    p={1.5}
                    variant={currentTab === test.id ? 'solid' : 'ghost'}
                    onClick={() => {
                      setCurrentTab(test.id);
                    }}
                  >
                    <HStack>
                      {hasRun && <StatusDot isPassing={isEqual(test.output, outputs[index])} />}
                      <Text>Case {test.id}</Text>
                    </HStack>
                  </IconButton>
                ))}
              </Wrap>
              <Box width="100%">
                <Text fontSize="small" opacity="secondary" fontWeight="semibold" pb={1}>
                  Input
                </Text>
                <Box
                  backgroundColor="whiteAlpha.200"
                  width="100%"
                  borderRadius={borderRadius}
                  p={1}
                >
                  <Wrap spacing={0}>
                    {input_names.map((inputName, index) => (
                      <Text key={inputName} fontSize="small" p={1}>
                        {inputName} = {beautifyInput(tests[currentTab - 1].input[index])}
                        {index + 1 < input_names.length ? ',' : ''}
                      </Text>
                    ))}
                  </Wrap>
                </Box>
              </Box>
              <Box width="100%">
                <Text fontSize="small" opacity="secondary" fontWeight="semibold" pb={1}>
                  Output
                </Text>
                <Box
                  backgroundColor="whiteAlpha.200"
                  width="100%"
                  borderRadius={borderRadius}
                  p={1}
                >
                  <Text
                    fontSize="small"
                    p={1}
                    color={
                      hasRun
                        ? isEqual(outputs[currentTab - 1], tests[currentTab - 1].output)
                          ? 'green.200'
                          : 'red.200'
                        : 'inherit'
                    }
                  >
                    {hasRun
                      ? beautifyInput(outputs[currentTab - 1])
                      : 'Run the tests to see the output'}
                  </Text>
                </Box>
              </Box>
              <Box width="100%">
                <Text fontSize="small" opacity="secondary" fontWeight="semibold" pb={1}>
                  Expected
                </Text>
                <Box
                  backgroundColor="whiteAlpha.200"
                  width="100%"
                  borderRadius={borderRadius}
                  p={1}
                >
                  <Text fontSize="small" p={1} color={hasRun ? 'green.200' : 'inherit'}>
                    {beautifyInput(tests[currentTab - 1].output)}
                  </Text>
                </Box>
              </Box>
            </VStack>
          </AccordionPanel>
        </>
      </AccordionItem>
    </Accordion>
  );
};

export default TestCases;
