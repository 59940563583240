import { useEffect, useRef, useState } from 'react';
import Confetti from 'react-confetti';
import useLocalStorageState from 'use-local-storage-state';

interface IUseConfettiProps {
  isMySolution: boolean;
  solutionUrl: string;
  solutionId: string;
  onComplete: () => void;
}

const useConfetti = ({ isMySolution, solutionUrl, solutionId, onComplete }: IUseConfettiProps) => {
  const [playConfetti, setPlayConfetti] = useState(false);
  const [playedConfetti, setPlayedConfetti] = useLocalStorageState<boolean>(
    `${solutionId}-confetti`,
    {
      defaultValue: false,
    }
  );

  const timeoutRef = useRef<any>(null);
  const progressModalTimeoutRef = useRef<any>(null);

  useEffect(() => {
    const oldKey = localStorage.getItem(`${solutionUrl}-confetti`);

    if (oldKey || playedConfetti || !isMySolution || !solutionUrl || !solutionId) {
      return;
    }

    setPlayedConfetti(true);
    setPlayConfetti(true);
    progressModalTimeoutRef.current = setTimeout(() => {
      onComplete();
    }, 1500);
    timeoutRef.current = setTimeout(() => {
      setPlayConfetti(false);
    }, 6000);

    return () => {
      clearTimeout(progressModalTimeoutRef.current);
      clearTimeout(timeoutRef.current);
    };
  }, [solutionUrl, isMySolution, solutionId]);

  return playConfetti ? Confetti : null;
};

export default useConfetti;
