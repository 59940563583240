import { Box, Center, VStack, useDisclosure } from '@chakra-ui/react';
import { debounce } from 'lodash';
import { useEffect, useState } from 'react';

import Button from 'components/Button';
import Icon from 'components/Icon';
import Modal from 'components/Modal';
import SplitPane from 'components/SplitPane';
import Text from 'components/Text';
import { useMediaQueries } from 'layout/MediaQueriesProvider';

import CodeEditor from './CodeEditor/CodeEditor';
import CodeFrame from './CodeFrame';
import { useDevSandbox } from './DevSandboxProvider';
import PaneTabs from './PaneTabs';
import TestCases from './TestCases';

const DevSandboxContent = () => {
  const [isAccordionOpen, setIsAccordionOpen] = useState(true);
  const [isDragging, setIsDragging] = useState(false);

  const codeMobileModal = useDisclosure();

  const { isSmallScreen } = useMediaQueries();

  const {
    mode,
    externalCodeUrl,
    dimensions: { codeEditorWidth, previewWidth },
    handlers: { onCodeEditorWidthChange, onPreviewWidthChange },
    iframe: { codeFrameUrl },
    flags: { isExternalCode },
  } = useDevSandbox();

  const handleResize = () => {
    const pane1 = document.querySelector('.BDS-SplitPane-horizontal-0');
    const pane2 = document.querySelector('.BDS-SplitPane-horizontal-1');

    const windowWidth = window.innerWidth;

    if (pane1 && pane2) {
      // @ts-ignore
      const pane1Width = pane1.offsetWidth;
      const pane2Width = windowWidth - pane1Width;

      // @ts-ignore
      pane1.style.width = `${pane1Width}px`;
      // @ts-ignore
      pane2.style.width = `${pane2Width}px`;
      onCodeEditorWidthChange(pane1Width);
      onPreviewWidthChange(pane2Width);
    }
  };

  const onHorizontalResize = debounce(() => {
    handleResize();
  }, 10);

  useEffect(() => {
    handleResize();
  }, []);

  const renderScriptComponents = () => {
    if (isSmallScreen) {
      return (
        <>
          <PaneTabs />
          <Box position="absolute" left={4} bottom={4} zIndex={999}>
            <Button
              onClick={codeMobileModal.onOpen}
              scheme="outline"
              leftIcon={<Icon type="Code" />}
            >
              Code
            </Button>
          </Box>
          <Modal
            isOpen={codeMobileModal.isOpen}
            onClose={codeMobileModal.onClose}
            header={null}
            footer={null}
          >
            <Box height="80vh" width="100%" overflowY="auto">
              <CodeEditor isAccordionOpen={isAccordionOpen} />
              <TestCases isAccordionOpen={isAccordionOpen} onAccordionOpen={setIsAccordionOpen} />
            </Box>
          </Modal>
        </>
      );
    }

    return (
      <>
        {isDragging && (
          <div
            style={{
              position: 'fixed',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              zIndex: 9999,
            }}
          />
        )}
        <SplitPane
          leftPaneSize={codeEditorWidth}
          rightPaneSize={previewWidth}
          onResize={onHorizontalResize}
          onDragStart={() => setIsDragging(true)}
          onDragEnd={() => setIsDragging(false)}
        >
          <Box height="100%" position="relative" overflowY="hidden">
            <CodeEditor isAccordionOpen={isAccordionOpen} />
            <TestCases isAccordionOpen={isAccordionOpen} onAccordionOpen={setIsAccordionOpen} />
          </Box>
          <PaneTabs />
        </SplitPane>
      </>
    );
  };

  const renderInteractiveComponents = () => {
    if (isSmallScreen) {
      const buttonIconProps = isExternalCode ? { rightIcon: <Icon type="OutsideLink" /> } : {};
      const buttonClickProps = isExternalCode
        ? { onClick: () => window.open(externalCodeUrl, '_blank') }
        : {};

      const smallScreenButtonProps = isSmallScreen
        ? {
            onClick: () => codeMobileModal.onOpen(),
          }
        : {};

      return (
        <>
          <PaneTabs />
          <Box
            position="absolute"
            left={isSmallScreen ? 4 : '50%'}
            transform={isSmallScreen ? 'none' : 'translateX(-50%)'}
            bottom={isSmallScreen ? 4 : 2}
            zIndex={999}
          >
            <Button
              scheme="outline"
              leftIcon={<Icon type="Code" />}
              {...buttonIconProps}
              {...smallScreenButtonProps}
              {...buttonClickProps}
            >
              {isExternalCode ? 'View Code' : 'Code'}
            </Button>
          </Box>
          <Modal
            isOpen={codeMobileModal.isOpen}
            onClose={codeMobileModal.onClose}
            header={null}
            footer={null}
          >
            <Box height="80vh" width="100%" overflowY="auto">
              <CodeEditor />
            </Box>
          </Modal>
        </>
      );
    }

    return (
      <>
        <SplitPane
          leftPaneSize={codeEditorWidth}
          rightPaneSize={previewWidth}
          onResize={onHorizontalResize}
          onDragStart={() => setIsDragging(true)}
          onDragEnd={() => setIsDragging(false)}
        >
          <Box height="100%" overflowY="hidden">
            {isExternalCode ? (
              <Center height="100%">
                <VStack spacing={3} px={4}>
                  <Text fontSize="large" textAlign="center">
                    The external {`solution's`} code is not available
                  </Text>
                  <Text fontSize="large" textAlign="center">
                    in our built-in Code Editor
                  </Text>
                  <Button
                    mt={2}
                    scheme="outline"
                    leftIcon={<Icon type="Code" />}
                    rightIcon={<Icon type="OutsideLink" />}
                    onClick={() => {
                      window.open(externalCodeUrl, '_blank');
                    }}
                  >
                    {isExternalCode ? 'View Code' : 'Code'}
                  </Button>
                </VStack>
              </Center>
            ) : (
              <CodeEditor />
            )}
          </Box>
          <PaneTabs />
        </SplitPane>
        {isDragging && (
          <div
            style={{
              position: 'fixed',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              zIndex: 9999,
            }}
          />
        )}
      </>
    );
  };

  switch (mode) {
    case 'script-practice':
      return renderScriptComponents();
    case 'interactive':
      return renderInteractiveComponents();
    case 'full-page':
      return <CodeFrame url={codeFrameUrl || ''} />;
    default:
      return null;
  }
};

export default DevSandboxContent;
