import { Stack } from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';

import { useDevSandbox } from 'components/DevSandbox/DevSandboxProvider';
import { ChatGptMessage } from 'types/edgeFunctions';

import Message from './Message';

interface IMessagesProps {
  messages: ChatGptMessage[];
}

const Messages = ({ messages }: IMessagesProps) => {
  const scrollRef = useRef<null | HTMLDivElement>(null);
  const [isUserScrolling, setIsUserScrolling] = useState(false);

  const {
    dimensions: { previewWidth },
    paneTabs: { tabIndex },
  } = useDevSandbox();

  const handleScrollDown = () => {
    if (!scrollRef.current || isUserScrolling) {
      return;
    }

    const scrollHeight = scrollRef.current.scrollHeight;
    const height = scrollRef.current.clientHeight;
    const maxScrollTop = scrollHeight - height;
    scrollRef.current.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
  };

  const handleUserScroll = () => {
    if (!scrollRef.current) {
      return;
    }

    const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;
    const bottomPosition = scrollHeight - clientHeight;

    // Check if the user is within a certain threshold from the bottom
    setIsUserScrolling(scrollTop < bottomPosition); // 10px threshold
  };

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.addEventListener('scroll', handleUserScroll);
    }

    return () => {
      if (scrollRef.current) {
        scrollRef.current.removeEventListener('scroll', handleUserScroll);
      }
    };
  }, []);

  useEffect(() => {
    if (!messages || messages.length === 1) {
      return;
    }

    handleScrollDown();
  }, [messages, tabIndex]);

  const showPromptButtons = previewWidth! > 500;

  return (
    <div
      ref={scrollRef}
      style={{
        flex: '1',
        maxHeight: showPromptButtons ? '54vh' : '69vh',
        height: showPromptButtons ? '54vh' : '69vh',
        overflowX: 'hidden',
        marginBottom: 4,
      }}
    >
      <Stack spacing={5}>
        {messages.map(({ id, who, message, status }) => (
          <Message
            key={id}
            id={id}
            who={who}
            status={status}
            content={message || ''}
            onScrollDown={handleScrollDown}
          />
        ))}
      </Stack>
    </div>
  );
};

export default Messages;
