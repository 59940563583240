import { SupabaseClient } from '@supabase/supabase-js';
import { useQueryClient } from '@tanstack/react-query';
import { useInfiniteQueryWithErrorBoundary, useSupabaseTypedClient } from 'hooks/reactQuery';
import { useCallback, useEffect, useRef } from 'react';

import { User_Challenge_Status_Enum } from 'types/databaseEnums';
import { GetChallengesResponse, ChallengePreview } from 'types/edgeFunctions';
import { TanstackQueryName } from 'types/frontend';
import { Database } from 'types/supabase';

interface IGetChallengesQueryFnParams {
  supabase: SupabaseClient<Database>;
  userId: string;
  limit?: number | null;
  pageParam?: unknown | null;
  tabIndex: number;
}

export const getChallengesQueryFn = async ({
  supabase,
  userId,
  limit = 12,
  pageParam,
  tabIndex,
}: IGetChallengesQueryFnParams) => {
  const challengesQuery = supabase
    .from('challenge')
    .select('id, title, created_at, order, screen_name, screenshot_url')
    .order('order', { ascending: true })
    .limit(tabIndex === 0 ? limit || 12 : 100);

  if (pageParam) {
    challengesQuery.gt('created_at', pageParam);
  }

  const challenges = await challengesQuery;

  if (challenges.error) throw challenges.error;

  const allChallenges = await Promise.all(
    challenges.data.map(async (challenge): Promise<ChallengePreview> => {
      const userChallenge = userId
        ? await supabase
            .from('user_challenge')
            .select('id, status_type')
            .eq('user_id', userId || '')
            .eq('challenge_id', challenge.id)
            .eq('status_type', User_Challenge_Status_Enum.Completed)
            .maybeSingle()
        : null;

      if (userChallenge?.error) throw userChallenge?.error;

      return {
        id: challenge.id,
        created_at: challenge.created_at,
        screen_name: challenge.screen_name,
        title: challenge.title,
        order: challenge.order,
        screenshot_url: challenge.screenshot_url || '',
        status: (userChallenge?.data?.status_type ||
          User_Challenge_Status_Enum.Started) as User_Challenge_Status_Enum,
        recent_users: [],
        users_completed_count: 0,
      };
    })
  );

  return allChallenges;
};

interface IUseGetChallengeQueryResponse {
  data?: GetChallengesResponse | null;
  isFetching: boolean;
  ref: any;
}

interface IUseGetChallengesQueryParams {
  userId: string;
  tabIndex: number;
}

export const useGetChallengesQuery = ({
  userId,
  tabIndex,
}: IUseGetChallengesQueryParams): IUseGetChallengeQueryResponse => {
  const supabase = useSupabaseTypedClient();
  const queryClient = useQueryClient();

  const { data, fetchNextPage, hasNextPage, isFetching } = useInfiniteQueryWithErrorBoundary({
    queryKey: [TanstackQueryName.GetChallenges, userId, tabIndex],
    queryFn: async ({ pageParam }) => {
      return getChallengesQueryFn({ supabase, userId, limit: 12, pageParam, tabIndex });
    },
    placeholderData: {
      pageParams: [],
      pages: [],
    },
    initialPageParam: null,
    getNextPageParam: (lastPage) => {
      if (lastPage && Array.isArray(lastPage) && lastPage?.length > 0) {
        const lastChallengeCreatedAt = lastPage[lastPage.length - 1]?.created_at;

        return lastChallengeCreatedAt;
      }

      return undefined;
    },
  });

  const observer = useRef<IntersectionObserver>();
  const lastChallengeElementRef = useCallback(
    (node) => {
      if (isFetching) return;

      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting && hasNextPage) {
            fetchNextPage();
          }
        },
        {
          rootMargin: '100px',
        }
      );

      if (node) observer.current.observe(node);
    },
    [fetchNextPage, hasNextPage, isFetching]
  );

  useEffect(() => {
    return () => {
      queryClient.removeQueries({
        queryKey: [TanstackQueryName.GetChallenges, userId, 0],
      });
      queryClient.removeQueries({
        queryKey: [TanstackQueryName.GetChallenges, userId, 1],
      });
      queryClient.removeQueries({
        queryKey: [TanstackQueryName.GetChallenges, userId, 2],
      });
    };
  }, []);

  return {
    data,
    isFetching,
    ref: lastChallengeElementRef,
  };
};
