import { SupabaseClient } from '@supabase/supabase-js';
import { useQueryClient } from '@tanstack/react-query';
import { useInfiniteQueryWithErrorBoundary, useSupabaseTypedClient } from 'hooks/reactQuery';
import { useRef, useCallback, useEffect } from 'react';

import { GetProjectSolutionsResponse } from 'types/edgeFunctions';
import { TanstackQueryName } from 'types/frontend';
import { Database } from 'types/supabase';

interface IGetSolutionsQueryFnParams {
  supabase: SupabaseClient<Database>;
  projectId: string;
  limit?: number | null;
  pageParam?: any | null;
}

export const getSolutionsQueryFn = async ({
  supabase,
  projectId,
  limit = 6,
  pageParam,
}: IGetSolutionsQueryFnParams): Promise<GetProjectSolutionsResponse> => {
  const solutionsQuery = supabase
    .from('user_code_view')
    .select('*')
    .eq('is_private', false)
    .eq('type', 'project')
    .eq('parent_id', projectId)
    .not('user_id', 'is', null)
    .limit(limit || 6)
    .order('completed_date', { ascending: false });

  if (pageParam) {
    solutionsQuery.lt('completed_date', pageParam?.completed_date);
  }

  const solutions = await solutionsQuery;

  if (solutions.error) throw solutions.error;

  return solutions.data;
};

interface IUseGetProjectSolutionsResponse {
  data: GetProjectSolutionsResponse | undefined | null;
  isFetching: boolean;
  ref: any;
}

interface IUseGetUserSolutionsQueryParams {
  projectId: string;
}

export const useGetProjectSolutionsQuery = ({
  projectId,
}: IUseGetUserSolutionsQueryParams): IUseGetProjectSolutionsResponse => {
  const supabase = useSupabaseTypedClient();
  const queryClient = useQueryClient();

  const { data, fetchNextPage, hasNextPage, isFetching } = useInfiniteQueryWithErrorBoundary({
    queryKey: [TanstackQueryName.GetProjectSolutions, projectId],
    queryFn: ({ pageParam }) =>
      getSolutionsQueryFn({
        supabase,
        projectId,
        limit: 6,
        pageParam,
      }),
    placeholderData: {
      pageParams: [],
      pages: [],
    },
    initialPageParam: null,
    getNextPageParam: (lastPage) => {
      if (lastPage && Array.isArray(lastPage) && lastPage?.length > 0) {
        const lastPageParams = lastPage[lastPage.length - 1];

        return lastPageParams;
      }

      return undefined;
    },
  });

  const observer = useRef<IntersectionObserver>();
  const lastProjectSolutionsRef = useCallback(
    (node) => {
      if (isFetching) return;

      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting && hasNextPage) {
            fetchNextPage();
          }
        },
        {
          rootMargin: '100px',
        }
      );

      if (node) observer.current.observe(node);
    },
    [fetchNextPage, hasNextPage, isFetching]
  );

  useEffect(() => {
    return () => {
      queryClient.removeQueries({
        queryKey: [TanstackQueryName.GetProjectSolutions, projectId],
      });
    };
  }, []);

  return {
    data,
    isFetching,
    ref: lastProjectSolutionsRef,
  };
};
