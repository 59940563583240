import { IconButton } from '@chakra-ui/react';

import GradientPopover from './GradientPopover';
import Icon from './Icon';
import Text from './Text';
import ToggleCodePrivacySwitch, { IToggleCodePrivacySwitchProps } from './ToggleCodePrivacySwitch';

interface IToggleCodePrivacyLockProps extends IToggleCodePrivacySwitchProps {}

const ToggleCodePrivacyLock = ({ ...switchProps }: IToggleCodePrivacyLockProps) => {
  return (
    <GradientPopover
      tooltip="Toggle code privacy"
      triggerComponent={
        <IconButton variant="ghost" aria-label="Toggle code privacy">
          {switchProps.togglePrivacyCodeParams.isPrivate ? (
            <Icon type="PrivateCodeLock" />
          ) : (
            <Icon type="PublicCodeLock" />
          )}
        </IconButton>
      }
      header={<Text fontSize="large">Toggle code privacy</Text>}
    >
      <ToggleCodePrivacySwitch {...switchProps} />
    </GradientPopover>
  );
};

export default ToggleCodePrivacyLock;
