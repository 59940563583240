import {
  Box,
  Divider,
  useTheme,
  Textarea,
  Stack,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  HStack,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { supabaseMutationFn, useSupabaseTypedClient } from 'hooks/reactQuery';
import { useToastWrapper } from 'hooks/useToastWrapper';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { useCurrentUser } from 'auth/CurrentUserProvider';
import Button from 'components/Button';
import Text from 'components/Text';
import { EdgeFunctionName, UpdateUserProfileParams, UserProfile } from 'types/edgeFunctions';
import { TanstackQueryName } from 'types/frontend';

interface AboutMeForm {
  bio: string;
  goal: string;
}

interface AboutMeProps {
  initialValues: Pick<UserProfile, 'bio' | 'goal'>;
}

const validationSchema = yup
  .object({
    bio: yup.string().max(300),
    goal: yup.string().max(300),
  })
  .required();

const AboutMe = ({ initialValues }: AboutMeProps) => {
  const { borderRadius } = useTheme();

  const supabase = useSupabaseTypedClient();

  const { id: userId } = useCurrentUser();

  const queryClient = useQueryClient();

  const { toastSuccess, toastError } = useToastWrapper();

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors, isDirty },
    watch,
  } = useForm<AboutMeForm>({
    // @ts-ignore
    resolver: yupResolver(validationSchema),
    mode: 'onSubmit',
    defaultValues: {
      bio: initialValues.bio || '',
      goal: initialValues.goal || '',
    },
  });

  const bio = watch('bio');
  const goal = watch('goal');

  const mutation = useMutation<UserProfile, string, AboutMeForm>({
    mutationFn: supabaseMutationFn((params) =>
      supabase.functions.invoke<UserProfile>(EdgeFunctionName.UpdateUserProfile, {
        body: params as UpdateUserProfileParams,
      })
    ),
    onSuccess: (data) => {
      reset({ bio: data.bio, goal: data.goal });

      queryClient.invalidateQueries({ queryKey: [TanstackQueryName.GetUserProfile, userId] });

      toastSuccess('About me saved');
    },
    onError: () => {
      toastError(`Can't save about me`);
    },
  });

  const onSubmit = async ({ bio, goal }: AboutMeForm) => {
    await mutation.mutate({
      bio: bio ? bio.trim() : '',
      goal: goal ? goal.trim() : '',
    });
  };

  return (
    <Box py={5} px={6} bg="whiteAlpha.200" borderRadius={borderRadius}>
      <Text fontSize="xLarge" pb={5}>
        About me
      </Text>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={4}>
          <Stack spacing={2}>
            <FormControl isInvalid={Boolean(errors.bio)}>
              <FormLabel htmlFor="bio" fontWeight="normal">
                Bio
              </FormLabel>
              <Textarea
                {...register('bio')}
                placeholder="We would love to hear a bit more about you! ❤️ Share a bit about your skills, previous experience and what you are looking for to achieve in BigDevSoon!"
                variant="filled"
                maxLength={300}
              />
              <HStack justifyContent="space-between" alignItems="center">
                {errors.bio?.message ? (
                  <FormErrorMessage>{errors.bio.message}</FormErrorMessage>
                ) : (
                  <div />
                )}
                <FormHelperText>
                  <HStack spacing={1}>
                    <Text fontSize="micro" opacity="secondary" fontWeight="regular">
                      Characters remaining:
                    </Text>
                    <Text fontSize="micro" opacity="secondary" fontWeight="semibold">
                      {300 - bio.length}
                    </Text>
                  </HStack>
                </FormHelperText>
              </HStack>
            </FormControl>
          </Stack>
          <Stack spacing={2}>
            <FormControl isInvalid={Boolean(errors.goal)}>
              <FormLabel htmlFor="goal" fontWeight="normal">
                Goal
              </FormLabel>
              <Textarea
                {...register('goal')}
                placeholder="i.e. what technologies do you want to master next, or what is your desired job? 👀"
                variant="filled"
                maxLength={300}
              />
              <HStack justifyContent="space-between" alignItems="center">
                {errors.goal?.message ? (
                  <FormErrorMessage>{errors.goal.message}</FormErrorMessage>
                ) : (
                  <div />
                )}
                <FormHelperText>
                  <HStack spacing={1}>
                    <Text fontSize="micro" opacity="secondary" fontWeight="regular">
                      Characters remaining:
                    </Text>
                    <Text fontSize="micro" opacity="secondary" fontWeight="semibold">
                      {300 - goal.length}
                    </Text>
                  </HStack>
                </FormHelperText>
              </HStack>
            </FormControl>
          </Stack>
        </Stack>
        <Divider mt={6} mb={5} />
        <Button type="submit" isLoading={mutation.isPending} isDisabled={!isDirty}>
          Save about me
        </Button>
      </form>
    </Box>
  );
};

export default AboutMe;
