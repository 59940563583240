import { HStack, IconButton } from '@chakra-ui/react';

import Icon from './Icon';
import Link from './Link';
import Text from './Text';

interface ICommentButtonProps {
  comments: number;
  url: string;
}

const CommentButton = ({ comments, url }: ICommentButtonProps) => {
  return (
    <Link
      to={url}
      onClick={(e) => {
        e.stopPropagation();
      }}
      _hover={{ textDecoration: 'none', opacity: 0.87 }}
      transition="all 0.2s ease-out"
    >
      <HStack spacing={1} minWidth="40px">
        <IconButton aria-label="comments count" variant="ghost" size="xs">
          <Icon type="CommunityQuestions" />
        </IconButton>
        <Text>{comments}</Text>
      </HStack>
    </Link>
  );
};

export default CommentButton;
