import {
  VStack,
  InputGroup,
  InputRightElement,
  Box,
  useTheme,
  HStack,
  IconButton,
  Textarea,
  useDisclosure,
  SimpleGrid,
} from '@chakra-ui/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { supabaseMutationFn, useSupabaseTypedClient } from 'hooks/reactQuery';
import { useToastWrapper } from 'hooks/useToastWrapper';
import { ChangeEvent, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { useCurrentUser } from 'auth/CurrentUserProvider';
import { BigBadge } from 'components/BigBadge';
import GradientTooltip from 'components/GradientTooltip';
import Icon from 'components/Icon';
import { ChatMessagesLimitModal } from 'components/Modal/ChatMessagesLimitModal';
import { UnlockBigModal } from 'components/Modal/UnlockBigModal';
import { ProtectedElement } from 'components/ProtectedElement';
import Text from 'components/Text';
import { Plan_Type_Enum } from 'types/databaseEnums';
import { AskChatGptParams, ChatGptMessage, EdgeFunctionName } from 'types/edgeFunctions';
import { TanstackQueryName } from 'types/frontend';

import Messages from './Messages';
import { useMessages } from './useMessages';

const promptButtons: {
  prompt: string;
  buttonText: string;
  description: string;
}[] = [
  {
    prompt: `User used "Give me a hint" prompt. Based on the whole context from this message, do your best to give the user the best possible advice. Do not mention the prompt.`,
    buttonText: 'Give me a hint',
    description: 'Unlock a clue to navigate your task',
  },
  {
    prompt: `User used "Write code" prompt. Based on the whole context from this message, try to write a small chunk of code to let user move forward. Do not mention the prompt.`,
    buttonText: 'Write code',
    description: 'Receive tailored code snippets',
  },
  {
    prompt: `User used "Review code" prompt. Based on the whole context from this message, do your best to review the code and give insightful tips for the user. Ask for the code snippet to review as well. Do not mention the prompt.`,
    buttonText: 'Review code',
    description: 'Gain insights for your code',
  },
  {
    prompt: `User used "Debug code" prompt. Based on the whole context from this message, do your best to give the user the best possible direction for solving a bug or ask for code snippet to solve a bug for the user. Do not mention the prompt.`,
    buttonText: 'Debug code',
    description: 'Solve a bug with ChatGPT',
  },
];

interface IChatGPTProps {
  chatContext: string;
}

const ChatGPT = ({ chatContext }: IChatGPTProps) => {
  const [message, setMessage] = useState('');

  const supabase = useSupabaseTypedClient();
  const queryClient = useQueryClient();

  const chatMessagesLimitModal = useDisclosure();
  const unlockBigModal = useDisclosure();

  const { messages, addMessage, updateMessage, deleteMessage, lastMessage } = useMessages();

  const {
    id: userId,
    isFree,
    isTerminated,
    chatMessageCounter,
    nextChatMessageRecycleTime,
    isMonthlySubscriber,
    isYearlySubscriber,
    isLifetimeUser,
    isSuperUser,
  } = useCurrentUser();

  const { borderRadius } = useTheme();

  const { toastError } = useToastWrapper();

  const isLoading = lastMessage.status === 'sending' || lastMessage.status === 'typing';

  const myMessages = messages.filter((message) => message.who === 'You');
  const myPreviousMessage = myMessages.length > 1 ? myMessages[myMessages.length - 2].message : '';

  const mutation = useMutation<ChatGptMessage, string, AskChatGptParams>({
    mutationFn: supabaseMutationFn(({ message, type }) =>
      supabase.functions.invoke<ChatGptMessage>(EdgeFunctionName.AskChatGpt, {
        body: {
          message: myPreviousMessage
            ? `For context, my previous message was: ${myPreviousMessage}. But my message now is: ${message}`
            : message,
          type,
        } as AskChatGptParams,
      })
    ),
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: [TanstackQueryName.GetCurrentUser, userId] });

      updateMessage(lastMessage.id, {
        ...data,
      });
    },
    onError: () => {
      deleteMessage(lastMessage.id);
      toastError('Something went wrong, send a shorter message and try again.');
    },
  });

  const prepareMessage = (initialMessage: string) => {
    const additionalContext = `The user is utilizing a built-in code editor with HTML, CSS, and JavaScript capabilities for web development tasks. They have direct access to code and can provide specific HTML, CSS, or JS snippets for review, debugging, or enhancement. Very important: Try to answer in a minimalistic manner while giving a relevant code fragment, i.e. do not copy whole HTML, CSS, JS from the user if the question is only about CSS for example. Remember that user has HTML, CSS, JS tabs available and tell them to write code there instead of inline styles and so on. Do not bother with style tags for CSS and script tags for JS, it's already there under the hood, HTML tab can use any tags still for loading external libraries.`;

    const finalMessage = `Initial context for answering: ${chatContext}. User message or selected prompt by the user: ${initialMessage}. Additional context based on the code environment: ${additionalContext}`;

    return finalMessage;
  };

  const sendMessage = (buttonText?: string) => {
    setMessage('');

    const promptMessage = promptButtons.find(
      (promptButton) => promptButton.buttonText === buttonText
    )?.prompt;

    const visibleMessage = buttonText || message;

    const youMessageDate = new Date().toISOString();
    const eliseMessageDate = new Date().toISOString();

    addMessage({
      id: `${uuidv4()}-${youMessageDate}`,
      created_at: youMessageDate,
      who: 'You',
      message: visibleMessage,
      status: 'received',
    });

    addMessage({
      id: `${uuidv4()}-${eliseMessageDate}`,
      created_at: eliseMessageDate,
      who: 'Elise',
      message: '',
      status: 'sending',
    });

    const userMessage = promptMessage || message;
    const preparedMessage = prepareMessage(userMessage);

    mutation.mutate({
      message: preparedMessage,
      type: 'ask-elise',
    });
  };

  const handleMessageChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setMessage(event.target.value);
  };

  const onAskChatCheckSubscription = () => {
    if (isSuperUser) {
      return;
    }

    if (isFree) {
      unlockBigModal.onOpen();

      return;
    }

    if (chatMessageCounter <= 0) {
      chatMessagesLimitModal.onOpen();

      return;
    }

    return;
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (!message || isLoading) {
      return;
    }

    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      sendMessage();
    }
  };

  const showChatMessagesAlert =
    (isMonthlySubscriber || isYearlySubscriber || isLifetimeUser) && chatMessageCounter < 100;

  const pane1 = document.querySelector('.BDS-SplitPane-horizontal-0');

  // @ts-ignore
  const previewWidth = window.innerWidth - (pane1?.offsetWidth || 0);

  return (
    <VStack
      alignItems="flex-start"
      width="100%"
      height="100%"
      justifyContent="space-between"
      px={4}
      pt={4}
    >
      {showChatMessagesAlert && previewWidth! > 400 && (
        <HStack spacing={1} width="100%" justifyContent="flex-end">
          <Text color={chatMessageCounter <= 0 ? 'red.200' : 'yellow.200'}>You have</Text>
          <Text color={chatMessageCounter <= 0 ? 'red.200' : 'yellow.200'}>
            {chatMessageCounter} messages left
          </Text>
          <Text color={chatMessageCounter <= 0 ? 'red.200' : 'yellow.200'}>to send.</Text>
        </HStack>
      )}
      <Messages messages={messages} />
      <VStack spacing={4} width="100%">
        {isFree && !isTerminated && !isSuperUser && previewWidth! > 400 && (
          <HStack spacing={1} whiteSpace="nowrap" width="100%" justifyContent="center">
            <Text fontSize="small" fontWeight="semibold">
              Become{' '}
            </Text>
            <BigBadge plan={Plan_Type_Enum.Lifetime} size="small" />
            <Text fontSize="small" fontWeight="semibold">
              to unlock AI Buddy
            </Text>
          </HStack>
        )}
        {previewWidth! > 500 && (
          <SimpleGrid spacing={2} columns={2} width="100%">
            {promptButtons.map((promptButton) => {
              return (
                <ProtectedElement
                  key={promptButton.prompt}
                  asButton={false}
                  fullWidth
                  onClick={() => {
                    if (isLoading) {
                      return;
                    }

                    if (isSuperUser) {
                      sendMessage(promptButton.buttonText);

                      return;
                    }

                    if (isFree) {
                      unlockBigModal.onOpen();

                      return;
                    }

                    if (chatMessageCounter <= 0) {
                      chatMessagesLimitModal.onOpen();

                      return;
                    }

                    sendMessage(promptButton.buttonText);
                  }}
                >
                  <Box
                    backgroundColor="whiteAlpha.200"
                    border="1px solid"
                    borderColor="whiteAlpha.300"
                    px={4}
                    py={2}
                    borderRadius={borderRadius}
                    cursor={isLoading ? 'not-allowed' : 'pointer'}
                    opacity={isLoading ? 0.4 : 1}
                    transition="all 0.3s ease"
                    _hover={{
                      backgroundColor: isLoading ? 'whiteAlpha.200' : 'whiteAlpha.300',
                    }}
                  >
                    <VStack justifyContent="flex-start" alignItems="flex-start" spacing={0}>
                      <Text fontWeight="semibold" fontSize="small">
                        {promptButton.buttonText}
                      </Text>
                      <Text
                        fontSize="small"
                        opacity="secondary"
                        fontWeight="regular"
                        isTruncated
                        maxWidth="100%"
                      >
                        {promptButton.description}
                      </Text>
                    </VStack>
                  </Box>
                </ProtectedElement>
              );
            })}
          </SimpleGrid>
        )}
        <ProtectedElement asButton={false} fullWidth>
          <InputGroup mb={4}>
            <GradientTooltip label="Send message">
              <InputRightElement top="50%" transform="translateY(-50%)">
                <IconButton
                  aria-label="Message to Elise - your ChatGPT coding buddy"
                  mr={4}
                  size="lg"
                  variant="unstyled"
                  transition="all 0.2s ease-out"
                  onClick={() => sendMessage()}
                  isDisabled={!message || isLoading}
                >
                  <Icon type="Send" />
                </IconButton>
              </InputRightElement>
            </GradientTooltip>
            <Textarea
              placeholder="Message to Elise"
              p={2}
              rows={1}
              backgroundColor="whiteAlpha.200"
              border="none"
              autoComplete="none"
              onChange={handleMessageChange}
              onKeyDown={handleKeyDown}
              onFocus={onAskChatCheckSubscription}
              value={message}
              resize="none"
              maxLength={1000}
            />
          </InputGroup>
        </ProtectedElement>
      </VStack>
      <ChatMessagesLimitModal
        {...chatMessagesLimitModal}
        nextChatMessageRecycleTime={nextChatMessageRecycleTime}
      />
      <UnlockBigModal {...unlockBigModal} />
    </VStack>
  );
};

export default ChatGPT;
