import {
  Tabs as ChakraTabs,
  TabList,
  Tab,
  useTheme,
  Box,
  Alert,
  AlertIcon,
  HStack,
  IconButton,
} from '@chakra-ui/react';
import { css as langCss } from '@codemirror/lang-css';
import { html as langHtml } from '@codemirror/lang-html';
import { javascript as langJavascript } from '@codemirror/lang-javascript';
import { EditorState } from '@codemirror/state';
import { EditorView } from '@codemirror/view';
import CodeMirror from '@uiw/react-codemirror';
import beautify from 'js-beautify';
import { useRef } from 'react';

import { useCurrentUser } from 'auth/CurrentUserProvider';
import GradientTooltip from 'components/GradientTooltip';
import Icon from 'components/Icon';
import Text from 'components/Text';
import { useMediaQueries } from 'layout/MediaQueriesProvider';

import { useDevSandbox } from '../DevSandboxProvider';
import { CODE_FREE_LIMIT, CODE_PAID_LIMIT } from '../constants';
import { TCodeTabName } from '../types';
import { checkCodeLimit } from '../utils';

export const formatCode = (code: string, tabIndex: number) => {
  const options = {
    indent_size: 2,
    space_in_empty_paren: false,
  };

  switch (tabIndex) {
    case 0:
      return beautify.html(code, options);
    case 1:
      return beautify.css(code, options);
    case 2:
      return beautify.js(code, options);
    default:
      return code;
  }
};

interface ICodeEditorTabsProps {
  tabIndex: number;
  onChange: (index: number) => void;
  tabs: string[];
}

const CodeEditorTabs = ({ tabIndex, onChange, tabs }: ICodeEditorTabsProps) => {
  const { opacity } = useTheme();

  return (
    <HStack spacing={4}>
      <ChakraTabs index={tabIndex} onChange={(index) => onChange(index)}>
        <TabList borderBottom="none !important">
          {tabs.map((tab) => (
            <Tab
              key={tab}
              px={8}
              height="42px"
              borderBottom="3px solid"
              borderColor="transparent"
              _selected={{
                backgroundColor: 'whiteAlpha.200',
                borderBottom: '3px solid',
                borderBottomColor: 'purple.200',
              }}
              _hover={{
                backgroundColor: 'whiteAlpha.200',
                borderBottom: '3px solid',
                borderBottomColor: 'purple.200',
              }}
            >
              <Text _hover={{ opacity: tabIndex === 0 ? 1 : opacity.primary }} opacity="default">
                {tab}
              </Text>
            </Tab>
          ))}
        </TabList>
      </ChakraTabs>
    </HStack>
  );
};

interface ICodeAlertProps {
  maxChars: number;
  currentChars: number;
  codeTabName: TCodeTabName;
}

const CodeAlert = ({ maxChars, currentChars, codeTabName }: ICodeAlertProps) => {
  return (
    <Alert
      status="warning"
      position="absolute"
      left={0}
      top={0}
      zIndex={9999999}
      width="100%"
      backgroundColor="yellow.700"
    >
      <AlertIcon />
      Exceeded characters limit ({currentChars}/{maxChars}) for {codeTabName}.
    </Alert>
  );
};

interface ICodeEditorProps {
  isAccordionOpen?: boolean | null;
}

const CodeEditor = ({ isAccordionOpen = false }: ICodeEditorProps) => {
  const htmlEditorRef = useRef<any>(null);
  const cssEditorRef = useRef<any>(null);
  const jsEditorRef = useRef<any>(null);

  const { isSmallScreen } = useMediaQueries();

  const { isFree } = useCurrentUser();

  const {
    codeTabs: { html, css, js, tabNames, tabIndex },
    handlers: {
      onCodeTabIndexChange,
      onHtmlChange,
      onCssChange,
      onJsChange,
      onCodeEditorWidthChange,
      onPreviewWidthChange,
    },
  } = useDevSandbox();

  const codeTabs = tabNames || [];
  const index = tabIndex || 0;

  const isHTMLTab = codeTabs[index] === 'HTML';
  const isCSSTab = codeTabs[index] === 'CSS';
  const isJsTab = codeTabs[index] === 'JS';

  return (
    <Box width="100%" height={isAccordionOpen ? '56%' : '100%'}>
      <HStack width="100%" justifyContent="space-between" spacing={0} overflowX="auto">
        <Box height="42px">
          <CodeEditorTabs
            tabs={codeTabs}
            tabIndex={index}
            onChange={(index) => onCodeTabIndexChange(index)}
          />
        </Box>
        {!isSmallScreen && (
          <GradientTooltip label="Toggle right pane">
            <span>
              <IconButton
                aria-label="Toggle right pane"
                size="sm"
                variant="ghost"
                mr={2}
                onClick={() => {
                  const pane1 = document.querySelector('.BDS-SplitPane-horizontal-0');
                  const pane2 = document.querySelector('.BDS-SplitPane-horizontal-1');

                  const windowWidth = window.innerWidth;

                  if (pane1 && pane2) {
                    // @ts-ignore
                    const currentPane1WidthPercent = (pane1.offsetWidth / windowWidth) * 100;
                    const targetPane1Flex = currentPane1WidthPercent >= 100 ? 50 : 100;
                    const targetPane2Flex = currentPane1WidthPercent >= 100 ? 50 : 0;

                    const targetPane1Width =
                      currentPane1WidthPercent >= 100 ? windowWidth / 2 : windowWidth;
                    const targetPane2Width = currentPane1WidthPercent >= 100 ? windowWidth / 2 : 0;

                    // @ts-ignore
                    pane1.style.width = `${targetPane1Width}px`;
                    // @ts-ignore
                    pane2.style.width = `${targetPane2Width}px`;

                    if (currentPane1WidthPercent < 100) {
                      // @ts-ignore
                      pane1.style.flex = `0 0 ${targetPane1Flex}%`;
                      // @ts-ignore
                      pane2.style.flex = `0 0 ${targetPane2Flex}%`;
                    } else {
                      // Removing flex properties to allow CSS to control layout
                      // @ts-ignore
                      pane1.style.removeProperty('flex');
                      // @ts-ignore
                      pane2.style.removeProperty('flex');
                    }

                    onCodeEditorWidthChange(targetPane1Width);
                    onPreviewWidthChange(targetPane2Width);
                  }
                }}
              >
                <Icon type="CodeEditorResize" size={14} />
              </IconButton>
            </span>
          </GradientTooltip>
        )}
      </HStack>
      <Box height="2px" backgroundColor="#282C34" />
      <Box height="calc(100% - 42px)" backgroundColor="#282C34" position="relative">
        {html && !checkCodeLimit(isFree, html) && isHTMLTab && (
          <CodeAlert
            currentChars={html.length}
            maxChars={isFree ? CODE_FREE_LIMIT : CODE_PAID_LIMIT}
            codeTabName="HTML"
          />
        )}
        {css && !checkCodeLimit(isFree, css) && isCSSTab && (
          <CodeAlert
            currentChars={css.length}
            maxChars={isFree ? CODE_FREE_LIMIT : CODE_PAID_LIMIT}
            codeTabName="CSS"
          />
        )}
        {js && !checkCodeLimit(isFree, js) && isJsTab && (
          <CodeAlert
            currentChars={js.length}
            maxChars={isFree ? CODE_FREE_LIMIT : CODE_PAID_LIMIT}
            codeTabName="JS"
          />
        )}
        <CodeMirror
          value={html}
          ref={htmlEditorRef}
          id="bds-code-editor-html"
          extensions={[
            langHtml({ selfClosingTags: true, autoCloseTags: false }),
            EditorView.lineWrapping,
            EditorState.readOnly.of(false),
            // keymap.of(customKeymap),
          ]}
          theme="dark"
          style={{
            display: isHTMLTab ? 'initial' : 'none',
          }}
          basicSetup={{ foldGutter: false }}
          onChange={isHTMLTab ? onHtmlChange : undefined}
        />
        <CodeMirror
          value={css}
          ref={cssEditorRef}
          id="bds-code-editor-css"
          extensions={[langCss(), EditorView.lineWrapping]}
          theme="dark"
          basicSetup={{ foldGutter: false }}
          style={{
            display: isCSSTab ? 'initial' : 'none',
          }}
          onChange={isCSSTab ? onCssChange : undefined}
        />
        <CodeMirror
          value={js}
          ref={jsEditorRef}
          id="bds-code-editor-js"
          extensions={[
            langJavascript({ jsx: false, typescript: false }),
            // keymap.of(customKeymap),
            EditorView.lineWrapping,
          ]}
          theme="dark"
          style={{
            display: isJsTab ? 'initial' : 'none',
          }}
          basicSetup={{ foldGutter: false }}
          onChange={isJsTab ? onJsChange : undefined}
        />
      </Box>
    </Box>
  );
};

export default CodeEditor;
