import { DISCORD_GUILD_HREF } from 'config/constants/discord';

import { openLinkInNewTab } from './links';

export const openDiscordChannel = (channelId: string) => {
  openLinkInNewTab(`${DISCORD_GUILD_HREF}/${channelId}`);
};

export const openDiscord = () => {
  openLinkInNewTab(`${DISCORD_GUILD_HREF}/${process.env.REACT_APP_DISCORD_GENERAL_CHANNEL_ID}`);
};

export const getDiscordThreadUrl = (threadUrl: string) => {
  return `${DISCORD_GUILD_HREF}/${threadUrl}`;
};
