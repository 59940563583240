import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  Box,
  useTheme,
  HStack,
  useDisclosure,
  PopoverProps,
} from '@chakra-ui/react';
import { ReactNode, forwardRef, useEffect, useImperativeHandle } from 'react';

import Icon, { IconType } from 'components/Icon';
import Text from 'components/Text';
import { useMediaQueries } from 'layout/MediaQueriesProvider';

import GradientTooltip from './GradientTooltip';

interface IGradientPopoverProps extends PopoverProps {
  children: ReactNode;
  triggerComponent: ReactNode;
  header: string | ReactNode | null;
  headerIcon?: IconType;
  width?: string;
  isHover?: boolean;
  noPadding?: boolean;
  onPopoverStateChange?: (isOpen: boolean) => void;
  tooltip?: ReactNode;
  onClickOutside?: () => void;
  popoverContentRef?: React.RefObject<HTMLDivElement>;
}

const GradientPopover = forwardRef(
  (
    {
      triggerComponent,
      header,
      headerIcon,
      children,
      width,
      isHover = false,
      tooltip,
      noPadding,
      onPopoverStateChange,
      defaultIsOpen,
      onClickOutside,
      popoverContentRef,
      id,
      ...props
    }: IGradientPopoverProps,
    ref
  ) => {
    const { borderRadius } = useTheme();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const tooltipState = useDisclosure();
    const { isSmallScreen } = useMediaQueries();

    useEffect(() => {
      if (onPopoverStateChange) {
        onPopoverStateChange(isOpen);
      }
    }, [isOpen, onPopoverStateChange]);

    const handleClose = () => {
      onClose();
      tooltipState.onClose();
      onClickOutside?.();
    };

    useImperativeHandle(ref, () => ({
      onClose: handleClose,
    }));

    useEffect(() => {
      if (defaultIsOpen && id) {
        setTimeout(() => {
          onOpen();
        }, 100);
      }
    }, [defaultIsOpen, onOpen, id]);

    const customWidth = width ? { width: isSmallScreen ? '100vw' : width } : {};

    return (
      <Popover
        placement="bottom-end"
        trigger={isHover ? 'hover' : 'click'}
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={handleClose}
        {...props}
      >
        {tooltip ? (
          <GradientTooltip label={tooltip} isOpen={tooltipState.isOpen && !isOpen}>
            <span onMouseLeave={tooltipState.onClose} onMouseEnter={tooltipState.onOpen}>
              <PopoverTrigger>{triggerComponent}</PopoverTrigger>
            </span>
          </GradientTooltip>
        ) : (
          <PopoverTrigger>{triggerComponent}</PopoverTrigger>
        )}
        <PopoverContent
          ref={popoverContentRef}
          p="2px"
          borderRadius={borderRadius}
          border="none"
          maxH="75vh"
          overflowX="hidden"
          overflowY="auto"
          {...customWidth}
          _before={{
            content: '""',
            position: 'absolute',
            top: '-2px',
            bottom: '-2px',
            left: '-2px',
            right: '-2px',
            background:
              'linear-gradient(360deg, rgba(159, 122, 234, 0.87) 1.16%, rgba(237, 137, 54, 0.87) 95.35%)',
          }}
        >
          <Box h="100%" w="100%" bg="gray.800" position="relative" borderRadius={borderRadius}>
            <PopoverHeader
              py={4}
              px={noPadding ? 0 : 3}
              borderBottom={noPadding ? 'none' : 'initial'}
            >
              {(header || headerIcon) && (
                <HStack align="center" mb={4}>
                  {headerIcon && <Icon type={headerIcon} size={40} />}
                  {header && (
                    <Text fontSize="xLarge" fontWeight="semibold" width="100%">
                      {header}
                    </Text>
                  )}
                </HStack>
              )}
              {children}
            </PopoverHeader>
          </Box>
        </PopoverContent>
      </Popover>
    );
  }
);

export default GradientPopover;
