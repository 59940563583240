import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  supabaseMutationFn,
  useQueryWithErrorBoundary,
  useSupabaseTypedClient,
} from 'hooks/reactQuery';
import { useToastWrapper } from 'hooks/useToastWrapper';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import PageLoader from 'components/PageLoader';
import { CodeEditorType, EdgeFunctionName, RenameCodeParams } from 'types/edgeFunctions';
import { ERoute } from 'types/frontend';

interface ILegacySolutionRedirectProps {
  type: CodeEditorType;
}

// challenge solution: /challenge-solutions/Bloom-Sayiorri/qr-code-scanner
// challenge repo: https://app.bigdevsoon.me/challenge-code-previews/linguistic-chocolate-skink?showCode=true
// challenge preview: https://app.bigdevsoon.me/challenge-code-previews/linguistic-chocolate-skink

// project solution: /solutions/Ed-Mo1/notely-1
// project repo: https://app.bigdevsoon.me/project-code-previews/recent-blue-muskox?showCode=true
// project preview: https://app.bigdevsoon.me/project-code-previews/recent-blue-muskox
const LegacySolutionRedirect = ({ type }: ILegacySolutionRedirectProps) => {
  const supabase = useSupabaseTypedClient();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const { userScreenName = '', challengeScreenName = '', previewUniqueName = '' } = useParams();

  const { toastError } = useToastWrapper();

  const solutionUrl = location.pathname.replace('/comments', '');

  const { data, isPending } = useQueryWithErrorBoundary({
    queryKey: [`${type}-${userScreenName}-${challengeScreenName}-${previewUniqueName}`],
    queryFn: async () => {
      if (type === 'challenge') {
        const userChallenge = await supabase
          .from('user_challenge')
          .select('id, challenge (screen_name), user_profile_view (screen_name)')
          .eq('solution_url', solutionUrl)
          .maybeSingle();

        if (userChallenge.error) throw userChallenge.error;

        return {
          id: userChallenge.data?.id,
          screen_name: userChallenge.data?.challenge?.screen_name,
          user_screen_name: userChallenge.data?.user_profile_view?.screen_name,
        };
      }

      if (type === 'project') {
        const userProject = await supabase
          .from('user_project')
          .select('id, project (screen_name), user_profile_view (screen_name)')
          .eq('solution_url', solutionUrl)
          .maybeSingle();

        if (userProject.error) throw userProject.error;

        return {
          id: userProject.data?.id,
          screen_name: userProject.data?.project?.screen_name,
          user_screen_name: userProject.data?.user_profile_view?.screen_name,
        };
      }

      return null;
    },
  });

  const mutation = useMutation<string, string, RenameCodeParams>({
    mutationFn: supabaseMutationFn(({ entityId, title, type, parentScreenName, userScreenName }) =>
      supabase.functions.invoke<string>(EdgeFunctionName.UpdateLegacySolution, {
        body: { entityId, title, type, parentScreenName, userScreenName } as RenameCodeParams,
      })
    ),
    onSuccess: (solutionUrl: string) => {
      navigate(solutionUrl, { replace: true });

      queryClient.removeQueries({
        queryKey: [`${type}-${userScreenName}-${challengeScreenName}-${previewUniqueName}`],
      });
    },
    onError: () => {
      toastError('Failed to load solution');
      navigate(ERoute.Dashboard);
    },
  });

  useEffect(() => {
    if (data !== null && data !== undefined && !isPending) {
      mutation.mutate({
        entityId: data.id!,
        title: `${data.screen_name!} Solution`,
        type,
        parentScreenName: data.screen_name!,
        userScreenName: data.user_screen_name!,
      });
    }
  }, [data, isPending]);

  return <PageLoader shift />;
};

export default LegacySolutionRedirect;
