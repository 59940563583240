import 'react-multi-carousel/lib/styles.css';

import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import {
  Box,
  Center,
  Container,
  Grid,
  GridItem,
  HStack,
  IconButton,
  Skeleton,
  Stack,
} from '@chakra-ui/react';
import { useQueryClient } from '@tanstack/react-query';
import { useQueryWithErrorBoundary, useSupabaseTypedClient } from 'hooks/reactQuery';
import { useEffect, useState } from 'react';
import Carousel from 'react-multi-carousel';

import { useCurrentUser } from 'auth/CurrentUserProvider';
import Card from 'components/Card';
import CodePreviewCard from 'components/CodePreviewCard';
import { EmptyContent } from 'components/EmptyContent';
import { SEO } from 'components/SEO';
import Tabs from 'components/Tabs';
import { useMediaQueries } from 'layout/MediaQueriesProvider';
import { CodeEditorType } from 'types/edgeFunctions';
import { TanstackQueryName } from 'types/frontend';

import Discord from './Discord';
import Feed from './Feed';
import Leaderboards from './Leaderboards';
import UnlockBIG from './UnlockBIG';

const responsiveCarousel = (dataLength: number) => ({
  desktop: {
    breakpoint: { max: 10000, min: 1536 },
    items: 2,
    partialVisibilityGutter: dataLength === 2 ? 0 : 160,
    slidesToSlide: dataLength === 2 ? 1 : 2,
  },
  tablet: {
    breakpoint: { max: 1536, min: 960 },
    items: 2,
    partialVisibilityGutter: dataLength === 2 ? 0 : 80,
    slidesToSlide: dataLength === 2 ? 1 : 2,
  },
  mobile: {
    breakpoint: { max: 960, min: 0 },
    items: 1,
    slidesToSlide: 1,
  },
});

const Dashboard = () => {
  const { isDesktopNavigation } = useMediaQueries();
  const [tabIndex, setTabIndex] = useState(0);
  const { id: currentUserId, isFree } = useCurrentUser();

  const supabase = useSupabaseTypedClient();
  const queryClient = useQueryClient();

  const { data, isFetching } = useQueryWithErrorBoundary({
    queryKey: [TanstackQueryName.GetUserCode, currentUserId, tabIndex],
    queryFn: async () => {
      const userCodeViewQuery = supabase
        .from('user_code_view')
        .select('*')
        .not('user_id', 'is', null)
        .limit(20);

      if (tabIndex === 0) {
        const oneWeekAgo = new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 7);
        const formattedDate = oneWeekAgo.toLocaleString('en-US', { timeZone: 'UTC' });

        userCodeViewQuery
          .gte('completed_date', formattedDate)
          .order('total_score', { ascending: false })
          .eq('is_private', false)
          .order('completed_date', { ascending: false });
      }

      if (tabIndex === 1) {
        userCodeViewQuery
          .order('total_score', { ascending: false })
          .eq('is_private', false)
          .order('completed_date', { ascending: false });
      }

      if (tabIndex === 2) {
        userCodeViewQuery
          .eq('user_id', currentUserId)
          .order('completed_date', { ascending: false });
      }

      const { data, error } = await userCodeViewQuery;

      if (error) throw error;

      return data;
    },
  });

  useEffect(() => {
    return () => {
      queryClient.removeQueries({
        queryKey: [TanstackQueryName.GetUserCode, currentUserId, 0],
      });
      queryClient.removeQueries({
        queryKey: [TanstackQueryName.GetUserCode, currentUserId, 1],
      });
      queryClient.removeQueries({
        queryKey: [TanstackQueryName.GetUserCode, currentUserId, 2],
      });
    };
  }, []);

  const renderUserCode = () => {
    if (!data || isFetching) {
      if (!isDesktopNavigation) {
        return <Skeleton height="531px" width="100%" />;
      }

      return (
        <HStack spacing={4}>
          <Skeleton height="531px" width="100%" />
          <Skeleton height="531px" width="100%" />
          <Skeleton height="531px" width="50%" />
        </HStack>
      );
    }

    if (data.length === 0) {
      return (
        <Center height="531px">
          <EmptyContent type="dashboard" mt="0px" />
        </Center>
      );
    }

    const getInvalidateKeys = (
      type: CodeEditorType,
      userId: string,
      parentId: string,
      solutionUrl: string
    ) => {
      const baseKeys = [[TanstackQueryName.GetProfileCode, userId, type]];

      if (type === 'project') {
        return [
          ...baseKeys,
          [TanstackQueryName.GetProjectSolutions, parentId],
          [TanstackQueryName.GetProjectSolution, solutionUrl],
        ];
      }

      if (type === 'challenge') {
        return [
          ...baseKeys,
          [TanstackQueryName.GetChallengeSolutions, parentId],
          [TanstackQueryName.GetChallengeSolution, solutionUrl],
        ];
      }

      if (type === 'problem') {
        return [
          ...baseKeys,
          [TanstackQueryName.GetPracticeProblemsSolutions, parentId],
          [TanstackQueryName.GetPracticeProblemSolution, solutionUrl],
        ];
      }

      if (type === 'code-frame') {
        return [
          ...baseKeys,
          [TanstackQueryName.GetCodeFrames, currentUserId],
          [TanstackQueryName.GetCodeFrames, null],
          [TanstackQueryName.GetCodeFrame, solutionUrl],
        ];
      }

      if (type === 'prototype') {
        return [
          ...baseKeys,
          [TanstackQueryName.GetPrototypes, currentUserId],
          [TanstackQueryName.GetPrototypes, null],
          [TanstackQueryName.GetPrototype, solutionUrl],
        ];
      }

      return baseKeys;
    };

    return (
      <Carousel
        responsive={responsiveCarousel(data.length)}
        partialVisible
        swipeable
        arrows
        customLeftArrow={
          <IconButton
            aria-label="Swipe left"
            width="48px"
            height="64px"
            backgroundColor="gray.400"
            position="absolute"
            top="50%"
            left="32px"
            _hover={{ backgroundColor: 'gray.500' }}
          >
            <ChevronLeftIcon boxSize={8} color="black" />
          </IconButton>
        }
        customRightArrow={
          <IconButton
            aria-label="Swipe right"
            width="48px"
            height="64px"
            backgroundColor="gray.400"
            position="absolute"
            top="50%"
            right={isDesktopNavigation ? '16px' : '32px'}
            _hover={{ backgroundColor: 'gray.500' }}
          >
            <ChevronRightIcon boxSize={8} color="black" />
          </IconButton>
        }
      >
        {data.map((userCode) => (
          <Box key={userCode.id} mr={4} height="100%">
            <CodePreviewCard
              width={`calc(100% + ${isDesktopNavigation ? '0px' : '16px'})`}
              height="300px"
              isMySolution={false}
              canEdit={false}
              queryKey={[TanstackQueryName.GetUserCode, currentUserId, tabIndex]}
              invalidateKeys={getInvalidateKeys(
                userCode.type as CodeEditorType,
                userCode.user_id!,
                userCode.parent_id!,
                userCode.solution_url!
              )}
              {...userCode}
            />
          </Box>
        ))}
      </Carousel>
    );
  };

  return (
    <>
      <SEO
        title="BigDevSoon | Dashboard"
        description="Browse user's creations, community feed, and leaderboards."
        url={`${process.env.REACT_APP_SITE_URL}Dashboard`}
      />
      <Container maxW="1400px" pt="28px">
        <Grid templateColumns="repeat(12, 1fr)" gap={4}>
          <GridItem colSpan={12}>
            <Card
              header={
                <Tabs
                  tabs={['Trending', 'Most Popular', 'Your Work']}
                  tabIndex={tabIndex}
                  onChange={(index) => setTabIndex(index)}
                />
              }
              position="relative"
              width="100%"
            >
              {renderUserCode()}
            </Card>
          </GridItem>
          <GridItem colSpan={[12, 12, 12, 12, 8]}>
            <Feed />
          </GridItem>
          <GridItem colSpan={[12, 12, 12, 12, 4]}>
            <Stack spacing={4} alignItems="center">
              {isFree && <UnlockBIG />}
              <Leaderboards />
              <Discord />
            </Stack>
          </GridItem>
        </Grid>
      </Container>
    </>
  );
};

export default Dashboard;
