import { ChevronRightIcon } from '@chakra-ui/icons';
import {
  Box,
  Divider,
  HStack,
  IconButton,
  VStack,
  useDisclosure,
  useTheme,
} from '@chakra-ui/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { supabaseMutationFn, useSupabaseTypedClient } from 'hooks/reactQuery';
import { getCodeStorageKey, useCodeStorage } from 'hooks/useCodeStorage';
import { useExitAlert } from 'hooks/useExitAlert';
import { useFigmaDownload } from 'hooks/useFigmaDownload';
import { useToastWrapper } from 'hooks/useToastWrapper';
import { useEffect, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useAuth } from 'auth/AuthProvider';
import { useCurrentUser } from 'auth/CurrentUserProvider';
import Button from 'components/Button';
import CardIcon from 'components/CardIcon';
import DevSandbox from 'components/DevSandbox';
import { formatCode } from 'components/DevSandbox/CodeEditor/CodeEditor';
import GenerateCodeSolutionModal from 'components/DevSandbox/GenerateCodeSolutionModal';
import BrowserTab from 'components/DevSandbox/PaneTabs/BrowserTab';
import ChatGPT from 'components/DevSandbox/PaneTabs/ChatGPT';
import DiscussionTab from 'components/DevSandbox/PaneTabs/Discussion/DiscussionTab';
import RightSideActions from 'components/DevSandbox/RightSideActions';
import {
  colorsPalette,
  designScreenshot,
  fonts,
  icons,
  resources,
} from 'components/DevSandbox/quickButtons';
import { HTML_LEARN_TEMPLATE } from 'components/DevSandbox/templates';
import { TDevSandboxFooterQuickButton } from 'components/DevSandbox/types';
import GradientTooltip from 'components/GradientTooltip';
import Icon from 'components/Icon';
import { UnlockFigmaModal } from 'components/Modal/UnlockFigmaModal';
import ProjectElement from 'components/ProjectElement';
import { ProtectedElement } from 'components/ProtectedElement';
import Tags from 'components/Tags';
import Text from 'components/Text';
import {
  DifficultyMarker,
  StatusIconMapper,
  StatusTextMapper,
} from 'features/practice-problems/PracticeProblems';
import {
  Support_Type_Enum,
  User_Card_Status_Enum,
  User_Project_Status_Enum,
} from 'types/databaseEnums';
import { AskChatGptParams, EdgeFunctionName, Project, ProjectCard } from 'types/edgeFunctions';
import { ERoute, TanstackQueryName } from 'types/frontend';
import { mapExpToProject } from 'utils/constants';
import { hasFirstCardAccessOnly } from 'utils/user';

import CardDrawer from './CardDrawer';
import CompleteCardCheckbox from './CompleteCardCheckbox';
import ProjectsDrawer from './ProjectsDrawer';

interface IProjectPlayground {
  project: Project;
}

const ProjectPlayground = ({ project }: IProjectPlayground) => {
  const [codeFrameUrl, setCodeFrameUrl] = useState<string>('');
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [colorPaletteQuickButton, setColorPaletteQuickButton] =
    useState<TDevSandboxFooterQuickButton | null>(null);
  const loadTimeoutRef = useRef<any | null>(null);

  const { id: userId, isFree, createdAt } = useCurrentUser();
  const { userId: authId } = useAuth();
  const [searchParams] = useSearchParams();

  const [currentCard, setCurrentCard] = useState<ProjectCard | null>(null);

  const { toastSuccess, toastError } = useToastWrapper();

  const [generatedSolutionCode, setGeneratedSolutionCode] = useCodeStorage({
    key: getCodeStorageKey({
      type: 'project',
      screenName: project.screen_name,
      userId,
      code: 'generated-solution-code',
    }),
  });

  const { isDownloadingFigmaDesign, onFigmaDownload } = useFigmaDownload({
    screenName: project.screen_name,
    type: 'project',
    entityId: project.id,
  });

  useEffect(() => {
    const fetchColorPalette = async () => {
      const colorPalette = await colorsPalette({
        screenshotUrl: project.design_screenshot.url,
        toast: toastSuccess,
      });

      setColorPaletteQuickButton(colorPalette);
    };

    fetchColorPalette();
  }, [project.design_screenshot.url]);

  useEffect(() => {
    loadTimeoutRef.current = setTimeout(() => {
      setIsLoaded(true);
    }, 500);

    return () => {
      clearTimeout(loadTimeoutRef.current);
    };
  }, []);

  const handleCardClick = (card: ProjectCard) => {
    setCurrentCard(card);
    setTimeout(() => {
      cardDrawer.onOpen();
    }, 50);
  };

  const codeEditorRef = useRef<any>(null);

  const navigate = useNavigate();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const figmaModal = useDisclosure();
  const cardDrawer = useDisclosure();

  const { borderRadius } = useTheme();

  const supabase = useSupabaseTypedClient();
  const queryClient = useQueryClient();

  const generateCodeMutation = useMutation<string, string, AskChatGptParams>({
    mutationFn: supabaseMutationFn(({ entityId, type }) =>
      supabase.functions.invoke<string>(EdgeFunctionName.AskChatGpt, {
        body: {
          entityId,
          type,
        } as AskChatGptParams,
      })
    ),
    onSuccess: (data) => {
      setGeneratedSolutionCode(data);
      queryClient.invalidateQueries({ queryKey: [TanstackQueryName.GetCurrentUser, userId] });
      toastSuccess('Code solution generated successfully.');
    },
    onError: () => {
      toastError('Could not generate solution, try again later.');
    },
  });

  useExitAlert({
    shouldShowBrowserExitAlert: generateCodeMutation.isPending,
  });

  const hasTabInUrl =
    location.pathname.includes('browser') ||
    location.pathname.includes('cards') ||
    location.pathname.includes('ai-buddy') ||
    location.pathname.includes('discussions') ||
    location.pathname.includes('solutions');
  const tabScreenName = location.pathname.split('/').pop();
  const browserUrl = hasTabInUrl
    ? window.location.href.replace(`/${tabScreenName}`, '')
    : window.location.href;

  const searchParamsMode = searchParams.get('mode');
  const mode = searchParamsMode === 'full-page' ? 'full-page' : 'interactive';

  const completedCardsCount =
    project.cards.filter((card) => card.status === User_Card_Status_Enum.Completed).length || 0;

  const isFreeWithoutCards = !authId || (isFree && hasFirstCardAccessOnly(createdAt));

  return (
    <>
      <DevSandbox
        ref={codeEditorRef}
        id={`project-${project.screen_name}-${userId}`}
        oldKey={project.preview_url}
        browserUrl={browserUrl}
        title={project.title}
        mode={mode}
        navigation={{
          leftSide: <ProjectsDrawer />,
          header: (
            <HStack spacing={4}>
              <GradientTooltip
                label={
                  generateCodeMutation.isPending
                    ? 'Code is generating...'
                    : generatedSolutionCode
                    ? 'Open generated code'
                    : 'Generate code solution'
                }
              >
                <IconButton
                  aria-label="Generate code solution for this project"
                  onClick={onOpen}
                  colorScheme={generatedSolutionCode ? 'green' : 'gray'}
                  backgroundColor={generatedSolutionCode ? 'green.400' : 'default'}
                  isLoading={generateCodeMutation.isPending}
                >
                  <Icon type="GenerateCodeSolution" />
                </IconButton>
              </GradientTooltip>
              <ProtectedElement
                scheme="gradient"
                leftIcon={<Icon type="SubmitCode" />}
                onClick={() => {
                  navigate('submit');
                }}
              >
                Submit
              </ProtectedElement>
            </HStack>
          ),
          rightSide: <RightSideActions />,
        }}
        codeTabs={{
          html: formatCode(
            HTML_LEARN_TEMPLATE(
              `https://github.com/elise-bigdevsoon/big-dev-soon-${project.screen_name}`,
              project.title,
              'project'
            ),
            0
          ),
          css: '',
          js: '',
        }}
        callbacks={{
          onCodeFrameUrlChange: (codeFrameUrl) => {
            setCodeFrameUrl(codeFrameUrl);
          },
        }}
        paneTabs={{
          tabs: [
            {
              screenName: 'browser',
              title: 'Browser',
              icon: <Icon type="Browser" size={16} />,
              content: (
                <BrowserTab
                  browserUrl={browserUrl}
                  codeFrameUrl={codeFrameUrl}
                  onCodeFrameUrlChange={codeEditorRef.current?.onCodeFrameUrlChange}
                />
              ),
            },
            {
              screenName: 'cards',
              title: `Cards (${completedCardsCount}/${project.cards.length})`,
              icon: <Icon type="CardsTab" size={16} />,
              content: (
                <VStack alignItems="flex-start" p={4} spacing={4}>
                  <VStack alignItems="flex-start" width="100%" spacing={4}>
                    <Tags tags={project.tags} />
                    <HStack justifyContent="space-between" alignItems="center" width="100%" pr={2}>
                      <VStack alignItems="flex-start">
                        <HStack>
                          <Text fontSize="xxLarge" fontWeight="bold">
                            {project.title}
                          </Text>
                          <ProjectElement
                            type={
                              project.status === User_Project_Status_Enum.Completed
                                ? 'experienceCompleted'
                                : 'experience'
                            }
                            experience={mapExpToProject[project.difficulty_lvl]}
                          />
                        </HStack>
                      </VStack>
                      <HStack>
                        {StatusIconMapper[project.status]}
                        <Text fontSize="small">{StatusTextMapper[project.status]}</Text>
                      </HStack>
                    </HStack>
                    <Text fontWeight="regular">{project.description}</Text>
                    <DifficultyMarker difficulty={project.difficulty_lvl} />
                  </VStack>
                  <Divider my={2} />
                  <VStack alignItems="flex-start" width="100%">
                    <HStack>
                      <Icon type="Cards" />
                      <Text fontSize="xLarge">Cards</Text>
                    </HStack>
                    <Text fontWeight="regular" opacity="secondary">
                      Organize and track your project tasks effectively using cards.
                    </Text>
                  </VStack>
                  <Box position="relative" width="100%">
                    {isFreeWithoutCards && authId && (
                      <Box
                        position="absolute"
                        left="50%"
                        top="30%"
                        transform="translate(-50%, -30%)"
                        minW="330px"
                      >
                        <VStack
                          spacing={4}
                          p={4}
                          borderRadius="md"
                          backgroundColor="gray.700"
                          boxShadow="0px 10px 20px 0px rgba(0, 0, 0, 0.15)"
                        >
                          <Text fontSize="large" fontWeight="semibold">
                            Upgrade plan to unlock all cards
                          </Text>
                          <Button
                            scheme="gradient"
                            to={ERoute.Pricing}
                            leftIcon={<Icon type="Rocket" />}
                          >
                            Unlock BIG
                          </Button>
                        </VStack>
                      </Box>
                    )}
                    <VStack spacing={4} width="100%" alignItems="flex-start">
                      {project.cards.map((card, index) => {
                        const isFirstCard = index === 0;
                        const isCardAccessible = isFirstCard || !isFreeWithoutCards;

                        return (
                          <Box
                            key={card.id}
                            backgroundColor="whiteAlpha.200"
                            borderRadius={borderRadius}
                            p={4}
                            width="100%"
                            transition="all 0.2s ease-out"
                            cursor={isCardAccessible ? 'pointer' : 'default'}
                            _hover={isCardAccessible ? { backgroundColor: 'whiteAlpha.100' } : {}}
                            style={{
                              filter: isCardAccessible ? 'none' : 'blur(4px)',
                              pointerEvents: isCardAccessible ? 'auto' : 'none',
                            }}
                            onClick={() => isCardAccessible && handleCardClick(card)}
                            position="relative"
                          >
                            <HStack width="100%" justifyContent="space-between">
                              <HStack spacing={6}>
                                <CompleteCardCheckbox
                                  cardId={card.id}
                                  isCompleted={card.status === User_Card_Status_Enum.Completed}
                                  isProjectCompleted={
                                    project.status === User_Project_Status_Enum.Completed
                                  }
                                  projectScreenName={project.screen_name}
                                  cards={project.cards}
                                  userProjectId={project.user_project_id}
                                />
                                <HStack spacing={3}>
                                  <CardIcon type={card.type} size="md" />
                                  <Text fontSize="large">
                                    {index + 1}. {card.title}
                                  </Text>
                                </HStack>
                              </HStack>
                              <ChevronRightIcon boxSize={6} />
                            </HStack>
                          </Box>
                        );
                      })}
                    </VStack>
                  </Box>
                </VStack>
              ),
            },
            {
              screenName: 'ai-buddy',
              title: 'AI Buddy',
              icon: <Icon type="CodeChatGpt" size={16} />,
              content: (
                <ChatGPT
                  chatContext={generateProjectPrompt(
                    `https://github.com/elise-bigdevsoon/big-dev-soon-${project.screen_name}`,
                    project.title,
                    project.description,
                    project.cards.map((card) => card.title).join(', '),
                    project.design_screenshot.url
                  )}
                />
              ),
            },
            {
              screenName: 'discussions',
              title: `${
                project.discussion_count > 0
                  ? `Discussions (${project.discussion_count})`
                  : 'Discussions'
              }`,
              icon: <Icon type="Discussion" size={16} />,
              content: (
                <DiscussionTab
                  entityId={project.id}
                  type={Support_Type_Enum.Question}
                  refetch={() => {
                    queryClient.invalidateQueries({
                      queryKey: [TanstackQueryName.GetProject, project.screen_name],
                    });
                  }}
                />
              ),
            },
          ],
        }}
        footer={{
          rightSide: [
            designScreenshot({
              screenshotUrl: project.design_screenshot.url,
              title: `${project.title} Project`,
              type: 'project',
              isFreeUser: isFree,
              isDownloadingFigma: isDownloadingFigmaDesign,
              onFigmaDownload: () => {
                if (project.is_figma_downloaded) {
                  onFigmaDownload();

                  return;
                }
                if (isFree) {
                  figmaModal.onOpen();

                  return;
                }

                onFigmaDownload();
              },
              autoOpen:
                !isLoaded &&
                window.location.pathname.includes('browser') &&
                project.status !== User_Project_Status_Enum.Completed,
            }),
            resources({
              isFreeUser: isFree,
              githubLink: `https://github.com/elise-bigdevsoon/big-dev-soon-${project.screen_name}`,
              glitchLink: (project.glitch_url || '').replace(
                '/big-dev-soon',
                '/remix/big-dev-soon'
              ),
              isDownloadingFigma: isDownloadingFigmaDesign,
              onFigmaDownload: () => {
                if (project.is_figma_downloaded) {
                  onFigmaDownload();

                  return;
                }
                if (isFree) {
                  figmaModal.onOpen();

                  return;
                }

                onFigmaDownload();
              },
              type: 'project',
            }),
            icons(),
            fonts(),
            ...(colorPaletteQuickButton ? [colorPaletteQuickButton] : []),
          ],
        }}
      />
      <GenerateCodeSolutionModal
        name="project"
        language="html"
        code={generatedSolutionCode}
        isOpen={isOpen}
        onClose={onClose}
        isLoading={generateCodeMutation.isPending}
        onGenerate={() =>
          generateCodeMutation.mutate({
            entityId: project.id,
            type: 'generate-project-solution',
          })
        }
      />
      <CardDrawer
        project={project}
        currentCard={currentCard}
        isOpen={cardDrawer.isOpen}
        onClose={cardDrawer.onClose}
        onCurrentCardChange={setCurrentCard}
      />
      <UnlockFigmaModal
        isOpen={figmaModal.isOpen}
        onClose={figmaModal.onClose}
        tokens={3}
        isDownloading={isDownloadingFigmaDesign}
        onDownload={onFigmaDownload}
      />
    </>
  );
};

export default ProjectPlayground;

const generateProjectPrompt = (
  documentationUrl: string,
  projectTitle: string,
  projectDescription: string,
  cardTitles: string,
  designScreenshotUrl: string
) => `
You are an expert in web development, skilled in creating interactive and responsive web designs using HTML, CSS (including TailwindCSS), and JavaScript. The user is working on a project named "${projectTitle}" which involves multiple tasks structured like a Kanban board. Each task, or card, is part of a larger goal to build a comprehensive application. Your role is to assist them by:

- Providing guidance based on the prompts they use or messages they send to you.
- Offering code snippets or partial solutions in HTML, CSS (including TailwindCSS), and JavaScript without providing the complete solution.
- Giving advice on best practices for web development, including API integration, data storage, animations, and backend setup.
- Debugging and reviewing code when users share specific snippets.
- Encouraging the user to think through the design and come up with their own solutions.
- Keeping in mind that you only have access to the code snippets they share with you.
- IMPORTANT: Do not provide a full solution to the project, involve the user in thinking and brainstorming instead.
- VERY IMPORTANT: When applicable please link below GitHub Documentation URL as a reference so user can get a high-level overview of the project.

### Project Overview
- **GitHub Documentation URL**: ${documentationUrl}
- **Title**: ${projectTitle}
- **Description**: ${projectDescription}

### Project Tasks (Cards)
- **All card titles**: ${cardTitles}

### DESIGN SCREENSHOT FOR REFERENCE: ${designScreenshotUrl}


Please respond with clear, actionable advice, partial code solutions, and insights. Do not provide complete solutions. Return code with markdown for syntax highlighting. Assume users have basic familiarity with web development concepts.
`;
