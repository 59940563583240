import { ChevronDownIcon } from '@chakra-ui/icons';
import { Box, Divider, HStack, VStack, useTheme } from '@chakra-ui/react';
import { useSupabaseTypedClient } from 'hooks/reactQuery';
import { useToastWrapper } from 'hooks/useToastWrapper';
import { useState } from 'react';

import { useAuth } from 'auth/AuthProvider';
import Button from 'components/Button';
import GitHubButton from 'components/GitHubButton';
import Icon from 'components/Icon';
import { ProtectedElement } from 'components/ProtectedElement';
import Text from 'components/Text';
import { plans } from 'config/constants/plans';
import { useMediaQueries } from 'layout/MediaQueriesProvider';
import { Plan_Type_Enum } from 'types/databaseEnums';
import { EdgeFunctionName, SubscribeParams } from 'types/edgeFunctions';
import { ERoute } from 'types/frontend';

export interface PlanBoxProps {
  plan: Plan_Type_Enum;
  basePriceSpot?: number;
  priceSpot?: number;
  discount?: number;
  customInfoBox?: React.ReactNode;
  isPPPActive?: boolean;
  border?: 'normal' | 'fancy';
  onClick?: () => void;
}

const PlanBox = ({
  plan,
  priceSpot,
  discount,
  customInfoBox,
  isPPPActive,
  basePriceSpot,
  border = 'normal',
  onClick,
}: PlanBoxProps) => {
  const { userId: authId } = useAuth();
  const { isSmallScreen } = useMediaQueries();
  const [isProcessing, setIsProcessing] = useState(false);
  const { toastError } = useToastWrapper();
  const { borderRadius } = useTheme();
  const supabaseClient = useSupabaseTypedClient();

  const planDetails = plans[plan];

  const handleOnClick = async () => {
    onClick?.();
    setIsProcessing(true);

    const { data, error } = await supabaseClient.functions.invoke<string>(
      EdgeFunctionName.Subscribe,
      {
        body: {
          plan,
          isPPP: isPPPActive,
        } as SubscribeParams,
      }
    );

    if (error || !data) {
      toastError(error?.message || 'Something went wrong');
      setIsProcessing(false);
      return;
    }

    setIsProcessing(false);
    window.location.replace(data);
  };

  const renderButtons = () => {
    if (!authId) {
      return (
        <GitHubButton
          text="Start for FREE"
          position="absolute"
          bottom="34px"
          left="50%"
          px={6}
          w="180px"
          transform="translateX(-50%)"
        />
      );
    }
    if (plan === Plan_Type_Enum.Free) {
      return (
        <Button
          position="absolute"
          bottom="34px"
          left="50%"
          transform="translateX(-50%)"
          to={`${ERoute.Projects}`}
          scheme="outline"
          mt={4}
          px={6}
          height="48px"
        >
          Browse projects
        </Button>
      );
    }

    const hoverProps =
      plan === Plan_Type_Enum.Lifetime
        ? { backgroundColor: 'cyan.300' }
        : plan === Plan_Type_Enum.Yearly
        ? { backgroundColor: 'purple.300' }
        : { backgroundColor: 'orange.300' };

    return (
      <ProtectedElement
        onClick={handleOnClick}
        bottom="34px"
        position="absolute"
        left="50%"
        margin="auto"
        minW="180px"
        transform="translateX(-50%)"
        bgColor={planDetails.color}
        isLoading={isProcessing}
        height="48px"
        px={6}
        _hover={hoverProps}
      >
        Get {planDetails.title}
      </ProtectedElement>
    );
  };

  const price = (isPPPActive ? priceSpot : basePriceSpot)?.toFixed(2).replace(/\.00$/, '');

  return (
    <VStack
      w="100%"
      height="100%"
      boxShadow="0px 10px 20px 0px rgba(0, 0, 0, 0.15)"
      position="relative"
    >
      <Box
        borderWidth={3}
        h="100%"
        w="100%"
        borderColor={border === 'fancy' ? '#3FE8FF' : 'inherit'}
        minH={isSmallScreen ? '400px' : '440px'}
        bg="gray.800"
        position="relative"
        borderRadius={borderRadius}
        px={6}
        py={2}
      >
        <VStack mt={6} spacing={0} width="100%" height="100%">
          <HStack
            filter="drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.15))"
            alignItems="center"
            justifyContent="space-between"
            width="100%"
            mb={4}
          >
            <HStack>
              <Icon type={planDetails.icon} size={32} />
              <Text fontSize="xxLarge" fontWeight="semibold">
                {planDetails.title}
              </Text>
              {planDetails.badge}
            </HStack>
            {isPPPActive && (
              <Box background={'purple.600'} p={1} borderRadius={borderRadius}>
                <Text fontSize="small" fontWeight="semibold">
                  -{discount}%
                </Text>
              </Box>
            )}
          </HStack>
          <HStack alignItems="center">
            {isPPPActive && (
              <HStack spacing={1} position="relative" width="auto">
                <Text fontSize="xLarge" fontWeight="bold" pt={0} textColor="whiteAlpha.500">
                  ${basePriceSpot}
                </Text>
                <Box
                  position="absolute"
                  height="1px"
                  bgColor="whiteAlpha.500"
                  width="100%"
                  transform="translateY(-50%)"
                />
              </HStack>
            )}
            <HStack spacing={1}>
              <Text fontSize="xxxxxLarge" fontWeight="bold" pt={0}>
                ${price}
              </Text>
              {plan !== Plan_Type_Enum.Lifetime && (
                <Text fontSize="small" fontWeight="regular" opacity="secondary">
                  /month
                </Text>
              )}
            </HStack>
          </HStack>
          {customInfoBox ?? planDetails.info(price!)}
          <Divider width="100%" mt={6} mb={4} />
          <Text my={2} fontWeight="regular" textAlign="center">
            {planDetails.description}
          </Text>
          {plan !== Plan_Type_Enum.Free && (
            <HStack
              position="absolute"
              bottom="110px"
              cursor="pointer"
              onClick={() => {
                const element = document.getElementById('bds-features-list');

                if (element) {
                  element.scrollIntoView({ behavior: 'smooth', block: 'start' });
                }
              }}
            >
              <Text fontWeight="semibold" textDecoration="underline">
                See all benefits
              </Text>
              <ChevronDownIcon boxSize={5} />
            </HStack>
          )}
          {renderButtons()}
        </VStack>
      </Box>
    </VStack>
  );
};

export default PlanBox;
