import { VStack } from '@chakra-ui/layout';
import { useQueryClient } from '@tanstack/react-query';
import { useSupabaseTypedClient } from 'hooks/reactQuery';
import { useToastWrapper } from 'hooks/useToastWrapper';
import { useState } from 'react';

import { useCurrentUser } from 'auth/CurrentUserProvider';
import Button from 'components/Button';
import Modal from 'components/Modal';
import Text from 'components/Text';
import { EdgeFunctionName } from 'types/edgeFunctions';
import { IModal, TanstackQueryName } from 'types/frontend';

interface IDiscordDetachModal extends IModal {}

export const DiscordDetachModal = ({ isOpen, onClose }: IDiscordDetachModal) => {
  const [isLoading, setIsLoading] = useState(false);

  const { id: userId } = useCurrentUser();
  const supabase = useSupabaseTypedClient();
  const queryClient = useQueryClient();

  const { toastSuccess, toastError } = useToastWrapper();

  const detachDiscord = async () => {
    try {
      setIsLoading(true);

      const { error } = await supabase.functions.invoke(EdgeFunctionName.DiscordDetach, {
        body: {
          returnUrl: window.location.pathname,
        },
      });

      if (error) {
        throw new Error(error);
      }

      await queryClient.invalidateQueries({ queryKey: [TanstackQueryName.GetCurrentUser, userId] });

      toastSuccess(`Discord detached`);
      onClose();
    } catch {
      toastError(`Can't detach Discord`);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={detachDiscord}
      header="Disconnecting Discord account"
      footer={
        <>
          <Button scheme="ghost" onClick={onClose}>
            Close
          </Button>
          <Button type="submit" isLoading={isLoading}>
            Confirm
          </Button>
        </>
      }
    >
      <VStack align="flex-start" spacing={5}>
        <Text fontWeight="regular">
          Your Discord account will be disconnected from the BigDevSoon and you will be signed out
          from the Discord server.
        </Text>
        <Text fontWeight="regular">
          You can attach your account again but you need to re-grant access.
        </Text>
      </VStack>
    </Modal>
  );
};
