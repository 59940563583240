import {
  HStack,
  IconButton,
  ListItem,
  UnorderedList,
  VStack,
  useDisclosure,
} from '@chakra-ui/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { supabaseMutationFn, useSupabaseTypedClient } from 'hooks/reactQuery';
import { getCodeStorageKey, useCodeStorage } from 'hooks/useCodeStorage';
import { useExitAlert } from 'hooks/useExitAlert';
import { useFigmaDownload } from 'hooks/useFigmaDownload';
import { useToastWrapper } from 'hooks/useToastWrapper';
import { useEffect, useRef, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useCurrentUser } from 'auth/CurrentUserProvider';
import DevSandbox from 'components/DevSandbox';
import { formatCode } from 'components/DevSandbox/CodeEditor/CodeEditor';
import GenerateCodeSolutionModal from 'components/DevSandbox/GenerateCodeSolutionModal';
import BrowserTab from 'components/DevSandbox/PaneTabs/BrowserTab';
import ChatGPT from 'components/DevSandbox/PaneTabs/ChatGPT';
import DiscussionTab from 'components/DevSandbox/PaneTabs/Discussion/DiscussionTab';
import RightSideActions from 'components/DevSandbox/RightSideActions';
import {
  colorsPalette,
  designScreenshot,
  fonts,
  icons,
  resources,
} from 'components/DevSandbox/quickButtons';
import { HTML_LEARN_TEMPLATE } from 'components/DevSandbox/templates';
import { TDevSandboxFooterQuickButton } from 'components/DevSandbox/types';
import GradientTooltip from 'components/GradientTooltip';
import Icon from 'components/Icon';
import Link from 'components/Link';
import { UnlockFigmaModal } from 'components/Modal/UnlockFigmaModal';
import ProjectElement from 'components/ProjectElement';
import { ProtectedElement } from 'components/ProtectedElement';
import Text from 'components/Text';
import { StatusIconMapper, StatusTextMapper } from 'features/practice-problems/PracticeProblems';
import { Support_Type_Enum, User_Challenge_Status_Enum } from 'types/databaseEnums';
import { EdgeFunctionName, Challenge, AskChatGptParams } from 'types/edgeFunctions';
import { TanstackQueryName } from 'types/frontend';

import ChallengesDrawer from './ChallengesDrawer';

interface IChallengePlaygroundProps {
  challenge: Challenge;
}

const ChallengePlayground = ({
  challenge: {
    id,
    is_figma_downloaded,
    title,
    status,
    preview_url,
    screen_name,
    screenshot_url,
    discussion_count,
  },
}: IChallengePlaygroundProps) => {
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [codeFrameUrl, setCodeFrameUrl] = useState<string>('');
  const [colorPaletteQuickButton, setColorPaletteQuickButton] =
    useState<TDevSandboxFooterQuickButton | null>(null);
  const loadTimeoutRef = useRef<any | null>(null);

  const { id: userId, isFree } = useCurrentUser();
  const [searchParams] = useSearchParams();

  const [generatedSolutionCode, setGeneratedSolutionCode] = useCodeStorage({
    key: getCodeStorageKey({
      type: 'challenge',
      screenName: screen_name,
      userId,
      code: 'generated-solution-code',
    }),
  });

  const { isDownloadingFigmaDesign, onFigmaDownload } = useFigmaDownload({
    screenName: screen_name,
    type: 'challenge',
    entityId: id,
  });

  useEffect(() => {
    loadTimeoutRef.current = setTimeout(() => {
      setIsLoaded(true);
    }, 500);

    return () => {
      clearTimeout(loadTimeoutRef.current);
    };
  }, []);

  useEffect(() => {
    const fetchColorPalette = async () => {
      const colorPalette = await colorsPalette({
        screenshotUrl: screenshot_url,
        toast: toastSuccess,
      });

      setColorPaletteQuickButton(colorPalette);
    };

    fetchColorPalette();
  }, [screenshot_url]);

  const codeEditorRef = useRef<any>(null);

  const supabase = useSupabaseTypedClient();
  const queryClient = useQueryClient();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const figmaModal = useDisclosure();

  const navigate = useNavigate();

  const { toastSuccess, toastError } = useToastWrapper();

  const mutation = useMutation<string, string, AskChatGptParams>({
    mutationFn: supabaseMutationFn(({ entityId, type }) =>
      supabase.functions.invoke<string>(EdgeFunctionName.AskChatGpt, {
        body: {
          entityId,
          type,
        } as AskChatGptParams,
      })
    ),
    onSuccess: (data) => {
      setGeneratedSolutionCode(data);
      queryClient.invalidateQueries({ queryKey: [TanstackQueryName.GetCurrentUser, userId] });
      toastSuccess('Code solution generated successfully.');
    },
    onError: () => {
      toastError('Could not generate solution, try again later.');
    },
  });

  useExitAlert({
    shouldShowBrowserExitAlert: mutation.isPending,
  });

  const hasTabInUrl =
    location.pathname.includes('browser') ||
    location.pathname.includes('instructions') ||
    location.pathname.includes('ai-buddy') ||
    location.pathname.includes('discussions');
  const tabScreenName = location.pathname.split('/').pop();
  const browserUrl = hasTabInUrl
    ? window.location.href.replace(`/${tabScreenName}`, '')
    : window.location.href;

  const searchParamsMode = searchParams.get('mode');
  const mode = searchParamsMode === 'full-page' ? 'full-page' : 'interactive';

  const markdownInstructions = `
  # User Story
  As a user, I want to create a solution that reflects the given design, adding unique interactivity and flair to enhance the standard layout.

  ## Acceptance Criteria
  - The solution accurately mirrors the layout and style of the provided screenshot using HTML, CSS, and JavaScript.
  - Enhance the solution with interactivity to improve the user experience beyond the static design.
  - Ensure the implementation is functional and of high quality.
  - Use the assets available in this Code Editor, including starter files, Figma design, etc.
  - Discuss with the community, review their solutions, and leverage AI-powered actions (chatting, generating solutions, etc.).
  - Aim to complete the challenge without perfection and with your unique variation.
  `;

  return (
    <>
      <DevSandbox
        ref={codeEditorRef}
        id={`challenge-${screen_name}-${userId}`}
        oldKey={preview_url}
        browserUrl={browserUrl}
        title={title}
        mode={mode}
        navigation={{
          leftSide: <ChallengesDrawer />,
          header: (
            <HStack spacing={4}>
              <GradientTooltip
                label={
                  mutation.isPending
                    ? 'Code is generating...'
                    : generatedSolutionCode
                    ? 'Open generated code'
                    : 'Generate code solution'
                }
              >
                <IconButton
                  aria-label="Generate code solution for this challenge"
                  onClick={onOpen}
                  colorScheme={generatedSolutionCode ? 'green' : 'gray'}
                  backgroundColor={generatedSolutionCode ? 'green.400' : 'default'}
                  isLoading={mutation.isPending}
                >
                  <Icon type="GenerateCodeSolution" />
                </IconButton>
              </GradientTooltip>
              <ProtectedElement
                scheme="gradient"
                leftIcon={<Icon type="SubmitCode" />}
                onClick={() => {
                  navigate('submit');
                }}
              >
                Submit
              </ProtectedElement>
            </HStack>
          ),
          rightSide: <RightSideActions />,
        }}
        codeTabs={{
          html: formatCode(
            HTML_LEARN_TEMPLATE(
              'https://github.com/elise-bigdevsoon/big-dev-soon-challenge-template',
              title,
              'challenge'
            ),
            0
          ),
          css: '',
          js: '',
        }}
        callbacks={{
          onCodeFrameUrlChange: (codeFrameUrl) => {
            setCodeFrameUrl(codeFrameUrl);
          },
        }}
        paneTabs={{
          tabs: [
            {
              screenName: 'browser',
              title: 'Browser',
              icon: <Icon type="Browser" size={16} />,
              content: (
                <BrowserTab
                  browserUrl={browserUrl}
                  codeFrameUrl={codeFrameUrl}
                  onCodeFrameUrlChange={codeEditorRef.current?.onCodeFrameUrlChange}
                />
              ),
            },
            {
              screenName: 'instructions',
              title: 'Instructions',
              icon: <Icon type="CodeInstructions" size={16} />,
              content: (
                <VStack alignItems="flex-start" p={4} spacing={4}>
                  <VStack alignItems="flex-start" width="100%">
                    <HStack justifyContent="space-between" alignItems="center" width="100%" pr={2}>
                      <HStack>
                        <Text fontSize="xxLarge" fontWeight="bold">
                          {title}
                        </Text>
                        <ProjectElement
                          type={
                            status === User_Challenge_Status_Enum.Completed
                              ? 'experienceCompleted'
                              : 'experience'
                          }
                          experience={25}
                        />
                      </HStack>
                      <HStack>
                        {StatusIconMapper[status]}
                        <Text fontSize="small">{StatusTextMapper[status]}</Text>
                      </HStack>
                    </HStack>
                  </VStack>
                  <ReactMarkdown
                    components={{
                      h1: ({ node, ...props }) => (
                        <HStack mt={5} mb={3}>
                          <Icon type="InstructionsUserStory" size={24} />
                          <Text fontSize="xLarge" {...props} />
                        </HStack>
                      ),
                      h2: ({ node, ...props }) => (
                        <HStack mt={5} mb={3}>
                          <Icon type="InstructionsAcceptanceCriteria" size={24} />
                          <Text fontSize="xLarge" {...props} />
                        </HStack>
                      ),
                      ul: ({ node, ...props }) => <UnorderedList spacing={2} pl={3} {...props} />,
                      li: ({ node, ...props }) => (
                        <ListItem lineHeight="26px" fontWeight="regular" {...props} />
                      ),
                      a: ({ node, ...props }) => <Link {...props} />,
                      p: ({ node, ...props }) => (
                        <Text lineHeight="26px" {...props} fontWeight="regular" />
                      ),
                    }}
                  >
                    {markdownInstructions}
                  </ReactMarkdown>
                </VStack>
              ),
            },
            {
              screenName: 'ai-buddy',
              title: 'AI Buddy',
              icon: <Icon type="CodeChatGpt" size={16} />,
              content: (
                <ChatGPT
                  chatContext={`You are an expert in web development, skilled in creating interactive and responsive web designs using HTML, CSS (including TailwindCSS), and JavaScript. The user is working on a daily web design challenge named ${title} using our built-in code editor. Your role is to assist them by:

                  - Providing guidance based on the prompts they use or messages they send to you.
                  - Offering code snippets or partial solutions in HTML, CSS (including TailwindCSS), and JavaScript without providing the complete solution.
                  - Giving advice on best practices for web design, responsive layouts, and interactivity.
                  - Debugging and reviewing code when users share specific snippets.
                  - Encouraging the user to think through the design and come up with their own solutions.
                  - Keeping in mind that you only have access to the code snippets they share with you.
                  - IMPORTANT: Do not provide a full solution to the challenge, involve the user in thinking and brainstorming instead.
                  - DESIGN SCREENSHOT FOR REFERENCE: ${screenshot_url}

                  Please respond with clear, actionable advice, partial code solutions, and insights. Do not provide complete solutions. Return code with markdown for syntax highlighting. Assume users have basic familiarity with web development concepts.`}
                />
              ),
            },
            {
              screenName: 'discussions',
              title: `${
                discussion_count > 0 ? `Discussions (${discussion_count})` : 'Discussions'
              }`,
              icon: <Icon type="Discussion" size={16} />,
              content: (
                <DiscussionTab
                  entityId={id}
                  type={Support_Type_Enum.Question}
                  refetch={() => {
                    queryClient.invalidateQueries({
                      queryKey: [TanstackQueryName.GetChallenge, screen_name],
                    });
                  }}
                />
              ),
            },
          ],
        }}
        footer={{
          rightSide: [
            designScreenshot({
              screenshotUrl: screenshot_url,
              title: `${title} Challenge`,
              type: 'challenge',
              isFreeUser: isFree,
              isDownloadingFigma: isDownloadingFigmaDesign,
              onFigmaDownload: () => {
                if (is_figma_downloaded) {
                  onFigmaDownload();

                  return;
                }
                if (isFree) {
                  figmaModal.onOpen();

                  return;
                }

                onFigmaDownload();
              },
              autoOpen:
                !isLoaded &&
                window.location.pathname.includes('browser') &&
                status !== User_Challenge_Status_Enum.Completed,
            }),
            resources({
              isFreeUser: isFree,
              githubLink: 'https://github.com/elise-bigdevsoon/big-dev-soon-challenge-template',
              glitchLink: 'https://glitch.com/edit/#!/remix/big-dev-soon-challenge-template',
              isDownloadingFigma: isDownloadingFigmaDesign,
              onFigmaDownload: () => {
                if (is_figma_downloaded) {
                  onFigmaDownload();

                  return;
                }
                if (isFree) {
                  figmaModal.onOpen();

                  return;
                }

                onFigmaDownload();
              },
              type: 'challenge',
            }),
            icons(),
            fonts(),
            ...(colorPaletteQuickButton ? [colorPaletteQuickButton] : []),
          ],
        }}
      />
      <GenerateCodeSolutionModal
        name="challenge"
        language="html"
        code={generatedSolutionCode}
        isOpen={isOpen}
        onClose={onClose}
        isLoading={mutation.isPending}
        onGenerate={() => mutation.mutate({ entityId: id, type: 'generate-challenge-solution' })}
      />
      <UnlockFigmaModal
        isOpen={figmaModal.isOpen}
        onClose={figmaModal.onClose}
        tokens={1}
        isDownloading={isDownloadingFigmaDesign}
        onDownload={onFigmaDownload}
      />
    </>
  );
};

export default ChallengePlayground;
