import { SupabaseClient } from '@supabase/supabase-js';
import { useQueryClient } from '@tanstack/react-query';
import { useInfiniteQueryWithErrorBoundary, useSupabaseTypedClient } from 'hooks/reactQuery';
import { useCallback, useEffect, useRef } from 'react';

import { useCurrentUser } from 'auth/CurrentUserProvider';
import {
  Experience_Level_Enum,
  Practice_Problem_Type_Enum,
  User_Practice_Problem_Status_Enum,
} from 'types/databaseEnums';
import {
  GetPracticeProblemsResponse,
  PracticeProblemPreview,
  RecentUser,
} from 'types/edgeFunctions';
import { TanstackQueryName } from 'types/frontend';
import { Database } from 'types/supabase';

interface IGetPracticeProblemsQueryFnParams {
  supabase: SupabaseClient<Database>;
  userId?: string;
  limit?: number | null;
  pageParam?: unknown | null;
  tabIndex: number;
  withRecentUsers?: boolean;
}

export const getPracticeProblemsQuery = async ({
  supabase,
  userId,
  limit = 15,
  pageParam,
  tabIndex,
  withRecentUsers,
}: IGetPracticeProblemsQueryFnParams) => {
  const practiceProblemsQuery = supabase
    .from('practice_problem')
    .select('id, created_at, diff_score, screen_name, difficulty, title, type')
    .order('diff_score', { ascending: true })
    .limit(tabIndex === 0 ? limit || 15 : 100);

  if (pageParam) {
    practiceProblemsQuery.gt('diff_score', pageParam);
  }

  const practiceProblems = await practiceProblemsQuery;

  if (practiceProblems.error) throw practiceProblems.error;

  const allPracticeProblems = await Promise.all(
    practiceProblems.data.map(async (practiceProblem): Promise<PracticeProblemPreview> => {
      const userPracticeProblem = userId
        ? await supabase
            .from('user_practice_problem')
            .select('id, status')
            .eq('user_id', userId || '')
            .eq('practice_problem_id', practiceProblem.id)
            .eq('status', User_Practice_Problem_Status_Enum.Completed)
            .maybeSingle()
        : null;

      if (userPracticeProblem?.error) throw userPracticeProblem?.error;

      let recentUsersData;
      let recentUsersError;
      let recentUsersObject;

      if (withRecentUsers) {
        const { data, error } = await supabase.rpc('get_recent_users_for_practice_problem', {
          p_practice_problem_id: practiceProblem.id,
        });

        recentUsersData = data;
        recentUsersError = error;
        recentUsersObject = recentUsersData?.[0];
      }

      return {
        id: practiceProblem.id,
        created_at: practiceProblem.created_at || '',
        diff_score: practiceProblem.diff_score || 0,
        status:
          (userPracticeProblem?.data?.status as User_Practice_Problem_Status_Enum) ||
          User_Practice_Problem_Status_Enum.NotStarted,
        title: practiceProblem.title,
        screen_name: practiceProblem.screen_name,
        difficulty: practiceProblem.difficulty as Experience_Level_Enum,
        type: practiceProblem.type as Practice_Problem_Type_Enum,
        recent_users: recentUsersError ? [] : ((recentUsersObject?.users || []) as RecentUser[]),
        users_completed_count: recentUsersError
          ? 0
          : recentUsersObject?.unique_users_completed_count || 0,
      };
    })
  );

  return allPracticeProblems;
};

interface IUseGetPracticeProblemsResponse {
  data: GetPracticeProblemsResponse | undefined | null;
  isFetching: boolean;
  ref: any;
}

export const useGetPracticeProblemsQuery = (
  tabIndex: number,
  withRecentUsers?: boolean
): IUseGetPracticeProblemsResponse => {
  const { id: userId } = useCurrentUser();

  const supabase = useSupabaseTypedClient();
  const queryClient = useQueryClient();

  const { data, fetchNextPage, hasNextPage, isFetching } = useInfiniteQueryWithErrorBoundary({
    queryKey: [TanstackQueryName.GetPracticeProblems, userId, tabIndex],
    queryFn: async ({ pageParam }) =>
      getPracticeProblemsQuery({
        supabase,
        userId,
        limit: 15,
        pageParam,
        tabIndex,
        withRecentUsers,
      }),
    placeholderData: {
      pageParams: [],
      pages: [],
    },
    initialPageParam: null,
    getNextPageParam: (lastPage) => {
      if (lastPage && Array.isArray(lastPage) && lastPage?.length > 0) {
        const lastPracticeProblemDiffScore = lastPage[lastPage.length - 1].diff_score;

        return lastPracticeProblemDiffScore;
      }

      return undefined;
    },
  });

  const observer = useRef<IntersectionObserver>();
  const lastPracticeProblemElementRef = useCallback(
    (node) => {
      if (isFetching) return;

      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting && hasNextPage) {
            fetchNextPage();
          }
        },
        {
          rootMargin: '100px',
        }
      );

      if (node) observer.current.observe(node);
    },
    [fetchNextPage, hasNextPage, isFetching]
  );

  useEffect(() => {
    return () => {
      queryClient.removeQueries({
        queryKey: [TanstackQueryName.GetPracticeProblems, userId, 0],
      });
      queryClient.removeQueries({
        queryKey: [TanstackQueryName.GetPracticeProblems, userId, 1],
      });
      queryClient.removeQueries({
        queryKey: [TanstackQueryName.GetPracticeProblems, userId, 2],
      });
    };
  }, []);

  return {
    data,
    isFetching,
    ref: lastPracticeProblemElementRef,
  };
};
