import { HStack, ListItem, UnorderedList, VStack } from '@chakra-ui/react';

import TokenGlare from 'assets/illustrations/TokenGlare.svg';
import Button from 'components/Button';
import Card from 'components/Card';
import Icon from 'components/Icon';
import Text from 'components/Text';
import { ERoute } from 'types/frontend';

const UnlockBIG = () => {
  return (
    <>
      <Card
        header={
          <HStack>
            <Icon type="UpgradeBig" size={32} />
            <Text fontSize="xxLarge" opacity="default" fontWeight="semibold">
              Unlock BIG Features
            </Text>
          </HStack>
        }
        width="100%"
        maxW={['350px', '350px', '350px', '350px', '100%', '100%']}
        cardHeaderProps={{ px: 4 }}
        cardBodyProps={{ px: 4 }}
        backgroundImage={`url(${TokenGlare})`}
        backgroundRepeat="no-repeat"
        backgroundColor="whiteAlpha"
        backgroundPosition="top"
      >
        <VStack spacing={0} alignItems="flex-start">
          <Text fontWeight="regular">
            <UnorderedList spacing={2} mb={4}>
              <ListItem>Work with 120+ Figma Designs</ListItem>
              <ListItem>Access over 170+ Project Cards</ListItem>
              <ListItem>Code faster with AI Buddy</ListItem>
              <ListItem>Private code solutions</ListItem>
              <ListItem>Dedicated Discord channel</ListItem>
              <ListItem>BIG Badges</ListItem>
            </UnorderedList>
          </Text>
          <Button
            scheme="gradient"
            fullWidth
            to={ERoute.Pricing}
            mt={1}
            leftIcon={<Icon type="Rocket" />}
          >
            Check BIG plans
          </Button>
        </VStack>
      </Card>
    </>
  );
};

export default UnlockBIG;
