import { HStack } from '@chakra-ui/react';

import { useCurrentUser } from 'auth/CurrentUserProvider';
import BigTokens from 'components/BigTokens';
import SettingsTile from 'components/Billing/SettingsTile';
import { LinkInput } from 'components/LinkInput';
import Text from 'components/Text';
import { useMediaQueries } from 'layout/MediaQueriesProvider';
import { CreditReward } from 'utils/constants';

export const ReferralLinkSettings = () => {
  const { hasExhaustedReferralLink } = useCurrentUser();
  const { isSmallScreen } = useMediaQueries();

  return (
    <>
      <SettingsTile
        title="Referral link"
        subtitle={
          hasExhaustedReferralLink ? (
            'Invite friends to BigDevSoon with your own referral link.'
          ) : (
            <HStack spacing={1}>
              <Text opacity="secondary" fontWeight="regular">
                Collect
              </Text>
              <BigTokens amount={CreditReward.Referral} />
              <Text fontSize="small">BigToken</Text>
              {!isSmallScreen && (
                <Text opacity="secondary" fontWeight="regular">
                  for each registration.
                </Text>
              )}
              <Text fontWeight="regular" opacity="secondary" fontSize="micro">
                (up to 5)
              </Text>
            </HStack>
          )
        }
      >
        <LinkInput />
      </SettingsTile>
    </>
  );
};
