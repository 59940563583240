import { DeleteIcon } from '@chakra-ui/icons';
import {
  VStack,
  Container,
  Stack,
  Box,
  useTheme,
  HStack,
  Textarea,
  Divider,
  useDisclosure,
  IconButton,
  Center,
  Spinner,
} from '@chakra-ui/react';
import { InfiniteData, useMutation, useQueryClient } from '@tanstack/react-query';
import { format } from 'date-fns';
import { useGetCommunityQuestions } from 'hooks/queries/useGetCommunityQuestions';
import { supabaseMutationFn, useSupabaseTypedClient } from 'hooks/reactQuery';
import { useToastWrapper } from 'hooks/useToastWrapper';
import { ChangeEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useCurrentUser } from 'auth/CurrentUserProvider';
import { BigBadge } from 'components/BigBadge';
import { EmptyContent } from 'components/EmptyContent';
import Icon from 'components/Icon';
import Link from 'components/Link';
import { GitHubSignInModal } from 'components/Modal/GitHubSignInModal';
import PageLoader from 'components/PageLoader';
import { ProtectedElement } from 'components/ProtectedElement';
import Text from 'components/Text';
import UserAvatar from 'components/UserAvatar';
import { DATE_FORMAT_WITH_TIME } from 'config/constants/plans';
import { Support_Type_Enum } from 'types/databaseEnums';
import {
  AskCommunityQuestionParams,
  CommunityQuestion,
  EdgeFunctionName,
} from 'types/edgeFunctions';
import { ERoute, TanstackQueryName } from 'types/frontend';

import DeleteMessageModal from './DeleteMessageModal';

interface IDiscussionTabProps {
  entityId: string;
  type: Support_Type_Enum;
  isMySolution?: boolean;
  refetch: () => void;
}

const DiscussionTab = ({ entityId, type, isMySolution, refetch }: IDiscussionTabProps) => {
  const [message, setMessage] = useState('');
  const [idToDelete, setIdToDelete] = useState<string>('');

  const { id: userId } = useCurrentUser();

  const supabase = useSupabaseTypedClient();

  const queryClient = useQueryClient();

  const navigate = useNavigate();

  const { toastSuccess, toastError } = useToastWrapper();

  const {
    isOpen: isGitHubSignInModalOpen,
    onOpen: onGitHubSignInModalOpen,
    onClose: onGitHubSignInModalClose,
  } = useDisclosure();

  const {
    isOpen: isDeleteMessageModalOpen,
    onOpen: onDeleteMessageModalOpen,
    onClose: onDeleteMessageModalClose,
  } = useDisclosure();

  const { borderRadius } = useTheme();

  const { data, isFetching, ref } = useGetCommunityQuestions({
    entity_id: entityId,
    type,
  });

  const mutation = useMutation<string, string, AskCommunityQuestionParams>({
    mutationFn: supabaseMutationFn(({ message, entity_id, type }) =>
      supabase.functions.invoke<string>(EdgeFunctionName.AskCommunityQuestion, {
        body: { message, entity_id, type } as AskCommunityQuestionParams,
      })
    ),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [TanstackQueryName.GetCommunityQuestions, entityId],
      });
      queryClient.invalidateQueries({
        queryKey: [TanstackQueryName.GetUserAchievements, userId],
      });

      refetch();

      setMessage('');
      toastSuccess('Message sent');
    },
    onError: () => {
      toastError(`Can't send message (e.g. too many in this thread)`);
    },
  });

  const handleMessageChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setMessage(event.target.value);
  };

  const onAskForFeedbackFocus = () => {
    if (!userId) {
      onGitHubSignInModalOpen();
      return;
    }
  };

  const navigateToUserProfile = (e: any, screenName: string) => {
    e.stopPropagation();

    navigate(`${ERoute.Profile}/${screenName}`);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();

      if (!message || mutation.isPending) {
        return;
      }

      mutation.mutate({
        message,
        entity_id: entityId,
        type,
      });
    }
  };

  const communityQuestionsData = (data as unknown as InfiniteData<CommunityQuestion[]>) || [];
  const communityQuestions =
    communityQuestionsData.pages.reduce(
      (acc, page) => acc.concat(page),
      [] as CommunityQuestion[]
    ) || [];

  const renderCommunityQuestions = () => {
    const isFirstPage = communityQuestionsData.pages.length === 0;

    if (isFirstPage && isFetching) {
      return <PageLoader shift />;
    }

    if (communityQuestions.length === 0) {
      return (
        <EmptyContent
          type={type === Support_Type_Enum.Question ? 'community_questions' : 'comments'}
        />
      );
    }

    return (
      <>
        <Stack spacing={4} width="100%" pr={2}>
          {communityQuestions.map((communityQuestion) => {
            const accountDeleted =
              communityQuestion.user.id === '' || communityQuestion.user.id === null;

            return (
              <Box
                borderRadius={borderRadius}
                key={communityQuestion.id}
                backgroundColor="whiteAlpha.200"
                p={4}
                _hover={{ backgroundColor: 'whiteAlpha.100' }}
                transition="all 0.3s ease-out"
                position="relative"
                role="group"
              >
                <VStack alignItems="flex-start" spacing={5}>
                  <HStack justifyContent="space-between" width="100%">
                    <HStack
                      spacing={4}
                      width="fit-content"
                      cursor={accountDeleted ? 'default' : 'pointer'}
                      alignItems="flex-start"
                    >
                      <UserAvatar
                        key={communityQuestion.user.id}
                        name={communityQuestion.user.name}
                        src={communityQuestion.user.avatar_url}
                        {...(!accountDeleted && {
                          onClick: (ev) =>
                            navigateToUserProfile(ev, communityQuestion.user.screen_name),
                          experience: communityQuestion.user.xp,
                        })}
                        hideCircularProgress
                      />
                      <VStack alignItems="flex-start" spacing={0} pt={1}>
                        <HStack>
                          {!accountDeleted ? (
                            <Link
                              to={`${ERoute.Profile}/${communityQuestion.user.screen_name}`}
                              color="white"
                            >
                              <Text fontSize="small" maxW="200px" isTruncated>
                                {communityQuestion.user.name}
                              </Text>
                            </Link>
                          ) : (
                            <Text fontSize="small" maxW="200px" isTruncated>
                              {communityQuestion.user.name}
                            </Text>
                          )}

                          <BigBadge
                            plan={communityQuestion.user.plan}
                            showOnlySubscribed
                            size="small"
                          />
                        </HStack>
                        <Text fontSize="micro" fontWeight="regular">
                          {format(new Date(communityQuestion.created_at), DATE_FORMAT_WITH_TIME)}
                        </Text>
                      </VStack>
                    </HStack>
                    {communityQuestion.user.id === userId && (
                      <IconButton
                        aria-label="Delete your discussion message"
                        icon={<DeleteIcon />}
                        position="absolute"
                        top="10px"
                        right="10px"
                        display="none"
                        onClick={() => {
                          setIdToDelete(communityQuestion.id);
                          onDeleteMessageModalOpen();
                        }}
                        _groupHover={{ display: 'block' }}
                      >
                        Delete
                      </IconButton>
                    )}
                  </HStack>
                  <Text fontWeight="regular">{communityQuestion.message}</Text>
                </VStack>
              </Box>
            );
          })}
        </Stack>
        <div ref={ref} />
        <Center width="100%">{isFetching && !isFirstPage && <Spinner size="lg" />}</Center>
      </>
    );
  };

  return (
    <>
      <Container maxW="1400px">
        <VStack alignItems="flex-start" spacing={4} width="100%" pb={4}>
          <Text fontSize="xxLarge" fontWeight="bold" pt={4}>
            {type === Support_Type_Enum.Question ? 'Discussions' : 'Comments'}
          </Text>
          <VStack alignItems="flex-start" spacing={2} width="100%" pr={2}>
            <Textarea
              onKeyDown={handleKeyPress}
              placeholder={
                type === Support_Type_Enum.Question
                  ? 'Ask a question, share your thoughts and discuss with the community. 💬'
                  : isMySolution
                  ? 'Ask for feedback you would like to receive. 🤗'
                  : 'Leave kind feedback, sharing is caring. ❤️'
              }
              variant="filled"
              maxLength={300}
              rows={4}
              isDisabled={mutation.isPending}
              onFocus={onAskForFeedbackFocus}
              onChange={handleMessageChange}
              value={message}
              autoComplete="off"
            />
            <HStack justifyContent="space-between" alignItems="center" width="100%" mt={2}>
              <ProtectedElement
                leftIcon={<Icon type="SendMessage" />}
                scheme="primary"
                onClick={() => {
                  if (!message) {
                    return;
                  }

                  mutation.mutate({
                    message,
                    entity_id: entityId,
                    type,
                  });
                }}
                isLoading={mutation.isPending}
              >
                {type === Support_Type_Enum.Question ? 'Discuss' : 'Comment'}
              </ProtectedElement>
              <HStack spacing={1} justifyContent="flex-end" width="100%">
                <Text fontSize="micro" opacity="secondary" fontWeight="regular">
                  Characters remaining:
                </Text>
                <Text fontSize="micro" opacity="secondary" fontWeight="semibold">
                  {300 - message.length}
                </Text>
              </HStack>
            </HStack>
            <Divider my={3} />
          </VStack>
          {renderCommunityQuestions()}
        </VStack>
      </Container>
      <GitHubSignInModal isOpen={isGitHubSignInModalOpen} onClose={onGitHubSignInModalClose} />
      <DeleteMessageModal
        messageId={idToDelete}
        entityId={entityId}
        isOpen={isDeleteMessageModalOpen}
        onClose={onDeleteMessageModalClose}
        refetch={refetch}
      />
    </>
  );
};

export default DiscussionTab;
