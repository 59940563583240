import { Box, Center, HStack, useDisclosure, useTheme, VStack } from '@chakra-ui/react';
import { format } from 'date-fns';

import { useCurrentUser } from 'auth/CurrentUserProvider';
import BigTokens from 'components/BigTokens';
import Button from 'components/Button';
import DiscordButton from 'components/DiscordButton';
import Icon from 'components/Icon';
import { LinkInput } from 'components/LinkInput';
import Modal from 'components/Modal';
import Text from 'components/Text';
import { DATE_FORMAT } from 'config/constants/plans';
import { ERoute, IModal } from 'types/frontend';
import { CreditReward } from 'utils/constants';

import { TokensModal } from './TokensModal';

interface INotEnoughBigTokensModal extends IModal {}

export const NotEnoughBigTokensModal = ({ isOpen, onClose }: INotEnoughBigTokensModal) => {
  const {
    hasDiscordRegistered,
    hasExhaustedReferralLink,
    nextTokenRecycleTime,
    rechargeTokens,
    isFree,
  } = useCurrentUser();

  const { borderRadius } = useTheme();

  const showOr = !hasDiscordRegistered || !hasExhaustedReferralLink;

  const {
    isOpen: isTokensModalOpen,
    onOpen: onTokensModalOpen,
    onClose: onTokensModalClose,
  } = useDisclosure();

  return (
    <>
      <Modal
        size="2xl"
        isOpen={isOpen}
        onClose={onClose}
        header={
          <HStack>
            <Icon type="MoneyBag" size={35} />
            <Text fontSize="xLarge" fontWeight="semibold">
              Not enough BigTokens
            </Text>
          </HStack>
        }
        footer={
          <HStack mt={2}>
            <Button scheme="ghost" onClick={onClose} autoFocus>
              Cancel
            </Button>
            {isFree && (
              <Button scheme="gradient" leftIcon={<Icon type="UpgradePlan" />} to={ERoute.Pricing}>
                Upgrade plan
              </Button>
            )}
          </HStack>
        }
      >
        <VStack align="stretch">
          <HStack spacing={1} shouldWrapChildren={true}>
            <Text fontWeight="regular">Your account will be recharged to</Text>
            <BigTokens amount={rechargeTokens} />
            <Text fontWeight="semibold">BigTokens</Text>
            <Text fontWeight="regular">on</Text>
            <Text fontWeight="semibold">
              {format(new Date(nextTokenRecycleTime || 0), DATE_FORMAT)}.
            </Text>
          </HStack>

          <Center>
            <Button
              mt={2}
              width="fit-content"
              scheme="gradient"
              onClick={onTokensModalOpen}
              leftIcon={<Icon type="Bigtokens" size={24} />}
            >
              Get BigTokens now
            </Button>
          </Center>

          {showOr && (
            <Center my={6}>
              <Text fontSize="xLarge">OR</Text>
            </Center>
          )}

          {!hasExhaustedReferralLink && (
            <Box background="whiteAlpha.200" borderRadius={borderRadius} p={5}>
              <VStack alignItems="flex-start" spacing={4}>
                <HStack>
                  <Icon type="InviteFriendsModal" />
                  <Text fontSize="xLarge">{`Invite friends`}</Text>
                </HStack>
                <HStack spacing={1} shouldWrapChildren={true}>
                  <Text fontWeight="regular">Collect</Text>
                  <BigTokens amount={CreditReward.Referral} />
                  <Text fontWeight="semibold">BigToken</Text>
                  <Text fontWeight="regular">for each registration.</Text>
                  <Text fontWeight="regular" opacity="secondary" fontSize="micro">
                    (up to 5)
                  </Text>
                </HStack>
                <LinkInput />
              </VStack>
            </Box>
          )}
          {!hasDiscordRegistered && (
            <Box background="whiteAlpha.200" borderRadius={borderRadius} p={5} mt={4}>
              <VStack alignItems="flex-start" spacing={4}>
                <HStack>
                  <Icon type="DiscordRoundModal" />
                  <Text fontSize="xLarge">{`Become discord member`}</Text>
                </HStack>
                <HStack spacing={1} shouldWrapChildren mb={1}>
                  <Text fontWeight="regular">Join our Discord community and collect</Text>
                  <BigTokens amount={CreditReward.DiscordConnected} />
                  <Text fontWeight="semibold">BigToken.</Text>
                </HStack>
                <DiscordButton isFullWidth={false} />
              </VStack>
            </Box>
          )}
        </VStack>
        {isFree && <Text mt={4}>You can also upgrade your plan to get more BigTokens.</Text>}
      </Modal>
      <TokensModal isOpen={isTokensModalOpen} onClose={onTokensModalClose} />
    </>
  );
};
