import { Box, HStack } from '@chakra-ui/react';
import React from 'react';

import { useMediaQueries } from 'layout/MediaQueriesProvider';

import GradientTooltip from './GradientTooltip';
import Icon from './Icon';
import Text from './Text';

interface ScoreBadgeProps {
  type: 'code' | 'design';
  score?: number | null;
  hideText?: boolean;
}

const getGrade = (score: number): string => {
  if (score >= 90) return 'S';
  if (score >= 80) return 'A';
  if (score >= 70) return 'B';
  if (score >= 60) return 'C';
  if (score >= 50) return 'D';
  if (score >= 40) return 'E';

  return 'F';
};

const ScoreBadge: React.FC<ScoreBadgeProps> = ({ type, score, hideText }) => {
  const { isMobile } = useMediaQueries();

  const isCode = type === 'code';
  const hasScore = score && score >= 0;

  const iconType = hasScore
    ? isCode
      ? 'CodeScoreNormal'
      : 'DesignScoreNormal'
    : isCode
    ? 'CodeScoreEmpty'
    : 'DesignScoreEmpty';

  const grade = hasScore ? getGrade(score!) : '?';
  const displayScore = hasScore ? `${score}` : 'N/A';

  const tooltipLabel = hasScore
    ? `${isCode ? 'Code' : 'Design'} Score: ${displayScore}/100`
    : `${isCode ? 'Code' : 'Design'} Score not generated`;

  return (
    <GradientTooltip label={tooltipLabel}>
      <Box
        display="inline-block"
        py={1}
        pl={hideText ? 1.5 : 1}
        pr={1.5}
        borderRadius={999}
        backgroundColor="whiteAlpha.200"
      >
        <HStack>
          <Box position="relative">
            <Icon type={iconType} />
            <Box position="absolute" left="50%" top="50%" transform="translate(-50%, -50%)">
              <Text fontWeight="bold" fontSize="xxLarge">
                {hasScore ? grade : '?'}
              </Text>
            </Box>
          </Box>
          {!hideText && !isMobile && <Text>{displayScore}</Text>}
        </HStack>
      </Box>
    </GradientTooltip>
  );
};

export default ScoreBadge;
