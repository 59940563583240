import { isWithinInterval, subWeeks } from 'date-fns';

import { Image_Type_Enum, Skill_Tag_Enum, Experience_Level_Enum } from 'types/databaseEnums.ts';
import { ProjectImage, ProjectPreview } from 'types/edgeFunctions.ts';
import { Database } from 'types/supabase.ts';

export type DatabaseProject = {
  project_tag: Omit<
    Database['public']['Tables']['project_tag']['Row'],
    'project_id' | 'project_version'
  >[];
  project_image: Omit<
    Database['public']['Tables']['project_image']['Row'],
    'project_id' | 'project_version'
  >[];
  card: Pick<Database['public']['Tables']['card']['Row'], 'id'>[];
} & Pick<
  Database['public']['Tables']['project']['Row'],
  'id' | 'screen_name' | 'difficulty_lvl' | 'title' | 'created_at' | 'description'
>;

export const mapToProjectPreview = (
  project: DatabaseProject
): Omit<ProjectPreview, 'recent_users' | 'users_completed_count' | 'status'> => {
  return {
    id: project.id,
    screen_name: project.screen_name || '',
    difficulty_lvl: project.difficulty_lvl as Experience_Level_Enum,
    title: project.title,
    description: project.description,
    tags: project.project_tag.map((projectTag) => ({
      ...projectTag,
      tag_type: projectTag.tag_type as Skill_Tag_Enum,
    })),
    is_new: isWithinInterval(new Date(project.created_at), {
      start: subWeeks(new Date(), 2),
      end: new Date(),
    }),
    horizontal_thumbnail: project.project_image.find(
      (projectImage) => projectImage.image_type === Image_Type_Enum.ProjectHorizontalThumbnail
    ) as ProjectImage,
    project_hd_thumbnail: project.project_image.find(
      (projectImage) => projectImage.image_type === Image_Type_Enum.ProjectHdThumbnail
    ) as ProjectImage,
    card: project.card,
  };
};
