import { Box, Divider, HStack, IconButton, VStack, useDisclosure } from '@chakra-ui/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useGetPracticeProblemSolutionQuery } from 'hooks/queries/problems/useGetPracticeProblemSolutionQuery';
import { supabaseMutationFn, useSupabaseTypedClient } from 'hooks/reactQuery';
import useConfetti from 'hooks/useConfetti';
import { useExitAlert } from 'hooks/useExitAlert';
import { useToastWrapper } from 'hooks/useToastWrapper';
import { useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useCurrentUser } from 'auth/CurrentUserProvider';
import { BigBadge } from 'components/BigBadge';
import CommentButton from 'components/CommentButton';
import DevSandbox from 'components/DevSandbox';
import DevSandboxSkeleton from 'components/DevSandbox/DevSandboxSkeleton';
import DiscussionTab from 'components/DevSandbox/PaneTabs/Discussion/DiscussionTab';
import SummaryTab from 'components/DevSandbox/PaneTabs/Summary/SummaryTab';
import RightSideActions from 'components/DevSandbox/RightSideActions';
import GradientPopover from 'components/GradientPopover';
import GradientTooltip from 'components/GradientTooltip';
import Icon from 'components/Icon';
import LikeButton from 'components/LikeButton';
import Link from 'components/Link';
import { LinkInput } from 'components/LinkInput';
import { ProtectedElement } from 'components/ProtectedElement';
import RenameCodeInput from 'components/RenameCodeInput';
import { SEO } from 'components/SEO';
import ShareButtons from 'components/ShareButtons';
import Text from 'components/Text';
import ToggleCodePrivacyLock from 'components/ToggleCodePrivacyLock';
import UserAvatar from 'components/UserAvatar';
import { useMediaQueries } from 'layout/MediaQueriesProvider';
import { Support_Type_Enum } from 'types/databaseEnums';
import { AskChatGptParams, EdgeFunctionName, PracticeProblem } from 'types/edgeFunctions';
import { ERoute, TanstackQueryName } from 'types/frontend';
import { mapExpToProblem } from 'utils/constants';

import ProgressModal from './ProgressModal';
import SolutionsTab from './SolutionsTab';

interface IPracticeProblemSolutionProps {
  practiceProblem: PracticeProblem;
}

const PracticeProblemSolution = ({
  practiceProblem: { id, title, test_cases, screen_name, difficulty, solutions_count },
}: IPracticeProblemSolutionProps) => {
  const { id: currentUserId, screenName: currentUserScreenName } = useCurrentUser();

  const { isMobile, isSmallScreen } = useMediaQueries();

  const codeEditorRef = useRef<any>(null);

  const location = useLocation();

  const navigate = useNavigate();

  const queryClient = useQueryClient();

  const progressModal = useDisclosure();

  const hasTabInUrl =
    location.pathname.includes('summary') ||
    location.pathname.includes('comments') ||
    location.pathname.includes('user-solutions');
  const tabScreenName = location.pathname.split('/').pop();
  const solutionUrl = hasTabInUrl
    ? location.pathname.replace(`/${tabScreenName}`, '')
    : location.pathname;

  const APP_URL = process.env.REACT_APP_SITE_URL || '';

  const link = `${APP_URL.substring(0, APP_URL.length - 1)}${solutionUrl}`;

  const { data, isPending } = useGetPracticeProblemSolutionQuery({
    solutionUrl,
  });

  const { toastSuccess, toastError } = useToastWrapper();

  const supabase = useSupabaseTypedClient();

  const regenerateSummaryModal = useDisclosure();

  const mutation = useMutation<string, string, AskChatGptParams>({
    mutationFn: supabaseMutationFn(({ entityId, type }) =>
      supabase.functions.invoke<string>(EdgeFunctionName.AskChatGpt, {
        body: {
          entityId,
          type,
        } as AskChatGptParams,
      })
    ),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [TanstackQueryName.GetCurrentUser, currentUserId],
      });
      queryClient.invalidateQueries({
        queryKey: [TanstackQueryName.GetPracticeProblemsSolutions, id],
      });
      queryClient.invalidateQueries({
        queryKey: [TanstackQueryName.GetPracticeProblemSolution, solutionUrl],
      });

      toastSuccess('Summary generated successfully.');
      regenerateSummaryModal.onClose();
    },
    onError: () => {
      toastError('Could not generate summary, try again later.');
    },
  });

  const isMySolution = Boolean(currentUserId && data?.user.id === currentUserId);

  const Confetti = useConfetti({
    isMySolution,
    solutionUrl,
    solutionId: data?.id || '',
    onComplete: () => progressModal.onOpen(),
  });

  useExitAlert({
    shouldShowBrowserExitAlert: mutation.isPending,
  });

  const accountDeleted = data?.user.id === '' || data?.user.id === null;

  if (isPending || !data) {
    return <DevSandboxSkeleton loadingText="Loading problem solution..." tabNames={['JS']} />;
  }

  return (
    <>
      <SEO title={`BigDevSoon | ${data.title} - ${title} problem solution`} url={link} />
      <Box position="absolute" zIndex={9999999} top={0} left={0}>
        {Confetti && <Confetti numberOfPieces={500} gravity={0.5} recycle={false} />}
      </Box>
      <DevSandbox
        ref={codeEditorRef}
        id={`problem-${screen_name}-${currentUserId}-solution-${data.id}`}
        title={data.title}
        mode="script-practice"
        navigation={{
          leftSide: (
            <HStack pl={1}>
              <VStack spacing={0} alignItems="flex-start">
                <RenameCodeInput
                  title={data.title}
                  isMySolution={isMySolution}
                  type="problem"
                  shortTitle
                  renameCodeParams={{
                    entityId: data.id,
                    userScreenName: currentUserScreenName,
                    parentScreenName: screen_name,
                    repositoryUrl: '',
                    previewUrl: '',
                    queryKey: [TanstackQueryName.GetPracticeProblemSolution, solutionUrl],
                    invalidateKeys: [
                      [TanstackQueryName.GetPracticeProblemsSolutions, id],
                      [TanstackQueryName.GetUserCode, currentUserId, 0],
                      [TanstackQueryName.GetUserCode, currentUserId, 1],
                      [TanstackQueryName.GetUserCode, currentUserId, 2],
                      [TanstackQueryName.GetProfileCode, data.user.id, 'problem'],
                      [TanstackQueryName.GetPracticeProblemSolution, solutionUrl],
                    ],
                  }}
                />
                <HStack
                  spacing={4}
                  width="fit-content"
                  cursor={accountDeleted ? 'default' : 'pointer'}
                  alignItems="flex-start"
                >
                  <HStack>
                    {isSmallScreen ? (
                      <UserAvatar
                        key={data.user.id}
                        name={data.user.name}
                        src={data.user.avatar_url}
                        onClick={(e) => {
                          if (accountDeleted) {
                            return;
                          }

                          navigate(`${ERoute.Profile}/${data.user.screen_name}`);
                        }}
                        experience={data.user.xp}
                        size="xs"
                        hideCircularProgress
                      />
                    ) : (
                      <>
                        {!accountDeleted ? (
                          <Link to={`${ERoute.Profile}/${data.user.screen_name}`} color="white">
                            <Text fontSize="micro" maxW="200px" isTruncated opacity="secondary">
                              {data.user.name}
                            </Text>
                          </Link>
                        ) : (
                          <Text fontSize="micro" maxW="200px" isTruncated opacity="secondary">
                            {data.user.name}
                          </Text>
                        )}
                      </>
                    )}
                    <BigBadge plan={data.user.plan} size="small" />
                  </HStack>
                </HStack>
              </VStack>
              <Divider height="24px" orientation="vertical" />
              <HStack spacing={4}>
                <LikeButton
                  userId={data.user.id}
                  likes={data.likes}
                  likeCodeParams={{
                    entityId: data.id,
                    isLiked: data.is_liked,
                    queryKey: [TanstackQueryName.GetPracticeProblemSolution, solutionUrl],
                    invalidateKeys: [
                      [TanstackQueryName.GetPracticeProblemsSolutions, id],
                      [TanstackQueryName.GetUserCode, currentUserId, 0],
                      [TanstackQueryName.GetUserCode, currentUserId, 1],
                      [TanstackQueryName.GetUserCode, currentUserId, 2],
                      [TanstackQueryName.GetProfileCode, data.user.id, 'problem'],
                    ],
                  }}
                />
                {!isSmallScreen && (
                  <CommentButton comments={data.comments_count} url={`${solutionUrl}/comments`} />
                )}
              </HStack>
            </HStack>
          ),
          header: (
            <HStack>
              {isMySolution ? (
                <>
                  <ProtectedElement
                    scheme="outline"
                    leftIcon={<Icon type="PencilEdit" />}
                    to={`${ERoute.PracticeProblems}/${screen_name}`}
                  >
                    Edit
                  </ProtectedElement>
                </>
              ) : (
                <ProtectedElement asButton={false}>
                  <GradientTooltip label="Fork code">
                    <IconButton
                      aria-label="fork solution"
                      onClick={() => {
                        navigate(`${ERoute.CodeFrames}${ERoute.NewCodeFrame}`, {
                          state: {
                            html: '',
                            css: '',
                            js: data.code,
                            shouldCreate: true,
                            isFork: true,
                          },
                        });
                      }}
                    >
                      <Icon type="ForkCode" />
                    </IconButton>
                  </GradientTooltip>
                </ProtectedElement>
              )}
            </HStack>
          ),
          rightSide: (
            <RightSideActions
              leftActions={
                <HStack spacing={0}>
                  {isMySolution && (
                    <ToggleCodePrivacyLock
                      type="problem"
                      togglePrivacyCodeParams={{
                        isPrivate: data.is_private,
                        entityId: data.id,
                        queryKey: [TanstackQueryName.GetPracticeProblemSolution, solutionUrl],
                        invalidateKeys: [
                          [TanstackQueryName.GetPracticeProblemsSolutions, id],
                          [TanstackQueryName.GetUserCode, currentUserId, 0],
                          [TanstackQueryName.GetUserCode, currentUserId, 1],
                          [TanstackQueryName.GetUserCode, currentUserId, 2],
                          [TanstackQueryName.GetProfileCode, data.user.id, 'problem'],
                          [TanstackQueryName.GetPracticeProblemSolution, solutionUrl],
                        ],
                      }}
                    />
                  )}
                  <GradientPopover
                    tooltip="Share code"
                    triggerComponent={
                      <IconButton
                        aria-label="share solution popover with share buttons and link"
                        variant="ghost"
                      >
                        <Icon type="Share" />
                      </IconButton>
                    }
                    width={isMobile ? '95vw' : '500px'}
                    header="Spread the word about this solution"
                  >
                    <VStack alignItems="flex-start" spacing={4} width="100%">
                      <ShareButtons
                        text={`Check out ${
                          isMySolution ? 'my latest' : 'this'
                        } solution to the ${title} Practice Problem on BigDevSoon. 🚀`}
                        link={link}
                        emailSubject={`Hey, wanted to share ${
                          isMySolution ? 'my' : 'this'
                        } solution to the ${title} Practice Problem on BigDevSoon with you`}
                      />
                      <LinkInput link={link} />
                    </VStack>
                  </GradientPopover>
                </HStack>
              }
            />
          ),
        }}
        codeTabs={{
          js: data.code,
          tabNames: ['JS'],
        }}
        paneTabs={{
          tabs: [
            {
              title: 'Summary',
              screenName: 'summary',
              icon: <Icon type="Summary" size={16} />,
              content: (
                <SummaryTab
                  feedback={data.ai_feedback}
                  isLoading={mutation.isPending}
                  codeScore={data.score}
                  codeSummary={data.report}
                  isCodeOnly
                  isMyReport={isMySolution}
                  onGenerate={() => {
                    mutation.mutate({ entityId: data.id, type: 'generate-problem-code-summary' });
                  }}
                  {...regenerateSummaryModal}
                />
              ),
            },
            {
              title: `${
                data.comments_count > 0 ? `Comments (${data.comments_count})` : 'Comments'
              }`,
              screenName: 'comments',
              icon: <Icon type="CommunityQuestions" />,
              content: (
                <DiscussionTab
                  entityId={data.id}
                  type={Support_Type_Enum.Feedback}
                  isMySolution={isMySolution}
                  refetch={() => {
                    queryClient.invalidateQueries({
                      queryKey: [TanstackQueryName.GetPracticeProblemSolution, solutionUrl],
                    });
                  }}
                />
              ),
            },
            {
              screenName: 'user-solutions',
              title: `${solutions_count > 0 ? `Solutions (${solutions_count})` : 'Solutions'}`,
              icon: <Icon type="SolutionsTab" size={16} />,
              content: <SolutionsTab practiceProblemId={id} />,
            },
          ],
        }}
        flags={{ loadCodeFromServer: true }}
        testCases={test_cases}
      />
      <ProgressModal
        {...progressModal}
        grantedExperience={mapExpToProblem[difficulty]}
        problemTitle={title}
        completedDate={data.completed_date}
      />
    </>
  );
};

export default PracticeProblemSolution;
