import { Checkbox, Skeleton, VStack } from '@chakra-ui/react';
import { useQueryClient } from '@tanstack/react-query';
import { useQueryWithErrorBoundary, useSupabaseTypedClient } from 'hooks/reactQuery';
import { useToastWrapper } from 'hooks/useToastWrapper';
import { useState } from 'react';

import { useCurrentUser } from 'auth/CurrentUserProvider';
import SettingsTile from 'components/Billing/SettingsTile';
import {
  EdgeFunctionName,
  ManageEmailNotificationsParams,
  GetEmailNotificationsResponse,
} from 'types/edgeFunctions';

export const EmailNotificationsSettings = () => {
  const [isLoading, setIsLoading] = useState(false);

  const supabase = useSupabaseTypedClient();
  const queryClient = useQueryClient();

  const { privateEmail } = useCurrentUser();

  const { toastSuccess, toastError } = useToastWrapper();

  const { data, isPending } = useQueryWithErrorBoundary({
    queryKey: [EdgeFunctionName.GetEmailNotifications, privateEmail],
    queryFn: async () => {
      const { data, error } = await supabase.functions.invoke<GetEmailNotificationsResponse>(
        EdgeFunctionName.GetEmailNotifications
      );

      if (error) throw error;

      return data;
    },
  });

  const handleAppUpdatesChange = async () => {
    try {
      setIsLoading(true);

      const { error } = await supabase.functions.invoke(EdgeFunctionName.ManageEmailNotifications, {
        body: {
          shouldSubscribeToAppUpdates: !data?.isSubscribedToAppUpdates,
        } as ManageEmailNotificationsParams,
      });

      if (error) throw error;

      await queryClient.invalidateQueries({
        queryKey: [EdgeFunctionName.GetEmailNotifications, privateEmail],
      });

      toastSuccess('BigDevSoon email notifications updated');
    } catch (error: any) {
      toastError(JSON.stringify(error));
    } finally {
      setIsLoading(false);
    }
  };

  const handleBiweeklyNewsletterChange = async () => {
    try {
      setIsLoading(true);

      const { error } = await supabase.functions.invoke(EdgeFunctionName.ManageEmailNotifications, {
        body: {
          shouldSubscribeToBiWeeklyNewsletter: !data?.isSubscribedToBiWeeklyNewsletter,
        } as ManageEmailNotificationsParams,
      });

      if (error) throw error;

      await queryClient.invalidateQueries({
        queryKey: [EdgeFunctionName.GetEmailNotifications, privateEmail],
      });

      toastSuccess('Biweekly newsletter email notifications updated');
    } catch (error: any) {
      toastError(JSON.stringify(error));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <SettingsTile title="Email notifications" subtitle="Stay informed and never miss an update!">
        {isPending || isLoading ? (
          <VStack>
            <Skeleton width="100%" height="24px" />
            <Skeleton width="100%" height="24px" />
          </VStack>
        ) : (
          <VStack alignItems="flex-start">
            <Checkbox isChecked={data?.isSubscribedToAppUpdates} onChange={handleAppUpdatesChange}>
              Send me emails from BigDevSoon (e.g. app updates, promo codes, etc.)
            </Checkbox>
            <Checkbox
              isChecked={data?.isSubscribedToBiWeeklyNewsletter}
              onChange={handleBiweeklyNewsletterChange}
            >
              Send me emails from LevelUp Biweekly Newsletter (e.g. job boards, tech news, etc.)
            </Checkbox>
          </VStack>
        )}
      </SettingsTile>
    </>
  );
};
