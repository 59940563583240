import {
  Box,
  Image,
  Center,
  useDisclosure,
  useTheme,
  ImageProps,
  VStack,
  Highlight,
} from '@chakra-ui/react';

import Icon from '../Icon';
import Modal from '../Modal';
import Text from '../Text';

interface IImagePreviewModalProps {
  url: string;
  title: string;
  height?: number;
  imageProps?: ImageProps;
  fullScreenProps?: ImageProps;
  objectFit?: 'contain' | 'initial';
}

const ImagePreviewModal = ({
  url,
  title,
  imageProps,
  fullScreenProps,
  objectFit = 'contain',
}: IImagePreviewModalProps) => {
  const { borderRadius } = useTheme();

  const imagePreviewModal = useDisclosure();

  return (
    <>
      <Box
        position="relative"
        width="100%"
        cursor="pointer"
        borderRadius={borderRadius}
        onClick={(e) => {
          e.stopPropagation();

          imagePreviewModal.onOpen();
        }}
        overflow="hidden"
      >
        <Image
          width="100%"
          height={350}
          htmlWidth={1440}
          htmlHeight={1080}
          backgroundColor="whiteAlpha.200"
          aspectRatio="4 / 3"
          src={url}
          fallbackStrategy="onError"
          fallback={
            <Center
              width="100%"
              height="100%"
              minHeight="300px"
              background="white"
              borderTopLeftRadius={borderRadius}
              borderTopRightRadius={borderRadius}
            >
              <Text color="black">Image not found</Text>
            </Center>
          }
          alt={`${title} screenshot image`}
          objectFit={objectFit}
          borderTopLeftRadius={8}
          borderTopRightRadius={8}
          transition="all 0.2s ease-out"
          _hover={{ transform: 'scale(1.1)' }}
          {...imageProps}
        />
        <Box
          position="absolute"
          right="10px"
          bottom="10px"
          backgroundColor="gray.800"
          borderRadius={borderRadius}
          p={1}
        >
          <Icon type="Fullscreen" />
        </Box>
      </Box>
      <Modal header={null} footer={null} {...imagePreviewModal} size="4xl">
        <VStack spacing={4} pt={6}>
          <Box maxHeight="75vh" overflowY="auto" borderRadius={borderRadius} textAlign="center">
            <Image
              width="100%"
              height="auto"
              htmlWidth={1440}
              htmlHeight={1080}
              aspectRatio="4 / 3"
              src={url}
              fallbackStrategy="onError"
              objectFit={objectFit}
              borderRadius={borderRadius}
              fallback={
                <Center
                  width="100%"
                  height="100%"
                  minHeight="300px"
                  background="white"
                  borderRadius={borderRadius}
                >
                  <Text color="black">Image not found</Text>
                </Center>
              }
              alt={`${title} screenshot image`}
              {...fullScreenProps}
            />
          </Box>
          <Text fontSize="small" fontWeight="regular" as="span" opacity="default" mb={4}>
            <Highlight query={title} styles={{ px: 1, py: 0.5, bg: 'gray.200', borderRadius: 4 }}>
              {title}
            </Highlight>
          </Text>
        </VStack>
      </Modal>
    </>
  );
};

export default ImagePreviewModal;
