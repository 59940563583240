import { SupabaseClient } from '@supabase/supabase-js';
import { useQueryClient } from '@tanstack/react-query';
import { useInfiniteQueryWithErrorBoundary, useSupabaseTypedClient } from 'hooks/reactQuery';
import { useRef, useCallback, useEffect } from 'react';

import { Plan_Type_Enum, Support_Type_Enum } from 'types/databaseEnums';
import { GetCommunityQuestionsParams, GetCommunityQuestionsResponse } from 'types/edgeFunctions';
import { TanstackQueryName } from 'types/frontend';
import { Database } from 'types/supabase';

interface IGetCommunityQuestionsQueryFnParams {
  supabase: SupabaseClient<Database>;
  entityId: string;
  type: Support_Type_Enum;
  limit?: number | null;
  pageParam?: unknown | null;
}

export const getCommunityQuestionsQueryFn = async ({
  supabase,
  entityId,
  limit = 6,
  pageParam,
  type,
}: IGetCommunityQuestionsQueryFnParams) => {
  const communityQuestionsQuery = supabase
    .from('support_center')
    .select(
      'id, message, created_at, thread_id, user_profile_view (id, avatar_url, name, screen_name, plan, experience)'
    )
    .not('user_id', 'is', null)
    .eq('entity_id', entityId)
    .eq('type', type)
    .limit(limit || 6)
    .order('created_at', { ascending: false });

  if (pageParam) {
    communityQuestionsQuery.lt('created_at', pageParam);
  }

  const communityQuestions = await communityQuestionsQuery;

  if (communityQuestions.error) throw communityQuestions.error;

  return communityQuestions.data.map((communityQuestion) => ({
    id: communityQuestion.id,
    created_at: communityQuestion.created_at,
    message: communityQuestion.message || '',
    thread_id: communityQuestion.thread_id || '',
    user: {
      id: communityQuestion.user_profile_view?.id || '',
      avatar_url: communityQuestion.user_profile_view?.avatar_url || '',
      name: communityQuestion.user_profile_view?.name || 'Account deleted',
      screen_name: communityQuestion.user_profile_view?.screen_name || 'Account deleted',
      plan: (communityQuestion.user_profile_view?.plan as Plan_Type_Enum) || Plan_Type_Enum.Free,
      xp: communityQuestion.user_profile_view?.experience || 0,
    },
  }));
};

interface IUseGetCommunityQuestionsResponse {
  data: GetCommunityQuestionsResponse | undefined | null;
  isFetching: boolean;
  ref: any;
}

export const useGetCommunityQuestions = ({
  entity_id,
  type,
}: GetCommunityQuestionsParams): IUseGetCommunityQuestionsResponse => {
  const supabase = useSupabaseTypedClient();
  const queryClient = useQueryClient();

  const { data, fetchNextPage, hasNextPage, isFetching } = useInfiniteQueryWithErrorBoundary({
    queryKey: [TanstackQueryName.GetCommunityQuestions, entity_id],
    queryFn: async ({ pageParam }) => {
      return getCommunityQuestionsQueryFn({
        supabase,
        entityId: entity_id,
        limit: 6,
        type,
        pageParam,
      });
    },
    placeholderData: {
      pageParams: [],
      pages: [],
    },
    initialPageParam: null,
    getNextPageParam: (lastPage) => {
      if (lastPage && Array.isArray(lastPage) && lastPage?.length > 0) {
        const lastCommunityQuestionCreatedAt = lastPage[lastPage.length - 1]?.created_at;

        return lastCommunityQuestionCreatedAt;
      }

      return undefined;
    },
  });

  const observer = useRef<IntersectionObserver>();
  const lastCommunityQuestionRef = useCallback(
    (node) => {
      if (isFetching) return;

      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting && hasNextPage) {
            fetchNextPage();
          }
        },
        {
          rootMargin: '100px',
        }
      );

      if (node) observer.current.observe(node);
    },
    [fetchNextPage, hasNextPage, isFetching]
  );

  useEffect(() => {
    return () => {
      queryClient.removeQueries({
        queryKey: [TanstackQueryName.GetCommunityQuestions, entity_id],
      });
    };
  }, []);

  return {
    data,
    isFetching,
    ref: lastCommunityQuestionRef,
  };
};
