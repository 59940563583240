import { Box, Container, Grid, GridItem, Stack } from '@chakra-ui/react';

import { SEO } from 'components/SEO';
import StickyMenu from 'components/StickyMenu';
import Text from 'components/Text';

import { ConnectedAppsSettings } from './ConnectedAppsSettings';
import { DeleteAccountSettings } from './DeleteAccountSettings';
import { EmailNotificationsSettings } from './EmailNotificationsSettings';
import { EmailSettings } from './EmailSettings';
import { PrivacyControlsSettings } from './PrivacyControlsSettings';
import { ReferralLinkSettings } from './ReferralLinkSettings';

const EMAIL_ID = 'email';
const CONNECTED_APPS_ID = 'connected-apps';
const REFERRAL_ID = 'referral-link';
const PRIVACY_CONTROLS_ID = 'privacy-controls';
const EMAIL_NOTIFICATIONS_ID = 'email-notifications';
const DANGER_ZONE_ID = 'danger-zone';

const Settings = () => {
  return (
    <Box width="100%" overflowX="hidden">
      <SEO
        title={`BigDevSoon | Settings`}
        description="Configure or change your account settings on this page"
        url={`${process.env.REACT_APP_SITE_URL}settings`}
      />
      <Container maxW="1400px">
        <Stack spacing={4}>
          <Text fontSize="xxLarge" fontWeight="semibold" py={4}>
            Settings
          </Text>
          <Grid templateColumns="repeat(12, 1fr)" gap={8}>
            <GridItem colSpan={[12, 12, 12, 3]}>
              <StickyMenu
                tabs={[
                  { id: EMAIL_ID, name: 'Email' },
                  { id: CONNECTED_APPS_ID, name: 'Connected apps' },
                  { id: REFERRAL_ID, name: 'Referral link' },
                  { id: PRIVACY_CONTROLS_ID, name: 'Privacy controls' },
                  { id: EMAIL_NOTIFICATIONS_ID, name: 'Email notifications' },
                  { id: DANGER_ZONE_ID, name: 'Danger zone', background: 'red.500' },
                ]}
              />
            </GridItem>
            <GridItem colSpan={[12, 12, 12, 9]} mt={2}>
              <Stack spacing={8}>
                <div id={EMAIL_ID}>
                  <EmailSettings />
                </div>
                <div id={CONNECTED_APPS_ID}>
                  <ConnectedAppsSettings />
                </div>
                <div id={REFERRAL_ID}>
                  <ReferralLinkSettings />
                </div>
                <div id={PRIVACY_CONTROLS_ID}>
                  <PrivacyControlsSettings />
                </div>
                <div id={EMAIL_NOTIFICATIONS_ID}>
                  <EmailNotificationsSettings />
                </div>
                <div id={DANGER_ZONE_ID}>
                  <DeleteAccountSettings />
                </div>
              </Stack>
            </GridItem>
          </Grid>
        </Stack>
      </Container>
    </Box>
  );
};

export default Settings;
