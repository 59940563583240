import {
  HStack,
  Container,
  Stack,
  Box,
  useTheme,
  VStack,
  SimpleGrid,
  Divider,
  IconButton,
  Center,
  useDisclosure,
} from '@chakra-ui/react';
import { format } from 'date-fns';
import { useGetProfileCodeQuery } from 'hooks/queries/profile/useGetProfileCodeQuery';
import { useGetUserIdQuery } from 'hooks/queries/useGetUserIdQuery';
import { useGetUserProfileQuery } from 'hooks/queries/useGetUserProfileQuery';
import { useScrollToTop } from 'hooks/useScrollToTop';
import { useEffect, useState } from 'react';
import { Navigate, useLocation, useParams } from 'react-router-dom';

import { useCurrentUser } from 'auth/CurrentUserProvider';
import { BigBadge } from 'components/BigBadge';
import Button from 'components/Button';
import CodePreviewCard from 'components/CodePreviewCard';
import { EmptyContent } from 'components/EmptyContent';
import GradientPopover from 'components/GradientPopover';
import Icon from 'components/Icon';
import Link from 'components/Link';
import { LinkInput } from 'components/LinkInput';
import Loader from 'components/Loader';
import PageLoader from 'components/PageLoader';
import { SEO } from 'components/SEO';
import ShareButtons from 'components/ShareButtons';
import Tabs from 'components/Tabs';
import Text from 'components/Text';
import UserAvatar from 'components/UserAvatar';
import { DATE_FORMAT } from 'config/constants/plans';
import { useMediaQueries } from 'layout/MediaQueriesProvider';
import { CodeEditorType } from 'types/edgeFunctions';
import { ERoute, TanstackQueryName } from 'types/frontend';
import { referralBuilder } from 'utils/strings';

import Achievements from './Achievements';
import MyCollections from './MyCollections';

const mapIndexToCodeType: Record<number, CodeEditorType> = {
  0: 'project',
  1: 'challenge',
  2: 'problem',
  3: 'prototype',
  4: 'code-frame',
};

const PreviewProfile = () => {
  useScrollToTop();

  const [tabIndex, setTabIndex] = useState(0);
  const [profileTabIndex, setProfileTabIndex] = useState(0);
  const type = mapIndexToCodeType[tabIndex];

  const { profileScreenName } = useParams();
  const { state } = useLocation();

  useEffect(() => {
    if (state?.fromAchievements) {
      setProfileTabIndex(3);
    }
  }, [state]);

  const {
    screenName,
    referralCode,
    id: currentUserId,
    hasExhaustedReferralLink,
  } = useCurrentUser();

  const currentScreenName = profileScreenName || screenName;

  const { data: userId, isPending: isPendingUserId } = useGetUserIdQuery({
    userScreenName: currentScreenName,
  });

  const { data: userProfile, isPending: isPendingUserProfile } = useGetUserProfileQuery({
    userId,
  });

  const { data: profileCodeData, isPending: isPendingProfileCode } = useGetProfileCodeQuery({
    userId,
    type,
  });

  const { borderRadius, opacity } = useTheme();
  const { isMobile, isSmallScreen } = useMediaQueries();

  const achievementsModal = useDisclosure();

  const renderProfileTabsContent = () => {
    if (profileTabIndex === 0) {
      const emptyBioText = isMyProfile ? (
        <>
          You have not added your Bio yet, <Link to={ERoute.Profile}>edit</Link> your profile to
          introduce yourself. 😊
        </>
      ) : (
        'This user has not added Bio yet.'
      );

      return (
        <VStack alignItems="flex-start">
          <Text fontWeight="semibold">Bio</Text>
          <Text fontSize="small" fontWeight="regular" fontStyle="italic">
            {userProfile?.bio || emptyBioText}
          </Text>
        </VStack>
      );
    }

    if (profileTabIndex === 1) {
      const emptyGoalText = isMyProfile ? (
        <>
          You have not added your Goal yet, <Link to={ERoute.Profile}>edit</Link> your profile and
          tell the world what you are aiming for. 👀
        </>
      ) : (
        'This user has not added Goal yet.'
      );

      return (
        <VStack alignItems="flex-start">
          <Text fontWeight="semibold">Bio</Text>
          <Text fontSize="small" fontWeight="regular" fontStyle="italic">
            {userProfile?.goal || emptyGoalText}
          </Text>
        </VStack>
      );
    }

    if (profileTabIndex === 2) {
      return (
        <VStack alignItems="flex-start" width="100%">
          <HStack justifyContent="space-between" width="100%">
            <Text fontWeight="semibold">Stats</Text>
            <Text fontSize="micro" fontWeight="regular" opacity="secondary">
              Joined {format(new Date(userProfile?.created_at || ''), DATE_FORMAT)}
            </Text>
          </HStack>
          <Box overflowX="auto">
            <MyCollections
              experience={userProfile?.experience || 0}
              likes={userProfile?.likes || 0}
              rank={userProfile?.rank || '-'}
            />
          </Box>
        </VStack>
      );
    }

    if (profileTabIndex === 3) {
      return (
        <Achievements
          isAchievementsModalOpen={achievementsModal.isOpen}
          onAchievementsModalClose={achievementsModal.onClose}
          onAchievementsModalOpen={achievementsModal.onOpen}
          userId={userId!}
        />
      );
    }

    return null;
  };

  const getInvalidateKeys = (
    type: CodeEditorType,
    userId: string,
    parentId: string,
    solutionUrl: string
  ) => {
    const baseKeys = [
      [TanstackQueryName.GetUserCode, currentUserId, 0],
      [TanstackQueryName.GetUserCode, currentUserId, 1],
      [TanstackQueryName.GetUserCode, currentUserId, 2],
    ];

    if (type === 'project') {
      return [
        ...baseKeys,
        [TanstackQueryName.GetProjectSolutions, parentId],
        [TanstackQueryName.GetProjectSolution, solutionUrl],
      ];
    }

    if (type === 'challenge') {
      return [
        ...baseKeys,
        [TanstackQueryName.GetChallengeSolutions, parentId],
        [TanstackQueryName.GetChallengeSolution, solutionUrl],
      ];
    }

    if (type === 'problem') {
      return [
        ...baseKeys,
        [TanstackQueryName.GetPracticeProblemsSolutions, parentId],
        [TanstackQueryName.GetPracticeProblemSolution, solutionUrl],
      ];
    }

    if (type === 'code-frame') {
      return [
        ...baseKeys,
        [TanstackQueryName.GetCodeFrames, currentUserId],
        [TanstackQueryName.GetCodeFrames, null],
        [TanstackQueryName.GetCodeFrame, solutionUrl],
      ];
    }

    if (type === 'prototype') {
      return [
        ...baseKeys,
        [TanstackQueryName.GetPrototypes, currentUserId],
        [TanstackQueryName.GetPrototypes, null],
        [TanstackQueryName.GetPrototype, solutionUrl],
      ];
    }

    return baseKeys;
  };

  const isMyProfile = screenName === profileScreenName;

  const inviteLink = referralBuilder(referralCode);

  if (isPendingUserId || isPendingUserProfile) {
    return <PageLoader shift />;
  }

  if (!userProfile) {
    return <Navigate to={ERoute.Dashboard} replace />;
  }

  return (
    <Box width="100%" overflowX="hidden">
      <SEO
        title={`BigDevSoon | Preview profile`}
        description={`${userProfile?.name}'s profile`}
        url={`${process.env.REACT_APP_SITE_URL}profile/${profileScreenName || screenName}`}
      />
      <Container maxW="1400px">
        <Stack spacing={4}>
          <HStack spacing={5} py={4}>
            <Text fontSize="xxLarge" fontWeight="semibold">
              Profile
            </Text>
            {isMyProfile && (
              <Button scheme="gradient" to={ERoute.Profile}>
                Edit
              </Button>
            )}
          </HStack>
          <Box
            backgroundColor="whiteAlpha.200"
            boxShadow="0px 10px 20px 0px rgba(0, 0, 0, 0.15)"
            p={4}
            position="relative"
            borderRadius={borderRadius}
          >
            <Stack
              spacing={[10, 10, 8, 8]}
              direction={['column', 'column', 'row', 'row']}
              alignItems="center"
              justifyContent="center"
            >
              <VStack spacing={4} pl={[0, 0, 2, 2]}>
                <UserAvatar
                  size="xl"
                  name={userProfile?.name}
                  src={userProfile?.avatar_url || ''}
                  experience={userProfile?.experience || 0}
                  hideCircularProgress={!isMyProfile}
                />
                <VStack spacing={1}>
                  <HStack>
                    <Text fontSize="large" maxW="160px" isTruncated align="center">
                      {userProfile?.name}
                    </Text>
                    {userProfile?.plan && <BigBadge plan={userProfile.plan} showOnlySubscribed />}
                  </HStack>

                  {userProfile?.location && (
                    <HStack justifyContent="center" opacity={opacity.secondary}>
                      <Icon type="Location" />
                      <Text fontWeight="regular">{userProfile.location}</Text>
                    </HStack>
                  )}
                </VStack>
                {userProfile?.public_email && (
                  <Button
                    leftIcon={<Icon type="Email" />}
                    scheme="outline"
                    href={`mailto:${userProfile.public_email}`}
                  >
                    Send Email
                  </Button>
                )}
              </VStack>
              <VStack width="100%" alignItems="flex-start" spacing={3} overflowX="hidden">
                <HStack
                  width="100%"
                  justifyContent={['center', 'center', 'flex-start', 'flex-start']}
                >
                  <Box overflowX="auto">
                    <Tabs
                      tabs={['Bio', 'Goal', 'Stats', 'Achievements']}
                      tabIndex={profileTabIndex}
                      onChange={(index) => setProfileTabIndex(index)}
                      selectedColor="whiteAlpha.300"
                    />
                  </Box>
                  <Box position="absolute" right={4} top={4}>
                    {isMyProfile && (
                      <GradientPopover
                        tooltip="Share your referral link with friends"
                        triggerComponent={
                          <IconButton aria-label="share referral link popover with share buttons and link">
                            <Icon type="InviteFriends" />
                          </IconButton>
                        }
                        width={isMobile ? '95vw' : '500px'}
                        header="Share your referral link"
                      >
                        <VStack alignItems="flex-start" spacing={4} width="100%">
                          <ShareButtons
                            text={`Join me on becoming BIG Developer journey on BigDevSoon. 🚀`}
                            link={inviteLink}
                            emailSubject={`Hey, wanted to invite you to this awesome code learning & creating platform called BigDevSoon`}
                          />
                          <LinkInput />
                          {!hasExhaustedReferralLink && (
                            <HStack spacing={1}>
                              <Text fontWeight="regular" opacity="secondary">
                                and collect
                              </Text>
                              <Icon type="Bigtokens" />
                              <Text fontSize="small">1 BigToken</Text>
                              <Text fontWeight="regular" opacity="secondary">
                                for each registration.
                              </Text>
                              <Text fontWeight="regular" opacity="secondary" fontSize="micro">
                                (up to 5)
                              </Text>
                            </HStack>
                          )}
                        </VStack>
                      </GradientPopover>
                    )}
                  </Box>
                </HStack>
                <Box
                  p={4}
                  borderRadius={borderRadius}
                  height="200px"
                  width="100%"
                  overflowX="auto"
                  backgroundColor="whiteAlpha.200"
                >
                  {renderProfileTabsContent()}
                </Box>
              </VStack>
            </Stack>
            <Divider my={4} />
            <HStack width="100%" justifyContent="space-between">
              <HStack spacing={2.5}>
                <Link href={userProfile?.github_url} isExternal>
                  <IconButton
                    isRound
                    aria-label="GitHub"
                    icon={<Icon type="Github" label="GitHub" />}
                  />
                </Link>
                {userProfile?.website_url && (
                  <Link href={`https://${userProfile?.website_url}`} isExternal>
                    <IconButton
                      isRound
                      aria-label="Website Portfolio"
                      icon={<Icon type="Website" label="Website Portfolio" />}
                    />
                  </Link>
                )}
                {userProfile?.devto_url && (
                  <Link href={`https://dev.to/${userProfile?.devto_url}`} isExternal>
                    <IconButton
                      isRound
                      aria-label="Dev.to User Website"
                      icon={<Icon type="Devto" label="Dev.to" />}
                    />
                  </Link>
                )}
                {userProfile?.twitter_url && (
                  <Link href={`https://x.com/${userProfile?.twitter_url}`} isExternal>
                    <IconButton
                      isRound
                      aria-label="X User Website"
                      icon={<Icon type="Twitter" label="Twitter" />}
                    />
                  </Link>
                )}
                {userProfile?.linkedin_url && (
                  <Link
                    href={`https://www.linkedin.com/in/${userProfile?.linkedin_url}`}
                    isExternal
                  >
                    <IconButton
                      isRound
                      aria-label="LinkedIn User Website"
                      icon={<Icon type="Linkedin" label="LinkedIn" />}
                    />
                  </Link>
                )}
              </HStack>
              <HStack spacing={2.5}>
                {userProfile?.has_beta_badge && (
                  <Icon type="BetaUserBadge" label="Beta User" size={32} />
                )}
                {userProfile?.has_monthly_badge && (
                  <Icon type="MonthlySubscriberBadge" label="Monthly Supporter" size={32} />
                )}
                {userProfile?.has_yearly_badge && (
                  <Icon type="YearlySubscriberBadge" label="Yearly Daredevil" size={32} />
                )}
                {userProfile?.has_early_bird_badge && (
                  <Icon type="EarlyBird" label="Early Bird" size={32} />
                )}
                {userProfile?.has_lifetime_badge && (
                  <Icon type="LifetimeBadge" label="Lifetime Legend" size={32} />
                )}
              </HStack>
            </HStack>
          </Box>
          <Box
            backgroundColor="whiteAlpha.200"
            boxShadow="0px 10px 20px 0px rgba(0, 0, 0, 0.15)"
            p={4}
            borderRadius={borderRadius}
          >
            <VStack spacing={4} alignItems="flex-start">
              <Box width={isSmallScreen ? '100%' : 'fit-content'}>
                <Tabs
                  tabs={['Projects', 'Challenges', 'Problems', 'Prototypes', 'Code Frames']}
                  onChange={(index) => setTabIndex(index)}
                  tabIndex={tabIndex}
                />
              </Box>
              {profileCodeData && profileCodeData.length > 0 ? (
                <SimpleGrid columns={[1, 1, 1, 2, 2, 3]} gap={4} width="100%">
                  {profileCodeData.map((code) => (
                    <CodePreviewCard
                      key={code.id}
                      width="100%"
                      height="300px"
                      // Even if other user's profiles we don't want to show redundant avatars
                      isMySolution
                      canEdit={isMyProfile}
                      queryKey={[TanstackQueryName.GetProfileCode, userId!, type]}
                      invalidateKeys={getInvalidateKeys(
                        code.type as CodeEditorType,
                        code.user_id!,
                        code.parent_id!,
                        code.solution_url!
                      )}
                      {...code}
                    />
                  ))}
                </SimpleGrid>
              ) : isPendingProfileCode ? (
                <Center width="100%" height="378px">
                  <Loader />
                </Center>
              ) : (
                <Box mb={8} width="100%">
                  <EmptyContent type={type} mt="0px" />
                </Box>
              )}
            </VStack>
          </Box>
        </Stack>
      </Container>
    </Box>
  );
};

export default PreviewProfile;
