import { HStack, VStack } from '@chakra-ui/react';
import { format } from 'date-fns';

import Button from 'components/Button';
import Icon from 'components/Icon';
import Modal from 'components/Modal';
import Text from 'components/Text';
import { DATE_FORMAT } from 'config/constants/plans';
import { IModal } from 'types/frontend';

interface IChatMessagesLimitModalProps extends IModal {
  nextChatMessageRecycleTime: Date | null;
}

export const ChatMessagesLimitModal = ({
  isOpen,
  onClose,
  nextChatMessageRecycleTime,
}: IChatMessagesLimitModalProps) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      header={
        <HStack>
          <Icon type="ChatGPT" size={40} />
          <Text fontSize="xLarge" fontWeight="semibold">
            Chat messages limit reached
          </Text>
        </HStack>
      }
      footer={
        <>
          <Button scheme="gradient" onClick={onClose} autoFocus>
            I got it
          </Button>
        </>
      }
    >
      <VStack align={'start'}>
        <Text fontWeight="regular">You have reached chat messages limit for this month.</Text>
        <HStack spacing={1}>
          <Text fontWeight="regular">Your limit renews on</Text>
          <Text fontWeight="semibold">
            {format(new Date(nextChatMessageRecycleTime || 0), DATE_FORMAT)}.
          </Text>
        </HStack>
      </VStack>
    </Modal>
  );
};
