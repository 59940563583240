import { Box } from '@chakra-ui/react';
import { differenceInSeconds } from 'date-fns';
import { useStopwatch } from 'react-timer-hook';

interface TimerProps {
  createdAt: string;
}

const Timer = ({ createdAt }: TimerProps) => {
  const stopwatchOffset = new Date();

  const secondsOffset = differenceInSeconds(new Date(), new Date(createdAt));
  stopwatchOffset.setSeconds(stopwatchOffset.getSeconds() + secondsOffset);

  const { days, seconds, minutes, hours } = useStopwatch({
    autoStart: true,
    offsetTimestamp: stopwatchOffset,
  });

  const formattedHours = hours < 10 ? `0${hours}` : hours;
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
  const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;

  return (
    <Box fontFamily="monospace">
      {days > 0 ? `${days}d` : ''} {formattedHours}:{formattedMinutes}:{formattedSeconds}
    </Box>
  );
};

export default Timer;
