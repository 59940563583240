import { useMediaQuery } from '@chakra-ui/react';
import React, { ReactNode, useContext, useEffect, useState } from 'react';

type MediaQueries = {
  isDesktopHome: boolean;
  isTabletHome: boolean;
  isDesktopProjects: boolean;
  isDesktopCancelSubscriptionButton: boolean;
  isHorizontalProject: boolean;
  isHorizontalProjectHeader: boolean;
  isDesktopNavigation: boolean;
  isOnboardingHorizontal: boolean;
  isHorizontalCardHeader: boolean;
  isSmallScreen: boolean;
  isMobile: boolean;
};

type MediaQueriesContextData = MediaQueries;

export const MediaQueriesContext = React.createContext({} as MediaQueriesContextData);

export const MediaQueriesProvider = ({ children }: { children: ReactNode }) => {
  const [isLoading, setIsLoading] = useState(true);

  const [isDesktopHome] = useMediaQuery('(min-width: 1300px)');
  const [isTabletHome] = useMediaQuery('(min-width: 1200px)');
  const [isDesktopProjects] = useMediaQuery('(min-width: 1050px)');
  const [isDesktopCancelSubscriptionButton] = useMediaQuery('(min-width: 570px)');
  const [isHorizontalProject] = useMediaQuery('(min-width: 768px)');
  const [isHorizontalProjectHeader] = useMediaQuery('(min-width: 1180px)');
  const [isDesktopNavigation] = useMediaQuery('(min-width: 960px)');
  const [isOnboardingHorizontal] = useMediaQuery('(min-width: 760px)');
  const [isHorizontalCardHeader] = useMediaQuery('(min-width: 870px)');
  const [isSmallScreen] = useMediaQuery('(max-width: 768px)');
  const [isMobile] = useMediaQuery('(max-width: 470px)');

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsLoading(false);
    }, 0);

    return () => clearTimeout(timeout);
  }, []);

  if (isLoading) {
    return null;
  }

  return (
    <MediaQueriesContext.Provider
      value={{
        isDesktopHome,
        isTabletHome,
        isDesktopProjects,
        isDesktopCancelSubscriptionButton,
        isHorizontalProject,
        isHorizontalProjectHeader,
        isDesktopNavigation,
        isOnboardingHorizontal,
        isHorizontalCardHeader,
        isSmallScreen,
        isMobile,
      }}
    >
      {children}
    </MediaQueriesContext.Provider>
  );
};

export const useMediaQueries = (): MediaQueriesContextData => {
  const context = useContext(MediaQueriesContext);

  if (!context) {
    throw new Error('useMediaQueries must be used within an MediaQueriesContextProvider');
  }

  return context;
};
