import { HStack, useDisclosure, useTheme, VStack } from '@chakra-ui/react';
import { format } from 'date-fns';

import Button from 'components/Button';
import { UndoCancelationModal } from 'components/Modal/UndoCancelationModal';
import Text from 'components/Text';

interface ICanceledState {
  current_period_end: Date;
}
export const CanceledState = ({ current_period_end }: ICanceledState) => {
  const theme = useTheme();

  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <UndoCancelationModal isOpen={isOpen} onClose={onClose} />
      <VStack align="stretch" spacing={6}>
        <VStack
          bg="whiteAlpha.200"
          borderRadius={theme.borderRadius}
          px={6}
          py={5}
          align="flex-start"
          boxShadow="0px 10px 20px rgba(0, 0, 0, 0.15)"
        >
          <Text fontSize="small">You have cancelled your subscription.</Text>
          <Text fontSize="small">
            You have access to all application features until your subscription ends on{' '}
            {format(current_period_end, 'dd MMM yyyy')}.
          </Text>
          <HStack>
            <Text fontSize="small">Changed your mind?</Text>
            <Text fontSize="small" opacity="secondary" fontWeight="regular">
              You can still undo your cancelation and back up right where you left off.
            </Text>
          </HStack>
        </VStack>

        <Button width="fit-content" onClick={onOpen}>
          Undo Cancellation
        </Button>
      </VStack>
    </>
  );
};
