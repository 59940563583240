import { Box, HStack, VStack } from '@chakra-ui/react';

import { Experience_Level_Enum } from 'types/databaseEnums';
import { mapExpToProject } from 'utils/constants';

import GradientTooltip from './GradientTooltip';
import Text from './Text';

interface ProjectDifficultyIndicatorProps {
  difficulty?: string;
}

type ProjectDifficultyIndicatorText = 'Junior' | 'Regular' | 'Senior';
type ProjectDifficultyIndicatorBackground = 'green.200' | 'yellow.200' | 'red.200';

type ProjectDifficultyIndicatorProperties = {
  text: ProjectDifficultyIndicatorText;
  background: ProjectDifficultyIndicatorBackground;
  label: string;
};

const mapProjectDifficultyIndicatorToProperties: Record<
  Experience_Level_Enum,
  ProjectDifficultyIndicatorProperties
> = {
  junior: {
    text: 'Junior',
    background: 'green.200',
    label: `Gain ${mapExpToProject.junior} XP upon project completion`,
  },
  regular: {
    text: 'Regular',
    background: 'yellow.200',
    label: `Gain ${mapExpToProject.regular} XP upon project completion`,
  },
  senior: {
    text: 'Senior',
    background: 'red.200',
    label: `Gain ${mapExpToProject.senior} XP upon project completion`,
  },
};

const ProjectDifficultyIndicator = ({ difficulty }: ProjectDifficultyIndicatorProps) => {
  const { text, background, label } =
    mapProjectDifficultyIndicatorToProperties[difficulty || Experience_Level_Enum.Junior];

  return (
    <GradientTooltip label={label}>
      <VStack alignItems="flex-start" spacing={1}>
        <Text fontSize="small" fontWeight="regular">
          {text} Level
        </Text>
        <HStack spacing={2}>
          <Box
            width="24px"
            height="6px"
            backgroundColor={
              ['Junior', 'Regular', 'Senior'].includes(text) ? background : 'whiteAlpha.300'
            }
            borderRadius="48px"
          />
          <Box
            width="24px"
            height="6px"
            backgroundColor={['Regular', 'Senior'].includes(text) ? background : 'whiteAlpha.300'}
            borderRadius="48px"
          />
          <Box
            width="24px"
            height="6px"
            backgroundColor={text === 'Senior' ? background : 'whiteAlpha.300'}
            borderRadius="48px"
          />
        </HStack>
      </VStack>
    </GradientTooltip>
  );
};

export default ProjectDifficultyIndicator;
