import { Center, HStack, VStack } from '@chakra-ui/react';

import BigTokens from 'components/BigTokens';
import Button from 'components/Button';
import Icon from 'components/Icon';
import Modal from 'components/Modal';
import { ProtectedElement } from 'components/ProtectedElement';
import Text from 'components/Text';
import { ERoute, IModal } from 'types/frontend';
import { pluralize } from 'utils/strings';

interface IUnlockFigmaModalProps extends IModal {
  tokens: number;
  isDownloading: boolean;
  onDownload: () => void;
}

export const UnlockFigmaModal = ({
  isOpen,
  onClose,
  tokens,
  isDownloading,
  onDownload,
}: IUnlockFigmaModalProps) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      header={
        <HStack>
          <Icon type="FigmaLogo" size={40} />
          <Text fontSize="xLarge" fontWeight="semibold">
            Download Figma Design
          </Text>
        </HStack>
      }
      footer={
        <>
          <Button scheme="ghost" onClick={onClose}>
            Cancel
          </Button>
          <Button
            leftIcon={<Icon type="UpgradePlan" />}
            scheme="gradient"
            to={ERoute.Pricing}
            autoFocus
          >
            Upgrade plan
          </Button>
        </>
      }
    >
      <VStack align={'start'}>
        <Text fontWeight="semibold">Experience the full Developer workflow.</Text>
        <HStack mt={2}>
          <Text>You can download this design for</Text>
          <BigTokens amount={tokens} />
          <Text>{pluralize('BigToken', tokens)}.</Text>
        </HStack>
      </VStack>
      <Center my={6}>
        <ProtectedElement
          requiredTokens={tokens}
          scheme="gradient"
          isLoading={isDownloading}
          onClick={async () => {
            await onDownload();
            onClose();
          }}
        >
          Download Figma Design
        </ProtectedElement>
      </Center>
      <Center my={6}>
        <Text fontSize="xLarge">OR</Text>
      </Center>
      <Text fontWeight="regular">
        You can upgrade your plan to either a monthly, yearly or lifetime
      </Text>
      <Text fontWeight="semibold">and access all of our Figma Designs.</Text>
    </Modal>
  );
};
