import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  HStack,
  Stack,
  Grid,
  GridItem,
  Box,
  useTheme,
  Divider,
  VStack,
  Center,
  FormHelperText,
  Textarea,
} from '@chakra-ui/react';
import { javascript as langJavascript } from '@codemirror/lang-javascript';
import { EditorView } from '@codemirror/view';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import CodeMirror from '@uiw/react-codemirror';
import { supabaseMutationFn, useSupabaseTypedClient } from 'hooks/reactQuery';
import { extractCodeFromStorage } from 'hooks/useCodeStorage';
import { useToastWrapper } from 'hooks/useToastWrapper';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import { useCurrentUser } from 'auth/CurrentUserProvider';
import { CODE_FREE_LIMIT, CODE_PAID_LIMIT } from 'components/DevSandbox/constants';
import Icon from 'components/Icon';
import Link from 'components/Link';
import { ProtectedElement } from 'components/ProtectedElement';
import Text from 'components/Text';
import ToggleCodePrivacySwitch from 'components/ToggleCodePrivacySwitch';
import { useMediaQueries } from 'layout/MediaQueriesProvider';
import {
  EdgeFunctionName,
  PracticeProblem,
  SubmitCodeParams,
  SubmitCodeResponse,
} from 'types/edgeFunctions';
import { TanstackQueryName } from 'types/frontend';

interface IBubbleCounterProps {
  count: number;
}

const BubbleCounter = ({ count }: IBubbleCounterProps) => {
  return (
    <Box width="24px" height="24px" backgroundColor="purple.400" borderRadius="50%">
      <Center>
        {count}
        <Text></Text>
      </Center>
    </Box>
  );
};

interface SubmitSolutionForm {
  title: string;
  feedback?: string;
}

const validationSchema = yup
  .object({
    title: yup
      .string()
      .required('Title is required')
      .max(80, 'Title is too long (maximum is 80 characters)'),
    feedback: yup.string().max(300, 'Feedback is too long (maximum is 300 characters)'),
  })
  .required();

interface ISubmitSolutionProps {
  practiceProblem: PracticeProblem;
}

const SubmitSolution = ({ practiceProblem }: ISubmitSolutionProps) => {
  const [allChecksPassed, setAllChecksPassed] = useState<boolean | undefined>(undefined);
  const [isPrivate, setIsPrivate] = useState(false);

  const { id: userId, isFree } = useCurrentUser();

  const storedJs = extractCodeFromStorage({
    type: 'problem',
    screenName: practiceProblem.screen_name,
    userId,
    code: 'js',
  });

  const { isDesktopNavigation } = useMediaQueries();

  const navigate = useNavigate();

  const supabase = useSupabaseTypedClient();

  const { pathname } = useLocation();

  const queryClient = useQueryClient();

  const { toastError } = useToastWrapper();

  const { borderRadius } = useTheme();

  const backTo = pathname.replace('/submit', '');

  const mutation = useMutation<SubmitCodeResponse, string, SubmitCodeParams>({
    mutationFn: supabaseMutationFn((params) =>
      supabase.functions.invoke<SubmitCodeResponse>(EdgeFunctionName.SubmitCode, {
        body: params as SubmitCodeParams,
      })
    ),
    onSuccess: async (data) => {
      setAllChecksPassed(data.all_checks_passed);

      if (data.all_checks_passed) {
        await Promise.all([
          queryClient.invalidateQueries({ queryKey: [TanstackQueryName.GetCurrentUser, userId] }),
          queryClient.invalidateQueries({
            queryKey: [TanstackQueryName.GetPracticeProblems, userId, 0],
          }),
          queryClient.invalidateQueries({
            queryKey: [TanstackQueryName.GetPracticeProblems, userId, 1],
          }),
          queryClient.invalidateQueries({
            queryKey: [TanstackQueryName.GetPracticeProblems, userId, 2],
          }),
          queryClient.invalidateQueries({
            queryKey: [TanstackQueryName.GetPracticeProblem, practiceProblem.screen_name],
          }),
          queryClient.invalidateQueries({
            queryKey: [TanstackQueryName.GetPracticeProblemsSolutions, practiceProblem.id],
          }),
          queryClient.invalidateQueries({
            queryKey: [TanstackQueryName.GetUserAchievements, userId],
          }),
        ]);

        setTimeout(() => {
          navigate(data.solution_url);
        }, 300);
      } else {
        return toastError('Some of the tests failed, try to fix them and submit again.');
      }
    },
    onError: () => {
      toastError(`Can't submit problem, remove any console logs from the code and try again.`);
    },
  });

  const {
    handleSubmit,
    register,
    formState: { errors },
    watch,
  } = useForm<SubmitSolutionForm>({
    resolver: yupResolver(validationSchema),
    mode: 'onSubmit',
    defaultValues: {
      title: practiceProblem.solution_title || '',
      feedback: '',
    },
  });

  const title = watch('title');
  const feedback = watch('feedback');

  const onSubmit = async (values: SubmitSolutionForm) => {
    const CODE_LIMIT = isFree ? CODE_FREE_LIMIT : CODE_PAID_LIMIT;

    if (storedJs.length > CODE_LIMIT) {
      return toastError(`Exceeded maximum ${CODE_LIMIT} characters of JS`);
    }

    await mutation.mutate({
      ...values,
      js: storedJs,
      type: 'problem',
      title,
      is_private: isPrivate,
      feedback,
      entityScreenName: practiceProblem.screen_name,
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <VStack spacing={3} overflowY="auto" h="80vh">
        <Box backgroundColor="whiteAlpha.200" borderRadius={borderRadius} p={4}>
          <Grid templateColumns="repeat(3, 1fr)" gap={8}>
            <GridItem colSpan={isDesktopNavigation ? 2 : 3}>
              <Stack spacing={8}>
                <Stack spacing={2}>
                  <FormControl isInvalid={Boolean(errors.title)}>
                    <FormLabel htmlFor="title">
                      <VStack alignItems="flex-start">
                        <HStack>
                          <BubbleCounter count={1} />
                          <Text fontSize="xLarge">Solution title</Text>
                        </HStack>
                        <Text opacity="secondary">
                          Try to use the title to easily identify your practice problem solution.
                        </Text>
                      </VStack>
                    </FormLabel>
                    <Input
                      {...register('title')}
                      placeholder={`e.g. My solution to ${practiceProblem.title} problem`}
                      variant="filled"
                      maxLength={80}
                      autoFocus
                      autoComplete="off"
                    />
                    <HStack justifyContent="space-between" alignItems="center">
                      {errors.title?.message ? (
                        <FormErrorMessage>{errors.title.message}</FormErrorMessage>
                      ) : (
                        <div />
                      )}
                      <FormHelperText>
                        <HStack spacing={1}>
                          <Text fontSize="micro" opacity="secondary" fontWeight="regular">
                            Characters remaining:
                          </Text>
                          <Text fontSize="micro" opacity="secondary" fontWeight="semibold">
                            {80 - title.length}
                          </Text>
                        </HStack>
                      </FormHelperText>
                    </HStack>
                  </FormControl>
                </Stack>
                <Stack spacing={2}>
                  <VStack alignItems="flex-start">
                    <HStack>
                      <BubbleCounter count={2} />
                      <Text fontSize="xLarge">Your code</Text>
                    </HStack>
                    <Text opacity="secondary">
                      {`Check it one more time before submitting the solution.`}
                    </Text>
                    <Box width="100%" maxH="200px" overflowY="auto" borderRadius={borderRadius}>
                      <CodeMirror
                        value={storedJs}
                        id="bds-code-editor-submit-practice-problem-js"
                        extensions={[
                          langJavascript({ jsx: true, typescript: true }),
                          EditorView.lineWrapping,
                        ]}
                        theme="dark"
                        basicSetup={{ foldGutter: false }}
                        editable={false}
                      />
                    </Box>
                  </VStack>
                </Stack>
                <Stack spacing={2}>
                  <FormControl isInvalid={Boolean(errors.feedback)}>
                    <FormLabel htmlFor="feedback">
                      <VStack alignItems="flex-start">
                        <HStack>
                          <BubbleCounter count={3} />
                          <Text fontSize="xLarge">Ask for feedback (optional)</Text>
                        </HStack>
                        <Text opacity="secondary">
                          {`Other people will be able to comment your solution based on this feedback request.`}
                        </Text>
                      </VStack>
                    </FormLabel>
                    <Textarea
                      {...register('feedback')}
                      placeholder="Please share the short brief of your solution and try to ask precisely
                      about the feedback you are looking for. If the message is on point,
                      answers will be too!"
                      variant="filled"
                      maxLength={300}
                      rows={5}
                      autoComplete="off"
                    />
                    <HStack justifyContent="space-between" alignItems="center">
                      {errors.feedback?.message ? (
                        <FormErrorMessage>{errors.feedback.message}</FormErrorMessage>
                      ) : (
                        <div />
                      )}
                      <FormHelperText>
                        <HStack spacing={1}>
                          <Text fontSize="micro" opacity="secondary" fontWeight="regular">
                            Characters remaining:
                          </Text>
                          <Text fontSize="micro" opacity="secondary" fontWeight="semibold">
                            {300 - (feedback?.length || 0)}
                          </Text>
                        </HStack>
                      </FormHelperText>
                    </HStack>
                  </FormControl>
                </Stack>
              </Stack>
            </GridItem>
            <GridItem colSpan={isDesktopNavigation ? 1 : 3}>
              <HStack height="100%" alignItems="flex-start">
                {isDesktopNavigation && (
                  <Divider orientation="vertical" width="1px" height="100%" />
                )}
                <VStack
                  ml={isDesktopNavigation ? 4 : 0}
                  spacing={8}
                  alignItems="flex-start"
                  width="100%"
                >
                  <HStack width="100%" justifyContent="space-between">
                    <Text fontSize="xLarge">Submit checks</Text>
                  </HStack>
                  <VStack alignItems="flex-start">
                    <HStack>
                      <Icon
                        type={
                          allChecksPassed === undefined
                            ? 'SubmitCheck'
                            : mutation.isPending
                            ? 'SubmitCheck'
                            : allChecksPassed
                            ? 'SubmitCheckPassed'
                            : 'SubmitCheckFailed'
                        }
                      />
                      <Text fontSize="large">All test cases pass</Text>
                    </HStack>
                    <Text fontWeight="regular" opacity="secondary">
                      Checking if the code passes server test cases.
                    </Text>
                    {allChecksPassed === false && <Link to={backTo}>Fix tests in Code Editor</Link>}
                  </VStack>
                  <ToggleCodePrivacySwitch
                    type="problem"
                    isNotCreated
                    togglePrivacyCodeParams={{
                      entityId: '',
                      isPrivate: false,
                      invalidateKeys: [],
                      queryKey: [],
                    }}
                    onChange={(isPrivate) => setIsPrivate(isPrivate)}
                  />
                  <ProtectedElement
                    scheme="gradient"
                    leftIcon={<Icon type="SubmitCode" />}
                    isLoading={mutation.isPending}
                    isDisabled={!title}
                    type="submit"
                    size="lg"
                    fullWidth
                  >
                    <Text>{allChecksPassed === false ? 'Retry' : 'Submit Solution'}</Text>
                  </ProtectedElement>
                </VStack>
              </HStack>
            </GridItem>
          </Grid>
        </Box>
      </VStack>
    </form>
  );
};

export default SubmitSolution;
