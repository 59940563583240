import { Box, Grid, GridItem } from '@chakra-ui/react';
import { forwardRef } from 'react';

import DevSandboxContent from './DevSandboxContent';
import DevSandboxFooter from './DevSandboxFooter';
import DevSandboxNavigation from './DevSandboxNavigation';
import DevSandboxProvider from './DevSandboxProvider';
import { IDevSandboxProps } from './types';

const DevSandbox = forwardRef((devSandboxProps: IDevSandboxProps, ref) => {
  const isFullPage = devSandboxProps.mode === 'full-page';

  if (isFullPage) {
    return (
      <DevSandboxProvider ref={ref} {...devSandboxProps}>
        <Box
          position="fixed"
          top="0"
          left="0"
          right="0"
          bottom="0"
          width="100vw"
          height="100vh"
          backgroundColor="gray.900"
          zIndex="modal"
        >
          <DevSandboxNavigation />
          <DevSandboxContent />
          <DevSandboxFooter />
        </Box>
      </DevSandboxProvider>
    );
  }

  return (
    <DevSandboxProvider ref={ref} {...devSandboxProps}>
      <Grid
        position="fixed"
        top="0"
        left="0"
        right="0"
        bottom="0"
        width="100vw"
        height="100vh"
        backgroundColor="gray.900"
        zIndex="modal"
        templateRows="60px 1fr 60px"
        templateColumns="1fr"
      >
        <GridItem rowSpan={1}>
          <DevSandboxNavigation />
        </GridItem>
        <GridItem rowSpan={1} overflow="auto">
          <DevSandboxContent />
        </GridItem>
        <GridItem rowSpan={1}>
          <DevSandboxFooter />
        </GridItem>
      </Grid>
    </DevSandboxProvider>
  );
});

export default DevSandbox;
