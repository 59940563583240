import { Box, Center, HStack, IconButton, Stack, VStack, useTheme } from '@chakra-ui/react';
import { useGetUserAchievementsQuery } from 'hooks/queries/useGetUserAchievementsQuery';

import GradientTooltip from 'components/GradientTooltip';
import Icon, { IconType } from 'components/Icon';
import Loader from 'components/Loader';
import Modal from 'components/Modal';
import ProjectElement from 'components/ProjectElement';
import Text from 'components/Text';
import { useMediaQueries } from 'layout/MediaQueriesProvider';
import { Achievement } from 'types/edgeFunctions';
import { IModal } from 'types/frontend';

interface IAchievementCardProps extends Achievement {}

const AchievementCard = ({ is_completed, type, name, description, xp }: IAchievementCardProps) => {
  const { borderRadius } = useTheme();
  const { isMobile } = useMediaQueries();

  const completedProps = is_completed ? { border: '1px solid', borderColor: 'green.200' } : {};

  return (
    <Box backgroundColor="whiteAlpha.200" borderRadius={borderRadius} p={4} {...completedProps}>
      <HStack justifyContent="space-between">
        <HStack spacing={4}>
          {is_completed ? (
            <Icon type={type as IconType} size={isMobile ? 40 : 48} />
          ) : (
            <Box
              border="1px solid"
              borderColor="whiteAlpha.200"
              width={isMobile ? '40px' : '48px'}
              height={isMobile ? '40px' : '48px'}
              borderRadius="50%"
            >
              <Center height="100%">
                <Text fontSize="large" fontWeight="semibold" opacity="secondary">
                  ?
                </Text>
              </Center>
            </Box>
          )}
          <VStack alignItems="flex-start">
            <Text fontSize={isMobile ? 'large' : 'xxLarge'}>{name}</Text>
            {!isMobile && <Text fontWeight="regular">{description}</Text>}
          </VStack>
        </HStack>
        <ProjectElement type="experience" experience={xp} />
      </HStack>
    </Box>
  );
};

interface IAchievementsModalProps extends IModal {
  achievements: Achievement[];
}

const AchievementsModal = ({ isOpen, onClose, achievements }: IAchievementsModalProps) => {
  return (
    <Modal size="3xl" isOpen={isOpen} onClose={onClose} header="Achievements" footer={null}>
      <Stack mb={6} pr={4} spacing={4} maxH="75vh" overflowY="auto">
        {achievements.map((achievement) => (
          <AchievementCard key={achievement.id} {...achievement} />
        ))}
      </Stack>
    </Modal>
  );
};

interface IAchievementsProps {
  isAchievementsModalOpen: boolean;
  onAchievementsModalOpen: () => void;
  onAchievementsModalClose: () => void;
  userId: string;
}

const Achievements = ({
  isAchievementsModalOpen,
  onAchievementsModalOpen,
  onAchievementsModalClose,
  userId,
}: IAchievementsProps) => {
  const { borderRadius } = useTheme();

  const { data, isPending } = useGetUserAchievementsQuery(userId);

  const completedAchievementsLength = data?.achievements.filter((a) => a.is_completed).length || 0;

  const sortedAchievements =
    data?.achievements
      .sort((a, b) => +b.is_completed - +a.is_completed)
      .sort((a, b) => Date.parse(a.timestamp) - Date.parse(b.timestamp)) || [];

  return (
    <VStack alignItems="flex-start" spacing={2}>
      <HStack>
        <Text fontWeight="semibold">
          Achievements ({isPending ? null : completedAchievementsLength}/
          {data?.achievements.length || 0})
        </Text>
        <GradientTooltip label="See all achievements">
          <IconButton
            size="xs"
            aria-label="Open all achievements"
            onClick={onAchievementsModalOpen}
            isRound
          >
            <Text fontWeight="semibold">?</Text>
          </IconButton>
        </GradientTooltip>
      </HStack>
      {isPending ? (
        <Center width="100%" height="100%">
          <Loader />
        </Center>
      ) : (
        <HStack spacing={0} overflowX="auto">
          {sortedAchievements.map((achievement) => (
            <Center
              py={4}
              px={2}
              mr={3}
              key={achievement.id}
              borderRadius={borderRadius}
              backgroundColor="whiteAlpha.200"
              width="170px"
              height="130px"
            >
              <VStack>
                {achievement.is_completed ? (
                  <Icon type={achievement.type as IconType} size={40} />
                ) : (
                  <Box
                    border="1px solid"
                    borderColor="whiteAlpha.200"
                    width="40px"
                    height="40px"
                    borderRadius="50%"
                  >
                    <Center height="100%">
                      <Text fontSize="large" fontWeight="semibold" opacity="secondary">
                        ?
                      </Text>
                    </Center>
                  </Box>
                )}
                <Text textAlign="center" fontSize="micro">
                  {achievement.name}
                </Text>
                <ProjectElement
                  type={achievement.is_completed ? 'experienceCompleted' : 'experience'}
                  experience={achievement.xp}
                />
              </VStack>
            </Center>
          ))}
        </HStack>
      )}
      <AchievementsModal
        isOpen={isAchievementsModalOpen}
        onClose={onAchievementsModalClose}
        achievements={sortedAchievements}
      />
    </VStack>
  );
};

export default Achievements;
