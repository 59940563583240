import { Box, VStack, useTheme, HStack } from '@chakra-ui/react';
import { useQueryWithErrorBoundary, useSupabaseTypedClient } from 'hooks/reactQuery';

import { ReactComponent as DiscordHomeIllustration } from 'assets/illustrations/DiscordHome.svg';
import DiscordButton from 'components/DiscordButton';
import Text from 'components/Text';

const Discord = () => {
  const { borderRadius } = useTheme();

  const supabase = useSupabaseTypedClient();

  const { data } = useQueryWithErrorBoundary({
    queryKey: ['discord-metadata-home'],
    queryFn: async () => {
      const { data, error } = await supabase
        .from('metadata')
        .select('value')
        .in('key', ['discord_current_members', 'discord_current_online']);

      if (error) throw error;

      return data;
    },
  });

  return (
    <Box
      bg="whiteAlpha.200"
      p={4}
      position="relative"
      overflow="hidden"
      width="100%"
      borderRadius={borderRadius}
    >
      <Box position="absolute" top={-7} left="50%" transform="translateX(-50%)">
        <DiscordHomeIllustration />
      </Box>
      <Box height="150px" />
      <VStack spacing={4}>
        <HStack spacing={5} justifyContent="center" width="100%">
          <HStack spacing={1.5}>
            <Box width="8px" height="8px" borderRadius="50%" backgroundColor="green.200" />
            <Text fontSize="small">{data?.[1]?.value || 0}</Text>
            <Text fontSize="small" fontWeight="regular" opacity="secondary">
              Online
            </Text>
          </HStack>
          <HStack spacing={1.5}>
            <Box
              width="8px"
              height="8px"
              borderRadius="50%"
              backgroundColor="gray.100"
              opacity={0.36}
            />
            <Text fontSize="small">{data?.[0]?.value || 0}</Text>
            <Text fontSize="small" fontWeight="regular" opacity="secondary">
              Members
            </Text>
          </HStack>
        </HStack>
        <VStack>
          <Text fontSize="xxLarge" fontWeight="semibold" textAlign="center">
            Skyrocket with us
          </Text>
          <Text fontWeight="regular" mb={4} opacity="secondary" textAlign="center">
            Dive into our Discord, a space <br /> to connect with BIG Devs.
          </Text>
        </VStack>
        <DiscordButton />
      </VStack>
    </Box>
  );
};

export default Discord;
