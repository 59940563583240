import { Dispatch, SetStateAction, useEffect } from 'react';
import useLocalStorageState from 'use-local-storage-state';

import { CodeEditorType } from 'types/edgeFunctions';

interface IUserCodeStorageParams {
  key: string;
  oldKey?: string;
  defaultValue?: string;
}

// in the future we could do server code storage here
export const useCodeStorage = ({
  key,
  oldKey,
  defaultValue = '',
}: IUserCodeStorageParams): [string, Dispatch<SetStateAction<string>>] => {
  const [code, setCode] = useLocalStorageState<string>(key, {
    defaultValue: defaultValue || '',
  });

  // To load old code for code editor for backward compatibility
  useEffect(() => {
    if (oldKey) {
      const oldCode = localStorage.getItem(oldKey);

      if (oldCode) {
        localStorage.removeItem(oldKey);

        setCode(JSON.parse(oldCode));
      }
    }
  }, [key, oldKey, defaultValue]);

  return [code, setCode];
};

interface IExtractCodeFromStorageParams {
  type?: CodeEditorType;
  screenName?: string;
  userId?: string;
  code?: 'html' | 'css' | 'js' | 'generated-solution-code';
  key?: string;
}

export const getCodeStorageKey = ({
  type,
  screenName,
  userId,
  code,
  key,
}: IExtractCodeFromStorageParams) => {
  if (key) {
    return `${key}-${code}`;
  }

  return `${type}-${screenName}-${userId}-${code}`;
};

interface IReplaceCodeInStorageParams {
  oldKeyParams: IExtractCodeFromStorageParams;
  newKeyParams: IExtractCodeFromStorageParams;
}

export const replaceCodeInStorage = ({
  oldKeyParams,
  newKeyParams,
}: IReplaceCodeInStorageParams) => {
  const oldKey = getCodeStorageKey(oldKeyParams);
  const newKey = getCodeStorageKey(newKeyParams);

  const code = localStorage.getItem(oldKey);

  if (code) {
    localStorage.removeItem(oldKey);

    localStorage.setItem(newKey, code);
  }
};

export const removeCodeFromStorage = ({
  type,
  screenName,
  userId,
  code,
  key,
}: IExtractCodeFromStorageParams) => {
  const storageKey = getCodeStorageKey({ type, screenName, userId, code, key });

  localStorage.removeItem(storageKey);
};

export const extractCodeFromStorage = ({
  type,
  screenName,
  userId,
  code,
  key,
}: IExtractCodeFromStorageParams) => {
  const storageKey = getCodeStorageKey({ type, screenName, userId, code, key });

  const codeFromStorage = localStorage.getItem(storageKey);

  if (!codeFromStorage) {
    return '';
  }

  return JSON.parse(codeFromStorage);
};
