import { Badge, VStack } from '@chakra-ui/react';
import { ReactNode } from 'react';

import { IconType } from 'components/Icon';
import Text from 'components/Text';
import { Plan_Type_Enum } from 'types/databaseEnums';

interface PlanDetails {
  order: number;
  title: string;
  icon: IconType;
  color: 'cyan.200' | 'purple.200' | 'orange.200';
  border: 'normal' | 'fancy';
  badge: ReactNode | null;
  info: (price: string) => JSX.Element;
  description: ReactNode;
}

interface IYearlyInfoBoxProps {
  totalPrice: number;
}

export const YearlyInfoBox = ({ totalPrice }: IYearlyInfoBoxProps) => {
  return (
    <Text fontSize="small" fontWeight="regular" opacity="secondary">
      ${totalPrice.toFixed(2).replace(/\.00$/, '')} billed yearly
    </Text>
  );
};

export const plans: Record<Plan_Type_Enum, PlanDetails> = {
  Lifetime: {
    order: 3,
    title: 'Lifetime',
    icon: 'LifetimePlan',
    color: 'cyan.200',
    border: 'fancy',
    badge: (
      <Badge px={1} py={0.5} ml={2} borderRadius="md" backgroundColor="whiteAlpha.200">
        <Text fontSize="micro" textTransform="none" fontWeight="regular">
          Most popular
        </Text>
      </Badge>
    ),
    info: () => (
      <Text fontSize="small" fontWeight="semibold" opacity="secondary">
        One-time payment
      </Text>
    ),
    description: (
      <VStack spacing={0}>
        <span>Maximize your learning</span>
        <span>to land a dream job</span>
      </VStack>
    ),
  },
  Yearly: {
    order: 2,
    title: 'Yearly',
    icon: 'YearlyPlan',
    color: 'purple.200',
    border: 'normal',
    badge: null,
    info: (price: string) => (
      <VStack spacing={0}>
        <Text fontSize="small" fontWeight="semibold" opacity="secondary">
          Billed yearly
        </Text>
        <Text fontSize="small" fontWeight="semibold" opacity="secondary">
          or ${price} billed monthly
        </Text>
      </VStack>
    ),
    description: (
      <VStack spacing={0}>
        <span>Build strong portfolio</span>
        <span>and get a job faster</span>
      </VStack>
    ),
  },
  Monthly: {
    order: 1,
    title: 'Monthly',
    icon: 'MonthlyPlan',
    color: 'orange.200',
    border: 'normal',
    badge: null,
    info: () => (
      <Text fontSize="small" fontWeight="regular" opacity="secondary">
        Billed every month
      </Text>
    ),
    description: (
      <VStack spacing={0}>
        <span>Build strong portfolio</span>
        <span>and get a job faster</span>
      </VStack>
    ),
  },
  Free: {
    order: 0,
    title: 'Free',
    icon: 'FreePlan',
    border: 'normal',
    color: 'cyan.200',
    badge: null,
    info: () => (
      <Text fontSize="small" fontWeight="semibold" opacity="secondary">
        Always free
      </Text>
    ),
    description: (
      <VStack spacing={0}>
        <span>Start coding instantly</span>
        <span>with free access</span>
      </VStack>
    ),
  },
};

export const DATE_FORMAT = 'do MMM yyyy';
export const DATE_FORMAT_WITH_TIME = 'do MMM yyyy, h:mm a';
