import { HStack, useTheme, Box, Switch } from '@chakra-ui/react';

import Text from 'components/Text';

import Icon, { IconType } from './Icon';

interface IConnectedAppProps {
  isActive: boolean;
  text: string;
  iconType: IconType;
  onDetach: () => void;
  onAttach: () => void;
}

export const ConnectedApp = ({
  isActive,
  text,
  iconType,
  onAttach,
  onDetach,
}: IConnectedAppProps) => {
  const { borderRadius } = useTheme();

  return (
    <Box w="100%" borderRadius={borderRadius} p={3} borderWidth="2px">
      <HStack justify="space-between">
        <HStack>
          <Icon type={iconType} size={24} />
          <Text>{text}</Text>
        </HStack>
        <Switch isChecked={isActive} onChange={() => (isActive ? onDetach() : onAttach())} />
      </HStack>
    </Box>
  );
};
