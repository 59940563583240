import { Center, Stack } from '@chakra-ui/react';
import { ReactNode } from 'react';

import { ReactComponent as CommunityQuestionsEmptyIllustration } from 'assets/illustrations/CommunityQuestionsEmpty.svg';
import { ReactComponent as NoProjectsCompletedIllustration } from 'assets/illustrations/NoProjectsCompleted.svg';
import { ReactComponent as SolutionsEmptyIllustration } from 'assets/illustrations/SolutionsEmpty.svg';
import Text from 'components/Text';
import { useMediaQueries } from 'layout/MediaQueriesProvider';
import { CodeEditorType } from 'types/edgeFunctions';

type EmptyContentComponent =
  | 'solutions'
  | 'challenges'
  | 'community_questions'
  | 'comments'
  | 'dashboard'
  | CodeEditorType;

interface IEmptyContent {
  type: EmptyContentComponent;
  action?: ReactNode;
  mt?: number | string;
}

const mapEmptyComponent: Record<EmptyContentComponent, React.FunctionComponent> = {
  solutions: SolutionsEmptyIllustration,
  challenges: SolutionsEmptyIllustration,
  community_questions: CommunityQuestionsEmptyIllustration,
  comments: CommunityQuestionsEmptyIllustration,
  'code-frame': NoProjectsCompletedIllustration,
  challenge: NoProjectsCompletedIllustration,
  problem: NoProjectsCompletedIllustration,
  project: NoProjectsCompletedIllustration,
  prototype: NoProjectsCompletedIllustration,
  dashboard: NoProjectsCompletedIllustration,
};

const mapText: Record<EmptyContentComponent, string> = {
  solutions: 'No project solutions found.',
  challenges: 'No challenges found.',
  community_questions: 'Nobody discussing yet.',
  comments: 'No comments yet.',
  'code-frame': 'No code frames found.',
  challenge: 'No challenge solutions found.',
  problem: 'No problem solutions found.',
  project: 'No project solutions found.',
  prototype: 'No prototypes found.',
  dashboard: 'Nothing there yet.',
};

export const EmptyContent = ({ type, action, mt }: IEmptyContent) => {
  const { isMobile } = useMediaQueries();

  const EmptyComponent = mapEmptyComponent[type];
  const emptyText = mapText[type];

  return (
    <Center width="100%" mt={mt || isMobile ? 10 : '12vh'}>
      <Stack spacing={5}>
        {/* @ts-ignore */}
        <EmptyComponent width={isMobile ? '90%' : '100%'} />
        <Text fontSize="xLarge" fontWeight="regular" textAlign="center">
          {emptyText}
        </Text>
        {action || null}
      </Stack>
    </Center>
  );
};
