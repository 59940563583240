import { useSupabaseTypedClient } from 'hooks/reactQuery';
import { useToastWrapper } from 'hooks/useToastWrapper';
import { useState } from 'react';

import { useCurrentUser } from 'auth/CurrentUserProvider';
import { EdgeFunctionName } from 'types/edgeFunctions';
import { openDiscord, openDiscordChannel } from 'utils/discord';

import Icon from './Icon';
import { ProtectedElement } from './ProtectedElement';

export const useDiscordConnection = (onClose?: () => void) => {
  const [isLoading, setIsLoading] = useState(false);

  const { toastError } = useToastWrapper();

  const supabase = useSupabaseTypedClient();

  const attachDiscord = async () => {
    setIsLoading(true);

    try {
      const { data, error } = await supabase.functions.invoke<string>(
        EdgeFunctionName.DiscordInvite,
        {
          body: {
            returnUrl: window.location.pathname,
          },
        }
      );

      if (error || !data) {
        throw new Error(error);
      }
      window.location.replace(data);
    } catch (error: any) {
      toastError(JSON.stringify(error));
    } finally {
      setIsLoading(false);
      onClose?.();
    }
  };

  return { attachDiscord, isLoading };
};

interface IDiscordButtonProps {
  channelId?: string;
  isFullWidth?: boolean;
}

const DiscordButton = ({ channelId, isFullWidth = true }: IDiscordButtonProps) => {
  const { hasDiscordRegistered } = useCurrentUser();

  const { attachDiscord, isLoading } = useDiscordConnection();

  const handleOnClick = async (e) => {
    e.stopPropagation();

    if (!hasDiscordRegistered) {
      await attachDiscord();

      return;
    }

    if (channelId) {
      openDiscordChannel(channelId);
    } else {
      openDiscord();
    }
  };

  return (
    <>
      <ProtectedElement
        onClick={handleOnClick}
        scheme="primary"
        isLoading={isLoading}
        loadingText="Connecting"
        leftIcon={<Icon type="DiscordGray" size={20} />}
        fullWidth={isFullWidth}
      >
        {hasDiscordRegistered ? 'Open Discord' : 'Connect Discord'}
      </ProtectedElement>
    </>
  );
};

export default DiscordButton;
