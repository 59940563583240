import { SupabaseClient } from '@supabase/supabase-js';
import { isWithinInterval, subWeeks } from 'date-fns';
import { useQueryWithErrorBoundary, useSupabaseTypedClient } from 'hooks/reactQuery';

import { useCurrentUser } from 'auth/CurrentUserProvider';
import { OtherTokenReward } from 'types/customEnums';
import {
  Card_Type_Enum,
  Experience_Level_Enum,
  Image_Type_Enum,
  Skill_Tag_Enum,
  User_Card_Status_Enum,
  User_Project_Status_Enum,
} from 'types/databaseEnums';
import { GetProjectParams, GetProjectResponse, Project, ProjectImage } from 'types/edgeFunctions';
import { TanstackQueryName } from 'types/frontend';
import { Database } from 'types/supabase';

interface IGetProjectQueryFnParams {
  supabase: SupabaseClient<Database>;
  userId: string;
  screenName: string;
}

export const getProjectQueryFn = async ({
  supabase,
  userId,
  screenName,
}: IGetProjectQueryFnParams): Promise<Project> => {
  const project = await supabase
    .from('project')
    .select(
      'id, screen_name, difficulty_lvl, glitch_url, replit_url, title, description, discord_channel_id, created_at, project_tag (id, tag_type), card (id, screen_name, title, description, difficulty_lvl, sequence, type, screenshot_url), project_image (id, sequence, title, url, image_type)'
    )
    .eq('screen_name', screenName)
    .single();

  if (project.error) throw project.error;

  const [
    userProjects,
    { count: discussionCount, error: discussionCountError },
    { count: solutionsCount, error: solutionsCountError },
    { count: downloadedFigmaCount, error: downloadedFigmaError },
  ] = await Promise.all([
    userId
      ? // In the past we allowed more than 1 project and can't do maybeSingle() here.
        supabase
          .from('user_project')
          .select('id, title, status, repository_url, preview_url, completed_date')
          .eq('user_id', userId)
          .eq('project_id', project.data!.id)
          .eq('status', User_Project_Status_Enum.Completed)
          .order('created_at', { ascending: false })
      : Promise.resolve(undefined),
    supabase
      .from('support_center')
      .select('*', { count: 'exact', head: true })
      .eq('entity_id', project.data!.id)
      .not('user_id', 'is', null),
    supabase
      .from('user_project')
      .select('*', { count: 'exact', head: true })
      .eq('status', User_Project_Status_Enum.Completed)
      .eq('project_id', project.data!.id)
      .not('user_id', 'is', null)
      .eq('is_private', false),
    supabase
      .from('user_action')
      .select('*', { count: 'exact', head: true })
      .eq('user_id', userId || '6ea22261-9854-4d1c-826b-35321d9504f3')
      .eq('entity_id', project.data.id)
      .eq('token_reward_id', OtherTokenReward.DownloadProjectFigma),
  ]);

  if (userProjects?.error) throw userProjects.error;
  if (discussionCountError) throw discussionCountError;
  if (solutionsCountError) throw solutionsCountError;
  if (downloadedFigmaError) throw downloadedFigmaError;

  const sortedProjectCards = project.data.card.sort((a, b) => a.sequence - b.sequence);

  const selectedUserProject = userProjects?.data[0];

  const userCards = await Promise.all(
    sortedProjectCards.map(async (projectCard) => {
      // In the past we allowed more than 1 project and can't do maybeSingle() here.
      const userCard = userId
        ? await supabase
            .from('user_card')
            .select('status')
            .eq('card_id', projectCard.id)
            .eq('user_id', userId)
        : await Promise.resolve(undefined);

      if (userCard?.error) throw userCard.error;

      return userCard
        ? userCard.data[0]
        : { html: '', css: '', js: '', status: User_Card_Status_Enum.NotStarted };
    })
  );

  const preparedProject = {
    id: project.data.id,
    user_project_id: selectedUserProject?.id || '',
    title: project.data.title,
    description: project.data.description,
    documentation: '',
    screen_name: project.data.screen_name || '',
    discussion_count: discussionCount || 0,
    solutions_count: solutionsCount || 0,
    glitch_url: project.data.glitch_url,
    replit_url: project.data.replit_url,
    difficulty_lvl: project.data.difficulty_lvl as Experience_Level_Enum,
    discord_channel_id: project.data.discord_channel_id || '',
    tags: project.data.project_tag.map((projectTag) => ({
      ...projectTag,
      tag_type: projectTag.tag_type as Skill_Tag_Enum,
    })),
    is_new: isWithinInterval(new Date(project.data.created_at), {
      start: subWeeks(new Date(), 2),
      end: new Date(),
    }),
    cards: sortedProjectCards.map((card, index) => ({
      id: card.id,
      screen_name: card.screen_name || '',
      difficulty_level: card.difficulty_lvl as Experience_Level_Enum,
      title: card.title,
      description: card.description,
      screenshot_url: card.screenshot_url,
      type: card.type as Card_Type_Enum,
      status: (userCards[index]?.status ||
        User_Card_Status_Enum.NotStarted) as User_Card_Status_Enum,
    })),
    status: (selectedUserProject?.status ||
      User_Project_Status_Enum.NotStarted) as User_Project_Status_Enum,
    repository_url: selectedUserProject?.repository_url || '',
    preview_url: selectedUserProject?.preview_url || '',
    solution_title: selectedUserProject?.title || '',
    design_screenshot: project.data.project_image.find(
      (projectImage) =>
        projectImage.image_type === Image_Type_Enum.Fullscreen && projectImage.sequence === 0
    ) as ProjectImage,
    completed_date: selectedUserProject?.completed_date || null,
    is_figma_downloaded: (downloadedFigmaCount || 0) > 0,
  };

  return preparedProject;
};

interface IUseGetProjectQueryResponse {
  data: GetProjectResponse | undefined | null;
  isPending: boolean;
}

interface IUseGetProjectQueryParams extends GetProjectParams {}

export const useGetProjectQuery = ({
  projectScreenName,
}: IUseGetProjectQueryParams): IUseGetProjectQueryResponse => {
  const { id: userId } = useCurrentUser();
  const supabase = useSupabaseTypedClient();

  const { data, isPending } = useQueryWithErrorBoundary({
    queryKey: [TanstackQueryName.GetProject, projectScreenName],
    queryFn: () => getProjectQueryFn({ supabase, userId, screenName: projectScreenName }),
  });

  if (!data) {
    return {
      data: null,
      isPending,
    };
  }

  return {
    data,
    isPending,
  };
};
