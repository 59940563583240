import {
  Box,
  HStack,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  Stack,
} from '@chakra-ui/react';
import { useToastWrapper } from 'hooks/useToastWrapper';
import { ReactNode } from 'react';
import { NavLink } from 'react-router-dom';

import { useAuth } from 'auth/AuthProvider';
import { useCurrentUser } from 'auth/CurrentUserProvider';
import Icon from 'components/Icon';
import Text from 'components/Text';
import UserAvatar from 'components/UserAvatar';
import { DISCORD_GUILD_HREF } from 'config/constants/discord';
import { ERoute } from 'types/frontend';
import { referralBuilder } from 'utils/strings';

interface UserDropdownMenuItemProps {
  pathname?: string;
  isExternal?: boolean;
  title: ReactNode;
  onClick?: () => void;
}

const UserDropdownMenuItem = ({
  pathname,
  isExternal,
  title,
  onClick,
}: UserDropdownMenuItemProps) => {
  return (
    <NavLink
      to={pathname as string}
      target={isExternal ? '_blank' : '_self'}
      onClick={() => onClick?.()}
    >
      <MenuItem bg="gray.900" _hover={{ bg: 'whiteAlpha.200' }}>
        <Text>{title}</Text>
      </MenuItem>
    </NavLink>
  );
};

const UserDropdownMenu = () => {
  const { signOut } = useAuth();
  const { avatarUrl, screenName, name, experience, hasDiscordRegistered, referralCode } =
    useCurrentUser();

  const value = referralBuilder(referralCode);

  const { toastSuccess } = useToastWrapper();

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(value);

    toastSuccess('Referral link copied to clipboard');
  };

  return (
    <Menu>
      {({ isOpen }) => (
        <>
          <MenuButton as={Button} variant={'link'} p={1} pr={0}>
            <HStack>
              <UserAvatar name={name} src={avatarUrl} experience={experience} />
              <Box transition="transform 0.3s" transform={isOpen ? 'rotate(180deg)' : 'rotate(0)'}>
                <Icon type="Dropdown" />
              </Box>
            </HStack>
          </MenuButton>
          <MenuList bg="gray.900" zIndex={2}>
            <Stack spacing={2}>
              <UserDropdownMenuItem pathname={`${ERoute.Profile}/${screenName}`} title="Profile" />
              <UserDropdownMenuItem pathname={ERoute.Billing} title="Billing" />
              <UserDropdownMenuItem pathname={ERoute.Settings} title="Settings" />
              <UserDropdownMenuItem onClick={handleCopyToClipboard} title="Invite friends" />
              {hasDiscordRegistered && (
                <UserDropdownMenuItem
                  isExternal
                  title={
                    <HStack>
                      <Text opacity="default">Discord</Text>
                      <Icon type="OutsideLink" />
                    </HStack>
                  }
                  pathname={`${DISCORD_GUILD_HREF}/${process.env.REACT_APP_DISCORD_GENERAL_CHANNEL_ID}`}
                />
              )}
              <MenuDivider m={0} />
              <UserDropdownMenuItem pathname={ERoute.Projects} onClick={signOut} title="Sign out" />
            </Stack>
          </MenuList>
        </>
      )}
    </Menu>
  );
};

export default UserDropdownMenu;
