import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  HStack,
  Stack,
  InputGroup,
  InputLeftElement,
  Grid,
  GridItem,
  Box,
  useTheme,
  Divider,
  VStack,
  Center,
  FormHelperText,
  Textarea,
  UnorderedList,
  ListItem,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import GradientTooltip from 'components/GradientTooltip';
import Icon from 'components/Icon';
import ImageUploadBox from 'components/ImageUploadBox';
import Link from 'components/Link';
import Modal from 'components/Modal';
import { ProtectedElement } from 'components/ProtectedElement';
import Text from 'components/Text';
import ToggleCodePrivacySwitch from 'components/ToggleCodePrivacySwitch';
import { useMediaQueries } from 'layout/MediaQueriesProvider';
import { ERoute } from 'types/frontend';

interface IBubbleCounterProps {
  count: number;
}

const BubbleCounter = ({ count }: IBubbleCounterProps) => {
  return (
    <Box width="24px" height="24px" backgroundColor="purple.400" borderRadius="50%">
      <Center>
        {count}
        <Text></Text>
      </Center>
    </Box>
  );
};

interface NewPrototypeModalForm {
  text?: string;
  url?: string;
}

const validationSchema = yup
  .object({
    text: yup.string().max(1000, 'Text is too long (maximum is 1000 characters)'),
    url: yup
      .string()
      .url('URL should be a valid URL')
      .max(255, 'URL is too long (maximum is 255 characters)'),
  })
  .required();

interface NewPrototypeModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const NewPrototypeModal = ({ isOpen, onClose }: NewPrototypeModalProps) => {
  const [screenshot, setScreenshot] = useState('');
  const [isPrivate, setIsPrivate] = useState(false);

  const { isDesktopNavigation } = useMediaQueries();

  const navigate = useNavigate();

  const { borderRadius } = useTheme();

  const {
    handleSubmit,
    register,
    formState: { errors },
    watch,
  } = useForm<NewPrototypeModalForm>({
    resolver: yupResolver(validationSchema),
    mode: 'onSubmit',
    defaultValues: {
      url: '',
      text: '',
    },
  });

  const text = watch('text');
  const url = watch('url');

  const onSubmit = async () => {
    navigate(`${ERoute.Prototypes}${ERoute.NewPrototype}`, {
      state: {
        screenshot,
        text,
        url,
        isPrivate,
        shouldCreate: true,
      },
    });
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={handleSubmit(onSubmit)}
      size="6xl"
      header="Create a new prototype"
      footer={null}
    >
      <Box backgroundColor="whiteAlpha.200" width="100%" borderRadius={borderRadius} p={4} mb={8}>
        <Grid templateColumns="repeat(3, 1fr)" gap={8}>
          <GridItem colSpan={isDesktopNavigation ? 2 : 3}>
            <Stack spacing={4}>
              <Stack spacing={2}>
                <FormControl isInvalid={Boolean(errors.text)}>
                  <FormLabel htmlFor="title">
                    <VStack alignItems="flex-start">
                      <HStack>
                        <BubbleCounter count={1} />
                        <Text fontSize="xLarge">Description</Text>
                      </HStack>
                      <Text opacity="secondary">Describe what should be generated.</Text>
                    </VStack>
                  </FormLabel>
                  <Textarea
                    {...register('text')}
                    placeholder={`e.g. I want to generate a simple Pomodoro Timer to see how it is built and analyze the code for learning purposes.`}
                    variant="filled"
                    maxLength={1000}
                    autoFocus
                    autoComplete="off"
                  />
                  <HStack justifyContent="space-between" alignItems="center">
                    {errors.text?.message ? (
                      <FormErrorMessage>{errors.text.message}</FormErrorMessage>
                    ) : (
                      <div />
                    )}
                    <FormHelperText>
                      <HStack spacing={1}>
                        <Text fontSize="micro" opacity="secondary" fontWeight="regular">
                          Characters remaining:
                        </Text>
                        <Text fontSize="micro" opacity="secondary" fontWeight="semibold">
                          {1000 - (text?.length || 0)}
                        </Text>
                      </HStack>
                    </FormHelperText>
                  </HStack>
                </FormControl>
              </Stack>
              <Stack spacing={2}>
                <FormControl isInvalid={Boolean(errors.url)}>
                  <FormLabel htmlFor="url">
                    <VStack alignItems="flex-start">
                      <HStack>
                        <BubbleCounter count={2} />
                        <Text fontSize="xLarge">URL reference</Text>
                      </HStack>
                      <Text opacity="secondary">
                        Reference a URL as an inspiration for the prototype.
                      </Text>
                    </VStack>
                  </FormLabel>
                  <InputGroup>
                    <InputLeftElement pointerEvents="none">
                      <Icon type="Website" />
                    </InputLeftElement>
                    <Input
                      {...register('url')}
                      placeholder={`e.g. https://www.youtube.com/`}
                      variant="filled"
                      autoComplete="off"
                    />
                  </InputGroup>
                  <FormErrorMessage>{errors.url?.message}</FormErrorMessage>
                </FormControl>
              </Stack>
              <Stack spacing={2} pt={4}>
                <VStack alignItems="flex-start">
                  <HStack>
                    <BubbleCounter count={3} />
                    <Text fontSize="xLarge">Design screenshot</Text>
                  </HStack>
                  <Text opacity="secondary">
                    Include an image for instructing AI on the design of the prototype.
                  </Text>
                </VStack>
                <ImageUploadBox
                  onImageChange={(base64) => {
                    setScreenshot(base64);
                  }}
                />
              </Stack>
            </Stack>
          </GridItem>
          <GridItem colSpan={isDesktopNavigation ? 1 : 3} height="100%">
            <HStack height="100%" alignItems="flex-start" justifyContent="space-between">
              {isDesktopNavigation && <Divider orientation="vertical" width="1px" />}
              <VStack
                alignItems="space-between"
                width="100%"
                height="100%"
                justifyContent="space-between"
              >
                <VStack p={2}>
                  <Text fontSize="large" fontWeight="semibold" mt={4}>
                    Prototype Guidelines
                  </Text>
                  <UnorderedList spacing={3}>
                    <ListItem>
                      <Text fontSize="small" fontWeight="regular">
                        Be specific in your descriptions to ensure the generated results closely
                        match your requirements.
                      </Text>
                    </ListItem>
                    <ListItem>
                      <Text fontSize="small" fontWeight="regular">
                        Focus on creating simple components or small functionalities rather than
                        entire applications.
                      </Text>
                    </ListItem>
                    <ListItem>
                      <Text fontSize="small" fontWeight="regular">
                        The generated code serves as a base; feel free to adjust it according to
                        your needs.
                      </Text>
                    </ListItem>
                    <ListItem>
                      <Text fontSize="small" fontWeight="regular">
                        Use the prototype for reverse engineering to learn how things work.{' '}
                        <Link href="https://en.wikipedia.org/wiki/Reverse_engineering" isExternal>
                          Learn more
                        </Link>
                      </Text>
                    </ListItem>
                  </UnorderedList>
                  <Text fontSize="micro" mt={2} opacity="secondary" fontWeight="regular">
                    This is AI-generated code and may have mistakes.
                  </Text>
                </VStack>
                <VStack alignItems="flex-end" width="100%">
                  <ToggleCodePrivacySwitch
                    type="prototype"
                    isNotCreated
                    togglePrivacyCodeParams={{
                      entityId: '',
                      isPrivate: false,
                      invalidateKeys: [],
                      queryKey: [],
                    }}
                    onChange={(isPrivate) => setIsPrivate(isPrivate)}
                  />
                  <GradientTooltip
                    label={
                      !text ? 'Provide at least description for the prototype generation' : null
                    }
                  >
                    <Box width="100%">
                      <ProtectedElement
                        scheme="gradient"
                        mt={4}
                        isDisabled={!text && !screenshot && !url}
                        type="submit"
                        size="lg"
                        fullWidth
                        requiredTokens={1}
                      >
                        Generate Prototype
                      </ProtectedElement>
                    </Box>
                  </GradientTooltip>
                </VStack>
              </VStack>
            </HStack>
          </GridItem>
        </Grid>
      </Box>
    </Modal>
  );
};

export default NewPrototypeModal;
