import { AddIcon } from '@chakra-ui/icons';
import {
  SimpleGrid,
  Center,
  Container,
  Stack,
  VStack,
  useDisclosure,
  Spinner,
} from '@chakra-ui/react';
import { InfiniteData } from '@tanstack/react-query';
import { useGetPrototypesQuery } from 'hooks/queries/prototypes/useGetPrototypesQuery';
import { useScrollToTop } from 'hooks/useScrollToTop';
import { useEffect, useState } from 'react';

import { ReactComponent as NoPrototypesIllustration } from 'assets/illustrations/NoPrototypes.svg';
import { useAuth } from 'auth/AuthProvider';
import { useCurrentUser } from 'auth/CurrentUserProvider';
import CodePreviewCard from 'components/CodePreviewCard';
import PageLoader from 'components/PageLoader';
import { ProtectedElement } from 'components/ProtectedElement';
import { SEO } from 'components/SEO';
import Tabs from 'components/Tabs';
import Text from 'components/Text';
import { useMediaQueries } from 'layout/MediaQueriesProvider';
import { CodePreview } from 'types/edgeFunctions';
import { TanstackQueryName } from 'types/frontend';

import NewPrototypeModal from './NewPrototypeModal';

const Prototypes = () => {
  useScrollToTop();
  const [tabIndex, setTabIndex] = useState(0);

  const { id: currentUserId } = useCurrentUser();
  const { userId: authId } = useAuth();

  useEffect(() => {
    sessionStorage.setItem('isSubmittingPrototype', 'false');
  }, []);

  const { data, isFetching, ref } = useGetPrototypesQuery({
    userId: tabIndex === 0 ? null : currentUserId,
  });

  const { isMobile } = useMediaQueries();

  const newPrototypeModal = useDisclosure();

  const renderEmptyPrototypes = () => {
    return (
      <Center width="100%" mt={isMobile ? 10 : '12vh'}>
        <Stack spacing={5}>
          <NoPrototypesIllustration />
          <Text fontSize="xLarge" fontWeight="regular" textAlign="center">
            No prototypes found.
          </Text>
        </Stack>
      </Center>
    );
  };

  const prototypesData = (data as unknown as InfiniteData<CodePreview[]>) || [];
  const prototypes =
    !authId && tabIndex === 1
      ? []
      : prototypesData.pages.reduce((acc, page) => acc.concat(page), [] as CodePreview[]) || [];

  const renderContent = () => {
    const isFirstPage = prototypesData.pages.length === 0;

    if (isFirstPage && isFetching) {
      return <PageLoader shift />;
    }

    if (prototypes?.length === 0) {
      return renderEmptyPrototypes();
    }

    return (
      <>
        <SimpleGrid columns={[1, 1, 1, 2, 3]} gap={8} mt={4}>
          {(prototypes || []).map((userCode) => {
            return (
              <CodePreviewCard
                key={userCode.id}
                {...userCode}
                isMySolution={tabIndex === 0}
                canEdit={tabIndex === 0}
                width="100%"
                height="300px"
                queryKey={[TanstackQueryName.GetPrototypes, currentUserId]}
                invalidateKeys={[
                  [TanstackQueryName.GetPrototype, userCode.solution_url!],
                  [TanstackQueryName.GetPrototypes, null],
                  [TanstackQueryName.GetProfileCode, userCode.user_id!, 'prototype'],
                  [TanstackQueryName.GetUserCode, currentUserId, 0],
                  [TanstackQueryName.GetUserCode, currentUserId, 1],
                  [TanstackQueryName.GetUserCode, currentUserId, 2],
                ]}
              />
            );
          })}
        </SimpleGrid>
        <div ref={ref} />
        <Center width="100%" mt={10}>
          {isFetching && !isFirstPage && <Spinner size="lg" />}
        </Center>
      </>
    );
  };

  return (
    <>
      <Container maxW="1400px" width="100%">
        <SEO
          title="BigDevSoon | Prototypes"
          description="Create prototypes of any idea and see how they work in the code editor, great for reverse engineering things!"
          url={`${process.env.REACT_APP_SITE_URL}Prototypes`}
        />
        <VStack alignItems="flex-start" width="100%" spacing={4}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            background="gray.900"
            width="100%"
            py={3}
          >
            <VStack alignItems="flex-start">
              <Text fontSize="xxLarge" fontWeight="semibold">
                Prototypes
              </Text>
              <Text fontSize="small" fontWeight="regular" opacity="secondary">
                See how things work, it just takes an idea.
              </Text>
            </VStack>
            <ProtectedElement leftIcon={<AddIcon boxSize={3} />} onClick={newPrototypeModal.onOpen}>
              New {!isMobile && 'Prototype'}
            </ProtectedElement>
          </Stack>
          <Tabs
            tabs={['Most Popular', 'Your Work']}
            tabIndex={tabIndex}
            onChange={(index) => setTabIndex(index)}
          />
        </VStack>
        {renderContent()}
      </Container>
      <NewPrototypeModal isOpen={newPrototypeModal.isOpen} onClose={newPrototypeModal.onClose} />
    </>
  );
};

export default Prototypes;
