import { Box, HStack } from '@chakra-ui/react';

import Button from 'components/Button';
import Icon from 'components/Icon';
import Link from 'components/Link';
import Modal from 'components/Modal';
import Text from 'components/Text';
import { IModal } from 'types/frontend';

interface IAccountTerminatedModal extends IModal {}

export const AccountTerminatedModal = ({ isOpen, onClose }: IAccountTerminatedModal) => {
  return (
    <Modal
      size="md"
      isOpen={isOpen}
      onClose={onClose}
      header={
        <HStack>
          <Icon type="DeleteModal" />
          <Text fontSize="xLarge" fontWeight="semibold">
            Your account is terminated
          </Text>
        </HStack>
      }
      footer={
        <Box position="relative">
          <Button scheme="ghost" onClick={onClose} position="relative" left="18px">
            Close
          </Button>
        </Box>
      }
    >
      <Text fontWeight="regular">
        You cannot perform certain actions such as this one,{' '}
        <Link href="mailto:support@bigdevsoon.me">contact us</Link> for more details.
      </Text>
    </Modal>
  );
};
