import { Buffer } from 'buffer';

import { useQueryClient } from '@tanstack/react-query';
import { useSupabaseTypedClient } from 'hooks/reactQuery';
import { useToastWrapper } from 'hooks/useToastWrapper';
import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useCurrentUser } from 'auth/CurrentUserProvider';
import PageLoader from 'components/PageLoader';
import { EdgeFunctionName } from 'types/edgeFunctions';
import { TanstackQueryName } from 'types/frontend';

const DiscordCallback = () => {
  const [searchParams] = useSearchParams();

  const { toastSuccess, toastError } = useToastWrapper();

  const { id: userId } = useCurrentUser();
  const supabase = useSupabaseTypedClient();
  const queryClient = useQueryClient();

  const navigate = useNavigate();

  useEffect(() => {
    const sign = async () => {
      const code = searchParams.get('code') as string;
      const state = searchParams.get('state') as string;
      const redirectEncoded = state.split(':')[1];
      const redirect = Buffer.from(redirectEncoded, 'base64').toString('ascii');

      try {
        const { error } = await supabase.functions.invoke(EdgeFunctionName.DiscordSignIn, {
          body: {
            code,
            state,
          },
        });

        if (error) {
          throw new Error(error);
        }

        toastSuccess('Discord connected successfully.');

        queryClient.invalidateQueries({ queryKey: [TanstackQueryName.GetCurrentUser, userId] });
      } catch (error) {
        toastError('Problem occurred while connecting to Discord. Try again.');
      } finally {
        navigate(redirect);
      }
    };

    sign();
  }, []);

  return <PageLoader shift />;
};

export default DiscordCallback;
