import { Box, SimpleGrid, Container, Stack, Spinner, Center } from '@chakra-ui/react';
import { InfiniteData } from '@tanstack/react-query';
import { useGetChallengesQuery } from 'hooks/queries/challenges/useGetChallengesQuery';
import { useScrollToTop } from 'hooks/useScrollToTop';
import { useState } from 'react';

import { useCurrentUser } from 'auth/CurrentUserProvider';
import Button from 'components/Button';
import { EmptyContent } from 'components/EmptyContent';
import PageLoader from 'components/PageLoader';
import { SEO } from 'components/SEO';
import Tabs from 'components/Tabs';
import Text from 'components/Text';
import { User_Challenge_Status_Enum } from 'types/databaseEnums';
import { ChallengePreview } from 'types/edgeFunctions';

import ChallengeCard from './ChallengeCard';

const Challenges = () => {
  const [tabIndex, setTabIndex] = useState(0);

  const { id: userId } = useCurrentUser();

  const { data, isFetching, ref } = useGetChallengesQuery({ userId, tabIndex });

  useScrollToTop();

  const challengesData = (data as unknown as InfiniteData<ChallengePreview[]>) || [];
  const challenges =
    challengesData.pages.reduce((acc, page) => acc.concat(page), [] as ChallengePreview[]) || [];

  const filteredChallenges = challenges.filter((challenge) => {
    if (tabIndex === 0) {
      return true;
    }

    if (tabIndex === 1) {
      return challenge.status === User_Challenge_Status_Enum.Started;
    }

    return challenge.status === User_Challenge_Status_Enum.Completed;
  });

  const renderChallenges = () => {
    const isFirstPage = challengesData.pages.length === 0;

    if (isFirstPage && isFetching) {
      return <PageLoader shift />;
    }

    if (filteredChallenges.length === 0) {
      return (
        <EmptyContent
          type="challenges"
          action={<Button onClick={() => setTabIndex(0)}>View all challenges</Button>}
        />
      );
    }

    return (
      <>
        <SimpleGrid columns={[1, 1, 2, 2, 3]} gap={8} mt={4}>
          {filteredChallenges.map((challenge, index) => {
            const dayKey = `DAY ${(challenge?.order || index) + 1}`;

            return (
              <Box key={dayKey}>
                <ChallengeCard challenge={challenge} day={dayKey} key={dayKey} />
              </Box>
            );
          })}
        </SimpleGrid>
        <div ref={ref} />
        <Center width="100%" mt={10}>
          {isFetching && !isFirstPage && <Spinner size="lg" />}
        </Center>
      </>
    );
  };

  return (
    <Container maxW="1400px">
      <SEO
        title="BigDevSoon | Daily challenges"
        description={`Solve a new challenge every day.`}
        url={`${process.env.REACT_APP_SITE_URL}challenges`}
      />
      <Stack
        direction={['column', 'column', 'row', 'row']}
        alignItems="center"
        justifyContent="space-between"
        background="gray.900"
        py={3}
      >
        <Text fontSize="xxLarge" fontWeight="semibold">
          Challenges
        </Text>
        <Tabs
          tabs={['All', 'Not completed', 'Completed']}
          tabIndex={tabIndex}
          onChange={(index) => setTabIndex(index)}
        />
      </Stack>
      {renderChallenges()}
    </Container>
  );
};

export default Challenges;
