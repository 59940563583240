import { Box, Input, InputGroup, InputRightElement, Stack } from '@chakra-ui/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  supabaseMutationFn,
  useQueryWithErrorBoundary,
  useSupabaseTypedClient,
} from 'hooks/reactQuery';
import { useToastWrapper } from 'hooks/useToastWrapper';
import { useEffect, useState } from 'react';

import { ReactComponent as NewsletterBackgroundIllustration } from 'assets/illustrations/NewsletterBackground.svg';
import { useCurrentUser } from 'auth/CurrentUserProvider';
import Button from 'components/Button';
import Modal from 'components/Modal';
import Text from 'components/Text';
import {
  EdgeFunctionName,
  GetEmailNotificationsResponse,
  ManageEmailNotificationsParams,
  UpdateUserPrivateEmailParams,
} from 'types/edgeFunctions';
import { IModal, TanstackQueryName } from 'types/frontend';

interface NewsletterModalProps extends IModal {}

const NewsletterModal = ({ isOpen, onClose }: NewsletterModalProps) => {
  const { id: currentUserId, privateEmail } = useCurrentUser();

  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState(privateEmail);

  const supabase = useSupabaseTypedClient();

  const queryClient = useQueryClient();

  const { toastSuccess, toastError } = useToastWrapper();

  const { data } = useQueryWithErrorBoundary({
    queryKey: [EdgeFunctionName.GetEmailNotifications, privateEmail],
    queryFn: async () => {
      const { data, error } = await supabase.functions.invoke<GetEmailNotificationsResponse>(
        EdgeFunctionName.GetEmailNotifications
      );

      if (error) throw error;

      return data;
    },
    enabled: isOpen,
  });

  const mutation = useMutation<string | null, string, string>({
    mutationFn: supabaseMutationFn((email) =>
      supabase.functions.invoke<string>(EdgeFunctionName.UpdateUserPrivateEmail, {
        body: { email } as UpdateUserPrivateEmailParams,
      })
    ),
    onError: () => {
      toastError(`Can't update email`);
    },
  });

  useEffect(() => {
    setEmail(privateEmail);
  }, [privateEmail]);

  const handleBiweeklyNewsletterJoin = async (email: string) => {
    if (!email) {
      return;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!emailRegex.test(email)) {
      toastError('Invalid email format');

      return;
    }

    try {
      setIsLoading(true);

      if (email !== privateEmail) {
        await mutation.mutate(email);
      }

      const { error } = await supabase.functions.invoke(EdgeFunctionName.ManageEmailNotifications, {
        body: {
          shouldSubscribeToBiWeeklyNewsletter: true,
          newEmail: email,
        } as ManageEmailNotificationsParams,
      });

      if (error) throw error;

      await queryClient.invalidateQueries({
        queryKey: [TanstackQueryName.GetCurrentUser, currentUserId],
      });

      queryClient.invalidateQueries({
        queryKey: [EdgeFunctionName.GetEmailNotifications, email],
      });
      queryClient.invalidateQueries({
        queryKey: [EdgeFunctionName.GetEmailNotifications, privateEmail],
      });

      toastSuccess('Subscribed to LevelUp biweekly newsletter!');
      onClose();
    } catch (error: any) {
      toastError(JSON.stringify(error));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      header={null}
      footer={null}
      isCentered={false}
      onSubmit={() => {
        handleBiweeklyNewsletterJoin(email);
      }}
      size="2xl"
    >
      <Box position="absolute" top={0} left={0} zIndex={-999}>
        <NewsletterBackgroundIllustration />
      </Box>
      <Stack spacing={5} justifyContent="center" width="100%" pt={8} pb={5} px={8}>
        <Text fontWeight="semibold" fontSize="xLarge" textAlign="center">
          Subscribe to our{' '}
          <Box
            as="span"
            bgGradient="linear-gradient(90deg, #F687B3 61.86%, #B794F4 100%)"
            bgClip="text"
          >
            LevelUp
          </Box>{' '}
          biweekly newsletter
        </Text>
        <Text fontWeight="regular" opacity="secondary" fontSize="small" textAlign="center">
          Get useful job search tips, coding news, tools, and more.
        </Text>

        {data?.isSubscribedToBiWeeklyNewsletter ? (
          <Text fontWeight="regular" textAlign="center" pt={4}>
            You already subscribed. Thank you!
          </Text>
        ) : (
          <InputGroup>
            <Input
              autoFocus
              borderColor="purple.200"
              pr="6rem"
              type="email"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              isRequired
              required
              _hover={{
                outline: 'none',
                boxShadow: 'none',
                borderColor: 'purple.300',
              }}
              _focus={{
                outline: 'none',
                boxShadow: 'none',
                borderColor: 'purple.300',
              }}
            />
            <InputRightElement width="5rem">
              <Button
                isLoading={isLoading}
                _disabled={{
                  opacity: 1,
                  backgroundColor: 'gray.500',
                  borderColor: 'purple.200',
                  cursor: 'not-allowed',
                }}
                height="95%"
                width="100%"
                type="submit"
              >
                Join
              </Button>
            </InputRightElement>
          </InputGroup>
        )}
        <Box pb={6} />
        <Text fontWeight="regular" opacity="secondary" fontSize="micro" textAlign="center">
          We know {`it's`} no fun looking for a job in tough times. {`We're`} here to help.
        </Text>
      </Stack>
    </Modal>
  );
};

export default NewsletterModal;
