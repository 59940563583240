import { useBillingStorage } from 'hooks/useBillingStorage';
import React, { ReactNode, useContext } from 'react';

import { SubscriptionDetails } from 'types/edgeFunctions';

type Billing = {
  data: SubscriptionDetails | null | undefined;
  isLoading: boolean;
  isWaitingForNewData: boolean;
  cacheTimestamp: () => void;
  clearStorage: () => void;
};

type BillingContextData = Billing;

export const BillingContext = React.createContext({} as BillingContextData);

export const BillingProvider = ({ children }: { children: ReactNode }) => {
  const { cacheTimestamp, clearStorage, data, isLoading, isWaitingForNewData } =
    useBillingStorage();

  return (
    <BillingContext.Provider
      value={{
        data,
        isLoading,
        isWaitingForNewData,
        cacheTimestamp,
        clearStorage,
      }}
    >
      {children}
    </BillingContext.Provider>
  );
};

export const useBilling = (): BillingContextData => {
  const context = useContext(BillingContext);

  if (!context) {
    throw new Error('useBilling must be used within an BillingContextProvider');
  }

  return context;
};
