import { useGetPracticeProblemQuery } from 'hooks/queries/problems/useGetPracticeProblemQuery';
import { Route, Routes, useParams } from 'react-router-dom';

import DevSandboxSkeleton from 'components/DevSandbox/DevSandboxSkeleton';
import { SEO } from 'components/SEO';

import PracticeProblemPlayground from './playground/PracticeProblemPlayground';
import Submit from './playground/Submit';
import PracticeProblemSolution from './solution/PracticeProblemSolution';

const PracticeProblemHub = () => {
  const { practiceProblemScreenName = '' } = useParams();

  const { data, isPending } = useGetPracticeProblemQuery({
    practiceProblemScreenName,
  });

  if (!data || isPending) {
    return <DevSandboxSkeleton loadingText="Loading problem..." tabNames={['JS']} />;
  }

  return (
    <>
      <SEO
        title={`BigDevSoon | ${data.title} practice problem`}
        description={data.description || ''}
        url={`${process.env.REACT_APP_SITE_URL}problems/${data.screen_name}`}
      />
      <Routes>
        <Route path="submit" element={<Submit practiceProblem={data!} />} />
        <Route
          path="solutions/:userScreenName/:title/*"
          element={<PracticeProblemSolution practiceProblem={data!} />}
        />
        <Route path="*" element={<PracticeProblemPlayground practiceProblem={data!} />} />
      </Routes>
    </>
  );
};

export default PracticeProblemHub;
