import { Box, HStack, Flex, IconButton } from '@chakra-ui/react';
import { useState } from 'react';

import GradientPopover from 'components/GradientPopover';
import { useMediaQueries } from 'layout/MediaQueriesProvider';

import { useDevSandbox } from './DevSandboxProvider';
import { TDevSandboxFooterQuickButton } from './types';

interface IQuickButtonProps extends TDevSandboxFooterQuickButton {
  browserUrl: string;
}

const QuickButton = ({
  title,
  icon,
  content,
  autoOpen,
  browserUrl,
  tooltip,
}: IQuickButtonProps) => {
  const [isOpened, setIsOpened] = useState(false);

  return (
    <GradientPopover
      width="500px"
      tooltip={tooltip}
      triggerComponent={
        <IconButton
          aria-label="tooltip"
          variant="ghost"
          backgroundColor={isOpened ? 'purple.400' : 'inherit'}
          transition="background-color 0.2s"
          _hover={{ backgroundColor: 'purple.400' }}
        >
          {icon}
        </IconButton>
      }
      onPopoverStateChange={setIsOpened}
      header={title}
      id={browserUrl}
      defaultIsOpen={Boolean(autoOpen)}
    >
      {content}
    </GradientPopover>
  );
};

const DevSandboxFooter = () => {
  const { isMobile } = useMediaQueries();

  const { browserUrl, mode, footer } = useDevSandbox();

  if (mode === 'full-page') {
    return null;
  }

  return (
    <>
      <Box borderTop="1px solid" borderColor="whiteAlpha.200" position="relative" zIndex={999} />
      <Flex
        height="60px"
        backgroundColor="gray.900"
        px={isMobile ? 0 : 4}
        alignItems="center"
        position="relative"
      >
        <Box mr="auto">{footer.leftSide || null}</Box>
        {!isMobile && (
          <Box position="absolute" left="50%" transform="translateX(-50%)" textAlign="center">
            {footer.header || null}
          </Box>
        )}
        <HStack spacing={4} ml="auto">
          {footer.rightSide
            ? footer.rightSide.map((button) => (
                <QuickButton key={button.id} {...button} browserUrl={browserUrl || ''} />
              ))
            : null}
        </HStack>
      </Flex>
    </>
  );
};

export default DevSandboxFooter;
