import { useSupabaseTypedClient } from 'hooks/reactQuery';
import { useToastWrapper } from 'hooks/useToastWrapper';
import { useState } from 'react';

import Button from 'components/Button';
import Modal from 'components/Modal';
import Text from 'components/Text';
import { useBilling } from 'features/billing/context/BillingProvider';
import { EdgeFunctionName } from 'types/edgeFunctions';
import { IModal } from 'types/frontend';
import { billingInfo, contactInfo } from 'utils/strings';

interface IUndoCancelationModal extends IModal {}

export const UndoCancelationModal = ({ isOpen, onClose }: IUndoCancelationModal) => {
  const { toastInfo, toastError } = useToastWrapper();
  const { cacheTimestamp } = useBilling();
  const [isProcessing, setIsProcessing] = useState(false);

  const supabaseClient = useSupabaseTypedClient();

  const handleUndoCancelation = async () => {
    try {
      setIsProcessing(true);
      await supabaseClient.functions.invoke<string>(EdgeFunctionName.SubscriptionUndoCancelation);

      cacheTimestamp();

      onClose();

      toastInfo(billingInfo);
    } catch {
      toastError(`Undo cancelation problem. ${contactInfo}`);
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      header="Undo cancellation"
      footer={
        <>
          <Button scheme="ghost" onClick={onClose}>
            Close
          </Button>
          <Button onClick={handleUndoCancelation} isLoading={isProcessing}>
            Undo
          </Button>
        </>
      }
    >
      <Text fontWeight="regular">Are you sure you want to undo your cancellation?</Text>
    </Modal>
  );
};
