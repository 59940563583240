import { HStack } from '@chakra-ui/react';

import Icon from './Icon';
import Text, { Opacity } from './Text';

interface IBigTokensProps {
  amount: number;
  opacity?: Opacity;
  color?: 'black' | 'white';
  bold?: boolean;
}

const BigTokens = ({
  amount,
  opacity = 'primary',
  color = 'white',
  bold = false,
}: IBigTokensProps) => {
  return (
    <HStack spacing={1}>
      <Icon type="Bigtokens" size={24} />
      <Text opacity={opacity} color={color} fontWeight={bold ? 'semibold' : 'medium'}>
        {Math.abs(amount)}
      </Text>
    </HStack>
  );
};

export default BigTokens;
