import { Box, Tooltip, TooltipProps } from '@chakra-ui/react';
import { ReactNode } from 'react';

import { useMediaQueries } from 'layout/MediaQueriesProvider';

interface IGradientTooltipProps extends TooltipProps {
  label?: ReactNode | null;
  children: ReactNode;
}

const GradientTooltip = ({ label, children, ...props }: IGradientTooltipProps) => {
  const { isMobile } = useMediaQueries();

  if (!label || isMobile) {
    return <>{children}</>;
  }

  return (
    <Tooltip
      label={
        <Box bg="gray.800" position="relative" borderRadius="md" p={3} zIndex="1" color="white">
          {label}
        </Box>
      }
      bg="transparent"
      border="none"
      borderRadius="md"
      p="4px"
      overflow="hidden"
      _before={{
        content: '""',
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: '0',
        margin: '2px',
        borderRadius: 'inherit',
        background:
          'linear-gradient(360deg, rgba(159, 122, 234, 0.87) 1.16%, rgba(237, 137, 54, 0.87) 95.35%)',
      }}
      {...props}
    >
      {children}
    </Tooltip>
  );
};

export default GradientTooltip;
