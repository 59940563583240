import {
  Box,
  Center,
  Grid,
  HStack,
  Fade,
  ScaleFade,
  SlideFade,
  Stack,
  VStack,
  useTheme,
} from '@chakra-ui/react';
import { format } from 'date-fns';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as WingsIllustration } from 'assets/illustrations/ProjectSubmittedWings.svg';
import { ReactComponent as StarsIllustration } from 'assets/illustrations/Stars.svg';
import { useCurrentUser } from 'auth/CurrentUserProvider';
import Button from 'components/Button';
import Icon from 'components/Icon';
import { LinkInput } from 'components/LinkInput';
import Modal from 'components/Modal';
import ProjectSummaryTile from 'components/ProjectSummaryTile';
import ShareButtons from 'components/ShareButtons';
import Text from 'components/Text';
import UserAvatar from 'components/UserAvatar';
import { DATE_FORMAT } from 'config/constants/plans';
import { ERoute, IModal } from 'types/frontend';

interface ProgressModalProps extends IModal {
  problemTitle: string;
  grantedExperience: number;
  completedDate: string;
}

const ProgressModal = ({
  problemTitle,
  grantedExperience,
  completedDate,
  isOpen,
  onClose,
}: ProgressModalProps) => {
  const { avatarUrl, experience } = useCurrentUser();
  const [showUser, setShowUser] = useState(false);
  const [showSummary, setShowSummary] = useState(false);
  const [showSocialMedia, setShowSocialMedia] = useState(false);
  const { borderRadius } = useTheme();

  const navigate = useNavigate();

  const showUserRef = useRef<any>(null);
  const showSummaryRef = useRef<any>(null);
  const showSocialMediaRef = useRef<any>(null);

  const hasTabInUrl =
    location.pathname.includes('summary') || location.pathname.includes('comments');
  const tabScreenName = location.pathname.split('/').pop();
  const solutionUrl = hasTabInUrl
    ? location.pathname.replace(`/${tabScreenName}`, '')
    : location.pathname;

  const APP_URL = process.env.REACT_APP_SITE_URL || '';

  const link = `${APP_URL.substring(0, APP_URL.length - 1)}${solutionUrl}`;

  useEffect(() => {
    if (isOpen) {
      showUserRef.current = setTimeout(() => {
        setShowUser(true);
      }, 200);

      showSummaryRef.current = setTimeout(() => {
        setShowSummary(true);
      }, 700);

      showSocialMediaRef.current = setTimeout(() => {
        setShowSocialMedia(true);
      }, 1200);
    }

    return () => {
      clearTimeout(showUserRef.current);
      clearTimeout(showSummaryRef.current);
      clearTimeout(showSocialMediaRef.current);
    };
  }, [isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      header="Problem solved"
      footer={
        <>
          <Button
            scheme="ghost"
            opacity="secondary"
            onClick={() => {
              onClose();
              navigate(ERoute.PracticeProblems);
            }}
          >
            Discover more problems
          </Button>
          <Button onClick={onClose}>Ok, got it!</Button>
        </>
      }
    >
      <Fade in={isOpen}>
        <Stack spacing={5}>
          <ScaleFade initialScale={0.1} in={showUser}>
            <Center position="relative" py={4}>
              <Box position="absolute">
                <WingsIllustration />
              </Box>
              <Box position="absolute">
                <UserAvatar src={avatarUrl} size="lg" experience={experience} />
              </Box>
              <StarsIllustration />
            </Center>
          </ScaleFade>

          <ScaleFade in={showSummary}>
            <Stack>
              <Text fontSize="xLarge">Details</Text>
              <Grid templateColumns="repeat(2, 1fr)" gap={6}>
                <ProjectSummaryTile text={`Experience granted`}>
                  <HStack>
                    <Icon type="ExperienceBubble" size={24} />
                    <Text fontSize="large">{grantedExperience}</Text>
                  </HStack>
                </ProjectSummaryTile>
                <ProjectSummaryTile text={`Completion date`}>
                  <HStack>
                    <Icon type="Clock" size={24} />
                    <Text fontSize="large">{format(new Date(completedDate), DATE_FORMAT)}</Text>
                  </HStack>
                </ProjectSummaryTile>
              </Grid>
            </Stack>
          </ScaleFade>
          <SlideFade in={showSocialMedia}>
            <VStack
              align="start"
              padding={4}
              spacing={5}
              bg="whiteAlpha.200"
              borderRadius={borderRadius}
            >
              <Text fontSize="xLarge">Share your solution on social media</Text>
              <ShareButtons
                text={`Check out my latest solution to the ${problemTitle} practice problem on BigDevSoon. 🚀`}
                link={link}
                emailSubject={`Hey, wanted to share my solution on BigDevSoon with you`}
              />
              <Text mb={-3}>or copy link</Text>
              <LinkInput link={link} />
            </VStack>
          </SlideFade>
        </Stack>
      </Fade>
    </Modal>
  );
};

export default ProgressModal;
