import { Box, HStack, ListItem, OrderedList, UnorderedList, VStack } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { ReactNode, useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { dracula } from 'react-syntax-highlighter/dist/esm/styles/prism';
import Typist from 'react-typist';

import 'react-typist/dist/Typist.css';

import eliseSrc from 'assets/images/Elise.png';
import { useCurrentUser } from 'auth/CurrentUserProvider';
import Button from 'components/Button';
import { useDevSandbox } from 'components/DevSandbox/DevSandboxProvider';
import Icon from 'components/Icon';
import Link from 'components/Link';
import Text from 'components/Text';
import UserAvatar from 'components/UserAvatar';
import { ChatGptMessage, ChatGptMessageStatus, Who } from 'types/edgeFunctions';

import { useMessages } from './useMessages';

export interface MessageProps {
  id: string;
  who: Who;
  content: string;
  status: ChatGptMessageStatus;
  onScrollDown: () => void;
}

interface IMessageTypingWrapperProps {
  id: string;
  status: ChatGptMessageStatus;
  children: ReactNode;
  onScrollDown: () => void;
  updateMessage: (id: string, message: ChatGptMessage) => void;
  lastMessage: ChatGptMessage;
}

const MessageTypingWrapper = ({
  status,
  onScrollDown,
  id,
  updateMessage,
  lastMessage,
  children,
}: IMessageTypingWrapperProps) => {
  useEffect(() => {
    return () => {
      if (status === 'typing') {
        updateMessage(id, { ...lastMessage, status: 'received' });
      }
    };
  }, []);

  if (status === 'sending') {
    return (
      <Typist
        cursor={{
          show: true,
          blink: true,
          element: '|',
          hideWhenDone: false,
          hideWhenDoneDelay: 300,
        }}
      />
    );
  }

  if (status === 'typing') {
    return (
      <Typist
        startDelay={0}
        avgTypingDelay={0}
        stdTypingDelay={0}
        onCharacterTyped={() => onScrollDown()}
        onTypingDone={() => updateMessage(id, { ...lastMessage, status: 'received' })}
        cursor={{ show: false }}
      >
        {children}
      </Typist>
    );
  }

  return children;
};

const MarkdownContainer = styled.div<{ width: number }>`
  width: ${(props) => `${props.width}px` || '100%'};
  overflow-x: auto;
`;

interface ISyntaxMessageProps {
  children: ReactNode;
  style: any;
  match: RegExpExecArray;
  isTyping: boolean;
}

export const SyntaxMessage = ({
  children,
  style,
  match,
  isTyping,
  ...props
}: ISyntaxMessageProps) => {
  const [isCopied, setIsCopied] = useState(false);

  const handleCopyToClipboard = (code: string) => {
    navigator.clipboard.writeText(code);
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 500);
  };

  return (
    <Box position="relative">
      <Box position="absolute" right={2} top={2}>
        <Button
          onClick={() => handleCopyToClipboard(String(children))}
          aria-label="Copy code to clipboard"
          leftIcon={<Icon type="Copy" size={16} />}
          scheme="outline"
          size="sm"
          isDisabled={isTyping}
        >
          <Text fontSize="small">{isCopied ? 'Copied!' : 'Copy code'}</Text>
        </Button>
      </Box>
      <SyntaxHighlighter
        className="bds-syntax-highlighter"
        style={{ ...style }}
        language={match[1]}
        PreTag="div"
        {...props}
      >
        {String(children).replace(/\n$/, '')}
      </SyntaxHighlighter>
    </Box>
  );
};

const Message = ({ id, who, status, content, onScrollDown }: MessageProps) => {
  const { name, avatarUrl } = useCurrentUser();

  const { updateMessage, lastMessage } = useMessages();

  const {
    dimensions: { previewWidth },
  } = useDevSandbox();

  const isSentByElise = who === 'Elise';

  const sanitizedContent = content
    .split('\n')
    .map((line) => line.trim())
    .join('\n');

  return (
    <Box>
      <HStack spacing={4} alignItems="flex-start">
        <UserAvatar
          name={name}
          src={isSentByElise ? eliseSrc : avatarUrl}
          size="sm"
          border="none"
        />
        <VStack alignItems="flex-start" spacing={1}>
          <Text fontWeight="semibold">{isSentByElise ? 'Elise' : 'You'}</Text>
          <MessageTypingWrapper
            id={id}
            status={status}
            onScrollDown={onScrollDown}
            updateMessage={updateMessage}
            lastMessage={lastMessage}
          >
            <MarkdownContainer width={previewWidth! - 80}>
              <ReactMarkdown
                components={{
                  h1: ({ node, ...props }) => <Text fontSize="xLarge" {...props} />,
                  h2: ({ node, ...props }) => <Text fontSize="large" {...props} />,
                  ul: ({ node, ...props }) => <UnorderedList spacing={2} pl={3} {...props} />,
                  ol: ({ node, ...props }) => <OrderedList spacing={2} pl={3} {...props} />,
                  li: ({ node, ...props }) => <ListItem {...props} />,
                  a: ({ node, ...props }) => <Link {...props} />,
                  p: ({ node, ...props }) => (
                    <Text lineHeight="26px" fontWeight="regular" {...props} />
                  ),
                  code: ({ node, inline, className, children, ...props }) => {
                    const match = /language-(\w+)/.exec(className || '');

                    return !inline && match ? (
                      <SyntaxMessage
                        match={match}
                        style={dracula}
                        {...props}
                        isTyping={status === 'typing'}
                      >
                        {children}
                      </SyntaxMessage>
                    ) : (
                      <code className={className} {...props}>
                        {children}
                      </code>
                    );
                  },
                }}
              >
                {sanitizedContent}
              </ReactMarkdown>
            </MarkdownContainer>
          </MessageTypingWrapper>
        </VStack>
      </HStack>
    </Box>
  );
};

export default Message;
