import { extractCodeFromStorage, useCodeStorage } from 'hooks/useCodeStorage';
import { useToastWrapper } from 'hooks/useToastWrapper';
import { debounce, isEqual } from 'lodash';
import React, {
  ReactNode,
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { useLocation } from 'react-router-dom';

import { IDevSandboxProps, ICodePreviewContextData } from './types';

type CodePreviewContextData = ICodePreviewContextData;

export const CodePreviewContext = React.createContext({} as CodePreviewContextData);

interface IDevSandboxProviderProps extends IDevSandboxProps {
  children: ReactNode;
}

export const getGeneratedPageURL = (html = '', css = '', js = '') => {
  const getBlobURL = (code, type) => {
    const blob = new Blob([code], { type });

    return URL.createObjectURL(blob);
  };

  const cssURL = getBlobURL(css, 'text/css');
  const jsURL = getBlobURL(js, 'text/javascript');

  const source = `
    <html>
      <head>
      </head>
      <body style="margin: 0">
      ${html || ''}
      ${css && `<link rel="stylesheet" type="text/css" href="${cssURL}" />`}
      ${js && `<script src="${jsURL}"></script>`}
      </body>
    </html>
  `;

  return getBlobURL(source, 'text/html');
};

const DevSandboxProvider = forwardRef(
  (
    {
      id,
      oldKey,
      title,
      externalCodeUrl,
      browserUrl,
      mode,
      navigation,
      codeTabs,
      paneTabs,
      iframe,
      footer,
      flags,
      testCases,
      dimensions,
      children,
      callbacks,
    }: IDevSandboxProviderProps,
    ref
  ) => {
    const { pathname } = useLocation();

    const { toastError } = useToastWrapper();

    const baseStorageKey = id;

    const [html, setHtml] = useCodeStorage({
      key: `${baseStorageKey}-html`,
      oldKey: oldKey ? `${oldKey}-html` : undefined,
      defaultValue: codeTabs?.html || '',
    });
    const [css, setCss] = useCodeStorage({
      key: `${baseStorageKey}-css`,
      oldKey: oldKey ? `${oldKey}-css` : undefined,
      defaultValue: codeTabs?.css || '',
    });
    const [js, setJs] = useCodeStorage({
      key: `${baseStorageKey}-js`,
      oldKey: oldKey ? `${oldKey}-js` : undefined,
      defaultValue: codeTabs?.js || '',
    });

    // practice problems state management
    const [hasRun, setHasRun] = useState(false);
    const [outputs, setOutputs] = useState<unknown[]>([]);

    const passedTestsCount =
      testCases?.tests.filter((test, index) => {
        return isEqual(test.output, outputs[index]);
      }).length || 0;

    const [codeEditorWidth, setCodeEditorWidth] = useState(dimensions?.codeEditorWidth || 0);
    const [previewWidth, setPreviewWidth] = useState(dimensions?.previewWidth || 0);

    const [codeFrameUrl, setCodeFrameUrl] = useState(iframe?.codeFrameUrl || '');
    const [bufferFrameUrl, setBufferFrameUrl] = useState(iframe?.bufferFrameUrl || '');

    const [codeTabIndex, setCodeTabIndex] = useState(codeTabs?.tabIndex || 0);
    const [paneTabIndex, setPaneTabIndex] = useState(paneTabs?.tabIndex || 0);

    useEffect(() => {
      setPaneTabIndex(0);
    }, [pathname]);

    useEffect(() => {
      if (baseStorageKey === 'new') {
        setHtml('');
        setCss('');
        setJs('');

        return;
      }

      const htmlFromStorage = baseStorageKey
        ? extractCodeFromStorage({ key: baseStorageKey, code: 'html' })
        : '';
      const cssFromStorage = baseStorageKey
        ? extractCodeFromStorage({ key: baseStorageKey, code: 'css' })
        : '';
      const jsFromStorage = baseStorageKey
        ? extractCodeFromStorage({ key: baseStorageKey, code: 'js' })
        : '';

      // Prioritize loading code from server, e.g. when browsing user solution
      if (flags?.loadCodeFromServer) {
        setHtml(codeTabs?.html || '');
        setCss(codeTabs?.css || '');
        setJs(codeTabs?.js || '');

        return;
      }

      setHtml(htmlFromStorage || codeTabs?.html || '');
      setCss(cssFromStorage || codeTabs?.css || '');
      setJs(jsFromStorage || codeTabs?.js || '');
    }, [baseStorageKey]);

    const handleRunTestsClick = (e: React.MouseEvent<HTMLButtonElement>): number => {
      e.stopPropagation();

      if (!js?.includes(testCases?.function_name || '')) {
        toastError(`${testCases?.function_name} function must be defined in the code`);
      }

      const outputs: unknown[] = [];

      const removeCommentsAndLogs = (code: string) => {
        // Remove single-line comments
        code = code.replace(/\/\/.*$/gm, '');
        // Remove multi-line comments
        code = code.replace(/\/\*[\s\S]*?\*\//g, '');
        // Remove empty lines
        code = code.replace(/^\s*[\r\n]/gm, '');

        return code;
      };

      const sanitizedJs = removeCommentsAndLogs(js);

      try {
        const executeFunction = new Function(
          'funcName',
          'inputs',
          sanitizedJs + `; return eval(funcName).apply(null, inputs);`
        );

        testCases?.tests.forEach((test) => {
          const output = executeFunction(testCases?.function_name, test.input);

          outputs.push(output);
        });

        setOutputs(outputs);
        setHasRun(true);

        return (
          testCases?.tests.filter((test, index) => {
            return isEqual(test.output, outputs[index]);
          }).length || 0
        );
      } catch (error) {
        toastError(`Error executing user code: ${error}`, true);

        setOutputs(outputs);
        setHasRun(true);

        return 0;
      }
    };

    useImperativeHandle(ref, () => ({
      updateHtml: setHtml,
      runTests: handleRunTestsClick,
      onCodeFrameUrlChange: updateIframeContent,
    }));

    const updateIframeContent = () => {
      const newUrl = getGeneratedPageURL(html, css, js);

      setCodeFrameUrl(newUrl);

      callbacks?.onCodeFrameUrlChange(newUrl);
    };

    const debouncedUpdateIframeContent = debounce(updateIframeContent, 500);

    useEffect(() => {
      debouncedUpdateIframeContent();

      return () => {
        debouncedUpdateIframeContent.cancel();
      };
    }, [html, css, js]);

    return (
      <CodePreviewContext.Provider
        value={{
          id,
          title,
          browserUrl,
          externalCodeUrl: flags?.isExternalCode ? externalCodeUrl : '',
          oldKey: oldKey || undefined,
          mode: mode || 'interactive',
          navigation: {
            leftSide: navigation?.leftSide || null,
            header: navigation?.header || null,
            rightSide: navigation?.rightSide || null,
          },
          codeTabs: {
            ...codeTabs,
            html,
            css,
            js,
            tabIndex: codeTabIndex,
            tabNames: codeTabs?.tabNames || ['HTML', 'CSS', 'JS'],
          },
          paneTabs: {
            ...paneTabs,
            tabIndex: paneTabIndex,
            tabs: paneTabs?.tabs || [],
          },
          testCases: testCases || { input_names: [], tests: [], function_name: '' },
          iframe: {
            codeFrameUrl,
            bufferFrameUrl,
          },
          footer: {
            leftSide: footer?.leftSide || null,
            header: footer?.header || null,
            rightSide: footer?.rightSide || null,
          },
          flags: {
            loadCodeFromServer: flags?.loadCodeFromServer || false,
            isExternalCode: flags?.isExternalCode || false,
          },
          dimensions: {
            codeEditorWidth,
            previewWidth,
          },
          handlers: {
            onHtmlChange: setHtml,
            onCssChange: setCss,
            onJsChange: setJs,
            onCodeTabIndexChange: setCodeTabIndex,
            onPaneTabIndexChange: setPaneTabIndex,
            onCodeFrameUrlChange: updateIframeContent,
            onCodeEditorWidthChange: setCodeEditorWidth,
            onPreviewWidthChange: setPreviewWidth,
            onCodeFrameChange: setCodeFrameUrl,
            onBufferFrameChange: setBufferFrameUrl,
            onRunTestsClick: handleRunTestsClick,
            onSetOutputs: setOutputs,
          },
          callbacks: callbacks || {
            onCodeFrameUrlChange: (codeFrameUrl: string) => setCodeFrameUrl(codeFrameUrl),
          },
          state: {
            practiceProblemChecks: {
              hasRun,
              outputs,
              passedTestsCount,
            },
          },
        }}
      >
        {children}
      </CodePreviewContext.Provider>
    );
  }
);

export const useDevSandbox = (): CodePreviewContextData => {
  const context = useContext(CodePreviewContext);

  if (!context) {
    throw new Error('useDevSandbox must be used within an DevSandboxContentProvider');
  }

  return context;
};

export default DevSandboxProvider;
