import { IconButton, Wrap, useTheme } from '@chakra-ui/react';

import Icon from './Icon';

interface IShareButtonsProps {
  text: string;
  link: string;
  emailSubject: string;
}

const shareOnTwitter = (text: string, link: string) => {
  const twitterUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
    `${text} #BigDevSoon #justcodeit @BigsonDev`
  )}&url=${encodeURIComponent(link)}`;

  window.open(twitterUrl, '_blank');
};

const shareOnReddit = (text: string, link: string) => {
  const redditUrl = `https://www.reddit.com/submit?title=${encodeURIComponent(
    `${text} #BigDevSoon #justcodeit`
  )}&url=${encodeURIComponent(link)}`;

  window.open(redditUrl, '_blank');
};

const shareOnLinkedin = (link: string) => {
  const linkedinUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
    link
  )}`;

  window.open(linkedinUrl, '_blank');
};

const shareOnFacebook = (link: string) => {
  const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
    link
  )}&hashtag=${encodeURIComponent('#BigDevSoon')}`;

  window.open(facebookUrl, '_blank');
};

const shareOnTelegram = (text: string, link: string) => {
  const telegramUrl = `https://telegram.me/share/url?url=${encodeURIComponent(
    link
  )}&text=${encodeURIComponent(`${text} #BigDevSoon #justcodeit`)}`;

  window.open(telegramUrl, '_blank');
};

const shareOnWhatsApp = (text: string, link: string) => {
  const whatsAppUrl = `https://web.whatsapp.com/?send=text=${encodeURIComponent(
    `${text} #BigDevSoon #justcodeit ${link}`
  )}`;

  window.open(whatsAppUrl, '_blank');
};

const shareViaEmail = (text: string, link: string, emailSubject: string) => {
  const emailUrl = `mailto:?subject=${encodeURIComponent(emailSubject)}&body=${encodeURIComponent(
    `${text} #BigDevSoon #justcodeit` + ' ' + link
  )}`;

  window.open(emailUrl);
};

const ShareButtons = ({ text, link, emailSubject }: IShareButtonsProps) => {
  const { opacity } = useTheme();

  return (
    <Wrap spacing={4}>
      <IconButton
        aria-label="share-via-twitter"
        backgroundColor="#000000"
        transition="all 0.2 ease-out"
        onClick={() => shareOnTwitter(text, link)}
        _hover={{ opacity: opacity.primary }}
      >
        <Icon type="TwitterShare" />
      </IconButton>
      <IconButton
        aria-label="share-via-reddit"
        backgroundColor="#FF4500"
        transition="all 0.2 ease-out"
        onClick={() => shareOnReddit(text, link)}
        _hover={{ opacity: opacity.primary }}
      >
        <Icon type="RedditShare" />
      </IconButton>
      <IconButton
        aria-label="share-via-linkedin"
        backgroundColor="#1293D2"
        transition="all 0.2 ease-out"
        onClick={() => shareOnLinkedin(link)}
        _hover={{ opacity: opacity.primary }}
      >
        <Icon type="LinkedinShare" />
      </IconButton>
      <IconButton
        aria-label="share-via-facebook"
        backgroundColor="#4267B2"
        transition="all 0.2 ease-out"
        onClick={() => shareOnFacebook(link)}
        _hover={{ opacity: opacity.primary }}
      >
        <Icon type="FacebookShare" />
      </IconButton>
      <IconButton
        aria-label="share-via-telegram"
        backgroundColor="#229ED9"
        transition="all 0.2 ease-out"
        onClick={() => shareOnTelegram(text, link)}
        _hover={{ opacity: opacity.primary }}
      >
        <Icon type="TelegramShare" />
      </IconButton>
      <IconButton
        aria-label="share-via-whats-app"
        backgroundColor="#25D366"
        transition="all 0.2 ease-out"
        onClick={() => shareOnWhatsApp(text, link)}
        _hover={{ opacity: opacity.primary }}
      >
        <Icon type="WhatsAppShare" />
      </IconButton>
      <IconButton
        aria-label="share-via-whats-email"
        backgroundColor="#8F8F8F"
        transition="all 0.2 ease-out"
        onClick={() => shareViaEmail(text, link, emailSubject)}
        _hover={{ opacity: opacity.primary }}
      >
        <Icon type="EmailShare" />
      </IconButton>
    </Wrap>
  );
};

export default ShareButtons;
