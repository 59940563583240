import { useState, useEffect } from 'react';

const initialTexts = [
  'Think about something nice...',
  "Just a moment, we're processing...",
  'Hang tight, working on it...',
  'Thanks for your patience!',
  'Still loading...',
];
const delayedTexts = [
  "We're almost there...",
  'Just a bit longer...',
  'Finalizing the last details...',
  'Thank you for waiting, almost done!',
  'Hang in there, nearly complete...',
];

const superDelayedTexts = [
  'Wow... 🙈',
  'it takes some time, huh?',
  'Well... almost there!',
  'Will be worth it, for sure!',
  'Patience is a virtue!',
];

const useLongSpinnerText = (isLoading: boolean): string | null => {
  const [text, setText] = useState<string | null>(null);

  useEffect(() => {
    let timer1: ReturnType<typeof setTimeout>,
      timer2: ReturnType<typeof setTimeout>,
      timer3: ReturnType<typeof setTimeout>;

    if (isLoading) {
      timer1 = setTimeout(() => {
        const randomInitialText = initialTexts[Math.floor(Math.random() * initialTexts.length)];
        setText(randomInitialText);
      }, 3000);

      timer2 = setTimeout(() => {
        const randomDelayedText = delayedTexts[Math.floor(Math.random() * delayedTexts.length)];
        setText(randomDelayedText);
      }, 10000);

      timer3 = setTimeout(() => {
        const randomDelayedText =
          superDelayedTexts[Math.floor(Math.random() * delayedTexts.length)];
        setText(randomDelayedText);
      }, 15000);
    } else {
      setText(null);
    }

    return () => {
      clearTimeout(timer1);
      clearTimeout(timer2);
      clearTimeout(timer3);
    };
  }, [isLoading, initialTexts, delayedTexts]);

  return text;
};

export default useLongSpinnerText;
