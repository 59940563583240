import { useQueryClient } from '@tanstack/react-query';

import { useCurrentUser } from 'auth/CurrentUserProvider';
import { CodePreview } from 'types/edgeFunctions';
import { TanstackQueryName } from 'types/frontend';

export interface IUseLikeCodeParams {
  queryKey: unknown[];
  invalidateKeys: unknown[][];
  entityId: string;
  isLiked: boolean;
  userId?: string | null;
}

export const useLikeCode = ({
  queryKey,
  invalidateKeys,
  entityId,
  isLiked,
  userId,
}: IUseLikeCodeParams) => {
  const queryClient = useQueryClient();
  const { id: currentUserId } = useCurrentUser();

  const onMutate = async () => {
    await queryClient.cancelQueries({
      queryKey,
    });

    const previousData = queryClient.getQueryData<CodePreview[]>(queryKey);

    queryClient.setQueryData<CodePreview[]>(queryKey, (old: any) => {
      if (!old) return;

      // Handle scenario where 'old' is an object possibly with 'solutions' array and 'my_solution'
      if (old.solutions && Array.isArray(old.solutions)) {
        const updatedSolutions = old.solutions.map((codePreview) => {
          if (codePreview.id === entityId) {
            return {
              ...codePreview,
              is_liked: !isLiked,
              likes: isLiked ? codePreview.likes - 1 : codePreview.likes + 1,
            };
          }
          return codePreview;
        });

        return { ...old, solutions: updatedSolutions };
      }

      // Handle scenario where 'old' is just an object
      if (typeof old === 'object' && !Array.isArray(old)) {
        if (old.id === entityId) {
          return {
            ...old,
            is_liked: !isLiked,
            likes: isLiked ? old.likes - 1 : old.likes + 1,
          };
        }

        return old;
      }

      // Existing handling for scenario 3 where 'old' is an array
      return old.map((codePreview) => {
        if (codePreview.id === entityId) {
          return {
            ...codePreview,
            is_liked: !isLiked,
            likes: isLiked ? codePreview.likes! - 1 : codePreview.likes! + 1,
          };
        }

        return codePreview;
      });
    });

    return { previousData };
  };

  const onError = (error: any, variables: any, context: any) => {
    queryClient.setQueryData(queryKey, context.previousData);
  };

  const onSettled = () => {
    invalidateKeys.forEach((key) => {
      queryClient.invalidateQueries({ queryKey: key });
    });
    queryClient.invalidateQueries({
      queryKey: [TanstackQueryName.GetUserProfile, userId],
    });
    queryClient.invalidateQueries({
      queryKey: [TanstackQueryName.GetUserAchievements, currentUserId],
    });
  };

  return {
    onMutate,
    onError,
    onSettled,
  };
};
