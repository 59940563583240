import { SupabaseClient } from '@supabase/supabase-js';
import { useQueryWithErrorBoundary, useSupabaseTypedClient } from 'hooks/reactQuery';

import { useCurrentUser } from 'auth/CurrentUserProvider';
import { Plan_Type_Enum } from 'types/databaseEnums';
import {
  Prototype,
  GetPrototypeResponse,
  GetSolutionParams,
  SummaryReport,
} from 'types/edgeFunctions';
import { TanstackQueryName } from 'types/frontend';
import { Database } from 'types/supabase';

interface IGetPrototypeQueryParams {
  supabase: SupabaseClient<Database>;
  currentUserId: string;
  solutionUrl: string;
}

const getPrototypeQueryFn = async ({
  supabase,
  currentUserId,
  solutionUrl,
}: IGetPrototypeQueryParams): Promise<Prototype> => {
  const prototypeResponse = await supabase
    .from('user_prototype')
    .select(
      'id, created_at, prompt_text, is_private, prompt_image_url, prompt_url, html, css, js, design_score, code_score, design_report, code_report, ai_feedback, title, screen_name, screenshot_url, solution_url, repository_url, preview_url, user_profile_view (id, avatar_url, name, screen_name, plan, experience)'
    )
    .eq('solution_url', solutionUrl)
    .single();

  if (prototypeResponse.error) throw prototypeResponse.error;

  const [likes, isLikedByMe, comments, explore] = await Promise.all([
    supabase.rpc('count_likes', { input_entity_id: prototypeResponse.data.id }),
    currentUserId
      ? supabase
          .from('user_like')
          .select('voter_id')
          .eq('voter_id', currentUserId)
          .eq('entity_id', prototypeResponse.data.id)
      : Promise.resolve(undefined),
    supabase
      .from('support_center')
      .select('*', { count: 'exact', head: true })
      .eq('entity_id', prototypeResponse.data.id)
      .not('user_id', 'is', null),
    supabase
      .from('user_code_view')
      .select('*', { count: 'exact', head: true })
      .or(`html.neq.,css.neq.,js.neq.`)
      .eq('type', 'prototype')
      .not('user_id', 'is', null)
      .eq('is_private', false),
  ]);

  if (likes.error) throw likes.error;
  if (isLikedByMe?.error) throw isLikedByMe.error;
  if (comments.error) throw comments.error;
  if (explore.error) throw explore.error;

  const prototype = prototypeResponse.data!;
  const screenshotUrl = prototype.screenshot_url;

  return {
    id: prototype.id,
    title: prototype.title || '',
    is_private: prototype.is_private || false,
    screen_name: prototype.screen_name || '',
    live_preview_url: prototype.preview_url || '',
    screenshot_url: screenshotUrl || '',
    repository_url: prototype.repository_url || '',
    preview_url: prototype.preview_url || '',
    solution_url: prototype.solution_url || '',
    created_at: prototype.created_at || '',
    html: prototype.html || '',
    css: prototype.css || '',
    js: prototype.js || '',
    is_my_solution: currentUserId === prototype.user_profile_view!.id,
    user: {
      id: prototype.user_profile_view!.id || '',
      avatar_url: prototype.user_profile_view!.avatar_url || '',
      name: prototype.user_profile_view!.name || '',
      screen_name: prototype.user_profile_view!.screen_name || '',
      plan: prototype.user_profile_view!.plan as Plan_Type_Enum,
      xp: prototype.user_profile_view?.experience || 0,
    },
    likes: likes.data || 0,
    is_liked: (isLikedByMe?.data || []).length > 0,
    comments_count: comments.count || 0,
    solutions_count: explore.count || 0,
    design_report: (prototype.design_report as SummaryReport[]) || null,
    code_report: (prototype.code_report as SummaryReport[]) || null,
    design_score: prototype.design_score || null,
    code_score: prototype.code_score || null,
    ai_feedback: prototype.ai_feedback || '',
    prompt_image_url: prototype.prompt_image_url || '',
    prompt_text: prototype.prompt_text || '',
    prompt_url: prototype.prompt_url || '',
  };
};

interface IUseGetPrototypeQueryResponse {
  data: GetPrototypeResponse | undefined | null;
  isPending: boolean;
}

export const useGetPrototypeQuery = ({
  solution_url,
}: GetSolutionParams): IUseGetPrototypeQueryResponse => {
  const { id: currentUserId } = useCurrentUser();
  const supabase = useSupabaseTypedClient();

  const { data, isPending } = useQueryWithErrorBoundary({
    queryKey: [TanstackQueryName.GetPrototype, solution_url],
    queryFn: async () => {
      const data = await getPrototypeQueryFn({
        supabase,
        currentUserId: currentUserId,
        solutionUrl: solution_url,
      });

      return data;
    },
  });

  if (!data) {
    return {
      data: null,
      isPending,
    };
  }

  return {
    data,
    isPending,
  };
};
