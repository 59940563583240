import { Box, CloseButton, HStack, VStack, useTheme, useToast } from '@chakra-ui/react';
import { captureException } from '@sentry/react';
import { useNavigate } from 'react-router-dom';

import { useCurrentUser } from 'auth/CurrentUserProvider';
import Icon, { IconType } from 'components/Icon';
import ProjectElement from 'components/ProjectElement';
import Text from 'components/Text';
import { Achievement } from 'types/edgeFunctions';
import { ERoute } from 'types/frontend';

export const useToastWrapper = () => {
  const { id: currentUserId } = useCurrentUser();
  const { borderRadius } = useTheme();
  const toast = useToast();

  const navigate = useNavigate();

  const toastSuccess = (description: string) => {
    toast({
      status: 'success',
      description,
      duration: 3000,
      position: 'bottom-right',
    });
  };

  const toastError = (description: string, skipCapture = false) => {
    if (!skipCapture) {
      captureException(description);
    }

    toast({
      status: 'error',
      description,
      duration: 5000,
      isClosable: true,
      position: 'bottom-right',
    });
  };

  const toastInfo = (description: string) => {
    toast({
      status: 'info',
      description,
      duration: 5000,
      isClosable: true,
      position: 'bottom-right',
    });
  };

  const closeAllToasts = () => {
    toast.closeAll();
  };

  const toastAchievement = ({ type, name, xp }: Achievement) => {
    toast({
      duration: 3000,
      isClosable: true,
      position: 'top-right',
      render: () => (
        <Box
          mr={4}
          position="relative"
          p="2px"
          overflow="hidden"
          borderRadius={borderRadius}
          border="none"
          cursor="pointer"
          _before={{
            content: '""',
            position: 'absolute',
            top: '-2px',
            bottom: '-2px',
            left: '-2px',
            right: '-2px',
            background:
              'linear-gradient(360deg, rgba(159, 122, 234, 0.87) 1.16%, rgba(237, 137, 54, 0.87) 95.35%)',
          }}
        >
          <Box
            bg="gray.800"
            position="relative"
            borderRadius={borderRadius}
            p={4}
            minWidth="320px"
            onClick={() => {
              navigate(`${ERoute.Profile}/${currentUserId}`, { state: { fromAchievements: true } });
              closeAllToasts();
            }}
            transition="all 0.2s ease-out"
            _hover={{
              backgroundColor: 'gray.900',
            }}
          >
            <VStack alignItems="flex-start" spacing={4}>
              <HStack justifyContent="space-between" width="100%">
                <Text fontWeight="semibold">New achievement unlocked</Text>
                <CloseButton
                  onClick={(e) => {
                    e.stopPropagation();
                    closeAllToasts();
                  }}
                />
              </HStack>
              <HStack justifyContent="space-between" width="100%">
                <HStack>
                  <Icon type={type as IconType} size={32} />
                  <Text fontSize="small" fontWeight="semibold">
                    {name}
                  </Text>
                </HStack>
                <ProjectElement type="experience" experience={xp} />
              </HStack>
            </VStack>
          </Box>
        </Box>
      ),
    });
  };

  return { toastSuccess, toastInfo, toastError, toastAchievement };
};
