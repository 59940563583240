import { useDisclosure } from '@chakra-ui/react';

import { useAuth } from 'auth/AuthProvider';
import { useCurrentUser } from 'auth/CurrentUserProvider';

import BigTokens from './BigTokens';
import Button, { IButtonProps } from './Button';
import { AccountTerminatedModal } from './Modal/AccountTerminatedModal';
import { GitHubSignInModal } from './Modal/GitHubSignInModal';
import { NotEnoughBigTokensModal } from './Modal/NotEnoughBigTokensModal';

export interface IProtectedElement extends IButtonProps {
  asButton?: boolean;
  children: React.ReactNode;
  requiredTokens?: number;
  subscribersOnly?: boolean;
  divDisplay?: 'inline' | 'block' | 'inline-block';
}

export const ProtectedElement = ({
  asButton = true,
  requiredTokens = 0,
  children,
  onClick,
  fullWidth,
  divDisplay = 'block',
  ...props
}: IProtectedElement) => {
  const { userId } = useAuth();
  const { isTerminated, bigTokensCollected } = useCurrentUser();
  const {
    isOpen: isAccountTerminatedModalOpen,
    onOpen: onAccountTerminatedModalOpen,
    onClose: onAccountTerminatedModalClose,
  } = useDisclosure();

  const {
    isOpen: isBigTokensModalOpen,
    onOpen: onBigTokensModalOpen,
    onClose: onBigTokensModalClose,
  } = useDisclosure();

  const {
    isOpen: isGitHubSignInModalOpen,
    onOpen: onGitHubSignInModalOpen,
    onClose: onGitHubSignInModalClose,
  } = useDisclosure();

  const hasSufficientTokens = requiredTokens <= bigTokensCollected;

  const buttonType = !userId || isTerminated || !hasSufficientTokens ? 'button' : 'submit';

  const handleOnClick = (event) => {
    if (!userId) {
      event.stopPropagation();
      onGitHubSignInModalOpen();

      return;
    }

    if (isTerminated) {
      event.stopPropagation();
      onAccountTerminatedModalOpen();

      return;
    }

    if (!hasSufficientTokens) {
      event.stopPropagation();
      onBigTokensModalOpen();

      return;
    }

    onClick?.(event);
  };

  const buttonToRender =
    requiredTokens > 0 ? (
      <Button
        {...props}
        fullWidth={fullWidth}
        type={buttonType}
        rightIcon={<BigTokens amount={requiredTokens} color={!props.scheme ? 'black' : 'white'} />}
      >
        {children}
      </Button>
    ) : (
      <Button {...props} fullWidth={fullWidth} type={buttonType}>
        {children}
      </Button>
    );

  const element = asButton ? <>{buttonToRender}</> : <>{children}</>;

  return (
    <>
      <GitHubSignInModal isOpen={isGitHubSignInModalOpen} onClose={onGitHubSignInModalClose} />
      <AccountTerminatedModal
        isOpen={isAccountTerminatedModalOpen}
        onClose={onAccountTerminatedModalClose}
      />
      <NotEnoughBigTokensModal isOpen={isBigTokensModalOpen} onClose={onBigTokensModalClose} />

      <div
        onClickCapture={handleOnClick}
        style={{ width: fullWidth ? '100%' : 'initial', display: divDisplay }}
      >
        {element}
      </div>
    </>
  );
};
