import { VStack } from '@chakra-ui/layout';
import { captureException } from '@sentry/react';
import { useSupabaseTypedClient } from 'hooks/reactQuery';
import { useToastWrapper } from 'hooks/useToastWrapper';
import { useState } from 'react';

import Button from 'components/Button';
import Modal from 'components/Modal';
import Text from 'components/Text';
import { GITHUB_COOKIE_KEY, GITHUB_EMULATOR_TOKEN } from 'config/constants/github';
import { IModal } from 'types/frontend';
import { setCookie } from 'utils/cookies';
import { isLocalEnvironment } from 'utils/env';

interface IGitHubAttachModal extends IModal {}

export const GitHubAttachModal = ({ isOpen, onClose }: IGitHubAttachModal) => {
  const [isLoading, setIsLoading] = useState(false);
  const { toastSuccess } = useToastWrapper();
  const supabase = useSupabaseTypedClient();
  const attachGitHub = async () => {
    setIsLoading(true);

    try {
      if (isLocalEnvironment()) {
        setCookie(GITHUB_COOKIE_KEY, GITHUB_EMULATOR_TOKEN);
        await supabase.auth.signUp({
          email: `local-email+${Math.floor(Math.random() * 9999999)}@bigdevsoon.me`,
          password: '123456',
        });
      } else {
        await supabase.auth.signInWithOAuth({
          provider: 'github',
          options: {
            scopes: 'user:email public_repo',
          },
        });
      }

      toastSuccess('GitHub attached properly.');
      onClose();
    } catch (error: any) {
      console.error(error);
      captureException(error);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={attachGitHub}
      header="Connect GitHub"
      footer={
        <>
          <Button scheme="ghost" onClick={onClose}>
            Close
          </Button>
          <Button type="submit" isLoading={isLoading}>
            Confirm
          </Button>
        </>
      }
    >
      <VStack align="flex-start" spacing={5}>
        <Text fontWeight="regular">Your GitHub account will be connected to the BigDevSoon.</Text>
      </VStack>
    </Modal>
  );
};
