import { Skeleton, Stack, VStack } from '@chakra-ui/react';
import { useSupabaseTypedClient } from 'hooks/reactQuery';
import { useState } from 'react';

import Button from 'components/Button';
import { useBilling } from 'features/billing/context/BillingProvider';
import { EdgeFunctionName, PaymentMethodType } from 'types/edgeFunctions';

import { CardShortInfo } from './CardShortInfo';
import { PayPalShortInfo } from './PayPalShortInfo';
import SettingsTile from './SettingsTile';

interface IPaymentMethodBilling {
  brand?: string | null;
  last4?: string | null;
  expirationDate?: Date;
  paymentMethodType: PaymentMethodType;
}

export const PaymentMethodBilling = ({
  brand,
  last4,
  expirationDate,
  paymentMethodType,
}: IPaymentMethodBilling) => {
  const { isWaitingForNewData } = useBilling();
  const [isProcessing, setIsProcessing] = useState(false);
  const supabaseClient = useSupabaseTypedClient();

  const handleOnPaymentMethodChangeClick = async () => {
    setIsProcessing(true);
    const { data, error } = await supabaseClient.functions.invoke<string>(
      EdgeFunctionName.PaymentMethodChange
    );

    setIsProcessing(false);

    if (error || !data) {
      console.error(error);
      return;
    }

    window.location.replace(data);
  };

  return (
    <>
      <SettingsTile
        title="Payment method"
        subtitle="Payment method information (Credit Card or PayPal)"
      >
        {isWaitingForNewData ? (
          <Stack>
            <Skeleton height="60px" />
            <Skeleton height="40px" />
          </Stack>
        ) : (
          <div data-sl="mask">
            <VStack align="stretch" spacing={6}>
              {paymentMethodType === 'paypal' ? (
                <PayPalShortInfo />
              ) : (
                <CardShortInfo
                  brand={brand ?? ''}
                  last4={last4 ?? ''}
                  expirationDate={expirationDate}
                />
              )}

              <Button
                scheme="outline"
                width="fit-content"
                onClick={handleOnPaymentMethodChangeClick}
                isLoading={isProcessing}
              >
                Change payment method
              </Button>
            </VStack>
          </div>
        )}
      </SettingsTile>
    </>
  );
};
