import { ButtonGroup, HStack } from '@chakra-ui/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { supabaseMutationFn, useSupabaseTypedClient } from 'hooks/reactQuery';
import { useToastWrapper } from 'hooks/useToastWrapper';

import Button from 'components/Button';
import Icon from 'components/Icon';
import Modal from 'components/Modal';
import { ProtectedElement } from 'components/ProtectedElement';
import Text from 'components/Text';
import { EdgeFunctionName, DeleteCommunityQuestionParams } from 'types/edgeFunctions';
import { TanstackQueryName } from 'types/frontend';

interface DeleteMessageModalProps {
  isOpen: boolean;
  onClose: () => void;
  messageId: string;
  entityId: string;
  refetch: () => void;
}

const DeleteMessageModal = ({
  isOpen,
  onClose,
  messageId,
  entityId,
  refetch,
}: DeleteMessageModalProps) => {
  const { toastSuccess, toastError } = useToastWrapper();

  const supabase = useSupabaseTypedClient();

  const queryClient = useQueryClient();

  const mutation = useMutation<string, string, DeleteCommunityQuestionParams>({
    mutationFn: supabaseMutationFn(({ id }) =>
      supabase.functions.invoke<string>(EdgeFunctionName.DeleteCommunityQuestion, {
        body: { id } as DeleteCommunityQuestionParams,
      })
    ),
    onSuccess: async () => {
      queryClient.invalidateQueries({
        queryKey: [TanstackQueryName.GetCommunityQuestions, entityId],
      });
      refetch();

      onClose();
      toastSuccess('Message deleted');
    },
    onError: () => {
      toastError(`Can't delete message`);
    },
  });

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={() => mutation.mutate({ id: messageId })}
      header={
        <HStack>
          <Icon type="DeleteModal" />
          <Text fontSize="xLarge" fontWeight="semibold">
            Delete message
          </Text>
        </HStack>
      }
      footer={
        <ButtonGroup>
          <Button onClick={onClose} scheme="ghost">
            <Text fontWeight="semibold" opacity="secondary">
              Cancel
            </Text>
          </Button>
          <ProtectedElement type="submit" scheme="danger" isLoading={mutation.isPending}>
            Delete message
          </ProtectedElement>
        </ButtonGroup>
      }
    >
      <Text fontWeight="regular" pb={3}>
        Are you sure about deleting this message?
      </Text>
      <Text fontWeight="semibold">This action cannot be undone.</Text>
    </Modal>
  );
};

export default DeleteMessageModal;
