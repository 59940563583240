import { AddIcon } from '@chakra-ui/icons';
import { SimpleGrid, Center, Container, Stack, VStack, Spinner } from '@chakra-ui/react';
import { InfiniteData } from '@tanstack/react-query';
import { useGetCodeFramesQuery } from 'hooks/queries/code-frames/useGetCodeFramesQuery';
import { useScrollToTop } from 'hooks/useScrollToTop';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as NoCodeFramesIllustration } from 'assets/illustrations/NoCodeFrames.svg';
import { useAuth } from 'auth/AuthProvider';
import { useCurrentUser } from 'auth/CurrentUserProvider';
import CodePreviewCard from 'components/CodePreviewCard';
import GradientTooltip from 'components/GradientTooltip';
import PageLoader from 'components/PageLoader';
import { ProtectedElement } from 'components/ProtectedElement';
import { SEO } from 'components/SEO';
import Tabs from 'components/Tabs';
import Text from 'components/Text';
import { useMediaQueries } from 'layout/MediaQueriesProvider';
import { CodePreview } from 'types/edgeFunctions';
import { ERoute, TanstackQueryName } from 'types/frontend';

const CodeFrames = () => {
  useScrollToTop();
  const [tabIndex, setTabIndex] = useState(0);

  const { id: currentUserId, isFree, codeFramesCount } = useCurrentUser();
  const { userId: authId } = useAuth();

  const { data, isFetching, ref } = useGetCodeFramesQuery({
    userId: tabIndex === 0 ? null : currentUserId,
  });

  const navigate = useNavigate();

  const { isMobile } = useMediaQueries();

  const renderEmptyCodeFrames = () => {
    return (
      <Center width="100%" mt={isMobile ? 10 : '12vh'}>
        <Stack spacing={5}>
          <NoCodeFramesIllustration />
          <Text fontSize="xLarge" fontWeight="regular" textAlign="center">
            No code frames found.
          </Text>
        </Stack>
      </Center>
    );
  };

  const codeFramesData = (data as unknown as InfiniteData<CodePreview[]>) || [];
  const codeFrames =
    !authId && tabIndex === 1
      ? []
      : codeFramesData.pages.reduce((acc, page) => acc.concat(page), [] as CodePreview[]) || [];

  const LIMIT = isFree ? 10 : 100;
  const isAboveLimit = codeFramesCount >= LIMIT;

  const renderContent = () => {
    const isFirstPage = codeFramesData.pages.length === 0;

    if (isFirstPage && isFetching) {
      return <PageLoader shift />;
    }

    if (codeFrames?.length === 0) {
      return renderEmptyCodeFrames();
    }

    return (
      <>
        <SimpleGrid columns={[1, 1, 1, 2, 3]} gap={8} mt={4}>
          {(codeFrames || []).map((userCode) => {
            return (
              <CodePreviewCard
                key={userCode.id}
                {...userCode}
                isMySolution={tabIndex === 0}
                canEdit={tabIndex === 0}
                width="100%"
                height="300px"
                queryKey={[TanstackQueryName.GetCodeFrames, currentUserId]}
                invalidateKeys={[
                  [TanstackQueryName.GetCodeFrame, userCode.solution_url!],
                  [TanstackQueryName.GetCodeFrames, null],
                  [TanstackQueryName.GetProfileCode, userCode.user_id!, 'code-frame'],
                  [TanstackQueryName.GetUserCode, currentUserId, 0],
                  [TanstackQueryName.GetUserCode, currentUserId, 1],
                  [TanstackQueryName.GetUserCode, currentUserId, 2],
                ]}
              />
            );
          })}
        </SimpleGrid>
        <div ref={ref} />
        <Center width="100%" mt={10}>
          {isFetching && !isFirstPage && <Spinner size="lg" />}
        </Center>
      </>
    );
  };

  return (
    <Container maxW="1400px" width="100%">
      <SEO
        title="BigDevSoon | Code Frames"
        description="Create and share your code frames with the community."
        url={`${process.env.REACT_APP_SITE_URL}CodeFrames`}
      />
      <VStack spacing={4} alignItems="flex-start" width="100%">
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          background="gray.900"
          width="100%"
          py={3}
        >
          <VStack alignItems="flex-start">
            <Text fontSize="xxLarge" fontWeight="semibold">
              Code Frames
            </Text>
            <Text fontSize="small" fontWeight="regular" opacity="secondary">
              Create code and share it with anyone.
            </Text>
          </VStack>
          <GradientTooltip
            label={isAboveLimit ? 'You have reached the limit of code frames' : null}
          >
            <span>
              <ProtectedElement
                isDisabled={isAboveLimit}
                leftIcon={<AddIcon boxSize={3} />}
                onClick={() => {
                  navigate(`${ERoute.CodeFrames}${ERoute.NewCodeFrame}`, {
                    state: {
                      shouldCreate: true,
                    },
                  });
                }}
              >
                New {!isMobile && 'Code Frame'}
              </ProtectedElement>
            </span>
          </GradientTooltip>
        </Stack>
        <Tabs
          tabs={['Most Popular', 'Your Work']}
          tabIndex={tabIndex}
          onChange={(index) => setTabIndex(index)}
        />
      </VStack>
      {renderContent()}
    </Container>
  );
};

export default CodeFrames;
