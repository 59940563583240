import { Spinner, VStack } from '@chakra-ui/react';
import { captureException } from '@sentry/react';
import { useSupabaseTypedClient } from 'hooks/reactQuery';
import { useToastWrapper } from 'hooks/useToastWrapper';
import { useEffect, useState } from 'react';
import useLocalStorageState from 'use-local-storage-state';
import { v4 as uuidv4 } from 'uuid';

import { SEO } from 'components/SEO';
import Text from 'components/Text';
import { REF_COOKIE_KEY, GITHUB_COOKIE_KEY } from 'config/constants/github';
import { AccountCreateParams, EdgeFunctionName } from 'types/edgeFunctions';
import { deleteCookie, getCookie } from 'utils/cookies';

const IDEMPOTENCY_KEY = 'idempotency-key';

const Onboarding = () => {
  const supabase = useSupabaseTypedClient();
  const { toastError } = useToastWrapper();
  const [isFinalizing, setIsFinalizing] = useState(false);
  const [idempotencyKey, _] = useLocalStorageState<string>(IDEMPOTENCY_KEY, {
    defaultValue: uuidv4(),
  });

  useEffect(() => {
    const finalizeOnboarding = async () => {
      if (isFinalizing) return;

      setIsFinalizing(true);

      try {
        const githubAccessToken = getCookie(GITHUB_COOKIE_KEY) || '';
        const refCode = getCookie(REF_COOKIE_KEY);

        const { error } = await supabase.functions.invoke(EdgeFunctionName.AccountCreate, {
          body: {
            github_access_token: githubAccessToken,
            ref_code: refCode,
          } as AccountCreateParams,
          headers: {
            'Idempotency-Key': idempotencyKey,
          },
        });

        if (error) throw error;

        deleteCookie(GITHUB_COOKIE_KEY);
        deleteCookie(REF_COOKIE_KEY);
        localStorage.removeItem(IDEMPOTENCY_KEY);

        await supabase.auth.refreshSession();
      } catch (error) {
        captureException(error);
        toastError('Error while completing Onboarding');
      }
    };

    finalizeOnboarding();
  }, []);

  return (
    <>
      <SEO
        title="BigDevSoon | Onboarding"
        description="Onboarding page"
        url={`${process.env.REACT_APP_SITE_URL}onboarding`}
      />
      <VStack>
        <Text fontSize="xxxLarge">🍻 with us for a second</Text>
        <Text fontWeight="regular" opacity="secondary" pb={5}>
          We are making sure everything will be awesome.
        </Text>
        <Spinner size="lg" />
      </VStack>
    </>
  );
};

export default Onboarding;
