import { ButtonGroup, HStack, Stack } from '@chakra-ui/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { supabaseMutationFn, useSupabaseTypedClient } from 'hooks/reactQuery';
import { useToastWrapper } from 'hooks/useToastWrapper';

import { useCurrentUser } from 'auth/CurrentUserProvider';
import Button from 'components/Button';
import Icon from 'components/Icon';
import Modal from 'components/Modal';
import { ProtectedElement } from 'components/ProtectedElement';
import Text from 'components/Text';
import { EdgeFunctionName, DeleteCodeParams, CodeEditorType } from 'types/edgeFunctions';
import { TanstackQueryName } from 'types/frontend';

interface DeleteCodeModalProps {
  isOpen: boolean;
  entityId: string;
  onDelete: () => void;
  type: CodeEditorType;
  onClose: () => void;
}

const DeleteCodeModal = ({ isOpen, entityId, type, onClose, onDelete }: DeleteCodeModalProps) => {
  const { toastSuccess, toastError } = useToastWrapper();

  const supabase = useSupabaseTypedClient();
  const { id: userId } = useCurrentUser();

  const queryClient = useQueryClient();

  const mutation = useMutation<string, string, DeleteCodeParams>({
    mutationFn: supabaseMutationFn(({ entityId, type }) =>
      supabase.functions.invoke<string>(EdgeFunctionName.DeleteCode, {
        body: { entityId, type } as DeleteCodeParams,
      })
    ),
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries({ queryKey: [TanstackQueryName.GetCurrentUser, userId] }),
      ]);

      toastSuccess('Code deleted');

      onDelete();
      onClose();
    },
    onError: () => {
      toastError(`Can't delete code`);
    },
  });

  const isNonExpType = type !== 'code-frame' && type !== 'prototype';

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={() => mutation.mutate({ entityId, type })}
      header={
        <HStack>
          <Icon type="DeleteModal" />
          <Text fontSize="xLarge" fontWeight="semibold">
            Delete Code
          </Text>
        </HStack>
      }
      footer={
        <ButtonGroup>
          <Button onClick={onClose} scheme="ghost">
            <Text fontWeight="semibold" opacity="secondary">
              Cancel
            </Text>
          </Button>
          <ProtectedElement type="submit" scheme="danger" isLoading={mutation.isPending}>
            Delete
          </ProtectedElement>
        </ButtonGroup>
      }
    >
      <Stack spacing={5}>
        {isNonExpType && (
          <Text fontWeight="regular">
            Deleting this code will result in the loss of gained XP for it.
          </Text>
        )}
        {isNonExpType && (
          <Text fontWeight="regular">
            Unlocked achievements will be retained but not granted again.
          </Text>
        )}
        <Text fontWeight="regular">
          All received likes, comments, and related entities (i.e. generated report) for this code
          will be deleted.
        </Text>
      </Stack>
    </Modal>
  );
};

export default DeleteCodeModal;
