import { Center, useTheme } from '@chakra-ui/react';

import { useAuth } from 'auth/AuthProvider';
import { useCurrentUser } from 'auth/CurrentUserProvider';
import { Plan_Type_Enum } from 'types/databaseEnums';
import { ERoute } from 'types/frontend';

import Link from './Link';
import Text from './Text';

type TBadgeSize = 'small' | 'default';

interface IBigBadgeProps {
  plan: Plan_Type_Enum;
  showOnlySubscribed?: boolean;
  size?: TBadgeSize;
  display?: 'block' | 'inline-block';
  stopPropagation?: boolean;
}

type BadgeType = 'monthly' | 'yearly' | 'lifetime' | 'free' | 'terminated' | 'super';

const mapBadgeToBackground: Record<BadgeType, string> = {
  monthly: 'linear-gradient(180deg, #F6AD55 0%, #ED8936 100%)',
  yearly: 'linear-gradient(180deg, #B794F4 0%, #9F7AEA 100%)',
  lifetime: 'linear-gradient(180deg, #3FE8FF 0%, #D228D2 100%)',
  free: 'linear-gradient(180deg, #68D391 0%, #48BB78 100%)',
  terminated: 'rgba(252, 129, 129, 0.36);',
  super: 'yellow.400',
};

const mapBadgeToText: Record<BadgeType, string> = {
  monthly: 'BIG',
  yearly: 'BIG',
  lifetime: 'BIG',
  free: 'FREE',
  terminated: 'TERMINATED',
  super: 'SUPER',
};

const PLAN_BADGE_TYPES = {
  [Plan_Type_Enum.Free]: 'free',
  [Plan_Type_Enum.Monthly]: 'monthly',
  [Plan_Type_Enum.Yearly]: 'yearly',
  [Plan_Type_Enum.Lifetime]: 'lifetime',
};

export const BigBadge = ({
  plan,
  showOnlySubscribed = false,
  size = 'default',
  display = 'block',
  stopPropagation = false,
}: IBigBadgeProps) => {
  const { plan: myPlan } = useCurrentUser();
  const { borderRadius } = useTheme();
  const { isTerminated, isSuperUser } = useCurrentUser();
  const { userId } = useAuth();

  const getBadgeType = () => {
    if (isTerminated) {
      return 'terminated';
    }

    if (isSuperUser) {
      return 'super';
    }

    return PLAN_BADGE_TYPES[plan];
  };

  const badgeType = getBadgeType();

  if (showOnlySubscribed && plan === Plan_Type_Enum.Free) {
    return null;
  }

  return (
    <Link
      onClick={(e) => {
        if (!stopPropagation) {
          e.stopPropagation();
        }
      }}
      to={myPlan === Plan_Type_Enum.Free || !userId ? ERoute.Pricing : ERoute.Billing}
      _hover={{
        textDecoration: 'none',
        transform: 'scale(1.03) translateZ(0)',
        boxShadow: '0px 12px 24px rgba(0, 0, 0, 0.15)',
      }}
      transition="transform 0.1s ease-in-out"
      boxShadow="0px 10px 20px rgba(0, 0, 0, 0.15)"
      display={display}
    >
      <Center
        minW={size === 'default' ? '45px' : '37px'}
        minH={size === 'default' ? '32px' : '26px'}
        border="2px solid #171923"
        borderRadius={borderRadius}
        px={size === 'default' && isTerminated ? 1.5 : 0}
        bg={mapBadgeToBackground[badgeType]}
      >
        <Text fontWeight="semibold" fontSize={size === 'default' ? 'small' : 'micro'}>
          {mapBadgeToText[badgeType]}
        </Text>
      </Center>
    </Link>
  );
};
