import { RepeatIcon } from '@chakra-ui/icons';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Center,
  Container,
  HStack,
  IconButton,
  ListItem,
  UnorderedList,
  VStack,
  Spinner,
  useTheme,
  ButtonGroup,
  Select,
} from '@chakra-ui/react';
import useLongSpinnerText from 'hooks/useLongSpinnerText';
import { useState } from 'react';

import { ReactComponent as EmptyReportIllustration } from 'assets/illustrations/EmptyReport.svg';
import eliseSrc from 'assets/images/Elise.png';
import Button from 'components/Button';
import GradientTooltip from 'components/GradientTooltip';
import Icon from 'components/Icon';
import Modal from 'components/Modal';
import { ProtectedElement } from 'components/ProtectedElement';
import ScoreBadge from 'components/ScoreBadge';
import Text from 'components/Text';
import UserAvatar from 'components/UserAvatar';
import { SummaryReport } from 'types/edgeFunctions';

interface ISummaryTabProps {
  feedback?: string | null;
  codeSummary?: SummaryReport[] | null;
  codeScore?: number | null;
  designSummary?: SummaryReport[] | null;
  designScore?: number | null;
  isCodeOnly?: boolean;
  isOpen: boolean;
  isLoading: boolean;
  isMyReport: boolean;
  isDisabled?: boolean | null;
  isExternalSolution?: boolean | null;
  onOpen: () => void;
  onClose: () => void;
  onGenerate: () => void;
}

const SummaryTab = ({
  feedback,
  codeSummary,
  codeScore,
  designSummary,
  designScore,
  isCodeOnly,
  isMyReport,
  isOpen,
  isLoading,
  isDisabled,
  isExternalSolution,
  onOpen,
  onClose,
  onGenerate,
}: ISummaryTabProps) => {
  const [currentSummary, setCurrentSummary] = useState<'code' | 'design'>(
    isExternalSolution ? 'design' : 'code'
  );

  const text = useLongSpinnerText(isLoading);

  const { borderRadius } = useTheme();

  const isGenerated = isExternalSolution
    ? designSummary && designScore && designScore >= 0
    : codeSummary && codeScore && codeScore >= 0 && feedback;

  const report = currentSummary === 'code' ? codeSummary : designSummary;

  const renderReportText = () => {
    if (isCodeOnly) {
      return 'code';
    }

    if (isExternalSolution) {
      return 'design';
    }

    return 'code & design';
  };

  const reportText = isMyReport ? (
    <Text fontSize="xLarge" fontWeight="regular" textAlign="center">
      Get a comprehensive analysis of your {renderReportText()} <br /> and boost your skills with
      detailed reports.
    </Text>
  ) : (
    <Text fontSize="xLarge" fontWeight="regular" textAlign="center">
      The creator of this code did not generate a summary report yet.
    </Text>
  );

  return (
    <>
      <Container maxW="1400px" height="100%">
        <VStack alignItems="flex-start" spacing={4} width="100%" height="100%">
          <HStack pt={4} width="100%" justifyContent="space-between">
            <Text fontSize="xxLarge" fontWeight="bold">
              Summary
            </Text>
            <HStack spacing={4}>
              {!isExternalSolution && <ScoreBadge type="code" score={codeScore} />}
              {!isCodeOnly && <ScoreBadge type="design" score={designScore} />}
            </HStack>
          </HStack>
          {isExternalSolution && (
            <Text fontSize="small" fontWeight="regular" opacity="secondary">
              <strong>Note:</strong> External solution is limited to design summary as we <br /> do
              not review code outside of the built-in Code Editor.
            </Text>
          )}
          {isGenerated ? (
            <VStack width="100%" spacing={4}>
              {!isExternalSolution && (
                <VStack spacing={4}>
                  <Box borderRadius={borderRadius} backgroundColor="whiteAlpha.200" p={4}>
                    <VStack alignItems="flex-start" spacing={5}>
                      <HStack spacing={4} width="fit-content" alignItems="center">
                        <UserAvatar size="sm" name="Elise" src={eliseSrc} border="none" />
                        <Text fontSize="large" maxW="200px" isTruncated>
                          AI Buddy
                        </Text>
                      </HStack>
                      <Text fontWeight="regular">{feedback}</Text>
                    </VStack>
                  </Box>
                </VStack>
              )}
              <Box backgroundColor="whiteAlpha.200" width="100%" p={4} borderRadius={borderRadius}>
                <HStack width="100%" justifyContent="space-between">
                  <Box>
                    <Text fontSize="xLarge" fontWeight="semibold">
                      Report
                    </Text>
                    <Text opacity="secondary" fontWeight="regular" pt={2}>
                      A detailed summary report of {currentSummary === 'code' ? 'code' : 'design'}.
                    </Text>
                  </Box>
                  <HStack>
                    {isMyReport && (
                      <GradientTooltip
                        label={isLoading ? 'Summary is generating...' : 'Regenerate summary report'}
                      >
                        <IconButton
                          aria-label="Regenerate summary"
                          isLoading={isLoading}
                          onClick={onOpen}
                        >
                          <RepeatIcon />
                        </IconButton>
                      </GradientTooltip>
                    )}
                    {!isCodeOnly && !isExternalSolution && (
                      <Select
                        defaultValue="code"
                        border="1px solid white"
                        fontWeight="semibold"
                        width="fit-content"
                        _hover={{
                          cursor: 'pointer',
                          backgroundColor: 'whiteAlpha.50',
                          border: '1px solid white',
                        }}
                        onChange={(e) => {
                          setCurrentSummary(e.target.value as 'code' | 'design');
                        }}
                      >
                        <option value="code">
                          <Text fontWeight="semibold">Code Report</Text>
                        </option>
                        <option value="design">
                          <Text fontWeight="semibold">Design Report</Text>
                        </option>
                      </Select>
                    )}
                  </HStack>
                </HStack>
                <Accordion allowMultiple width="100%">
                  {report!.map(({ factor, content, score }) => (
                    <AccordionItem
                      mt={6}
                      key={factor}
                      border="none"
                      backgroundColor="whiteAlpha.50"
                      borderRadius={borderRadius}
                    >
                      <>
                        <h2>
                          <AccordionButton
                            px={4}
                            py={5}
                            backgroundColor="whiteAlpha.50"
                            _hover={{ backgroundColor: 'whiteAlpha.100' }}
                            borderRadius={borderRadius}
                          >
                            <Box as="span" flex="1" textAlign="left">
                              <HStack spacing={4}>
                                <Text fontSize="large">{factor}</Text>
                                <GradientTooltip label={`Section Score: ${score}/20`}>
                                  <div>
                                    <Box
                                      backgroundColor="purple.400"
                                      borderRadius={borderRadius}
                                      p={1}
                                    >
                                      <Text fontSize="small" color="black">
                                        {score}/20
                                      </Text>
                                    </Box>
                                  </div>
                                </GradientTooltip>
                              </HStack>
                            </Box>
                            <AccordionIcon />
                          </AccordionButton>
                        </h2>
                        <AccordionPanel pt={4}>
                          <UnorderedList spacing={3}>
                            {content.map((item) => {
                              return (
                                <ListItem key={item}>
                                  <Text fontWeight="regular">{item}</Text>
                                </ListItem>
                              );
                            })}
                          </UnorderedList>
                        </AccordionPanel>
                      </>
                    </AccordionItem>
                  ))}
                </Accordion>
              </Box>
            </VStack>
          ) : (
            <Center width="100%" height="80%">
              <VStack spacing={4}>
                <EmptyReportIllustration />
                {isLoading ? (
                  <Text fontSize="xLarge" fontWeight="regular" textAlign="center">
                    Summary generating...
                  </Text>
                ) : (
                  reportText
                )}
                {isMyReport && (
                  <VStack>
                    <GradientTooltip label={isDisabled ? 'Save code to generate a report' : null}>
                      <span>
                        <ProtectedElement
                          requiredTokens={1}
                          scheme="gradient"
                          isLoading={isLoading}
                          isDisabled={Boolean(isDisabled)}
                          onClick={onGenerate}
                        >
                          Generate Summary
                        </ProtectedElement>
                      </span>
                    </GradientTooltip>
                    {text && isLoading && (
                      <Text fontWeight="regular" fontStyle="italic" opacity="secondary">
                        {text}
                      </Text>
                    )}
                  </VStack>
                )}
              </VStack>
            </Center>
          )}
        </VStack>
      </Container>
      <GenerateSummaryReportModal
        isOpen={isOpen}
        isLoading={isLoading}
        onClose={onClose}
        onGenerate={onGenerate}
      />
    </>
  );
};

interface GenerateSummaryReportModalProps {
  isOpen: boolean;
  isLoading: boolean;
  onClose: () => void;
  onGenerate: () => void;
}

const GenerateSummaryReportModal = ({
  isOpen,
  isLoading,
  onClose,
  onGenerate,
}: GenerateSummaryReportModalProps) => {
  const text = useLongSpinnerText(isLoading);

  const renderContent = () => {
    if (isLoading) {
      return (
        <VStack py={6}>
          <Spinner size="lg" />
          <Text fontWeight="regular">Regenerating summary, please wait...</Text>
          {text && isLoading && (
            <Text fontWeight="regular" fontStyle="italic" opacity="secondary">
              {text}
            </Text>
          )}
        </VStack>
      );
    }

    return (
      <>
        <Text pb={3} fontWeight="regular">
          Are you sure you want to regenerate a summary for this code?
        </Text>
        <Text fontWeight="regular" textDecoration="underline" pb={3}>
          Your current summary report will be replaced with a new one.
        </Text>
      </>
    );
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={onGenerate}
      size="xl"
      header={
        <HStack>
          <Icon type="Summary" />
          <Text fontSize="xLarge" fontWeight="semibold">
            Regenerate Summary Report
          </Text>
        </HStack>
      }
      footer={
        <ButtonGroup>
          <Button onClick={onClose} scheme="ghost">
            <Text fontWeight="semibold" opacity="secondary">
              Cancel
            </Text>
          </Button>
          <ProtectedElement
            type="submit"
            scheme="gradient"
            isDisabled={isLoading}
            requiredTokens={1}
          >
            Regenerate Summary
          </ProtectedElement>
        </ButtonGroup>
      }
    >
      {renderContent()}
    </Modal>
  );
};

export default SummaryTab;
