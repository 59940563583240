import { FormControl, FormHelperText, Input, useDisclosure } from '@chakra-ui/react';
import { captureException } from '@sentry/react';
import { useSupabaseTypedClient } from 'hooks/reactQuery';
import { useToastWrapper } from 'hooks/useToastWrapper';
import { useState } from 'react';

import Button, { IButtonProps } from 'components/Button';
import Icon from 'components/Icon';
import Modal from 'components/Modal';
import { GITHUB_COOKIE_KEY, GITHUB_EMULATOR_TOKEN } from 'config/constants/github';
import { EdgeFunctionName, ProtectorParams } from 'types/edgeFunctions';
import { setCookie } from 'utils/cookies';
import { isLocalEnvironment, isProductionEnvironment } from 'utils/env';

export const useSignIn = () => {
  const [value, setValue] = useState('');
  const [loading, setLoading] = useState(false);

  const supabase = useSupabaseTypedClient();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const { toastError } = useToastWrapper();

  const handleChange = (event) => setValue(event.target.value);

  const signInWithGithub = async () => {
    try {
      if (isLocalEnvironment()) {
        setCookie(GITHUB_COOKIE_KEY, GITHUB_EMULATOR_TOKEN);
        await supabase.auth.signUp({
          email: `local-email+${Math.floor(Math.random() * 9999999)}@bigdevsoon.me`,
          password: '123456',
        });

        return;
      }

      await supabase.auth.signInWithOAuth({
        provider: 'github',
        options: {
          scopes: 'user:email public_repo',
        },
      });
    } catch (error) {
      captureException(error);
    }
  };

  const handleLoginClick = async () => {
    const showPasswordProtector = !isLocalEnvironment() && !isProductionEnvironment();

    if (showPasswordProtector) {
      onOpen();

      return;
    }

    await signInWithGithub();
  };

  const handleOnConfirm = async () => {
    try {
      setLoading(true);

      const { data, error } = await supabase.functions.invoke<boolean>(EdgeFunctionName.Protector, {
        body: { pass: value } as ProtectorParams,
      });

      if (error) throw error;

      if (data) {
        await signInWithGithub();
      } else {
        toastError(`Provided password is not correct`);
      }
    } catch (error) {
      toastError(`Error while trying to sign in`);
    } finally {
      setLoading(false);
    }
  };

  return {
    isOpen,
    onClose,
    handleLoginClick,
    handleOnConfirm,
    handleChange,
    value,
    loading,
  };
};

interface IGitHubButtonProps extends IButtonProps {
  text?: string;
  isFullWidth?: boolean;
}

const GitHubButton = ({
  text = 'Sign in with GitHub',
  isFullWidth = false,
  ...props
}: IGitHubButtonProps) => {
  const { isOpen, onClose, handleOnConfirm, handleLoginClick, handleChange, value, loading } =
    useSignIn();

  return (
    <>
      <Button
        onClick={handleLoginClick}
        scheme="outline"
        leftIcon={<Icon type="Github" />}
        fullWidth={isFullWidth}
        {...props}
      >
        {text}
      </Button>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        onSubmit={handleOnConfirm}
        header="Please provide password"
        footer={
          <>
            <Button scheme="ghost" onClick={onClose}>
              Close
            </Button>
            <Button isDisabled={!value} isLoading={loading} type="submit">
              Confirm
            </Button>
          </>
        }
      >
        <FormControl>
          <Input
            type="password"
            placeholder="Enter password"
            maxLength={100}
            autoFocus
            value={value}
            onChange={handleChange}
          />
          <FormHelperText>You should have received password as an Alpha Tester.</FormHelperText>
        </FormControl>
      </Modal>
    </>
  );
};

export default GitHubButton;
