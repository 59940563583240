import { CodeEditorType } from 'types/edgeFunctions';

export const identifyRepositoryUrl = (url: string) => {
  const githubRegex = /^https:\/\/github\.com\/(.+)$/i;
  const replitRegex = /^https:\/\/replit\.com\/(.+)$/i;
  const glitchRegex = /^https:\/\/glitch\.com\/edit\/#!/i;

  if (githubRegex.test(url)) {
    return true;
  } else if (replitRegex.test(url)) {
    return true;
  } else if (glitchRegex.test(url)) {
    return true;
  } else {
    return false;
  }
};

export const identifyLivePreviewURL = (url: string) => {
  return [
    'glitch.me',
    'repl.co',
    'vercel.app',
    'netlify.app',
    'web.app',
    'firebaseapp.com',
    'amplifyapp.com',
    'github.io',
    'pages.dev',
  ].some((codeRepository) => (url?.toLowerCase() || '').includes(codeRepository));
};

interface IDetermineSolutionFlagsParams {
  solution_url: string;
  repository_url: string;
  preview_url: string;
  type: CodeEditorType;
}

export const determineSolutionFlags = ({
  solution_url,
  repository_url,
  preview_url,
  type,
}: IDetermineSolutionFlagsParams) => {
  const areNewLinks =
    solution_url.startsWith(`/${type}s`) &&
    repository_url.startsWith(`/${type}s`) &&
    preview_url.startsWith(`/${type}s`);

  const isExternalSolution =
    !areNewLinks && (identifyRepositoryUrl(repository_url) || identifyLivePreviewURL(preview_url));

  return {
    isExternalSolution: type === 'problem' ? false : isExternalSolution,
  };
};
