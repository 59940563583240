import { Box, HStack } from '@chakra-ui/react';
import { useToastWrapper } from 'hooks/useToastWrapper';
import { RefObject, useRef, useState } from 'react';
import Cropper from 'react-easy-crop';

import Button from 'components/Button';
import Modal from 'components/Modal';
import UserAvatar from 'components/UserAvatar';

import { getCroppedImg } from './crop-utils';

// 256 kB
const FILE_MAX_SIZE = 256000;

interface IUploadPhotoProps {
  isLoading?: boolean;
  setIsLoading?: (isLoading: boolean) => void;
  photoUrl?: string | null;
  onSubmit: (photo: Blob) => void;
}

const UploadPhoto = ({ isLoading, setIsLoading, photoUrl, onSubmit }: IUploadPhotoProps) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [photoSrc, setPhotoSrc] = useState('');

  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const hiddenPhotoInput: RefObject<HTMLInputElement> = useRef(null);

  const { toastError } = useToastWrapper();

  const handlePhotoUpload = (e) => {
    e.stopPropagation();
    hiddenPhotoInput.current?.click();
  };

  const handlePhotoChange = async (event) => {
    const target = event.target as HTMLInputElement;
    const files = target.files as FileList;

    if (!files?.[0]) {
      return;
    }

    setPhotoSrc(URL.createObjectURL(files[0]));
  };

  const handleCropModalClose = () => {
    setPhotoSrc('');
  };

  const handleCropComplete = async (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const handleSubmit = async () => {
    const croppedPhoto = (await getCroppedImg(photoSrc, croppedAreaPixels)) as Blob;

    if (croppedPhoto.size > FILE_MAX_SIZE) {
      setIsLoading?.(false);
      return toastError(
        `Photo size: ${Math.round(
          croppedPhoto.size / 1000
        )} kB, max size: 256 kB - try to zoom in to crop more or choose another photo`
      );
    }

    await onSubmit(croppedPhoto);

    handleCropModalClose();
  };

  const avatarSrc = photoUrl || '';

  return (
    <>
      <Modal
        isOpen={Boolean(photoSrc)}
        onClose={handleCropModalClose}
        onSubmit={handleSubmit}
        header="Crop photo"
        footer={
          <>
            <Button onClick={handleCropModalClose} scheme="ghost">
              Close
            </Button>
            <Button type="submit">Upload photo</Button>
          </>
        }
      >
        <Box position="relative" height="480px" width="90%" margin="0 auto">
          <Cropper
            image={photoSrc}
            crop={crop}
            zoom={zoom}
            aspect={1}
            cropShape="round"
            onCropChange={setCrop}
            onCropComplete={handleCropComplete}
            onZoomChange={setZoom}
          />
        </Box>
      </Modal>
      <HStack
        spacing={6}
        role="group"
        onClick={handlePhotoUpload}
        _hover={{ opacity: 0.9 }}
        transition="all 0.2s ease-out"
        pointerEvents={isLoading ? 'none' : 'initial'}
        cursor={isLoading ? 'default' : 'pointer'}
        width="fit-content"
      >
        <UserAvatar width="64px" height="64px" src={avatarSrc} onClick={handlePhotoUpload} />
        <Button scheme="outline" onClick={handlePhotoUpload} isLoading={isLoading}>
          Upload new photo
        </Button>
        <input
          type="file"
          accept="image/*"
          ref={hiddenPhotoInput}
          onClick={() => {
            if (hiddenPhotoInput?.current) {
              // @ts-ignore
              hiddenPhotoInput.current.value = null;
            }
          }}
          onChange={handlePhotoChange}
          style={{ display: 'none' }}
        />
      </HStack>
    </>
  );
};

export default UploadPhoto;
