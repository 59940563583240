import { HStack, useDisclosure, VStack } from '@chakra-ui/react';

import { useCurrentUser } from 'auth/CurrentUserProvider';
import SettingsTile from 'components/Billing/SettingsTile';
import { ConnectedApp } from 'components/ConnectedApp';
import { DiscordAttachModal } from 'components/Modal/DiscordAttachModal';
import { DiscordDetachModal } from 'components/Modal/DiscordDetachModal';
import { GitHubAttachModal } from 'components/Modal/GitHubAttachModal';
import { GitHubDetachModal } from 'components/Modal/GitHubDetachModal';

export const ConnectedAppsSettings = () => {
  const { hasDiscordRegistered, isGithubConnected } = useCurrentUser();

  const {
    isOpen: isDisconnectGitHubOpen,
    onOpen: onDisconnectGitHubOpen,
    onClose: onDisconnectGitHubClose,
  } = useDisclosure();

  const {
    isOpen: isConnectGitHubOpen,
    onOpen: onConnectGitHubOpen,
    onClose: onConnectGitHubClose,
  } = useDisclosure();

  const {
    isOpen: isDisconnectDiscordOpen,
    onOpen: onDisconnectDiscordOpen,
    onClose: onDisconnectDiscordClose,
  } = useDisclosure();

  const {
    isOpen: isConnectDiscordOpen,
    onOpen: onConnectDiscordOpen,
    onClose: onConnectDiscordClose,
  } = useDisclosure();

  return (
    <>
      <SettingsTile title="Connected apps" subtitle="Apps that you granted access to BigDevSoon.">
        <VStack align="start" spacing={6}>
          <HStack spacing={6} w="100%">
            <ConnectedApp
              isActive={isGithubConnected}
              text="GitHub"
              iconType="Github"
              onAttach={onConnectGitHubOpen}
              onDetach={onDisconnectGitHubOpen}
            />
            <ConnectedApp
              isActive={hasDiscordRegistered}
              text="Discord"
              iconType="Discord"
              onAttach={onConnectDiscordOpen}
              onDetach={onDisconnectDiscordOpen}
            />
          </HStack>
        </VStack>
      </SettingsTile>
      <GitHubDetachModal isOpen={isDisconnectGitHubOpen} onClose={onDisconnectGitHubClose} />
      <GitHubAttachModal isOpen={isConnectGitHubOpen} onClose={onConnectGitHubClose} />
      <DiscordDetachModal isOpen={isDisconnectDiscordOpen} onClose={onDisconnectDiscordClose} />
      <DiscordAttachModal isOpen={isConnectDiscordOpen} onClose={onConnectDiscordClose} />
    </>
  );
};
