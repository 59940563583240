import { useMutation, useQueryClient } from '@tanstack/react-query';
import { supabaseMutationFn, useSupabaseTypedClient } from 'hooks/reactQuery';
import { useExitAlert } from 'hooks/useExitAlert';
import { useToastWrapper } from 'hooks/useToastWrapper';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useCurrentUser } from 'auth/CurrentUserProvider';
import DevSandboxSkeleton from 'components/DevSandbox/DevSandboxSkeleton';
import { EdgeFunctionName, SubmitCodeParams, SubmitCodeResponse } from 'types/edgeFunctions';
import { ERoute, TanstackQueryName } from 'types/frontend';

const NewPrototype = () => {
  const supabase = useSupabaseTypedClient();
  const queryClient = useQueryClient();

  const navigate = useNavigate();
  const { state } = useLocation();

  const { id: currentUserId } = useCurrentUser();

  const { toastError } = useToastWrapper();

  const mutation = useMutation<SubmitCodeResponse, string, SubmitCodeParams>({
    mutationFn: supabaseMutationFn((params) =>
      supabase.functions.invoke<SubmitCodeResponse>(EdgeFunctionName.SubmitCode, {
        body: params as SubmitCodeParams,
      })
    ),
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: [TanstackQueryName.GetCurrentUser, currentUserId],
      });
      queryClient.invalidateQueries({ queryKey: [TanstackQueryName.GetPrototypes, currentUserId] });
      queryClient.invalidateQueries({ queryKey: [TanstackQueryName.GetPrototypes, null] });

      navigate(data.solution_url, { replace: true });
    },
    onError: () => {
      toastError('Could not create prototype, try again later.');
      navigate(ERoute.Prototypes);
    },
  });

  useEffect(() => {
    if (!state?.shouldCreate) {
      navigate(ERoute.Prototypes);

      return;
    }

    const isSubmittingPrototype = sessionStorage.getItem('isSubmittingPrototype');

    const hasNoPromptProperties = !state?.text && !state?.url && !state?.screenshot;

    if (isSubmittingPrototype === 'true' || hasNoPromptProperties) {
      navigate(ERoute.Prototypes);

      return;
    }

    sessionStorage.setItem('isSubmittingPrototype', 'true');

    mutation.mutate({
      type: 'prototype',
      entityScreenName: '',
      title: '',
      html: '',
      css: '',
      js: '',
      feedback: '',
      repository_url: '',
      live_preview_url: '',
      iframe_screenshot: '',
      prompt_image_url: state?.screenshot || '',
      prompt_text: state?.text || '',
      prompt_url: state?.url || '',
      is_private: state?.isPrivate || false,
    });
  }, []);

  useExitAlert({ shouldShowBrowserExitAlert: mutation.isPending });

  return (
    <DevSandboxSkeleton
      loadingText="Creating prototype..."
      pageLoaderText="Please wait (do not refresh the browser)"
    />
  );
};

export default NewPrototype;
