import '@fontsource/poppins/400.css'; // regular
import '@fontsource/poppins/500.css'; // medium
import '@fontsource/poppins/600.css'; // semibold
import '@fontsource/poppins/700.css'; // bold

import { Box, ChakraProvider, Image } from '@chakra-ui/react';
import * as Sentry from '@sentry/react';
import { createClient as createSupabaseClient } from '@supabase/supabase-js';
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister';
import { QueryClient } from '@tanstack/react-query';
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider as SupabaseProvider } from 'react-supabase';
import Smartlook from 'smartlook-client';

import pageErrorSrc from 'assets/images/PageError.png';
import { AuthProvider, useAuth } from 'auth/AuthProvider';
import Button from 'components/Button';
import { useSignIn } from 'components/GitHubButton';
import Text from 'components/Text';
import { isLocalEnvironment, isProductionEnvironment } from 'utils/env';

import MaintenanceMode from './MaintenanceMode';
import { Routes } from './Routes';
import { MediaQueriesProvider } from './layout/MediaQueriesProvider';
import reportWebVitals from './reportWebVitals';
import { theme } from './theme';
import { Database } from './types/supabase';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const isLocal = isLocalEnvironment();
const isProduction = isProductionEnvironment();

const supabaseClient = createSupabaseClient<Database>(
  process.env.REACT_APP_SUPABASE_URL,
  process.env.REACT_APP_SUPABASE_ANON_KEY
);

if (!isLocal) {
  Sentry.init({
    dsn: 'https://08c5e9e772f14b3f8a7aa68ca873aed4@o4505081575636992.ingest.sentry.io/4505081580290048',
    integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
    // Performance Monitoring
    tracesSampleRate: 0.2,
    // Session Replay
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 1.0,
    environment: isProduction ? 'production' : 'dev',
    beforeSend(event) {
      // Remove specific cookies from the event payload in Sentry
      if (event.request?.headers?.Cookie) {
        delete event.request.headers.Cookie;
      }
      return event;
    },
  });
}

if (typeof window !== 'undefined' && isProduction) {
  Smartlook.init('8a7f9b2986519d6a08ec04937056b8bd591d031b', {
    cookies: false,
  });
}

const queryClient = new QueryClient({
  defaultOptions: { queries: { retry: 1, staleTime: 0, gcTime: 1000 * 60 * 30 } },
});

const persister = createSyncStoragePersister({
  storage: window.localStorage,
});

const FallbackComponent = () => {
  const { signOut, loading } = useAuth();
  const { handleLoginClick } = useSignIn();

  return (
    <>
      <Box
        position="absolute"
        top="50%"
        transform="translateY(-50%)"
        width="100%"
        textAlign="center"
        zIndex={999}
      >
        <Text fontSize="xxxxLarge" mb={8}>
          Something went wrong
        </Text>
        <Button
          onClick={async () => {
            await signOut();
            handleLoginClick();
          }}
          isLoading={loading}
        >
          Reload application
        </Button>
      </Box>
      <Image src={pageErrorSrc} alt="Page error" width="100vw" height="100vh" objectFit="cover" />
    </>
  );
};

root.render(
  <ChakraProvider theme={theme}>
    <MediaQueriesProvider>
      <BrowserRouter>
        <SupabaseProvider value={supabaseClient}>
          <PersistQueryClientProvider client={queryClient} persistOptions={{ persister }}>
            <AuthProvider>
              <Sentry.ErrorBoundary fallback={<FallbackComponent />} showDialog>
                {/* <ReactQueryDevtools
                initialIsOpen={process.env.REACT_APP_REACT_QUERY_DEV_TOOLS === 'true'}
              /> */}
                <MaintenanceMode>
                  <Routes />
                </MaintenanceMode>
              </Sentry.ErrorBoundary>
            </AuthProvider>
          </PersistQueryClientProvider>
        </SupabaseProvider>
      </BrowserRouter>
    </MediaQueriesProvider>
  </ChakraProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
