import { SupabaseClient } from '@supabase/supabase-js';
import { useQueryWithErrorBoundary, useSupabaseTypedClient } from 'hooks/reactQuery';

import { OtherTokenReward } from 'types/customEnums';
import { Plan_Type_Enum } from 'types/databaseEnums';
import { CurrentUser, Limits, MetadataKey } from 'types/edgeFunctions';
import { TanstackQueryName } from 'types/frontend';
import { Database } from 'types/supabase';

const REFERRAL_CAP = 5;

const desiredMetadataKeys = [MetadataKey.CHAT_MESSAGE_LIMIT] as string[];

const rechargeTokens: Record<Plan_Type_Enum, number> = {
  [Plan_Type_Enum.Free]: 3,
  [Plan_Type_Enum.Monthly]: 10,
  [Plan_Type_Enum.Yearly]: 20,
  [Plan_Type_Enum.Lifetime]: 30,
};

interface IGetCurrentUserQueryFnParams {
  supabase: SupabaseClient<Database>;
  userId: string;
}

export const getCurrentUserQueryFn = async ({
  supabase,
  userId,
}: IGetCurrentUserQueryFnParams): Promise<CurrentUser> => {
  const [
    { data, error: userError },
    metadata,
    userTokens,
    userExperience,
    { count, error },
    { count: codeFramesCount, error: codeFramesCountError },
  ] = await Promise.all([
    supabase
      .from('user')
      .select(
        'id, name, screen_name, created_at, github_access_token, has_early_bird_badge, feature_flag_on, plan, public_email, private_email, avatar_url, github_url, discord_user_id, referral_code, is_terminated, has_full_access, chat_message_counter, chat_message_recycle_at'
      )
      .eq('id', userId)
      .single(),
    supabase.from('metadata').select('key, value'),
    supabase
      .from('user_token')
      .select('tokens_available, extra_tokens, recycle_at')
      .eq('id', userId)
      .single(),
    supabase.rpc('get_total_experience', { p_user_id: userId }),
    supabase
      .from('user_action')
      .select('*', { count: 'exact', head: true })
      .eq('user_id', userId)
      .eq('token_reward_id', OtherTokenReward.Referral),
    supabase
      .from('user_code_frame')
      .select('*', { count: 'exact', head: true })
      .eq('user_id', userId),
  ]);

  if (userError) throw userError;
  if (metadata.error) throw metadata.error;
  if (userTokens.error) throw userTokens.error;
  if (userExperience.error) throw userExperience.error;
  if (error) throw error;
  if (codeFramesCountError) throw codeFramesCountError;

  const filteredMetadata = metadata.data.filter(({ key }) => desiredMetadataKeys.includes(key));
  const limits = filteredMetadata.reduce((obj: { [key: string]: number }, item) => {
    const key = item.key
      .split('_')
      .map((word: any, index: any) => (index === 0 ? word : word[0].toUpperCase() + word.slice(1)))
      .join('');
    obj[key] = Number(item.value);

    return obj;
  }, {}) as Limits;

  const user = data!;

  const tokensAvailable = userTokens.data?.tokens_available || 0;
  const extraTokens = userTokens.data?.extra_tokens || 0;

  return {
    id: user.id,
    name: user.name,
    codeFramesCount: codeFramesCount || 0,
    screenName: user.screen_name || '',
    publicEmail: user.public_email || '',
    privateEmail: user.private_email,
    avatarUrl: user.avatar_url || '',
    githubUrl: user.github_url,
    createdAt: user.created_at || '',
    isGithubConnected: Boolean(user.github_access_token),
    tokensAvailable,
    extraTokens,
    bigTokensCollected: tokensAvailable + extraTokens,
    rechargeTokens: rechargeTokens[user.plan as Plan_Type_Enum],
    experience: userExperience.data,
    isTerminated: Boolean(user.is_terminated),
    isFree: user.plan === Plan_Type_Enum.Free,
    isMonthlySubscriber: user.plan === Plan_Type_Enum.Monthly,
    isYearlySubscriber: user.plan === Plan_Type_Enum.Yearly,
    isLifetimeUser: user.plan === Plan_Type_Enum.Lifetime,
    isSuperUser: Boolean(user.has_full_access),
    hasDiscordRegistered: user.discord_user_id !== null,
    hasExhaustedReferralLink: (count || 0) >= REFERRAL_CAP,
    referralCode: user.referral_code || '',
    ...limits,
    plan: user.plan as Plan_Type_Enum,
    nextTokenRecycleTime: new Date(userTokens.data?.recycle_at || ''),
    chatMessageCounter: user.chat_message_counter || 0,
    nextChatMessageRecycleTime: user.chat_message_recycle_at
      ? new Date(user.chat_message_recycle_at)
      : null,
    githubAccessToken: user.github_access_token || '',
  };
};

interface IUseGetCurrentUserResponse {
  data: CurrentUser | undefined | null;
  isPending: boolean;
}

interface IUseGetcurrentUserQueryParams {
  userId?: string;
}

export const useGetCurrentUserQuery = ({
  userId,
}: IUseGetcurrentUserQueryParams): IUseGetCurrentUserResponse => {
  const supabase = useSupabaseTypedClient();

  const { data, isPending } = useQueryWithErrorBoundary({
    queryKey: [TanstackQueryName.GetCurrentUser, userId],
    queryFn: () =>
      getCurrentUserQueryFn({
        supabase,
        userId: userId || '',
      }),
    enabled: Boolean(userId),
  });

  if (!data) {
    return {
      data: null,
      isPending,
    };
  }

  return {
    data,
    isPending,
  };
};
