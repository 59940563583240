import { ButtonGroup, HStack } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

import Button from 'components/Button';
import Icon from 'components/Icon';
import Modal from 'components/Modal';
import { ProtectedElement } from 'components/ProtectedElement';
import Text from 'components/Text';

interface CardsCompletedModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const CardsCompletedModal = ({ isOpen, onClose }: CardsCompletedModalProps) => {
  const navigate = useNavigate();

  const handleClose = () => {
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      onSubmit={() => {
        handleClose();
        navigate('submit');
      }}
      header={
        <HStack>
          <Icon type="CardsCompletedModal" size={48} />
          <Text fontSize="xLarge" fontWeight="semibold">
            All cards completed
          </Text>
        </HStack>
      }
      footer={
        <ButtonGroup>
          <Button onClick={handleClose} scheme="ghost">
            <Text fontWeight="semibold" opacity="secondary">
              Cancel
            </Text>
          </Button>

          <ProtectedElement
            type="submit"
            scheme="gradient"
            leftIcon={<Icon type="SubmitCode" size={20} />}
          >
            Submit
          </ProtectedElement>
        </ButtonGroup>
      }
    >
      <Text fontWeight="regular" pb={3}>
        Congratulations on completing all the cards, the next step is to submit a solution to the
        project! 🚀
      </Text>
    </Modal>
  );
};

export default CardsCompletedModal;
