import { Experience_Level_Enum } from 'types/databaseEnums';

export enum CreditReward {
  DiscordConnected = 1,
  Referral = 1,
  GenerateCodeSolution = -1,
  GeneratePrototype = -1,
  GenerateCodeSummary = -1,
}

export enum ProjectCompletedExperienceReward {
  Junior = 50,
  Regular = 100,
  Senior = 150,
}

export enum CardExperienceReward {
  Junior = 5,
  Regular = 10,
  Senior = 15,
}

export enum ProblemExperienceReward {
  Junior = 5,
  Regular = 10,
  Senior = 15,
}

export const mapExpToCard: Record<Experience_Level_Enum, CardExperienceReward> = {
  junior: CardExperienceReward.Junior,
  regular: CardExperienceReward.Regular,
  senior: CardExperienceReward.Senior,
};

export const mapExpToProject: Record<Experience_Level_Enum, ProjectCompletedExperienceReward> = {
  junior: ProjectCompletedExperienceReward.Junior,
  regular: ProjectCompletedExperienceReward.Regular,
  senior: ProjectCompletedExperienceReward.Senior,
};

export const mapExpToProblem: Record<Experience_Level_Enum, ProblemExperienceReward> = {
  junior: ProblemExperienceReward.Junior,
  regular: ProblemExperienceReward.Regular,
  senior: ProblemExperienceReward.Senior,
};
