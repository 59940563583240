import { Box, ButtonGroup, HStack, Spinner, VStack } from '@chakra-ui/react';
import useLongSpinnerText from 'hooks/useLongSpinnerText';
import { useState } from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { dracula } from 'react-syntax-highlighter/dist/esm/styles/prism';

import Button from 'components/Button';
import Icon from 'components/Icon';
import Modal from 'components/Modal';
import { ProtectedElement } from 'components/ProtectedElement';
import Text from 'components/Text';

interface GenerateCodeSolutionModalProps {
  name: string;
  code: string;
  isOpen: boolean;
  isLoading: boolean;
  onClose: () => void;
  onGenerate: () => void;
  language: 'html' | 'css' | 'javascript';
}

const GenerateCodeSolutionModal = ({
  name,
  code,
  isOpen,
  isLoading,
  onClose,
  onGenerate,
  language,
}: GenerateCodeSolutionModalProps) => {
  const [isCopied, setIsCopied] = useState(false);

  const text = useLongSpinnerText(isLoading);

  const handleCopyToClipboard = (code: string) => {
    navigator.clipboard.writeText(code);
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 500);
  };

  const renderContent = () => {
    if (isLoading) {
      return (
        <VStack py={6}>
          <Spinner size="lg" />
          <Text fontWeight="regular">Please wait (do not refresh the browser)</Text>
          {text && (
            <Text fontWeight="regular" fontStyle="italic" opacity="secondary">
              {text}
            </Text>
          )}
        </VStack>
      );
    }

    if (!code) {
      return (
        <>
          <Text pb={3} fontWeight="regular">
            Are you sure you want to generate a solution for this {name}?
          </Text>
          <Text fontWeight="regular" textDecoration="underline" pb={3}>
            This will provide you with an AI-generated code solution that you can use, and adjust it
            as needed.
          </Text>
        </>
      );
    }

    return (
      <VStack alignItems="flex-start">
        <Box maxW="100%" maxH="70vh" overflow="auto" width="100%" position="relative">
          <Box position="absolute" right={4} top={6}>
            <Button
              onClick={() => handleCopyToClipboard(code)}
              aria-label="Copy code to clipboard"
              leftIcon={<Icon type="Copy" size={16} />}
              scheme="outline"
              size="sm"
              isDisabled={false}
            >
              <Text fontSize="small">{isCopied ? 'Copied!' : 'Copy code'}</Text>
            </Button>
          </Box>
          <SyntaxHighlighter language={language} style={dracula}>
            {code}
          </SyntaxHighlighter>
        </Box>
        <Text pb={3} fontWeight="regular">
          Your solution is available above, you can copy and paste it into the Code Editor.
        </Text>
        <Text textDecoration="underline" fontWeight="regular" pb={3}>
          This is an AI-generated solution and can have mistakes, you can modify it per your
          requirements.
        </Text>
      </VStack>
    );
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={onGenerate}
      size={code ? '4xl' : 'xl'}
      header={
        <HStack>
          <Icon type="GenerateCodeSolution" />
          <Text fontSize="xLarge" fontWeight="semibold">
            {code ? 'Your Code Solution' : 'Generate Code Solution'}
          </Text>
        </HStack>
      }
      footer={
        <ButtonGroup>
          <Button onClick={onClose} scheme="ghost">
            <Text fontWeight="semibold" opacity="secondary">
              Cancel
            </Text>
          </Button>
          <ProtectedElement
            type="submit"
            scheme="gradient"
            isDisabled={isLoading}
            requiredTokens={1}
          >
            {code ? 'Regenerate Code' : 'Generate Code'}
          </ProtectedElement>
        </ButtonGroup>
      }
    >
      {renderContent()}
    </Modal>
  );
};

export default GenerateCodeSolutionModal;
