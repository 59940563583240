import { Flex, HStack, VStack } from '@chakra-ui/react';

import Icon from 'components/Icon';
import Link from 'components/Link';
import Text from 'components/Text';

export const Support = () => {
  return (
    <Flex
      bg="whiteAlpha.200"
      borderRadius={8}
      justifyContent="space-between"
      overflow="hidden"
      shadow="0px 4px 24px 0px rgba(0, 0, 0, 0.24)"
    >
      <HStack p={4}>
        <Icon type="Support" size={48} />
        <VStack align="flex-start" pl={1} spacing={1}>
          <Text fontSize="small" opacity="primary">
            Have any issues with payment?
          </Text>
          <Link href="mailto:support@bigdevsoon.me">Contact us</Link>
        </VStack>
      </HStack>
    </Flex>
  );
};
