import { SupabaseClient } from '@supabase/supabase-js';
import { isBefore } from 'date-fns';
import { useQueryWithErrorBoundary, useSupabaseTypedClient } from 'hooks/reactQuery';

import { Plan_Type_Enum } from 'types/databaseEnums';
import { MetadataKey, UserProfile } from 'types/edgeFunctions';
import { TanstackQueryName } from 'types/frontend';
import { Database } from 'types/supabase';

interface IGetUserProfileQueryFnParams {
  supabase: SupabaseClient<Database>;
  userId: string;
}

export const getUserProfileQueryFn = async ({ supabase, userId }: IGetUserProfileQueryFnParams) => {
  const [user, metadata, rank, likes] = await Promise.all([
    supabase
      .from('user_profile_view')
      .select(
        'id, created_at, avatar_url, bio, devto_url, github_url, goal, linkedin_url, location, name, plan, public_email, twitter_url, website_url, created_at, has_monthly_badge, has_yearly_badge, has_early_bird_badge, experience, has_lifetime_badge'
      )
      .eq('id', userId)
      .single(),
    supabase.from('metadata').select('value').eq('key', MetadataKey.BETA_END_DATE).single(),
    supabase.rpc('get_user_rank', { p_user_id: userId }),
    supabase.rpc('count_likes', { input_user_id: userId }),
  ]);

  if (user.error) throw user.error;
  if (metadata.error) throw metadata.error;
  if (rank.error) throw rank.error;
  if (likes.error) throw likes.error;

  const rankingPlace = rank.data < 1000 ? rank.data.toString() : '-';

  return {
    avatar_url: user.data.avatar_url || '',
    bio: user.data.bio || '',
    devto_url: user.data.devto_url || '',
    github_url: user.data.github_url || '',
    goal: user.data.goal || '',
    linkedin_url: user.data.linkedin_url || '',
    location: user.data.location || '',
    name: user.data.name || '',
    public_email: user.data.public_email || '',
    twitter_url: user.data.twitter_url || '',
    website_url: user.data.website_url || '',
    experience: user.data.experience || 0,
    rank: rankingPlace,
    likes: likes.data || 0,
    has_beta_badge: isBefore(new Date(user.data.created_at || ''), new Date(metadata.data?.value)),
    has_monthly_badge: Boolean(user.data.has_monthly_badge),
    has_yearly_badge: Boolean(user.data.has_yearly_badge),
    has_early_bird_badge: Boolean(user.data.has_early_bird_badge),
    has_lifetime_badge: Boolean(user.data.has_lifetime_badge),
    created_at: user.data.created_at || '',
    plan: user.data.plan as Plan_Type_Enum,
  };
};

interface IUseGetUserProfileResponse {
  data: UserProfile | undefined | null;
  isPending: boolean;
}

interface IUseGetUserProfileQueryParams {
  userId?: string | null;
}

export const useGetUserProfileQuery = ({
  userId,
}: IUseGetUserProfileQueryParams): IUseGetUserProfileResponse => {
  const supabase = useSupabaseTypedClient();

  const { data, isPending } = useQueryWithErrorBoundary({
    queryKey: [TanstackQueryName.GetUserProfile, userId],
    queryFn: () =>
      getUserProfileQueryFn({
        supabase,
        userId: userId as string,
      }),
    enabled: Boolean(userId),
  });

  if (!data) {
    return {
      data: null,
      isPending,
    };
  }

  return {
    data,
    isPending,
  };
};
