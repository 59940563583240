import { Wrap, Box } from '@chakra-ui/react';

import { skillName } from 'config/constants/skills';
import { ProjectTag } from 'types/edgeFunctions';

import Text from './Text';

interface TagsProps {
  tags?: ProjectTag[];
  size?: 'xs' | 'sm';
  max?: number;
}

const Tags = ({ tags = [], size = 'xs', max }: TagsProps) => {
  return (
    <Wrap spacing={2}>
      {tags.slice(0, max || Infinity).map(({ id, tag_type }) => (
        <Box key={id} borderRadius="48px" py={1} px={1.5} backgroundColor="whiteAlpha.300">
          <Text fontSize={size === 'xs' ? 'micro' : 'small'}>{skillName[tag_type]}</Text>
        </Box>
      ))}
    </Wrap>
  );
};

export default Tags;
