import {
  Box,
  Grid,
  IconButton,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  VStack,
  Stack,
  HStack,
  Divider,
  Center,
} from '@chakra-ui/react';
import { useSupabaseTypedClient } from 'hooks/reactQuery';
import { ReactNode, useEffect } from 'react';
import { NavLink } from 'react-router-dom';

import { useAuth } from 'auth/AuthProvider';
import { BigBadge } from 'components/BigBadge';
import GitHubButton from 'components/GitHubButton';
import GradientTooltip from 'components/GradientTooltip';
import Icon from 'components/Icon';
import Link from 'components/Link';
import Logo from 'components/Logo';
import { DiscordAttachModal } from 'components/Modal/DiscordAttachModal';
import Text from 'components/Text';
import { GITHUB_COOKIE_KEY } from 'config/constants/github';
import { Plan_Type_Enum } from 'types/databaseEnums';
import { EdgeFunctionName, UpdateGithubAccessTokenParams } from 'types/edgeFunctions';
import { ERoute } from 'types/frontend';
import { deleteCookie, getCookie } from 'utils/cookies';
import { openDiscord } from 'utils/discord';

import Banner from './Banner';
import { useMediaQueries } from './MediaQueriesProvider';
import Support from './Navigation/Support';
import UserBigtokens from './Navigation/UserBigtokens';
import UserDropdownMenu from './Navigation/UserDropdownMenu';
import UserNotifications from './Navigation/UserNotifications';

interface ILinkWrapperProps {
  isActive: boolean;
  isCollapsed: boolean;
  isLocked: boolean;
  children: ReactNode;
}

const LinkWrapper = ({ isCollapsed, isActive, isLocked, children }: ILinkWrapperProps) => {
  const isActiveProps =
    isCollapsed && isActive
      ? { bg: 'purple.400', borderRadius: 8 }
      : !isCollapsed && isActive
      ? { bg: 'purple.400', borderRadius: 8 }
      : {};

  return (
    <Box
      width="100%"
      opacity={isLocked ? 0.6 : isActive ? 1 : 0.87}
      _hover={{ opacity: isLocked ? 0.6 : 1 }}
      transition="all 0.2s ease-out"
      mb={isCollapsed ? 4 : 0}
      height={isCollapsed ? '32px' : 'initial'}
      pl={isCollapsed ? 1 : 2}
      pt={isCollapsed ? 1.5 : 0}
      lineHeight="32px"
      {...isActiveProps}
    >
      {children}
    </Box>
  );
};

interface INavigationLinkProps {
  children?: ReactNode;
  to?: ERoute | string;
  onClick?: () => void;
  isCollapsed?: boolean;
  isLocked?: boolean;
}

const NavigationLink = ({ to, children, isCollapsed, isLocked, onClick }: INavigationLinkProps) => {
  if (to) {
    return (
      <NavLink to={to} style={{ width: '100%', height: '32px' }}>
        {({ isActive }) => (
          <LinkWrapper isActive={isActive} isLocked={!!isLocked} isCollapsed={!!isCollapsed}>
            {children}
          </LinkWrapper>
        )}
      </NavLink>
    );
  }

  if (onClick) {
    return (
      <Box onClick={onClick} cursor="pointer" width={isCollapsed ? '32px' : '100%'}>
        <LinkWrapper isActive={false} isLocked={!!isLocked} isCollapsed={!!isCollapsed}>
          {children}
        </LinkWrapper>
      </Box>
    );
  }

  return (
    <LinkWrapper isActive={false} isLocked={!!isLocked} isCollapsed={!!isCollapsed}>
      <Box width={isCollapsed ? '32px' : '100%'}>{children}</Box>
    </LinkWrapper>
  );
};

interface SidebarItemProps {
  icon: ReactNode;
  title: string;
  isCollapsed: boolean;
  isLocked?: boolean;
  navigationProps: INavigationLinkProps;
  onClose?: () => void;
}

const SidebarItem = ({
  title,
  icon,
  isCollapsed,
  isLocked,
  navigationProps,
  onClose,
}: SidebarItemProps) => {
  return (
    <NavigationLink {...navigationProps} isLocked={isLocked} isCollapsed={isCollapsed}>
      <span onClick={() => onClose?.()}>
        <GradientTooltip label={isLocked ? 'Sign in to unlock' : null}>
          <HStack mb={4} justifyContent="space-between" width="100%">
            <HStack width="100%" spacing={2} transition="all 0.2s ease-out">
              <GradientTooltip label={!isLocked && isCollapsed ? title : null}>
                <span style={{ marginLeft: isCollapsed ? 4 : 0 }}>{icon}</span>
              </GradientTooltip>
              {!isCollapsed && <Text fontSize="small">{title}</Text>}
            </HStack>
            {isLocked && !isCollapsed && <Icon type="SidebarLockedIcon" />}
          </HStack>
        </GradientTooltip>
      </span>
    </NavigationLink>
  );
};

interface ISidebarContent {
  isDrawer?: boolean;
  isFreeUser: boolean;
  isCollapsed: boolean;
  isSignedIn: boolean;
  hasDiscordRegistered: boolean;
  onClose?: () => void;
}

const SidebarContent = ({
  isDrawer,
  isFreeUser,
  isCollapsed,
  isSignedIn,
  hasDiscordRegistered,
  onClose,
}: ISidebarContent) => {
  const discordAttachModal = useDisclosure();

  const logoBoxProps = isCollapsed
    ? { position: 'absolute', left: '50%', top: 4, transform: 'translateX(-50%)', ml: 1 }
    : {};

  return (
    <>
      <Stack
        p={isDrawer ? 0 : isCollapsed ? 5 : 6}
        alignItems={isCollapsed ? 'center' : 'flex-start'}
        width="100%"
        height="100%"
        position="relative"
      >
        <Box mb={isCollapsed ? 12 : 8}>
          {!isDrawer && (
            //  @ts-ignore
            <Box {...logoBoxProps} onClick={() => onClose?.()}>
              <Logo isClickable rocketOnly={isCollapsed} size={isCollapsed ? 24 : 32} />
            </Box>
          )}
        </Box>
        <SidebarItem
          title="Dashboard"
          icon={<Icon type="SidebarDashboard" />}
          isCollapsed={isCollapsed}
          isLocked={!isSignedIn}
          navigationProps={isSignedIn ? { to: ERoute.Dashboard } : {}}
          onClose={() => onClose?.()}
        />
        <Text fontSize="small" fontWeight="semibold" mt={2} opacity="secondary">
          Learn
        </Text>
        <VStack alignItems="flex-start" spacing={2} width="100%">
          <SidebarItem
            title="Projects"
            icon={<Icon type="SidebarProjects" />}
            isCollapsed={isCollapsed}
            navigationProps={{ to: ERoute.Projects }}
            onClose={() => onClose?.()}
          />
          <SidebarItem
            title="Challenges"
            icon={<Icon type="SidebarChallenges" />}
            isCollapsed={isCollapsed}
            navigationProps={{ to: ERoute.Challenges }}
            onClose={() => onClose?.()}
          />
          <SidebarItem
            title="Problems"
            icon={<Icon type="SidebarPracticeProblems" />}
            isCollapsed={isCollapsed}
            navigationProps={{ to: ERoute.PracticeProblems }}
            onClose={() => onClose?.()}
          />
        </VStack>
        <Text fontSize="small" fontWeight="semibold" mt={2} opacity="secondary">
          Create
        </Text>
        <VStack alignItems="flex-start" spacing={2} width="100%">
          <SidebarItem
            title="Prototypes"
            icon={<Icon type="SidebarPrototypes" />}
            isCollapsed={isCollapsed}
            navigationProps={{ to: ERoute.Prototypes }}
            onClose={() => onClose?.()}
          />
          <SidebarItem
            title="Code Frames"
            icon={<Icon type="SidebarCodeFrames" />}
            isCollapsed={isCollapsed}
            navigationProps={{ to: ERoute.CodeFrames }}
            onClose={() => onClose?.()}
          />
        </VStack>
        <Divider width="100%" />
        <SidebarItem
          title="Pricing"
          icon={<Icon type="SidebarUpgradePlan" />}
          isCollapsed={isCollapsed}
          navigationProps={{ to: ERoute.Pricing }}
          onClose={() => onClose?.()}
        />
        <SidebarItem
          title="Community"
          icon={<Icon type="SidebarCommunityDiscord" size={20} />}
          isCollapsed={isCollapsed}
          isLocked={!isSignedIn}
          navigationProps={
            isSignedIn
              ? {
                  onClick: hasDiscordRegistered ? openDiscord : discordAttachModal.onOpen,
                }
              : {}
          }
        />
        <Center
          width="100%"
          height="100%"
          left="50%"
          transform="translateX(-50%)"
          position="relative"
          bottom={0}
          ml={!isCollapsed && !isDrawer ? -1.5 : 0}
          alignItems="flex-end"
          justifyContent={isDrawer ? 'flex-start' : 'center'}
        >
          <Stack
            spacing={4}
            direction={isCollapsed ? 'column' : 'row'}
            px={3}
            py={1}
            mt={6}
            ml={isDrawer ? 4 : 0}
            borderRadius={8}
            backgroundColor={isCollapsed ? 'initial' : 'whiteAlpha.200'}
            justifyContent="center"
            width="fit-content"
            alignItems="center"
          >
            <Link href="https://www.instagram.com/bigdevsoon/" isExternal>
              <IconButton
                aria-label="Instagram"
                icon={<Icon type="SidebarInstagram" />}
                size="small"
                variant="ghost"
                opacity={0.6}
                transition="all 0.2 ease-out"
                _hover={{ opacity: 1 }}
              />
            </Link>
            <Link href="https://www.tiktok.com/@bigdevsoon" isExternal>
              <IconButton
                aria-label="TikTok"
                icon={<Icon type="SidebarTikTok" />}
                size="small"
                variant="ghost"
                opacity={0.6}
                transition="all 0.2 ease-out"
                _hover={{ opacity: 1 }}
              />
            </Link>
            <Link href="https://www.youtube.com/@BigDevSoon" isExternal>
              <IconButton
                aria-label="YouTube"
                icon={<Icon type="SidebarYouTube" />}
                size="small"
                variant="ghost"
                opacity={0.6}
                transition="all 0.2 ease-out"
                _hover={{ opacity: 1 }}
              />
            </Link>
            <Link href="https://www.facebook.com/BigDevSoon" isExternal>
              <IconButton
                aria-label="Facebook"
                icon={<Icon type="SidebarFacebook" />}
                size="small"
                variant="ghost"
                opacity={0.6}
                transition="all 0.2 ease-out"
                _hover={{ opacity: 1 }}
              />
            </Link>
            <Link href="https://www.linkedin.com/company/bigdevsoon" isExternal>
              <IconButton
                aria-label="LinkedIn"
                icon={<Icon type="SidebarLinkedin" size={16} />}
                size="small"
                variant="ghost"
                opacity={0.6}
                transition="all 0.2 ease-out"
                _hover={{ opacity: 1 }}
              />
            </Link>
          </Stack>
        </Center>
      </Stack>
      <DiscordAttachModal isOpen={discordAttachModal.isOpen} onClose={discordAttachModal.onClose} />
    </>
  );
};

interface IAppLayoutProps {
  isFreeUser: boolean;
  hasDiscordRegistered: boolean;
  children: ReactNode;
}

export const AppLayout = ({ isFreeUser, hasDiscordRegistered, children }: IAppLayoutProps) => {
  const { userId } = useAuth();
  const { isSmallScreen, isDesktopHome } = useMediaQueries();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const sidebarState = isSmallScreen ? 'drawer' : isDesktopHome ? 'full' : 'collapsed';

  const supabase = useSupabaseTypedClient();

  const isSignedIn = !!userId;

  useEffect(() => {
    if (!isSignedIn) {
      return;
    }

    const accessToken = getCookie(GITHUB_COOKIE_KEY);

    if (accessToken) {
      supabase.functions.invoke(EdgeFunctionName.UpdateGithubAccessToken, {
        body: { accessToken } as UpdateGithubAccessTokenParams,
      });

      deleteCookie(GITHUB_COOKIE_KEY);
    }
  }, [isSignedIn]);

  return (
    <Grid
      templateColumns={
        sidebarState === 'drawer' ? '1fr' : sidebarState === 'full' ? '241px 1fr' : '75px 1fr'
      }
      templateRows="60px 1fr"
      height="100vh"
    >
      <Banner />
      {sidebarState !== 'drawer' && (
        <Box
          gridColumn="1"
          gridRow="1 / 3"
          borderRight="1px solid"
          borderColor="whiteAlpha.300"
          position="fixed"
          height="100%"
          overflowY="auto"
          width={sidebarState === 'full' ? '241px' : '75px'}
        >
          <SidebarContent
            isCollapsed={sidebarState === 'collapsed'}
            isSignedIn={isSignedIn}
            isFreeUser={isFreeUser}
            hasDiscordRegistered={hasDiscordRegistered}
          />
        </Box>
      )}

      <Box
        gridColumn={sidebarState === 'drawer' ? '1' : '2'}
        gridRow="1"
        borderBottom="1px solid"
        borderBottomColor="whiteAlpha.300"
        display="flex"
        alignItems="center"
        justifyContent={sidebarState === 'drawer' ? 'space-between' : 'flex-end'}
        pr={sidebarState === 'drawer' ? 6 : 4}
        pl={4}
        zIndex="docked"
        width="100%"
      >
        {sidebarState === 'drawer' && (
          <HStack spacing={2}>
            <IconButton
              aria-label="Open Sidebar"
              icon={<Icon type="SidebarHamburgerMenu" />}
              onClick={onOpen}
              variant="ghost"
            />
            <Drawer isOpen={isOpen} placement="left" onClose={onClose} size="full">
              <DrawerOverlay />
              <DrawerContent bg="gray.900">
                <DrawerCloseButton mt={1} />
                <DrawerHeader pb={0} pl={8}>
                  <Box onClick={onClose}>
                    <Logo isClickable />
                  </Box>
                </DrawerHeader>
                <DrawerBody>
                  <SidebarContent
                    isDrawer
                    onClose={onClose}
                    isCollapsed={false}
                    isSignedIn={isSignedIn}
                    isFreeUser={isFreeUser}
                    hasDiscordRegistered={hasDiscordRegistered}
                  />
                </DrawerBody>
              </DrawerContent>
            </Drawer>
            <Box pt={0.5}>
              <Logo rocketOnly isClickable size={24} />
            </Box>
          </HStack>
        )}
        {isSignedIn ? (
          <HStack spacing={3}>
            {!isSmallScreen && <Support />}
            <UserNotifications />
            <UserBigtokens />
            <UserDropdownMenu />
            {isFreeUser && !isSmallScreen && <BigBadge plan={Plan_Type_Enum.Lifetime} />}
          </HStack>
        ) : (
          <GitHubButton />
        )}
      </Box>
      <Box
        gridColumn={sidebarState === 'drawer' ? '1' : '2'}
        gridRow="2"
        px={sidebarState === 'drawer' ? 2 : 4}
        py={4}
        pb="60px"
        overflowY="auto"
        flexGrow={1}
        width="100%"
        minWidth="100%"
        height="calc(100vh - 60px)"
        id="big-dev-soon-main-scroller"
      >
        {children}
      </Box>
    </Grid>
  );
};
