import {
  Card as ChakraUiCard,
  CardHeader,
  CardBody,
  HStack,
  CardProps,
  CardBodyProps,
  CardHeaderProps,
} from '@chakra-ui/react';
import { ReactNode } from 'react';

import Text from './Text';

interface ICardProps extends CardProps {
  alert?: ReactNode;
  header?: ReactNode;
  rightAction?: ReactNode;
  children?: ReactNode;
  cardHeaderProps?: CardHeaderProps;
  cardBodyProps?: CardBodyProps;
}

const Card = ({
  alert,
  header,
  rightAction,
  children,
  cardHeaderProps,
  cardBodyProps,
  ...props
}: ICardProps) => {
  return (
    <ChakraUiCard
      background="whiteAlpha.200"
      boxShadow="0px 10px 20px 0px rgba(0, 0, 0, 0.15)"
      {...props}
    >
      {alert && alert}
      <CardHeader pb={0} {...cardHeaderProps}>
        <HStack alignItems="center" justifyContent="space-between">
          <Text fontSize="xxLarge" fontWeight="semibold">
            {header}
          </Text>
          <>{rightAction}</>
        </HStack>
      </CardHeader>
      <CardBody {...cardBodyProps}>{children}</CardBody>
    </ChakraUiCard>
  );
};

export default Card;
