import { useQuery } from '@tanstack/react-query';
import { getUserAchievementsQueryFn } from 'hooks/queries/useGetUserAchievementsQuery';
import { useSupabaseTypedClient } from 'hooks/reactQuery';
import { useToastWrapper } from 'hooks/useToastWrapper';
import React, { useEffect } from 'react';
import useLocalStorageState from 'use-local-storage-state';

import { useCurrentUser } from 'auth/CurrentUserProvider';
import { useMediaQueries } from 'layout/MediaQueriesProvider';
import { TanstackQueryName } from 'types/frontend';

type AchievementContext = object;

export const AchievementContext = React.createContext({} as AchievementContext);

export const AchievementProvider = ({ children }) => {
  const { isMobile } = useMediaQueries();
  const { toastAchievement } = useToastWrapper();
  const { id } = useCurrentUser();
  const supabase = useSupabaseTypedClient();

  const storageKey = `${id}-achievements`;

  const [storageCompletedAchievements, setStorageCompletedAchievements] = useLocalStorageState<
    string[]
  >(storageKey, {
    defaultValue: [],
  });

  const { data } = useQuery({
    queryKey: [TanstackQueryName.GetUserAchievements, id],
    queryFn: () => getUserAchievementsQueryFn({ supabase, userId: id }),
  });

  useEffect(() => {
    const checkNewAchievements = async () => {
      if (!data?.achievements) return;

      const completedAchievements = data.achievements.filter(
        (achievement) => achievement.is_completed
      );
      const newCompletedAchievements = completedAchievements.filter(
        (achievement) => !storageCompletedAchievements.includes(achievement.id)
      );

      newCompletedAchievements.forEach((newAchievement, idx) => {
        setTimeout(() => {
          if (isMobile) {
            return;
          }

          toastAchievement(newAchievement);
        }, idx * 1000);
      });

      setStorageCompletedAchievements(completedAchievements.map((achievement) => achievement.id));
    };

    checkNewAchievements();
  }, [data?.achievements]);

  return <AchievementContext.Provider value={{}}>{children}</AchievementContext.Provider>;
};

export const useAchievement = () => {
  const context = React.useContext(AchievementContext);

  if (!context) {
    throw new Error('useAchievement must be used within an AchievementContextProvider');
  }

  return context;
};
