import { useQueryClient } from '@tanstack/react-query';

import { CodeEditorType, CodePreview } from 'types/edgeFunctions';
import { identifyLivePreviewURL, identifyRepositoryUrl } from 'utils/solutions';

import { useToastWrapper } from './useToastWrapper';

const sanitizeTitle = (title: string) => {
  return title
    .toLowerCase()
    .replace(/[^a-z0-9\s]/g, '')
    .replace(/\s+/g, '-')
    .replace(/-+/g, '-')
    .trim();
};

export interface IUseRenameCodeParams {
  type: CodeEditorType;
  queryKey: unknown[];
  invalidateKeys: unknown[][];
  entityId: string;
  title: string;
  userScreenName: string;
  parentScreenName?: string | null;
  repositoryUrl: string;
  previewUrl: string;
}

export const getParamsToRefresh = (
  type: CodeEditorType,
  title: string,
  userScreenName: string,
  repositoryUrl: string,
  previewUrl: string,
  parentScreenName?: string | null
) => {
  const screenName = sanitizeTitle(title);
  const isExternalSolution =
    identifyRepositoryUrl(repositoryUrl) || identifyLivePreviewURL(previewUrl);

  let solutionUrl = '';

  switch (type) {
    case 'project':
      solutionUrl = `/projects/${parentScreenName}/solutions/${userScreenName}/${screenName}`;
      break;
    case 'challenge':
      solutionUrl = `/challenges/${parentScreenName}/solutions/${userScreenName}/${screenName}`;
      break;
    case 'problem':
      solutionUrl = `/problems/${parentScreenName}/solutions/${userScreenName}/${screenName}`;
      break;
    case 'code-frame':
      solutionUrl = `/code-frames/${userScreenName}/${screenName}`;
      break;
    case 'prototype':
      solutionUrl = `/prototypes/${userScreenName}/${screenName}`;
  }

  const paramsToUpdate = isExternalSolution
    ? { title, screen_name: screenName, solution_url: solutionUrl }
    : {
        title,
        screen_name: screenName,
        solution_url: solutionUrl,
        repository_url: solutionUrl,
        preview_url: `${solutionUrl}?mode=full-page`,
      };

  return paramsToUpdate;
};

export const useRenameCode = ({
  type,
  queryKey,
  invalidateKeys,
  entityId,
  title,
  userScreenName,
  parentScreenName,
  repositoryUrl,
  previewUrl,
}: IUseRenameCodeParams) => {
  const queryClient = useQueryClient();
  const { toastError } = useToastWrapper();

  const onMutate = async () => {
    await queryClient.cancelQueries({
      queryKey,
    });

    const previousData = queryClient.getQueryData<CodePreview[]>(queryKey);

    queryClient.setQueryData<CodePreview[]>(queryKey, (old: any) => {
      if (!old) return;

      // Handle scenario where 'old' is an object possibly with 'solutions' array and 'my_solution'
      if (old.solutions && Array.isArray(old.solutions)) {
        const updatedSolutions = old.solutions.map((codePreview) => {
          if (codePreview.id === entityId) {
            return {
              ...codePreview,
              ...getParamsToRefresh(
                type,
                title,
                userScreenName,
                repositoryUrl,
                previewUrl,
                parentScreenName
              ),
            };
          }
          return codePreview;
        });

        return { ...old, solutions: updatedSolutions };
      }

      // Handle scenario where 'old' is just an object
      if (typeof old === 'object' && !Array.isArray(old)) {
        if (old.id === entityId) {
          return {
            ...old,
            ...getParamsToRefresh(
              type,
              title,
              userScreenName,
              repositoryUrl,
              previewUrl,
              parentScreenName
            ),
          };
        }

        return old;
      }

      // Existing handling for scenario 3 where 'old' is an array
      return old.map((codePreview) => {
        if (codePreview.id === entityId) {
          return {
            ...codePreview,
            ...getParamsToRefresh(
              type,
              title,
              userScreenName,
              repositoryUrl,
              previewUrl,
              parentScreenName
            ),
          };
        }

        return codePreview;
      });
    });

    return { previousData };
  };

  const onError = (error: any, variables: any, context: any) => {
    toastError('Failed to rename title (e.g. same title exists)');

    queryClient.setQueryData(queryKey, context.previousData);

    invalidateKeys.forEach((key) => {
      queryClient.removeQueries({ queryKey: key });
    });
  };

  const onSettled = () => {
    invalidateKeys.forEach((key) => {
      queryClient.removeQueries({ queryKey: key });
    });
  };

  return {
    onMutate,
    onError,
    onSettled,
  };
};
