import { HStack } from '@chakra-ui/react';
import { ReactNode } from 'react';

import { useAuth } from 'auth/AuthProvider';
import { useCurrentUser } from 'auth/CurrentUserProvider';
import GitHubButton from 'components/GitHubButton';
import UserBigtokens from 'layout/Navigation/UserBigtokens';
import UserDropdownMenu from 'layout/Navigation/UserDropdownMenu';
import { Plan_Type_Enum } from 'types/databaseEnums';

import { BigBadge } from '../BigBadge';

interface IRightSideActionsProps {
  leftActions?: ReactNode;
  rightActions?: ReactNode;
}

const RightSideActions = ({ leftActions, rightActions }: IRightSideActionsProps) => {
  const { userId: authId } = useAuth();
  const { plan } = useCurrentUser();

  return !authId ? (
    <HStack spacing={3}>
      {leftActions || null}
      <GitHubButton />
      {rightActions || null}
    </HStack>
  ) : (
    <HStack spacing={3}>
      {leftActions || null}
      <UserBigtokens />
      <UserDropdownMenu />
      {plan === Plan_Type_Enum.Free && <BigBadge plan={Plan_Type_Enum.Lifetime} />}
      {rightActions || null}
    </HStack>
  );
};

export default RightSideActions;
