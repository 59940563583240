import { VStack, Stack, Container, HStack, Center, Spinner } from '@chakra-ui/react';
import { InfiniteData } from '@tanstack/react-query';
import { useGetPracticeProblemsSolutionsQuery } from 'hooks/queries/problems/useGetPracticeProblemsSolutionsQuery';

import { useCurrentUser } from 'auth/CurrentUserProvider';
import CodePreviewCard from 'components/CodePreviewCard';
import { EmptyContent } from 'components/EmptyContent';
import PageLoader from 'components/PageLoader';
import Text from 'components/Text';
import { CodePreview } from 'types/edgeFunctions';
import { TanstackQueryName } from 'types/frontend';

interface ISolutionsProps {
  practiceProblemId: string;
}

const SolutionsTab = ({ practiceProblemId }: ISolutionsProps) => {
  const { id: currentUserId } = useCurrentUser();

  const { data, isFetching, ref } = useGetPracticeProblemsSolutionsQuery({
    practiceProblemId,
  });

  const solutionsData = (data as unknown as InfiniteData<CodePreview[]>) || [];
  const solutions =
    solutionsData.pages.reduce((acc, page) => acc.concat(page), [] as CodePreview[]) || [];

  const renderContent = () => {
    const isFirstPage = solutionsData.pages.length === 0;

    if (isFirstPage && isFetching) {
      return <PageLoader shift />;
    }

    if (solutions?.length === 0) {
      return <EmptyContent type="solutions" />;
    }

    return (
      <>
        <Stack spacing={4} width="100%" pr={2}>
          {solutions.map((userCode) => (
            <CodePreviewCard
              key={userCode.id}
              isMySolution={false}
              canEdit={false}
              queryKey={[TanstackQueryName.GetPracticeProblemsSolutions, practiceProblemId]}
              invalidateKeys={[
                [TanstackQueryName.GetPracticeProblemSolution, userCode.solution_url!],
                [TanstackQueryName.GetProfileCode, userCode.user_id!, 'problem'],
                [TanstackQueryName.GetUserCode, currentUserId, 0],
                [TanstackQueryName.GetUserCode, currentUserId, 1],
                [TanstackQueryName.GetUserCode, currentUserId, 2],
              ]}
              {...userCode}
            />
          ))}
        </Stack>
        <div ref={ref} />
        <Center width="100%" mt={10}>
          {isFetching && !isFirstPage && <Spinner size="lg" />}
        </Center>
      </>
    );
  };

  return (
    <>
      <Container maxW="1400px">
        <VStack alignItems="flex-start" spacing={4} width="100%" pb={4}>
          <HStack width="100%" justifyContent="space-between" pt={4}>
            <Text fontSize="xxLarge" fontWeight="bold">
              Solutions
            </Text>
          </HStack>
          {renderContent()}
        </VStack>
      </Container>
    </>
  );
};

export default SolutionsTab;
