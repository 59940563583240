import { QuestionOutlineIcon, RepeatIcon } from '@chakra-ui/icons';
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  HStack,
  Stack,
  InputGroup,
  InputLeftElement,
  Grid,
  GridItem,
  Box,
  useTheme,
  Tag,
  TagLabel,
  Divider,
  VStack,
  Center,
  FormHelperText,
  Textarea,
  Progress,
  IconButton,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { supabaseMutationFn, useSupabaseTypedClient } from 'hooks/reactQuery';
import { extractCodeFromStorage } from 'hooks/useCodeStorage';
import { useToastWrapper } from 'hooks/useToastWrapper';
import html2canvas from 'html2canvas';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import resemble from 'resemblejs';
import * as yup from 'yup';

import { useCurrentUser } from 'auth/CurrentUserProvider';
import DesignComparison from 'components/DesignComparison';
import CodeFrame from 'components/DevSandbox/CodeFrame';
import { getGeneratedPageURL } from 'components/DevSandbox/DevSandboxProvider';
import { CODE_FREE_LIMIT, CODE_PAID_LIMIT } from 'components/DevSandbox/constants';
import GradientTooltip from 'components/GradientTooltip';
import Icon from 'components/Icon';
import Link from 'components/Link';
import { ProtectedElement } from 'components/ProtectedElement';
import Tabs from 'components/Tabs';
import Text from 'components/Text';
import ToggleCodePrivacySwitch from 'components/ToggleCodePrivacySwitch';
import { useMediaQueries } from 'layout/MediaQueriesProvider';
import {
  Challenge,
  EdgeFunctionName,
  SubmitChecks,
  SubmitCodeParams,
  SubmitCodeResponse,
} from 'types/edgeFunctions';
import { TanstackQueryName } from 'types/frontend';
import { convertImageUrlToBase64 } from 'utils/links';
import { pluralize } from 'utils/strings';

interface IBubbleCounterProps {
  count: number;
}

const BubbleCounter = ({ count }: IBubbleCounterProps) => {
  return (
    <Box width="24px" height="24px" backgroundColor="purple.400" borderRadius="50%">
      <Center>
        {count}
        <Text></Text>
      </Center>
    </Box>
  );
};

interface SubmitSolutionForm {
  title: string;
  repository_url?: string;
  live_preview_url?: string;
  feedback: string;
}

const validationSchema = yup
  .object({
    title: yup
      .string()
      .required('Title is required')
      .max(80, 'Title is too long (maximum is 80 characters)'),
    feedback: yup.string().max(300, 'Feedback is too long (maximum is 300 characters)'),
  })
  .required();

const validationSchemaExternalSubmission = yup
  .object({
    title: yup
      .string()
      .required('Title is required')
      .max(80, 'Title is too long (maximum is 80 characters)'),
    repository_url: yup
      .string()
      .url('Repository URL should be a valid URL')
      .max(255, 'Repository URL is too long (maximum is 255 characters)')
      .test(
        'is-repository-url',
        'Repository URL should be from Glitch (https://glitch.com/edit/#!/[MY-GLITCH]), replit (https://replit.com/[@ME]/[REPL]) or GitHub (https://github.com/[ME]/[MY-REPO]).',
        (value) => {
          return ['glitch.com/edit/#!', 'replit.com', 'github.com'].some((codeRepository) =>
            (value?.toLowerCase() || '').includes(codeRepository)
          );
        }
      ),
    live_preview_url: yup
      .string()
      .url('Live Preview URL should be a valid URL')
      .max(255, 'Live Preview URL is too long (maximum is 255 characters)')
      .test(
        'is-live-preview-url',
        'Live Preview URL should be from Glitch (https://[MY-GLITCH].glitch.me), replit (https://[REPL].[ME].repl.co), Vercel (https://[MY-APP].vercel.app), Netlify (https://[MY-APP].netlify.app), Firebase (https://[MY-APP].web.app or https://[MY-APP].firebaseapp.com), AWS Amplify (https://[BRANCH].[MY-APP].amplifyapp.com), GitHub Pages (https://[ME].github.io/[MY-REPO]) or Cloudflare Pages (https://[NAME].pages.dev).',
        (value) => {
          return [
            'glitch.me',
            'repl.co',
            'vercel.app',
            'netlify.app',
            'web.app',
            'firebaseapp.com',
            'amplifyapp.com',
            'github.io',
            'pages.dev',
          ].some((codeRepository) => (value?.toLowerCase() || '').includes(codeRepository));
        }
      ),
  })
  .required();

interface ISubmitSolutionProps {
  challenge: Challenge;
}

const SubmitSolution = ({
  challenge: {
    id,
    screen_name,
    title: challengeTitle,
    solution_title,
    repository_url,
    preview_url,
    screenshot_url,
  },
}: ISubmitSolutionProps) => {
  const [tabIndex, setTabIndex] = useState(0);
  const [isPrivate, setIsPrivate] = useState(false);

  const [checks, setChecks] = useState<SubmitChecks | null>(null);
  const [all_checks_passed, setAllChecksPassed] = useState(false);

  const [score, setScore] = useState(0);
  const [screenshot, setScreenshot] = useState('');

  const { id: userId, screenName: userScreenName, isFree } = useCurrentUser();

  const storedHtml = extractCodeFromStorage({
    type: 'challenge',
    screenName: screen_name,
    userId,
    code: 'html',
  });
  const storedCss = extractCodeFromStorage({
    type: 'challenge',
    screenName: screen_name,
    userId,
    code: 'css',
  });
  const storedJs = extractCodeFromStorage({
    type: 'challenge',
    screenName: screen_name,
    userId,
    code: 'js',
  });

  const compareImages = (image1: string, image2: string) => {
    return new Promise((resolve, reject) => {
      resemble(image1)
        .compareTo(image2)
        .scaleToSameSize()
        .ignoreAntialiasing()
        .onComplete((data) => {
          if (data.error) {
            reject(data.error);
          } else {
            const mismatchPercentage = parseFloat(data.misMatchPercentage);
            const similarityScore = Math.ceil(100 - mismatchPercentage);

            resolve(similarityScore);

            setScore(similarityScore);
          }
        });
    });
  };

  useEffect(() => {
    const getCompareScore = async () => {
      if (screenshot) {
        const designScreenshotBase64 = await convertImageUrlToBase64(screenshot_url);

        compareImages(screenshot, designScreenshotBase64 || '');
      }
    };

    getCompareScore();
  }, [screenshot]);

  const { isDesktopNavigation } = useMediaQueries();

  const navigate = useNavigate();

  const supabase = useSupabaseTypedClient();

  const queryClient = useQueryClient();

  const { toastSuccess, toastError } = useToastWrapper();

  const { borderRadius } = useTheme();

  const codeFrameUrl = useMemo(() => {
    return getGeneratedPageURL(storedHtml, storedCss, storedJs);
  }, [storedHtml, storedCss, storedJs]);
  const screenshotFrameUrl = useMemo(() => {
    return getGeneratedPageURL(storedHtml, storedCss, storedJs);
  }, [storedHtml, storedCss, storedJs]);

  const updateScreenshot = () => {
    const iframe = document.getElementById(`bds-code-frame-${screenshotFrameUrl}`);
    // @ts-ignore
    const iframeDocument = iframe.contentDocument || iframe.contentWindow.document;

    html2canvas(iframeDocument.body, {
      useCORS: true,
      allowTaint: true,
      width: 1440,
      height: 1080,
    })
      .then((canvas) => {
        setScreenshot(canvas.toDataURL('image/png'));
      })
      .catch((err) => {
        console.error('Error capturing screenshot:', err);
      });
  };

  useEffect(() => {
    if (!screenshot && screenshotFrameUrl) {
      setTimeout(() => {
        updateScreenshot();
      }, 1000);
    }
  }, [screenshotFrameUrl, screenshot]);

  const mutation = useMutation<SubmitCodeResponse, string, SubmitCodeParams>({
    mutationFn: supabaseMutationFn((params) =>
      supabase.functions.invoke<SubmitCodeResponse>(EdgeFunctionName.SubmitCode, {
        body: params as SubmitCodeParams,
      })
    ),
    onSuccess: async (data) => {
      setChecks(data.checks as SubmitChecks);
      setAllChecksPassed(data.all_checks_passed);

      if (data.all_checks_passed) {
        await Promise.all([
          queryClient.invalidateQueries({ queryKey: [TanstackQueryName.GetCurrentUser, userId] }),
          queryClient.invalidateQueries({
            queryKey: [TanstackQueryName.GetChallenge, screen_name],
          }),
          queryClient.invalidateQueries({ queryKey: [TanstackQueryName.GetChallenges, userId, 1] }),
          queryClient.invalidateQueries({ queryKey: [TanstackQueryName.GetChallenges, userId, 2] }),
          queryClient.invalidateQueries({
            queryKey: [TanstackQueryName.GetChallengeSolutions, id],
          }),
          queryClient.invalidateQueries({
            queryKey: [TanstackQueryName.GetUserAchievements, userId],
          }),
        ]);

        setTimeout(() => {
          navigate(data.solution_url);
        }, 300);
      } else {
        return toastError('Some of the checks failed, try to fix them and submit again.');
      }
    },
    onError: () => {
      toastError(`Can't submit solution, please try again later.`);
    },
  });

  useEffect(() => {
    if (repository_url && !repository_url.includes('/challenges/')) {
      setTabIndex(1);
    }
  }, [repository_url]);

  const {
    handleSubmit,
    register,
    formState: { errors },
    watch,
  } = useForm<SubmitSolutionForm>({
    // @ts-ignore
    resolver: yupResolver(tabIndex === 0 ? validationSchema : validationSchemaExternalSubmission),
    mode: 'onSubmit',
    defaultValues: {
      repository_url:
        repository_url.includes('/challenges/') && repository_url.includes(userScreenName)
          ? ''
          : repository_url || '',
      live_preview_url:
        preview_url.includes('/challenges/') && preview_url.includes(userScreenName)
          ? ''
          : preview_url || '',
      title: solution_title || '',
      feedback: '',
    },
  });

  const title = watch('title');
  const feedback = watch('feedback');
  const repositoryUrl = watch('repository_url');
  const livePreviewUrl = watch('live_preview_url');

  const onSubmit = async (values: SubmitSolutionForm) => {
    const CODE_LIMIT = isFree ? CODE_FREE_LIMIT : CODE_PAID_LIMIT;

    if (storedHtml.length > CODE_LIMIT) {
      return toastError(`Exceeded maximum ${CODE_LIMIT} characters of HTML`);
    }

    if (storedCss.length > CODE_LIMIT) {
      return toastError(`Exceeded maximum ${CODE_LIMIT} characters of CSS`);
    }

    if (storedJs.length > CODE_LIMIT) {
      return toastError(`Exceeded maximum ${CODE_LIMIT} characters of JS`);
    }

    if (tabIndex === 0 && score < 50) {
      return toastError('Screenshot match score is less than 50%');
    }

    if (tabIndex === 0) {
      return await mutation.mutate({
        ...values,
        html: storedHtml,
        css: storedCss,
        js: storedJs,
        title,
        is_private: isPrivate,
        feedback,
        type: 'challenge',
        entityScreenName: screen_name,
        iframe_screenshot: screenshot,
      });
    }

    await mutation.mutate({
      ...values,
      html: storedHtml,
      css: storedCss,
      js: storedJs,
      title,
      is_private: isPrivate,
      feedback,
      type: 'challenge',
      entityScreenName: screen_name,
      live_preview_url: livePreviewUrl,
      repository_url: repositoryUrl,
    });
  };

  const failedChecksCount = Object.values(checks || {}).filter((flag) => flag === false).length;

  return (
    <>
      <Box position="absolute" top="-99999px">
        <CodeFrame url={screenshotFrameUrl} style={{ width: 1440, height: 1080 }} />
      </Box>
      <VStack spacing={3} alignItems="flex-start">
        <Tabs
          tabs={['Code Editor', 'External Solution']}
          tabIndex={tabIndex}
          onChange={(index) => setTabIndex(index)}
        />
        <form onSubmit={handleSubmit(onSubmit)}>
          <VStack spacing={3} overflowY="auto" h="80vh" alignItems="flex-start">
            <Box backgroundColor="whiteAlpha.200" borderRadius={borderRadius} p={4}>
              <Grid templateColumns="repeat(3, 1fr)" gap={8}>
                <GridItem colSpan={isDesktopNavigation ? 2 : 3}>
                  <Stack spacing={8}>
                    <Stack spacing={2}>
                      <FormControl isInvalid={Boolean(errors.title)}>
                        <FormLabel htmlFor="title">
                          <VStack alignItems="flex-start">
                            <HStack>
                              <BubbleCounter count={1} />
                              <Text fontSize="xLarge">Solution title</Text>
                            </HStack>
                            <Text opacity="secondary">
                              Try to use the title to easily identify your challenge solution.
                            </Text>
                          </VStack>
                        </FormLabel>
                        <Input
                          {...register('title')}
                          placeholder={`e.g. My solution to ${challengeTitle} challenge`}
                          variant="filled"
                          maxLength={80}
                          autoFocus
                          autoComplete="off"
                        />
                        <HStack justifyContent="space-between" alignItems="center">
                          {errors.title?.message ? (
                            <FormErrorMessage>{errors.title.message}</FormErrorMessage>
                          ) : (
                            <div />
                          )}
                          <FormHelperText>
                            <HStack spacing={1}>
                              <Text fontSize="micro" opacity="secondary" fontWeight="regular">
                                Characters remaining:
                              </Text>
                              <Text fontSize="micro" opacity="secondary" fontWeight="semibold">
                                {80 - title.length}
                              </Text>
                            </HStack>
                          </FormHelperText>
                        </HStack>
                      </FormControl>
                    </Stack>
                    {tabIndex === 0 && (
                      <Stack spacing={2} alignItems="flex-start" width="100%">
                        <VStack alignItems="flex-start" width="100%">
                          <HStack width="100%" justifyContent="space-between" alignItems="center">
                            <VStack alignItems="flex-start">
                              <HStack>
                                <BubbleCounter count={2} />
                                <Text fontSize="xLarge">Design comparison</Text>
                              </HStack>
                              <Text opacity="secondary">
                                Resolution used for comparison is 1440x1080.
                              </Text>
                            </VStack>
                            <Stack>
                              <HStack spacing={4}>
                                <Box>
                                  <HStack pb={1}>
                                    <Text fontSize="small">
                                      Matching Score: {score === 0 ? 'Loading...' : `${score}/100`}
                                    </Text>
                                    {score !== 0 && (
                                      <GradientTooltip label="Ensure your code covers the 1440x1080 resolution to improve the matching score and remember that similar placement of elements and use of colors including background color are relevant for the comparison.">
                                        <QuestionOutlineIcon fontSize="small" />
                                      </GradientTooltip>
                                    )}
                                  </HStack>
                                  <Progress
                                    value={score}
                                    colorScheme={
                                      score === 0 ? 'initial' : score >= 50 ? 'green' : 'red'
                                    }
                                    borderRadius={borderRadius}
                                  />
                                </Box>
                                <GradientTooltip label="Refresh the screenshot for comparison.">
                                  <IconButton
                                    aria-label="Refresh the screenshot"
                                    isDisabled={score === 0}
                                    onClick={() => {
                                      updateScreenshot();
                                      toastSuccess('Screenshot refreshed.');
                                    }}
                                  >
                                    <RepeatIcon />
                                  </IconButton>
                                </GradientTooltip>
                              </HStack>
                            </Stack>
                          </HStack>
                        </VStack>
                        <DesignComparison
                          codeFrameUrl={codeFrameUrl}
                          designImage={screenshot_url}
                        />
                      </Stack>
                    )}
                    {tabIndex === 1 && (
                      <Stack spacing={2}>
                        <FormControl isInvalid={Boolean(errors.repository_url)}>
                          <FormLabel htmlFor="repository_url">
                            <VStack alignItems="flex-start">
                              <HStack>
                                <BubbleCounter count={2} />
                                <Text fontSize="xLarge">Repository URL</Text>
                              </HStack>
                              <Text opacity="secondary">
                                We accept URLs from <Link href="https://glitch.com/">Glitch</Link>,{' '}
                                <Link href="https://replit.com/">replit</Link>, and{' '}
                                <Link href="https://github.com/">GitHub</Link>.
                              </Text>
                            </VStack>
                          </FormLabel>
                          <InputGroup>
                            <InputLeftElement pointerEvents="none">
                              <Icon type="Website" />
                            </InputLeftElement>
                            <Input
                              {...register('repository_url')}
                              placeholder={`e.g. https://glitch.com/edit/#!/my-glitch-app`}
                              variant="filled"
                              autoComplete="off"
                            />
                          </InputGroup>
                          <FormErrorMessage>{errors.repository_url?.message}</FormErrorMessage>
                        </FormControl>
                      </Stack>
                    )}
                    {tabIndex === 1 && (
                      <Stack spacing={2}>
                        <FormControl isInvalid={Boolean(errors.live_preview_url)}>
                          <FormLabel htmlFor="live_preview_url">
                            <VStack alignItems="flex-start">
                              <HStack>
                                <BubbleCounter count={3} />
                                <Text fontSize="xLarge">Live Preview URL</Text>
                              </HStack>
                              <Text opacity="secondary">
                                We support <Link href="https://glitch.com/">Glitch</Link>,{' '}
                                <Link href="https://replit.com/">replit</Link>,{' '}
                                <Link href="https://vercel.com/">Vercel</Link>,{' '}
                                <Link href="https://www.netlify.com/">Netlify</Link>,{' '}
                                <Link href="https://firebase.google.com/">Firebase</Link>,{' '}
                                <Link href="https://aws.amazon.com/amplify/">AWS Amplify</Link>,{' '}
                                <Link href="https://pages.github.com/">GitHub Pages</Link> and{' '}
                                <Link href="https://pages.cloudflare.com/">Cloudflare Pages</Link>.
                              </Text>
                            </VStack>
                          </FormLabel>
                          <InputGroup>
                            <InputLeftElement pointerEvents="none">
                              <Icon type="Website" />
                            </InputLeftElement>
                            <Input
                              {...register('live_preview_url')}
                              placeholder={`e.g. https://my-glitch-app.glitch.me`}
                              variant="filled"
                              autoComplete="off"
                            />
                          </InputGroup>
                          <FormErrorMessage>{errors.live_preview_url?.message}</FormErrorMessage>
                        </FormControl>
                      </Stack>
                    )}
                    <Stack spacing={2}>
                      <FormControl isInvalid={Boolean(errors.feedback)}>
                        <FormLabel htmlFor="feedback">
                          <VStack alignItems="flex-start">
                            <HStack>
                              <BubbleCounter count={tabIndex === 0 ? 3 : 4} />
                              <Text fontSize="xLarge">Ask for feedback (optional)</Text>
                            </HStack>
                            <Text opacity="secondary">
                              {`Other people will be able to comment your solution based on this feedback request.`}
                            </Text>
                          </VStack>
                        </FormLabel>
                        <Textarea
                          {...register('feedback')}
                          placeholder="Please share the short brief of your solution and try to ask precisely
                      about the feedback you are looking for. If the message is on point,
                      answers will be too!"
                          variant="filled"
                          maxLength={300}
                          rows={5}
                          autoComplete="off"
                        />
                        <HStack justifyContent="space-between" alignItems="center">
                          {errors.feedback?.message ? (
                            <FormErrorMessage>{errors.feedback.message}</FormErrorMessage>
                          ) : (
                            <div />
                          )}
                          <FormHelperText>
                            <HStack spacing={1}>
                              <Text fontSize="micro" opacity="secondary" fontWeight="regular">
                                Characters remaining:
                              </Text>
                              <Text fontSize="micro" opacity="secondary" fontWeight="semibold">
                                {300 - feedback.length}
                              </Text>
                            </HStack>
                          </FormHelperText>
                        </HStack>
                      </FormControl>
                    </Stack>
                  </Stack>
                </GridItem>
                <GridItem colSpan={isDesktopNavigation ? 1 : 3}>
                  <HStack height="100%" alignItems="flex-start">
                    {isDesktopNavigation && (
                      <Divider orientation="vertical" width="1px" height="100%" />
                    )}
                    <VStack
                      ml={isDesktopNavigation ? 4 : 0}
                      spacing={8}
                      alignItems="flex-start"
                      width="100%"
                    >
                      <Text fontSize="xLarge">Submit checks</Text>
                      {failedChecksCount > 0 && !mutation.isPending && (
                        <Tag
                          p={2}
                          borderRadius="999px"
                          border="1px solid"
                          borderColor="whiteAlpha.300"
                          backgroundColor="whiteAlpha.200"
                        >
                          <TagLabel>
                            <HStack>
                              <Icon type="SubmitCheckFailed" />
                              <Text fontSize="large">
                                {failedChecksCount} {pluralize('check', failedChecksCount)} failed
                              </Text>
                            </HStack>
                          </TagLabel>
                        </Tag>
                      )}
                      {tabIndex === 0 && (
                        <VStack alignItems="flex-start">
                          <HStack>
                            <Icon
                              type={
                                !checks
                                  ? 'SubmitCheck'
                                  : mutation.isPending
                                  ? 'SubmitCheck'
                                  : score > 50
                                  ? 'SubmitCheckPassed'
                                  : 'SubmitCheckFailed'
                              }
                            />
                            <Text fontSize="large">Design matches</Text>
                          </HStack>
                          <Text fontWeight="regular" opacity="secondary">
                            Design screenshot should match at least 50% with the code solution.
                          </Text>
                        </VStack>
                      )}
                      {tabIndex === 1 && (
                        <VStack alignItems="flex-start">
                          <HStack>
                            <Icon
                              type={
                                !checks
                                  ? 'SubmitCheck'
                                  : mutation.isPending
                                  ? 'SubmitCheck'
                                  : checks?.repository_url_exists
                                  ? 'SubmitCheckPassed'
                                  : 'SubmitCheckFailed'
                              }
                            />
                            <Text fontSize="large">Repository URL exists</Text>
                          </HStack>
                          <Text fontWeight="regular" opacity="secondary">
                            URL must exist and be accessible.
                          </Text>
                        </VStack>
                      )}
                      {tabIndex === 1 && (
                        <VStack alignItems="flex-start">
                          <HStack>
                            <Icon
                              type={
                                !checks
                                  ? 'SubmitCheck'
                                  : mutation.isPending
                                  ? 'SubmitCheck'
                                  : checks?.live_preview_url_exists
                                  ? 'SubmitCheckPassed'
                                  : 'SubmitCheckFailed'
                              }
                            />
                            <Text fontSize="large">Live Preview URL exists</Text>
                          </HStack>
                          <Text fontWeight="regular" opacity="secondary">
                            URL must exists and be accessible.
                          </Text>
                        </VStack>
                      )}
                      {tabIndex === 1 && (
                        <VStack alignItems="flex-start">
                          <HStack>
                            <Icon
                              type={
                                !checks
                                  ? 'SubmitCheck'
                                  : mutation.isPending
                                  ? 'SubmitCheck'
                                  : checks?.live_preview_url_unique
                                  ? 'SubmitCheckPassed'
                                  : 'SubmitCheckFailed'
                              }
                            />
                            <Text fontSize="large">Live Preview URL is unique</Text>
                          </HStack>
                          <Text fontWeight="regular" opacity="secondary">
                            URL must be unique and not already used by another user.
                          </Text>
                        </VStack>
                      )}
                      <VStack alignItems="flex-start">
                        <HStack>
                          <Icon
                            type={
                              !checks
                                ? 'SubmitCheck'
                                : mutation.isPending
                                ? 'SubmitCheck'
                                : checks?.html_tag_exists
                                ? 'SubmitCheckPassed'
                                : 'SubmitCheckFailed'
                            }
                          />
                          <Text fontSize="large">index.html exists</Text>
                        </HStack>
                        <Text fontWeight="regular" opacity="secondary">
                          An index.html file must be present in the Live Preview URL.
                        </Text>
                      </VStack>
                      <VStack alignItems="flex-start">
                        <HStack>
                          <Icon
                            type={
                              !checks
                                ? 'SubmitCheck'
                                : mutation.isPending
                                ? 'SubmitCheck'
                                : checks?.bds_tag_exists
                                ? 'SubmitCheckPassed'
                                : 'SubmitCheckFailed'
                            }
                          />
                          <Text fontSize="large">{`<bds />`} tag exists</Text>
                        </HStack>
                        <Text fontWeight="regular" opacity="secondary">
                          {`The special <bds /> HTML tag must be present in the index.html.`}
                        </Text>
                      </VStack>
                      <ToggleCodePrivacySwitch
                        type="challenge"
                        isNotCreated
                        togglePrivacyCodeParams={{
                          entityId: '',
                          isPrivate: false,
                          invalidateKeys: [],
                          queryKey: [],
                        }}
                        onChange={(isPrivate) => setIsPrivate(isPrivate)}
                      />
                      <ProtectedElement
                        scheme="gradient"
                        isLoading={mutation.isPending || (tabIndex === 0 && score === 0)}
                        leftIcon={<Icon type="SubmitCode" />}
                        isDisabled={
                          tabIndex === 0 ? !title : !title || !repositoryUrl || !livePreviewUrl
                        }
                        type="submit"
                        size="lg"
                        fullWidth
                      >
                        <Text>{checks && !all_checks_passed ? 'Retry' : 'Submit Solution'}</Text>
                      </ProtectedElement>
                    </VStack>
                  </HStack>
                </GridItem>
              </Grid>
            </Box>
          </VStack>
        </form>
      </VStack>
    </>
  );
};

export default SubmitSolution;
