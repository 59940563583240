import { Box, Container, Center, Flex, HStack, VStack } from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';

import RightSideActions from 'components/DevSandbox/RightSideActions';
import Icon from 'components/Icon';
import Link from 'components/Link';
import Logo from 'components/Logo';
import Text from 'components/Text';
import { useMediaQueries } from 'layout/MediaQueriesProvider';
import { Challenge } from 'types/edgeFunctions';

import SubmitSolution from './SubmitSolution';

interface ISubmitProps {
  challenge: Challenge;
}

const Submit = ({ challenge }: ISubmitProps) => {
  const { pathname } = useLocation();
  const { isSmallScreen } = useMediaQueries();

  const backTo = pathname.replace('/submit', '');

  return (
    <Box
      position="fixed"
      top="0"
      left="0"
      right="0"
      bottom="0"
      width="100vw"
      height="100vh"
      backgroundColor="gray.900"
      zIndex="modal"
    >
      <VStack spacing={0} alignItems="stretch" height="100%">
        <Flex
          position="relative"
          height="60px"
          alignItems="center"
          px={4}
          backgroundColor="gray.900"
        >
          <Box mr="auto">
            <HStack spacing={0} flexGrow={1} justifyContent="flex-start" alignItems="center">
              <Logo isClickable size={24} rocketOnly />
              <Link to={backTo} color="white">
                <HStack spacing={1}>
                  <Icon type="BackArrow" />
                  <Text fontSize="small" fontWeight="regular" opacity="secondary">
                    Back to Code Editor
                  </Text>
                </HStack>
              </Link>
            </HStack>
          </Box>
          {!isSmallScreen && (
            <Box position="absolute" left="50%" transform="translateX(-50%)" textAlign="center">
              <Text fontWeight="semibold">Submit Solution</Text>
            </Box>
          )}
          <Box ml="auto">
            <RightSideActions />
          </Box>
        </Flex>
        <Box
          borderBottom="1px solid"
          borderColor="whiteAlpha.200"
          position="relative"
          zIndex={999}
        />
        <Container maxW="1400px">
          <Center height="90vh">
            <SubmitSolution challenge={challenge} />
          </Center>
        </Container>
      </VStack>
    </Box>
  );
};

export default Submit;
