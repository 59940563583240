import { HStack, IconButton } from '@chakra-ui/react';
import { useMutation } from '@tanstack/react-query';
import { supabaseMutationFn, useSupabaseTypedClient } from 'hooks/reactQuery';
import { IUseLikeCodeParams, useLikeCode } from 'hooks/useLikeCode';
import { debounce } from 'lodash';
import { useRef } from 'react';

import { EdgeFunctionName, LikeParams, LikeResponse } from 'types/edgeFunctions';

import LikeIcon from './LikeIcon';
import { ProtectedElement } from './ProtectedElement';
import Text from './Text';

interface ILikeButtonProps {
  userId: string;
  likes: number;
  likeCodeParams: IUseLikeCodeParams;
}

const LikeButton = ({ userId, likes, likeCodeParams }: ILikeButtonProps) => {
  const supabase = useSupabaseTypedClient();

  const { onMutate, onError, onSettled } = useLikeCode({ ...likeCodeParams, userId });

  const mutation = useMutation<LikeResponse, string, LikeParams>({
    mutationFn: supabaseMutationFn(({ entity_id, user_id, is_liked }) =>
      supabase.functions.invoke<LikeResponse>(EdgeFunctionName.Like, {
        body: { entity_id, user_id, is_liked } as LikeParams,
      })
    ),
    onMutate: async () => await onMutate?.(),
    onError: (err, variables, context) => onError?.(err, variables, context),
    onSettled: () => onSettled?.(),
  });

  const debouncedMutate = useRef(debounce(mutation.mutate, 300));

  return (
    <ProtectedElement
      asButton={false}
      onClick={(e) => {
        e.stopPropagation();
        debouncedMutate.current({
          is_liked: likeCodeParams.isLiked,
          user_id: userId,
          entity_id: likeCodeParams.entityId,
        });
      }}
    >
      <HStack
        spacing={1}
        minWidth="40px"
        cursor="pointer"
        _hover={{ opacity: 0.87 }}
        transition="all 0.2s ease-out"
      >
        <IconButton aria-label="like solution" variant="ghost" size="xs">
          <LikeIcon isLiked={likeCodeParams.isLiked} />
        </IconButton>
        <Text>{likes}</Text>
      </HStack>
    </ProtectedElement>
  );
};

export default LikeButton;
