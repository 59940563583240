import {
  Box,
  Button as ChakraUiButton,
  ButtonProps as ChakraUiButtonProps,
  TooltipProps,
} from '@chakra-ui/react';
import { ReactNode } from 'react';

import Text from 'components/Text';

import GradientTooltip from './GradientTooltip';
import Link, { LinkProps } from './Link';

type ButtonScheme =
  | 'primary'
  | 'outline'
  | 'outline-green'
  | 'gradient'
  | 'ghost'
  | 'danger'
  | 'glitch'
  | 'glitch-remix'
  | 'replit';

export interface IButtonProps extends ChakraUiButtonProps {
  scheme?: ButtonScheme | string;
  href?: string;
  to?: string | any;
  target?: '_self' | '_blank';
  label?: ReactNode | null;
  fullWidth?: boolean;
}

export interface ITooltipProps extends TooltipProps {
  fullWidth?: boolean;
}

export interface ILinkProps extends LinkProps {
  fullWidth?: boolean;
}

const LinkWrapper = ({ href, to, target, fullWidth, children, ...props }: ILinkProps) =>
  href || to ? (
    <Link
      href={href}
      to={to}
      target={target || to ? '_self' : '_blank'}
      {...props}
      width={fullWidth ? '100%' : 'initial'}
    >
      {children}
    </Link>
  ) : (
    <>{children}</>
  );

const TooltipWrapper = ({ label, children, fullWidth, ...props }: ITooltipProps) =>
  label ? (
    <Box width={fullWidth ? '100%' : 'initial'}>
      <GradientTooltip label={label} {...props}>
        <span>{children}</span>
      </GradientTooltip>
    </Box>
  ) : (
    <>{children}</>
  );

const Button = ({
  children,
  scheme = 'primary',
  href,
  to,
  target,
  isLoading,
  isDisabled,
  label,
  fullWidth,
  ...props
}: IButtonProps) => {
  const cantClick = isLoading || isDisabled;

  const schemePropsMapper: Record<ButtonScheme, any> = {
    danger: {
      color: 'white',
      backgroundColor: 'red.500',
      _hover: { backgroundColor: 'red.400' },
      _disabled: {
        backgroundColor: 'whiteAlpha.500',
        opacity: 0.36,
        cursor: 'not-allowed',
      },
    },
    primary: {
      color: 'gray.900',
      backgroundColor: 'purple.200',
      _hover: { backgroundColor: 'purple.300' },
      _disabled: {
        backgroundColor: 'whiteAlpha.500',
        opacity: 0.36,
        cursor: 'not-allowed',
      },
    },
    outline: {
      variant: 'outline',
      border: '1px solid rgba(255, 255, 255, 0.87)',
      _hover: {
        backgroundColor: cantClick ? 'initial' : 'whiteAlpha.200',
      },
    },
    'outline-green': {
      variant: 'outline',
      border: '1px solid rgb(154, 230, 180)',
      _hover: {
        backgroundColor: cantClick ? 'initial' : 'whiteAlpha.200',
      },
      color: 'green.200',
    },
    gradient: {
      transition: 'all 0.2s ease-out',
      opacity: 1,
      background:
        'linear-gradient(90deg, rgba(159, 122, 234, 0.87) 0%, rgba(237, 137, 54, 0.87) 100%)',
      _hover: {
        opacity: cantClick ? 0.4 : 0.9,
      },
      _active: {
        background:
          'linear-gradient(90deg, rgba(159, 122, 234, 0.87) 0%, rgba(237, 137, 54, 0.87) 100%)',
      },
    },
    ghost: {
      variant: 'ghost',
    },
    glitch: {
      color: 'gray.900',
      backgroundColor: '#FD81E9',
      _hover: { opacity: 0.87 },
      _disabled: {
        backgroundColor: 'whiteAlpha.500',
        opacity: 0.36,
        cursor: 'not-allowed',
      },
    },
    'glitch-remix': {
      color: 'black',
      backgroundColor: 'white',
      _hover: { opacity: 0.87 },
      _disabled: {
        opacity: 1,
        cursor: 'initial',
      },
    },
    replit: {
      color: 'gray.900',
      backgroundColor: '#FFA46A',
      _hover: { opacity: 0.87 },
      _disabled: {
        backgroundColor: 'whiteAlpha.500',
        opacity: 0.36,
        cursor: 'not-allowed',
      },
    },
  };

  return (
    <TooltipWrapper label={label} fullWidth={fullWidth}>
      <LinkWrapper
        href={href}
        to={to}
        target={target}
        color="white"
        textDecoration="none !important"
        fullWidth={fullWidth}
        width={props.width}
      >
        <ChakraUiButton
          isLoading={isLoading}
          isDisabled={isDisabled}
          transition="all 0.2s ease-out"
          width={fullWidth ? '100%' : 'initial'}
          {...schemePropsMapper[scheme]}
          {...props}
        >
          <Text color={schemePropsMapper[scheme].color} fontWeight="semibold">
            {children}
          </Text>
        </ChakraUiButton>
      </LinkWrapper>
    </TooltipWrapper>
  );
};

export default Button;
