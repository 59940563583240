import { SupabaseClient } from '@supabase/supabase-js';
import { useQueryWithErrorBoundary, useSupabaseTypedClient } from 'hooks/reactQuery';

import { useCurrentUser } from 'auth/CurrentUserProvider';
import { Experience_Level_Enum, User_Practice_Problem_Status_Enum } from 'types/databaseEnums';
import {
  GetPracticeProblemParams,
  GetPracticeProblemResponse,
  TestCases,
} from 'types/edgeFunctions';
import { TanstackQueryName } from 'types/frontend';
import { Database } from 'types/supabase';

interface IGetPracticeProblemQueryFnParams {
  supabase: SupabaseClient<Database>;
  userId: string;
  screenName: string;
}

export const getPracticeProblemQueryFn = async ({
  supabase,
  userId,
  screenName,
}: IGetPracticeProblemQueryFnParams) => {
  const practiceProblem = await supabase
    .from('practice_problem')
    .select('id, screen_name, title, description, difficulty, function_name, test_cases')
    .eq('screen_name', screenName)
    .single();

  if (practiceProblem.error) throw practiceProblem.error;

  const [
    userPracticeProblem,
    { count: discussionCount, error: discussionCountError },
    { count: solutionsCount, error: solutionsCountError },
  ] = await Promise.all([
    userId
      ? supabase
          .from('user_practice_problem')
          .select('id, status, title')
          .eq('user_id', userId)
          .eq('practice_problem_id', practiceProblem.data!.id)
          .eq('status', User_Practice_Problem_Status_Enum.Completed)
          .maybeSingle()
      : Promise.resolve(undefined),
    supabase
      .from('support_center')
      .select('*', { count: 'exact', head: true })
      .eq('entity_id', practiceProblem.data!.id)
      .not('user_id', 'is', null),
    supabase
      .from('user_practice_problem')
      .select('*', { count: 'exact', head: true })
      .eq('status', User_Practice_Problem_Status_Enum.Completed)
      .eq('practice_problem_id', practiceProblem.data!.id)
      .not('user_id', 'is', null)
      .eq('is_private', false),
  ]);

  if (userPracticeProblem?.error) throw userPracticeProblem.error;
  if (discussionCountError) throw discussionCountError;
  if (solutionsCountError) throw solutionsCountError;

  const preparedPracticeProblem = {
    id: practiceProblem.data.id,
    title: practiceProblem.data.title,
    description: practiceProblem.data.description,
    test_cases: {
      // @ts-ignore, JSON structure is not typed
      input_names: practiceProblem?.data?.test_cases?.input_names,
      function_name: practiceProblem.data.function_name,
      // @ts-ignore, JSON structure is not typed
      tests: practiceProblem?.data?.test_cases?.tests,
    } as TestCases,
    function_name: practiceProblem.data.function_name || '',
    screen_name: practiceProblem.data.screen_name || '',
    difficulty: practiceProblem.data.difficulty as Experience_Level_Enum,
    status: (userPracticeProblem?.data?.status ||
      User_Practice_Problem_Status_Enum.NotStarted) as User_Practice_Problem_Status_Enum,
    solution_title: userPracticeProblem?.data?.title || '',
    discussion_count: discussionCount || 0,
    solutions_count: solutionsCount || 0,
  };

  return preparedPracticeProblem;
};

interface IUseGetPracticeProblemQueryResponse {
  data?: GetPracticeProblemResponse | null;
  isPending: boolean;
}

interface IUseGetPracticeProblemQueryParams extends GetPracticeProblemParams {}

export const useGetPracticeProblemQuery = ({
  practiceProblemScreenName,
}: IUseGetPracticeProblemQueryParams): IUseGetPracticeProblemQueryResponse => {
  const { id: userId } = useCurrentUser();
  const supabase = useSupabaseTypedClient();

  const { data, isPending } = useQueryWithErrorBoundary({
    queryKey: [TanstackQueryName.GetPracticeProblem, practiceProblemScreenName],
    queryFn: () =>
      getPracticeProblemQueryFn({ supabase, userId, screenName: practiceProblemScreenName }),
  });

  if (!data) {
    return {
      data: null,
      isPending,
    };
  }

  return {
    data,
    isPending,
  };
};
