import { Box, useTheme, Stack, Center } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

import Button from 'components/Button';
import GradientTooltip from 'components/GradientTooltip';
import ImagePreviewModal from 'components/Modal/ImagePreviewModal';
import Text from 'components/Text';
import { ChallengePreview } from 'types/edgeFunctions';
import { ERoute } from 'types/frontend';

interface IChallengeDayProps {
  day: string;
}

const ChallengeDay = ({ day }: IChallengeDayProps) => {
  const { borderRadius } = useTheme();

  return (
    <GradientTooltip label="Use day reference for #100DaysOfCode, complete new challenges daily, and share your progress on social media. 👀">
      <Box backgroundColor="whiteAlpha.200" p={2} borderRadius={borderRadius} width="fit-content">
        <Text fontSize="small">{day}</Text>
      </Box>
    </GradientTooltip>
  );
};

export interface ChallengeCardProps {
  day: string;
  challenge: ChallengePreview;
}

const ChallengeCard = ({ challenge, day }: ChallengeCardProps) => {
  const navigate = useNavigate();

  const { borderRadius } = useTheme();

  const handleClick = (screenName: string) => {
    navigate(`${ERoute.Challenges}/${screenName}`);
  };

  return (
    <Box
      borderRadius={borderRadius}
      bg="whiteAlpha.200"
      onClick={() => handleClick(challenge.screen_name)}
      cursor="pointer"
      boxShadow="0px 10px 20px 0px rgba(0, 0, 0, 0.15);"
      position="relative"
    >
      <Stack spacing={0} p={4}>
        <ImagePreviewModal
          objectFit="initial"
          imageProps={{
            maxWidth: '100%',
            height: 'auto',
            htmlWidth: 1366,
            htmlHeight: 768,
            fallbackStrategy: 'onError',
            fallback: (
              <Center
                width="100%"
                height="100%"
                minHeight={170}
                background="white"
                borderRadius={borderRadius}
              >
                <Text color="black">Image not found</Text>
              </Center>
            ),
          }}
          url={challenge.screenshot_url || ''}
          title={challenge.title}
        />
        <Stack spacing={3} pt={4}>
          <Text fontSize="xLarge" fontWeight="semibold">
            {challenge.title}
          </Text>
          <ChallengeDay day={day} />
        </Stack>
      </Stack>
      <Box px={4} mt={1} pb={4}>
        <Button onClick={() => handleClick(challenge.screen_name)} fullWidth>
          Open challenge
        </Button>
      </Box>
    </Box>
  );
};

export default ChallengeCard;
