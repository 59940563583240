import { format, startOfWeek, endOfWeek, addDays, formatDistanceStrict } from 'date-fns';

const HASURA_TIMESTAMPTZ_FORMAT = 'yyyy-MM-dd';

export const getCurrentWeekRange = () =>
  `${format(startOfWeek(new Date(), { weekStartsOn: 1 }), 'MMM d')} - ${format(
    endOfWeek(new Date(), { weekStartsOn: 1 }),
    'MMM d'
  )}`;

export const getCurrentWeekStartDate = () =>
  format(startOfWeek(new Date(), { weekStartsOn: 1 }), HASURA_TIMESTAMPTZ_FORMAT);

export const getCurrentWeekEndDate = () =>
  format(addDays(endOfWeek(new Date(), { weekStartsOn: 1 }), 1), HASURA_TIMESTAMPTZ_FORMAT);

export const getTimeAgo = (date: string) =>
  `${formatDistanceStrict(new Date(), new Date(date))} ago`;

export const secondsToHms = (seconds: number) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const secs = seconds % 60;

  const formattedHours = String(hours).padStart(2, '0');
  const formattedMinutes = String(minutes).padStart(2, '0');
  const formattedSeconds = String(secs).padStart(2, '0');

  return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
};
