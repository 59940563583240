import { Box, HStack, Flex } from '@chakra-ui/react';

import Logo from 'components/Logo';
import { useMediaQueries } from 'layout/MediaQueriesProvider';

import { useDevSandbox } from './DevSandboxProvider';

const DevSandboxNavigation = () => {
  const { isSmallScreen } = useMediaQueries();

  const {
    mode,
    navigation: { leftSide, header, rightSide },
  } = useDevSandbox();

  if (mode === 'full-page') {
    return null;
  }

  return (
    <>
      <Flex
        position="relative"
        height="60px"
        alignItems="center"
        px={isSmallScreen ? 2 : 4}
        backgroundColor="gray.900"
      >
        <Box mr="auto">
          <HStack spacing={0} flexGrow={1} justifyContent="flex-start" alignItems="center">
            <Logo isClickable rocketOnly size={24} />
            {leftSide || <div />}
          </HStack>
        </Box>
        {!isSmallScreen && (
          <Box position="absolute" left="50%" transform="translateX(-50%)" textAlign="center">
            {header}
          </Box>
        )}
        <Box ml="auto">{rightSide || <div />}</Box>
      </Flex>
      <Box borderBottom="1px solid" borderColor="whiteAlpha.200" position="relative" zIndex={999} />
    </>
  );
};

export default DevSandboxNavigation;
