import { ReactComponent as AchievementFeed } from 'assets/icons/AchievementFeed.svg';
import { ReactComponent as Add } from 'assets/icons/Add.svg';
import { ReactComponent as AmEx } from 'assets/icons/AmEx.svg';
import { ReactComponent as AskForCodeReview } from 'assets/icons/AskForCodeReview.svg';
import { ReactComponent as AskForFeedback } from 'assets/icons/AskForFeedback.svg';
import { ReactComponent as AskForHelp } from 'assets/icons/AskForHelp.svg';
import { ReactComponent as BackArrow } from 'assets/icons/BackArrow.svg';
import { ReactComponent as Backend } from 'assets/icons/Backend.svg';
import { ReactComponent as BdsLogoRocket } from 'assets/icons/BdsLogoRocket.svg';
import { ReactComponent as BdsNotification } from 'assets/icons/BdsNotification.svg';
import { ReactComponent as BdsRocketOnly } from 'assets/icons/BdsRocketOnly.svg';
import { ReactComponent as BetaUserBadge } from 'assets/icons/BetaUserBadge.svg';
import { ReactComponent as Bigtokens } from 'assets/icons/Bigtokens.svg';
import { ReactComponent as Billing } from 'assets/icons/Billing.svg';
import { ReactComponent as Branch } from 'assets/icons/Branch.svg';
import { ReactComponent as Browser } from 'assets/icons/Browser.svg';
import { ReactComponent as BuildProjectStatusStep } from 'assets/icons/BuildProjectStatusStep.svg';
import { ReactComponent as Calendar } from 'assets/icons/Calendar.svg';
import { ReactComponent as CancelProjectDotsMenu } from 'assets/icons/CancelProjectDotsMenu.svg';
import { ReactComponent as CardAvatar } from 'assets/icons/CardAvatar.svg';
import { ReactComponent as CardCheck } from 'assets/icons/CardCheck.svg';
import { ReactComponent as CardCollector } from 'assets/icons/CardCollector.svg';
import { ReactComponent as CardHand } from 'assets/icons/CardHand.svg';
import { ReactComponent as Cards } from 'assets/icons/Cards.svg';
import { ReactComponent as CardsCompletedModal } from 'assets/icons/CardsCompletedModal.svg';
import { ReactComponent as CardsTab } from 'assets/icons/CardsTab.svg';
import { ReactComponent as ChallengeConqueror } from 'assets/icons/ChallengeConqueror.svg';
import { ReactComponent as ChallengeHourglass } from 'assets/icons/ChallengeHourglass.svg';
import { ReactComponent as ChallengeLock } from 'assets/icons/ChallengeLock.svg';
import { ReactComponent as ChallengeSolutionFeed } from 'assets/icons/ChallengeSolutionFeed.svg';
import { ReactComponent as ChallengesCompleted } from 'assets/icons/ChallengesCompleted.svg';
import { ReactComponent as ChatGPT } from 'assets/icons/ChatGPT.svg';
import { ReactComponent as ChatGPTTab } from 'assets/icons/ChatGPTTab.svg';
import { ReactComponent as Check } from 'assets/icons/Check.svg';
import { ReactComponent as CheckMark } from 'assets/icons/CheckMark.svg';
import { ReactComponent as CheckPricing } from 'assets/icons/CheckPricing.svg';
import { ReactComponent as Clock } from 'assets/icons/Clock.svg';
import { ReactComponent as Close } from 'assets/icons/Close.svg';
import { ReactComponent as Code } from 'assets/icons/Code.svg';
import { ReactComponent as CodeChatGpt } from 'assets/icons/CodeChatGpt.svg';
import { ReactComponent as CodeEditor } from 'assets/icons/CodeEditor.svg';
import { ReactComponent as CodeEditorAction } from 'assets/icons/CodeEditorAction.svg';
import { ReactComponent as CodeEditorResize } from 'assets/icons/CodeEditorResize.svg';
import { ReactComponent as CodeInstructions } from 'assets/icons/CodeInstructions.svg';
import { ReactComponent as CodePeople } from 'assets/icons/CodePeople.svg';
import { ReactComponent as CodePreview } from 'assets/icons/CodePreview.svg';
import { ReactComponent as CodeSave } from 'assets/icons/CodeSave.svg';
import { ReactComponent as CodeScoreEmpty } from 'assets/icons/CodeScoreEmpty.svg';
import { ReactComponent as CodeScoreNormal } from 'assets/icons/CodeScoreNormal.svg';
import { ReactComponent as CodeSupport } from 'assets/icons/CodeSupport.svg';
import { ReactComponent as ColorsPalette } from 'assets/icons/ColorsPalette.svg';
import { ReactComponent as Commits } from 'assets/icons/Commits.svg';
import { ReactComponent as Community } from 'assets/icons/Community.svg';
import { ReactComponent as CommunityQuestionFeed } from 'assets/icons/CommunityQuestionFeed.svg';
import { ReactComponent as CommunityQuestions } from 'assets/icons/CommunityQuestions.svg';
import { ReactComponent as CommunitySpeaker } from 'assets/icons/CommunitySpeaker.svg';
import { ReactComponent as CompleteIndicator } from 'assets/icons/CompleteIndicator.svg';
import { ReactComponent as CompletedProjects } from 'assets/icons/CompletedProjects.svg';
import { ReactComponent as Copy } from 'assets/icons/Copy.svg';
import { ReactComponent as CreditCard } from 'assets/icons/CreditCard.svg';
import { ReactComponent as Crosshair } from 'assets/icons/Crosshair.svg';
import { ReactComponent as Cvc } from 'assets/icons/Cvc.svg';
import { ReactComponent as Danger } from 'assets/icons/Danger.svg';
import { ReactComponent as DaybreakDebutant } from 'assets/icons/DaybreakDebutant.svg';
import { ReactComponent as DeleteModal } from 'assets/icons/DeleteModal.svg';
import { ReactComponent as DesignFonts } from 'assets/icons/DesignFonts.svg';
import { ReactComponent as DesignIcons } from 'assets/icons/DesignIcons.svg';
import { ReactComponent as DesignQuickAction } from 'assets/icons/DesignQuickAction.svg';
import { ReactComponent as DesignScoreEmpty } from 'assets/icons/DesignScoreEmpty.svg';
import { ReactComponent as DesignScoreNormal } from 'assets/icons/DesignScoreNormal.svg';
import { ReactComponent as DesignScreenshot } from 'assets/icons/DesignScreenshot.svg';
import { ReactComponent as DesignShortcut } from 'assets/icons/DesignShortcut.svg';
import { ReactComponent as DesignShortcutActive } from 'assets/icons/DesignShortcutActive.svg';
import { ReactComponent as DevSandboxAssets } from 'assets/icons/DevSandboxAssets.svg';
import { ReactComponent as Devto } from 'assets/icons/Devto.svg';
import { ReactComponent as Discord } from 'assets/icons/Discord.svg';
import { ReactComponent as DiscordDisconnected } from 'assets/icons/DiscordDisconnected.svg';
import { ReactComponent as DiscordGray } from 'assets/icons/DiscordGray.svg';
import { ReactComponent as DiscordGrayDotsMenu } from 'assets/icons/DiscordGrayDotsMenu.svg';
import { ReactComponent as DiscordRound } from 'assets/icons/DiscordRound.svg';
import { ReactComponent as DiscordRoundModal } from 'assets/icons/DiscordRoundModal.svg';
import { ReactComponent as DiscordViolet } from 'assets/icons/DiscordViolet.svg';
import { ReactComponent as DiscordWhite } from 'assets/icons/DiscordWhite.svg';
import { ReactComponent as Discussion } from 'assets/icons/Discussion.svg';
import { ReactComponent as DocumentationQuickAction } from 'assets/icons/DocumentationQuickAction.svg';
import { ReactComponent as DotsMenu } from 'assets/icons/DotsMenu.svg';
import { ReactComponent as DrawerOpen } from 'assets/icons/DrawerOpen.svg';
import { ReactComponent as Dropdown } from 'assets/icons/Dropdown.svg';
import { ReactComponent as EarlyBird } from 'assets/icons/EarlyBird.svg';
import { ReactComponent as Easy } from 'assets/icons/Easy.svg';
import { ReactComponent as Email } from 'assets/icons/Email.svg';
import { ReactComponent as EmailShare } from 'assets/icons/EmailShare.svg';
import { ReactComponent as Experience } from 'assets/icons/Experience.svg';
import { ReactComponent as ExperienceBubble } from 'assets/icons/ExperienceBubble.svg';
import { ReactComponent as ExpiryDate } from 'assets/icons/ExpiryDate.svg';
import { ReactComponent as FacebookShare } from 'assets/icons/FacebookShare.svg';
import { ReactComponent as FigmaLogo } from 'assets/icons/FigmaLogo.svg';
import { ReactComponent as FigmaQuickAction } from 'assets/icons/FigmaQuickAction.svg';
import { ReactComponent as FirstCard } from 'assets/icons/FirstCard.svg';
import { ReactComponent as FirstCommit } from 'assets/icons/FirstCommit.svg';
import { ReactComponent as Flag } from 'assets/icons/Flag.svg';
import { ReactComponent as ForkCode } from 'assets/icons/ForkCode.svg';
import { ReactComponent as FreePlan } from 'assets/icons/FreePlan.svg';
import { ReactComponent as Freerun } from 'assets/icons/Freerun.svg';
import { ReactComponent as Frontend } from 'assets/icons/Frontend.svg';
import { ReactComponent as Fullscreen } from 'assets/icons/Fullscreen.svg';
import { ReactComponent as GenerateCodeSolution } from 'assets/icons/GenerateCodeSolution.svg';
import { ReactComponent as GitHubEditorAction } from 'assets/icons/GitHubEditorAction.svg';
import { ReactComponent as Github } from 'assets/icons/Github.svg';
import { ReactComponent as GithubProjectStatusStep } from 'assets/icons/GithubProjectStatusStep.svg';
import { ReactComponent as Glitch } from 'assets/icons/Glitch.svg';
import { ReactComponent as GlitchEditorAction } from 'assets/icons/GlitchEditorAction.svg';
import { ReactComponent as Guidelines } from 'assets/icons/Guidelines.svg';
import { ReactComponent as Hard } from 'assets/icons/Hard.svg';
import { ReactComponent as HowToStart } from 'assets/icons/HowToStart.svg';
import { ReactComponent as Implementation } from 'assets/icons/Implementation.svg';
import { ReactComponent as InProgressIndicator } from 'assets/icons/InProgressIndicator.svg';
import { ReactComponent as Infinity } from 'assets/icons/Infinity.svg';
import { ReactComponent as InstructionsAcceptanceCriteria } from 'assets/icons/InstructionsAcceptanceCriteria.svg';
import { ReactComponent as InstructionsDesign } from 'assets/icons/InstructionsDesign.svg';
import { ReactComponent as InstructionsUserStory } from 'assets/icons/InstructionsUserStory.svg';
import { ReactComponent as InviteFriends } from 'assets/icons/InviteFriends.svg';
import { ReactComponent as InviteFriendsModal } from 'assets/icons/InviteFriendsModal.svg';
import { ReactComponent as Junior } from 'assets/icons/Junior.svg';
import { ReactComponent as KnowledgeBase } from 'assets/icons/KnowledgeBase.svg';
import { ReactComponent as LifetimeBadge } from 'assets/icons/LifetimeBadge.svg';
import { ReactComponent as LifetimePlan } from 'assets/icons/LifetimePlan.svg';
import { ReactComponent as LightMode } from 'assets/icons/LightMode.svg';
import { ReactComponent as Lightning } from 'assets/icons/Lightning.svg';
import { ReactComponent as Like } from 'assets/icons/Like.svg';
import { ReactComponent as LikeFeed } from 'assets/icons/LikeFeed.svg';
import { ReactComponent as LikeHeart } from 'assets/icons/LikeHeart.svg';
import { ReactComponent as LikeMagnet } from 'assets/icons/LikeMagnet.svg';
import { ReactComponent as Linkedin } from 'assets/icons/Linkedin.svg';
import { ReactComponent as LinkedinShare } from 'assets/icons/LinkedinShare.svg';
import { ReactComponent as Location } from 'assets/icons/Location.svg';
import { ReactComponent as Lock } from 'assets/icons/Lock.svg';
import { ReactComponent as Logo } from 'assets/icons/Logo.svg';
import { ReactComponent as LoyalLearner } from 'assets/icons/LoyalLearner.svg';
import { ReactComponent as MasterCard } from 'assets/icons/MasterCard.svg';
import { ReactComponent as MasterOfModes } from 'assets/icons/MasterOfModes.svg';
import { ReactComponent as Medium } from 'assets/icons/Medium.svg';
import { ReactComponent as Minus } from 'assets/icons/Minus.svg';
import { ReactComponent as MobileNavigation } from 'assets/icons/MobileNavigation.svg';
import { ReactComponent as ModeProject } from 'assets/icons/ModeProject.svg';
import { ReactComponent as MoneyBag } from 'assets/icons/MoneyBag.svg';
import { ReactComponent as MonthlyPlan } from 'assets/icons/MonthlyPlan.svg';
import { ReactComponent as MonthlySubscriberBadge } from 'assets/icons/MonthlySubscriberBadge.svg';
import { ReactComponent as NewAttemptDotsMenu } from 'assets/icons/NewAttemptDotsMenu.svg';
import { ReactComponent as NewBadge } from 'assets/icons/NewBadge.svg';
import { ReactComponent as NotificationBell } from 'assets/icons/NotificationBell.svg';
import { ReactComponent as Onboarding } from 'assets/icons/Onboarding.svg';
import { ReactComponent as OnboardingCompleted } from 'assets/icons/OnboardingCompleted.svg';
import { ReactComponent as OneManArmy } from 'assets/icons/OneManArmy.svg';
import { ReactComponent as OutsideLink } from 'assets/icons/OutsideLink.svg';
import { ReactComponent as OutsideLinkDark } from 'assets/icons/OutsideLinkDark.svg';
import { ReactComponent as PaneAction } from 'assets/icons/PaneAction.svg';
import { ReactComponent as PaneCodeControl } from 'assets/icons/PaneCodeControl.svg';
import { ReactComponent as PanePreviewControl } from 'assets/icons/PanePreviewControl.svg';
import { ReactComponent as PanePreviewOpenControl } from 'assets/icons/PanePreviewOpenControl.svg';
import { ReactComponent as PayPal } from 'assets/icons/PayPal.svg';
import { ReactComponent as PayPalGray } from 'assets/icons/PayPalGray.svg';
import { ReactComponent as PencilEdit } from 'assets/icons/PencilEdit.svg';
import { ReactComponent as PlanChange } from 'assets/icons/PlanChange.svg';
import { ReactComponent as Play } from 'assets/icons/Play.svg';
import { ReactComponent as Polygon } from 'assets/icons/Polygon.svg';
import { ReactComponent as Preview } from 'assets/icons/Preview.svg';
import { ReactComponent as PreviewCopy } from 'assets/icons/PreviewCopy.svg';
import { ReactComponent as PreviewNewTab } from 'assets/icons/PreviewNewTab.svg';
import { ReactComponent as PreviewRefresh } from 'assets/icons/PreviewRefresh.svg';
import { ReactComponent as PricingCheck } from 'assets/icons/PricingCheck.svg';
import { ReactComponent as PricingTooltip } from 'assets/icons/PricingTooltip.svg';
import { ReactComponent as PrivateCodeLock } from 'assets/icons/PrivateCodeLock.svg';
import { ReactComponent as Profile } from 'assets/icons/Profile.svg';
import { ReactComponent as ProjectPage } from 'assets/icons/ProjectPage.svg';
import { ReactComponent as ProjectStartFeed } from 'assets/icons/ProjectStartFeed.svg';
import { ReactComponent as PublicCodeLock } from 'assets/icons/PublicCodeLock.svg';
import { ReactComponent as PullRequest } from 'assets/icons/PullRequest.svg';
import { ReactComponent as QuickAction } from 'assets/icons/QuickAction.svg';
import { ReactComponent as RankingPlace } from 'assets/icons/RankingPlace.svg';
import { ReactComponent as RedditShare } from 'assets/icons/RedditShare.svg';
import { ReactComponent as Regular } from 'assets/icons/Regular.svg';
import { ReactComponent as Replit } from 'assets/icons/Replit.svg';
import { ReactComponent as Requirements } from 'assets/icons/Requirements.svg';
import { ReactComponent as Research } from 'assets/icons/Research.svg';
import { ReactComponent as Resize } from 'assets/icons/Resize.svg';
import { ReactComponent as RestartProject } from 'assets/icons/RestartProject.svg';
import { ReactComponent as RestartProjectDotsMenu } from 'assets/icons/RestartProjectDotsMenu.svg';
import { ReactComponent as Review } from 'assets/icons/Review.svg';
import { ReactComponent as RewardProject } from 'assets/icons/RewardProject.svg';
import { ReactComponent as Rocket } from 'assets/icons/Rocket.svg';
import { ReactComponent as Send } from 'assets/icons/Send.svg';
import { ReactComponent as SendMessage } from 'assets/icons/SendMessage.svg';
import { ReactComponent as Senior } from 'assets/icons/Senior.svg';
import { ReactComponent as Settings } from 'assets/icons/Settings.svg';
import { ReactComponent as Share } from 'assets/icons/Share.svg';
import { ReactComponent as SidebarChallenges } from 'assets/icons/SidebarChallenges.svg';
import { ReactComponent as SidebarCodeFrames } from 'assets/icons/SidebarCodeFrames.svg';
import { ReactComponent as SidebarCommunityDiscord } from 'assets/icons/SidebarCommunityDiscord.svg';
import { ReactComponent as SidebarDashboard } from 'assets/icons/SidebarDashboard.svg';
import { ReactComponent as SidebarFacebook } from 'assets/icons/SidebarFacebook.svg';
import { ReactComponent as SidebarHamburgerMenu } from 'assets/icons/SidebarHamburgerMenu.svg';
import { ReactComponent as SidebarInstagram } from 'assets/icons/SidebarInstagram.svg';
import { ReactComponent as SidebarLearningPaths } from 'assets/icons/SidebarLearningPaths.svg';
import { ReactComponent as SidebarLinkedin } from 'assets/icons/SidebarLinkedin.svg';
import { ReactComponent as SidebarLockedIcon } from 'assets/icons/SidebarLockedIcon.svg';
import { ReactComponent as SidebarPracticeProblems } from 'assets/icons/SidebarPracticeProblems.svg';
import { ReactComponent as SidebarProjects } from 'assets/icons/SidebarProjects.svg';
import { ReactComponent as SidebarPrototypes } from 'assets/icons/SidebarPrototypes.svg';
import { ReactComponent as SidebarTikTok } from 'assets/icons/SidebarTikTok.svg';
import { ReactComponent as SidebarUpgradePlan } from 'assets/icons/SidebarUpgradePlan.svg';
import { ReactComponent as SidebarYouTube } from 'assets/icons/SidebarYouTube.svg';
import { ReactComponent as SignOut } from 'assets/icons/SignOut.svg';
import { ReactComponent as SocialButterfly } from 'assets/icons/SocialButterfly.svg';
import { ReactComponent as SolutionFeed } from 'assets/icons/SolutionFeed.svg';
import { ReactComponent as SolutionsTab } from 'assets/icons/SolutionsTab.svg';
import { ReactComponent as Speedrun } from 'assets/icons/Speedrun.svg';
import { ReactComponent as Start } from 'assets/icons/Start.svg';
import { ReactComponent as StartAgain } from 'assets/icons/StartAgain.svg';
import { ReactComponent as StartProjectModal } from 'assets/icons/StartProjectModal.svg';
import { ReactComponent as StarterFilesQuickAction } from 'assets/icons/StarterFilesQuickAction.svg';
import { ReactComponent as StatusBadgeSuccess } from 'assets/icons/StatusBadgeSuccess.svg';
import { ReactComponent as StatusCompleted } from 'assets/icons/StatusCompleted.svg';
import { ReactComponent as StatusInProgress } from 'assets/icons/StatusInProgress.svg';
import { ReactComponent as StatusNotStarted } from 'assets/icons/StatusNotStarted.svg';
import { ReactComponent as StatusProject } from 'assets/icons/StatusProject.svg';
import { ReactComponent as StuckChatGPT } from 'assets/icons/StuckChatGPT.svg';
import { ReactComponent as StuckDiscord } from 'assets/icons/StuckDiscord.svg';
import { ReactComponent as StuckPeople } from 'assets/icons/StuckPeople.svg';
import { ReactComponent as Submit } from 'assets/icons/Submit.svg';
import { ReactComponent as SubmitCheck } from 'assets/icons/SubmitCheck.svg';
import { ReactComponent as SubmitCheckFailed } from 'assets/icons/SubmitCheckFailed.svg';
import { ReactComponent as SubmitCheckPassed } from 'assets/icons/SubmitCheckPassed.svg';
import { ReactComponent as SubmitCode } from 'assets/icons/SubmitCode.svg';
import { ReactComponent as SubmitProject } from 'assets/icons/SubmitProject.svg';
import { ReactComponent as SubmitProjectStatusStep } from 'assets/icons/SubmitProjectStatusStep.svg';
import { ReactComponent as SubmitSolutionDotsMenu } from 'assets/icons/SubmitSolutionDotsMenu.svg';
import { ReactComponent as SubscriptionFeed } from 'assets/icons/SubscriptionFeed.svg';
import { ReactComponent as Summary } from 'assets/icons/Summary.svg';
import { ReactComponent as Support } from 'assets/icons/Support.svg';
import { ReactComponent as TechTitan } from 'assets/icons/TechTitan.svg';
import { ReactComponent as Technologies } from 'assets/icons/Technologies.svg';
import { ReactComponent as TelegramShare } from 'assets/icons/TelegramShare.svg';
import { ReactComponent as Timer } from 'assets/icons/Timer.svg';
import { ReactComponent as Token } from 'assets/icons/Token.svg';
import { ReactComponent as TokenBagShine } from 'assets/icons/TokenBagShine.svg';
import { ReactComponent as Tooltip } from 'assets/icons/Tooltip.svg';
import { ReactComponent as Trailblazer } from 'assets/icons/Trailblazer.svg';
import { ReactComponent as Trash } from 'assets/icons/Trash.svg';
import { ReactComponent as TriathlonFinisher } from 'assets/icons/TriathlonFinisher.svg';
import { ReactComponent as Twitter } from 'assets/icons/Twitter.svg';
import { ReactComponent as TwitterShare } from 'assets/icons/TwitterShare.svg';
import { ReactComponent as UnionPay } from 'assets/icons/UnionPay.svg';
import { ReactComponent as UpgradeBig } from 'assets/icons/UpgradeBig.svg';
import { ReactComponent as UpgradePlan } from 'assets/icons/UpgradePlan.svg';
import { ReactComponent as UploadImage } from 'assets/icons/UploadImage.svg';
import { ReactComponent as ViewCode } from 'assets/icons/ViewCode.svg';
import { ReactComponent as Visa } from 'assets/icons/Visa.svg';
import { ReactComponent as Warning } from 'assets/icons/Warning.svg';
import { ReactComponent as WarningModal } from 'assets/icons/WarningModal.svg';
import { ReactComponent as WarningTwo } from 'assets/icons/WarningTwo.svg';
import { ReactComponent as Website } from 'assets/icons/Website.svg';
import { ReactComponent as WhatsAppShare } from 'assets/icons/WhatsAppShare.svg';
import { ReactComponent as WrongCardModal } from 'assets/icons/WrongCardModal.svg';
import { ReactComponent as YearlyPlan } from 'assets/icons/YearlyPlan.svg';
import { ReactComponent as YearlySubscriberBadge } from 'assets/icons/YearlySubscriberBadge.svg';
import { ReactComponent as ZipCode } from 'assets/icons/ZipCode.svg';

import GradientTooltip from './GradientTooltip';

const icons = {
  BdsRocketOnly,
  BdsNotification,
  SidebarPracticeProblems,
  SidebarLearningPaths,
  SidebarLockedIcon,
  SidebarDashboard,
  SidebarChallenges,
  SidebarProjects,
  SidebarPrototypes,
  SidebarCodeFrames,
  CodeSave,
  SidebarCommunityDiscord,
  SidebarUpgradePlan,
  SidebarHamburgerMenu,
  SidebarFacebook,
  SidebarInstagram,
  PublicCodeLock,
  PrivateCodeLock,
  SidebarTikTok,
  SidebarYouTube,
  ChallengeConqueror,
  UploadImage,
  DaybreakDebutant,
  ChallengeSolutionFeed,
  ChallengesCompleted,
  WarningModal,
  CodeEditorResize,
  WrongCardModal,
  ColorsPalette,
  CardsCompletedModal,
  PaneCodeControl,
  PanePreviewControl,
  PanePreviewOpenControl,
  DesignShortcut,
  DesignShortcutActive,
  FigmaQuickAction,
  DocumentationQuickAction,
  DesignQuickAction,
  StarterFilesQuickAction,
  StuckDiscord,
  StuckChatGPT,
  StuckPeople,
  CodeInstructions,
  Browser,
  SendMessage,
  Discussion,
  PricingCheck,
  PricingTooltip,
  InstructionsDesign,
  DrawerOpen,
  InstructionsUserStory,
  InstructionsAcceptanceCriteria,
  CodeEditorAction,
  GitHubEditorAction,
  GlitchEditorAction,
  PaneAction,
  DesignFonts,
  DesignIcons,
  QuickAction,
  PreviewCopy,
  PreviewNewTab,
  PreviewRefresh,
  CodeChatGpt,
  CodeSupport,
  DevSandboxAssets,
  DesignScreenshot,
  CodePreview,
  CodePeople,
  Logo,
  Code,
  Cards,
  CodeEditor,
  Bigtokens,
  Branch,
  BdsLogoRocket,
  Lock,
  Play,
  SolutionsTab,
  Summary,
  SubmitCode,
  GenerateCodeSolution,
  AchievementFeed,
  CommunityQuestionFeed,
  LikeFeed,
  ProjectStartFeed,
  SolutionFeed,
  SubscriptionFeed,
  Junior,
  Regular,
  Senior,
  Backend,
  Frontend,
  Research,
  CardsTab,
  Preview,
  PullRequest,
  Share,
  StartProjectModal,
  Lightning,
  Clock,
  ModeProject,
  RewardProject,
  StatusProject,
  WhatsAppShare,
  TwitterShare,
  TelegramShare,
  RedditShare,
  LinkedinShare,
  SidebarLinkedin,
  FacebookShare,
  EmailShare,
  ChatGPT,
  ChatGPTTab,
  PencilEdit,
  MonthlyPlan,
  Send,
  YearlyPlan,
  Technologies,
  UpgradeBig,
  UpgradePlan,
  Community,
  CommunityQuestions,
  NewBadge,
  CodeScoreEmpty,
  CodeScoreNormal,
  DesignScoreEmpty,
  DesignScoreNormal,
  CardAvatar,
  Copy,
  CreditCard,
  ChallengeLock,
  ForkCode,
  Cvc,
  ExpiryDate,
  Support,
  Check,
  CompletedProjects,
  OnboardingCompleted,
  Timer,
  BackArrow,
  ZipCode,
  Flag,
  LikeHeart,
  RankingPlace,
  LightMode,
  LifetimePlan,
  StatusBadgeSuccess,
  ViewCode,
  MobileNavigation,
  Dropdown,
  Profile,
  Rocket,
  Like,
  Start,
  Billing,
  Settings,
  Close,
  CheckPricing,
  Crosshair,
  Experience,
  ExperienceBubble,
  SignOut,
  Email,
  Website,
  Github,
  Linkedin,
  InProgressIndicator,
  InviteFriends,
  InviteFriendsModal,
  CompleteIndicator,
  Devto,
  Glitch,
  Replit,
  MoneyBag,
  Twitter,
  Location,
  Onboarding,
  CardHand,
  PlanChange,
  KnowledgeBase,
  DiscordRound,
  DiscordRoundModal,
  DiscordGray,
  Easy,
  Medium,
  Hard,
  FigmaLogo,
  Resize,
  Fullscreen,
  Infinity,
  GithubProjectStatusStep,
  BuildProjectStatusStep,
  SubmitProjectStatusStep,
  Commits,
  Warning,
  WarningTwo,
  AmEx,
  Visa,
  MasterCard,
  UnionPay,
  PayPal,
  Polygon,
  PayPalGray,
  Danger,
  FreePlan,
  EarlyBird,
  Discord,
  DiscordWhite,
  DiscordViolet,
  OutsideLink,
  OutsideLinkDark,
  CheckMark,
  Tooltip,
  Trash,
  ProjectPage,
  DiscordDisconnected,
  AskForHelp,
  AskForCodeReview,
  AskForFeedback,
  ChallengeHourglass,
  StartAgain,
  RestartProject,
  DeleteModal,
  Calendar,
  BetaUserBadge,
  MonthlySubscriberBadge,
  YearlySubscriberBadge,
  SubmitCheck,
  SubmitCheckPassed,
  SubmitCheckFailed,
  Guidelines,
  HowToStart,
  Implementation,
  Review,
  Requirements,
  SubmitProject,
  Submit,
  DotsMenu,
  CancelProjectDotsMenu,
  DiscordGrayDotsMenu,
  RestartProjectDotsMenu,
  SubmitSolutionDotsMenu,
  NewAttemptDotsMenu,
  Speedrun,
  Freerun,
  FirstCard,
  FirstCommit,
  CardCollector,
  CommunitySpeaker,
  LikeMagnet,
  LoyalLearner,
  MasterOfModes,
  OneManArmy,
  SocialButterfly,
  TechTitan,
  Trailblazer,
  TriathlonFinisher,
  CardCheck,
  LifetimeBadge,
  Add,
  Minus,
  TokenBagShine,
  Token,
  StatusNotStarted,
  StatusInProgress,
  StatusCompleted,
  NotificationBell,
} as const;

export type IconType = keyof typeof icons;

export interface IconProps {
  type: IconType;
  size?: number;
  opacity?: number;
  label?: string;
  display?: 'block' | 'inline' | 'inline-block';
  onClick?: () => void;
  verticalAlign?: 'middle';
}

const Icon = ({ type, size, opacity, label, display, verticalAlign, onClick }: IconProps) => {
  if (!type) {
    return null;
  }

  const IconComponent = icons[type];

  const icon = (
    <IconComponent
      {...(size && {
        width: `${size}px`,
        height: `${size}px`,
      })}
      opacity={opacity}
      onClick={() => onClick?.()}
      style={{ display, verticalAlign }}
    />
  );

  if (label) {
    return <GradientTooltip label={label}>{icon}</GradientTooltip>;
  }

  return icon;
};

export default Icon;
