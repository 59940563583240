import { Box, HStack, Spinner, Stack, VStack, Wrap, WrapItem } from '@chakra-ui/react';
import { useGetTokensQuery } from 'hooks/queries/billing/useGetTokens';

import { useCurrentUser } from 'auth/CurrentUserProvider';
import Icon, { IconType } from 'components/Icon';
import Link from 'components/Link';
import Modal from 'components/Modal';
import Text from 'components/Text';
import { TokenPackage } from 'components/TokenPackage';
import { ERoute, IModal } from 'types/frontend';

interface ITokensModalProps extends IModal {}

export const TokensModal = ({ isOpen, onClose }: ITokensModalProps) => {
  const { data, isPending } = useGetTokensQuery();
  const { isMonthlySubscriber, isYearlySubscriber, isLifetimeUser } = useCurrentUser();

  const isSubscriber = isMonthlySubscriber || isYearlySubscriber || isLifetimeUser;

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="2xl"
      header={
        <HStack>
          <Box bg="purple.200" p={1} borderRadius={20}>
            <Icon type="Bigtokens" size={24} />
          </Box>
          <Text fontSize="xLarge" fontWeight="semibold">
            BigTokens Boost
          </Text>
        </HStack>
      }
      footer={<></>}
    >
      {isPending && <Spinner />}
      {!isPending && (
        <VStack spacing={6} maxH="85vh">
          <Text fontWeight="regular">
            Use Cases for BigTokens: {!isSubscriber && 'Download Figma designs,'} generate AI code
            solutions for projects, challenges, and practice problems. On top of that, you can
            generate detailed design & code reports, and create new prototypes.
          </Text>
          <Wrap spacing={6} mt={2} justify="center">
            {data.map((token) => (
              <WrapItem key={token.order_id} w="280px">
                <TokenPackage
                  id={token.id}
                  tokens={token.tokens}
                  price={token.price}
                  isBestPrice={token.is_best_value}
                  icon={token.icon as IconType}
                />
              </WrapItem>
            ))}
          </Wrap>
          {!isSubscriber && (
            <Stack alignSelf="start" direction={['column', 'column', 'row', 'row']}>
              <Text fontWeight="regular">Want to subscribe instead?</Text>
              <Link to={ERoute.Pricing} onClick={onClose}>
                <Text textColor="purple.200">Check out our BIG Plans.</Text>
              </Link>
            </Stack>
          )}
        </VStack>
      )}
    </Modal>
  );
};
