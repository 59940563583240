import { useToastWrapper } from 'hooks/useToastWrapper';
import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import PageLoader from 'components/PageLoader';
import { useBilling } from 'features/billing/context/BillingProvider';
import { ERoute } from 'types/frontend';

const StripeCallback = () => {
  const { toastInfo } = useToastWrapper();
  const navigate = useNavigate();
  const { cacheTimestamp } = useBilling();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const type = searchParams.get('type');
    const path = searchParams.get('path');

    if (type === 'subscription') {
      cacheTimestamp();
    }

    if (type === 'change') {
      toastInfo('Payment method change attempt registered');
    } else {
      toastInfo('Payment registered. Changes will be applied in a few minutes.');
    }

    if (path) {
      navigate(path);
    } else {
      navigate(ERoute.Billing);
    }
  }, []);

  return <PageLoader shift />;
};

export default StripeCallback;
