import { Box, HStack, useTheme, Stack, Center } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

import Tags from 'components/Tags';
import Text from 'components/Text';
import { ProjectPreview } from 'types/edgeFunctions';
import { ERoute } from 'types/frontend';

import Button from './Button';
import ImagePreviewModal from './Modal/ImagePreviewModal';
import NewBadge from './NewBadge';
import ProjectDifficultyIndicator from './ProjectDifficultyIndicator';
import ProjectElement from './ProjectElement';

export interface ProjectCardProps {
  project: ProjectPreview;
  showLessTags?: boolean;
}

const ProjectCard = ({ project, showLessTags }: ProjectCardProps) => {
  const navigate = useNavigate();
  const { borderRadius } = useTheme();

  const handleClick = () => {
    navigate(`${ERoute.Projects}/${project.screen_name}`);
  };

  return (
    <Box
      borderRadius={borderRadius}
      bg="whiteAlpha.200"
      onClick={handleClick}
      cursor="pointer"
      boxShadow="0px 10px 20px 0px rgba(0, 0, 0, 0.15);"
      position="relative"
    >
      <Stack spacing={0} p={4}>
        <ImagePreviewModal
          url={project.horizontal_thumbnail.url}
          title={project.title}
          objectFit="initial"
          imageProps={{
            maxWidth: '100%',
            height: 'auto',
            htmlWidth: 1366,
            htmlHeight: 768,
            fallbackStrategy: 'onError',
            fallback: (
              <Center
                width="100%"
                height="100%"
                minHeight={170}
                background="white"
                borderRadius={borderRadius}
              >
                <Text color="black">Image not found</Text>
              </Center>
            ),
          }}
        />
        <HStack position="absolute" right={6} top={6}>
          {project.is_new && <NewBadge />}
          <ProjectElement type="cards" cards={project.card.length} />
        </HStack>
        <Stack spacing={3} pt={4}>
          <Tags tags={project.tags} max={showLessTags ? 3 : undefined} />
          <Text fontSize="xLarge" fontWeight="semibold">
            {project.title}
          </Text>
          <Text fontSize="small" fontWeight="regular" minH="42px">
            {project.description}
          </Text>
          <ProjectDifficultyIndicator difficulty={project.difficulty_lvl} />
        </Stack>
      </Stack>
      <Box px={4} mt={1} mb={4}>
        <Button onClick={handleClick} fullWidth>
          Open project
        </Button>
      </Box>
    </Box>
  );
};

export default ProjectCard;
