import { SupabaseClient } from '@supabase/supabase-js';
import { useQueryWithErrorBoundary, useSupabaseTypedClient } from 'hooks/reactQuery';

import { useCurrentUser } from 'auth/CurrentUserProvider';
import { OtherTokenReward } from 'types/customEnums';
import { User_Challenge_Status_Enum } from 'types/databaseEnums';
import { GetChallengeResponse, GetChallengeParams, Challenge } from 'types/edgeFunctions';
import { TanstackQueryName } from 'types/frontend';
import { Database } from 'types/supabase';

interface IGetChallengeQueryFnParams {
  supabase: SupabaseClient<Database>;
  userId: string;
  screenName: string;
}

export const getChallengeQueryFn = async ({
  supabase,
  userId,
  screenName,
}: IGetChallengeQueryFnParams): Promise<Challenge> => {
  const challenge = await supabase
    .from('challenge')
    .select('id, screen_name, title, screenshot_url')
    .eq('screen_name', screenName)
    .single();

  if (challenge.error) throw challenge.error;

  const [
    userChallenges,
    { count: discussionCount, error: discussionCountError },
    { count: solutionsCount, error: solutionsCountError },
    { count: downloadedFigmaCount, error: downloadedFigmaError },
  ] = await Promise.all([
    userId
      ? // Avoid race conditions and only get completed challenges as previously they could be in progress
        supabase
          .from('user_challenge')
          .select('id, title, status_type, repository_url, preview_url, completed_date')
          .eq('user_id', userId)
          .eq('challenge_id', challenge.data!.id)
          .eq('status_type', User_Challenge_Status_Enum.Completed)
          .order('created_at', { ascending: false })
      : Promise.resolve(undefined),
    supabase
      .from('support_center')
      .select('*', { count: 'exact', head: true })
      .eq('entity_id', challenge.data!.id)
      .not('user_id', 'is', null),
    supabase
      .from('user_challenge')
      .select('*', { count: 'exact', head: true })
      .eq('status_type', User_Challenge_Status_Enum.Completed)
      .eq('challenge_id', challenge.data!.id)
      .not('user_id', 'is', null)
      .eq('is_private', false),
    supabase
      .from('user_action')
      .select('*', { count: 'exact', head: true })
      .eq('user_id', userId || '6ea22261-9854-4d1c-826b-35321d9504f3')
      .eq('entity_id', challenge.data.id)
      .eq('token_reward_id', OtherTokenReward.DownloadChallengeFigma),
  ]);

  if (userChallenges?.error) throw userChallenges.error;
  if (discussionCountError) throw discussionCountError;
  if (solutionsCountError) throw solutionsCountError;
  if (downloadedFigmaError) throw downloadedFigmaError;

  const userChallenge = userChallenges?.data[0];

  const preparedChallenge: Challenge = {
    id: challenge.data.id,
    title: challenge.data.title,
    screen_name: challenge.data.screen_name || '',
    status: (userChallenge?.status_type ||
      User_Challenge_Status_Enum.Started) as User_Challenge_Status_Enum,
    repository_url: userChallenge?.repository_url || '',
    preview_url: userChallenge?.preview_url || '',
    solution_title: userChallenge?.title || '',
    screenshot_url: challenge.data.screenshot_url || '',
    discussion_count: discussionCount || 0,
    solutions_count: solutionsCount || 0,
    completed_date: userChallenge?.completed_date || null,
    is_figma_downloaded: (downloadedFigmaCount || 0) > 0,
  };

  return preparedChallenge;
};

interface IUseGetChallengeQueryResponse {
  data?: GetChallengeResponse | null;
  isPending: boolean;
}

interface IUseGetChallengeQueryParams extends GetChallengeParams {}

export const useGetChallengeQuery = ({
  challengeScreenName,
}: IUseGetChallengeQueryParams): IUseGetChallengeQueryResponse => {
  const { id: userId } = useCurrentUser();
  const supabase = useSupabaseTypedClient();

  const { data, isPending } = useQueryWithErrorBoundary({
    queryKey: [TanstackQueryName.GetChallenge, challengeScreenName],
    queryFn: () => getChallengeQueryFn({ supabase, userId, screenName: challengeScreenName }),
  });

  if (!data) {
    return {
      data: null,
      isPending,
    };
  }

  return {
    data,
    isPending,
  };
};
