import { SupabaseClient } from '@supabase/supabase-js';
import { useQueryWithErrorBoundary, useSupabaseTypedClient } from 'hooks/reactQuery';

import { useCurrentUser } from 'auth/CurrentUserProvider';
import { Project_State_Enum, User_Project_Status_Enum } from 'types/databaseEnums';
import { GetProjectsResponse, ProjectPreview } from 'types/edgeFunctions';
import { TanstackQueryName } from 'types/frontend';
import { Database } from 'types/supabase';

import { mapToProjectPreview } from './mapToProjectPreview';

interface IGetProjectsQueryFnParams {
  supabase: SupabaseClient<Database>;
  userId?: string;
}

export const getProjectsQueryFn = async ({ supabase, userId }: IGetProjectsQueryFnParams) => {
  const projects = await supabase
    .from('project')
    .select(
      'id, version, screen_name, difficulty_lvl, title, description, created_at, project_tag (id, tag_type), project_image (id, sequence, title, url, image_type), card (id)'
    )
    .eq('state', Project_State_Enum.Published)
    .order('created_at', { ascending: false });

  if (projects.error) throw projects.error;

  const allProjects = await Promise.all(
    projects.data.map(async (project): Promise<ProjectPreview> => {
      const userProjects = userId
        ? // In the past we allowed more than 1 project and can't do maybeSingle() here.
          await supabase
            .from('user_project')
            .select('id, status')
            .eq('user_id', userId || '')
            .eq('project_id', project.id)
            .eq('project_version', project.version)
            .eq('status', User_Project_Status_Enum.Completed)
            .order('created_at', { ascending: false })
        : null;

      if (userProjects?.error) throw userProjects?.error;

      const mappedProject = mapToProjectPreview(project);

      const selectedUserProject = userProjects?.data[0];

      return {
        ...mappedProject,
        recent_users: [],
        users_completed_count: 0,
        status: (selectedUserProject?.status ||
          User_Project_Status_Enum.NotStarted) as User_Project_Status_Enum,
      };
    })
  );

  return allProjects;
};

interface IUseGetProjectsResponse {
  data: GetProjectsResponse | undefined | null;
  isPending: boolean;
}

export const useGetProjectsQuery = (): IUseGetProjectsResponse => {
  const { id: userId } = useCurrentUser();
  const supabase = useSupabaseTypedClient();

  const { data, isPending } = useQueryWithErrorBoundary({
    queryKey: [TanstackQueryName.GetProjects, userId],
    queryFn: () => getProjectsQueryFn({ supabase, userId }),
  });

  if (!data) {
    return {
      data: null,
      isPending,
    };
  }

  return {
    data,
    isPending,
  };
};
