import {
  Box,
  Divider,
  useTheme,
  InputGroup,
  Input,
  Stack,
  FormControl,
  FormLabel,
  FormErrorMessage,
  InputLeftAddon,
  HStack,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { supabaseMutationFn, useSupabaseTypedClient } from 'hooks/reactQuery';
import { useToastWrapper } from 'hooks/useToastWrapper';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { useCurrentUser } from 'auth/CurrentUserProvider';
import Button from 'components/Button';
import Icon from 'components/Icon';
import Link from 'components/Link';
import Text from 'components/Text';
import { EdgeFunctionName, UpdateUserProfileParams, UserProfile } from 'types/edgeFunctions';
import { TanstackQueryName } from 'types/frontend';

interface OnlinePresenceForm {
  githubUrl?: string;
  linkedin_url?: string;
  devto_url?: string;
  twitter_url?: string;
}

interface OnlinePresenceProps {
  initialValues: Pick<UserProfile, 'github_url' | 'linkedin_url' | 'devto_url' | 'twitter_url'>;
}

const validationSchema = yup
  .object({
    devto_url: yup.string().max(255, 'Dev.to username is too long (maximum is 255 characters)'),
    twitter_url: yup.string().max(255, 'Twitter username is too long (maximum is 255 characters)'),
    linkedin_url: yup
      .string()
      .max(255, 'LinkedIn username is too long (maximum is 255 characters)'),
  })
  .required();

const OnlinePresence = ({ initialValues }: OnlinePresenceProps) => {
  const { borderRadius } = useTheme();

  const { id: userId, screenName } = useCurrentUser();

  const supabase = useSupabaseTypedClient();

  const queryClient = useQueryClient();

  const { toastSuccess, toastError } = useToastWrapper();

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors, isDirty },
  } = useForm<OnlinePresenceForm>({
    resolver: yupResolver(validationSchema),
    mode: 'onSubmit',
    defaultValues: {
      linkedin_url: initialValues.linkedin_url || '',
      devto_url: initialValues.devto_url || '',
      twitter_url: initialValues.twitter_url || '',
    },
  });

  const mutation = useMutation<UserProfile, string, OnlinePresenceForm>({
    mutationFn: supabaseMutationFn((params) =>
      supabase.functions.invoke<UserProfile>(EdgeFunctionName.UpdateUserProfile, {
        body: params as UpdateUserProfileParams,
      })
    ),
    onSuccess: (data) => {
      reset({
        linkedin_url: data.linkedin_url,
        devto_url: data.devto_url,
        twitter_url: data.twitter_url,
      });

      queryClient.invalidateQueries({ queryKey: [TanstackQueryName.GetUserProfile, userId] });

      toastSuccess('Online presence saved');
    },
    onError: () => {
      toastError(`Can't save online presence`);
    },
  });

  const onSubmit = async ({ linkedin_url, devto_url, twitter_url }: OnlinePresenceForm) => {
    await mutation.mutate({
      linkedin_url: linkedin_url ? linkedin_url.trim() : '',
      devto_url: devto_url ? devto_url.trim() : '',
      twitter_url: twitter_url ? twitter_url.trim() : '',
    });
  };

  return (
    <Box py={5} px={6} bg="whiteAlpha.200" borderRadius={borderRadius}>
      <Text fontSize="xLarge" pb={5}>
        Online presence
      </Text>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={4}>
          <Stack spacing={2}>
            <FormControl>
              <FormLabel htmlFor="githubUrl" fontWeight="normal">
                <HStack>
                  <Icon type="Github" />
                  <span>GitHub</span>
                </HStack>
              </FormLabel>
              <InputGroup>
                <InputLeftAddon>https://github.com/</InputLeftAddon>
                <Input variant="filled" value={screenName} isDisabled />
              </InputGroup>
            </FormControl>
            <Text fontSize="small" opacity="secondary" fontWeight="regular">
              The application requires your GitHub username to function. If you need to change it,
              please <Link href="mailto:support@bigdevsoon.me">contact us</Link>.
            </Text>
          </Stack>
          <Stack spacing={1}>
            <FormControl isInvalid={Boolean(errors.devto_url)}>
              <FormLabel htmlFor="devto_url" fontWeight="normal">
                <HStack>
                  <Icon type="Devto" size={20} />
                  <span>Dev.to</span>
                </HStack>
              </FormLabel>
              <InputGroup>
                <InputLeftAddon>https://dev.to/</InputLeftAddon>
                <Input {...register('devto_url')} variant="filled" placeholder="Dev.to username" />
              </InputGroup>
              <FormErrorMessage>{errors.devto_url?.message}</FormErrorMessage>
            </FormControl>
          </Stack>
          <Stack spacing={1}>
            <FormControl isInvalid={Boolean(errors.twitter_url)}>
              <FormLabel htmlFor="twitter_url" fontWeight="normal">
                <HStack>
                  <Icon type="Twitter" size={20} />
                  <span>X</span>
                </HStack>
              </FormLabel>
              <InputGroup>
                <InputLeftAddon>https://x.com/</InputLeftAddon>
                <Input {...register('twitter_url')} variant="filled" placeholder="X username" />
              </InputGroup>
              <FormErrorMessage>{errors.twitter_url?.message}</FormErrorMessage>
            </FormControl>
          </Stack>
          <Stack spacing={1}>
            <FormControl isInvalid={Boolean(errors.linkedin_url)}>
              <FormLabel htmlFor="linkedin_url" fontWeight="normal">
                <HStack>
                  <Icon type="Linkedin" size={20} />
                  <span>LinkedIn</span>
                </HStack>
              </FormLabel>
              <InputGroup>
                <InputLeftAddon>https://www.linkedin.com/in/</InputLeftAddon>
                <Input
                  {...register('linkedin_url')}
                  variant="filled"
                  placeholder="LinkedIn username"
                />
              </InputGroup>
              <FormErrorMessage>{errors.linkedin_url?.message}</FormErrorMessage>
            </FormControl>
          </Stack>
        </Stack>
        <Divider mt={6} mb={5} />
        <Button type="submit" isLoading={mutation.isPending} isDisabled={!isDirty}>
          Save online presence
        </Button>
      </form>
    </Box>
  );
};

export default OnlinePresence;
