import { Checkbox, HStack, IconButton, useDisclosure } from '@chakra-ui/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { supabaseMutationFn, useSupabaseTypedClient } from 'hooks/reactQuery';
import { useToastWrapper } from 'hooks/useToastWrapper';
import { debounce } from 'lodash';
import { ReactNode, useRef } from 'react';

import { useCurrentUser } from 'auth/CurrentUserProvider';
import GradientTooltip from 'components/GradientTooltip';
import { ProtectedElement } from 'components/ProtectedElement';
import { User_Card_Status_Enum } from 'types/databaseEnums';
import { CompleteCardParams, EdgeFunctionName, ProjectCard } from 'types/edgeFunctions';
import { TanstackQueryName } from 'types/frontend';

import CardsCompletedModal from '../CardsCompletedModal';

interface ICompleteCardCheckboxProps {
  isCompleted: boolean;
  projectScreenName: string;
  isProjectCompleted: boolean;
  cardId: string;
  children?: ReactNode | null;
  cards: ProjectCard[];
  userProjectId?: string | null;
}

const CompleteCardCheckbox = ({
  isCompleted,
  projectScreenName,
  isProjectCompleted,
  userProjectId,
  cardId,
  cards,
  children,
}: ICompleteCardCheckboxProps) => {
  const { id: currentUserId } = useCurrentUser();

  const supabase = useSupabaseTypedClient();
  const queryClient = useQueryClient();

  const { toastSuccess, toastError } = useToastWrapper();
  const cardsCompletedModal = useDisclosure();

  const mutation = useMutation<string, string, CompleteCardParams>({
    mutationFn: supabaseMutationFn(({ card_id, is_completed, user_project_id }) =>
      supabase.functions.invoke<string>(EdgeFunctionName.CompleteCard, {
        body: { card_id, is_completed, user_project_id } as CompleteCardParams,
      })
    ),
    onSettled: async () => {
      return await Promise.all([
        queryClient.invalidateQueries({
          queryKey: [TanstackQueryName.GetCurrentUser, currentUserId],
        }),
        queryClient.invalidateQueries({
          queryKey: [TanstackQueryName.GetProject, projectScreenName],
        }),
        queryClient.invalidateQueries({
          queryKey: [TanstackQueryName.GetUserAchievements, currentUserId],
        }),
      ]);
    },
    onMutate: () => {
      const completedCardsSoFar = cards.reduce((acc, card) => {
        if (card.status === User_Card_Status_Enum.Completed) {
          acc++;

          return acc;
        }
        return acc;
      }, 0);

      const areAllCardsCompleted = completedCardsSoFar === cards.length - 1;

      if (!isCompleted) {
        toastSuccess('Card completed!');

        if (areAllCardsCompleted && !isProjectCompleted) {
          cardsCompletedModal.onOpen();
        }
      }
    },
    onError: () => {
      if (!isCompleted) {
        toastError('Could not complete a card, try again later.');
      }
    },
  });

  const debouncedMutate = useRef(debounce(mutation.mutate, 300));

  return (
    <>
      <ProtectedElement
        asButton={false}
        onClick={(e) => {
          e.stopPropagation();

          debouncedMutate.current({
            card_id: cardId,
            is_completed: isCompleted,
            user_project_id: userProjectId,
          });
        }}
      >
        <HStack
          spacing={1}
          minWidth="40px"
          cursor="pointer"
          _hover={{ opacity: 0.87 }}
          transition="all 0.2s ease-out"
        >
          <GradientTooltip label={isCompleted ? 'Mark as Incomplete' : 'Mark as Complete'}>
            <IconButton aria-label="complete card checkbox" variant="ghost">
              <Checkbox
                px={1}
                isChecked={
                  mutation.isPending ? (isCompleted ? false : true) : isCompleted ? true : false
                }
              >
                {children}
              </Checkbox>
            </IconButton>
          </GradientTooltip>
        </HStack>
      </ProtectedElement>
      <CardsCompletedModal
        isOpen={cardsCompletedModal.isOpen}
        onClose={cardsCompletedModal.onClose}
      />
    </>
  );
};

export default CompleteCardCheckbox;
