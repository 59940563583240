import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Box,
  VStack,
  HStack,
} from '@chakra-ui/react';

import Icon from 'components/Icon';
import Text from 'components/Text';

const QUESTIONS_AND_ANSWERS = [
  {
    question: `What's the difference between Monthly, Yearly, and Lifetime?`,
    answer: `The primary differences between the Monthly, Yearly, and Lifetime plans lie in the cost-effectiveness, access duration, and additional perks. The Monthly plan is ideal for those exploring our features with minimal commitment, offering basic access and a monthly token recharge. The Yearly plan reduces the overall cost, provides more tokens monthly, and includes special access to dedicated Discord channels for a more engaged community experience. The Lifetime plan requires a one-time payment granting you unlimited access to all current and future resources, the highest number of monthly tokens, and exclusive badges, making it the best value for serious and long-term users.`,
  },
  {
    question: 'Is being an experienced Developer required to use your app?',
    answer: `No, BigDevSoon is designed to cater to all skill levels, from beginners to experienced developers. Our AI-powered features, professional designs, and community support are tailored to help you learn, grow, and succeed in your coding journey, regardless of your current expertise.`,
  },
  {
    question: 'What is ChatGPT support on BigDevSoon?',
    answer:
      'ChatGPT support is an AI-powered assistance feature available on BigDevSoon. It is used to generate AI-code solutions, generate code & design reports, and create prototypes. Additionally, Elise, your coding Buddy, is here to help you with any questions or issues you encounter while working on projects and challenges. Whether you need guidance on a specific card, assistance with coding, or general inquiries, Elise is your go-to resource for quick and helpful responses.',
  },
  {
    question: `What benefits do I get from the dedicated Discord channels for Yearly and Lifetime subscribers?`,
    answer: `Our dedicated Discord channels for Yearly and Lifetime subscribers is a close-knit community for our most committed learners. By being a part of this exclusive group, you receive priority support, valuable tips & tricks, and insights to boost your coding journey. It's our way of providing extra attention and resources to those who support us the most, ensuring they succeed in their learning goals.`,
  },
  {
    question: 'Why should I upgrade from FREE?',
    answer:
      'Upgrading from the FREE plan offers significant benefits for those looking to maximize their learning and development capabilities. While the FREE plan provides a taste of what we offer, including a limited number of BigTokens and access to community support, upgrading unlocks a wealth of resources. By choosing a paid plan, you gain unlimited access to professional Figma designs, and additional AI-assisted features like AI Buddy. Each paid plan significantly increases the monthly BigToken allocation, which you can use for more advanced AI-generated solutions, personalized reports, and prototypes. Additionally, paid plans offer exclusive features like private code storage, and prioritized community support, enhancing both learning efficiency and project development.',
  },
  {
    question: `Does my subscription automatically renew?`,
    answer: `Yes, our subscriptions auto-renew at the end of their term. (applies only for Monthly & Yearly as Lifetime is a one-time payment)`,
  },
  {
    question: `What happens when my subscription expires?`,
    answer: `If your subscription expires, your token count resets to the default 3 BigTokens per month. All the premium features will be revoked, but code stays private and can only be made public again. Code with 10,000+ characters will be there but can't be saved anymore. If you had 10+ Code Frames, you will need to remove existing ones first before creating new. AI Buddy messages will not disappear but you won't be able to chat anymore. Yearly subscribers will also be removed from the dedicated Discord channel.`,
  },
  {
    question: `Do you offer refunds?`,
    answer: `Yes, we offer a 14-day refund policy. If you're not satisfied with your subscription, please request a refund within 14 days of your purchase date.`,
  },
  {
    question: `Can I cancel my subscription anytime?`,
    answer: `Absolutely! You can manage and cancel your subscription anytime from the Billing Page.`,
  },
];

export const FAQ = () => {
  return (
    <VStack pt={40} px={4}>
      <Text fontSize="xxxxLarge" fontWeight="semibold" textAlign="center">
        Frequently asked questions
      </Text>
      <Accordion allowMultiple>
        {QUESTIONS_AND_ANSWERS.map(({ question, answer }) => (
          <AccordionItem
            mt={6}
            p={4}
            key={answer}
            borderRadius="8px"
            border="1px solid"
            borderColor="whiteAlpha.200"
            background="linear-gradient(136deg, rgba(214, 188, 250, 0.16) 0%, rgba(214, 188, 250, 0.00) 100%)"
            boxShadow="0px 10px 20px 0px rgba(0, 0, 0, 0.15)"
            maxWidth="900px"
          >
            {({ isExpanded }) => (
              <>
                <h2>
                  <AccordionButton>
                    <Box as="span" flex="1" textAlign="left">
                      <HStack spacing={2}>
                        <Text fontSize="xLarge">{question}</Text>
                      </HStack>
                    </Box>
                    <Icon type={isExpanded ? 'Minus' : 'Add'} size={20} />
                  </AccordionButton>
                </h2>
                <AccordionPanel py={4}>
                  <Text fontSize="large" fontWeight="regular">
                    {answer}
                  </Text>
                </AccordionPanel>
              </>
            )}
          </AccordionItem>
        ))}
      </Accordion>
    </VStack>
  );
};
