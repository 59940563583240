import { useGetChallengeQuery } from 'hooks/queries/challenges/useGetChallengeQuery';
import { Route, Routes, useParams } from 'react-router-dom';

import DevSandboxSkeleton from 'components/DevSandbox/DevSandboxSkeleton';
import { SEO } from 'components/SEO';

import ChallengePlayground from './playground/ChallengePlayground';
import Submit from './playground/Submit';
import ChallengeSolution from './solution/ChallengeSolution';

const ChallengeHub = () => {
  const { challengeScreenName = '' } = useParams();

  const { data, isPending } = useGetChallengeQuery({
    challengeScreenName,
  });

  if (!data || isPending) {
    return <DevSandboxSkeleton loadingText="Loading challenge..." />;
  }

  return (
    <>
      <SEO
        title={`BigDevSoon | ${data.title} challenge`}
        url={`${process.env.REACT_APP_SITE_URL}challenges/${data.screen_name}`}
        image={data.screenshot_url}
      />
      <Routes>
        {<Route path="submit" element={<Submit challenge={data!} />} />}
        <Route
          path="solutions/:userScreenName/:title/*"
          element={<ChallengeSolution challenge={data!} />}
        />
        <Route path="*" element={<ChallengePlayground challenge={data!} />} />
      </Routes>
    </>
  );
};

export default ChallengeHub;
