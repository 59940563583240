import { SupabaseClient } from '@supabase/supabase-js';
import { useQueryWithErrorBoundary, useSupabaseTypedClient } from 'hooks/reactQuery';

import { EdgeFunctionName, GetPriceResponse } from 'types/edgeFunctions';
import { Database } from 'types/supabase';

interface IGetPriceQueryFnParams {
  supabase: SupabaseClient<Database>;
}

export const getTokensQueryFn = async ({ supabase }: IGetPriceQueryFnParams) => {
  const { data, error } = await supabase.functions.invoke(EdgeFunctionName.GetPrice);

  if (error) throw error;

  return data;
};

interface IGetPriceResponse {
  data: GetPriceResponse | undefined | null;
  isPending: boolean;
}

export const useGetPriceQuery = (): IGetPriceResponse => {
  const supabase = useSupabaseTypedClient();

  const { data, isPending } = useQueryWithErrorBoundary({
    queryKey: ['price'],
    queryFn: () =>
      getTokensQueryFn({
        supabase,
      }),
  });

  if (!data) {
    return {
      data: null,
      isPending,
    };
  }

  return {
    data,
    isPending,
  };
};
