import { useToastWrapper } from 'hooks/useToastWrapper';
import { useEffect } from 'react';
import { Navigate, Route, Routes as RouterRoutes, useSearchParams } from 'react-router-dom';

import { AchievementProvider } from 'auth/AchievementProvider';
import { useAuth } from 'auth/AuthProvider';
import { CurrentUserProvider, useCurrentUser } from 'auth/CurrentUserProvider';
import { REF_COOKIE_KEY } from 'config/constants/github';
import Billing from 'features/billing';
import { BillingProvider } from 'features/billing/context/BillingProvider';
import StripeCallback from 'features/callbacks/StripeCallback';
import ChallengeHub from 'features/challenge-hub';
import Challenges from 'features/challenges';
import CodeFrameHub from 'features/code-frame-hub';
import NewCodeFrame from 'features/code-frame-hub/NewCodeFrame';
import CodeFrames from 'features/code-frames';
import Dashboard from 'features/dashboard';
import EditProfile from 'features/edit-profile';
import Onboarding from 'features/onboarding/steps/Onboarding';
import PracticeProblemHub from 'features/practice-problem-hub';
import PracticeProblems from 'features/practice-problems/PracticeProblems';
import PreviewProfile from 'features/preview-profile';
import Pricing from 'features/pricing';
import ProjectHub from 'features/project-hub';
import Projects from 'features/projects';
import PrototypeHub from 'features/prototype-hub';
import NewPrototype from 'features/prototype-hub/NewPrototype';
import Prototypes from 'features/prototypes';
import Settings from 'features/settings';
import { ERoute } from 'types/frontend';
import { setCookie } from 'utils/cookies';

import LegacySolutionRedirect from './LegacySolutionRedirect';
import DiscordCallback from './features/callbacks/DiscordCallback';
import { AppLayout, OnboardingLayout } from './layout';

const UnauthenticatedRoutes = () => (
  <>
    <AppLayout isFreeUser hasDiscordRegistered={false}>
      <RouterRoutes>
        {/* Legacy routes for solutions */}
        <Route
          path={`/challenge-solutions/:userScreenName/:challengeScreenName/*`}
          element={<LegacySolutionRedirect type="challenge" />}
        />
        <Route
          path={`/challenge-code-previews/:previewUniqueName/*`}
          element={<LegacySolutionRedirect type="challenge" />}
        />
        <Route
          path={`/solutions/:userScreenName/:projectScreenName/*`}
          element={<LegacySolutionRedirect type="project" />}
        />
        <Route
          path={`/project-code-previews/:previewUniqueName/*`}
          element={<LegacySolutionRedirect type="project" />}
        />

        {/* Learn routes */}
        <Route path={ERoute.Projects} element={<Projects />} />
        <Route path={ERoute.Challenges} element={<Challenges />} />
        <Route path={ERoute.PracticeProblems} element={<PracticeProblems />} />
        <Route path={`${ERoute.Projects}/:projectScreenName/*`} element={<ProjectHub />} />
        <Route path={`${ERoute.Challenges}/:challengeScreenName/*`} element={<ChallengeHub />} />
        <Route
          path={`${ERoute.PracticeProblems}/:practiceProblemScreenName/*`}
          element={<PracticeProblemHub />}
        />
        {/* Create routes */}
        <Route path={ERoute.Prototypes} element={<Prototypes />} />
        <Route
          path={`${ERoute.Prototypes}/:userScreenName/:prototypeScreenName/*`}
          element={<PrototypeHub />}
        />
        <Route path={ERoute.CodeFrames} element={<CodeFrames />} />
        <Route
          path={`${ERoute.CodeFrames}/:userScreenName/:codeFrameScreenName/*`}
          element={<CodeFrameHub />}
        />
        <Route path={`${ERoute.Profile}/:profileScreenName`} element={<PreviewProfile />} />
        <Route path={ERoute.Pricing} element={<Pricing />} />
        <Route path={ERoute.UnlockBig} element={<Pricing />} />
        <Route path="*" element={<Navigate to={ERoute.Projects} replace />} />
      </RouterRoutes>
    </AppLayout>
  </>
);

const OnboardingRoutes = () => (
  <OnboardingLayout>
    <RouterRoutes>
      <Route path={ERoute.Onboarding} element={<Onboarding />} />
      <Route path="*" element={<Navigate to={ERoute.Onboarding} replace />} />
    </RouterRoutes>
  </OnboardingLayout>
);

const AppRoutes = () => {
  const { githubAccessToken, isFree, hasDiscordRegistered } = useCurrentUser();
  const { signOut, userId: authUserId } = useAuth();
  const { toastInfo } = useToastWrapper();

  const showPricing = isFree;

  useEffect(() => {
    const checkSignOut = async () => {
      if (!githubAccessToken && authUserId) {
        await signOut();

        toastInfo('Session expired, please sign in again.');
      }
    };

    checkSignOut();
  }, [githubAccessToken, authUserId]);

  return (
    <AppLayout isFreeUser={isFree} hasDiscordRegistered={hasDiscordRegistered}>
      <RouterRoutes>
        {/* Legacy routes for solutions */}
        <Route
          path={`/challenge-solutions/:userScreenName/:challengeScreenName/*`}
          element={<LegacySolutionRedirect type="challenge" />}
        />
        <Route
          path={`/challenge-code-previews/:previewUniqueName/*`}
          element={<LegacySolutionRedirect type="challenge" />}
        />
        <Route
          path={`/solutions/:userScreenName/:projectScreenName/*`}
          element={<LegacySolutionRedirect type="project" />}
        />
        <Route
          path={`/project-code-previews/:previewUniqueName/*`}
          element={<LegacySolutionRedirect type="project" />}
        />

        <Route path={ERoute.Dashboard} element={<Dashboard />} />

        {/* Learn routes */}
        <Route path={ERoute.Projects} element={<Projects />} />
        <Route path={ERoute.Challenges} element={<Challenges />} />
        <Route path={ERoute.PracticeProblems} element={<PracticeProblems />} />

        <Route path={`${ERoute.Projects}/:projectScreenName/*`} element={<ProjectHub />} />
        <Route path={`${ERoute.Challenges}/:challengeScreenName/*`} element={<ChallengeHub />} />
        <Route
          path={`${ERoute.PracticeProblems}/:practiceProblemScreenName/*`}
          element={<PracticeProblemHub />}
        />

        {/* Create routes */}
        <Route path={ERoute.Prototypes} element={<Prototypes />} />
        <Route path={`${ERoute.Prototypes}${ERoute.NewPrototype}`} element={<NewPrototype />} />
        <Route
          path={`${ERoute.Prototypes}/:userScreenName/:prototypeScreenName/*`}
          element={<PrototypeHub />}
        />
        <Route path={ERoute.CodeFrames} element={<CodeFrames />} />
        <Route path={`${ERoute.CodeFrames}${ERoute.NewCodeFrame}`} element={<NewCodeFrame />} />
        <Route
          path={`${ERoute.CodeFrames}/:userScreenName/:codeFrameScreenName/*`}
          element={<CodeFrameHub />}
        />

        <Route path={`${ERoute.Profile}/:profileScreenName`} element={<PreviewProfile />} />
        <Route path={ERoute.Pricing} element={<Pricing />} />
        <Route path={ERoute.UnlockBig} element={<Pricing />} />
        <Route path={ERoute.Profile} element={<EditProfile />} />
        <Route
          path={ERoute.Billing}
          element={
            <BillingProvider>
              <Billing />
            </BillingProvider>
          }
        />
        <Route path={ERoute.Settings} element={<Settings />} />
        <Route path={ERoute.DiscordCallback} element={<DiscordCallback />} />
        <Route
          path={ERoute.StripeCallback}
          element={
            <BillingProvider>
              <StripeCallback />
            </BillingProvider>
          }
        />
        <Route path="*" element={<Navigate to={ERoute.Dashboard} replace />} />
      </RouterRoutes>
    </AppLayout>
  );
};

export const Routes = () => {
  const { session, userId, loading } = useAuth();

  const [searchParams] = useSearchParams();

  useEffect(() => {
    const refCode = searchParams.get(REF_COOKIE_KEY) as string;

    if (refCode) {
      setCookie(REF_COOKIE_KEY, refCode);
    }
  }, [searchParams]);

  const renderProperRoutes = () => {
    if (!session) {
      return <UnauthenticatedRoutes />;
    }

    if (userId) {
      return (
        <AchievementProvider>
          <AppRoutes />
        </AchievementProvider>
      );
    }

    if (session) {
      return <OnboardingRoutes />;
    }

    return <UnauthenticatedRoutes />;
  };

  return (
    <CurrentUserProvider userId={userId} isAuthLoading={loading}>
      {renderProperRoutes()}
    </CurrentUserProvider>
  );
};
