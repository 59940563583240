import {
  Box,
  HStack,
  IconButton,
  Popover,
  PopoverTrigger,
  PopoverContent,
  Center,
  Image,
  PopoverBody,
  PopoverArrow,
  Stack,
} from '@chakra-ui/react';
import { useState } from 'react';

import GradientTooltip from 'components/GradientTooltip';
import Link from 'components/Link';

import Icon from '../../Icon';
import Text from '../../Text';
import CodeFrame from '../CodeFrame';

interface IBrowserTabProps {
  browserUrl: string;
  codeFrameUrl: string;
  screenshotUrl?: string | null;
  isExternalSolution?: boolean | null;
  onCodeFrameUrlChange: () => void;
}

const BrowserTab = ({
  browserUrl,
  codeFrameUrl,
  isExternalSolution,
  screenshotUrl,
  onCodeFrameUrlChange,
}: IBrowserTabProps) => {
  const [isCopied, setIsCopied] = useState(false);

  if (!browserUrl) {
    return null;
  }

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(browserUrl);
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 300);
  };

  return (
    <Stack
      spacing={0}
      alignItems="flex-start"
      justifyContent="space-between"
      width="100%"
      height="100%"
    >
      <Box
        p={2}
        backgroundColor="whiteAlpha.200"
        border="1px solid"
        borderColor="#3F444E"
        borderLeft="none"
        width="100%"
      >
        <HStack justifyContent="space-between">
          <Text maxWidth="500px" isTruncated>
            {browserUrl}
          </Text>
          <HStack>
            {!isExternalSolution && (
              <GradientTooltip label="Refresh preview">
                <span>
                  <IconButton
                    aria-label="refresh preview"
                    onClick={onCodeFrameUrlChange}
                    variant="ghost"
                  >
                    <Icon type="PreviewRefresh" />
                  </IconButton>
                </span>
              </GradientTooltip>
            )}
            <Popover isOpen={isCopied} placement="top">
              <GradientTooltip label="Copy preview URL to clipboard">
                <span>
                  <PopoverTrigger>
                    <IconButton
                      onClick={handleCopyToClipboard}
                      aria-label="copy preview url to clipboard"
                      variant="ghost"
                    >
                      <Icon type="PreviewCopy" />
                    </IconButton>
                  </PopoverTrigger>
                </span>
              </GradientTooltip>
              <PopoverContent width="fit-content" backgroundColor="#D9D9D9">
                <PopoverArrow backgroundColor="#D9D9D9" />
                <PopoverBody color="gray.900">Copied!</PopoverBody>
              </PopoverContent>
            </Popover>
            <Link
              href={isExternalSolution ? browserUrl : `${browserUrl}?mode=full-page`}
              isExternal
            >
              <GradientTooltip label="Open preview URL in a new tab">
                <span>
                  <IconButton aria-label="open preview url in a new tab" variant="ghost">
                    <Icon type="PreviewNewTab" />
                  </IconButton>
                </span>
              </GradientTooltip>
            </Link>
          </HStack>
        </HStack>
      </Box>
      <Box width="100%" height="100%">
        {isExternalSolution ? (
          <Center width="100%" height="100%">
            <Image
              src={screenshotUrl || ''}
              width="100%"
              height="auto"
              maxW={1440}
              maxH="80%"
              fallbackStrategy={'onError'}
              fallback={
                <Center width="100%" height="auto" background="white">
                  <Text color="black">Image not found</Text>
                </Center>
              }
            />
          </Center>
        ) : (
          <CodeFrame url={codeFrameUrl} />
        )}
      </Box>
      <div />
    </Stack>
  );
};

export default BrowserTab;
