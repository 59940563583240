import { Box, Divider, HStack, Stack, useDisclosure, useTheme, VStack } from '@chakra-ui/react';
import { format } from 'date-fns';

import Button from 'components/Button';
import Icon from 'components/Icon';
import Link from 'components/Link';
import { CancelSubscriptionModal } from 'components/Modal/CancelSubscriptionModal';
import Text from 'components/Text';
import { DATE_FORMAT, plans } from 'config/constants/plans';
import { useMediaQueries } from 'layout/MediaQueriesProvider';
import { Plan_Type_Enum } from 'types/databaseEnums';

interface IDefaultState {
  interval: Plan_Type_Enum;
  brand: string;
  last4: string;
  amount: number;
  currentPeriodEnd: Date;
}

export const DefaultState = ({ interval: plan, amount, currentPeriodEnd }: IDefaultState) => {
  const theme = useTheme();
  const { isDesktopCancelSubscriptionButton } = useMediaQueries();

  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <VStack align="stretch" spacing={6}>
        <VStack align="stretch" spacing={6} shadow="0px 4px 24px 0px rgba(0, 0, 0, 0.24)">
          <VStack
            bg="whiteAlpha.200"
            opacity="8"
            borderRadius={theme.borderRadius}
            p={6}
            align="stretch"
          >
            <div data-sl="mask">
              <HStack align="stretch" justifyContent="space-between">
                <VStack align="stretch">
                  <HStack spacing={3}>
                    <Icon type={plans[plan].icon} size={48} />
                    <Text fontSize="xLarge">{plans[plan].title}</Text>
                  </HStack>
                  <HStack align="end">
                    <Text fontSize="xxxLarge">${amount ? amount / 100 : 0}</Text>
                    <Text fontSize="small" opacity="secondary" pb={1}>
                      {plan.toLowerCase()}
                    </Text>
                  </HStack>
                  <HStack spacing={1}>
                    <Text opacity="secondary" fontWeight="regular">
                      Next payment will be on{' '}
                    </Text>
                    <Text>{format(new Date(currentPeriodEnd), DATE_FORMAT)}</Text>
                  </HStack>
                </VStack>
                {isDesktopCancelSubscriptionButton && (
                  <Button scheme="danger" leftIcon={<Icon type="Close" />} onClick={onOpen}>
                    Cancel subscription
                  </Button>
                )}
              </HStack>
            </div>
            {!isDesktopCancelSubscriptionButton && (
              <Box pt={3}>
                <Button
                  leftIcon={<Icon type="Close" />}
                  scheme="danger"
                  width="fit-content"
                  onClick={onOpen}
                >
                  Cancel subscription
                </Button>
              </Box>
            )}
          </VStack>
        </VStack>
        {(plan === Plan_Type_Enum.Monthly || plan === Plan_Type_Enum.Yearly) && (
          <Stack>
            <Divider />
            <Stack direction={['column', 'row', 'row', 'row']}>
              <Text fontWeight="regular">
                {plan === Plan_Type_Enum.Monthly
                  ? 'Do you want to upgrade to either Yearly or Lifetime Plan?'
                  : 'Do you want to upgrade to Lifetime Plan?'}
              </Text>
              <Link href="mailto:support@bigdevsoon.me">Contact us</Link>
            </Stack>
          </Stack>
        )}
      </VStack>
      <CancelSubscriptionModal isOpen={isOpen} onClose={onClose} />
    </>
  );
};
