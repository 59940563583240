import { useEffect } from 'react';
import useLocalStorageState from 'use-local-storage-state';

import { useCurrentUser } from 'auth/CurrentUserProvider';
import { useDevSandbox } from 'components/DevSandbox/DevSandboxProvider';
import { ChatGptMessage } from 'types/edgeFunctions';

export const useMessages = () => {
  const { id } = useDevSandbox();
  const { screenName } = useCurrentUser();

  const storageKey = `${screenName}-${id}`;

  const initialMessage: ChatGptMessage = {
    id: 'first-message',
    who: 'Elise',
    message: 'How can I help you?',
    created_at: '',
    status: 'received',
  };

  const [messages, setMessages] = useLocalStorageState<ChatGptMessage[]>(storageKey, {
    defaultValue: [initialMessage],
  });

  const addMessage = (message: ChatGptMessage) => {
    setMessages((prevMessages) => [...prevMessages, message]);
  };

  const updateMessage = (id: string, updatedMessage: ChatGptMessage) => {
    setMessages((prevMessages) =>
      prevMessages.map((message) => (message.id === id ? updatedMessage : message))
    );
  };

  const deleteMessage = (id: string) => {
    setMessages((prevMessages) => prevMessages.filter((message) => message.id !== id));
  };

  useEffect(() => {
    const storedMessages = localStorage.getItem(storageKey);

    if (storedMessages) {
      setMessages(JSON.parse(storedMessages));
    }
  }, [storageKey]);

  return {
    messages: messages,
    lastMessage: messages[messages.length - 1],
    addMessage,
    updateMessage,
    deleteMessage,
  };
};
