import { SupabaseClient } from '@supabase/supabase-js';
import { useQueryWithErrorBoundary, useSupabaseTypedClient } from 'hooks/reactQuery';

import { useCurrentUser } from 'auth/CurrentUserProvider';
import { Plan_Type_Enum } from 'types/databaseEnums';
import {
  CodeFrame,
  GetCodeFrameResponse,
  GetSolutionParams,
  SummaryReport,
} from 'types/edgeFunctions';
import { TanstackQueryName } from 'types/frontend';
import { Database } from 'types/supabase';

interface IGetCodeFrameQueryFnParams {
  supabase: SupabaseClient<Database>;
  currentUserId: string;
  solutionUrl: string;
}

const getCodeFrameQueryFn = async ({
  supabase,
  currentUserId,
  solutionUrl,
}: IGetCodeFrameQueryFnParams): Promise<CodeFrame> => {
  const codeFrameResponse = await supabase
    .from('user_code_frame')
    .select(
      'id, created_at, html, css, js, is_private, design_score, code_score, design_report, code_report, ai_feedback, title, screen_name, screenshot_url, solution_url, repository_url, preview_url, user_profile_view (id, avatar_url, name, screen_name, plan, experience)'
    )
    .eq('solution_url', solutionUrl)
    .single();

  if (codeFrameResponse.error) throw codeFrameResponse.error;

  const [likes, isLikedByMe, comments, explore] = await Promise.all([
    supabase.rpc('count_likes', { input_entity_id: codeFrameResponse.data.id }),
    currentUserId
      ? supabase
          .from('user_like')
          .select('voter_id')
          .eq('voter_id', currentUserId)
          .eq('entity_id', codeFrameResponse.data.id)
      : Promise.resolve(undefined),
    supabase
      .from('support_center')
      .select('*', { count: 'exact', head: true })
      .eq('entity_id', codeFrameResponse.data.id)
      .not('user_id', 'is', null),
    supabase
      .from('user_code_view')
      .select('*', { count: 'exact', head: true })
      .or(`html.neq.,css.neq.,js.neq.`)
      .eq('type', 'code-frame')
      .not('user_id', 'is', null)
      .eq('is_private', false),
  ]);

  if (likes.error) throw likes.error;
  if (isLikedByMe?.error) throw isLikedByMe.error;
  if (comments.error) throw comments.error;
  if (explore.error) throw explore.error;

  const codeFrame = codeFrameResponse.data!;
  const screenshotUrl = codeFrame.screenshot_url;

  return {
    id: codeFrame.id,
    title: codeFrame.title || '',
    is_private: codeFrame.is_private || false,
    screen_name: codeFrame.screen_name || '',
    live_preview_url: codeFrame.preview_url || '',
    screenshot_url: screenshotUrl || '',
    repository_url: codeFrame.repository_url || '',
    preview_url: codeFrame.preview_url || '',
    solution_url: codeFrame.solution_url || '',
    created_at: codeFrame.created_at || '',
    html: codeFrame.html || '',
    css: codeFrame.css || '',
    js: codeFrame.js || '',
    is_my_solution: currentUserId === codeFrame.user_profile_view!.id,
    user: {
      id: codeFrame.user_profile_view!.id || '',
      avatar_url: codeFrame.user_profile_view!.avatar_url || '',
      name: codeFrame.user_profile_view!.name || '',
      screen_name: codeFrame.user_profile_view!.screen_name || '',
      plan: codeFrame.user_profile_view!.plan as Plan_Type_Enum,
      xp: codeFrame.user_profile_view?.experience || 0,
    },
    likes: likes.data || 0,
    is_liked: (isLikedByMe?.data || []).length > 0,
    comments_count: comments.count || 0,
    solutions_count: explore.count || 0,
    design_report: (codeFrame.design_report as SummaryReport[]) || null,
    code_report: (codeFrame.code_report as SummaryReport[]) || null,
    design_score: codeFrame.design_score || null,
    code_score: codeFrame.code_score || null,
    ai_feedback: codeFrame.ai_feedback || '',
  };
};

interface IUseGetCodeFrameQueryResponse {
  data: GetCodeFrameResponse | undefined | null;
  isPending: boolean;
}

export const useGetCodeFrameQuery = ({
  solution_url,
}: GetSolutionParams): IUseGetCodeFrameQueryResponse => {
  const { id: currentUserId } = useCurrentUser();
  const supabase = useSupabaseTypedClient();

  const { data, isPending } = useQueryWithErrorBoundary({
    queryKey: [TanstackQueryName.GetCodeFrame, solution_url],
    queryFn: async () => {
      const data = await getCodeFrameQueryFn({
        supabase,
        currentUserId: currentUserId,
        solutionUrl: solution_url,
      });

      return data;
    },
  });

  if (!data) {
    return {
      data: null,
      isPending,
    };
  }

  return {
    data,
    isPending,
  };
};
