import { Helmet } from 'react-helmet';

interface ISEOProps {
  title: string;
  description?: string;
  url?: string;
  image?: string;
}

export const SEO = ({ title, description, url, image }: ISEOProps) => {
  const mainUrl = url || process.env.REACT_APP_SITE_URL;
  const mainImage = image || `${process.env.REACT_APP_SITE_URL}og-image.png`;

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} key="description" />
      <meta name="og:title" content={title} key="og:title" />
      <meta
        name="og:description"
        content={description || 'BigDevSoon - You and Code Frames in the center'}
        key="og:description"
      />
      <meta name="og:url" content={mainUrl} key="og:url" />
      <meta name="og:site_name" content="app.bigdevsoon.me" key="og:site_name" />
      <meta name="og:type" content="website" key="og:type" />
      <meta name="og:image" content={mainImage} key="og:image" />
      <meta name="twitter:card" content="summary_large_image" key="twitter:card" />
      <meta name="twitter:title" content={title} key="twitter:title" />
      <meta name="twitter:site" content="app.bigdevsoon.me" key="twitter:site" />
      <meta name="twitter:creator" content="Adrian Bigaj" key="twitter:creator" />
      <meta name="twitter:description" content={description} key="twitter:description" />
      <meta name="twitter:image" content={mainImage} key="twitter:image" />
    </Helmet>
  );
};
