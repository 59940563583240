export const setCookie = (name: string, value: string) => {
  document.cookie = name + '=' + encodeURIComponent(value); // by default it expires at the end of session
};
export const getCookie = (name: string) => {
  const cookies = document.cookie.split('; ');
  for (let i = 0; i < cookies.length; i++) {
    const cookieParts = cookies[i].split('=');
    const cookieName = decodeURIComponent(cookieParts[0]);
    const cookieValue = decodeURIComponent(cookieParts[1]);
    if (cookieName === name) {
      return cookieValue;
    }
  }
  return null;
};
export const deleteCookie = (name: string) => {
  document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
};
