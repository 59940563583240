import { Center, VStack } from '@chakra-ui/react';
import { useQueryWithErrorBoundary, useSupabaseTypedClient } from 'hooks/reactQuery';
import { ReactNode } from 'react';

import { ReactComponent as MaintenanceModeIllustration } from 'assets/illustrations/MaintenanceMode.svg';
import Text from 'components/Text';
import { useMediaQueries } from 'layout/MediaQueriesProvider';

interface IMaintenanceModeProps {
  children: ReactNode;
}

const MaintenanceMode = ({ children }: IMaintenanceModeProps) => {
  const { isMobile } = useMediaQueries();
  const supabase = useSupabaseTypedClient();

  const { data } = useQueryWithErrorBoundary({
    queryKey: ['maintenance-mode'],
    queryFn: async () => {
      const { data, error } = await supabase
        .from('metadata')
        .select('value')
        .eq('key', 'maintenance_mode')
        .single();

      if (error) throw error;

      return data;
    },
  });

  const isMaintenanceMode = data?.value === 'true';

  if (!isMaintenanceMode) {
    return children;
  }

  return (
    <Center height="100vh" p={isMobile ? 2 : 0} width="100%">
      <VStack spacing={5}>
        <MaintenanceModeIllustration width="100%" height="auto" />
        <VStack alignItems="center" mt={isMobile ? 4 : 2} spacing={isMobile ? 4 : 2}>
          <Text fontSize="xxxLarge" textAlign="center">
            BigDevSoon is temporarily unavailable.
          </Text>
          <Text fontSize="xxxLarge" fontWeight="regular" textAlign="center">
            {`We're`} performing a BIG update, stay tuned!
          </Text>
        </VStack>
      </VStack>
    </Center>
  );
};

export default MaintenanceMode;
