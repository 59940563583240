import { SupabaseClient } from '@supabase/supabase-js';
import { useQueryWithErrorBoundary, useSupabaseTypedClient } from 'hooks/reactQuery';

import { GetUserProfileParams } from 'types/edgeFunctions';
import { TanstackQueryName } from 'types/frontend';
import { Database } from 'types/supabase';

interface IGetUserIdQueryFnParams {
  supabase: SupabaseClient<Database>;
  userScreenName: string;
}

export const getUserIdQueryFn = async ({ supabase, userScreenName }: IGetUserIdQueryFnParams) => {
  const { data, error } = await supabase
    .from('user_profile_view')
    .select('id')
    .eq('screen_name', userScreenName)
    .single();

  if (error) throw error;

  return data.id;
};

interface IUseGetUserIdResponse {
  data: string | undefined | null;
  isPending: boolean;
}

export const useGetUserIdQuery = ({
  userScreenName,
}: GetUserProfileParams): IUseGetUserIdResponse => {
  const supabase = useSupabaseTypedClient();

  const { data, isPending } = useQueryWithErrorBoundary({
    queryKey: [TanstackQueryName.GetUserId, userScreenName],
    queryFn: () =>
      getUserIdQueryFn({
        supabase,
        userScreenName,
      }),
  });

  if (!data) {
    return {
      data: null,
      isPending,
    };
  }

  return {
    data,
    isPending,
  };
};
