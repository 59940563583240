import { Box } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import styled, { keyframes, css } from 'styled-components';

import { ReactComponent as Like } from 'assets/icons/Like.svg';

interface LikeIconProps {
  isLiked?: boolean;
  size?: number;
}

const pop = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.3); }
  100% { transform: scale(1); }
`;

const LikeContainer = styled(Box)<{ isLiked: boolean; shouldAnimate: boolean }>`
  transition: fill 0.2s ease-in-out;

  & > svg > path {
    fill: ${({ isLiked }) => (isLiked ? '#d6bcfa' : 'currentColor')};
  }

  ${({ shouldAnimate }) =>
    shouldAnimate &&
    css`
      animation: ${pop} 0.4s ease;
    `}
`;

const LikeIcon = ({ isLiked = false, size }: LikeIconProps) => {
  const [previouslyLiked, setPreviouslyLiked] = useState(isLiked);
  const [shouldAnimate, setShouldAnimate] = useState(false);

  useEffect(() => {
    if (isLiked && !previouslyLiked) {
      setShouldAnimate(true);
      const timer = setTimeout(() => setShouldAnimate(false), 400);
      return () => clearTimeout(timer);
    }
    setPreviouslyLiked(isLiked);
  }, [isLiked, previouslyLiked]);

  return (
    <LikeContainer isLiked={isLiked} shouldAnimate={shouldAnimate}>
      <Like
        {...(size && {
          width: size,
          height: size,
        })}
      />
    </LikeContainer>
  );
};

export default LikeIcon;
