import { Center, HStack, VStack, useTheme } from '@chakra-ui/react';
import CountUp from 'react-countup';

import Icon, { IconType } from 'components/Icon';
import Text from 'components/Text';

interface StatsBoxProps {
  icon: IconType;
  label: string;
  count: number;
  showHyphen?: boolean;
  width?: string;
}

export const StatsBox = ({ icon, label, count, showHyphen, width }: StatsBoxProps) => {
  const { borderRadius } = useTheme();

  return (
    <Center
      backgroundColor="whiteAlpha.200"
      borderRadius={borderRadius}
      height="100%"
      minH="130px"
      py={4}
      px={6}
      display="flex"
      width={width || 'initial'}
    >
      <VStack spacing={1}>
        <HStack>
          <Icon type={icon} size={32} />
          <Text fontSize="xLarge">{showHyphen ? '-' : <CountUp end={count} duration={1} />}</Text>
        </HStack>
        <Text
          fontSize="micro"
          fontWeight="regular"
          opacity="secondary"
          textAlign="center"
          whiteSpace="nowrap"
        >
          {label}
        </Text>
      </VStack>
    </Center>
  );
};
