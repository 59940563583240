import { Spinner, SpinnerProps, VStack } from '@chakra-ui/react';
import { useState, useEffect, useRef } from 'react';

import Text from './Text';

interface ILoaderProps extends SpinnerProps {
  text?: string;
}

const Loader = (props: ILoaderProps) => {
  const [showLoader, setShowLoader] = useState(false);

  const timeoutRef = useRef<any>(null);

  // Delay showing loader after 200 ms
  useEffect(() => {
    if (!showLoader) {
      timeoutRef.current = setTimeout(() => {
        setShowLoader(true);
      }, 200);
    }

    return () => clearTimeout(timeoutRef.current);
  }, [showLoader]);

  if (!showLoader) {
    return null;
  }

  return (
    <VStack spacing={4}>
      <Spinner size="lg" {...props} />
      {props.text && (
        <Text fontWeight="regular" fontSize="small">
          {props.text}
        </Text>
      )}
    </VStack>
  );
};

export default Loader;
