import { Box, HStack, useTheme, Grid, GridItem, Center } from '@chakra-ui/react';

import { useAuth } from 'auth/AuthProvider';
import { useCurrentUser } from 'auth/CurrentUserProvider';
import { plans } from 'config/constants/plans';
import { Plan_Type_Enum } from 'types/databaseEnums';
import { hasFirstCardAccessOnly } from 'utils/user';

import { BigBadge } from './BigBadge';
import GradientTooltip from './GradientTooltip';
import Icon from './Icon';
import Text from './Text';

interface IFeaturesListProps {
  showMonthly: boolean;
}

export const FeaturesList = ({ showMonthly }: IFeaturesListProps) => {
  const plansToMap = Object.values(plans).sort((a, b) => a.order - b.order);
  const { borderRadius } = useTheme();
  const { createdAt } = useCurrentUser();
  const { userId: authId } = useAuth();

  const filteredPlans = plansToMap.filter((plan) =>
    showMonthly ? plan.title !== Plan_Type_Enum.Yearly : plan.title !== Plan_Type_Enum.Monthly
  );

  const showProjectCardRow = !authId || hasFirstCardAccessOnly(createdAt);

  return (
    <Box width="100%" height="100%" mt="120px" id="bds-features-list">
      <Grid templateColumns="repeat(13, 1fr)" width="100%" overflowX="auto" position="relative">
        {/* Plans row */}
        <GridItem colSpan={4} minW="245px" />
        {filteredPlans.map((plan, index) => (
          <GridItem
            key={plan.title}
            backgroundColor="gray.800"
            borderWidth={1}
            colSpan={3}
            minW="245px"
            borderTopLeftRadius={index === 0 ? borderRadius : 0}
            borderTopRightRadius={index === plansToMap.length - 2 ? borderRadius : 0}
          >
            <Center>
              <HStack p={4} spacing={3}>
                <Icon type={plan.icon} size={32} />
                <Text textTransform="uppercase" fontWeight="semibold">
                  {plan.title}
                </Text>
              </HStack>
            </Center>
          </GridItem>
        ))}

        {/* BigToken credits row */}
        <GridItem
          minW="245px"
          colSpan={13}
          borderTopLeftRadius={borderRadius}
          p={4}
          backgroundColor="gray.700"
        >
          <HStack>
            <Icon type="Bigtokens" />
            <Text fontSize="xLarge">BigTokens</Text>
            <GradientTooltip label="BigTokens are like credits, you can use them for AI-generated code solutions while learning by coding, get robust code & design reports, and create new prototypes. Each of these actions costs 1 BigToken.">
              <Box cursor="pointer">
                <Icon type="PricingTooltip" size={20} />
              </Box>
            </GradientTooltip>
          </HStack>
        </GridItem>

        {/* Recharging rows */}
        <GridItem
          minW="245px"
          colSpan={4}
          p={4}
          backgroundColor="gray.800"
          borderLeftWidth={1}
          borderRightWidth={1}
        >
          <HStack>
            <Text>Recharging of tokens</Text>
            <GradientTooltip label="Each month we will be recharging your account with tokens based on the plan, this is a base number of tokens as you can store extra tokens from various activities too.">
              <Box cursor="pointer">
                <Icon type="PricingTooltip" size={20} />
              </Box>
            </GradientTooltip>
          </HStack>
        </GridItem>
        <GridItem minW="245px" colSpan={3} p={4} backgroundColor="gray.800" borderRightWidth={1}>
          <Center>
            <HStack spacing={1}>
              <Text fontWeight="semibold">3</Text>
              <Icon type="Bigtokens" size={20} />
              <Text opacity="secondary">/month</Text>
            </HStack>
          </Center>
        </GridItem>
        {showMonthly && (
          <GridItem minW="245px" colSpan={3} p={4} backgroundColor="gray.800" borderRightWidth={1}>
            <Center>
              <Center>
                <HStack spacing={1}>
                  <Text fontWeight="semibold">10</Text>
                  <Icon type="Bigtokens" size={20} />
                  <Text opacity="secondary">/month</Text>
                </HStack>
              </Center>
            </Center>
          </GridItem>
        )}
        {!showMonthly && (
          <GridItem minW="245px" colSpan={3} p={4} backgroundColor="gray.800" borderRightWidth={1}>
            <Center>
              <HStack spacing={1}>
                <Text fontWeight="semibold">20</Text>
                <Icon type="Bigtokens" size={20} />
                <Text opacity="secondary">/month</Text>
              </HStack>
            </Center>
          </GridItem>
        )}
        <GridItem minW="245px" colSpan={3} p={4} backgroundColor="gray.800" borderRightWidth={1}>
          <Center>
            <HStack spacing={1}>
              <Text fontWeight="semibold">30</Text>
              <Icon type="Bigtokens" size={20} />
              <Text opacity="secondary">/month</Text>
            </HStack>
          </Center>
        </GridItem>

        {/* Learn by coding row */}
        <GridItem minW="245px" colSpan={13} p={4} backgroundColor="gray.700">
          <Text fontSize="xLarge">Learn by coding</Text>
        </GridItem>

        {/* AI-code solution rows */}
        <GridItem
          minW="245px"
          colSpan={4}
          p={4}
          backgroundColor="gray.800"
          borderLeftWidth={1}
          borderRightWidth={1}
        >
          <HStack>
            <Text>AI-code solutions</Text>
            <GradientTooltip label="Generate an AI-code-based solution to your project or challenge based on a design screenshot, or generate solutions for practice problems.">
              <Box cursor="pointer">
                <Icon type="PricingTooltip" size={20} />
              </Box>
            </GradientTooltip>
          </HStack>
        </GridItem>
        <GridItem minW="245px" colSpan={3} p={4} backgroundColor="gray.800" borderRightWidth={1}>
          <Center>
            <HStack spacing={1}>
              <Text fontWeight="semibold">1</Text>
              <Icon type="Bigtokens" size={20} />
              <Text opacity="secondary">/each</Text>
            </HStack>
          </Center>
        </GridItem>
        {showMonthly && (
          <GridItem minW="245px" colSpan={3} p={4} backgroundColor="gray.800" borderRightWidth={1}>
            <Center>
              <HStack spacing={1}>
                <Text fontWeight="semibold">1</Text>
                <Icon type="Bigtokens" size={20} />
                <Text opacity="secondary">/each</Text>
              </HStack>
            </Center>
          </GridItem>
        )}
        {!showMonthly && (
          <GridItem minW="245px" colSpan={3} p={4} backgroundColor="gray.800" borderRightWidth={1}>
            <Center>
              <HStack spacing={1}>
                <Text fontWeight="semibold">1</Text>
                <Icon type="Bigtokens" size={20} />
                <Text opacity="secondary">/each</Text>
              </HStack>
            </Center>
          </GridItem>
        )}
        <GridItem minW="245px" colSpan={3} p={4} backgroundColor="gray.800" borderRightWidth={1}>
          <Center>
            <HStack spacing={1}>
              <Text fontWeight="semibold">1</Text>
              <Icon type="Bigtokens" size={20} />
              <Text opacity="secondary">/each</Text>
            </HStack>
          </Center>
        </GridItem>

        {/* Real-world projects rows */}
        <GridItem
          minW="245px"
          colSpan={4}
          p={4}
          backgroundColor="gray.800"
          borderLeftWidth={1}
          borderRightWidth={1}
        >
          <HStack>
            <Text>Real-world projects</Text>
            <GradientTooltip label="Access all of our 20+ projects including cards that guide you through project completion.">
              <Box cursor="pointer">
                <Icon type="PricingTooltip" size={20} />
              </Box>
            </GradientTooltip>
          </HStack>
        </GridItem>
        <GridItem minW="245px" colSpan={3} p={4} backgroundColor="gray.800" borderRightWidth={1}>
          <Center>
            <Icon type="PricingCheck" size={20} />
          </Center>
        </GridItem>
        {showMonthly && (
          <GridItem minW="245px" colSpan={3} p={4} backgroundColor="gray.800" borderRightWidth={1}>
            <Center>
              <Icon type="PricingCheck" size={20} />
            </Center>
          </GridItem>
        )}
        {!showMonthly && (
          <GridItem minW="245px" colSpan={3} p={4} backgroundColor="gray.800" borderRightWidth={1}>
            <Center>
              <Icon type="PricingCheck" size={20} />
            </Center>
          </GridItem>
        )}
        <GridItem minW="245px" colSpan={3} p={4} backgroundColor="gray.800" borderRightWidth={1}>
          <Center>
            <Icon type="PricingCheck" size={20} />
          </Center>
        </GridItem>

        {/* Daily challenges rows */}
        <GridItem
          minW="245px"
          colSpan={4}
          p={4}
          backgroundColor="gray.800"
          borderLeftWidth={1}
          borderRightWidth={1}
        >
          <HStack>
            <Text>Daily challenges</Text>
            <GradientTooltip label="Access all of our 100 daily challenges including design screenshots (JPEG).">
              <Box cursor="pointer">
                <Icon type="PricingTooltip" size={20} />
              </Box>
            </GradientTooltip>
          </HStack>
        </GridItem>
        <GridItem minW="245px" colSpan={3} p={4} backgroundColor="gray.800" borderRightWidth={1}>
          <Center>
            <Icon type="PricingCheck" size={20} />
          </Center>
        </GridItem>
        {showMonthly && (
          <GridItem minW="245px" colSpan={3} p={4} backgroundColor="gray.800" borderRightWidth={1}>
            <Center>
              <Icon type="PricingCheck" size={20} />
            </Center>
          </GridItem>
        )}
        {!showMonthly && (
          <GridItem minW="245px" colSpan={3} p={4} backgroundColor="gray.800" borderRightWidth={1}>
            <Center>
              <Icon type="PricingCheck" size={20} />
            </Center>
          </GridItem>
        )}
        <GridItem minW="245px" colSpan={3} p={4} backgroundColor="gray.800" borderRightWidth={1}>
          <Center>
            <Icon type="PricingCheck" size={20} />
          </Center>
        </GridItem>

        {/* Practice problems rows */}
        <GridItem
          minW="245px"
          colSpan={4}
          p={4}
          backgroundColor="gray.800"
          borderLeftWidth={1}
          borderRightWidth={1}
        >
          <HStack>
            <Text>Practice problems</Text>
            <GradientTooltip label="Access all of our 30+ practice problems including various test cases to pass and robust problem examples.">
              <Box cursor="pointer">
                <Icon type="PricingTooltip" size={20} />
              </Box>
            </GradientTooltip>
          </HStack>
        </GridItem>
        <GridItem minW="245px" colSpan={3} p={4} backgroundColor="gray.800" borderRightWidth={1}>
          <Center>
            <Icon type="PricingCheck" size={20} />
          </Center>
        </GridItem>
        {showMonthly && (
          <GridItem minW="245px" colSpan={3} p={4} backgroundColor="gray.800" borderRightWidth={1}>
            <Center>
              <Icon type="PricingCheck" size={20} />
            </Center>
          </GridItem>
        )}
        {!showMonthly && (
          <GridItem minW="245px" colSpan={3} p={4} backgroundColor="gray.800" borderRightWidth={1}>
            <Center>
              <Icon type="PricingCheck" size={20} />
            </Center>
          </GridItem>
        )}
        <GridItem minW="245px" colSpan={3} p={4} backgroundColor="gray.800" borderRightWidth={1}>
          <Center>
            <Icon type="PricingCheck" size={20} />
          </Center>
        </GridItem>

        {/* Community support rows */}
        <GridItem
          minW="245px"
          colSpan={4}
          p={4}
          backgroundColor="gray.800"
          borderLeftWidth={1}
          borderRightWidth={1}
        >
          <HStack>
            <Text>Community support</Text>
            <GradientTooltip label="Discuss projects, challenges, and problems, ask for feedback, and comment on other users' code as well as connect to our private Discord Community.">
              <Box cursor="pointer">
                <Icon type="PricingTooltip" size={20} />
              </Box>
            </GradientTooltip>
          </HStack>
        </GridItem>
        <GridItem minW="245px" colSpan={3} p={4} backgroundColor="gray.800" borderRightWidth={1}>
          <Center>
            <Icon type="PricingCheck" size={20} />
          </Center>
        </GridItem>
        {showMonthly && (
          <GridItem minW="245px" colSpan={3} p={4} backgroundColor="gray.800" borderRightWidth={1}>
            <Center>
              <Icon type="PricingCheck" size={20} />
            </Center>
          </GridItem>
        )}
        {!showMonthly && (
          <GridItem minW="245px" colSpan={3} p={4} backgroundColor="gray.800" borderRightWidth={1}>
            <Center>
              <Icon type="PricingCheck" size={20} />
            </Center>
          </GridItem>
        )}
        <GridItem minW="245px" colSpan={3} p={4} backgroundColor="gray.800" borderRightWidth={1}>
          <Center>
            <Icon type="PricingCheck" size={20} />
          </Center>
        </GridItem>

        {/* Project cards rows */}
        {showProjectCardRow && (
          <>
            <GridItem
              minW="245px"
              colSpan={4}
              p={4}
              backgroundColor="gray.800"
              borderLeftWidth={1}
              borderRightWidth={1}
            >
              <HStack>
                <Text>Project cards</Text>
                <GradientTooltip label="Access a comprehensive set of project cards, each breaking down projects into manageable Agile-like tasks, complete with dedicated visuals and resources to guide your step-by-step progression.">
                  <Box cursor="pointer">
                    <Icon type="PricingTooltip" size={20} />
                  </Box>
                </GradientTooltip>
              </HStack>
            </GridItem>
            <GridItem
              minW="245px"
              colSpan={3}
              p={4}
              backgroundColor="gray.800"
              borderRightWidth={1}
            >
              <Center>
                <Text>first card only</Text>
              </Center>
            </GridItem>
            {showMonthly && (
              <GridItem
                minW="245px"
                colSpan={3}
                p={4}
                backgroundColor="gray.800"
                borderRightWidth={1}
              >
                <Center>
                  <Text>all cards</Text>
                </Center>
              </GridItem>
            )}
            {!showMonthly && (
              <GridItem
                minW="245px"
                colSpan={3}
                p={4}
                backgroundColor="gray.800"
                borderRightWidth={1}
              >
                <Center>
                  <Icon type="PricingCheck" size={20} />
                </Center>
              </GridItem>
            )}
            <GridItem
              minW="245px"
              colSpan={3}
              p={4}
              backgroundColor="gray.800"
              borderRightWidth={1}
            >
              <Center>
                <Icon type="PricingCheck" size={20} />
              </Center>
            </GridItem>
          </>
        )}

        {/* Figma designs rows */}
        <GridItem
          minW="245px"
          colSpan={4}
          p={4}
          backgroundColor="gray.800"
          borderLeftWidth={1}
          borderRightWidth={1}
        >
          <HStack>
            <Text>Figma designs</Text>
            <GradientTooltip label="Access a collection of 120+ Figma designs tailored to mimic day-to-day Developer workflow and represent various resources and states unavailable on design screenshots.">
              <Box cursor="pointer">
                <Icon type="PricingTooltip" size={20} />
              </Box>
            </GradientTooltip>
          </HStack>
        </GridItem>
        <GridItem minW="245px" colSpan={3} p={4} backgroundColor="gray.800" borderRightWidth={1}>
          <Center>
            <Text fontSize="xLarge" fontWeight="semibold">
              —
            </Text>
          </Center>
        </GridItem>
        {showMonthly && (
          <GridItem minW="245px" colSpan={3} p={4} backgroundColor="gray.800" borderRightWidth={1}>
            <Center>
              <Icon type="PricingCheck" size={20} />
            </Center>
          </GridItem>
        )}
        {!showMonthly && (
          <GridItem minW="245px" colSpan={3} p={4} backgroundColor="gray.800" borderRightWidth={1}>
            <Center>
              <Icon type="PricingCheck" size={20} />
            </Center>
          </GridItem>
        )}
        <GridItem minW="245px" colSpan={3} p={4} backgroundColor="gray.800" borderRightWidth={1}>
          <Center>
            <Icon type="PricingCheck" size={20} />
          </Center>
        </GridItem>

        {/* Get creative row */}
        <GridItem minW="245px" colSpan={13} p={4} backgroundColor="gray.700">
          <Text fontSize="xLarge">Get creative</Text>
        </GridItem>

        {/* Prototypes rows */}
        <GridItem
          minW="245px"
          colSpan={4}
          p={4}
          backgroundColor="gray.800"
          borderLeftWidth={1}
          borderRightWidth={1}
        >
          <HStack>
            <Text>Prototypes</Text>
            <GradientTooltip label="Prototypes are a great way to learn how things work by reverse engineering, it just takes an idea. Then you use it to instruct AI about what should be generated and you can experiment with the results in our built-in Code Editor with live preview.">
              <Box cursor="pointer">
                <Icon type="PricingTooltip" size={20} />
              </Box>
            </GradientTooltip>
          </HStack>
        </GridItem>
        <GridItem minW="245px" colSpan={3} p={4} backgroundColor="gray.800" borderRightWidth={1}>
          <Center>
            <HStack spacing={1}>
              <Text fontWeight="semibold">1</Text>
              <Icon type="Bigtokens" size={20} />
              <Text opacity="secondary">/each</Text>
            </HStack>
          </Center>
        </GridItem>
        {showMonthly && (
          <GridItem minW="245px" colSpan={3} p={4} backgroundColor="gray.800" borderRightWidth={1}>
            <Center>
              <HStack spacing={1}>
                <Text fontWeight="semibold">1</Text>
                <Icon type="Bigtokens" size={20} />
                <Text opacity="secondary">/each</Text>
              </HStack>
            </Center>
          </GridItem>
        )}
        {!showMonthly && (
          <GridItem minW="245px" colSpan={3} p={4} backgroundColor="gray.800" borderRightWidth={1}>
            <Center>
              <HStack spacing={1}>
                <Text fontWeight="semibold">1</Text>
                <Icon type="Bigtokens" size={20} />
                <Text opacity="secondary">/each</Text>
              </HStack>
            </Center>
          </GridItem>
        )}
        <GridItem minW="245px" colSpan={3} p={4} backgroundColor="gray.800" borderRightWidth={1}>
          <Center>
            <HStack spacing={1}>
              <Text fontWeight="semibold">1</Text>
              <Icon type="Bigtokens" size={20} />
              <Text opacity="secondary">/each</Text>
            </HStack>
          </Center>
        </GridItem>

        {/* Code Frames rows */}
        <GridItem
          minW="245px"
          colSpan={4}
          p={4}
          backgroundColor="gray.800"
          borderLeftWidth={1}
          borderRightWidth={1}
        >
          <HStack>
            <Text>Code Frames</Text>
            <GradientTooltip label="Code Frames are your window on code, you can create empty ones or fork existing code that you liked from other users and experiment with it.">
              <Box cursor="pointer">
                <Icon type="PricingTooltip" size={20} />
              </Box>
            </GradientTooltip>
          </HStack>
        </GridItem>
        <GridItem minW="245px" colSpan={3} p={4} backgroundColor="gray.800" borderRightWidth={1}>
          <Center>
            <Text>up to 10</Text>
          </Center>
        </GridItem>
        {showMonthly && (
          <GridItem minW="245px" colSpan={3} p={4} backgroundColor="gray.800" borderRightWidth={1}>
            <Center>
              <Text>up to 100</Text>
            </Center>
          </GridItem>
        )}
        {!showMonthly && (
          <GridItem minW="245px" colSpan={3} p={4} backgroundColor="gray.800" borderRightWidth={1}>
            <Center>
              <Text>up to 100</Text>
            </Center>
          </GridItem>
        )}
        <GridItem minW="245px" colSpan={3} p={4} backgroundColor="gray.800" borderRightWidth={1}>
          <Center>
            <Text>up to 100</Text>
          </Center>
        </GridItem>

        {/* Code Editor row */}
        <GridItem minW="245px" colSpan={13} p={4} backgroundColor="gray.700">
          <Text fontSize="xLarge">Code Editor</Text>
        </GridItem>

        {/* Reports rows */}
        <GridItem
          minW="245px"
          colSpan={4}
          p={4}
          backgroundColor="gray.800"
          borderLeftWidth={1}
          borderRightWidth={1}
        >
          <HStack>
            <Text>Code & design reports</Text>
            <GradientTooltip label="Generate robust AI-based code & design reports for any code you created including projects, challenges, practice problems solutions, prototypes, and code frames.">
              <Box cursor="pointer">
                <Icon type="PricingTooltip" size={20} />
              </Box>
            </GradientTooltip>
          </HStack>
        </GridItem>
        <GridItem minW="245px" colSpan={3} p={4} backgroundColor="gray.800" borderRightWidth={1}>
          <Center>
            <HStack spacing={1}>
              <Text fontWeight="semibold">1</Text>
              <Icon type="Bigtokens" size={20} />
              <Text opacity="secondary">/each</Text>
            </HStack>
          </Center>
        </GridItem>
        {showMonthly && (
          <GridItem minW="245px" colSpan={3} p={4} backgroundColor="gray.800" borderRightWidth={1}>
            <Center>
              <HStack spacing={1}>
                <Text fontWeight="semibold">1</Text>
                <Icon type="Bigtokens" size={20} />
                <Text opacity="secondary">/each</Text>
              </HStack>
            </Center>
          </GridItem>
        )}
        {!showMonthly && (
          <GridItem minW="245px" colSpan={3} p={4} backgroundColor="gray.800" borderRightWidth={1}>
            <Center>
              <HStack spacing={1}>
                <Text fontWeight="semibold">1</Text>
                <Icon type="Bigtokens" size={20} />
                <Text opacity="secondary">/each</Text>
              </HStack>
            </Center>
          </GridItem>
        )}
        <GridItem minW="245px" colSpan={3} p={4} backgroundColor="gray.800" borderRightWidth={1}>
          <Center>
            <HStack spacing={1}>
              <Text fontWeight="semibold">1</Text>
              <Icon type="Bigtokens" size={20} />
              <Text opacity="secondary">/each</Text>
            </HStack>
          </Center>
        </GridItem>

        {/* Basic access rows */}
        <GridItem
          minW="245px"
          colSpan={4}
          p={4}
          backgroundColor="gray.800"
          borderLeftWidth={1}
          borderRightWidth={1}
        >
          <HStack>
            <Text>Basic access</Text>
            <GradientTooltip label="All of our users can use built-in Code Editor with HTML, CSS, JS tabs, live preview, and various tools, only signed users can save the code.">
              <Box cursor="pointer">
                <Icon type="PricingTooltip" size={20} />
              </Box>
            </GradientTooltip>
          </HStack>
        </GridItem>
        <GridItem minW="245px" colSpan={3} p={4} backgroundColor="gray.800" borderRightWidth={1}>
          <Center>
            <Icon type="PricingCheck" size={20} />
          </Center>
        </GridItem>
        {showMonthly && (
          <GridItem minW="245px" colSpan={3} p={4} backgroundColor="gray.800" borderRightWidth={1}>
            <Center>
              <Icon type="PricingCheck" size={20} />
            </Center>
          </GridItem>
        )}
        {!showMonthly && (
          <GridItem minW="245px" colSpan={3} p={4} backgroundColor="gray.800" borderRightWidth={1}>
            <Center>
              <Icon type="PricingCheck" size={20} />
            </Center>
          </GridItem>
        )}
        <GridItem minW="245px" colSpan={3} p={4} backgroundColor="gray.800" borderRightWidth={1}>
          <Center>
            <Icon type="PricingCheck" size={20} />
          </Center>
        </GridItem>

        {/* Code limits rows */}
        <GridItem
          minW="245px"
          colSpan={4}
          p={4}
          backgroundColor="gray.800"
          borderLeftWidth={1}
          borderRightWidth={1}
        >
          <HStack>
            <Text>Characters limit</Text>
            <GradientTooltip label="Each HTML, CSS, and JS tab has a separate character limit based on the plan.">
              <Box cursor="pointer">
                <Icon type="PricingTooltip" size={20} />
              </Box>
            </GradientTooltip>
          </HStack>
        </GridItem>
        <GridItem minW="245px" colSpan={3} p={4} backgroundColor="gray.800" borderRightWidth={1}>
          <Center>
            <Text>up to 10,000</Text>
          </Center>
        </GridItem>
        {showMonthly && (
          <GridItem minW="245px" colSpan={3} p={4} backgroundColor="gray.800" borderRightWidth={1}>
            <Center>
              <Text>up to 20,000</Text>
            </Center>
          </GridItem>
        )}
        {!showMonthly && (
          <GridItem minW="245px" colSpan={3} p={4} backgroundColor="gray.800" borderRightWidth={1}>
            <Center>
              <Text>up to 20,000</Text>
            </Center>
          </GridItem>
        )}
        <GridItem minW="245px" colSpan={3} p={4} backgroundColor="gray.800" borderRightWidth={1}>
          <Center>
            <Text>up to 20,000</Text>
          </Center>
        </GridItem>

        {/* AI Buddy rows */}
        <GridItem
          minW="245px"
          colSpan={4}
          p={4}
          backgroundColor="gray.800"
          borderLeftWidth={1}
          borderRightWidth={1}
        >
          <HStack>
            <Text>AI Buddy</Text>
            <GradientTooltip label="ChatGPT AI-based buddy - Elise is your code companion and can help in various scenarios, it is helpful to brainstorm together, get unstuck, ask for code fragments, and more.">
              <Box cursor="pointer">
                <Icon type="PricingTooltip" size={20} />
              </Box>
            </GradientTooltip>
          </HStack>
        </GridItem>
        <GridItem minW="245px" colSpan={3} p={4} backgroundColor="gray.800" borderRightWidth={1}>
          <Center>
            <Text fontSize="xLarge" fontWeight="semibold">
              —
            </Text>
          </Center>
        </GridItem>
        {showMonthly && (
          <GridItem minW="245px" colSpan={3} p={4} backgroundColor="gray.800" borderRightWidth={1}>
            <Center>
              <HStack spacing={1}>
                <Text>700 messages</Text>
                <Text opacity="secondary">/month</Text>
              </HStack>
            </Center>
          </GridItem>
        )}
        {!showMonthly && (
          <GridItem minW="245px" colSpan={3} p={4} backgroundColor="gray.800" borderRightWidth={1}>
            <Center>
              <HStack spacing={1}>
                <Text>700 messages</Text>
                <Text opacity="secondary">/month</Text>
              </HStack>
            </Center>
          </GridItem>
        )}
        <GridItem minW="245px" colSpan={3} p={4} backgroundColor="gray.800" borderRightWidth={1}>
          <Center>
            <HStack spacing={1}>
              <Text>700 messages</Text>
              <Text opacity="secondary">/month</Text>
            </HStack>
          </Center>
        </GridItem>

        {/* Private code rows */}
        <GridItem
          minW="245px"
          colSpan={4}
          p={4}
          backgroundColor="gray.800"
          borderLeftWidth={1}
          borderRightWidth={1}
        >
          <HStack>
            <Text>Private code</Text>
            <GradientTooltip label="When the code is made private, it will not show anywhere in our app but you can still share it via URL with other people.">
              <Box cursor="pointer">
                <Icon type="PricingTooltip" size={20} />
              </Box>
            </GradientTooltip>
          </HStack>
        </GridItem>
        <GridItem minW="245px" colSpan={3} p={4} backgroundColor="gray.800" borderRightWidth={1}>
          <Center>
            <Text fontSize="xLarge" fontWeight="semibold">
              —
            </Text>
          </Center>
        </GridItem>
        {showMonthly && (
          <GridItem minW="245px" colSpan={3} p={4} backgroundColor="gray.800" borderRightWidth={1}>
            <Center>
              <Icon type="PricingCheck" size={20} />
            </Center>
          </GridItem>
        )}
        {!showMonthly && (
          <GridItem minW="245px" colSpan={3} p={4} backgroundColor="gray.800" borderRightWidth={1}>
            <Center>
              <Icon type="PricingCheck" size={20} />
            </Center>
          </GridItem>
        )}
        <GridItem minW="245px" colSpan={3} p={4} backgroundColor="gray.800" borderRightWidth={1}>
          <Center>
            <Icon type="PricingCheck" size={20} />
          </Center>
        </GridItem>

        {/* Additional benefits row */}
        <GridItem minW="245px" colSpan={13} p={4} backgroundColor="gray.700">
          <Text fontSize="xLarge">Additional benefits</Text>
        </GridItem>

        {/* Dedicated Discord channels row */}
        <GridItem
          minW="245px"
          colSpan={4}
          p={4}
          backgroundColor="gray.800"
          borderLeftWidth={1}
          borderRightWidth={1}
        >
          <HStack>
            <Text>Discord channel</Text>
            <GradientTooltip label="For our most supportive users including Yearly subscribers and Lifetime buyers, we have special Discord channels where you can ask for priority support, or chat to strengthen the bonds!">
              <Box cursor="pointer">
                <Icon type="PricingTooltip" size={20} />
              </Box>
            </GradientTooltip>
          </HStack>
        </GridItem>
        <GridItem minW="245px" colSpan={3} p={4} backgroundColor="gray.800" borderRightWidth={1}>
          <Center>
            <Text fontSize="xLarge" fontWeight="semibold">
              —
            </Text>
          </Center>
        </GridItem>
        {showMonthly && (
          <GridItem minW="245px" colSpan={3} p={4} backgroundColor="gray.800" borderRightWidth={1}>
            <Center>
              <Text fontSize="xLarge" fontWeight="semibold">
                —
              </Text>
            </Center>
          </GridItem>
        )}
        {!showMonthly && (
          <GridItem minW="245px" colSpan={3} p={4} backgroundColor="gray.800" borderRightWidth={1}>
            <Center>
              <Text>Yearly Daredevils</Text>
            </Center>
          </GridItem>
        )}
        <GridItem minW="245px" colSpan={3} p={4} backgroundColor="gray.800" borderRightWidth={1}>
          <Center>
            <Text>Lifetime Legends</Text>
          </Center>
        </GridItem>

        {/* Badges row */}
        {/* Last item has special borders */}
        <GridItem
          minW="245px"
          colSpan={4}
          p={4}
          backgroundColor="gray.800"
          borderLeftWidth={1}
          borderRightWidth={1}
          borderBottomWidth={1}
          borderBottomLeftRadius={borderRadius}
        >
          <HStack>
            <Text>Badges</Text>
            <GradientTooltip label="Last but not least, as our token of appreciation we created special badges for the beloved supporters, thanks a lot for being awesome!">
              <Box cursor="pointer">
                <Icon type="PricingTooltip" size={20} />
              </Box>
            </GradientTooltip>
          </HStack>
        </GridItem>
        <GridItem
          minW="245px"
          colSpan={3}
          p={4}
          backgroundColor="gray.800"
          borderRightWidth={1}
          borderBottomWidth={1}
        >
          <Center>
            <Text fontSize="xLarge" fontWeight="semibold">
              —
            </Text>
          </Center>
        </GridItem>
        {showMonthly && (
          <GridItem
            minW="245px"
            colSpan={3}
            p={4}
            backgroundColor="gray.800"
            borderRightWidth={1}
            borderBottomWidth={1}
          >
            <Center>
              <HStack>
                <BigBadge plan={Plan_Type_Enum.Monthly} />
                <Icon type="MonthlySubscriberBadge" label="Monthly Supporter" size={32} />
              </HStack>
            </Center>
          </GridItem>
        )}
        {!showMonthly && (
          <GridItem
            minW="245px"
            colSpan={3}
            p={4}
            backgroundColor="gray.800"
            borderRightWidth={1}
            borderBottomWidth={1}
          >
            <Center>
              <HStack>
                <BigBadge plan={Plan_Type_Enum.Yearly} />
                <Icon type="YearlySubscriberBadge" label="Yearly Daredevil" size={32} />
              </HStack>
            </Center>
          </GridItem>
        )}
        <GridItem
          minW="245px"
          colSpan={3}
          p={4}
          backgroundColor="gray.800"
          borderRightWidth={1}
          borderBottomWidth={1}
          borderBottomRightRadius={borderRadius}
        >
          <Center>
            <HStack>
              <BigBadge plan={Plan_Type_Enum.Lifetime} />
              <Icon type="LifetimeBadge" label="Lifetime Legend" size={32} />
            </HStack>
          </Center>
        </GridItem>
      </Grid>
    </Box>
  );
};
