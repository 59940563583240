import { Skill_Tag_Enum } from 'types/databaseEnums';

export const skillName: Record<Skill_Tag_Enum, string> = {
  security: 'Security',
  visualization: 'Visualization',
  performance: 'Performance',
  testing: 'Testing',
  automation: 'Automation',
  debugging: 'Debugging',
  layout: 'Layout',
  forms: 'Forms',
  integration: 'Integration',
  routing: 'Routing',
  website: 'Website',
  game: 'Game',
  application: 'Application',
  tool: 'Tool',
  library: 'Library',
  database: 'Database',
  setup: 'Setup',
  rwd: 'RWD',
  'node.js': 'Node.js',
  data_optimization: 'Data Optimization',
  realtime: 'Realtime',
};
