import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  InputGroup,
  InputLeftElement,
  Input,
  Stack,
  Divider,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { supabaseMutationFn, useSupabaseTypedClient } from 'hooks/reactQuery';
import { useToastWrapper } from 'hooks/useToastWrapper';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { useCurrentUser } from 'auth/CurrentUserProvider';
import SettingsTile from 'components/Billing/SettingsTile';
import Button from 'components/Button';
import Icon from 'components/Icon';
import Text from 'components/Text';
import { EdgeFunctionName, UpdateUserPrivateEmailParams } from 'types/edgeFunctions';
import { TanstackQueryName } from 'types/frontend';

interface EmailSettingsForm {
  privateEmail: string;
}

const validationSchema = yup
  .object({
    privateEmail: yup
      .string()
      .required('Email is required')
      .email('Email should be a valid email (e.g. john.doe@gmail.com)')
      .max(255, 'Email is too long (maximum is 255 characters)'),
  })
  .required();

type MutationVariables = { email: string };

export const EmailSettings = () => {
  const supabase = useSupabaseTypedClient();
  const queryClient = useQueryClient();

  const { privateEmail, id: currentUserId } = useCurrentUser();

  const { toastSuccess, toastError } = useToastWrapper();
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors, isDirty },
  } = useForm<EmailSettingsForm>({
    resolver: yupResolver(validationSchema),
    mode: 'onSubmit',
    defaultValues: {
      privateEmail,
    },
  });

  const mutation = useMutation<string | null, string, MutationVariables>({
    mutationFn: supabaseMutationFn(({ email }) =>
      supabase.functions.invoke<string>(EdgeFunctionName.UpdateUserPrivateEmail, {
        body: { email } as UpdateUserPrivateEmailParams,
      })
    ),
    onSuccess: (data) => {
      reset({
        privateEmail: data!,
      });

      queryClient.invalidateQueries({
        queryKey: [TanstackQueryName.GetCurrentUser, currentUserId],
      });
      queryClient.invalidateQueries({
        queryKey: [EdgeFunctionName.GetEmailNotifications, data!],
      });

      toastSuccess('Email saved');
    },
    onError: () => {
      toastError(`Can't save email`);
    },
  });

  const onSubmit = async (values: EmailSettingsForm) => {
    await mutation.mutate({ email: values.privateEmail });
  };

  return (
    <>
      <SettingsTile
        title="Email"
        subtitle="Private email used for payments and direct communication."
      >
        <form onSubmit={handleSubmit(onSubmit)} data-sl="mask">
          <Stack spacing={1}>
            <FormControl isInvalid={Boolean(errors.privateEmail)}>
              <FormLabel htmlFor="privateEmail" fontWeight="normal">
                Email
              </FormLabel>
              <InputGroup>
                <InputLeftElement pointerEvents="none">
                  <Icon type="Email" />
                </InputLeftElement>
                <Input
                  {...register('privateEmail')}
                  variant="filled"
                  placeholder="Add email (e.g. john.doe@gmail.com)"
                />
              </InputGroup>
              <FormErrorMessage>{errors.privateEmail?.message}</FormErrorMessage>
            </FormControl>
            <Text fontSize="micro" opacity="secondary">
              {`This email is private and only visible to you, don’t share it with other users.`}
            </Text>
          </Stack>
          <Divider mt={6} mb={5} />
          <Button type="submit" isLoading={mutation.isPending} isDisabled={!isDirty}>
            Save email
          </Button>
        </form>
      </SettingsTile>
    </>
  );
};
