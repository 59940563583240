import { SupabaseClient } from '@supabase/supabase-js';
import { useQueryWithErrorBoundary, useSupabaseTypedClient } from 'hooks/reactQuery';

import { useCurrentUser } from 'auth/CurrentUserProvider';
import { CodeEditorType, CodePreview } from 'types/edgeFunctions';
import { TanstackQueryName } from 'types/frontend';
import { Database } from 'types/supabase';

interface IGetSolutionsQueryFnParams {
  supabase: SupabaseClient<Database>;
  userId: string;
  currentUserId: string;
  type: CodeEditorType;
}

export const getSolutionsQueryFn = async ({
  supabase,
  userId,
  currentUserId,
  type,
}: IGetSolutionsQueryFnParams): Promise<CodePreview[]> => {
  const solutionsQuery = supabase
    .from('user_code_view')
    .select('*')
    .eq('user_id', userId)
    .eq('type', type)
    .order('completed_date', { ascending: false });

  if (userId !== currentUserId) {
    solutionsQuery.eq('is_private', false);
  }

  const solutions = await solutionsQuery;

  if (solutions.error) throw solutions.error;

  return solutions.data;
};

interface IGetProfileCodeQueryResponse {
  data: CodePreview[] | undefined | null;
  isPending: boolean;
}

interface IGetProfileCodeQueryParams {
  userId?: string | null;
  type: CodeEditorType;
}

export const useGetProfileCodeQuery = ({
  userId,
  type,
}: IGetProfileCodeQueryParams): IGetProfileCodeQueryResponse => {
  const supabase = useSupabaseTypedClient();
  const { id: currentUserId } = useCurrentUser();

  const { data, isPending } = useQueryWithErrorBoundary({
    queryKey: [TanstackQueryName.GetProfileCode, userId, type],
    queryFn: () =>
      getSolutionsQueryFn({
        supabase,
        userId: userId!,
        currentUserId,
        type,
      }),
    enabled: Boolean(userId),
  });

  if (!data) {
    return {
      data: null,
      isPending,
    };
  }

  return {
    data,
    isPending,
  };
};
