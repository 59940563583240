import { SupabaseClient } from '@supabase/supabase-js';
import { useQueryWithErrorBoundary, useSupabaseTypedClient } from 'hooks/reactQuery';

import { useCurrentUser } from 'auth/CurrentUserProvider';
import { Plan_Type_Enum } from 'types/databaseEnums';
import { GetPracticeProblemSolutionResponse, SummaryReport } from 'types/edgeFunctions';
import { TanstackQueryName } from 'types/frontend';
import { Database } from 'types/supabase';

interface IGetPracticeProblemSolutionQueryFnParams {
  supabase: SupabaseClient<Database>;
  currentUserId: string;
  solutionUrl: string;
}

export const getSolutionQueryFn = async ({
  supabase,
  currentUserId,
  solutionUrl,
}: IGetPracticeProblemSolutionQueryFnParams) => {
  const solution = await supabase
    .from('user_practice_problem')
    .select(
      'id, completed_date, is_private, code, score, report, ai_feedback, title, user_profile_view (id, avatar_url, name, screen_name, plan, experience), practice_problem (id)'
    )
    .eq('solution_url', solutionUrl)
    .single();

  if (solution.error) throw solution.error;

  const [likes, isLikedByMe, comments] = await Promise.all([
    supabase.rpc('count_likes', { input_entity_id: solution.data.id }),
    currentUserId
      ? supabase
          .from('user_like')
          .select('voter_id')
          .eq('voter_id', currentUserId)
          .eq('entity_id', solution.data.id)
      : Promise.resolve(undefined),
    supabase
      .from('support_center')
      .select('*', { count: 'exact', head: true })
      .eq('entity_id', solution.data.id)
      .not('user_id', 'is', null),
  ]);

  if (likes.error) throw likes.error;
  if (isLikedByMe?.error) throw isLikedByMe.error;
  if (comments.error) throw comments.error;

  return {
    id: solution.data.id,
    title: solution.data.title || '',
    is_private: solution.data.is_private || false,
    code: solution.data.code || '',
    user: {
      id: solution.data.user_profile_view!.id || '',
      avatar_url: solution.data.user_profile_view!.avatar_url || '',
      name: solution.data.user_profile_view!.name || '',
      screen_name: solution.data.user_profile_view!.screen_name || '',
      plan: solution.data.user_profile_view!.plan as Plan_Type_Enum,
      xp: solution.data.user_profile_view!.experience || 0,
    },
    likes: likes.data || 0,
    is_liked: (isLikedByMe?.data || []).length > 0,
    comments_count: comments.count || 0,
    memory_usage: 0,
    completed_date: solution.data.completed_date || '',
    score: solution.data.score || null,
    report: (solution.data.report as SummaryReport[]) || null,
    ai_feedback: solution.data.ai_feedback || '',
  };
};

interface IUseGetPracticeProblemSolutionResponse {
  data: GetPracticeProblemSolutionResponse | undefined | null;
  isPending: boolean;
}

interface IUseGetPracticeProblemSolutionQueryParams {
  solutionUrl: string;
}

export const useGetPracticeProblemSolutionQuery = ({
  solutionUrl,
}: IUseGetPracticeProblemSolutionQueryParams): IUseGetPracticeProblemSolutionResponse => {
  const { id: currentUserId } = useCurrentUser();
  const supabase = useSupabaseTypedClient();

  const { data, isPending } = useQueryWithErrorBoundary({
    queryKey: [TanstackQueryName.GetPracticeProblemSolution, solutionUrl],
    queryFn: () =>
      getSolutionQueryFn({
        supabase,
        currentUserId: currentUserId,
        solutionUrl,
      }),
    enabled: Boolean(solutionUrl),
  });

  if (!data) {
    return {
      data: null,
      isPending,
    };
  }

  return {
    data,
    isPending,
  };
};
