import { Center, HStack } from '@chakra-ui/react';
import { differenceInCalendarDays } from 'date-fns';

import Icon, { IconType } from 'components/Icon';
import Text from 'components/Text';

interface ICardShortInfoProps {
  brand: string;
  last4: string;
  expirationDate?: Date;
}

const brands: Record<string, IconType> = {
  visa: 'Visa',
  amex: 'AmEx',
  unionpay: 'UnionPay',
  mastercard: 'MasterCard',
};

export const CardShortInfo = ({ brand, last4, expirationDate }: ICardShortInfoProps) => {
  let isAboutToExpire = false;
  let isExpired = false;
  let expirationDiff = 0;
  if (expirationDate) {
    expirationDiff = differenceInCalendarDays(expirationDate, new Date());
    if (expirationDiff < 0) {
      isExpired = true;
    } else if (expirationDiff <= 30) {
      isAboutToExpire = true;
    }
  }

  return (
    <>
      <HStack
        borderRadius={8}
        bg="whiteAlpha.200"
        px={4}
        h={16}
        background={isAboutToExpire ? '#FBD38D5C' : isExpired ? '#FC81815C' : 'whiteAlpha.200'}
        borderLeft={
          isAboutToExpire ? 'solid #FBD38DDE 8px' : isExpired ? 'solid #FC8181DE 8px' : ''
        }
        shadow={
          isAboutToExpire
            ? '0px 4px 20px rgba(0, 0, 0, 0.16)'
            : '0px 4px 24px 0px rgba(0, 0, 0, 0.24)'
        }
      >
        {brand && (
          <Center w={10} h={6} bg="rgba(255, 255, 255, 0.87)" borderRadius={2}>
            <Icon type={brands[brand]} />
          </Center>
        )}
        <Text fontSize="small" opacity="primary">
          {brand === 'paypal' ? 'PayPal' : `**** **** **** ${last4}`}
        </Text>
      </HStack>
      {isAboutToExpire && (
        <HStack mt="8px !important">
          <Icon type="Warning" />
          <Text fontSize="small" opacity="primary">
            Your credit card will expire in {expirationDiff} days
          </Text>
        </HStack>
      )}
      {isExpired && (
        <HStack mt="8px !important">
          <Icon type="WarningTwo" />
          <Text textColor="red.300" fontSize="small" opacity="primary">
            Your credit card has expired. Change the payment method to keep the payment flow.
          </Text>
        </HStack>
      )}
    </>
  );
};
