import {
  Card_Type_Enum,
  Experience_Level_Enum,
  Image_Type_Enum,
  Plan_Type_Enum,
  Practice_Problem_Type_Enum,
  Skill_Tag_Enum,
  Support_Type_Enum,
  User_Card_Status_Enum,
  User_Challenge_Status_Enum,
  User_Practice_Problem_Status_Enum,
  User_Project_Status_Enum,
} from './databaseEnums.ts';
import { Database } from './supabase.ts';

export enum SubscriptionStatus {
  Inactive = 0,
  Trial = 1,
  TrialExpired = 2,
  Active = 3,
  Canceled = 4,
  Terminate = 5,
}

export type PaymentMethodType = 'card' | 'paypal';

export type Limits = {
  chatMessageLimit: number;
};

export enum ProjectMode {
  Speedrun = 'Speedrun',
  Freerun = 'Freerun',
}

export type CurrentUser = {
  id: string;
  name: string;
  codeFramesCount: number;
  screenName: string;
  publicEmail: string;
  privateEmail: string;
  avatarUrl: string;
  githubUrl: string;
  createdAt: string;
  isGithubConnected: boolean;
  tokensAvailable: number;
  extraTokens: number;
  rechargeTokens: number;
  bigTokensCollected: number;
  experience: number;
  isTerminated: boolean;
  isFree: boolean;
  isMonthlySubscriber: boolean;
  isYearlySubscriber: boolean;
  isLifetimeUser: boolean;
  isSuperUser: boolean;
  hasDiscordRegistered: boolean;
  hasExhaustedReferralLink: boolean;
  referralCode: string;
  plan: Plan_Type_Enum;
  nextTokenRecycleTime: Date;
  chatMessageCounter: number;
  nextChatMessageRecycleTime: Date | null;
  githubAccessToken: string;
} & Limits;

export type UserProfile = {
  avatar_url: string;
  created_at: string;
  name: string;
  location: string;
  public_email: string;
  website_url: string;
  github_url: string;
  linkedin_url: string;
  devto_url: string;
  twitter_url: string;
  bio: string;
  goal: string;
  experience: number;
  rank: string;
  likes: number;
  has_beta_badge: boolean;
  has_monthly_badge: boolean;
  has_yearly_badge: boolean;
  has_early_bird_badge: boolean;
  has_lifetime_badge: boolean;
  plan: Plan_Type_Enum | null;
};

export type SubscriptionDetails = {
  brand: string | null;
  expiration_date: string | null;
  last4: string | null;
  next_billing_time: string | null;
  plan: Plan_Type_Enum | null;
  amount: number;
  status: SubscriptionStatus;
  timestamp: string | null;
  payment_method_type: string | null;
};

export type RecentUser = {
  user_id: string;
  avatar_url: string;
  name: string;
  screen_name: string;
};

export type ProjectTag = {
  id: string;
  tag_type: Skill_Tag_Enum;
};

export type ProjectImage = {
  id: string;
  sequence: number;
  title: string;
  url: string;
  image_type: Image_Type_Enum;
};

export type ProjectPreview = {
  id: string;
  screen_name: string;
  tags: ProjectTag[];
  difficulty_lvl: Experience_Level_Enum;
  title: string;
  description: string;
  horizontal_thumbnail: ProjectImage;
  project_hd_thumbnail: ProjectImage;
  recent_users: RecentUser[];
  users_completed_count: number;
  is_new: boolean;
  status: User_Project_Status_Enum;
  card: { id: string }[];
};

export type Challenge = {
  id: string;
  screen_name: string;
  screenshot_url: string;
  title: string;
  status: User_Challenge_Status_Enum;
  repository_url: string;
  preview_url: string;
  solution_title: string;
  discussion_count: number;
  solutions_count: number;
  completed_date?: string | null;
  is_figma_downloaded?: boolean | null;
};

export type TestCase = {
  id: number;
  input: unknown[];
  output: unknown;
};

export type TestCases = {
  function_name: string;
  input_names: string[];
  tests: TestCase[];
};

export type PracticeProblem = {
  id: string;
  function_name: string;
  screen_name: string;
  description: string;
  title: string;
  test_cases: TestCases;
  difficulty: Experience_Level_Enum;
  status: User_Practice_Problem_Status_Enum;
  solution_title: string;
  discussion_count: number;
  solutions_count: number;
};

export type PracticeProblemPreview = {
  id: string;
  screen_name: string;
  title: string;
  status: User_Practice_Problem_Status_Enum;
  difficulty: Experience_Level_Enum;
  type: Practice_Problem_Type_Enum;
  recent_users: RecentUser[];
  users_completed_count: number;
  created_at: string;
  diff_score: number;
};

export type GetProjectResponse = Project;
export type GetChallengeResponse = Challenge;
export type GetPracticeProblemResponse = PracticeProblem;

export type GetProjectsResponse = ProjectPreview[];
export type GetChallengesResponse = ChallengePreview[];
export type GetPracticeProblemsResponse = PracticeProblemPreview[];
export type GetCodeFramesResponse = CodePreview[];
export type GetPrototypesResponse = CodePreview[];

export type SubmitChecks = {
  repository_url_exists: boolean;
  live_preview_url_exists: boolean;
  live_preview_url_unique: boolean;
  html_tag_exists: boolean;
  bds_tag_exists: boolean;
};

export type ChallengePreview = {
  id: string;
  created_at: string;
  screen_name: string;
  title: string;
  screenshot_url: string;
  order: number;
  status: User_Challenge_Status_Enum;
  recent_users: RecentUser[];
  users_completed_count: number;
};

export type ProjectCard = {
  id: string;
  screen_name: string;
  title: string;
  description?: string | null;
  difficulty_level: Experience_Level_Enum;
  type: Card_Type_Enum;
  screenshot_url?: string | null;
  status: User_Card_Status_Enum;
};

export type Project = {
  id: string;
  user_project_id?: string | null;
  screen_name: string;
  design_screenshot: ProjectImage;
  tags: ProjectTag[];
  glitch_url: string | null;
  replit_url: string | null;
  difficulty_lvl: Experience_Level_Enum;
  discord_channel_id: string;
  title: string;
  description: string;
  cards: ProjectCard[];
  status: User_Project_Status_Enum;
  repository_url: string;
  preview_url: string;
  solution_title: string;
  discussion_count: number;
  solutions_count: number;
  completed_date?: string | null;
  is_figma_downloaded?: boolean | null;
};

export type UserProjectTechnology = {
  id: string;
  technology: Skill_Tag_Enum;
};

export type CreateDesignUrlParams = {
  screenName: string;
  type: CodeEditorType;
  entityId: string;
};

export type GetChallengeParams = {
  challengeScreenName: string;
};

export type GetPracticeProblemParams = {
  practiceProblemScreenName: string;
};

export type GetProjectParams = {
  projectScreenName: string;
};

export type ProtectorParams = {
  pass: string;
};

export type GetUserProfileParams = {
  userScreenName: string;
};

export type UpdateUserPrivateEmailParams = {
  email: string;
};

export type SubscribeParams = {
  plan: Plan_Type_Enum;
  isPPP: boolean;
};

export type TokenPackageParams = {
  tokenPackageId: string;
  path: string;
};

export type CancelSubscriptionParams = {
  reason: string;
  comment: string;
};

export type UpdateGithubAccessTokenParams = {
  accessToken: string;
};

export type UpdatePricingCounterParams = {
  count: number;
};

export type UploadUserAvatarParams = FormData;

export type CodeEditorType = 'project' | 'challenge' | 'problem' | 'code-frame' | 'prototype';

export type SubmitCodeParams = {
  entityId?: string | null;
  entityScreenName: string;
  type: CodeEditorType;
  title: string;
  is_private: boolean;
  html?: string;
  css?: string;
  js?: string;
  feedback?: string;
  repository_url?: string;
  live_preview_url?: string;
  iframe_screenshot?: string;
  screenshot_url?: string;
  prompt_text?: string | null;
  prompt_image_url?: string | null;
  prompt_url?: string | null;
};

export type RenameCodeParams = {
  title: string;
  entityId: string;
  type: CodeEditorType;
  parentScreenName?: string | null;
  userScreenName?: string | null;
};

export type TogglePrivacyCodeParams = {
  isPrivate: boolean;
  entityId: string;
  type: CodeEditorType;
};

export type DeleteCodeParams = {
  entityId: string;
  type: CodeEditorType;
};

export interface SubmitCodeResponse {
  solution_url: string;
  checks?: SubmitChecks;
  all_checks_passed: boolean;
}

export type UpdateUserProfileParams = {
  name?: string;
  location?: string;
  public_email?: string;
  website_url?: string;
  linkedin_url?: string;
  devto_url?: string;
  twitter_url?: string;
  bio?: string;
  goal?: string;
};

export const TECHNOLOGIES = [
  'React',
  'Preact',
  'Vue.js',
  'Angular',
  'Svelte',
  'Next.js',
  'Nuxt.js',
  'Gatsby',
  'Tailwind CSS',
  'Bootstrap',
  'Material UI',
  'Chakra UI',
  'Ant Design',
  'Styled Components',
  'Framer Motion',
  'Three.js',
  'GSAP',
  'Ember.js',
  'Backbone.js',
  'Aurelia',
  'Riot.js',
  'jQuery',
  'CSS',
  'HTML',
  'JavaScript',
  'TypeScript',
  'Laravel',
  'PWA',
  'D3',
  'Node.js',
  'Express.js',
  'Socket.IO',
  'Firebase',
  'GraphQL',
  'Prisma',
  'Meteor.js',
  'Redux',
  'Zustand',
  'Axios',
  'Cypress',
  'Webpack',
].sort((a, b) => a.localeCompare(b));

export type UserLeaderboard = UserPreview & {
  count: number;
};

export type UserPreview = {
  id: string;
  avatar_url: string;
  name: string;
  screen_name: string;
  plan: Plan_Type_Enum;
  xp: number;
};

export type CommunityQuestion = {
  id: string;
  user: UserPreview;
  message: string;
  created_at: string;
  thread_id: string;
};

export type ChallengeSolution = {
  id: string;
  challenge_id: string;
  title: string;
  is_private: boolean;
  solution_title: string;
  screen_name: string;
  screenshot_url: string;
  solution_url: string;
  created_at: string;
  completed_date: string;
  is_my_solution: boolean;
  user: UserPreview;
  live_preview_url: string;
  repository_url: string;
  likes: number;
  is_liked: boolean;
  comments_count: number;
  html: string;
  css: string;
  js: string;
  design_report?: SummaryReport[] | null;
  code_report?: SummaryReport[] | null;
  design_score?: number | null;
  code_score?: number | null;
  ai_feedback?: string | null;
};

export type ProjectSolution = {
  id: string;
  project_id: string;
  title: string;
  is_private: boolean;
  solution_title: string;
  screen_name: string;
  screenshot_url: string;
  solution_url: string;
  created_at: string;
  completed_date: string;
  is_my_solution: boolean;
  user: UserPreview;
  live_preview_url: string;
  repository_url: string;
  likes: number;
  is_liked: boolean;
  comments_count: number;
  html: string;
  css: string;
  js: string;
  design_report?: SummaryReport[] | null;
  code_report?: SummaryReport[] | null;
  design_score?: number | null;
  code_score?: number | null;
  ai_feedback?: string | null;
};

export type ProjectSolutionPreview = {
  id: string;
  title: string;
  solution_title: string;
  screenshot_url: string;
  repository_url: string;
  preview_url: string;
  solution_url: string;
  user: UserPreview;
  likes: number;
  is_liked: boolean;
  comments_count: number;
  completed_date: string;
  html: string;
  css: string;
  js: string;
  design_report?: SummaryReport[] | null;
  code_report?: SummaryReport[] | null;
  design_score?: number | null;
  code_score?: number | null;
  ai_feedback?: string | null;
};

export type ChallengeSolutionPreview = {
  id: string;
  title: string;
  solution_title: string;
  screenshot_url: string;
  repository_url: string;
  preview_url: string;
  solution_url: string;
  user: UserPreview;
  likes: number;
  is_liked: boolean;
  comments_count: number;
  completed_date: string;
  html: string;
  css: string;
  js: string;
  design_report?: SummaryReport[] | null;
  code_report?: SummaryReport[] | null;
  design_score?: number | null;
  code_score?: number | null;
  ai_feedback?: string | null;
};

export type CodeFrame = {
  id: string;
  title: string;
  is_private: boolean;
  screen_name: string;
  screenshot_url: string;
  solution_url: string;
  preview_url: string;
  created_at: string;
  is_my_solution: boolean;
  user: UserPreview;
  live_preview_url: string;
  repository_url: string;
  likes: number;
  is_liked: boolean;
  comments_count: number;
  solutions_count: number;
  html: string;
  css: string;
  js: string;
  design_report?: SummaryReport[] | null;
  code_report?: SummaryReport[] | null;
  design_score?: number | null;
  code_score?: number | null;
  ai_feedback?: string | null;
};

export type Prototype = {
  id: string;
  title: string;
  is_private: boolean;
  screen_name: string;
  screenshot_url: string;
  solution_url: string;
  preview_url: string;
  created_at: string;
  is_my_solution: boolean;
  user: UserPreview;
  live_preview_url: string;
  repository_url: string;
  likes: number;
  is_liked: boolean;
  comments_count: number;
  solutions_count: number;
  html: string;
  css: string;
  js: string;
  design_report?: SummaryReport[] | null;
  code_report?: SummaryReport[] | null;
  design_score?: number | null;
  code_score?: number | null;
  ai_feedback?: string | null;
  prompt_text?: string | null;
  prompt_image_url?: string | null;
  prompt_url?: string | null;
};

export type SummaryReport = {
  factor: string;
  content: string[];
  score: number;
};

export type PracticeProblemSolutionPreview = {
  id: string;
  code: string;
  solution_url: string;
  user: UserPreview;
  title: string;
  likes: number;
  is_liked: boolean;
  comments_count: number;
  completed_date: string;
  score?: number | null;
  report?: SummaryReport[] | null;
  ai_feedback?: string | null;
};

export type PracticeProblemSolution = {
  id: string;
  code: string;
  user: UserPreview;
  title: string;
  is_private: boolean;
  likes: number;
  is_liked: boolean;
  comments_count: number;
  completed_date: string;
  score?: number | null;
  report?: SummaryReport[] | null;
  ai_feedback?: string | null;
};

export type DiscordInviteParams = {
  returnUrl: string;
};

export type DiscordSignParams = {
  code: string;
  state: string;
};

export type AccountDeleteParams = {
  reason: string;
};

export type EstimateCardParams = {
  user_card_id: string;
  estimate: number;
};

export type GetProjectSolutionResponse = ProjectSolution;

export type GetChallengeSolutionResponse = ChallengeSolution;

export type GetPrototypeResponse = Prototype;

export type GetCodeFrameResponse = CodeFrame;

export type CodePreview = Database['public']['Views']['user_code_view']['Row'];

export type GetProjectSolutionsResponse = CodePreview[];

export type GetChallengeSolutionsResponse = CodePreview[];

export type GetPracticeProblemsSolutionsResponse = CodePreview[];

export type GetPracticeProblemSolutionResponse = PracticeProblemSolution;

export type LikeParams = {
  entity_id: string;
  user_id: string;
  is_liked: boolean;
};

export type LikeResponse = {
  entity_id: string;
  is_liked: boolean;
};

export type CreateGithubRepositoryParams = {
  user_project_id: string;
};

export type CreateGithubRepositoryResponse = {
  repository_url: string;
};

export type Achievement = {
  id: string;
  name: string;
  description: string;
  type: string;
  xp: number;
  is_completed: boolean;
  timestamp: string;
};

export enum NotificationMessageType {
  WELCOME = 'welcome',
  USER_LIKE = 'user_like_notification',
  USER_COMMENT = 'user_comment_notification',
}

export type NotificationMessage = Database['public']['Views']['user_notification_view']['Row'];

export enum FeedMessageType {
  PROJECT_DISCUSSION = 'project_discussion',
  CHALLENGE_DISCUSSION = 'challenge_discussion',
  PRACTICE_PROBLEM_DISCUSSION = 'practice_problem_discussion',
  PROJECT_CODE_COMMENT = 'project_code_comment',
  CHALLENGE_CODE_COMMENT = 'challenge_code_comment',
  PRACTICE_PROBLEM_CODE_COMMENT = 'practice_problem_code_comment',
  CODE_FRAME_COMMENT = 'code_frame_comment',
  PROTOTYPE_COMMENT = 'prototype_comment',
  USER_SUBSCRIPTION = 'user_subscription',
  USER_PROJECT_COMPLETED = 'user_project_completed',
  USER_CHALLENGE_COMPLETED = 'user_challenge_completed',
  USER_PRACTICE_PROBLEM_COMPLETED = 'user_practice_problem_completed',
  USER_TOKEN_TRANSACTION = 'user_token_transaction',
}

export type FeedMessage = Database['public']['Views']['user_feed_data_view']['Row'];

export type PlanDetails = { discounted_price: number; base_price: number; priceTotal?: number };
export type PlanRecord = Record<string, PlanDetails>;
export type GetPriceResponse = {
  discount: number;
  plans: PlanRecord;
  country_flag: string;
  country_name: string;
};

export enum TypeFilter {
  XP = 'xp',
  LIKES = 'likes',
}

export enum TimeFilter {
  TODAY = 'today',
  WEEKLY = 'weekly',
  MONTHLY = 'monthly',
  ALL_TIME = 'all_time',
}

export type GetLeaderboardParams = {
  type: TypeFilter;
  time: TimeFilter;
};

export type GetLeaderboardResponse = {
  leaderboard: UserLeaderboard[];
};

export type GetAchievementsResponse = {
  achievements: Achievement[];
};

export type AccountCreateParams = {
  github_access_token: string;
  ref_code?: string;
};

export type GetCommunityQuestionsParams = {
  entity_id: string;
  type: Support_Type_Enum;
};

export type CompleteCardParams = {
  card_id: string;
  user_project_id?: string | null;
  is_completed: boolean;
};

export type GetCommunityQuestionsResponse = CommunityQuestion[];

export type AskCommunityQuestionParams = {
  type: Support_Type_Enum;
  entity_id: string;
  message: string;
};

export type DeleteCommunityQuestionParams = {
  id: string;
};

export type AskChatGptType =
  | 'ask-elise'
  | 'generate-project-solution'
  | 'generate-challenge-solution'
  | 'generate-problem-solution'
  | 'generate-project-summary'
  | 'generate-challenge-summary'
  | 'generate-problem-code-summary'
  | 'generate-code-frame-summary'
  | 'generate-prototype-summary';

export type AskChatGptParams = {
  type: AskChatGptType;
  message?: string;
  entityId?: string;
};

export type Who = 'Elise' | 'You';

export type ChatGptMessageStatus = 'sending' | 'typing' | 'received';

export type ChatGptMessage = {
  id: string;
  created_at: string;
  who: Who;
  message: string;
  status: ChatGptMessageStatus;
};

export type ManageEmailNotificationsParams = {
  shouldSubscribeToAppUpdates?: boolean | null;
  shouldSubscribeToBiWeeklyNewsletter?: boolean | null;
  newEmail?: string | null;
};

export type GetSolutionParams = {
  solution_url: string;
};

export type UpdateUserNotificationDateParams = {
  timestamp: string;
};

export type GetEmailNotificationsResponse = {
  isSubscribedToAppUpdates: boolean;
  isSubscribedToBiWeeklyNewsletter: boolean;
};

export enum EdgeFunctionName {
  AccountCreate = 'account-create',
  GetSubscriptionDetails = 'get-subscription-details',
  UpdateUserProfile = 'update-user-profile',
  Protector = 'protector',
  UpdateGithubAccessToken = 'update-github-access-token',
  UpdateUserPrivateEmail = 'update-user-private-email',
  CreateDesignUrl = 'create-design-url',
  UploadUserAvatar = 'upload-user-avatar',
  SubmitCode = 'submit-code',
  RenameCode = 'rename-code',
  UpdateLegacySolution = 'update-legacy-solution',
  TogglePrivacyCode = 'toggle-privacy-code',
  DeleteCode = 'delete-code',
  Like = 'like',
  AskCommunityQuestion = 'ask-community-question',
  DeleteCommunityQuestion = 'delete-community-question',
  AskChatGpt = 'ask-chat-gpt',
  CompleteCard = 'complete-card',
  TakeScreenshot = 'take-screenshot',
  DiscordInvite = 'discord-invite',
  DiscordDetach = 'discord-detach',
  DiscordSignIn = 'discord-sign',
  SubscriptionCancel = 'subscription-cancel',
  SubscriptionUndoCancelation = 'subscription-undo-cancelation',
  GithubDetach = 'github-detach',
  AccountDelete = 'account-delete',
  Subscribe = 'subscribe',
  PaymentMethodChange = 'payment-method-change',
  ManageEmailNotifications = 'manage-email-notifications',
  GetEmailNotifications = 'get-email-notifications',
  Checkout = 'checkout',
  UpdateUserNotificationDate = 'update-user-notification-date',
  GetPrice = 'get-price',
  UpdatePricingCounter = 'update-pricing-counter',
}

export enum MetadataKey {
  CHAT_MESSAGE_LIMIT = 'chat_message_limit',
  BETA_END_DATE = 'beta_end_date',
  EARLY_BIRDS_NUMBER = 'early_birds_number',
  DISCORD_CURRENT_MEMBERS = 'discord_current_members',
  DISCORD_CURRENT_ONLINE = 'discord_current_online',
}

export enum RepositoryUrlType {
  GIT_HUB = 'github',
  REPLIT = 'replit',
  GLITCH = 'glitch',
  CODE_EDITOR = 'code_editor',
}
