import {
  VStack,
  Box,
  Stack,
  HStack,
  Image,
  Checkbox,
  useTheme,
  Container,
  useDisclosure,
} from '@chakra-ui/react';
import { useMutation } from '@tanstack/react-query';
import { format } from 'date-fns';
import { useGetPriceQuery } from 'hooks/queries/billing/useGetPrice';
import { supabaseMutationFn, useSupabaseTypedClient } from 'hooks/reactQuery';
import { useScrollToTop } from 'hooks/useScrollToTop';
import { useEffect, useState } from 'react';

import { useAuth } from 'auth/AuthProvider';
import { useCurrentUser } from 'auth/CurrentUserProvider';
import BigTokens from 'components/BigTokens';
import PlanBox from 'components/Billing/PlanBox';
import Button from 'components/Button';
import { FeaturesList } from 'components/FeaturesList';
import Icon from 'components/Icon';
import Link from 'components/Link';
import { TokensModal } from 'components/Modal/TokensModal';
import PageLoader from 'components/PageLoader';
import { SEO } from 'components/SEO';
import Tabs from 'components/Tabs';
import Text from 'components/Text';
import { DATE_FORMAT, YearlyInfoBox, plans } from 'config/constants/plans';
import { useMediaQueries } from 'layout/MediaQueriesProvider';
import { Plan_Type_Enum } from 'types/databaseEnums';
import { EdgeFunctionName, UpdatePricingCounterParams } from 'types/edgeFunctions';
import { ERoute } from 'types/frontend';

import { FAQ } from './FAQ';

const Pricing = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const { userId } = useAuth();

  const supabase = useSupabaseTypedClient();

  const isSignedIn = !!userId;

  const mutation = useMutation<boolean, string, UpdatePricingCounterParams>({
    mutationFn: supabaseMutationFn(({ count }) =>
      supabase.functions.invoke<boolean>(EdgeFunctionName.UpdatePricingCounter, {
        body: { count } as UpdatePricingCounterParams,
      })
    ),
    onError: () => {
      // silent error updating under the hood
    },
  });

  useEffect(() => {
    if (!isSignedIn) {
      return;
    }

    supabase.functions.invoke(EdgeFunctionName.UpdatePricingCounter, {
      body: { count: 1 } as UpdatePricingCounterParams,
    });
  }, []);

  useScrollToTop();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const { isSmallScreen } = useMediaQueries();
  const { borderRadius } = useTheme();
  const { data, isPending } = useGetPriceQuery();
  const [isPPPActive, setIsPPPActive] = useState(false);
  const {
    isMonthlySubscriber,
    isYearlySubscriber,
    isLifetimeUser,
    plan,
    rechargeTokens,
    nextTokenRecycleTime,
  } = useCurrentUser();

  const isSubscriber = isMonthlySubscriber || isYearlySubscriber || isLifetimeUser;

  useEffect(() => {
    if ((data?.discount || 0) > 0) {
      setIsPPPActive(true);
    }
  }, [data]);

  const showPPP = (data?.discount || 0) > 0;

  if (isPending || !data) {
    return <PageLoader shift />;
  }

  const renderSubscriberInfo = () => {
    return (
      <VStack width="100%" spacing={3}>
        <Stack mt={8} mb={2} direction={['column', 'column', 'row', 'row']}>
          <Text fontSize="xxxLarge" fontWeight="semibold" textAlign="center">
            You are currently on a {` `}
          </Text>
          <Text
            fontSize="xxxLarge"
            color={plans[plan].color}
            fontWeight="semibold"
            opacity="default"
            textAlign="center"
          >
            {plan} Plan
          </Text>
        </Stack>
        <HStack spacing={1}>
          <Text fontWeight="regular">which recharges</Text>
          <BigTokens amount={rechargeTokens} />
          <Text fontWeight="semibold">BigTokens monthly.</Text>
        </HStack>
        <HStack spacing={1}>
          <Text fontWeight="regular">Next recharge date:</Text>
          <Text fontWeight="semibold">{format(new Date(nextTokenRecycleTime), DATE_FORMAT)}.</Text>
        </HStack>
        <Text fontWeight="regular">
          Manage your subscription in the <Link to={ERoute.Billing}>Billing page</Link>.
        </Text>
        <Button
          mt={2}
          scheme="gradient"
          onClick={onOpen}
          leftIcon={<Icon type="Bigtokens" size={24} />}
        >
          Get BigTokens now
        </Button>
        <TokensModal isOpen={isOpen} onClose={onClose} />
        <Stack width="100%" alignItems="flex-end" mt={8}>
          <Tabs
            tabs={['Yearly', 'Monthly']}
            tabIndex={tabIndex}
            onChange={(index) => setTabIndex(index)}
          />
        </Stack>
      </VStack>
    );
  };

  return (
    <Container maxW="1400px">
      <SEO
        title="BigDevSoon | Upgrade plan"
        description={`Choose the best plan that fits your needs.`}
        url={`${process.env.REACT_APP_SITE_URL}unlock-big`}
      />
      <VStack width="100%">
        {isSubscriber ? (
          renderSubscriberInfo()
        ) : (
          <Text fontSize="xxxLarge" fontWeight="semibold" textAlign="center" my={8}>
            Choose a plan that fits your needs
          </Text>
        )}
        {!isSubscriber && showPPP && (
          <Box
            width="100%"
            padding={4}
            borderRadius={8}
            background="linear-gradient(90deg, rgba(159, 122, 234, 0.87) 0%, rgba(237, 137, 54, 0.87) 100%)"
          >
            <VStack spacing={4}>
              <Text fontWeight="semibold" fontSize="large" textTransform={'uppercase'}>
                It looks like you are based in{' '}
                <span
                  style={{
                    display: 'inline-block',
                    verticalAlign: 'middle',
                    paddingLeft: 4,
                    paddingRight: 4,
                  }}
                >
                  <Image src={data?.country_flag} boxSize="24px" borderRadius={16} />
                </span>{' '}
                {data?.country_name}
              </Text>
              <Text fontWeight="medium" textAlign={'center'}>
                We embrace Purchasing Power Parity (PPP), so a {data?.discount}% discount will{' '}
                <br />
                be automatically applied during checkout! 😊
              </Text>
              <Box bg={'gray.900'} borderRadius={borderRadius} px={3} py={2}>
                <Checkbox
                  sx={{
                    '.chakra-checkbox__control': {
                      _checked: {
                        bg: 'purple.600',
                        borderColor: 'purple.600',
                        color: 'white',
                        _hover: {
                          bg: 'purple.500',
                          borderColor: 'purple.500',
                        },
                      },
                    },
                  }}
                  isChecked={isPPPActive}
                  onChange={(e) => setIsPPPActive(e.target.checked)}
                >
                  <Text>{data?.discount}% Discount Activated*</Text>
                </Checkbox>
              </Box>
              <Text fontSize="micro" fontWeight="regular">
                {`*PPP discount can't be stacked with promo codes`}
              </Text>
            </VStack>
          </Box>
        )}
        {!isSubscriber && (
          <HStack justifyContent="space-between" width="100%" mb={4}>
            <VStack alignItems="flex-end" width="100%" spacing={4}>
              <Box
                borderRadius="md"
                borderWidth={1}
                borderColor="purple.200"
                backgroundColor="whiteAlpha.200"
                p={2}
                mt={showPPP ? 4 : 0}
              >
                <Text fontWeight="regular" color="purple.200">
                  Save 33% with Yearly vs Monthly plan
                </Text>
              </Box>
              <Tabs
                tabs={['Yearly', 'Monthly']}
                tabIndex={tabIndex}
                onChange={(index) => setTabIndex(index)}
              />
            </VStack>
          </HStack>
        )}
        {!isSubscriber && (
          <Stack
            justifyContent="center"
            alignItems="stretch"
            spacing={8}
            width={isSmallScreen ? '80%' : '100%'}
            height="100%"
            direction={['column', 'column', 'column', 'column', 'row']}
          >
            <PlanBox
              plan={Plan_Type_Enum.Free}
              basePriceSpot={0}
              priceSpot={0}
              discount={0}
              isPPPActive={false}
            />
            <PlanBox
              plan={Plan_Type_Enum.Lifetime}
              basePriceSpot={data?.plans![Plan_Type_Enum.Lifetime].base_price}
              priceSpot={data?.plans![Plan_Type_Enum.Lifetime].discounted_price}
              discount={data?.discount}
              isPPPActive={isPPPActive}
              border="fancy"
              onClick={() => {
                mutation.mutate({ count: 10 });
              }}
            />
            {tabIndex === 0 ? (
              <PlanBox
                plan={Plan_Type_Enum.Yearly}
                basePriceSpot={(data?.plans![Plan_Type_Enum.Yearly].base_price || 0) / 12}
                priceSpot={(data?.plans![Plan_Type_Enum.Yearly].discounted_price || 0) / 12}
                discount={data?.discount}
                customInfoBox={
                  <YearlyInfoBox
                    totalPrice={
                      isPPPActive
                        ? data?.plans![Plan_Type_Enum.Yearly].discounted_price || 0
                        : data?.plans![Plan_Type_Enum.Yearly].base_price || 0
                    }
                  />
                }
                isPPPActive={isPPPActive}
                onClick={() => {
                  mutation.mutate({ count: 5 });
                }}
              />
            ) : (
              <PlanBox
                plan={Plan_Type_Enum.Monthly}
                basePriceSpot={data?.plans![Plan_Type_Enum.Monthly].base_price}
                priceSpot={data?.plans![Plan_Type_Enum.Monthly].discounted_price}
                discount={data?.discount}
                isPPPActive={isPPPActive}
                onClick={() => {
                  mutation.mutate({ count: 1 });
                }}
              />
            )}
          </Stack>
        )}
        <FeaturesList showMonthly={tabIndex === 1} />
        <FAQ />
      </VStack>
    </Container>
  );
};

export default Pricing;
