import { HStack, VStack } from '@chakra-ui/react';

import { BigBadge } from 'components/BigBadge';
import Button from 'components/Button';
import Icon from 'components/Icon';
import Modal from 'components/Modal';
import Text from 'components/Text';
import { Plan_Type_Enum } from 'types/databaseEnums';
import { ERoute, IModal } from 'types/frontend';

interface IUnlockBigModalProps extends IModal {}

export const UnlockBigModal = ({ isOpen, onClose }: IUnlockBigModalProps) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      header={
        <HStack>
          <Icon type="UpgradeBig" size={40} />
          <Text fontSize="xLarge" fontWeight="semibold">
            Unlock BIG
          </Text>
        </HStack>
      }
      footer={
        <>
          <Button scheme="ghost" onClick={onClose}>
            Cancel
          </Button>
          <Button
            leftIcon={<Icon type="UpgradePlan" />}
            scheme="gradient"
            to={ERoute.Pricing}
            autoFocus
          >
            Upgrade plan
          </Button>
        </>
      }
    >
      <VStack align={'start'}>
        <HStack spacing={1}>
          <Text fontWeight="regular">Become </Text>
          <BigBadge plan={Plan_Type_Enum.Lifetime} size="small" />
          <Text fontWeight="regular">to be able to use this action.</Text>
        </HStack>
        <Text fontWeight="regular">
          You need to upgrade your plan to either a monthly, yearly or lifetime before continuing.
        </Text>
      </VStack>
    </Modal>
  );
};
