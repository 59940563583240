import { HStack, useTheme, VStack } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

import Button from 'components/Button';
import Icon from 'components/Icon';
import Text from 'components/Text';
import { ERoute } from 'types/frontend';

export const FreeState = () => {
  const { borderRadius } = useTheme();
  const navigate = useNavigate();

  return (
    <>
      <VStack align="stretch" spacing={6}>
        <VStack align="stretch" spacing={6} shadow="0px 4px 24px 0px rgba(0, 0, 0, 0.24)">
          <VStack bg="whiteAlpha.200" opacity="8" borderRadius={borderRadius} p={6} align="stretch">
            <HStack align="stretch" justifyContent="space-between">
              <VStack align="stretch" spacing={4}>
                <HStack>
                  <Icon type="FreePlan" size={48} />
                  <HStack>
                    <Text fontSize="xxLarge">Free Plan</Text>
                  </HStack>
                </HStack>
                <Text opacity="secondary">Explore the application for free.</Text>
              </VStack>
            </HStack>
          </VStack>
        </VStack>
        <Button
          leftIcon={<Icon type="UpgradePlan" />}
          width="fit-content"
          scheme="gradient"
          onClick={() => navigate(ERoute.Pricing)}
        >
          <Text>Check pricing and Unlock BIG!</Text>
        </Button>
      </VStack>
    </>
  );
};
