import { ChevronRightIcon } from '@chakra-ui/icons';
import {
  useDisclosure,
  Divider,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  HStack,
  VStack,
  useTheme,
  Box,
  Image,
  CircularProgress,
  Center,
  Spinner,
} from '@chakra-ui/react';
import { InfiniteData } from '@tanstack/react-query';
import { useGetChallengesQuery } from 'hooks/queries/challenges/useGetChallengesQuery';
import { useQueryWithErrorBoundary, useSupabaseTypedClient } from 'hooks/reactQuery';
import React from 'react';

import { useCurrentUser } from 'auth/CurrentUserProvider';
import Button from 'components/Button';
import GradientTooltip from 'components/GradientTooltip';
import Icon from 'components/Icon';
import Link from 'components/Link';
import PageLoader from 'components/PageLoader';
import Text from 'components/Text';
import { StatusIconMapper } from 'features/practice-problems/PracticeProblems';
import { User_Challenge_Status_Enum } from 'types/databaseEnums';
import { ChallengePreview } from 'types/edgeFunctions';
import { ERoute, TanstackQueryName } from 'types/frontend';

interface IChallengesListProps {
  data: ChallengePreview[];
  onClose: () => void;
}

const ChallengesList = ({ data, onClose }: IChallengesListProps) => {
  const { borderRadius } = useTheme();

  return (
    <>
      <VStack width="100%" spacing={2}>
        {data.map(({ id, status, title, screen_name, screenshot_url }, index) => {
          return (
            <Link
              to={`${ERoute.Challenges}/${screen_name}`}
              onClick={onClose}
              key={id}
              width="100%"
              _hover={{ backgroundColor: 'whiteAlpha.300', textDecoration: 'none' }}
              borderRadius={borderRadius}
              transition="all 0.2s ease-out"
              p={3}
              backgroundColor="whiteAlpha.200"
            >
              <HStack width="100%" justifyContent="space-between">
                <HStack alignItems="center" spacing={4}>
                  <Box>{StatusIconMapper[status]}</Box>
                  <Image
                    objectFit="initial"
                    maxWidth="100%"
                    height="70px"
                    borderRadius={borderRadius}
                    fallbackStrategy="onError"
                    fallback={
                      <Center
                        width="100%"
                        height="100%"
                        minHeight={70}
                        background="white"
                        borderRadius={borderRadius}
                      >
                        <Text color="black">Image not found</Text>
                      </Center>
                    }
                    src={screenshot_url || ''}
                    alt={title}
                  />
                  <Text>
                    {index + 1}. {title}
                  </Text>
                </HStack>
              </HStack>
            </Link>
          );
        })}
      </VStack>
    </>
  );
};

const ChallengesDrawer = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { id: userId } = useCurrentUser();

  const supabase = useSupabaseTypedClient();

  const divRef = React.useRef<HTMLDivElement | null>(null);

  const { data: countData } = useQueryWithErrorBoundary({
    queryKey: [TanstackQueryName.GetChallengesCount, userId],
    queryFn: async () => {
      return supabase.from('challenge').select('*', { count: 'exact', head: true });
    },
  });

  const { data: completedCountData } = useQueryWithErrorBoundary({
    queryKey: [TanstackQueryName.GetChallengesCompletedCount, userId],
    queryFn: async () => {
      return supabase
        .from('user_challenge')
        .select('*', { count: 'exact', head: true })
        .eq('user_id', userId)
        .eq('status_type', User_Challenge_Status_Enum.Completed);
    },
  });

  const { data, isFetching, ref } = useGetChallengesQuery({ userId, tabIndex: 0 });

  const challengesData = (data as unknown as InfiniteData<ChallengePreview[]>) || [];
  const challenges =
    challengesData.pages.reduce((acc, page) => acc.concat(page), [] as ChallengePreview[]) || [];

  const progress =
    completedCountData?.count === 0
      ? 0
      : Math.floor(((completedCountData?.count || 0) / (countData?.count || 100)) * 100);

  const renderChallenges = () => {
    const isFirstPage = challengesData.pages.length === 0;

    if (isFirstPage && isFetching) {
      return <PageLoader shift />;
    }

    if (isOpen) {
      return (
        <>
          <ChallengesList data={challenges} onClose={onClose} />
          <div ref={ref} />
          <Center width="100%" mt={10}>
            {isFetching && !isFirstPage && <Spinner size="lg" />}
          </Center>
        </>
      );
    }
  };

  return (
    <>
      <div ref={divRef}>
        <Button
          scheme="ghost"
          onClick={onOpen}
          leftIcon={<Icon type="DrawerOpen" size={16} />}
          size="sm"
        >
          <Text fontSize="small" opacity="default">
            Challenges
          </Text>
        </Button>
      </div>
      <Drawer isOpen={isOpen} placement="left" onClose={onClose} finalFocusRef={divRef} size="md">
        <DrawerOverlay />
        <DrawerContent backgroundColor="gray.900">
          <DrawerCloseButton mt={1} />
          <DrawerHeader pb={3}>
            <HStack width="100%" justifyContent="space-between">
              <Link
                to={ERoute.Challenges}
                onClick={onClose}
                color="white"
                _hover={{ textDecoration: 'none' }}
              >
                <HStack spacing={1}>
                  <Text fontSize="large" fontWeight="semibold">
                    Challenges
                  </Text>
                  <ChevronRightIcon />
                </HStack>
              </Link>
              <GradientTooltip
                label={`${completedCountData?.count || 0}/${countData?.count || 100} completed`}
              >
                <CircularProgress
                  value={progress}
                  color="green.400"
                  thickness="6px"
                  size={4}
                  mr={8}
                  mb={1}
                />
              </GradientTooltip>
            </HStack>
          </DrawerHeader>
          <Divider />
          <DrawerBody p={5}>{renderChallenges()}</DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default ChallengesDrawer;
