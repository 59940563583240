import { CodeEditorType } from 'types/edgeFunctions';

export const HTML_LEARN_TEMPLATE = (
  documentationUrl: string,
  name: string,
  type: CodeEditorType
) => `<!DOCTYPE html>
<html lang="en">
<head>
    <!-- Our unique tag to identify the solution -->
    <bds />
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>BigDevSoon ${name} ${type}</title>

    <!-- TailwindCSS for styling -->
    <script src="https://cdn.tailwindcss.com"></script>
</head>
<body class="bg-gray-900 flex flex-col items-center justify-center min-h-screen">
    <div class="bg-white text-gray-800 p-8 space-y-4 shadow rounded-xl max-w-md md:max-w-lg">
        <!-- Logo and Title -->
        <img src="https://mqzzwgavxmdmqvivwgez.supabase.co/storage/v1/object/public/previews/logo-challenge-template.svg" alt="BigDevSoon Logo" class="w-150 mx-auto">
        <h1 class="text-2xl font-bold text-center">${name} ${type}</h1>
        <!-- Challenge Introduction -->
        <p class="text">
            The goal is to transform the provided design screenshot into a functional, complete ${type}.
        </p>

        <!-- Tabs Description -->
        <ul class="list-disc list-inside space-y-2">
            <li>Documentation is available at: <strong><a href="${documentationUrl}" target="_blank" rel="noopener noreferrer" class="text-purple-400 hover:underline">GitHub</a></strong>.</li>
            <li>Use the <strong>Browser</strong> tab for the live preview of your code changes.</li>
            <li>Read the <strong>Instructions</strong> tab for ${type} details.</li>
            <li>Brainstorm with the <strong>AI Buddy</strong> to speed up and improve your implementation.</li>
            <li>Ask questions, and discuss with other folks in the <strong>Discussions</strong> tab.</li>
            <li>Browse community solutions in the <strong>Solutions</strong> tab for inspiration.</li>
        </ul>

        <!-- Generate Code Solution Mention -->
        <p class="text-sm">
            You can click the <strong>Generate code solution</strong> icon next to the Submit button to generate an AI-based code solution while stuck.
        </p>

        <p class="text-sm border-t pt-6">
            <strong>Tip</strong>: This example uses TailwindCSS for styling. Feel free to integrate other libraries using their CDNs to enhance your ${type}.
        </p>
    </div>
</body>
</html>
`;
