import { Box } from '@chakra-ui/react';

import { Experience_Level_Enum } from 'types/databaseEnums';
import { ProjectMode } from 'types/edgeFunctions';

import Icon, { IconType } from './Icon';

const sizeMapper = {
  xs: 16,
  s: 20,
  sm: 24,
  md: 32,
};

export type ProjectIconType =
  | ProjectMode
  | Experience_Level_Enum
  | 'Cards'
  | 'ExperienceBubble'
  | 'Bigtokens'
  | 'Calendar'
  | 'Clock'
  | 'Concept'
  | 'Task'
  | 'Lock'
  | 'Commits'
  | 'InProgressIndicator'
  | 'CompleteIndicator';

export interface ProjectIconProps {
  type: IconType;
  size?: 'xs' | 's' | 'sm' | 'md';
  display?: 'block' | 'inline';
  isDisabled?: boolean;
}

const ProjectIcon = ({ type, size = 'sm', display = 'block', isDisabled }: ProjectIconProps) => {
  return (
    <Box opacity={isDisabled ? 0.5 : 1}>
      <Icon type={isDisabled ? 'Lock' : type} size={sizeMapper[size]} display={display} />
    </Box>
  );
};

export default ProjectIcon;
