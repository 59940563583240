import numeral from 'numeral';

import { REF_COOKIE_KEY } from 'config/constants/github';

export const getNameFromUrl = (url: string) => {
  return url.split('/').pop() || '';
};

export const pluralize = (word: string, count: number) => {
  if (count === 1) {
    return word;
  }

  return `${word}s`;
};

export const billingInfo = `Your changes are being processed and will be visible shortly`;
export const contactInfo = `Contact us at support@bigdevsoon.me or try again later`;

export const referralBuilder = (refCode: string) => {
  return `${window.location.origin}/ref?${REF_COOKIE_KEY}=${refCode}`;
};

export const formatExecutionTime = (milliseconds: number) => {
  if (milliseconds < 1000) {
    return `${numeral(milliseconds).format('0.00')} ms`;
  } else if (milliseconds < 60000) {
    return `${numeral(milliseconds / 1000).format('0.00')} s`;
  } else {
    return `${numeral(milliseconds / 60000).format('0.00')} min`;
  }
};

export const formatMemoryUsage = (bytes: number) => {
  return numeral(bytes).format('0.00 b');
};
