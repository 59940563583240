import { HStack, Switch, VStack } from '@chakra-ui/react';
import { useMutation } from '@tanstack/react-query';
import { supabaseMutationFn, useSupabaseTypedClient } from 'hooks/reactQuery';
import { IUseTogglePrivacyCodeParams, useTogglePrivacyCode } from 'hooks/useTogglePrivacyCode';
import { debounce } from 'lodash';
import { useRef, useState } from 'react';

import { useCurrentUser } from 'auth/CurrentUserProvider';
import { Plan_Type_Enum } from 'types/databaseEnums';
import { CodeEditorType, EdgeFunctionName, TogglePrivacyCodeParams } from 'types/edgeFunctions';

import { BigBadge } from './BigBadge';
import GradientTooltip from './GradientTooltip';
import Text from './Text';

export interface IToggleCodePrivacySwitchProps {
  type: CodeEditorType;
  togglePrivacyCodeParams: IUseTogglePrivacyCodeParams;
  isNotCreated?: boolean | null;
  onChange?: (isPrivate: boolean) => void;
  alignItems?: 'flex-start' | 'center' | 'flex-end' | 'stretch' | 'baseline';
}

const ToggleCodePrivacySwitch = ({
  type,
  togglePrivacyCodeParams,
  isNotCreated,
  onChange,
  alignItems = 'flex-end',
}: IToggleCodePrivacySwitchProps) => {
  const [isChecked, setIsChecked] = useState(false);
  const supabase = useSupabaseTypedClient();
  const { plan } = useCurrentUser();

  const { onMutate, onError, onSettled } = useTogglePrivacyCode(togglePrivacyCodeParams);

  const mutation = useMutation<boolean, string, TogglePrivacyCodeParams>({
    mutationFn: supabaseMutationFn(({ entityId, type, isPrivate }) =>
      supabase.functions.invoke<boolean>(EdgeFunctionName.TogglePrivacyCode, {
        body: { entityId, type, isPrivate } as TogglePrivacyCodeParams,
      })
    ),
    onMutate: async () => await onMutate?.(),
    onError: (err, variables, context) => onError?.(err, variables, context),
    onSettled: () => onSettled?.(),
  });

  const debouncedMutate = useRef(debounce(mutation.mutate, 300));

  const isFreeUser = plan === Plan_Type_Enum.Free;

  return (
    <VStack alignItems={alignItems} width="100%">
      {isFreeUser && <BigBadge size="small" plan={Plan_Type_Enum.Lifetime} />}
      <GradientTooltip label={isFreeUser ? 'Upgrade plan to unlock' : null}>
        <HStack spacing={4}>
          <Text opacity="secondary">Public</Text>
          <Switch
            size="lg"
            isChecked={isNotCreated ? isChecked : togglePrivacyCodeParams.isPrivate}
            onChange={() => {
              if (isNotCreated) {
                setIsChecked(!isChecked);
                onChange?.(!isChecked);

                return;
              }

              debouncedMutate.current({
                isPrivate: !togglePrivacyCodeParams.isPrivate,
                entityId: togglePrivacyCodeParams.entityId,
                type,
              });
            }}
            isDisabled={isFreeUser}
          />
          <Text opacity="secondary">Private</Text>
        </HStack>
      </GradientTooltip>
    </VStack>
  );
};

export default ToggleCodePrivacySwitch;
