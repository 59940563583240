import { useGetPrototypeQuery } from 'hooks/queries/prototypes/useGetPrototypeQuery';

import DevSandboxSkeleton from 'components/DevSandbox/DevSandboxSkeleton';
import { SEO } from 'components/SEO';

import PrototypePlayground from './PrototypePlayground';

const PrototypeHub = () => {
  const hasTabInUrl =
    location.pathname.includes('browser') ||
    location.pathname.includes('summary') ||
    location.pathname.includes('ai-buddy') ||
    location.pathname.includes('comments') ||
    location.pathname.includes('explore');
  const tabScreenName = location.pathname.split('/').pop();
  const solutionUrl = hasTabInUrl
    ? location.pathname.replace(`/${tabScreenName}`, '')
    : location.pathname;

  const { data, isPending } = useGetPrototypeQuery({
    solution_url: solutionUrl,
  });

  if (!data || isPending) {
    return <DevSandboxSkeleton loadingText="Loading prototype..." />;
  }

  return (
    <>
      <SEO
        title={`BigDevSoon | ${data.title} Prototype`}
        url={`${process.env.REACT_APP_SITE_URL}prototypes/${data.user.screen_name}/${data.screen_name}`}
        image={data.screenshot_url}
      />
      <PrototypePlayground prototype={data} />
    </>
  );
};

export default PrototypeHub;
