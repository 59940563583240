import { QuestionOutlineIcon } from '@chakra-ui/icons';
import { IconButton, Box, VStack, HStack, Divider } from '@chakra-ui/react';
import { useQueryWithErrorBoundary, useSupabaseTypedClient } from 'hooks/reactQuery';

import DiscordButton from 'components/DiscordButton';
import GradientPopover from 'components/GradientPopover';
import Icon from 'components/Icon';
import Link from 'components/Link';
import Text from 'components/Text';

const Support = () => {
  const supabase = useSupabaseTypedClient();

  const { data } = useQueryWithErrorBoundary({
    queryKey: ['support-metadata'],
    queryFn: async () => {
      const { data, error } = await supabase
        .from('metadata')
        .select('value')
        .in('key', ['discord_current_members', 'discord_current_online']);

      if (error) throw error;

      return data;
    },
  });

  return (
    <GradientPopover
      tooltip="Support center"
      triggerComponent={
        <IconButton
          aria-label="support center popover (contact via email or Discord)"
          variant="ghost"
        >
          <QuestionOutlineIcon />
        </IconButton>
      }
      header="Support center"
    >
      <VStack alignItems="flex-start" spacing={4} width="100%">
        <Text opacity="secondary">Contact via email</Text>
        <HStack>
          <Icon type="Support" size={48} />
          <VStack align="flex-start" pl={1} spacing={1}>
            <Text fontSize="small" opacity="primary">
              Need some help?
            </Text>
            <Link href="mailto:support@bigdevsoon.me">Contact us</Link>
          </VStack>
        </HStack>
        <Divider />
        <Text opacity="secondary">Contact via Discord</Text>
        <HStack spacing={5}>
          <HStack spacing={1.5}>
            <Box width="8px" height="8px" borderRadius="50%" backgroundColor="green.200" />
            <Text fontSize="small">{data?.[1]?.value || 0}</Text>
            <Text fontSize="small" fontWeight="regular" opacity="secondary">
              Online
            </Text>
          </HStack>
          <HStack spacing={1.5}>
            <Box
              width="8px"
              height="8px"
              borderRadius="50%"
              backgroundColor="gray.100"
              opacity={0.36}
            />
            <Text fontSize="small">{data?.[0]?.value || 0}</Text>
            <Text fontSize="small" fontWeight="regular" opacity="secondary">
              Members
            </Text>
          </HStack>
        </HStack>
        <DiscordButton />
      </VStack>
    </GradientPopover>
  );
};

export default Support;
