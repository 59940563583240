import { VStack } from '@chakra-ui/layout';
import { HStack } from '@chakra-ui/react';

import BigTokens from 'components/BigTokens';
import Button from 'components/Button';
import { useDiscordConnection } from 'components/DiscordButton';
import Modal from 'components/Modal';
import Text from 'components/Text';
import { IModal } from 'types/frontend';
import { CreditReward } from 'utils/constants';

interface IDiscordAttachModal extends IModal {}
export const DiscordAttachModal = ({ isOpen, onClose }: IDiscordAttachModal) => {
  const { attachDiscord, isLoading } = useDiscordConnection(onClose);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={attachDiscord}
      header="Connect Discord"
      footer={
        <>
          <Button scheme="ghost" onClick={onClose}>
            Close
          </Button>
          <Button type="submit" isLoading={isLoading}>
            Confirm
          </Button>
        </>
      }
    >
      <VStack align="flex-start" spacing={5}>
        <Text fontWeight="regular">Your Discord account will be connected to the BigDevSoon.</Text>
        <HStack spacing={1}>
          <Text fontWeight="regular">{`Upon your first account connection, you'll receive`}</Text>
          <BigTokens amount={CreditReward.DiscordConnected} />
          <Text fontWeight="semibold">BigToken.</Text>
        </HStack>
      </VStack>
    </Modal>
  );
};
