import { useQueryClient } from '@tanstack/react-query';

import { CodePreview } from 'types/edgeFunctions';

import { useToastWrapper } from './useToastWrapper';

export interface IUseTogglePrivacyCodeParams {
  queryKey: unknown[];
  invalidateKeys: unknown[][];
  entityId: string;
  isPrivate: boolean;
}

export const useTogglePrivacyCode = ({
  queryKey,
  invalidateKeys,
  entityId,
  isPrivate,
}: IUseTogglePrivacyCodeParams) => {
  const queryClient = useQueryClient();

  const { toastError } = useToastWrapper();

  const onMutate = async () => {
    await queryClient.cancelQueries({
      queryKey,
    });

    const previousData = queryClient.getQueryData<CodePreview[]>(queryKey);

    queryClient.setQueryData<CodePreview[]>(queryKey, (old: any) => {
      if (!old) return;

      // Handle scenario where 'old' is an object possibly with 'solutions' array and 'my_solution'
      if (old.solutions && Array.isArray(old.solutions)) {
        const updatedSolutions = old.solutions.map((codePreview) => {
          if (codePreview.id === entityId) {
            return {
              ...codePreview,
              is_private: !isPrivate,
            };
          }
          return codePreview;
        });

        return { ...old, solutions: updatedSolutions };
      }

      // Handle scenario where 'old' is just an object
      if (typeof old === 'object' && !Array.isArray(old)) {
        if (old.id === entityId) {
          return {
            ...old,
            is_private: !isPrivate,
          };
        }

        return old;
      }

      // Existing handling for scenario 3 where 'old' is an array
      return old.map((codePreview) => {
        if (codePreview.id === entityId) {
          return {
            ...codePreview,
            is_private: !isPrivate,
          };
        }

        return codePreview;
      });
    });

    return { previousData };
  };

  const onError = (error: any, variables: any, context: any) => {
    toastError(`Failed to make code ${isPrivate ? 'public' : 'private'}`);

    queryClient.setQueryData(queryKey, context.previousData);

    invalidateKeys.forEach((key) => {
      queryClient.removeQueries({ queryKey: key });
    });
  };

  const onSettled = () => {
    invalidateKeys.forEach((key) => {
      queryClient.removeQueries({ queryKey: key });
    });
  };

  return {
    onMutate,
    onError,
    onSettled,
  };
};
