import { Box, Image, Badge, VStack, useTheme } from '@chakra-ui/react';
import { useState } from 'react';

import CodeFrame from './DevSandbox/CodeFrame';
import Text from './Text';

interface IDesignComparisonProps {
  codeFrameUrl: string;
  designImage: string;
  allowLongImages?: boolean;
}

const DesignComparison = ({
  codeFrameUrl,
  designImage,
  allowLongImages,
}: IDesignComparisonProps) => {
  const [dividerPosition, setDividerPosition] = useState(50);
  const { borderRadius } = useTheme();

  const handleMouseMove = (e) => {
    const boundingRect = document
      .getElementById('design-comparison-container')
      ?.getBoundingClientRect();
    const newPosition =
      ((e.clientX - (boundingRect?.left || 0)) / (boundingRect?.width || 0)) * 100;

    if (newPosition < 1) {
      setDividerPosition(1);
    } else if (newPosition > 99) {
      setDividerPosition(99);
    } else {
      setDividerPosition(newPosition);
    }
  };

  const handleMouseDown = (e) => {
    e.preventDefault();
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  };

  const handleMouseUp = () => {
    document.removeEventListener('mousemove', handleMouseMove);
    document.removeEventListener('mouseup', handleMouseUp);
  };

  const heightProps = allowLongImages ? { height: 'auto', minHeight: '100%' } : { height: '100%' };

  return (
    <VStack align="center" spacing={4} userSelect="none" width="100%">
      <Box
        id="design-comparison-container"
        position="relative"
        overflow="hidden"
        width="100%"
        height="530px"
        borderRadius={borderRadius}
      >
        <Box position="absolute" top="0" left="0" width="100%" height="100%" pointerEvents="none">
          <CodeFrame url={codeFrameUrl} />
        </Box>
        <Box
          position="absolute"
          top="0"
          left="0"
          width="100%"
          {...heightProps}
          style={{
            clipPath: `polygon(${dividerPosition}% 0, 100% 0, 100% 100%, ${dividerPosition}% 100%)`,
            pointerEvents: 'none',
          }}
        >
          <Image
            src={designImage}
            alt="Screenshot of the design"
            width="100%"
            {...heightProps}
            borderRadius="md"
            position="absolute"
            top="0"
            left="0"
          />
        </Box>
        <Box
          position="absolute"
          top="0"
          left={`${dividerPosition}%`}
          transform="translateX(-50%)"
          height="100%"
          width="4px"
          backgroundColor="gray.500"
          cursor="col-resize"
          _hover={{ backgroundColor: 'purple.400' }}
          transition="background-color 0.2s"
          onMouseDown={handleMouseDown}
        />
        <Badge
          position="absolute"
          bottom="4"
          left="4"
          p={1}
          colorScheme="purple"
          borderRadius={borderRadius}
          textTransform="capitalize"
        >
          <Text color="black" fontSize="small">
            Your Solution
          </Text>
        </Badge>
        <Badge
          position="absolute"
          bottom="4"
          right="4"
          p={1}
          colorScheme="purple"
          borderRadius={borderRadius}
          textTransform="capitalize"
        >
          <Text color="black" fontSize="small">
            Design Screenshot
          </Text>
        </Badge>
      </Box>
    </VStack>
  );
};

export default DesignComparison;
