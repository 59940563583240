import { Box, Center, Grid, GridItem, HStack, VStack, useTheme } from '@chakra-ui/react';

import GitHubButton, { useSignIn } from 'components/GitHubButton';
import Icon from 'components/Icon';
import Link from 'components/Link';
import Modal from 'components/Modal';
import Text from 'components/Text';
import { useMediaQueries } from 'layout/MediaQueriesProvider';
import { IModal } from 'types/frontend';

interface IGitHubSignInModal extends IModal {}

export const GitHubSignInModal = ({ isOpen, onClose }: IGitHubSignInModal) => {
  const { borderRadius } = useTheme();
  const { handleLoginClick } = useSignIn();
  const { isMobile } = useMediaQueries();

  return (
    <Modal size="2xl" isOpen={isOpen} onClose={onClose} header={null} footer={null}>
      <Grid templateColumns="repeat(5, 1fr)" gap={2}>
        {!isMobile && (
          <GridItem colSpan={2}>
            <VStack
              background="whiteAlpha.200"
              borderRadius={borderRadius}
              p={5}
              mb={8}
              spacing={5}
              minH="300px"
              position="relative"
            >
              <Icon type="Logo" />
              <Text textAlign="center" fontWeight="regular" fontSize="small">
                Join us today, and embark on your journey towards becoming the Biggest Developer
                Soon!
              </Text>
              <Box position="absolute" left="0" bottom="0">
                <Icon type="BdsLogoRocket" />
              </Box>
            </VStack>
          </GridItem>
        )}
        <GridItem colSpan={isMobile ? 5 : 3}>
          <Center height="100%">
            <VStack spacing={6}>
              <Text fontSize="xLarge" fontWeight="semibold">
                Sign up and become BIG
              </Text>
              <GitHubButton text="Sign up with GitHub" />
              <Text textAlign="center" fontWeight="regular" fontSize="small" opacity="secondary">
                By signing up, you agree to the{' '}
                <Link
                  color="white"
                  fontWeight="regular"
                  textDecoration="underline"
                  href="https://www.iubenda.com/terms-and-conditions/78614077"
                >
                  Terms of Service
                </Link>
                ,{' '}
                <Link
                  color="white"
                  fontWeight="regular"
                  textDecoration="underline"
                  href="https://www.iubenda.com/privacy-policy/78614077"
                >
                  Privacy Policy
                </Link>
                , and
                <Link
                  color="white"
                  fontWeight="regular"
                  textDecoration="underline"
                  href="https://www.iubenda.com/privacy-policy/78614077/cookie-policy"
                >
                  {' '}
                  Cookie Policy
                </Link>
                .
              </Text>
              <HStack spacing={1} mt={3} mb={isMobile ? 5 : 0}>
                <Text fontWeight="regular" fontSize="small">
                  Existing user?
                </Text>
                <Text
                  color="purple.200"
                  cursor="pointer"
                  fontWeight="regular"
                  fontSize="small"
                  _hover={{ textDecoration: 'underline' }}
                  onClick={handleLoginClick}
                >
                  Sign in here
                </Text>
              </HStack>
            </VStack>
          </Center>
        </GridItem>
      </Grid>
    </Modal>
  );
};
