import { Box, HStack } from '@chakra-ui/react';
import { differenceInDays, differenceInSeconds, format } from 'date-fns';

import { DATE_FORMAT } from 'config/constants/plans';
import { pluralize } from 'utils/strings';

import GradientTooltip from './GradientTooltip';
import ProjectIcon from './ProjectIcon';
import Text from './Text';
import Timer from './Timer';

interface ProjectCardsProps {
  cards: number;
  completedCards?: number | null;
}

const ProjectCards = ({ cards, completedCards }: ProjectCardsProps) => {
  return (
    <Box position="relative" background="purple.400" borderRadius={999} p={1}>
      <HStack spacing={1.5}>
        <ProjectIcon type="Cards" size="s" />
        {completedCards !== undefined && completedCards !== null ? (
          <Text whiteSpace="nowrap" fontSize="small" fontWeight="semibold">
            {completedCards}/{cards} Cards
          </Text>
        ) : (
          <Text whiteSpace="nowrap" fontSize="small">
            {cards} Cards
          </Text>
        )}
      </HStack>
    </Box>
  );
};

interface ProjectExperienceProps {
  experience: number;
}

const ProjectExperience = ({ experience }: ProjectExperienceProps) => {
  return (
    <Box position="relative" background="whiteAlpha.200" borderRadius={999} p={1.5}>
      <HStack spacing={1}>
        <ProjectIcon type="ExperienceBubble" size="s" />
        <Text whiteSpace="nowrap" fontSize="small">
          +{experience} XP
        </Text>
      </HStack>
    </Box>
  );
};

const ProjectExperienceCompleted = ({ experience }: ProjectExperienceProps) => {
  return (
    <Box position="relative" background="whiteAlpha.200" borderRadius={999} p={1.5}>
      <HStack spacing={1}>
        <ProjectIcon type="CardCheck" size="s" />
        <Text whiteSpace="nowrap" fontSize="small">
          +{experience} XP
        </Text>
      </HStack>
    </Box>
  );
};

interface ProjectBigtokensProps {
  bigtokens: number;
}

const ProjectBigtokens = ({ bigtokens }: ProjectBigtokensProps) => {
  return (
    <Box position="relative">
      <HStack spacing={1}>
        <ProjectIcon type="Bigtokens" />
        <Text whiteSpace="nowrap">+{bigtokens} BT</Text>
      </HStack>
    </Box>
  );
};

interface ProjectCommitsProps {
  commits: number;
}

const ProjectCommits = ({ commits }: ProjectCommitsProps) => {
  return (
    <HStack spacing={2}>
      <ProjectIcon type="Commits" />
      <Text>
        {commits}{' '}
        <Text as="span" opacity="secondary">
          {pluralize('Commit', commits)}
        </Text>
      </Text>
    </HStack>
  );
};

interface ProjectTimerProps {
  createdAt: string;
  completedAt?: string;
  withBackground: boolean;
}

const formatTimeUnit = (unit: number) => String(unit).padStart(2, '0');

const getEndTimer = (createdAt: string, completedAt: string) => {
  const startDate = new Date(createdAt);
  const endDate = new Date(completedAt);

  const diffInDays = differenceInDays(endDate, startDate);
  const remainingSeconds = differenceInSeconds(endDate, startDate) % 86400;
  const hours = Math.floor(remainingSeconds / 3600);
  const minutes = Math.floor((remainingSeconds % 3600) / 60);
  const seconds = remainingSeconds % 60;

  return `${diffInDays > 0 ? `${diffInDays}d ` : ''} ${formatTimeUnit(hours)}:${formatTimeUnit(
    minutes
  )}:${formatTimeUnit(seconds)}`;
};

const ProjectTimer = ({ createdAt, completedAt, withBackground }: ProjectTimerProps) => {
  const completedTimer = completedAt ? getEndTimer(createdAt, completedAt) : null;

  return (
    <Box
      position="relative"
      background={withBackground ? 'whiteAlpha.200' : ''}
      borderRadius={999}
      p={1.5}
    >
      <HStack spacing={1.5}>
        <ProjectIcon type="Clock" size="s" />
        {completedTimer ? (
          <Box fontFamily="monospace">{completedTimer}</Box>
        ) : (
          <Timer createdAt={createdAt} />
        )}
      </HStack>
    </Box>
  );
};

interface ProjectCalendarProps {
  createdAt?: string;
  completedAt?: string;
}

const ProjectCalendar = ({ createdAt = '', completedAt = '' }: ProjectCalendarProps) => {
  return (
    <GradientTooltip label={completedAt ? 'Completed date' : 'Started date'}>
      <Box position="relative" background="whiteAlpha.200" borderRadius={999} p={1.5}>
        <HStack spacing={1.5}>
          <ProjectIcon type="Calendar" size="s" />
          <Text fontSize="small">{format(new Date(completedAt || createdAt), DATE_FORMAT)}</Text>
        </HStack>
      </Box>
    </GradientTooltip>
  );
};

interface ProjectElementProps {
  type?:
    | 'timer'
    | 'experience'
    | 'experienceCompleted'
    | 'bigtokens'
    | 'commits'
    | 'cards'
    | 'calendar';
  experience?: number;
  createdAt?: string;
  completedAt?: string;
  bigtokens?: number;
  commits?: number;
  cards?: number;
  completedCards?: number | null;
  withBackground?: boolean;
}

const ProjectElement = ({
  type,
  experience,
  bigtokens,
  commits,
  cards,
  completedCards,
  createdAt,
  completedAt,
  withBackground,
}: ProjectElementProps) => {
  switch (type) {
    case 'experience':
      return <ProjectExperience experience={experience || 0} />;
    case 'experienceCompleted':
      return <ProjectExperienceCompleted experience={experience || 0} />;
    case 'bigtokens':
      return <ProjectBigtokens bigtokens={bigtokens || 0} />;
    case 'cards':
      return <ProjectCards cards={cards || 0} completedCards={completedCards} />;
    case 'commits':
      return <ProjectCommits commits={commits || 0} />;
    case 'timer':
      return (
        <ProjectTimer
          createdAt={createdAt || ''}
          completedAt={completedAt || ''}
          withBackground={withBackground ?? true}
        />
      );
    case 'calendar':
      return <ProjectCalendar createdAt={createdAt} completedAt={completedAt} />;
    default:
      throw new Error('provided no type or wrong type to ProjectElement');
  }
};

export default ProjectElement;
