import { Box, VStack } from '@chakra-ui/react';
import useLongSpinnerText from 'hooks/useLongSpinnerText';
import { useSearchParams } from 'react-router-dom';

import DevSandbox from 'components/DevSandbox';
import { TCodeTabName } from 'components/DevSandbox/types';
import PageLoader from 'components/PageLoader';
import Text from 'components/Text';

interface IDevSandboxSkeletonProps {
  loadingText: string;
  pageLoaderText?: string;
  tabNames?: TCodeTabName[];
}

const DevSandboxSkeleton = ({
  loadingText,
  pageLoaderText,
  tabNames,
}: IDevSandboxSkeletonProps) => {
  const [searchParams] = useSearchParams();

  const searchParamsMode = searchParams.get('mode');
  const mode = searchParamsMode === 'full-page' ? 'full-page' : 'interactive';

  const text = useLongSpinnerText(Boolean(pageLoaderText));

  if (mode === 'full-page') {
    return (
      <Box
        position="fixed"
        top="0"
        left="0"
        right="0"
        bottom="0"
        width="100vw"
        height="100vh"
        backgroundColor="gray.900"
        zIndex="modal"
      >
        <PageLoader size="xl" />
      </Box>
    );
  }

  return (
    <>
      <PageLoader
        size="xl"
        text={
          <VStack>
            <Text opacity="default">{pageLoaderText || 'Loading Code Editor'}</Text>
            {text && (
              <Text fontWeight="regular" fontStyle="italic" opacity="primary">
                {text}
              </Text>
            )}
          </VStack>
        }
      />
      <DevSandbox
        id="new"
        title="Loading..."
        mode="interactive"
        navigation={{
          leftSide: null,
          header: <Text fontSize="large">{loadingText}</Text>,
          rightSide: null,
        }}
        codeTabs={{
          tabNames: tabNames || ['HTML', 'CSS', 'JS'],
        }}
      />
    </>
  );
};

export default DevSandboxSkeleton;
