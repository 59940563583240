import { useGetCurrentUserQuery } from 'hooks/queries/useGetCurrentUserQuery';
import React, { ReactNode, useContext } from 'react';

import PageLoader from 'components/PageLoader';
import { CurrentUser } from 'types/edgeFunctions';

type CurrentUserContextData = CurrentUser;
export const CurrentUserContext = React.createContext({} as CurrentUserContextData);

interface ICurrentUserProviderProps {
  children: ReactNode;
  userId: string | null;
  isAuthLoading: boolean;
}

export const CurrentUserProvider = ({
  children,
  userId,
  isAuthLoading,
}: ICurrentUserProviderProps) => {
  const { data, isPending } = useGetCurrentUserQuery({
    userId: userId || '',
  });

  const isUserQueryLoading = userId && isPending;

  if (isAuthLoading || isUserQueryLoading) {
    return <PageLoader />;
  }

  const currentUser = data!;

  return (
    <CurrentUserContext.Provider
      value={{
        ...currentUser,
      }}
    >
      {children}
    </CurrentUserContext.Provider>
  );
};

export const useCurrentUser = (): CurrentUserContextData => {
  const context = useContext(CurrentUserContext);

  if (!context) {
    throw new Error('useCurrentUser must be used within an CurrentUserProvider');
  }

  return context;
};
