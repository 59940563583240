import { Box, HStack, Image, VStack } from '@chakra-ui/react';
import { useSupabaseTypedClient } from 'hooks/reactQuery';
import { useToastWrapper } from 'hooks/useToastWrapper';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';

import TokenBagIllustration from 'assets/illustrations/TokenBag.png';
import TokenBarrelIllustration from 'assets/illustrations/TokenBarrel.png';
import TokenBoostIllustration from 'assets/illustrations/TokenBoost.png';
import TokenChestIllustration from 'assets/illustrations/TokenChest.png';
import TokenGlare from 'assets/illustrations/TokenGlare.svg';
import { EdgeFunctionName, TokenPackageParams } from 'types/edgeFunctions';

import Button from './Button';
import { IconType } from './Icon';
import Text from './Text';

interface ITokenPackageProps {
  id: string;
  tokens: number;
  price: number;
  isBestPrice: boolean;
  icon: IconType;
}

const mapTokenIllsutrationToIcon = {
  TokenBoost: TokenBoostIllustration,
  TokenBag: TokenBagIllustration,
  TokenBarrel: TokenBarrelIllustration,
  TokenChest: TokenChestIllustration,
};

export const TokenPackage = ({
  id,
  tokens,
  price,
  isBestPrice = false,
  icon,
}: ITokenPackageProps) => {
  const supabase = useSupabaseTypedClient();
  const { toastError } = useToastWrapper();
  const [isLoading, setIsLoading] = useState(false);
  const { pathname } = useLocation();

  const handleBuyTokensClick = async () => {
    setIsLoading(true);
    const { data, error } = await supabase.functions.invoke(EdgeFunctionName.Checkout, {
      body: {
        tokenPackageId: id,
        path: pathname,
      } as TokenPackageParams,
    });

    if (error) {
      setIsLoading(false);
      toastError(error);
      return;
    }
    setIsLoading(false);
    window.location.href = data as string;
  };

  return (
    <Box position="relative" w="100%">
      {isBestPrice && (
        <Box
          position="absolute"
          right={0}
          top={0}
          width={0}
          height={0}
          borderStyle="solid"
          borderTopRightRadius={16}
          borderRightColor="orange.200"
          borderBottomColor="transparent"
          borderWidth="0 85px 85px 0"
          dropShadow="2xl"
        >
          <Text
            position="absolute"
            textAlign="center"
            marginLeft="40px"
            marginTop="10px"
            transform="rotate(45deg)"
            textColor="gray.800"
            fontSize="micro"
            fontWeight="bold"
            opacity="default"
          >
            Best
            <br /> Value
          </Text>
        </Box>
      )}
      <Box
        borderWidth={3}
        borderRadius={16}
        boxShadow="0px 10px 20px 0px rgba(0, 0, 0, 0.15)"
        background={
          isBestPrice
            ? 'linear-gradient(136deg, #9f7aeade 0%, #ed8936de 100%)'
            : 'linear-gradient(136deg, #B794F4 0%, #E9D8FD 100%)'
        }
      >
        <VStack
          spacing={0}
          px={3}
          py={5}
          bg="gray.700"
          rounded="xl"
          backgroundImage={`url(${TokenGlare})`}
          backgroundRepeat="no-repeat"
          backgroundPosition="top -100px right 50%"
        >
          <HStack>
            <Text fontSize="xxxLarge" fontWeight="bold">
              {tokens}
            </Text>
            <Text fontSize="large">BigTokens</Text>
          </HStack>
          <Image
            src={mapTokenIllsutrationToIcon[icon]}
            width="150px"
            height="auto"
            alt={`Token package image: ${icon}`}
          />
          <Button
            borderRadius={6}
            fullWidth
            onClick={handleBuyTokensClick}
            isLoading={isLoading}
            scheme={isBestPrice ? 'gradient' : 'primary'}
            mt={6}
          >
            Buy for ${price.toFixed(2)}
          </Button>
        </VStack>
      </Box>
    </Box>
  );
};
