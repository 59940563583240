import { SupabaseClient } from '@supabase/supabase-js';
import { useQueryClient } from '@tanstack/react-query';
import { useInfiniteQueryWithErrorBoundary, useSupabaseTypedClient } from 'hooks/reactQuery';
import { useCallback, useEffect, useRef } from 'react';

import { GetCodeFramesResponse } from 'types/edgeFunctions';
import { TanstackQueryName } from 'types/frontend';
import { Database } from 'types/supabase';

interface IGetCodeFramesQueryFnParams {
  supabase: SupabaseClient<Database>;
  userId?: string | null;
  limit?: number | null;
  pageParam?: any | null;
}

export const getCodeFramesQueryFn = async ({
  supabase,
  userId,
  limit = 6,
  pageParam,
}: IGetCodeFramesQueryFnParams) => {
  const codeFramesQuery = supabase
    .from('user_code_view')
    .select('*')
    .eq('type', 'code-frame')
    .not('user_id', 'is', null)
    .limit(limit || 6);

  if (userId) {
    codeFramesQuery.eq('user_id', userId).order('completed_date', { ascending: false });
  } else {
    codeFramesQuery
      .or(`html.neq.,css.neq.,js.neq.`)
      .eq('is_private', false)
      .order('total_score', { ascending: false })
      .order('completed_date', { ascending: false });
  }

  if (pageParam) {
    if (userId) {
      codeFramesQuery.lt('completed_date', pageParam?.completed_date);
    } else {
      codeFramesQuery
        .lte('total_score', pageParam?.total_score)
        .lt('completed_date', pageParam?.completed_date);
    }
  }

  const codeFrames = await codeFramesQuery;

  if (codeFrames.error) throw codeFrames.error;

  return codeFrames.data;
};

interface IUseGetCodeFramesQueryResponse {
  data?: GetCodeFramesResponse | null;
  isFetching: boolean;
  refetch: any;
  ref: any;
}

interface IUseGetCodeFramesQueryParams {
  userId?: string | null;
}

export const useGetCodeFramesQuery = ({
  userId,
}: IUseGetCodeFramesQueryParams): IUseGetCodeFramesQueryResponse => {
  const supabase = useSupabaseTypedClient();
  const queryClient = useQueryClient();

  const { data, fetchNextPage, hasNextPage, isFetching, refetch } =
    useInfiniteQueryWithErrorBoundary({
      gcTime: 0,
      queryKey: [TanstackQueryName.GetCodeFrames, userId],
      queryFn: ({ pageParam }) => getCodeFramesQueryFn({ supabase, userId, limit: 6, pageParam }),
      placeholderData: {
        pageParams: [],
        pages: [],
      },
      initialPageParam: null,
      getNextPageParam: (lastPage) => {
        if (lastPage && Array.isArray(lastPage) && lastPage?.length > 0) {
          const lastPageParams = lastPage[lastPage.length - 1];

          return lastPageParams;
        }

        return undefined;
      },
    });

  const observer = useRef<IntersectionObserver>();
  const lastCodeFrameRef = useCallback(
    (node) => {
      if (isFetching) return;

      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting && hasNextPage) {
            fetchNextPage();
          }
        },
        {
          rootMargin: '100px',
        }
      );

      if (node) observer.current.observe(node);
    },
    [fetchNextPage, hasNextPage, isFetching]
  );

  useEffect(() => {
    return () => {
      queryClient.removeQueries({
        queryKey: [TanstackQueryName.GetCodeFrames, userId],
      });
    };
  }, []);

  return {
    data,
    isFetching,
    refetch,
    ref: lastCodeFrameRef,
  };
};
