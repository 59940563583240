import { Grid, GridItem, Stack, HStack, Container } from '@chakra-ui/react';
import { useGetUserProfileQuery } from 'hooks/queries/useGetUserProfileQuery';
import { Navigate } from 'react-router-dom';

import { useCurrentUser } from 'auth/CurrentUserProvider';
import Button from 'components/Button';
import PageLoader from 'components/PageLoader';
import { SEO } from 'components/SEO';
import StickyMenu from 'components/StickyMenu';
import Text from 'components/Text';
import { ERoute } from 'types/frontend';

import AboutMe from './AboutMe';
import OnlinePresence from './OnlinePresence';
import UserDetails from './UserDetails';

const PUBLIC_PROFILE_ID = 'public-profile';
const ABOUT_ME_ID = 'about-me';
const ONLINE_PRESENCE_ID = 'online-presence';

const EditProfile = () => {
  const { id: userId, screenName } = useCurrentUser();

  const { data, isPending } = useGetUserProfileQuery({
    userId,
  });

  if (isPending) {
    return <PageLoader shift />;
  }

  if (!data) {
    return <Navigate to={ERoute.Dashboard} replace />;
  }

  const {
    avatar_url: avatarUrl,
    name,
    location,
    public_email,
    website_url,
    github_url,
    linkedin_url,
    devto_url,
    twitter_url,
    bio,
    goal,
  } = data;

  return (
    <>
      <SEO
        title={`BigDevSoon | Edit profile`}
        description="Edit your profile page"
        url={`${process.env.REACT_APP_SITE_URL}profile`}
      />
      <Container maxW="1400px">
        <Stack spacing={4}>
          <HStack spacing={5} py={4}>
            <Text fontSize="xxLarge" fontWeight="semibold">
              Profile
            </Text>
            <Button scheme="gradient" to={{ pathname: screenName, state: { userId } }}>
              Preview
            </Button>
          </HStack>
          <Grid templateColumns="repeat(12, 1fr)" gap={8}>
            <GridItem colSpan={[12, 12, 12, 3]}>
              <StickyMenu
                tabs={[
                  { id: PUBLIC_PROFILE_ID, name: 'User details' },
                  { id: ABOUT_ME_ID, name: 'About me' },
                  { id: ONLINE_PRESENCE_ID, name: 'Online presence' },
                ]}
              />
            </GridItem>
            <GridItem colSpan={[12, 12, 12, 9]} mt={2}>
              <Stack spacing={6}>
                <div id={PUBLIC_PROFILE_ID}>
                  <UserDetails
                    avatarUrl={avatarUrl}
                    initialValues={{ name, location, public_email, website_url }}
                  />
                </div>
                <div id={ABOUT_ME_ID}>
                  <AboutMe initialValues={{ bio, goal }} />
                </div>
                <div id={ONLINE_PRESENCE_ID}>
                  <OnlinePresence
                    initialValues={{ github_url, linkedin_url, devto_url, twitter_url }}
                  />
                </div>
              </Stack>
            </GridItem>
          </Grid>
        </Stack>
      </Container>
    </>
  );
};

export default EditProfile;
