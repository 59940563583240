import { Experience_Level_Enum } from './databaseEnums';

export enum RecycleTokenReward {
  RecycleFree = 'c1c137e4-c198-47aa-9bb4-aa4f4bbf6d01',
  RecycleMonthly = '4866468f-e622-41c7-9de7-bce003bc6170',
  RecycleYearly = 'a5f35cd7-1a0d-49c5-b22c-029397424423',
  RecycleLifetime = 'b7d081c1-1800-4336-9ff9-58ec27f21c8a',
}

export enum OtherTokenReward {
  DiscordConnected = '7aa36fdd-c1a6-4bca-a87f-ad9cd675ac25',
  Referral = '2a97bddc-a417-45e3-aa26-2157db608830',
  GenerateCodeSolution = '4d751e7b-79d5-4f72-b27b-e07b2a6d85df',
  GeneratePrototype = 'dc3d69f2-61f6-4c0e-9dc1-67f03cc53ebd',
  GenerateCodeSummary = '8a85dde0-e4fc-4d54-b72a-f32f9f08c2ec',
  DownloadProjectFigma = '9b82ee53-e2ba-4bca-b79c-17a0a1a1f9e9',
  DownloadChallengeFigma = 'b15c8727-7b0f-4da1-8195-7e7eac859196',
}

export type TokenReward = RecycleTokenReward | OtherTokenReward;

export enum AchievementExperienceReward {
  FirstCommit = '9077c550-601d-4a2c-b5ca-4337fb21c74b',
  OneManArmy = '14f48095-ca18-455a-afd3-a138754bdf46',
  Freerunner = '87965c50-5b97-48ee-842d-e23a5535a7a7',
  Speedrunner = '53528e39-3bb8-4e75-ab39-7584875c2e4e',
  FirstCard = 'b212f50a-a1d2-40d6-8e79-6e1f7c133612',
  TechTitan = '4B243516-A05E-40E4-A5F7-3C3CDCBE6B0A',
  TriathlonFinisher = '58B436E3-1D22-488F-8C4A-B981FAE003C4',
  LikeMagnet = '18E39950-9259-4DEE-B99B-003962ABD09F',
  SocialButterfly = 'EACF9C25-D209-4549-BBAF-7FD6EB39F422',
  CardCollector = '700942F5-53AE-4766-B2D0-BCEAA5B66E39',
  Trailblazer = '07E94F67-D65A-46AA-9C7B-6C8D23BEB467',
  CommunitySpeaker = 'a7e96950-76f4-4bc9-85e8-1631bc854754',
  LoyalLearner = '65ca285f-cb3c-4988-9299-b789ebda7d6d',
  MasterOfModes = 'ad0cc7d5-6fd4-476d-9a55-d8ef9541759e',
  Onboarding = 'DD3563AF-42A6-4271-B6BA-A67EC03A5A70',
  ChallengeConqueror = '7ffeb29f-997a-4022-a2df-6667458e7375',
  DaybreakDebutant = '2ffefadd-11b2-46eb-8048-8c9da515881b',
}

export enum ProjectCompletedExperienceReward {
  Junior = 'e63cb73b-427a-4d4d-ab84-087e6a767f12',
  Regular = 'b51bf3b4-dd9e-4f3b-9133-5bca200489ba',
  Senior = 'd20e12f1-0fdd-42a7-8185-0fcc2b3142ed',
}

export enum CardCompletedExperienceReward {
  Junior = 'a31ef0b8-a562-4660-834b-2b3492a49b49',
  Regular = '5cf1976f-50eb-4757-8b40-e09aeb781f9e',
  Senior = '53e48c60-28e8-40a0-bf39-79705d035fb8',
}

export type ExperienceReward =
  | AchievementExperienceReward
  | ProjectCompletedExperienceReward
  | CardCompletedExperienceReward;

export const mapExpToCardReward: Record<Experience_Level_Enum, CardCompletedExperienceReward> = {
  junior: CardCompletedExperienceReward.Junior,
  regular: CardCompletedExperienceReward.Regular,
  senior: CardCompletedExperienceReward.Senior,
};

export const mapExpToProjectReward: Record<
  Experience_Level_Enum,
  ProjectCompletedExperienceReward
> = {
  junior: ProjectCompletedExperienceReward.Junior,
  regular: ProjectCompletedExperienceReward.Regular,
  senior: ProjectCompletedExperienceReward.Senior,
};
