import { RepeatIcon } from '@chakra-ui/icons';
import {
  VStack,
  HStack,
  IconButton,
  Divider,
  Wrap,
  Box,
  UnorderedList,
  ListItem,
} from '@chakra-ui/react';

import { BigBadge } from 'components/BigBadge';
import Button from 'components/Button';
import GradientTooltip from 'components/GradientTooltip';
import Icon from 'components/Icon';
import Link from 'components/Link';
import ImagePreviewModal from 'components/Modal/ImagePreviewModal';
import { ProtectedElement } from 'components/ProtectedElement';
import Text from 'components/Text';
import { Plan_Type_Enum } from 'types/databaseEnums';
import { CodeEditorType } from 'types/edgeFunctions';

import { TDevSandboxFooterQuickButton } from './types';
import { generateColorPalette } from './utils';

interface IDesignScreenshotParams {
  screenshotUrl: string;
  title: string;
  isFreeUser: boolean;
  type: CodeEditorType;
  autoOpen: boolean;
  isDownloadingFigma: boolean;
  onFigmaDownload: () => void;
}

export const designScreenshot = ({
  screenshotUrl,
  title,
  isFreeUser,
  type,
  autoOpen,
  isDownloadingFigma,
  onFigmaDownload,
}: IDesignScreenshotParams): TDevSandboxFooterQuickButton => {
  return {
    id: 'design_screenshot',
    tooltip: `View the design screenshot`,
    autoOpen,
    content: (
      <VStack alignItems="flex-start">
        <ImagePreviewModal
          url={screenshotUrl}
          title={title}
          imageProps={{
            width: '100%',
            height: 'auto',
          }}
          fullScreenProps={
            type === 'project'
              ? {
                  width: '100%',
                  height: 'auto',
                  borderRadius: 'md',
                  aspectRatio: 'initial',
                }
              : {}
          }
        />
        <Divider mt={2} />
        <HStack>
          <Text fontSize="large">Figma Design</Text>
          {isFreeUser && <BigBadge plan={Plan_Type_Enum.Lifetime} size="small" />}
        </HStack>
        <Text fontWeight="semibold">Extract images, icons, colors, and fonts from design.</Text>
        <ProtectedElement
          scheme="gradient"
          leftIcon={<Icon type="FigmaLogo" />}
          fullWidth
          onClick={isDownloadingFigma ? undefined : onFigmaDownload}
        >
          {isDownloadingFigma ? 'Downloading...' : 'Download Figma Design'}
        </ProtectedElement>
      </VStack>
    ),
    icon: <Icon type="DesignScreenshot" />,
    title: (
      <VStack alignItems="flex-start">
        <HStack alignItems="center" justifyContent="space-between" width="100%">
          <span>Design Screenshot</span>
          <GradientTooltip label="Open Design Screenshot in a new tab">
            <div>
              <Link href={screenshotUrl} isExternal>
                <IconButton
                  aria-label="Open Design Screenshot in a new tab"
                  size="sm"
                  variant="ghost"
                >
                  <Icon type="OutsideLink" />
                </IconButton>
              </Link>
            </div>
          </GradientTooltip>
        </HStack>
        <Divider />
      </VStack>
    ),
  };
};

interface IResourcesParams {
  isFreeUser: boolean;
  type: CodeEditorType;
  isDownloadingFigma: boolean;
  githubLink: string;
  glitchLink: string;
  onFigmaDownload: () => void;
}

export const resources = ({
  isFreeUser,
  type,
  githubLink,
  glitchLink,
  isDownloadingFigma,
  onFigmaDownload,
}: IResourcesParams): TDevSandboxFooterQuickButton => {
  return {
    id: 'resources',
    tooltip: `View resources`,
    content: (
      <VStack alignItems="flex-start" spacing={5}>
        <VStack alignItems="flex-start" spacing={4}>
          <HStack>
            <Text fontSize="large">Figma Design</Text>
            {isFreeUser && <BigBadge plan={Plan_Type_Enum.Lifetime} size="small" />}
          </HStack>
          <Text opacity="secondary" fontWeight="regular">
            Access our Figma file for a quick and easy reference during your{' '}
            {type === 'challenge' ? 'daily challenge' : 'project'} implementation. You can look up{' '}
            {type === 'project' && 'different project states, '}
            fonts, export icons, check colors, and download images.
          </Text>
          <ProtectedElement
            scheme="gradient"
            leftIcon={<Icon type="FigmaLogo" />}
            fullWidth
            onClick={isDownloadingFigma ? undefined : onFigmaDownload}
          >
            {isDownloadingFigma ? 'Downloading...' : 'Download Figma Design'}
          </ProtectedElement>
          <Divider />
        </VStack>
        <VStack alignItems="flex-start" spacing={4}>
          <Text fontSize="large">Starter Files</Text>
          <Text opacity="secondary" fontWeight="regular">
            Use our provided starter files to kickstart your{' '}
            {type === 'challenge' ? 'daily challenge' : 'project'}. HTML file is a good starting
            point to build your solution and README contains additional information.
          </Text>
          <ProtectedElement
            scheme="primary"
            onClick={() => {
              window.open(`${githubLink}/archive/refs/heads/main.zip`, '_self');
            }}
            fullWidth
          >
            Download Starter Files
          </ProtectedElement>
          <Divider />
        </VStack>
        <VStack alignItems="flex-start" spacing={4}>
          <Text fontSize="large">Boilerplate Templates</Text>
          <Text opacity="secondary" fontWeight="regular">
            Additionally, you can browse our templates to help you get started in different
            environments.
          </Text>
          <HStack width="100%">
            <Button
              scheme="outline"
              leftIcon={<Icon type="Github" />}
              rightIcon={<Icon type="OutsideLink" />}
              fullWidth
              href={githubLink}
            >
              GitHub Template
            </Button>
            <Button
              scheme="glitch"
              leftIcon={<Icon type="Glitch" />}
              rightIcon={<Icon type="OutsideLinkDark" />}
              fullWidth
              href={glitchLink}
            >
              Glitch Template
            </Button>
          </HStack>
          <Divider />
        </VStack>
      </VStack>
    ),
    icon: <Icon type="DevSandboxAssets" />,
    title: 'Resources',
  };
};

export const images = (): TDevSandboxFooterQuickButton => {
  return {
    id: 'images',
    tooltip: `Search Images`,
    content: (
      <VStack alignItems="flex-start" spacing={4}>
        <Text fontSize="small" opacity="secondary">
          Check the below links for images.
        </Text>
        <UnorderedList spacing={4}>
          <ListItem>
            <Link href="https://unsplash.com/">Unsplash</Link>
          </ListItem>
          <ListItem>
            <Link href="https://www.pexels.com/">Pexels</Link>
          </ListItem>
          <ListItem>
            <Link href="https://pixabay.com/">Pixabay</Link>
          </ListItem>
        </UnorderedList>
      </VStack>
    ),
    icon: <Icon type="DesignScreenshot" />,
    title: 'Search Images',
  };
};

export const icons = (): TDevSandboxFooterQuickButton => {
  return {
    id: 'icons',
    tooltip: `Search Icons`,
    content: (
      <VStack alignItems="flex-start" spacing={4}>
        <Text fontSize="small" opacity="secondary">
          Check the below links for icons.
        </Text>
        <UnorderedList spacing={4}>
          <ListItem>
            <Link href="https://fontawesome.com/icons">Font Awesome</Link>
          </ListItem>
          <ListItem>
            <Link href="https://fonts.google.com/icons">Material Icons</Link>
          </ListItem>
          <ListItem>
            <Link href="https://feathericons.com/">Feather Icons</Link>
          </ListItem>
        </UnorderedList>
      </VStack>
    ),
    icon: <Icon type="DesignIcons" />,
    title: 'Search Icons',
  };
};

export const fonts = (): TDevSandboxFooterQuickButton => {
  return {
    id: 'fonts',
    tooltip: `Search Fonts`,
    content: (
      <VStack alignItems="flex-start" spacing={4}>
        <Text fontSize="small" opacity="secondary">
          Check the below links for fonts.
        </Text>
        <UnorderedList spacing={4}>
          <ListItem>
            <Link href="https://fonts.google.com/">Google Fonts</Link>
          </ListItem>
          <ListItem>
            <Link href="https://fonts.adobe.com/fonts">Adobe Fonts</Link>
          </ListItem>
          <ListItem>
            <Link href="https://www.fontsquirrel.com/">Font Squirrel</Link>
          </ListItem>
        </UnorderedList>
      </VStack>
    ),
    icon: <Icon type="DesignFonts" />,
    title: 'Search Fonts',
  };
};

interface IColorsPaletteParams {
  screenshotUrl?: string | null;
  toast: (message: string) => void;
  onRefresh?: () => void;
}

export const colorsPalette = async ({
  screenshotUrl,
  toast,
  onRefresh,
}: IColorsPaletteParams): Promise<TDevSandboxFooterQuickButton> => {
  const colorPalette = await generateColorPalette(screenshotUrl);

  const handleCopyToClipboard = (value: string) => {
    navigator.clipboard.writeText(value.toUpperCase());
    toast(`Copied ${value.toUpperCase()} color to clipboard!`);
  };

  return {
    id: 'colors_palette',
    tooltip: `View the color palette`,
    content: (
      <VStack alignItems="flex-start" spacing={4}>
        <Text fontSize="small" opacity="secondary">
          Copy the #hex color to the clipboard.
        </Text>

        <Wrap spacing={4}>
          {colorPalette.palette.map((color) => {
            return (
              <VStack key={color}>
                <Box
                  bg={color}
                  width="64px"
                  height="64px"
                  borderRadius="md"
                  cursor="pointer"
                  transition="all 0.2s ease-out"
                  _hover={{ opacity: 0.9, transform: 'scale(1.05)' }}
                  position="relative"
                  role="group"
                  onClick={() => handleCopyToClipboard(color)}
                >
                  <Box
                    position="absolute"
                    transform="translate(-50%, -50%)"
                    top="50%"
                    left="50%"
                    display="none"
                    _groupHover={{ display: 'block' }}
                  >
                    <Icon type="Copy" />
                  </Box>
                </Box>
                <Text fontSize="small">{color.toUpperCase()}</Text>
              </VStack>
            );
          })}
        </Wrap>
        <Text fontSize="small" opacity="secondary">
          {screenshotUrl
            ? 'Colors are generated from the image.'
            : 'Colors are generated randomly.'}
        </Text>
        <Text fontSize="large" mt={4}>
          More Color Palettes
        </Text>
        <Text fontSize="small" opacity="secondary">
          Check the below links for more color palettes inspiration.
        </Text>
        <UnorderedList spacing={4}>
          <ListItem>
            <Link href="https://materialui.co/colors">Material Design</Link>
          </ListItem>
          <ListItem>
            <Link href="https://colorhunt.co/">Color Hunt</Link>
          </ListItem>
          <ListItem>
            <Link href="https://v2.chakra-ui.com/docs/styled-system/theme#colors">Chakra UI</Link>
          </ListItem>
        </UnorderedList>
      </VStack>
    ),
    icon: <Icon type="ColorsPalette" />,
    title: (
      <HStack>
        <Text fontWeight="semibold" fontSize="xLarge">
          Color Palette
        </Text>
        {onRefresh && (
          <GradientTooltip label="Randomize again">
            <IconButton aria-label="Randomize again" onClick={onRefresh}>
              <RepeatIcon />
            </IconButton>
          </GradientTooltip>
        )}
      </HStack>
    ),
  };
};
