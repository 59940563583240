import { useMutation, useQueryClient } from '@tanstack/react-query';
import saveAs from 'file-saver';
import { supabaseMutationFn, useSupabaseTypedClient } from 'hooks/reactQuery';
import { useToastWrapper } from 'hooks/useToastWrapper';
import { useState } from 'react';

import { useCurrentUser } from 'auth/CurrentUserProvider';
import { CodeEditorType, CreateDesignUrlParams, EdgeFunctionName } from 'types/edgeFunctions';
import { TanstackQueryName } from 'types/frontend';

interface MutationVariables {
  screenName: string;
  type: CodeEditorType;
  entityId: string;
}

export const useFigmaDownload = ({ screenName, type, entityId }: MutationVariables) => {
  const { id: userId } = useCurrentUser();
  const { toastError } = useToastWrapper();

  const supabase = useSupabaseTypedClient();
  const queryClient = useQueryClient();

  const { toastSuccess } = useToastWrapper();

  const [figmaDesignBlob, setFigmaDesignBlob] = useState<Blob | undefined>(undefined);
  const [isDownloadingFigmaDesign, setIsDownloadingFigmaDesign] = useState(false);

  const handleDownloadFigmaDesignClick = async (url: string) => {
    if (!figmaDesignBlob) {
      const xhr = new XMLHttpRequest();

      xhr.responseType = 'blob';
      xhr.onload = () => {
        const blob = xhr.response;

        setFigmaDesignBlob(blob);
        saveAs(blob, `${screenName}-figma-design.zip`);
        toastSuccess('Figma design has been downloaded');
        setIsDownloadingFigmaDesign(false);
      };

      xhr.open('GET', url);
      xhr.send();
    } else {
      saveAs(figmaDesignBlob, `${screenName}-figma-design.zip`);
      toastSuccess('Figma design has been downloaded');
    }
  };

  const mutation = useMutation<string, string, MutationVariables>({
    mutationFn: supabaseMutationFn(({ screenName, type, entityId }) =>
      supabase.functions.invoke<string>(EdgeFunctionName.CreateDesignUrl, {
        body: { screenName, type, entityId } as CreateDesignUrlParams,
      })
    ),
    onSuccess: async (data) => {
      queryClient.invalidateQueries({
        queryKey: [TanstackQueryName.GetCurrentUser, userId],
      });
      queryClient.invalidateQueries({
        queryKey: [TanstackQueryName.GetProject, screenName],
      });
      queryClient.invalidateQueries({
        queryKey: [TanstackQueryName.GetChallenge, screenName],
      });

      await handleDownloadFigmaDesignClick(data);

      setIsDownloadingFigmaDesign(false);
    },
    onError: () => {
      setIsDownloadingFigmaDesign(false);
      toastError(`Can't download Figma design`);
    },
  });

  return {
    onFigmaDownload: () => {
      setIsDownloadingFigmaDesign(true);
      mutation.mutate({ screenName, type, entityId });
    },
    isDownloadingFigmaDesign,
  };
};
