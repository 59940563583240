import { Box, Center, HStack, IconButton, Stack, VStack, useDisclosure } from '@chakra-ui/react';
import { InfiniteData, useMutation, useQueryClient } from '@tanstack/react-query';
import {
  supabaseMutationFn,
  useInfiniteQueryWithErrorBoundary,
  useQueryWithErrorBoundary,
  useSupabaseTypedClient,
} from 'hooks/reactQuery';
import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useLocalStorageState from 'use-local-storage-state';

import { useCurrentUser } from 'auth/CurrentUserProvider';
import { BigBadge } from 'components/BigBadge';
import Button from 'components/Button';
import GradientPopover from 'components/GradientPopover';
import Icon from 'components/Icon';
import Link from 'components/Link';
import Loader from 'components/Loader';
import Text from 'components/Text';
import UserAvatar from 'components/UserAvatar';
import { useMediaQueries } from 'layout/MediaQueriesProvider';
import { Plan_Type_Enum } from 'types/databaseEnums';
import {
  EdgeFunctionName,
  NotificationMessage,
  NotificationMessageType,
  UpdateUserNotificationDateParams,
} from 'types/edgeFunctions';
import { ERoute, TanstackQueryName } from 'types/frontend';
import { getTimeAgo } from 'utils/dates';

import NewsletterModal from './NewsletterModal';

interface INotificationProps extends NotificationMessage {
  shouldHighlight: boolean;
  onNewsletterModalOpen?: () => void;
  handleCloseNotification: () => void;
}

const Notification = ({
  shouldHighlight,
  name,
  screen_name,
  plan,
  avatar_url,
  code_url,
  code_title,
  created_at,
  experience,
  source_type,
  onNewsletterModalOpen,
  handleCloseNotification,
}: INotificationProps) => {
  const navigate = useNavigate();

  const highlightBoxProps = shouldHighlight ? { backgroundColor: 'whiteAlpha.300' } : {};

  const toUserProfile = `${ERoute.Profile}/${screen_name}`;

  const navigateToUserProfile = (e) => {
    e.stopPropagation();

    handleCloseNotification();

    navigate(toUserProfile);
  };

  const renderNotificationText = () => {
    switch (source_type) {
      case NotificationMessageType.WELCOME: {
        return (
          <Text fontWeight="regular" fontSize="small">
            Welcome to BigDevSoon! 🎉 Your journey to building {`Dev's career`} starts now and we
            invite you to our{' '}
            <Link to="#" onClick={onNewsletterModalOpen}>
              LevelUp biweekly newsletter
            </Link>
            . 🚀
          </Text>
        );
      }
      case NotificationMessageType.USER_LIKE: {
        return (
          <Text fontWeight="regular" fontSize="small">
            liked your{' '}
            <Link to={code_url!} fontWeight="semibold">
              {code_title}
            </Link>{' '}
            code.
          </Text>
        );
      }
      case NotificationMessageType.USER_COMMENT: {
        return (
          <Text fontWeight="regular" fontSize="small">
            commented your{' '}
            <Link to={`${code_url}/comments`} fontWeight="semibold">
              {code_title}
            </Link>{' '}
            code.
          </Text>
        );
      }
    }
  };

  return (
    <Box
      width="100%"
      backgroundColor="whiteAlpha.200"
      borderBottom="1px solid"
      borderBottomColor="whiteAlpha.200"
      px={2}
      py={3}
      {...highlightBoxProps}
    >
      <HStack
        width="100%"
        justifyContent="space-between"
        alignItems="flex-start"
        overflowX="auto"
        position="relative"
        pr={4}
      >
        <Stack direction="row" spacing={4} width="100%" alignItems="center">
          {screen_name === 'bigdevsoon' ? (
            <Box>
              <Icon type="BdsNotification" size={40} />
            </Box>
          ) : (
            <UserAvatar
              name={name as string}
              src={avatar_url as string}
              onClick={navigateToUserProfile}
              experience={experience as number}
              hideCircularProgress
            />
          )}
          <VStack spacing={0} alignItems="flex-start">
            <HStack alignItems="flex-start">
              {screen_name === 'bigdevsoon' ? (
                <Text
                  maxW={plan !== Plan_Type_Enum.Free ? '150px' : '200px'}
                  isTruncated
                  fontSize="small"
                  opacity="default"
                >
                  {name}
                </Text>
              ) : (
                <Box onClick={handleCloseNotification}>
                  <Link to={toUserProfile} color="white">
                    <Text
                      maxW={plan !== Plan_Type_Enum.Free ? '150px' : '200px'}
                      isTruncated
                      _hover={{ textDecoration: 'underline' }}
                      fontSize="small"
                      opacity="default"
                    >
                      {name}
                    </Text>
                  </Link>
                </Box>
              )}
              {screen_name !== 'bigdevsoon' && (
                <BigBadge plan={plan as Plan_Type_Enum} showOnlySubscribed size="small" />
              )}
            </HStack>
            {renderNotificationText()}
          </VStack>
        </Stack>
        <Text
          fontSize="micro"
          opacity="secondary"
          whiteSpace="nowrap"
          position="absolute"
          right={0}
        >
          {getTimeAgo(created_at as string)}
        </Text>
      </HStack>
    </Box>
  );
};

const UserNotifications = () => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const { id: currentUserId } = useCurrentUser();
  const { isMobile } = useMediaQueries();
  const popoverContentRef = useRef<HTMLDivElement | null>(null);
  const ref = useRef<any>(null);
  const { pathname } = useLocation();
  const [localLastTime, setLocalLastTime] = useLocalStorageState<string | null>(
    `${currentUserId}-BDS-NOTIFICATION-LAST-TIME`,
    {
      defaultValue: null,
    }
  );

  const supabase = useSupabaseTypedClient();
  const queryClient = useQueryClient();

  const newsletterModal = useDisclosure();

  const mutation = useMutation<string, string, UpdateUserNotificationDateParams>({
    mutationFn: supabaseMutationFn(({ timestamp }) =>
      supabase.functions.invoke<string>(EdgeFunctionName.UpdateUserNotificationDate, {
        body: { timestamp },
      })
    ),
    onSuccess: async (data) => {
      setLocalLastTime(data);

      await refetchLastOpenedTime();
      await refetchUnreadNotifications();
    },
    onError: () => {
      // silent error, updating under the hood
    },
  });

  const { isPending: isPendingLastOpenedTime, refetch: refetchLastOpenedTime } =
    useQueryWithErrorBoundary({
      queryKey: [TanstackQueryName.GetNotificationLastOpenedTime, currentUserId],
      queryFn: async () => {
        const { data, error } = await supabase
          .from('user')
          .select('notification_last_opened_at')
          .eq('id', currentUserId)
          .single();

        if (error) return null;

        setLocalLastTime(data.notification_last_opened_at);

        return null;
      },
    });

  const {
    data: unreadNotificationsCount,
    refetch: refetchUnreadNotifications,
    isPending,
  } = useQueryWithErrorBoundary({
    gcTime: 0,
    staleTime: 0,
    queryKey: [TanstackQueryName.GetUnreadNotificationsCount, currentUserId],
    queryFn: async () => {
      const unreadNotificationsQuery = supabase
        .from('user_notification_view')
        .select('*', { count: 'exact', head: true });

      if (localLastTime) {
        unreadNotificationsQuery.gt('created_at', localLastTime);
      }

      const { count, error } = await unreadNotificationsQuery;

      if (error) return 0;

      return count;
    },
  });

  useEffect(() => {
    refetchUnreadNotifications();
  }, [pathname]);

  const { data, fetchNextPage, hasNextPage, isFetching } = useInfiniteQueryWithErrorBoundary({
    queryKey: [TanstackQueryName.GetUserNotifications, currentUserId],
    queryFn: async ({ pageParam }) => {
      const userNotificationsQuery = supabase
        .from('user_notification_view')
        .select('*')
        .order('created_at', { ascending: false })
        .limit(10);

      if (pageParam) {
        userNotificationsQuery.lt('created_at', pageParam);
      }

      const { data, error } = await userNotificationsQuery;

      if (error) throw error;

      return data;
    },
    placeholderData: {
      pageParams: [],
      pages: [],
    },
    enabled: false,
    initialPageParam: null,
    getNextPageParam: (lastPage) => {
      if (lastPage && Array.isArray(lastPage) && lastPage?.length > 0) {
        const lastItemCreatedAt = lastPage[lastPage.length - 1]?.created_at;

        return lastItemCreatedAt;
      }

      return undefined;
    },
  });

  const handleCloseNotification = () => {
    if (ref.current) {
      ref.current.onClose();
    }
  };

  const renderUserNotifications = (handleClose: () => void) => {
    const userNotificationsData = (data as unknown as InfiniteData<NotificationMessage[]>) || [];
    const userNotifications =
      userNotificationsData.pages.reduce(
        (acc, page) => acc.concat(page),
        [] as NotificationMessage[]
      ) || [];

    const userNotificationsLength = userNotifications.length || 0;
    const shouldLoadUserNotifications = isFetching && userNotificationsLength === 0;

    if (shouldLoadUserNotifications) {
      return (
        <Center width="100%" height={isMobile ? '40vh' : '400px'}>
          <Loader />
        </Center>
      );
    }

    return userNotifications.map((userNotification) => (
      <Notification
        key={`${userNotification.source_type}-${userNotification.created_at}-${userNotification.user_id}`}
        shouldHighlight={localLastTime ? userNotification.created_at! > localLastTime : true}
        onNewsletterModalOpen={newsletterModal.onOpen}
        handleCloseNotification={handleClose}
        {...userNotification}
      />
    ));
  };

  const isLoadingMore = isFetching;
  const hasMoreData =
    hasNextPage &&
    data &&
    // @ts-ignore
    data.pages?.reduce((acc, page) => acc.concat(page), []).length % 10 === 0;

  const showDot =
    !isPendingLastOpenedTime &&
    !mutation.isPending &&
    !isPopoverOpen &&
    !isPending &&
    localLastTime === null
      ? true
      : (unreadNotificationsCount || 0) > 0;

  return (
    <>
      <GradientPopover
        ref={ref}
        tooltip="Notifications"
        header={
          <Text fontSize="xLarge" fontWeight="semibold" pl={3} opacity="default">
            Notifications
          </Text>
        }
        noPadding
        triggerComponent={
          <IconButton
            variant="ghost"
            aria-label="Open notifications"
            onClick={() => {
              fetchNextPage();
            }}
          >
            <Box position="relative">
              <Icon type="NotificationBell" />
              {showDot && (
                <Center
                  position="absolute"
                  top={0}
                  right={0}
                  width="14px"
                  height="14px"
                  borderRadius="999px"
                  backgroundColor="red.400"
                  zIndex="popover"
                >
                  <Text fontSize="xMicro" fontWeight="semibold">
                    {unreadNotificationsCount! > 99
                      ? '99+'
                      : unreadNotificationsCount! === 0
                      ? 1
                      : unreadNotificationsCount}
                  </Text>
                </Center>
              )}
            </Box>
          </IconButton>
        }
        onPopoverStateChange={(isOpen) => {
          setIsPopoverOpen(isOpen);
        }}
        onClickOutside={() => {
          mutation.mutate({ timestamp: new Date().toISOString() });

          queryClient.removeQueries({
            queryKey: [TanstackQueryName.GetUserNotifications, currentUserId],
          });
        }}
        popoverContentRef={popoverContentRef}
        width={isMobile ? '100vw' : '460px'}
      >
        <VStack
          alignItems="flex-start"
          spacing={4}
          height={isMobile ? '40vh' : '400px'}
          overflowY="auto"
        >
          <Box width="100%">{renderUserNotifications(handleCloseNotification)}</Box>
          {hasMoreData && (
            <Center width="100%">
              <Button
                isLoading={isLoadingMore}
                onClick={() => {
                  fetchNextPage();
                  popoverContentRef?.current?.focus();
                }}
                mb={1}
              >
                Load more notifications
              </Button>
            </Center>
          )}
        </VStack>
      </GradientPopover>
      <NewsletterModal {...newsletterModal} />
    </>
  );
};

export default UserNotifications;
