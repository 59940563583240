import { Divider, useTheme, Tabs, TabList, Tab, Box } from '@chakra-ui/react';

import Text from './Text';

type StickyMenuTab = {
  id: string;
  name: string;
  background?: string;
};

interface StickyMenuProps {
  tabs: StickyMenuTab[];
}

const StickyMenu = ({ tabs }: StickyMenuProps) => {
  const { borderRadius } = useTheme();

  const handleTabClick = (id: string) => {
    document.getElementById(id)?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  return (
    <Tabs
      orientation="vertical"
      borderRadius={borderRadius}
      variant="unstyled"
      defaultIndex={1}
      width="100%"
      position="sticky"
      top="2%"
      py={2}
    >
      <TabList width="100%">
        {tabs.map(({ id, name, background }, index) => {
          const isFirstTab = index === 0;
          const isLastTab = tabs.length - 1 !== index;

          return (
            <span key={id}>
              <Tab
                justifyContent="flex-start"
                width="100%"
                transition="background 0.2s ease-in-out"
                _hover={{
                  background: 'whiteAlpha.300',
                }}
                borderRadius={
                  isFirstTab
                    ? `${borderRadius} ${borderRadius} 0 0`
                    : !isLastTab
                    ? `0 0 ${borderRadius} ${borderRadius}`
                    : ''
                }
                bg={background !== undefined ? background : 'whiteAlpha.200'}
                onClick={() => handleTabClick(id)}
                py={3}
                px={5}
              >
                <Box>
                  <Text>{name}</Text>
                </Box>
              </Tab>
              {isLastTab && <Divider />}
            </span>
          );
        })}
      </TabList>
    </Tabs>
  );
};

export default StickyMenu;
