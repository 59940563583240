import { Link as ChakraUiLink, LinkProps as ChakraUiLinkProps } from '@chakra-ui/react';
import { Link as ReactRouterLink, LinkProps as ReactRouterLinkProps } from 'react-router-dom';

export interface LinkProps extends ChakraUiLinkProps {
  to?: ReactRouterLinkProps['to'];
}

const Link = ({ href, to, children, ...props }: LinkProps) => {
  if (to) {
    return (
      <ChakraUiLink
        as={ReactRouterLink}
        to={to}
        color="purple.200"
        fontWeight="medium"
        transition="all 0.2s ease-out"
        {...props}
      >
        {children}
      </ChakraUiLink>
    );
  }

  if (href) {
    return (
      <ChakraUiLink
        href={href}
        isExternal
        fontWeight="medium"
        transition="all 0.2s ease-out"
        color="purple.200"
        {...props}
      >
        {children}
      </ChakraUiLink>
    );
  }

  return <>{children}</>;
};

export default Link;
