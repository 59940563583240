import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import {
  Divider,
  Drawer,
  DrawerOverlay,
  CircularProgress,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  HStack,
  ListItem,
  VStack,
  useTheme,
  Center,
  DrawerFooter,
  UnorderedList,
} from '@chakra-ui/react';
import React from 'react';
import ReactMarkdown from 'react-markdown';

import { useAuth } from 'auth/AuthProvider';
import { useCurrentUser } from 'auth/CurrentUserProvider';
import Button from 'components/Button';
import GradientTooltip from 'components/GradientTooltip';
import Icon from 'components/Icon';
import Link from 'components/Link';
import ImagePreviewModal from 'components/Modal/ImagePreviewModal';
import ProjectElement from 'components/ProjectElement';
import Text from 'components/Text';
import {
  DifficultyMarker,
  StatusIconMapper,
  StatusTextMapper,
} from 'features/practice-problems/PracticeProblems';
import { Experience_Level_Enum, User_Card_Status_Enum } from 'types/databaseEnums';
import { Project, ProjectCard } from 'types/edgeFunctions';
import { mapExpToCard } from 'utils/constants';
import { hasFirstCardAccessOnly } from 'utils/user';

interface ICardDrawerProps {
  project: Project;
  currentCard?: ProjectCard | null;
  isOpen: boolean;
  onCurrentCardChange: (card: ProjectCard) => void;
  onClose: () => void;
}

const CardDrawer = ({
  project,
  currentCard,
  isOpen,
  onClose,
  onCurrentCardChange,
}: ICardDrawerProps) => {
  const { borderRadius } = useTheme();
  const { userId: authId } = useAuth();
  const { isFree, createdAt } = useCurrentUser();

  const divRef = React.useRef<HTMLDivElement | null>(null);

  const currentCardIndex = project.cards.findIndex((card) => card.title === currentCard?.title);
  const isFirstCardIndex =
    project.cards.findIndex((card) => card.title === currentCard?.title) === 0;
  const isLastCardIndex =
    project.cards.findIndex((card) => card.title === currentCard?.title) ===
    project.cards.length - 1;

  const completedCardsCount =
    project.cards.filter((card) => card.status === User_Card_Status_Enum.Completed).length || 0;

  const progress =
    completedCardsCount === 0
      ? 0
      : Math.floor((completedCardsCount / (project.cards.length || 1)) * 100);

  const isFreeWithoutCards = !authId || (isFree && hasFirstCardAccessOnly(createdAt));

  return (
    <>
      <div ref={divRef}></div>
      <Drawer isOpen={isOpen} placement="right" onClose={onClose} finalFocusRef={divRef} size="lg">
        <DrawerOverlay />
        <DrawerContent backgroundColor="gray.900">
          <DrawerCloseButton mt={1} />
          <DrawerHeader pb={3}>
            <HStack width="100%" justifyContent="space-between">
              <Text fontSize="large" fontWeight="semibold">
                Cards
              </Text>
              <GradientTooltip
                label={`${completedCardsCount}/${project.cards.length || 1} completed`}
              >
                <CircularProgress
                  value={progress}
                  color="green.400"
                  thickness="6px"
                  size={4}
                  mr={8}
                  mb={1}
                />
              </GradientTooltip>
            </HStack>
          </DrawerHeader>
          <Divider />
          <DrawerBody p={5}>
            <VStack width="100%" alignItems="flex-start" pb={4}>
              <HStack justifyContent="space-between" alignItems="center" width="100%" pr={2}>
                <HStack>
                  <Text fontSize="xxLarge" fontWeight="bold">
                    {currentCard?.title}
                  </Text>
                  <ProjectElement
                    type={
                      currentCard?.status === User_Card_Status_Enum.Completed
                        ? 'experienceCompleted'
                        : 'experience'
                    }
                    experience={
                      mapExpToCard[currentCard?.difficulty_level || Experience_Level_Enum.Junior]
                    }
                  />
                </HStack>
                <HStack>
                  {StatusIconMapper[currentCard?.status || User_Card_Status_Enum.NotStarted]}
                  <Text fontSize="small">
                    {StatusTextMapper[currentCard?.status || User_Card_Status_Enum.NotStarted]}
                  </Text>
                </HStack>
              </HStack>
              <DifficultyMarker
                difficulty={currentCard?.difficulty_level || Experience_Level_Enum.Junior}
              />
            </VStack>
            {currentCard?.screenshot_url && (
              <ImagePreviewModal
                url={currentCard?.screenshot_url || ''}
                title={currentCard?.title || ''}
                imageProps={{
                  maxWidth: '100%',
                  height: 'auto',
                  maxHeight: '400px',
                  htmlWidth: 1440,
                  htmlHeight: 1080,
                  aspectRatio: '4 / 3',
                  fallbackStrategy: 'onError',
                  fallback: (
                    <Center
                      width="100%"
                      height="auto"
                      maxHeight="400px"
                      minHeight={300}
                      background="white"
                      borderRadius={borderRadius}
                    >
                      <Text color="black">Image not found</Text>
                    </Center>
                  ),
                }}
              />
            )}
            <ReactMarkdown
              components={{
                h1: ({ node, ...props }) => (
                  <HStack mt={5} mb={3}>
                    <Icon type="InstructionsUserStory" size={24} />
                    <Text fontSize="xLarge" {...props} />
                  </HStack>
                ),
                h2: ({ node, ...props }) => (
                  <HStack mt={5} mb={3}>
                    <Icon type="InstructionsAcceptanceCriteria" size={24} />
                    <Text fontSize="xLarge" {...props} />
                  </HStack>
                ),
                ul: ({ node, ...props }) => <UnorderedList spacing={2} pl={3} {...props} />,
                li: ({ node, ...props }) => (
                  <ListItem lineHeight="26px" fontWeight="regular" {...props} />
                ),
                a: ({ node, ...props }) => <Link {...props} />,
                p: ({ node, ...props }) => (
                  <Text lineHeight="26px" {...props} fontWeight="regular" />
                ),
              }}
            >
              {currentCard?.description || ''}
            </ReactMarkdown>
          </DrawerBody>
          <DrawerFooter>
            <HStack width="100%" justifyContent="space-between">
              {!isFirstCardIndex ? (
                <Button
                  scheme="outline"
                  leftIcon={<ChevronLeftIcon />}
                  onClick={() => onCurrentCardChange(project.cards[currentCardIndex - 1])}
                >
                  Prev
                </Button>
              ) : (
                <div />
              )}
              {!isLastCardIndex && !isFreeWithoutCards ? (
                <Button
                  scheme="outline"
                  rightIcon={<ChevronRightIcon />}
                  onClick={() => onCurrentCardChange(project.cards[currentCardIndex + 1])}
                >
                  Next
                </Button>
              ) : (
                <div />
              )}
            </HStack>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default CardDrawer;
