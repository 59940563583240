import convert from 'color-convert';
import colorNameList from 'color-name-list';
import ColorThief from 'colorthief';
import nearestColor from 'nearest-color';

import { convertImageUrlToBase64 } from 'utils/links';

import { formatCode } from './CodeEditor/CodeEditor';

export type NearestColor = {
  name: string;
  // value is hex
  value: string;
  rgb: {
    r: number;
    g: number;
    b: number;
  };
};

export type ColorPalette = {
  dominantColor: string;
  palette: string[];
};

// Convert base64 string to HTMLImageElement
const base64ToImage = (base64: string): Promise<HTMLImageElement> => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve(img);
    img.onerror = reject;
    img.src = base64;
  });
};

// Function to generate a single random color in hexadecimal format
const generateRandomColor = (): string => {
  const randomColor = Math.floor(Math.random() * 16777215).toString(16);

  return `#${randomColor.padStart(6, '0')}`; // Ensures the hex color is 6 characters long
};

// Function to generate a random color palette
export const generateRandomColorPalette = (size = 5): ColorPalette => {
  const palette = Array.from({ length: size }, () => generateRandomColor());

  return {
    dominantColor: palette[0],
    palette: palette,
  };
};

// Get the closest color from the list of color names
const getColorHex = (nearest: (colorHex: string) => NearestColor, rgb: number[]): string => {
  const colorHex = `#${convert.rgb.hex(rgb).toLowerCase()}`;
  const closestColor = nearest(colorHex);

  return closestColor.value;
};

// Generate a color palette from an image
export const generateColorPalette = async (
  screenshotUrl?: string | null
): Promise<ColorPalette> => {
  if (!screenshotUrl) {
    return generateRandomColorPalette();
  }

  const colorThief = new ColorThief();
  const base64Image = await convertImageUrlToBase64(screenshotUrl);
  const imageElement = await base64ToImage(base64Image!);

  const colors = colorNameList.reduce((o, { name, hex }) => Object.assign(o, { [name]: hex }), {});
  const nearest = nearestColor.from(colors);

  const dominantColorRGB = await colorThief.getColor(imageElement);
  const paletteRGB = await colorThief.getPalette(imageElement, 5);

  return {
    dominantColor: getColorHex(nearest, dominantColorRGB),
    palette: paletteRGB.map((rgb: number[]) => getColorHex(nearest, rgb)),
  };
};

export const checkCodeLimit = (isFreeUser: boolean, code: string) => {
  if (isFreeUser && code.length > 10000) {
    return false;
  }

  if (code.length > 20000) {
    return false;
  }

  return true;
};

interface IIsCodeSameParams {
  html: string;
  css: string;
  js: string;
  storedHtml: string;
  storedCss: string;
  storedJs: string;
}

export const isCodeSame = ({
  html,
  css,
  js,
  storedHtml,
  storedCss,
  storedJs,
}: IIsCodeSameParams) => {
  return (
    formatCode(html, 0) !== formatCode(storedHtml, 0) ||
    formatCode(css, 1) !== formatCode(storedCss, 1) ||
    formatCode(js, 2) !== formatCode(storedJs, 2)
  );
};
