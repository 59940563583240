import {
  Box,
  Divider,
  useTheme,
  InputGroup,
  Input,
  InputLeftElement,
  Stack,
  FormControl,
  FormLabel,
  FormErrorMessage,
  InputLeftAddon,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { supabaseMutationFn, useSupabaseTypedClient } from 'hooks/reactQuery';
import { useToastWrapper } from 'hooks/useToastWrapper';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { useCurrentUser } from 'auth/CurrentUserProvider';
import Button from 'components/Button';
import Icon from 'components/Icon';
import Text from 'components/Text';
import UploadPhoto from 'components/UploadPhoto';
import {
  EdgeFunctionName,
  UpdateUserProfileParams,
  UploadUserAvatarParams,
  UserProfile,
} from 'types/edgeFunctions';
import { TanstackQueryName } from 'types/frontend';

interface UserDetailsForm {
  name: string;
  location?: string;
  public_email?: string;
  website_url?: string;
}

interface UserDetailsProps {
  avatarUrl?: string;
  initialValues: Pick<UserProfile, 'name' | 'location' | 'public_email' | 'website_url'>;
}

const validationSchema = yup
  .object({
    name: yup
      .string()
      .required('Name is required')
      .max(255, 'Name is too long (maximum is 255 characters)'),
    location: yup.string().max(255, 'Location is too long (maximum is 255 characters)'),
    public_email: yup
      .string()
      .email('Public email should be a valid email (e.g. john.doe@gmail.com)')
      .max(255, 'Public email is too long (maximum is 255 characters)'),
    website_url: yup
      .string()
      .test('valid-website-url', 'Website URL should be a valid URL', (value) => {
        if (!value) return true;

        const urlRegex =
          /^(?:(?:https?:\/\/)?(?:www\.)?)?([a-z0-9-]+(?:\.[a-z0-9-]+)*\.[a-z]{2,})(?:\/.*)?$/i;
        return urlRegex.test(value);
      })
      .max(255, 'Website URL is too long (maximum is 255 characters)'),
  })
  .required();

const UserDetails = ({ avatarUrl, initialValues }: UserDetailsProps) => {
  const [currentAvatarUrl, setCurrentAvatarUrl] = useState(avatarUrl);
  const [isPhotoUploadLoading, setIsPhotoUploadLoading] = useState(false);

  const { borderRadius } = useTheme();

  const { toastSuccess, toastError } = useToastWrapper();

  const { id: userId } = useCurrentUser();

  const supabase = useSupabaseTypedClient();

  const queryClient = useQueryClient();

  useEffect(() => {
    if (currentAvatarUrl !== avatarUrl) {
      setCurrentAvatarUrl(avatarUrl);
    }
  }, [avatarUrl]);

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors, isDirty },
  } = useForm<UserDetailsForm>({
    resolver: yupResolver(validationSchema),
    mode: 'onSubmit',
    defaultValues: {
      name: initialValues.name,
      location: initialValues.location || '',
      public_email: initialValues.public_email || '',
      website_url: initialValues.website_url || '',
    },
  });

  const uploadUserAvatarMutation = useMutation<string, string, FormData>({
    mutationFn: supabaseMutationFn((params) =>
      supabase.functions.invoke<string>(EdgeFunctionName.UploadUserAvatar, {
        body: params as UploadUserAvatarParams,
      })
    ),
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries({ queryKey: [TanstackQueryName.GetCurrentUser, userId] }),
        queryClient.invalidateQueries({
          queryKey: [TanstackQueryName.GetUserProfile, userId],
        }),
      ]);

      setIsPhotoUploadLoading(false);
      toastSuccess('Photo uploaded');
    },
    onError: () => {
      setIsPhotoUploadLoading(false);
      toastError(`Can't upload photo`);
    },
  });

  const updateUserProfileMutation = useMutation<UserProfile, string, UserDetailsForm>({
    mutationFn: supabaseMutationFn((params) =>
      supabase.functions.invoke<UserProfile>(EdgeFunctionName.UpdateUserProfile, {
        body: params as UpdateUserProfileParams,
      })
    ),
    onSuccess: (data) => {
      reset({
        name: data.name,
        location: data.location,
        public_email: data.public_email,
        website_url: data.website_url,
      });

      queryClient.invalidateQueries({ queryKey: [TanstackQueryName.GetUserProfile, userId] });

      toastSuccess('User details saved');
    },
    onError: () => {
      toastError(`Can't save user details`);
    },
  });

  const onSubmit = async ({ name, location, public_email, website_url }: UserDetailsForm) => {
    await updateUserProfileMutation.mutate({
      name: name ? name.trim() : '',
      location: location ? location.trim() : '',
      public_email: public_email ? public_email.trim() : '',
      website_url: website_url ? website_url.trim() : '',
    });
  };

  const onPhotoUpload = async (photo: Blob) => {
    try {
      setIsPhotoUploadLoading(true);

      const formData = new FormData();

      formData.append('image', photo, 'avatar.jpeg');

      await uploadUserAvatarMutation.mutate(formData);
    } catch (error) {
      toastError(`Can't upload  photo`);
    }
  };

  return (
    <>
      <Box py={5} px={6} bg="whiteAlpha.200" borderRadius={borderRadius}>
        <Text fontSize="xLarge" pb={5}>
          User details
        </Text>
        <Box pb={5}>
          <UploadPhoto
            isLoading={isPhotoUploadLoading}
            setIsLoading={setIsPhotoUploadLoading}
            photoUrl={currentAvatarUrl}
            onSubmit={onPhotoUpload}
          />
        </Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={4}>
            <Stack spacing={1}>
              <FormControl isInvalid={Boolean(errors.name)}>
                <FormLabel htmlFor="name" fontWeight="normal">
                  Username
                </FormLabel>
                <Input {...register('name')} variant="filled" placeholder="username" />
                <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
              </FormControl>
            </Stack>
            <Stack spacing={1}>
              <FormControl isInvalid={Boolean(errors.location)}>
                <FormLabel htmlFor="location" fontWeight="normal">
                  Location
                </FormLabel>
                <InputGroup>
                  <InputLeftElement pointerEvents="none">
                    <Icon type="Location" />
                  </InputLeftElement>
                  <Input {...register('location')} variant="filled" placeholder="location" />
                </InputGroup>
                <FormErrorMessage>{errors.location?.message}</FormErrorMessage>
              </FormControl>
            </Stack>
            <Stack spacing={2}>
              <FormControl isInvalid={Boolean(errors.public_email)}>
                <FormLabel htmlFor="public_email" fontWeight="normal">
                  Public email
                </FormLabel>
                <InputGroup>
                  <InputLeftElement pointerEvents="none">
                    <Icon type="Email" />
                  </InputLeftElement>
                  <Input {...register('public_email')} variant="filled" placeholder="email" />
                </InputGroup>
                <FormErrorMessage>{errors.public_email?.message}</FormErrorMessage>
              </FormControl>
              <Text fontSize="small" opacity="secondary" fontWeight="regular">
                {`Add an email to your profile to enable others to contact you via the "Send Email" button. You can remove it at any time.`}
              </Text>
            </Stack>
            <Stack spacing={1}>
              <FormControl isInvalid={Boolean(errors.website_url)}>
                <FormLabel htmlFor="website_url" fontWeight="normal">
                  Website URL
                </FormLabel>
                <InputGroup>
                  <InputLeftAddon>https://</InputLeftAddon>
                  <Input
                    {...register('website_url')}
                    variant="filled"
                    placeholder="your portfolio URL"
                  />
                </InputGroup>
                <FormErrorMessage>{errors.website_url?.message}</FormErrorMessage>
              </FormControl>
            </Stack>
          </Stack>
          <Divider mt={6} mb={5} />
          <Button
            type="submit"
            isLoading={updateUserProfileMutation.isPending}
            isDisabled={!isDirty}
          >
            Save user details
          </Button>
        </form>
      </Box>
    </>
  );
};

export default UserDetails;
