import { Text, CloseButton, Box, HStack } from '@chakra-ui/react';
import { useQueryWithErrorBoundary, useSupabaseTypedClient } from 'hooks/reactQuery';
import { useEffect } from 'react';
import useLocalStorageState from 'use-local-storage-state';

import { useAuth } from 'auth/AuthProvider';
import { useCurrentUser } from 'auth/CurrentUserProvider';
import { BigBadge } from 'components/BigBadge';
import Link from 'components/Link';
import { Plan_Type_Enum } from 'types/databaseEnums';
import { ERoute } from 'types/frontend';

import { useMediaQueries } from './MediaQueriesProvider';

const Banner = () => {
  const { userId: authId } = useAuth();
  const {
    isMonthlySubscriber,
    isYearlySubscriber,
    isLifetimeUser,
    isTerminated,
    isSuperUser,
    screenName,
  } = useCurrentUser();

  const [banner, setBanner] = useLocalStorageState(`banner-${screenName}`, {
    defaultValue: {
      show: false,
      version: 0,
    },
  });

  const { isDesktopNavigation } = useMediaQueries();

  const supabase = useSupabaseTypedClient();

  const { data } = useQueryWithErrorBoundary({
    queryKey: ['banner'],
    queryFn: async () => {
      const { data, error } = await supabase
        .from('metadata')
        .select('key, value')
        .in('key', ['banner_show', 'banner_promo_code', 'banner_off_percentage', 'banner_version']);

      if (error) throw error;

      return {
        show: data.find((d) => d.key === 'banner_show')?.value === 'true',
        promoCode: data.find((d) => d.key === 'banner_promo_code')?.value,
        offPercentage: data.find((d) => d.key === 'banner_off_percentage')?.value,
        version: Number(data.find((d) => d.key === 'banner_version')?.value),
      };
    },
  });

  useEffect(() => {
    if (!data) return;

    if (data.show && data.version > Number(banner.version)) {
      setBanner({
        show: true,
        version: data.version,
      });
    }
  }, [data]);

  const handleCloseBanner = () => {
    setBanner({
      show: false,
      version: data?.version || 0,
    });
  };

  if (!authId) {
    return null;
  }

  if (!banner.show || !data?.show) {
    return null;
  }

  if (!isDesktopNavigation) {
    return null;
  }

  if (isMonthlySubscriber || isYearlySubscriber || isLifetimeUser || isTerminated || isSuperUser) {
    return null;
  }

  if (!data) {
    return null;
  }

  return (
    <>
      <Box
        bg="purple.600"
        height="40px"
        py={1}
        px={2}
        left="50%"
        transform="translateX(-50%)"
        position="absolute"
        bottom={0}
        width="100%"
        zIndex={99999}
      >
        <HStack spacing={1} justifyContent="center">
          <Text>Become</Text>
          <Text fontWeight="semibold">BIG</Text>
          <Text>and get</Text>
          <Text color="orange.200" fontWeight="semibold" px={0.5}>
            {data.offPercentage}% off
          </Text>
          <Text pr={0.5}>for</Text>
          <BigBadge plan={Plan_Type_Enum.Lifetime} size="small" />
          <Text pl={0.5}>plans with</Text>
          <Text fontWeight="semibold">{data.promoCode}</Text>
          <Text>code.</Text>
          <Text textDecoration="underline">
            <Link color="white" to={ERoute.Pricing}>
              Upgrade your plan
            </Link>
          </Text>
          <CloseButton onClick={handleCloseBanner} />
        </HStack>
      </Box>
    </>
  );
};

export default Banner;
