export const openLinkInNewTab = (url?: string) => {
  if (!url) {
    return;
  }

  const anchor = document.createElement('a');
  anchor.href = url;
  anchor.target = '_blank';

  // Simulate a click on the anchor element
  const event = new MouseEvent('click', {
    view: window,
    bubbles: true,
    cancelable: true,
  });

  anchor.dispatchEvent(event);
};

const GITHUB_TEMPLATE_PREFIX = 'https://github.com/elise-bigdevsoon';
const GLITCH_TEMPLATE_PREFIX = 'https://glitch.com/edit/#!';

export const openGitHubTemplate = (projectScreenName: string) => {
  openLinkInNewTab(`${GITHUB_TEMPLATE_PREFIX}/big-dev-soon-${projectScreenName}`);
};

export const openGlitchTemplate = (projectScreenName: string) => {
  openLinkInNewTab(`${GLITCH_TEMPLATE_PREFIX}/big-dev-soon-${projectScreenName}`);
};

export const transformGlitchRepositoryToPreviewURL = (url: string) => {
  const match = url.match(/#!\/([^\/?]+)/);

  if (!match) return '';

  const projectId = match[1];

  return `https://${projectId}.glitch.me`;
};

export async function convertImageUrlToBase64(url: string) {
  try {
    const response = await fetch(url);

    if (!response.ok) {
      throw new Error('Failed to fetch the image');
    }

    const arrayBuffer = await response.arrayBuffer();
    const byteArray = new Uint8Array(arrayBuffer);

    let binaryString = '';

    for (let i = 0; i < byteArray.byteLength; i++) {
      binaryString += String.fromCharCode(byteArray[i]);
    }

    const base64String = btoa(binaryString);
    return `data:image/jpeg;base64,${base64String}`;
  } catch (error) {
    console.error('Error converting image to Base64:', error);
  }
}
