import { Experience_Level_Enum } from './databaseEnums';
import { RecentUser } from './edgeFunctions';

export enum ERoute {
  Dashboard = '/dashboard',
  Onboarding = '/onboarding',
  Projects = '/projects',
  Challenges = '/challenges',
  PracticeProblems = '/problems',
  Prototypes = '/prototypes',
  NewPrototype = '/new',
  CodeFrames = '/code-frames',
  NewCodeFrame = '/new',
  Profile = '/profile',
  Billing = '/billing',
  Settings = '/settings',
  Payment = '/payment',
  PaymentSuccess = '/payment/success',
  GitHubCallback = '/github-callback',
  DiscordCallback = '/discord-callback',
  StripeCallback = '/stripe-callback',
  Ref = '/ref',
  UnlockBig = '/unlock-big',
  Pricing = '/pricing',
}

export interface IModal {
  isOpen: boolean;
  onOpen?: () => void;
  onClose: () => void;
}

export type TDay =
  | 'Monday'
  | 'Tuesday'
  | 'Wednesday'
  | 'Thursday'
  | 'Friday'
  | 'Saturday'
  | 'Sunday';

export type TSubscriptionMode = 'init' | 'change' | 'renew';
export interface IStepComponent {
  component: JSX.Element;
  name: string;
}

export type TLimitation = 'trial' | 'monthly';

export type TEstimate = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8;

export type ProjectMetadata = {
  id: string;
  screen_name: string;
  difficulty_lvl: Experience_Level_Enum;
};

export type ChallengeMetadata = {
  id: string;
  screen_name: string;
};

export type PracticeProblemMetadata = {
  id: string;
  screen_name: string;
};

export type RecentUsersResponse = {
  recent_users: RecentUser[];
  users_completed_count: number;
};

export enum TanstackQueryName {
  GetProject = 'get-project',
  GetProjects = 'get-projects',
  GetProjectSolution = 'get-project-solution',
  GetProjectSolutions = 'get-project-solutions',
  GetChallenge = 'get-challenge',
  GetChallenges = 'get-challenges',
  GetChallengesCount = 'get-challenges-count',
  GetChallengesCompletedCount = 'get-challenges-completed-count',
  GetPracticeProblemsCount = 'get-practice-problems-count',
  GetPracticeProblemsCompletedCount = 'get-practice-problems-completed-count',
  GetChallengeSolution = 'get-challenge-solution',
  GetChallengeSolutions = 'get-challenge-solutions',
  GetPracticeProblems = 'get-practice-problems',
  GetPracticeProblem = 'get-practice-problem',
  GetPracticeProblemsSolutions = 'get-practice-problems-solutions',
  GetPracticeProblemSolution = 'get-practice-problem-solution',
  GetPrototypes = 'get-prototypes',
  GetPrototype = 'get-prototype',
  GetCodeFrames = 'get-code-frames',
  GetCodeFrame = 'get-code-frame',
  GetUserProfile = 'get-user-profile',
  GetProfileCode = 'get-profile-code',
  GetCurrentUser = 'get-current-user',
  GetUserId = 'get-user-id',
  GetUserHome = 'get-user-home',
  GetUserAchievements = 'get-user-achievements',
  GetCommunityQuestions = 'get-community-questions',
  GetUserCode = 'get-user-code',
  GetUserFeed = 'get-user-feed',
  GetLeaderboard = 'get-leaderboard',
  GetUserNotifications = 'get-user-notifications',
  GetUnreadNotificationsCount = 'get-unread-notifications-count',
  GetNotificationLastOpenedTime = 'get-notification-last-opened-time',
}

export const MAIN_BRANCH = 'main';
