import { useMutation, useQueryClient } from '@tanstack/react-query';
import { supabaseMutationFn, useSupabaseTypedClient } from 'hooks/reactQuery';
import { useExitAlert } from 'hooks/useExitAlert';
import { useToastWrapper } from 'hooks/useToastWrapper';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useCurrentUser } from 'auth/CurrentUserProvider';
import DevSandboxSkeleton from 'components/DevSandbox/DevSandboxSkeleton';
import { CODE_FREE_LIMIT, CODE_PAID_LIMIT } from 'components/DevSandbox/constants';
import { EdgeFunctionName, SubmitCodeParams, SubmitCodeResponse } from 'types/edgeFunctions';
import { ERoute, TanstackQueryName } from 'types/frontend';

const NewCodeFrame = () => {
  const supabase = useSupabaseTypedClient();
  const queryClient = useQueryClient();

  const navigate = useNavigate();
  const { state } = useLocation();

  const { id: currentUserId, isFree } = useCurrentUser();

  const { toastError } = useToastWrapper();

  const mutation = useMutation<SubmitCodeResponse, string, SubmitCodeParams>({
    mutationFn: supabaseMutationFn((params) =>
      supabase.functions.invoke<SubmitCodeResponse>(EdgeFunctionName.SubmitCode, {
        body: params as SubmitCodeParams,
      })
    ),
    onSuccess: (data) => {
      queryClient.removeQueries({ queryKey: [TanstackQueryName.GetCodeFrames, currentUserId] });
      queryClient.removeQueries({ queryKey: [TanstackQueryName.GetCodeFrames, null] });

      navigate(data.solution_url, { replace: true });
    },
    onError: () => {
      toastError('Could not create code frame (e.g. limit of code frames has been reached).');
      navigate(ERoute.CodeFrames);
    },
  });

  const handleSave = async () => {
    if (!state?.shouldCreate) {
      navigate(ERoute.CodeFrames);

      return;
    }

    const CODE_LIMIT = isFree ? CODE_FREE_LIMIT : CODE_PAID_LIMIT;

    if (state?.html && state.html > CODE_LIMIT) {
      toastError(`Exceeded maximum ${CODE_LIMIT} characters of HTML`);
      navigate(ERoute.CodeFrames);

      return;
    }

    if (state?.css && state.css > CODE_LIMIT) {
      toastError(`Exceeded maximum ${CODE_LIMIT} characters of CSS`);
      navigate(ERoute.CodeFrames);

      return;
    }

    if (state?.js && state.js > CODE_LIMIT) {
      toastError(`Exceeded maximum ${CODE_LIMIT} characters of JS`);
      navigate(ERoute.CodeFrames);

      return;
    }

    mutation.mutate({
      type: 'code-frame',
      entityScreenName: '',
      title: '',
      html: state?.html || '',
      css: state?.css || '',
      js: state?.js || '',
      feedback: '',
      repository_url: '',
      live_preview_url: '',
      iframe_screenshot: '',
      screenshot_url: state?.screenshotUrl || '',
      is_private: false,
    });
  };

  useEffect(() => {
    handleSave();
  }, []);

  useExitAlert({ shouldShowBrowserExitAlert: mutation.isPending });

  return <DevSandboxSkeleton loadingText="Creating code frame..." />;
};

export default NewCodeFrame;
