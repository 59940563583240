import { useEffect } from 'react';

export const useScrollToTop = () => {
  useEffect(() => {
    const element = document.getElementById('big-dev-soon-main-scroller');

    if (element) {
      element.scrollTo(0, 0);
    }
  }, []);
};
