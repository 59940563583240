import { addMinutes, addSeconds, isAfter } from 'date-fns';
import { useEffect, useState } from 'react';

import { useCurrentUser } from 'auth/CurrentUserProvider';
import { StorageVars } from 'config/constants/storage';
import { EdgeFunctionName, SubscriptionDetails } from 'types/edgeFunctions';

import { useQueryWithErrorBoundary, useSupabaseTypedClient } from './reactQuery';

export interface IBillingStorage {
  __timestamp?: Date;
}

const storageLifetimeInMinutes = 10;

export type ISetStorageValues = { param: keyof IBillingStorage; value: any }[];

export const useBillingStorage = () => {
  const { screenName, id } = useCurrentUser();
  const supabase = useSupabaseTypedClient();

  const uniqueIdx = `${screenName}-${StorageVars.BDS_BILLING}`;

  const [storageValue, setStorageValue] = useState<IBillingStorage | null>(() => {
    const item = localStorage.getItem(uniqueIdx);

    if (item) {
      const parsedItem: IBillingStorage = JSON.parse(item);

      if (
        isAfter(new Date(parsedItem.__timestamp!), addMinutes(new Date(), storageLifetimeInMinutes))
      ) {
        return null;
      }

      return parsedItem;
    }

    return null;
  });

  const { isLoading, refetch, data } = useQueryWithErrorBoundary({
    queryKey: [EdgeFunctionName.GetSubscriptionDetails, id],
    queryFn: async () => {
      const { data, error } = await supabase.functions.invoke<SubscriptionDetails>(
        EdgeFunctionName.GetSubscriptionDetails
      );

      if (error) throw error;

      return data;
    },
  });
  useEffect(() => {
    if (storageValue === null) {
      localStorage.removeItem(uniqueIdx);
    } else {
      localStorage.setItem(uniqueIdx, JSON.stringify(storageValue));
    }
  }, [storageValue]);

  useEffect(() => {
    if (
      storageValue &&
      data?.timestamp &&
      isAfter(new Date(data.timestamp), new Date(storageValue.__timestamp!))
    ) {
      setStorageValue(null);
    }
  }, [data]);

  useEffect(() => {
    const refetchQuery = async () => {
      await refetch();
    };

    refetchQuery();
  }, [storageValue]);

  const cacheTimestamp = async () => {
    setStorageValue({
      __timestamp: addSeconds(new Date(), -45),
    });

    await refetch();
  };

  const clearStorage = () => {
    setStorageValue(null);
  };

  const isWaitingForNewData = storageValue !== null;

  return {
    cacheTimestamp,
    clearStorage,
    data,
    isLoading,
    isWaitingForNewData,
  };
};
