import { SimpleGrid, Center, Container, Stack } from '@chakra-ui/react';
import { useGetProjectsQuery } from 'hooks/queries/projects/useGetProjectsQuery';
import { useScrollToTop } from 'hooks/useScrollToTop';
import { useState } from 'react';

import { ReactComponent as SolutionsEmptyIllustration } from 'assets/illustrations/SolutionsEmpty.svg';
import Button from 'components/Button';
import PageLoader from 'components/PageLoader';
import ProjectCard from 'components/ProjectCard';
import { SEO } from 'components/SEO';
import Tabs from 'components/Tabs';
import Text from 'components/Text';
import { useMediaQueries } from 'layout/MediaQueriesProvider';
import { User_Project_Status_Enum } from 'types/databaseEnums';

const Projects = () => {
  const [tabIndex, setTabIndex] = useState(0);

  const { isMobile } = useMediaQueries();

  const { data, isPending } = useGetProjectsQuery();

  useScrollToTop();

  if (isPending || !data) {
    return <PageLoader shift />;
  }

  const renderEmptyProjects = () => {
    return (
      <Center width="100%" mt={isMobile ? 10 : '12vh'}>
        <Stack spacing={5}>
          <SolutionsEmptyIllustration />
          <Text fontSize="xLarge" fontWeight="regular" textAlign="center">
            No projects found.
          </Text>
          <Button onClick={() => setTabIndex(0)}>View all projects</Button>
        </Stack>
      </Center>
    );
  };

  const projects = data;

  const filteredProjects = projects.filter((project) => {
    if (tabIndex === 0) {
      return true;
    }

    if (tabIndex === 1) {
      return project.status === User_Project_Status_Enum.NotStarted;
    }

    return project.status === User_Project_Status_Enum.Completed;
  });

  return (
    <Container maxW="1400px" width="100%">
      <SEO
        title="BigDevSoon | Projects"
        description="Discover next real project to improve your coding skills"
        url={`${process.env.REACT_APP_SITE_URL}projects`}
      />
      <Stack
        direction={['column', 'column', 'row', 'row']}
        alignItems="center"
        justifyContent="space-between"
        background="gray.900"
        py={3}
      >
        <Text fontSize="xxLarge" fontWeight="semibold">
          Projects
        </Text>
        <Tabs
          tabs={['All', 'Not completed', 'Completed']}
          tabIndex={tabIndex}
          onChange={(index) => setTabIndex(index)}
        />
      </Stack>
      {filteredProjects.length === 0 ? (
        renderEmptyProjects()
      ) : (
        <SimpleGrid columns={[1, 1, 2, 2, 3]} gap={8} mt={4}>
          {filteredProjects.map((project) => {
            return <ProjectCard project={project} showLessTags key={project.id} />;
          })}
        </SimpleGrid>
      )}
    </Container>
  );
};

export default Projects;
