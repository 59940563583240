import {
  Menu,
  MenuList,
  MenuButton,
  MenuItem,
  Stack,
  IconButton,
  useTheme,
  Divider,
  VStack,
} from '@chakra-ui/react';
import { ReactNode } from 'react';

import Icon from './Icon';
import Text from './Text';

export type TDotItem = {
  id: string;
  title: ReactNode;
  withDivider?: boolean | null;
  onClick: () => void;
  isDanger?: boolean;
  isDisabled?: boolean;
};

interface IDotsMenuProps {
  items: TDotItem[];
  size?: 'md' | 'lg';
}

const DotsMenu = ({ items, size = 'md' }: IDotsMenuProps) => {
  const { borderRadius, opacity } = useTheme();

  return (
    <Menu placement="bottom-end">
      <MenuButton
        as={IconButton}
        size={size}
        aria-label="Project actions"
        icon={<Icon type="DotsMenu" />}
        variant="solid"
        backgroundColor="whiteAlpha.200"
        borderRadius={borderRadius}
        onClick={(e) => e.stopPropagation()}
      />
      <MenuList bg="gray.800" px={3} width="100%" zIndex={9999999}>
        <Stack spacing={2} width="100%">
          {items.map(({ id, title, onClick, isDanger, isDisabled, withDivider }) => {
            const hoverProps = isDisabled
              ? {}
              : { backgroundColor: 'whiteAlpha.300', opacity: opacity.primary };
            return (
              <VStack key={id} alignItems="flex-start" width="100%">
                <MenuItem
                  width="100%"
                  bg="gray.800"
                  transition="all 0.2s ease-out"
                  isDisabled={isDisabled}
                  opacity={opacity.secondary}
                  _hover={hoverProps}
                  onClick={(e) => {
                    e.stopPropagation();

                    onClick();
                  }}
                >
                  <Text color={isDanger ? 'red.300' : 'white'} opacity="default">
                    {title}
                  </Text>
                </MenuItem>
                {withDivider && <Divider width="100%" />}
              </VStack>
            );
          })}
        </Stack>
      </MenuList>
    </Menu>
  );
};

export default DotsMenu;
