import { Text as ChakraText, TextProps as ChakraTextProps, useTheme } from '@chakra-ui/react';

export type Opacity = 'primary' | 'secondary' | 'tertiary' | 'default';
export type Color =
  | 'white'
  | 'black'
  | 'orange.200'
  | 'blue.200'
  | 'yellow.200'
  | 'green.200'
  | 'pink.200'
  | 'red.200'
  | 'purple.200'
  | 'purple.400'
  | 'gray.900';
type FontWeight = 'regular' | 'medium' | 'semibold' | 'bold';
type FontSize =
  | 'xMicro'
  | 'micro'
  | 'small'
  | 'medium'
  | 'large'
  | 'xLarge'
  | 'xxLarge'
  | 'xxxLarge'
  | 'xxxxLarge'
  | 'xxxxxLarge'
  | 'xxxxxxLarge';

export interface TextProps extends ChakraTextProps {
  opacity?: Opacity;
  color?: Color | string;
  fontSize?: FontSize;
  fontWeight?: FontWeight;
}

const Text = ({
  opacity = 'primary',
  color = 'white',
  fontSize = 'medium',
  fontWeight = 'medium',
  ...props
}: TextProps) => {
  const theme = useTheme();

  const mapOpacity: Record<Opacity, number> = {
    primary: theme.opacity.primary,
    secondary: theme.opacity.secondary,
    tertiary: theme.opacity.tertiary,
    default: 1,
  };

  const mapFontSize: Record<FontSize, string> = {
    xMicro: theme.fontSizes.xMicro,
    micro: theme.fontSizes.micro,
    small: theme.fontSizes.small,
    medium: theme.fontSizes.medium,
    large: theme.fontSizes.large,
    xLarge: theme.fontSizes.xLarge,
    xxLarge: theme.fontSizes.xxLarge,
    xxxLarge: theme.fontSizes.xxxLarge,
    xxxxLarge: theme.fontSizes.xxxxLarge,
    xxxxxLarge: theme.fontSizes.xxxxxLarge,
    xxxxxxLarge: theme.fontSizes.xxxxxxLarge,
  };

  const mapFontWeight: Record<FontWeight, string> = {
    regular: theme.fontWeights.normal,
    medium: theme.fontWeights.medium,
    semibold: theme.fontWeights.semibold,
    bold: theme.fontWeights.bold,
  };

  return (
    <ChakraText
      opacity={mapOpacity[opacity]}
      color={color}
      fontSize={mapFontSize[fontSize]}
      fontWeight={mapFontWeight[fontWeight]}
      {...props}
    />
  );
};

export default Text;
