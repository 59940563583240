import { Divider, HStack, IconButton, VStack, useDisclosure } from '@chakra-ui/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { supabaseMutationFn, useSupabaseTypedClient } from 'hooks/reactQuery';
import { extractCodeFromStorage } from 'hooks/useCodeStorage';
import { useExitAlert } from 'hooks/useExitAlert';
import { useToastWrapper } from 'hooks/useToastWrapper';
import html2canvas from 'html2canvas';
import { useEffect, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useCurrentUser } from 'auth/CurrentUserProvider';
import { BigBadge } from 'components/BigBadge';
import CommentButton from 'components/CommentButton';
import DevSandbox from 'components/DevSandbox';
import BrowserTab from 'components/DevSandbox/PaneTabs/BrowserTab';
import ChatGPT from 'components/DevSandbox/PaneTabs/ChatGPT';
import DiscussionTab from 'components/DevSandbox/PaneTabs/Discussion/DiscussionTab';
import SummaryTab from 'components/DevSandbox/PaneTabs/Summary/SummaryTab';
import RightSideActions from 'components/DevSandbox/RightSideActions';
import { CODE_FREE_LIMIT, CODE_PAID_LIMIT } from 'components/DevSandbox/constants';
import { colorsPalette, fonts, icons, images } from 'components/DevSandbox/quickButtons';
import { TDevSandboxFooterQuickButton } from 'components/DevSandbox/types';
import { isCodeSame } from 'components/DevSandbox/utils';
import GradientPopover from 'components/GradientPopover';
import GradientTooltip from 'components/GradientTooltip';
import Icon from 'components/Icon';
import LikeButton from 'components/LikeButton';
import Link from 'components/Link';
import { LinkInput } from 'components/LinkInput';
import { ProtectedElement } from 'components/ProtectedElement';
import RenameCodeInput from 'components/RenameCodeInput';
import ShareButtons from 'components/ShareButtons';
import Text from 'components/Text';
import ToggleCodePrivacyLock from 'components/ToggleCodePrivacyLock';
import UserAvatar from 'components/UserAvatar';
import { useMediaQueries } from 'layout/MediaQueriesProvider';
import { Support_Type_Enum } from 'types/databaseEnums';
import {
  EdgeFunctionName,
  AskChatGptParams,
  Prototype,
  SubmitCodeResponse,
  SubmitCodeParams,
} from 'types/edgeFunctions';
import { ERoute, TanstackQueryName } from 'types/frontend';

import SolutionsTab from './SolutionsTab';

interface IPrototypePlaygroundProps {
  prototype: Prototype;
}

const PrototypePlayground = ({
  prototype: {
    id,
    title,
    user,
    html,
    css,
    js,
    is_liked,
    is_private,
    likes,
    comments_count,
    solution_url,
    solutions_count,
    is_my_solution,
    ai_feedback,
    code_score,
    code_report,
    design_score,
    design_report,
    screenshot_url,
  },
}: IPrototypePlaygroundProps) => {
  const [localTitle, setLocalTitle] = useState<string>(title);
  const [codeFrameUrl, setCodeFrameUrl] = useState<string>('');
  const [colorPaletteQuickButton, setColorPaletteQuickButton] =
    useState<TDevSandboxFooterQuickButton | null>(null);

  const { id: currentUserId, screenName: currentUserScreenName, isFree } = useCurrentUser();
  const [searchParams] = useSearchParams();

  const { isMobile, isSmallScreen } = useMediaQueries();

  const navigate = useNavigate();

  const onColorPaletteRefresh = async () => {
    const colorPalette = await colorsPalette({
      screenshotUrl: null,
      toast: toastSuccess,
      onRefresh: onColorPaletteRefresh,
    });

    setColorPaletteQuickButton(colorPalette);
  };

  useEffect(() => {
    onColorPaletteRefresh();
  }, []);

  const mutation = useMutation<SubmitCodeResponse, string, SubmitCodeParams>({
    mutationFn: supabaseMutationFn((params) =>
      supabase.functions.invoke<SubmitCodeResponse>(EdgeFunctionName.SubmitCode, {
        body: params as SubmitCodeParams,
      })
    ),
    onError: () => {
      toastError('Could not save code, try again later.');
    },
  });

  const handleSave = async (skipScreenshot: boolean) => {
    const CODE_LIMIT = isFree ? CODE_FREE_LIMIT : CODE_PAID_LIMIT;

    const storedHtml = extractCodeFromStorage({
      type: 'prototype',
      screenName: id,
      userId: currentUserId,
      code: 'html',
    });

    const storedCss = extractCodeFromStorage({
      type: 'prototype',
      screenName: id,
      userId: currentUserId,
      code: 'css',
    });

    const storedJs = extractCodeFromStorage({
      type: 'prototype',
      screenName: id,
      userId: currentUserId,
      code: 'js',
    });

    if (storedHtml.length > CODE_LIMIT) {
      return toastError(`Exceeded maximum ${CODE_LIMIT} characters of HTML`);
    }

    if (storedCss.length > CODE_LIMIT) {
      return toastError(`Exceeded maximum ${CODE_LIMIT} characters of CSS`);
    }

    if (storedJs.length > CODE_LIMIT) {
      return toastError(`Exceeded maximum ${CODE_LIMIT} characters of JS`);
    }

    let iframeScreenshot = '';

    if (!skipScreenshot) {
      iframeScreenshot = await createIframeScreenshot();
    }

    mutation.mutate(
      {
        type: 'prototype',
        entityId: id,
        entityScreenName: '',
        title,
        is_private,
        html: storedHtml,
        css: storedCss,
        js: storedJs,
        iframe_screenshot: iframeScreenshot,
      },
      {
        onSuccess: (data) => {
          queryClient.refetchQueries({
            queryKey: [TanstackQueryName.GetPrototype, solution_url],
          });
          queryClient.refetchQueries({
            queryKey: [TanstackQueryName.GetPrototype, data.solution_url],
          });
          queryClient.refetchQueries({
            queryKey: [TanstackQueryName.GetPrototypes, currentUserId],
          });
          queryClient.refetchQueries({ queryKey: [TanstackQueryName.GetPrototypes, null] });

          if (!skipScreenshot) {
            toastSuccess('Code saved');
          }
        },
      }
    );
  };

  useEffect(() => {
    return () => {
      const storedHtml = extractCodeFromStorage({
        type: 'prototype',
        screenName: id,
        userId: currentUserId,
        code: 'html',
      });

      const storedCss = extractCodeFromStorage({
        type: 'prototype',
        screenName: id,
        userId: currentUserId,
        code: 'css',
      });

      const storedJs = extractCodeFromStorage({
        type: 'prototype',
        screenName: id,
        userId: currentUserId,
        code: 'js',
      });

      const isCodeChanged = isCodeSame({ html, css, js, storedHtml, storedCss, storedJs });

      if (!isCodeChanged) {
        return;
      }

      handleSave(true);
    };
  }, []);

  const generateSummaryMutation = useMutation<string, string, AskChatGptParams>({
    mutationFn: supabaseMutationFn(({ entityId, type }) =>
      supabase.functions.invoke<string>(EdgeFunctionName.AskChatGpt, {
        body: {
          entityId,
          type,
        } as AskChatGptParams,
      })
    ),
    onSuccess: async () => {
      queryClient.invalidateQueries({
        queryKey: [TanstackQueryName.GetCurrentUser, currentUserId],
      });
      await queryClient.invalidateQueries({
        queryKey: [TanstackQueryName.GetPrototype, solution_url],
      }),
        toastSuccess('Summary generated successfully.');
      regenerateSummaryModal.onClose();
    },
    onError: () => {
      toastError('Could not generate summary, try again later.');
    },
  });

  const storedHtml = extractCodeFromStorage({
    type: 'prototype',
    screenName: id,
    userId: currentUserId,
    code: 'html',
  });

  const storedCss = extractCodeFromStorage({
    type: 'prototype',
    screenName: id,
    userId: currentUserId,
    code: 'css',
  });

  const storedJs = extractCodeFromStorage({
    type: 'prototype',
    screenName: id,
    userId: currentUserId,
    code: 'js',
  });

  const isCodeChanged = isCodeSame({ html, css, js, storedHtml, storedCss, storedJs });
  const isCodeEmpty = storedHtml === '' && storedCss === '' && storedJs === '';

  useExitAlert({
    shouldShowBrowserExitAlert: isCodeChanged || generateSummaryMutation.isPending,
  });

  const createIframeScreenshot = async (): Promise<string> => {
    const iframe = document.getElementById(`bds-code-frame-${codeFrameUrl}`);
    // @ts-ignore
    const iframeDocument = iframe.contentDocument || iframe.contentWindow.document;

    try {
      const canvas = await html2canvas(iframeDocument.body, {
        useCORS: true,
        allowTaint: true,
        width: 1440,
        height: 1080,
      });

      return canvas.toDataURL('image/png');
    } catch (err) {
      toastError(`Error capturing screenshot ${err}`);
    }

    return '';
  };

  const codeEditorRef = useRef<any>(null);

  const regenerateSummaryModal = useDisclosure();

  const supabase = useSupabaseTypedClient();
  const queryClient = useQueryClient();

  const { toastSuccess, toastError } = useToastWrapper();

  const hasTabInUrl =
    location.pathname.includes('browser') ||
    location.pathname.includes('summary') ||
    location.pathname.includes('ai-buddy') ||
    location.pathname.includes('comments') ||
    location.pathname.includes('explore');
  const tabScreenName = location.pathname.split('/').pop();
  const browserUrl = hasTabInUrl
    ? window.location.href.replace(`/${tabScreenName}`, '')
    : window.location.href;

  const APP_URL = process.env.REACT_APP_SITE_URL || '';

  const link = `${APP_URL.substring(0, APP_URL.length - 1)}${solution_url}`;

  const searchParamsMode = searchParams.get('mode');
  const mode = searchParamsMode === 'full-page' ? 'full-page' : 'interactive';

  const accountDeleted = user.id === '' || user.id === null;

  return (
    <>
      <DevSandbox
        ref={codeEditorRef}
        id={`prototype-${id}-${currentUserId}`}
        browserUrl={browserUrl}
        title={title}
        mode={mode}
        navigation={{
          leftSide: (
            <HStack pl={1}>
              <VStack spacing={0} alignItems="flex-start">
                <RenameCodeInput
                  title={title}
                  isMySolution={is_my_solution}
                  onTitleChange={(title) => setLocalTitle(title)}
                  type="prototype"
                  shortTitle
                  renameCodeParams={{
                    entityId: id,
                    userScreenName: currentUserScreenName,
                    repositoryUrl: '',
                    previewUrl: '',
                    queryKey: [TanstackQueryName.GetPrototype, solution_url],
                    invalidateKeys: [
                      [TanstackQueryName.GetPrototypes, null],
                      [TanstackQueryName.GetPrototypes, currentUserId],
                      [TanstackQueryName.GetProfileCode, user.id, 'prototype'],
                      [TanstackQueryName.GetUserCode, currentUserId, 0],
                      [TanstackQueryName.GetUserCode, currentUserId, 1],
                      [TanstackQueryName.GetUserCode, currentUserId, 2],
                      [TanstackQueryName.GetPrototype, solution_url],
                    ],
                  }}
                />
                <HStack
                  spacing={4}
                  width="fit-content"
                  cursor={accountDeleted ? 'default' : 'pointer'}
                  alignItems="flex-start"
                >
                  <HStack>
                    {isSmallScreen ? (
                      <UserAvatar
                        key={user.id}
                        name={user.name}
                        src={user.avatar_url}
                        onClick={(e) => {
                          if (accountDeleted) {
                            return;
                          }

                          navigate(`${ERoute.Profile}/${user.screen_name}`);
                        }}
                        experience={user.xp}
                        size="xs"
                        hideCircularProgress
                      />
                    ) : (
                      <>
                        {!accountDeleted ? (
                          <Link to={`${ERoute.Profile}/${user.screen_name}`} color="white">
                            <Text fontSize="micro" maxW="200px" isTruncated opacity="secondary">
                              {user.name}
                            </Text>
                          </Link>
                        ) : (
                          <Text fontSize="micro" maxW="200px" isTruncated opacity="secondary">
                            {user.name}
                          </Text>
                        )}
                      </>
                    )}
                    <BigBadge plan={user.plan} size="small" />
                  </HStack>
                </HStack>
              </VStack>
              <Divider height="24px" orientation="vertical" />
              <HStack spacing={4}>
                <LikeButton
                  userId={user.id}
                  likes={likes}
                  likeCodeParams={{
                    entityId: id,
                    isLiked: is_liked,
                    queryKey: [TanstackQueryName.GetPrototype, solution_url],
                    invalidateKeys: [
                      [TanstackQueryName.GetPrototypes, null],
                      [TanstackQueryName.GetPrototypes, currentUserId],
                      [TanstackQueryName.GetProfileCode, user.id, 'prototype'],
                      [TanstackQueryName.GetUserCode, currentUserId, 0],
                      [TanstackQueryName.GetUserCode, currentUserId, 1],
                      [TanstackQueryName.GetUserCode, currentUserId, 2],
                    ],
                  }}
                />
                {!isSmallScreen && (
                  <CommentButton comments={comments_count} url={`${solution_url}/comments`} />
                )}
              </HStack>
            </HStack>
          ),
          header: (
            <HStack>
              {is_my_solution ? (
                <ProtectedElement
                  scheme="outline"
                  onClick={async () => await handleSave(false)}
                  isLoading={mutation.isPending}
                  leftIcon={<Icon type="CodeSave" />}
                >
                  Save
                </ProtectedElement>
              ) : (
                <ProtectedElement asButton={false}>
                  <GradientTooltip label="Fork code">
                    <IconButton
                      aria-label="fork solution"
                      onClick={() => {
                        navigate(`${ERoute.CodeFrames}${ERoute.NewCodeFrame}`, {
                          state: {
                            html,
                            css,
                            js,
                            screenshotUrl: screenshot_url,
                            shouldCreate: true,
                            isFork: true,
                          },
                        });
                      }}
                    >
                      <Icon type="ForkCode" />
                    </IconButton>
                  </GradientTooltip>
                </ProtectedElement>
              )}
            </HStack>
          ),
          rightSide: (
            <RightSideActions
              leftActions={
                <HStack spacing={0}>
                  {is_my_solution && !isSmallScreen && (
                    <ToggleCodePrivacyLock
                      type="prototype"
                      togglePrivacyCodeParams={{
                        isPrivate: is_private,
                        entityId: id,
                        queryKey: [TanstackQueryName.GetPrototype, solution_url],
                        invalidateKeys: [
                          [TanstackQueryName.GetPrototypes, null],
                          [TanstackQueryName.GetPrototypes, currentUserId],
                          [TanstackQueryName.GetProfileCode, user.id, 'prototype'],
                          [TanstackQueryName.GetUserCode, currentUserId, 0],
                          [TanstackQueryName.GetUserCode, currentUserId, 1],
                          [TanstackQueryName.GetUserCode, currentUserId, 2],
                        ],
                      }}
                    />
                  )}
                  {!isSmallScreen && (
                    <GradientPopover
                      tooltip="Share code"
                      triggerComponent={
                        <IconButton
                          aria-label="share solution popover with share buttons and link"
                          variant="ghost"
                        >
                          <Icon type="Share" />
                        </IconButton>
                      }
                      width={isMobile ? '95vw' : '500px'}
                      header="Spread the word about this solution"
                    >
                      <VStack alignItems="flex-start" spacing={4} width="100%">
                        <ShareButtons
                          text={`Check out ${
                            is_my_solution ? 'my' : 'this'
                          } awesome ${title} code on BigDevSoon. 🚀`}
                          link={link}
                          emailSubject={`Hey, wanted to share ${
                            is_my_solution ? 'my' : 'this'
                          } awesome ${title} code on BigDevSoon with you`}
                        />
                        <LinkInput link={link} />
                      </VStack>
                    </GradientPopover>
                  )}
                </HStack>
              }
            />
          ),
        }}
        codeTabs={{
          html,
          css,
          js,
        }}
        callbacks={{
          onCodeFrameUrlChange: (codeFrameUrl) => {
            setCodeFrameUrl(codeFrameUrl);
          },
        }}
        flags={{
          loadCodeFromServer: is_my_solution ? false : true,
        }}
        paneTabs={{
          tabs: [
            {
              screenName: 'browser',
              title: 'Browser',
              icon: <Icon type="Browser" size={16} />,
              content: (
                <BrowserTab
                  browserUrl={browserUrl}
                  codeFrameUrl={codeFrameUrl}
                  onCodeFrameUrlChange={codeEditorRef.current?.onCodeFrameUrlChange}
                />
              ),
            },
            {
              title: 'Summary',
              screenName: 'summary',
              icon: <Icon type="Summary" size={16} />,
              content: (
                <SummaryTab
                  feedback={ai_feedback}
                  isLoading={generateSummaryMutation.isPending}
                  isDisabled={isCodeChanged || isCodeEmpty || !screenshot_url}
                  codeScore={code_score}
                  codeSummary={code_report}
                  designScore={design_score}
                  designSummary={design_report}
                  isMyReport={is_my_solution}
                  onGenerate={() => {
                    generateSummaryMutation.mutate({
                      entityId: id,
                      type: 'generate-prototype-summary',
                    });
                  }}
                  {...regenerateSummaryModal}
                />
              ),
            },
            ...(is_my_solution
              ? [
                  {
                    screenName: 'ai-buddy',
                    title: 'AI Buddy',
                    icon: <Icon type="CodeChatGpt" size={16} />,
                    content: (
                      <ChatGPT
                        chatContext={`You are an AI Buddy, designed to assist and collaborate with web developers of all skill levels as they experiment and create projects in a coding environment similar to CodePen. The user is currently exploring various web development techniques and may work on a range of tasks, from basic HTML structures to complex JavaScript interactions. Your role is to provide:

  - Contextual guidance and suggestions based on the user's current focus area or code snippets they share.
  - Code hints and partial solutions in HTML, CSS (including frameworks like TailwindCSS), and JavaScript. Avoid providing complete solutions to foster learning and experimentation.
  - Tips on best practices for clean code, responsive design, and efficient scripting.
  - Debugging support by analyzing and offering solutions to specific issues in the user's code.
  - Encouragement for creative thinking and exploration, helping the user expand their understanding and skills in web development.
  - Answers to queries about web technologies and coding techniques, with a focus on enabling the user to apply this knowledge practically.
  - Ensure interactions are tailored to the user's level of experience, whether they're a beginner needing foundational advice or an advanced developer looking for optimization tips.

  Please respond with actionable advice, relevant code snippets, and helpful insights, formatted with markdown for clarity. Engage the user in a collaborative and educational dialogue to enhance their coding journey.
  `}
                      />
                    ),
                  },
                ]
              : []),
            {
              title: `${comments_count > 0 ? `Comments (${comments_count})` : 'Comments'}`,
              screenName: 'comments',
              icon: <Icon type="CommunityQuestions" />,
              content: (
                <DiscussionTab
                  entityId={id}
                  type={Support_Type_Enum.Feedback}
                  isMySolution={is_my_solution}
                  refetch={() => {
                    queryClient.invalidateQueries({
                      queryKey: [TanstackQueryName.GetPrototypes, currentUserId],
                    });
                    queryClient.invalidateQueries({
                      queryKey: [TanstackQueryName.GetPrototypes, null],
                    });
                    queryClient.invalidateQueries({
                      queryKey: [TanstackQueryName.GetPrototype, solution_url],
                    });
                  }}
                />
              ),
            },
            {
              screenName: 'explore',
              title: `${solutions_count > 0 ? `Explore (${solutions_count})` : 'Explore'}`,
              icon: <Icon type="SolutionsTab" size={16} />,
              content: <SolutionsTab />,
            },
          ],
        }}
        footer={{
          rightSide: [
            images(),
            icons(),
            fonts(),
            ...(colorPaletteQuickButton ? [colorPaletteQuickButton] : []),
          ],
        }}
      />
    </>
  );
};

export default PrototypePlayground;
