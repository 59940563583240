import { HStack } from '@chakra-ui/react';

import Icon from 'components/Icon';

export const PayPalShortInfo = () => {
  return (
    <>
      <HStack
        borderRadius={8}
        bg="whiteAlpha.200"
        px={4}
        minH={16}
        shadow="0px 4px 24px 0px rgba(0, 0, 0, 0.24)"
      >
        <Icon type="PayPalGray" />
      </HStack>
    </>
  );
};
