import { Box } from '@chakra-ui/react';
import { NavLink } from 'react-router-dom';

import { ERoute } from 'types/frontend';

import Icon from './Icon';

interface LogoProps {
  isClickable?: boolean;
  simple?: boolean;
  rocketOnly?: boolean;
  size?: number;
}

const Logo = ({
  isClickable = false,
  simple = false,
  rocketOnly = false,
  size = 32,
}: LogoProps) => {
  const logo = isClickable ? (
    <NavLink to={simple ? ERoute.Projects : ERoute.Dashboard}>
      {rocketOnly ? <Icon type="BdsRocketOnly" size={size} /> : <Icon type="Logo" />}
    </NavLink>
  ) : (
    <>{rocketOnly ? <Icon type="BdsRocketOnly" size={size} /> : <Icon type="Logo" />}</>
  );

  return <Box mr={rocketOnly ? 2 : 4}>{logo}</Box>;
};

export default Logo;
