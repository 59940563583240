import { Divider } from '@chakra-ui/react';

import SettingsTile from 'components/Billing/SettingsTile';
import Button from 'components/Button';
import Text from 'components/Text';

const BDS_NOTICE_AT_COLLECTION_LINK = 'bds-notice-at-collection-link';
const BDS_PRIVACY_CHOICES_LINK = 'bds-edit-privacy-choices-link';

const openPrivacyLink = (id: string) => {
  document.getElementById(id)?.click();
};

export const PrivacyControlsSettings = () => {
  return (
    <>
      <SettingsTile title="Privacy controls" subtitle="Configure your privacy choices.">
        <Text fontWeight="regular">
          Learn more about{' '}
          <Text
            color="purple.200"
            display="inline-block"
            cursor="pointer"
            _hover={{
              textDecoration: 'underline',
            }}
            onClick={() => openPrivacyLink(BDS_NOTICE_AT_COLLECTION_LINK)}
          >
            Notice at Collection
          </Text>
          .
        </Text>
        <Divider mt={6} mb={5} />
        <Button
          scheme="outline"
          border="1px solid"
          borderColor="whiteAlpha.800"
          onClick={() => openPrivacyLink(BDS_PRIVACY_CHOICES_LINK)}
        >
          Edit Privacy Choices
        </Button>
      </SettingsTile>
    </>
  );
};
