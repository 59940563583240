import { VStack, Box, useTheme } from '@chakra-ui/react';

import Text from 'components/Text';

export interface IBillingCardProps {
  title: string;
  subtitle?: React.ReactNode | string;
  children: React.ReactNode;
}

export const SettingsTile = ({ title, subtitle, children }: IBillingCardProps) => {
  const theme = useTheme();
  return (
    <VStack
      bg="whiteAlpha.200"
      borderRadius={theme.borderRadius}
      p={4}
      align="flex-start"
      shadow="0px 4px 24px 0px rgba(0, 0, 0, 0.24)"
    >
      <Text fontSize="xLarge">{title}</Text>

      {typeof subtitle === 'string' ? (
        <Text opacity="secondary" fontWeight="regular">
          {subtitle}
        </Text>
      ) : subtitle ? (
        subtitle
      ) : null}

      <Box pt={3} w="100%">
        {children}
      </Box>
    </VStack>
  );
};

export default SettingsTile;
