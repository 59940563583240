import {
  Box,
  HStack,
  useTheme,
  Divider,
  VStack,
  Center,
  Skeleton,
  IconButton,
  useDisclosure,
  Image,
} from '@chakra-ui/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { format } from 'date-fns';
import { supabaseMutationFn, useSupabaseTypedClient } from 'hooks/reactQuery';
import { removeCodeFromStorage } from 'hooks/useCodeStorage';
import { useToastWrapper } from 'hooks/useToastWrapper';
import { useTogglePrivacyCode } from 'hooks/useTogglePrivacyCode';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { dracula } from 'react-syntax-highlighter/dist/esm/styles/prism';

import DotsMenu, { TDotItem } from 'components/DotsMenu';
import Text from 'components/Text';
import { DATE_FORMAT } from 'config/constants/plans';
import { useMediaQueries } from 'layout/MediaQueriesProvider';
import { Plan_Type_Enum } from 'types/databaseEnums';
import {
  CodeEditorType,
  CodePreview,
  EdgeFunctionName,
  TogglePrivacyCodeParams,
} from 'types/edgeFunctions';
import { ERoute, TanstackQueryName } from 'types/frontend';
import { determineSolutionFlags } from 'utils/solutions';

import { BigBadge } from './BigBadge';
import CommentButton from './CommentButton';
import CodeFrame from './DevSandbox/CodeFrame';
import DeleteCodeModal from './DevSandbox/DeleteCodeModal';
import { getGeneratedPageURL } from './DevSandbox/DevSandboxProvider';
import GradientTooltip from './GradientTooltip';
import Icon from './Icon';
import LikeButton from './LikeButton';
import Link from './Link';
import RenameCodeInput from './RenameCodeInput';
import ScoreBadge from './ScoreBadge';
import UserAvatar from './UserAvatar';

export type CodePreviewCardProps = {
  containerHeight?: string | string[];
  width?: string | string[];
  height?: string;
  isMySolution: boolean;
  canEdit: boolean;
  queryKey: unknown[];
  invalidateKeys: unknown[][];
} & CodePreview;

const CodePreviewCard = ({
  id,
  parent_id,
  parent_screen_name,
  screenshot_url,
  solution_url,
  repository_url,
  preview_url,
  user_avatar_url,
  user_experience,
  user_id,
  user_name,
  user_plan,
  user_screen_name,
  title,
  likes,
  comments,
  html,
  css,
  js,
  is_liked,
  code_score,
  design_score,
  completed_date,
  type,
  is_private,
  isMySolution,
  canEdit,
  queryKey,
  invalidateKeys,
  total_score,
  containerHeight = '100%',
  width = '100%',
  height = '450px',
}: CodePreviewCardProps) => {
  const [isRenaming, setIsRenaming] = useState(false);

  const deleteCodeModal = useDisclosure();

  const { borderRadius } = useTheme();
  const { isMobile } = useMediaQueries();

  const { toastSuccess } = useToastWrapper();

  const hasBadge = user_plan !== Plan_Type_Enum.Free;

  const navigate = useNavigate();

  const supabase = useSupabaseTypedClient();
  const queryClient = useQueryClient();

  const handleInvalidateCode = () => {
    queryClient.invalidateQueries({
      queryKey: [TanstackQueryName.GetCurrentUser, user_id],
    });
    queryClient.invalidateQueries({
      queryKey: [TanstackQueryName.GetUserProfile, user_id],
    });

    if (type === 'project') {
      queryClient.invalidateQueries({
        queryKey: [TanstackQueryName.GetProjects, user_id],
      });
      queryClient.invalidateQueries({
        queryKey: [TanstackQueryName.GetProject, parent_screen_name],
      });
      queryClient.invalidateQueries({
        queryKey: [TanstackQueryName.GetProjectSolutions, id],
      });
      queryClient.invalidateQueries({
        queryKey: [TanstackQueryName.GetUserCode, user_id, 0],
      });
      queryClient.invalidateQueries({
        queryKey: [TanstackQueryName.GetUserCode, user_id, 1],
      });
      queryClient.invalidateQueries({
        queryKey: [TanstackQueryName.GetUserCode, user_id, 2],
      });
      queryClient.invalidateQueries({
        queryKey: [TanstackQueryName.GetProfileCode, user_id, 'project'],
      });
    } else if (type === 'challenge') {
      queryClient.invalidateQueries({
        queryKey: [TanstackQueryName.GetChallenges, user_id, 0],
      });
      queryClient.invalidateQueries({
        queryKey: [TanstackQueryName.GetChallenges, user_id, 1],
      });
      queryClient.invalidateQueries({
        queryKey: [TanstackQueryName.GetChallenges, user_id, 2],
      });
      queryClient.invalidateQueries({
        queryKey: [TanstackQueryName.GetChallenge, parent_screen_name],
      });
      queryClient.invalidateQueries({
        queryKey: [TanstackQueryName.GetChallengeSolutions, id],
      });
      queryClient.invalidateQueries({
        queryKey: [TanstackQueryName.GetUserCode, user_id],
      });
      queryClient.invalidateQueries({
        queryKey: [TanstackQueryName.GetProfileCode, user_id, type],
      });
    } else if (type === 'problem') {
      queryClient.invalidateQueries({
        queryKey: [TanstackQueryName.GetPracticeProblems, user_id, 0],
      });
      queryClient.invalidateQueries({
        queryKey: [TanstackQueryName.GetPracticeProblems, user_id, 1],
      });
      queryClient.invalidateQueries({
        queryKey: [TanstackQueryName.GetPracticeProblems, user_id, 2],
      });
      queryClient.invalidateQueries({
        queryKey: [TanstackQueryName.GetPracticeProblem, parent_screen_name],
      });
      queryClient.invalidateQueries({
        queryKey: [TanstackQueryName.GetPracticeProblemsSolutions, id],
      });
      queryClient.invalidateQueries({
        queryKey: [TanstackQueryName.GetUserCode, user_id],
      });
      queryClient.invalidateQueries({
        queryKey: [TanstackQueryName.GetProfileCode, user_id, 'problem'],
      });
    } else if (type === 'code-frame') {
      removeCodeFromStorage({ type, screenName: id!, userId: user_id!, code: 'html' });
      removeCodeFromStorage({ type, screenName: id!, userId: user_id!, code: 'css' });
      removeCodeFromStorage({ type, screenName: id!, userId: user_id!, code: 'js' });

      queryClient.invalidateQueries({
        queryKey: [TanstackQueryName.GetCodeFrames, user_id],
      });
      queryClient.invalidateQueries({
        queryKey: [TanstackQueryName.GetCodeFrames, null],
      });
      queryClient.invalidateQueries({
        queryKey: [TanstackQueryName.GetUserCode, user_id],
      });
      queryClient.invalidateQueries({
        queryKey: [TanstackQueryName.GetProfileCode, user_id, 'code-frame'],
      });
    } else if (type === 'prototype') {
      removeCodeFromStorage({ type, screenName: id!, userId: user_id!, code: 'html' });
      removeCodeFromStorage({ type, screenName: id!, userId: user_id!, code: 'css' });
      removeCodeFromStorage({ type, screenName: id!, userId: user_id!, code: 'js' });

      queryClient.invalidateQueries({
        queryKey: [TanstackQueryName.GetPrototypes, user_id],
      });
      queryClient.invalidateQueries({
        queryKey: [TanstackQueryName.GetPrototypes, null],
      });
      queryClient.invalidateQueries({
        queryKey: [TanstackQueryName.GetUserCode, user_id],
      });
      queryClient.invalidateQueries({
        queryKey: [TanstackQueryName.GetProfileCode, user_id, 'prototype'],
      });
    }
  };

  const { onMutate, onError, onSettled } = useTogglePrivacyCode({
    entityId: id!,
    isPrivate: Boolean(is_private),
    invalidateKeys,
    queryKey,
  });

  const mutation = useMutation<boolean, string, TogglePrivacyCodeParams>({
    mutationFn: supabaseMutationFn(({ entityId, type, isPrivate }) =>
      supabase.functions.invoke<boolean>(EdgeFunctionName.TogglePrivacyCode, {
        body: { entityId, type, isPrivate } as TogglePrivacyCodeParams,
      })
    ),
    onMutate: async () => await onMutate?.(),
    onError: (err, variables, context) => onError?.(err, variables, context),
    onSettled: () => {
      onSettled?.();
      toastSuccess(`Code ${is_private ? 'made private' : 'made public'}`);
    },
  });

  const { isExternalSolution } = determineSolutionFlags({
    solution_url: solution_url || '',
    repository_url: repository_url || '',
    preview_url: preview_url || '',
    type: type as CodeEditorType,
  });

  const renderImageContent = () => {
    if (type === 'problem') {
      return (
        <Box
          backgroundColor="#282A36"
          height={height}
          maxH={height}
          width="100%"
          overflow="hidden"
          borderRadius={borderRadius}
        >
          <SyntaxHighlighter language="javascript" style={dracula}>
            {js || ''}
          </SyntaxHighlighter>
        </Box>
      );
    }

    if (isExternalSolution) {
      return (
        <Link to={solution_url || ''} width="100%">
          <Image
            src={screenshot_url || ''}
            width="100%"
            htmlWidth={1440}
            htmlHeight={1080}
            height={height || 'auto'}
            maxHeight={height}
            fallbackStrategy={'onError'}
            borderRadius={borderRadius}
            fallback={
              <Center
                width="100%"
                height="auto"
                maxHeight={height}
                minHeight={300}
                background="white"
                borderRadius={borderRadius}
              >
                <Text color="black">Image not found</Text>
              </Center>
            }
          />
        </Link>
      );
    }

    return (
      <div style={{ position: 'relative', width: '100%' }}>
        <MemoCodeFrame
          html={html || ''}
          css={css || ''}
          js={js || ''}
          borderRadius={borderRadius}
          height={height}
        />
        <Link
          to={solution_url || ''}
          onClick={(e) => e.stopPropagation()}
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height,
            zIndex: 1,
            backgroundColor: 'transparent',
          }}
        />
      </div>
    );
  };

  const toUserProfile = `${ERoute.Profile}/${user_screen_name}`;

  const navigateToUserProfile = (e) => {
    e.stopPropagation();

    navigate(toUserProfile);
  };

  const boxProps = {
    _hover: { backgroundColor: 'whiteAlpha.100' },
    cursor: 'pointer',
    onClick: () => navigate(solution_url || ''),
  };

  const accountDeleted = user_id === '' || user_id === null;

  return (
    <>
      <Box
        key={id}
        borderRadius={borderRadius}
        backgroundColor="whiteAlpha.200"
        p={4}
        transition="all 0.3s ease-out"
        position="relative"
        role="group"
        height={containerHeight}
        width={width}
        {...boxProps}
      >
        <VStack alignItems="flex-start" spacing={5} width="100%">
          <HStack justifyContent="space-between" width="100%" overflowX="auto">
            <HStack
              spacing={4}
              width="fit-content"
              cursor={accountDeleted ? 'default' : 'pointer'}
              alignItems="flex-start"
            >
              {!isMySolution && (
                <UserAvatar
                  key={user_id}
                  name={user_name || ''}
                  src={user_avatar_url || ''}
                  {...(!accountDeleted && {
                    onClick: (ev) => {
                      ev.stopPropagation();
                      navigateToUserProfile(ev);
                    },
                    experience: user_experience || 0,
                  })}
                  hideCircularProgress
                />
              )}
              <VStack alignItems="flex-start" spacing={0} pt={1}>
                {!isMySolution && (
                  <HStack>
                    {!accountDeleted ? (
                      <Link
                        to={`${ERoute.Profile}/${user_screen_name}`}
                        color="white"
                        onClick={(ev) => ev.stopPropagation()}
                      >
                        <Text
                          fontSize="small"
                          maxW={
                            hasBadge
                              ? ['20vw', '30vw', '10vw', '10vw', '10vw']
                              : ['30vw', '40vw', '15vw', '15vw', '15vw']
                          }
                          isTruncated
                        >
                          {user_name}
                        </Text>
                      </Link>
                    ) : (
                      <Text
                        fontSize="small"
                        maxW={
                          hasBadge
                            ? ['20vw', '30vw', '10vw', '10vw', '10vw']
                            : ['30vw', '40vw', '15vw', '15vw', '15vw']
                        }
                        isTruncated
                      >
                        {user_name}
                      </Text>
                    )}
                    <BigBadge plan={user_plan as Plan_Type_Enum} showOnlySubscribed size="small" />
                  </HStack>
                )}
                <Text
                  fontSize={isMySolution ? 'medium' : 'micro'}
                  fontWeight={isMySolution ? 'medium' : 'regular'}
                >
                  {format(new Date(completed_date || ''), DATE_FORMAT)}
                </Text>
              </VStack>
            </HStack>
            <HStack spacing={4}>
              {!isExternalSolution && (
                <ScoreBadge type="code" score={code_score} hideText={isMobile} />
              )}
              {type !== 'problem' && (
                <ScoreBadge type="design" score={design_score} hideText={isMobile} />
              )}
            </HStack>
          </HStack>
          <HStack width="100%" justifyContent="space-between">
            <HStack width="100%" spacing={2}>
              {isMySolution && canEdit && (
                <GradientTooltip label={is_private ? 'Private code' : 'Public code'}>
                  <span>
                    {is_private ? <Icon type="PrivateCodeLock" /> : <Icon type="PublicCodeLock" />}
                  </span>
                </GradientTooltip>
              )}
              <RenameCodeInput
                isEditMode={isRenaming}
                onEditModeChange={setIsRenaming}
                title={title || 'Untitled'}
                textProps={{
                  fontSize: 'xLarge',
                }}
                size="sm"
                isMySolution={false}
                type={type as CodeEditorType}
                renameCodeParams={{
                  queryKey,
                  invalidateKeys,
                  entityId: id!,
                  userScreenName: user_screen_name!,
                  parentScreenName: parent_screen_name,
                  repositoryUrl: repository_url || '',
                  previewUrl: preview_url || '',
                }}
              />
            </HStack>
            {isMySolution && canEdit && (
              <CodePreviewMenu
                id={id}
                screenshot_url={screenshot_url}
                solution_url={solution_url}
                repository_url={repository_url}
                preview_url={preview_url}
                user_avatar_url={user_avatar_url}
                user_experience={user_experience}
                user_id={user_id}
                user_name={user_name}
                user_plan={user_plan}
                user_screen_name={user_screen_name}
                title={title}
                likes={likes}
                is_private={is_private}
                comments={comments}
                html={html}
                css={css}
                js={js}
                is_liked={is_liked}
                code_score={code_score}
                design_score={design_score}
                completed_date={completed_date}
                type={type}
                parent_id={parent_id}
                parent_screen_name={parent_screen_name}
                total_score={total_score}
                isPrivate={Boolean(is_private)}
                isFreeUser={user_plan === Plan_Type_Enum.Free}
                onToggleVisibility={() => {
                  mutation.mutate({
                    entityId: id!,
                    type: type as CodeEditorType,
                    isPrivate: !is_private,
                  });
                }}
                onRenaming={() => {
                  setIsRenaming(true);
                }}
                onDelete={() => {
                  deleteCodeModal.onOpen();
                }}
              />
            )}
          </HStack>
          {renderImageContent()}
          <Divider />
          <HStack width="100%" justifyContent="space-between">
            <HStack spacing={4}>
              <LikeButton
                userId={user_id || ''}
                likes={likes || 0}
                likeCodeParams={{
                  queryKey,
                  invalidateKeys,
                  entityId: id!,
                  isLiked: Boolean(is_liked),
                }}
              />
              <CommentButton comments={comments || 0} url={`${solution_url || ''}/comments`} />
            </HStack>
            <HStack spacing={4}>
              <GradientTooltip label="View solution code">
                <div>
                  <Link href={repository_url || ''} isExternal onClick={(e) => e.stopPropagation()}>
                    <IconButton aria-label="View code">
                      <Icon type="Code" />
                    </IconButton>
                  </Link>
                </div>
              </GradientTooltip>
              {type !== 'problem' && (
                <GradientTooltip label="View solution preview">
                  <div>
                    <Link href={preview_url || ''} isExternal onClick={(e) => e.stopPropagation()}>
                      <IconButton aria-label="View solution">
                        <Icon type="Browser" />
                      </IconButton>
                    </Link>
                  </div>
                </GradientTooltip>
              )}
            </HStack>
          </HStack>
        </VStack>
      </Box>
      <DeleteCodeModal
        entityId={id!}
        type={type as CodeEditorType}
        isOpen={deleteCodeModal.isOpen}
        onClose={deleteCodeModal.onClose}
        onDelete={handleInvalidateCode}
      />
    </>
  );
};

export default CodePreviewCard;

interface IMemoCodeFrameProps {
  html: string;
  css: string;
  js: string;
  borderRadius: string;
  height: string;
}

const MemoCodeFrame = ({ html, css, js, borderRadius, height }: IMemoCodeFrameProps) => {
  const url = useMemo(() => getGeneratedPageURL(html, css, js), [html, css, js]);

  return (
    <CodeFrame
      url={url}
      style={{
        height,
        borderRadius,
      }}
      skeleton={<Skeleton height={height} width="100%" borderRadius={borderRadius} />}
    />
  );
};

interface ICodePreviewMenuProps extends CodePreview {
  isPrivate: boolean;
  isFreeUser: boolean;
  onRenaming: () => void;
  onDelete: () => void;
  onToggleVisibility: () => void;
}

const CodePreviewMenu = ({
  solution_url,
  isPrivate,
  isFreeUser,
  onRenaming,
  onDelete,
  onToggleVisibility,
}: ICodePreviewMenuProps) => {
  const navigate = useNavigate();

  const { toastSuccess } = useToastWrapper();

  const handleCopyToClipboard = (link: string) => {
    const APP_URL = process.env.REACT_APP_SITE_URL || '';

    const fullLink = `${APP_URL.substring(0, APP_URL.length - 1)}${link}`;

    navigator.clipboard.writeText(fullLink);
    toastSuccess('Link copied to clipboard');
  };

  const openItem: TDotItem = {
    id: 'open',
    title: 'Open',
    onClick: () => {
      navigate(solution_url!);
    },
  };

  const openItemInNewTab: TDotItem = {
    id: 'open-in-new-tab',
    title: 'Open in new tab',
    onClick: () => {
      window.open(solution_url!, '_blank');
    },
  };

  const copyLinkItem: TDotItem = {
    id: 'copy-link',
    title: 'Copy link',
    withDivider: true,
    onClick: () => {
      handleCopyToClipboard(solution_url!);
    },
  };

  const toggleVisibilityItem: TDotItem = {
    id: 'toggle-visibility',
    isDisabled: isFreeUser,
    title: (
      <HStack>
        <Text opacity="default">Make {isPrivate ? 'public' : 'private'}</Text>
        {isFreeUser && <BigBadge plan={Plan_Type_Enum.Lifetime} />}
      </HStack>
    ),
    onClick: () => {
      if (isFreeUser) {
        return;
      }

      onToggleVisibility();
    },
  };

  const renameItem: TDotItem = {
    id: 'rename',
    title: 'Rename',
    withDivider: true,
    onClick: onRenaming,
  };

  const deleteItem: TDotItem = {
    id: 'delete',
    title: 'Delete',
    isDanger: true,
    onClick: onDelete,
  };

  return (
    <DotsMenu
      items={[
        openItem,
        openItemInNewTab,
        copyLinkItem,
        toggleVisibilityItem,
        renameItem,
        deleteItem,
      ]}
    />
  );
};
