import { VStack } from '@chakra-ui/layout';
import { useSupabaseTypedClient } from 'hooks/reactQuery';
import { useToastWrapper } from 'hooks/useToastWrapper';
import { useState } from 'react';

import { useAuth } from 'auth/AuthProvider';
import Button from 'components/Button';
import Modal from 'components/Modal';
import Text from 'components/Text';
import { EdgeFunctionName } from 'types/edgeFunctions';
import { IModal } from 'types/frontend';

interface IGitHubDetachModal extends IModal {}

export const GitHubDetachModal = ({ isOpen, onClose }: IGitHubDetachModal) => {
  const [isLoading, setIsLoading] = useState(false);
  const { toastSuccess, toastError } = useToastWrapper();
  const { signOut } = useAuth();

  const supabase = useSupabaseTypedClient();
  const detachGitHub = async () => {
    try {
      setIsLoading(true);
      const { error } = await supabase.functions.invoke(EdgeFunctionName.GithubDetach);

      if (error) throw error;

      await signOut();

      toastSuccess(`GitHub Account disconnected`);
      onClose();
    } catch (error: any) {
      toastError(JSON.stringify(error));
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={detachGitHub}
      header="Disconnecting GitHub account"
      footer={
        <>
          <Button scheme="ghost" onClick={onClose}>
            Close
          </Button>
          <Button type="submit" isLoading={isLoading}>
            Confirm
          </Button>
        </>
      }
    >
      <VStack align="flex-start" spacing={5}>
        <Text fontWeight="regular">
          Your GitHub account will be disconnected from the BigDevSoon and you will be signed out.
        </Text>
        <Text fontWeight="regular">
          You can sign in again but you need to re-grant access to GitHub.
        </Text>
      </VStack>
    </Modal>
  );
};
