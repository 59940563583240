import { switchAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers, extendTheme } from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  switchAnatomy.keys
);

const switchStyle = definePartsStyle({
  thumb: {
    _checked: {
      bg: 'purple.200',
    },
  },
  track: {
    _checked: {
      bg: 'purple.400',
    },
  },
});

const switchTheme = defineMultiStyleConfig({ baseStyle: switchStyle });

export const theme = extendTheme({
  fonts: {
    heading: 'Poppins, sans-serif',
    body: 'Poppins, sans-serif',
  },
  fontSizes: {
    xMicro: '0.625rem', // 10px
    micro: '0.75rem', // 12px
    small: '0.875rem', // 14px
    medium: '1rem', // 16px -- default
    large: '1.125rem', // 18px
    xLarge: '1.25rem', // 20px
    xxLarge: '1.5rem', // 24px
    xxxLarge: '2rem', // 32px
    xxxxLarge: '2.5rem', // 40px
    xxxxxLarge: '3rem', // 48px
    xxxxxxLarge: '4rem', // 64px
  },
  config: {
    initialColorMode: 'dark',
    useSystemColorMode: false,
  },
  opacity: {
    primary: 0.87, // -- default
    secondary: 0.6,
    tertiary: 0.38,
  },
  borderRadius: '8px',
  styles: {
    global: (props) => ({
      body: {
        fontFamily: 'body',
        color: mode('gray.600', 'whiteAlpha.900')(props),
        bg: mode('white', 'gray.900')(props),
        lineHeight: 'base',
      },
      html: {
        fontSize: '16px',
      },
      th: {
        textTransform: 'none !important',
      },
    }),
  },
  components: {
    Switch: switchTheme,
    Checkbox: {
      defaultProps: {
        colorScheme: 'purple',
      },
    },
    Input: {
      baseStyle: {
        field: {
          _placeholder: {
            color: 'whiteAlpha.600',
          },
        },
      },
    },
    Textarea: {
      baseStyle: {
        _placeholder: {
          color: 'whiteAlpha.600',
        },
      },
    },
  },
});
