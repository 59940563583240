import { useEffect } from 'react';

interface IUseExitAlertParams {
  shouldShowBrowserExitAlert: boolean;
}

export const useExitAlert = ({ shouldShowBrowserExitAlert }: IUseExitAlertParams) => {
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (shouldShowBrowserExitAlert) {
        const message = 'Are you sure you want to leave? Changes you made may not be saved.';
        event.returnValue = message;

        return message;
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
  }, [shouldShowBrowserExitAlert]);
};
