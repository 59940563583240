import { useEffect, useRef, useState } from 'react';

interface ICodeFrameUrlProps {
  url: string;
  style?: React.CSSProperties;
  skeleton?: React.ReactNode;
}

const CodeFrame = ({ url, style, skeleton }: ICodeFrameUrlProps) => {
  const iframeRef = useRef<any>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (iframeRef.current) {
      const iframe = iframeRef.current;

      const handleLoad = () => {
        setIsLoading(false);
        iframe.style.visibility = 'visible';
      };

      iframe.addEventListener('load', handleLoad);
      return () => {
        iframe.removeEventListener('load', handleLoad);
      };
    }
  }, []);

  if (!url) {
    return null;
  }

  return (
    <div style={{ position: 'relative', width: '100%', height: '100%' }}>
      {isLoading && skeleton && (
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            zIndex: 2,
          }}
        >
          {skeleton}
        </div>
      )}
      <iframe
        id={`bds-code-frame-${url}`}
        className="bds-code-frame"
        ref={iframeRef}
        src={url}
        sandbox="allow-forms allow-modals allow-pointer-lock allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation allow-downloads allow-presentation"
        allow="accelerometer; camera; encrypted-media; display-capture; geolocation; gyroscope; microphone; midi; clipboard-read; clipboard-write; web-share"
        loading="lazy"
        allowFullScreen
        // eslint-disable-next-line
        allowTransparency
        onLoad={() => {
          iframeRef.current.style.visibility = 'visible';
        }}
        style={{
          width: '100%',
          height: '100%',
          backgroundColor: 'white',
          visibility: 'hidden',
          ...style,
        }}
      />
    </div>
  );
};

export default CodeFrame;
