import { Box, HStack, Stack, VStack, Divider, useDisclosure } from '@chakra-ui/react';
import { format } from 'date-fns';

import { useCurrentUser } from 'auth/CurrentUserProvider';
import BigTokens from 'components/BigTokens';
import Button from 'components/Button';
import GradientPopover from 'components/GradientPopover';
import Icon from 'components/Icon';
import { TokensModal } from 'components/Modal/TokensModal';
import ProjectIcon from 'components/ProjectIcon';
import Text from 'components/Text';
import { DATE_FORMAT, plans } from 'config/constants/plans';

const UserBigtokens = () => {
  const { tokensAvailable, extraTokens, rechargeTokens, nextTokenRecycleTime, plan } =
    useCurrentUser();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const renderTooltipContent = () => {
    return (
      <VStack alignItems="flex-start">
        <VStack alignItems="flex-start">
          <HStack>
            <Text fontSize="small" fontWeight="semibold">
              BigTokens in your plan:
            </Text>
            <BigTokens amount={rechargeTokens} />
          </HStack>
          <HStack spacing={1.5}>
            <Text fontSize="small" fontWeight="regular">
              You are currently on a{' '}
            </Text>
            <Text
              fontSize="small"
              color={plans[plan].color}
              fontWeight="semibold"
              opacity="default"
            >
              {plan} Plan
            </Text>
          </HStack>
          <HStack spacing={1}>
            <Text fontSize="small" fontWeight="regular">
              which recharges
            </Text>
            <Text fontSize="small" fontWeight="semibold">
              {rechargeTokens} BigTokens monthly.
            </Text>
          </HStack>
          <Divider my={2} />
        </VStack>

        <VStack alignItems="flex-start">
          <VStack alignItems="flex-start">
            <HStack>
              <Text fontSize="small" fontWeight="semibold">
                Next recharge date:
              </Text>
              <Text fontSize="small" fontWeight="semibold">
                {format(new Date(nextTokenRecycleTime), DATE_FORMAT)}
              </Text>
            </HStack>
            <Text fontSize="small" fontWeight="regular">
              Your account will be recharged back to
            </Text>
            <HStack spacing={1}>
              <Text fontSize="small" fontWeight="semibold">
                {rechargeTokens} BigTokens
              </Text>
              <Text fontSize="small" fontWeight="regular">
                on that date.
              </Text>
            </HStack>
            <Divider my={2} />
          </VStack>
        </VStack>

        <VStack alignItems="flex-start">
          <HStack>
            <Text fontSize="small" fontWeight="semibold">
              Extra BigTokens:
            </Text>
            <BigTokens amount={extraTokens} />
          </HStack>
          <Text fontSize="small" fontWeight="regular">
            These can be collected by inviting your friends, connecting Discord account for the
            first time, or buying token packages.
          </Text>
          <Text fontSize="small" fontWeight="regular" textDecoration="underline" mt={2}>
            Extra BigTokens are spent out first.
          </Text>
        </VStack>
        <Button
          mt={2}
          fullWidth
          scheme="gradient"
          onClick={onOpen}
          leftIcon={<Icon type="Bigtokens" size={24} />}
        >
          Get BigTokens now
        </Button>
        <TokensModal isOpen={isOpen} onClose={onClose} />
      </VStack>
    );
  };

  const triggerComponent = () => {
    return (
      <Box
        p="3px"
        background="linear-gradient(360deg, rgba(159, 122, 234, 0.57) 50.16%, rgba(237, 137, 54, 0.87) 95.35%)"
        borderRadius="50px"
      >
        <Box
          py={1}
          px={3}
          background="linear-gradient(360deg, rgba(159, 122, 234, 0.87) 1.16%, rgba(237, 137, 54, 0.87) 95.35%);"
          borderRadius="50px"
        >
          <Stack justifyContent="center" alignItems="center" direction="row" spacing={1.5}>
            <ProjectIcon type="Bigtokens" size="sm" />
            <HStack spacing={1}>
              <Text>{tokensAvailable}</Text>
              {extraTokens > 0 && <Text opacity="secondary">(+{extraTokens})</Text>}
            </HStack>
          </Stack>
        </Box>
      </Box>
    );
  };

  return (
    <GradientPopover header={null} triggerComponent={triggerComponent()} isHover>
      {renderTooltipContent()}
    </GradientPopover>
  );
};

export default UserBigtokens;
