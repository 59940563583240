import { SupabaseClient } from '@supabase/supabase-js';
import { useQueryWithErrorBoundary, useSupabaseTypedClient } from 'hooks/reactQuery';

import { Database } from 'types/supabase';

interface IGetTokensQueryFnParams {
  supabase: SupabaseClient<Database>;
}

export const getTokensQueryFn = async ({ supabase }: IGetTokensQueryFnParams) => {
  const { data, error } = await supabase
    .from('token_package')
    .select('id, order_id, tokens, price, is_best_value, icon');

  if (error) throw error;

  return data;
};

interface IGetTokensResponse {
  data: {
    id: string;
    order_id: number;
    tokens: number;
    price: number;
    is_best_value: boolean;
    icon: string;
  }[];
  isPending: boolean;
}

export const useGetTokensQuery = (): IGetTokensResponse => {
  const supabase = useSupabaseTypedClient();

  const { data, isPending } = useQueryWithErrorBoundary({
    queryKey: ['tokens'],
    queryFn: () =>
      getTokensQueryFn({
        supabase,
      }),
  });

  if (!data) {
    return {
      data: [],
      isPending,
    };
  }

  return {
    data,
    isPending,
  };
};
