import { Box } from '@chakra-ui/react';

import { Card_Type_Enum } from 'types/databaseEnums';

import Icon, { IconType } from './Icon';

const sizeMapper = {
  xs: 16,
  s: 20,
  sm: 24,
  md: 32,
  xl: 64,
};

export interface CardIconProps {
  type: Card_Type_Enum;
  size?: 'xs' | 's' | 'sm' | 'md' | 'xl' | number;
  display?: 'block' | 'inline';
  isDisabled?: boolean;
}

const mapCardTypeToIcon: Record<Card_Type_Enum, IconType> = {
  backend: 'Backend',
  // For now it won't be used but maybe we can use it in the future with different icon
  concept: 'Research',
  task: 'Research',
  frontend: 'Frontend',
};

const CardIcon = ({ type, size = 'sm', display = 'block', isDisabled }: CardIconProps) => {
  return (
    <Box>
      <Icon
        type={isDisabled ? 'Lock' : mapCardTypeToIcon[type]}
        size={sizeMapper[size] || size}
        display={display}
      />
    </Box>
  );
};

export default CardIcon;
