import { Tabs as ChakraTabs, TabList, Tab, useTheme } from '@chakra-ui/react';

import Text from './Text';

interface ITabsProps {
  tabIndex: number;
  onChange: (index: number) => void;
  tabs: string[];
  selectedColor?: string;
}

const Tabs = ({ tabIndex, onChange, tabs, selectedColor = 'purple.600' }: ITabsProps) => {
  const { opacity } = useTheme();

  return (
    <ChakraTabs variant="soft-rounded" index={tabIndex} onChange={(index) => onChange(index)}>
      <TabList
        backgroundColor="whiteAlpha.100"
        borderRadius="9999px"
        py={1}
        px={1}
        overflowX="auto"
      >
        {tabs.map((tab) => (
          <Tab
            key={tab}
            _selected={{ backgroundColor: `${selectedColor} !important` }}
            color="white !important"
            whiteSpace="nowrap"
          >
            <Text _hover={{ opacity: tabIndex === 0 ? 1 : opacity.primary }} opacity="default">
              {tab}
            </Text>
          </Tab>
        ))}
      </TabList>
    </ChakraTabs>
  );
};

export default Tabs;
