import {
  Modal as ChakraModal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  ButtonGroup,
  useTheme,
} from '@chakra-ui/react';
import { ReactNode } from 'react';

import { useMediaQueries } from 'layout/MediaQueriesProvider';

import Text from './Text';

interface ModalProps {
  size?: 'sm' | 'md' | 'xl' | '2xl' | '3xl' | '4xl' | '5xl' | '6xl' | 'full';
  isOpen: boolean;
  onClose: () => void;
  onSubmit?: () => void;
  header: ReactNode;
  children: ReactNode;
  footer: ReactNode;
  isFooterFullWidth?: boolean;
  isCentered?: boolean;
}

const Modal = ({
  size: modalSize = 'xl',
  isOpen,
  onClose,
  onSubmit,
  header,
  children,
  footer,
  isFooterFullWidth,
  isCentered = true,
}: ModalProps) => {
  const { borderRadius } = useTheme();
  const { isMobile } = useMediaQueries();

  const size = modalSize;

  const mobileProps = isMobile ? { m: 4 } : {};

  const fullSizeProps =
    size === 'full'
      ? {
          width: '97vw',
          minHeight: '95%',
          maxHeight: '97%',
          border: '2px solid',
          borderColor: 'whiteAlpha.300',
        }
      : {};

  const modalFooterProps = isFooterFullWidth ? { p: 0 } : {};
  const buttonGroupProps = isFooterFullWidth ? { width: '100%' } : {};

  return (
    <ChakraModal
      size={size}
      isOpen={isOpen}
      onClose={onClose}
      isCentered={isCentered}
      returnFocusOnClose={false}
    >
      <form
        onSubmit={(event) => {
          event.preventDefault();

          onSubmit?.();
        }}
      >
        <ModalOverlay />
        <ModalContent
          backgroundColor="gray.800"
          boxShadow="0px 4px 20px 8px rgba(0, 0, 0, 0.24)"
          borderRadius={borderRadius}
          {...mobileProps}
          {...fullSizeProps}
          overflowY="auto"
        >
          <ModalHeader>
            {typeof header === 'string' ? <Text fontSize="xLarge">{header}</Text> : header}
          </ModalHeader>
          <ModalCloseButton mt={2} />
          <ModalBody py={0}>{children}</ModalBody>
          {footer && (
            <ModalFooter {...modalFooterProps}>
              <ButtonGroup spacing={3} {...buttonGroupProps}>
                {footer}
              </ButtonGroup>
            </ModalFooter>
          )}
        </ModalContent>
      </form>
    </ChakraModal>
  );
};

export default Modal;
