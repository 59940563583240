import { Box, Image, VStack } from '@chakra-ui/react';
import { useToastWrapper } from 'hooks/useToastWrapper';
import React, { useState } from 'react';

import Icon from './Icon';
import Text from './Text';

interface ImageUploadBoxProps {
  onImageChange: (base64: string) => void;
}

const ImageUploadBox: React.FC<ImageUploadBoxProps> = ({ onImageChange }: ImageUploadBoxProps) => {
  const [imageSrc, setImageSrc] = useState<string | null>(null);
  const { toastError } = useToastWrapper();

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (!file) return;

    if (file.size > 10485760) {
      // 10 MB limit
      toastError(
        `File size is too large (${(file.size / 1048576).toFixed(2)} MB). Max limit is 10 MB.`
      );

      return;
    }

    const reader = new FileReader();
    reader.onload = (e) => {
      setImageSrc(e.target?.result as string);
      onImageChange(e.target?.result as string);
    };
    reader.readAsDataURL(file);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      bg="whiteAlpha.200"
      borderRadius="md"
      textAlign="center"
      width="100%"
      height="300px"
      mx="auto"
      cursor="pointer"
      onClick={() => document.getElementById('imageUploadInput')?.click()}
    >
      <input
        id="imageUploadInput"
        type="file"
        accept="image/*"
        hidden
        onChange={handleImageChange}
      />
      {imageSrc ? (
        <Image src={imageSrc} borderRadius="md" alt="Uploaded image" width="100%" height="300px" />
      ) : (
        <VStack>
          <Icon type="UploadImage" />
          <Text color="purple.200" fontWeight="regular">
            Upload your image
          </Text>
          <Text fontSize="micro" opacity="secondary" fontWeight="regular">
            PNG, JPG, JPEG (max 10 MB)
          </Text>
        </VStack>
      )}
    </Box>
  );
};

export default ImageUploadBox;
