import { Center, Spinner, SpinnerProps, VStack } from '@chakra-ui/react';
import { useState, useEffect, useRef, ReactNode } from 'react';

import { useMediaQueries } from 'layout/MediaQueriesProvider';

import Text from './Text';

interface IPageLoaderProps extends SpinnerProps {
  text?: ReactNode;
  shift?: boolean;
}

const PageLoader = (props: IPageLoaderProps) => {
  const { isDesktopHome } = useMediaQueries();
  const [showLoader, setShowLoader] = useState(false);

  const timeoutRef = useRef<any>(null);

  // Delay showing loader after 200 ms
  useEffect(() => {
    if (!showLoader) {
      timeoutRef.current = setTimeout(() => {
        setShowLoader(true);
      }, 200);
    }

    return () => clearTimeout(timeoutRef.current);
  }, [showLoader]);

  if (!showLoader) {
    return null;
  }

  return (
    <Center
      position="fixed"
      top="50%"
      left={isDesktopHome && props.shift ? '55%' : '50%'}
      transform="translate(-50%, -50%)"
      zIndex={9999}
    >
      <VStack spacing={4}>
        <Spinner size="lg" {...props} />
        {props.text && (
          <Text fontWeight="regular" fontSize="small">
            {props.text}
          </Text>
        )}
      </VStack>
    </Center>
  );
};

export default PageLoader;
