import { ChevronRightIcon } from '@chakra-ui/icons';
import {
  useDisclosure,
  Divider,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  HStack,
  VStack,
  useTheme,
  Box,
  Image,
  CircularProgress,
  Center,
} from '@chakra-ui/react';
import { useGetProjectsQuery } from 'hooks/queries/projects/useGetProjectsQuery';
import React from 'react';

import Button from 'components/Button';
import GradientTooltip from 'components/GradientTooltip';
import Icon from 'components/Icon';
import Link from 'components/Link';
import PageLoader from 'components/PageLoader';
import Text from 'components/Text';
import { DifficultyMarker, StatusIconMapper } from 'features/practice-problems/PracticeProblems';
import { User_Project_Status_Enum } from 'types/databaseEnums';
import { ProjectPreview } from 'types/edgeFunctions';
import { ERoute } from 'types/frontend';

interface IProjectsListProps {
  data: ProjectPreview[] | null | undefined;
  isLoading: boolean;
  onClose: () => void;
}

const ProjectsList = ({ data, isLoading, onClose }: IProjectsListProps) => {
  const { borderRadius } = useTheme();

  if (isLoading || !data) {
    return <PageLoader />;
  }

  return (
    <>
      <VStack width="100%" spacing={2}>
        {data.map(
          (
            { id, status, title, screen_name, horizontal_thumbnail, card, difficulty_lvl },
            index
          ) => {
            return (
              <Link
                to={`${ERoute.Projects}/${screen_name}`}
                onClick={onClose}
                key={id}
                width="100%"
                _hover={{ backgroundColor: 'whiteAlpha.300', textDecoration: 'none' }}
                borderRadius={borderRadius}
                transition="all 0.2s ease-out"
                p={3}
                backgroundColor="whiteAlpha.200"
              >
                <HStack width="100%" justifyContent="space-between">
                  <HStack alignItems="center" spacing={4}>
                    <Box>{StatusIconMapper[status]}</Box>
                    <Image
                      objectFit="initial"
                      maxWidth="93px"
                      height="70px"
                      borderRadius={borderRadius}
                      fallbackStrategy="onError"
                      fallback={
                        <Center
                          width="100%"
                          height="100%"
                          minHeight={70}
                          background="white"
                          borderRadius={borderRadius}
                        >
                          <Text color="black">Image not found</Text>
                        </Center>
                      }
                      src={horizontal_thumbnail.url || ''}
                      alt={title}
                    />

                    <VStack alignItems="flex-start">
                      <Text>
                        {index + 1}. {title}
                      </Text>
                      <Text fontSize="small" opacity="secondary">
                        {card.length} cards
                      </Text>
                    </VStack>
                  </HStack>
                  <DifficultyMarker difficulty={difficulty_lvl} />
                </HStack>
              </Link>
            );
          }
        )}
      </VStack>
    </>
  );
};

const ProjectsDrawer = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const divRef = React.useRef<HTMLDivElement | null>(null);

  const { data, isPending } = useGetProjectsQuery();

  const completedProjectsCount =
    data?.filter((project) => project.status === User_Project_Status_Enum.Completed).length || 0;

  const progress =
    completedProjectsCount === 0
      ? 0
      : Math.floor((completedProjectsCount / (data?.length || 1)) * 100);

  return (
    <>
      <div ref={divRef}>
        <Button
          scheme="ghost"
          onClick={onOpen}
          leftIcon={<Icon type="DrawerOpen" size={16} />}
          size="sm"
        >
          <Text fontSize="small" opacity="default">
            Projects
          </Text>
        </Button>
      </div>
      <Drawer isOpen={isOpen} placement="left" onClose={onClose} finalFocusRef={divRef} size="md">
        <DrawerOverlay />
        <DrawerContent backgroundColor="gray.900">
          <DrawerCloseButton mt={1} />
          <DrawerHeader pb={3}>
            <HStack width="100%" justifyContent="space-between">
              <Link
                to={ERoute.Projects}
                onClick={onClose}
                color="white"
                _hover={{ textDecoration: 'none' }}
              >
                <HStack spacing={1}>
                  <Text fontSize="large" fontWeight="semibold">
                    Projects
                  </Text>
                  <ChevronRightIcon />
                </HStack>
              </Link>
              <GradientTooltip label={`${completedProjectsCount}/${data?.length || 1} completed`}>
                <CircularProgress
                  value={progress}
                  color="green.400"
                  thickness="6px"
                  size={4}
                  mr={8}
                  mb={1}
                />
              </GradientTooltip>
            </HStack>
          </DrawerHeader>
          <Divider />
          <DrawerBody p={5}>
            {isOpen && <ProjectsList data={data} isLoading={isPending} onClose={onClose} />}
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default ProjectsDrawer;
