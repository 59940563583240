import { RepeatClockIcon } from '@chakra-ui/icons';
import { Stack, useTheme } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

import Button from 'components/Button';
import Text from 'components/Text';
import { ERoute } from 'types/frontend';

export const TerminateState = () => {
  const navigate = useNavigate();
  const { borderRadius } = useTheme();

  return (
    <>
      <Stack spacing={6}>
        <Stack
          bg="whiteAlpha.200"
          borderRadius={borderRadius}
          px={6}
          py={7}
          boxShadow="0px 10px 20px rgba(0, 0, 0, 0.15)"
        >
          <Text fontWeight="regular">
            Your subscription is{' '}
            <Text as="span" opacity="default">
              no longer active.
            </Text>
          </Text>
          <Text fontWeight="regular">
            Most of the{' '}
            <Text as="span" opacity="default">
              functionality
            </Text>{' '}
            <Text fontWeight="regular" as="span" opacity="default">
              of the application
            </Text>{' '}
            <Text as="span" opacity="default">
              has been blocked.
            </Text>
          </Text>
          <Text fontWeight="regular">
            To have{' '}
            <Text as="span" opacity="default">
              full access
            </Text>{' '}
            <Text fontWeight="regular" as="span" opacity="default">
              to the application,
            </Text>{' '}
            <Text as="span" opacity="default">
              renew your subscription.
            </Text>
          </Text>
        </Stack>
        <Button
          leftIcon={<RepeatClockIcon />}
          width="fit-content"
          onClick={() => navigate(ERoute.Pricing)}
        >
          Renew subscription
        </Button>
      </Stack>
    </>
  );
};
