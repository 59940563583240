import { Box, Container, Grid, GridItem, Stack } from '@chakra-ui/react';
import { useQueryClient } from '@tanstack/react-query';
import { useEffect, useRef } from 'react';

import { useCurrentUser } from 'auth/CurrentUserProvider';
import { PaymentMethodBilling } from 'components/Billing/PaymentMethodBilling';
import SettingsTile from 'components/Billing/SettingsTile';
import { SubscriptionDetailsBilling } from 'components/Billing/SubscriptionDetailsBilling';
import { Support } from 'components/Billing/Support';
import PageLoader from 'components/PageLoader';
import { SEO } from 'components/SEO';
import StickyMenu from 'components/StickyMenu';
import Text from 'components/Text';
import { Plan_Type_Enum } from 'types/databaseEnums';
import {
  EdgeFunctionName,
  PaymentMethodType,
  SubscriptionDetails,
  SubscriptionStatus,
} from 'types/edgeFunctions';
import { TanstackQueryName } from 'types/frontend';

import { useBilling } from './context/BillingProvider';

const SUBSCRIPTION_DETAILS_ID = 'subscription-details';
const PAYMENT_METHOD_ID = 'payment-method';
const SUPPORT_ID = 'support';

const Billing = () => {
  const { id: userId } = useCurrentUser();
  const { isLoading, data, isWaitingForNewData } = useBilling();
  const interval = useRef<any>();
  const queryClient = useQueryClient();

  useEffect(() => {
    const refetchUserData = async () => {
      await Promise.all([
        queryClient.invalidateQueries({ queryKey: [TanstackQueryName.GetCurrentUser, userId] }),
        queryClient.invalidateQueries({ queryKey: [TanstackQueryName.GetUserProfile, userId] }),
      ]);
    };

    if (isWaitingForNewData) {
      interval.current = setInterval(async () => {
        await queryClient.invalidateQueries({
          queryKey: [EdgeFunctionName.GetSubscriptionDetails],
        });
      }, 5000);
    } else {
      clearInterval(interval.current);
      refetchUserData();
    }

    return () => clearInterval(interval.current);
  }, [isWaitingForNewData]);

  if (isLoading) {
    return <PageLoader shift />;
  }

  const {
    next_billing_time,
    expiration_date,
    plan: subscription_plan,
    brand,
    last4,
    payment_method_type,
    status: subscription_status,
    amount,
  } = {
    ...(data as SubscriptionDetails),
  };

  const nextBillingTime = new Date(next_billing_time || 0);

  const shouldRenderPaymentMethodMenu = [SubscriptionStatus.Active].includes(subscription_status);

  const getMenuItems = (): { id: string; name: string }[] => {
    const allMenuItems = [
      { id: SUBSCRIPTION_DETAILS_ID, name: 'Subscription details' },
      { id: PAYMENT_METHOD_ID, name: 'Payment method' },
      { id: SUPPORT_ID, name: 'Support' },
    ];

    if (shouldRenderPaymentMethodMenu) {
      return allMenuItems;
    }

    return allMenuItems.filter((x) => x.id !== PAYMENT_METHOD_ID);
  };

  return (
    <Box width="100%" overflowX="hidden">
      <SEO
        title={`BigDevSoon | Billing`}
        description="Check out your subscription details and payment method information on this page"
        url={`${process.env.REACT_APP_SITE_URL}billing`}
      />
      <Container maxW="1400px">
        <Stack spacing={4}>
          <Text fontSize="xxLarge" fontWeight="semibold" py={4}>
            Billing
          </Text>
          <Grid templateColumns="repeat(12, 1fr)" gap={8}>
            <GridItem colSpan={[12, 12, 12, 3]}>
              <StickyMenu tabs={getMenuItems()} />
            </GridItem>
            <GridItem colSpan={[12, 12, 12, 9]} mt={2}>
              <Stack spacing={8}>
                <div id={SUBSCRIPTION_DETAILS_ID}>
                  <SubscriptionDetailsBilling
                    brand={brand ?? ''}
                    last4={last4 ?? ''}
                    amount={amount ?? 0}
                    status={subscription_status}
                    plan={subscription_plan as Plan_Type_Enum}
                    nextBillingTime={nextBillingTime}
                  />
                </div>
                {shouldRenderPaymentMethodMenu && (
                  <div id={PAYMENT_METHOD_ID}>
                    <PaymentMethodBilling
                      brand={brand}
                      last4={last4}
                      expirationDate={
                        expiration_date !== null ? new Date(expiration_date) : undefined
                      }
                      paymentMethodType={payment_method_type as PaymentMethodType}
                    />
                  </div>
                )}
                <div id={SUPPORT_ID}>
                  <SettingsTile
                    title="Support"
                    subtitle="Additional support related to payment and billing."
                  >
                    <Support />
                  </SettingsTile>
                </div>
              </Stack>
            </GridItem>
          </Grid>
        </Stack>
      </Container>
    </Box>
  );
};

export default Billing;
