import { SupabaseClient } from '@supabase/supabase-js';
import { useQueryWithErrorBoundary, useSupabaseTypedClient } from 'hooks/reactQuery';

import { AchievementExperienceReward } from 'types/customEnums';
import { GetAchievementsResponse } from 'types/edgeFunctions';
import { TanstackQueryName } from 'types/frontend';
import { Database } from 'types/supabase';

interface IGetUserAchievementsQueryFnParams {
  supabase: SupabaseClient<Database>;
  userId: string;
}

export const getUserAchievementsQueryFn = async ({
  supabase,
  userId,
}: IGetUserAchievementsQueryFnParams) => {
  const achievementsValues = Object.values(AchievementExperienceReward);

  const [userExperience, achievementsReward] = await Promise.all([
    supabase
      .from('user_experience')
      .select('id, experience, experience_reward_id, timestamp')
      .eq('user_id', userId)
      .in('experience_reward_id', achievementsValues),
    supabase.from('experience_reward').select('*').in('id', achievementsValues),
  ]);

  if (userExperience.error) throw userExperience.error;
  if (achievementsReward.error) throw achievementsReward.error;

  const userExperienceIds = userExperience.data.map(
    (userExperience) => userExperience.experience_reward_id
  );

  const achievements = achievementsReward.data.map((achievement) => ({
    id: achievement.id,
    name: achievement.name,
    xp: achievement.experience,
    description: achievement.description || '',
    type: achievement.icon || '',
    is_completed: userExperienceIds.includes(achievement.id),
    timestamp:
      userExperience.data.filter((x) => x.experience_reward_id === achievement.id)[0]?.timestamp ||
      '',
  }));

  return {
    achievements,
  };
};

interface IUseGetUserAchievementsResponse {
  data: GetAchievementsResponse | undefined | null;
  isPending: boolean;
}

export const useGetUserAchievementsQuery = (userId: string): IUseGetUserAchievementsResponse => {
  const supabase = useSupabaseTypedClient();

  const { data, isPending } = useQueryWithErrorBoundary({
    queryKey: [TanstackQueryName.GetUserAchievements, userId],
    queryFn: () =>
      getUserAchievementsQueryFn({
        supabase,
        userId,
      }),
  });

  if (!data) {
    return {
      data: null,
      isPending,
    };
  }

  return {
    data,
    isPending,
  };
};
