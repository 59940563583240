import { SupabaseClient } from '@supabase/supabase-js';
import {
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  UseQueryOptions,
  UseQueryResult,
  useInfiniteQuery,
  useQuery,
} from '@tanstack/react-query';
import { useClient } from 'react-supabase';

import { Database } from 'types/supabase';
import { isLocalEnvironment } from 'utils/env';

export type SupabaseResult<TData> = {
  data?: TData | null;
  error?: any;
};

type SupabaseFunction<TData, TVariables> = (options: TVariables) => Promise<SupabaseResult<TData>>;

export const supabaseMutationFn = <TData, TVariables>(
  supabaseFn: SupabaseFunction<TData, TVariables>
) => {
  return async function (variables: TVariables): Promise<TData> {
    const { data, error } = await supabaseFn(variables);

    if (error) throw error;

    return data!;
  };
};

// ONLY FOR LOCAL SWITCHING OF KONG -> LOCALHOST, Supabase does not have an easy workaround
function replaceURLs(data) {
  const replaceURLInString = (str) => {
    if (typeof str === 'string') {
      return str.replace('http://kong:8000', 'http://localhost:54321');
    }
    return str;
  };

  const processObject = (obj) => {
    for (const key in obj) {
      if (typeof obj[key] === 'string') {
        obj[key] = replaceURLInString(obj[key]);
      } else if (typeof obj[key] === 'object' && obj[key] !== null) {
        processElement(obj[key]); // Recursive call for nested objects or arrays
      }
    }
  };

  const processArray = (arr) => {
    arr.forEach((item, index) => {
      if (typeof item === 'string') {
        arr[index] = replaceURLInString(item);
      } else if (typeof item === 'object' && item !== null) {
        processElement(item); // Recursive call for each element in the array
      }
    });
  };

  const processElement = (element) => {
    if (Array.isArray(element)) {
      processArray(element);
    } else if (typeof element === 'object' && element !== null) {
      processObject(element);
    }
  };

  // Start processing
  processElement(data);

  return data;
}

export function useQueryWithErrorBoundary<TData = unknown, TError = unknown>(
  options: UseQueryOptions<TData, TError>
): UseQueryResult<TData, TError> {
  const queryResult = useQuery(options);

  if (queryResult.error) {
    throw queryResult.error;
  }

  if (isLocalEnvironment()) {
    const modifiedData = replaceURLs(queryResult.data);

    const modifiedQueryResult = {
      ...queryResult,
      data: modifiedData,
    };

    return modifiedQueryResult;
  }

  return queryResult;
}

export function useInfiniteQueryWithErrorBoundary<TData = unknown, TError = unknown>(
  options: UseInfiniteQueryOptions<TData, TError>
): UseInfiniteQueryResult<TData, TError> {
  const queryResult = useInfiniteQuery(options);

  if (queryResult.error) {
    throw queryResult.error;
  }

  if (isLocalEnvironment()) {
    const modifiedData = replaceURLs(queryResult.data);

    const modifiedQueryResult = {
      ...queryResult,
      data: modifiedData,
    };

    return modifiedQueryResult;
  }

  return queryResult;
}

export const useSupabaseTypedClient = () => {
  const client = useClient();

  return client as SupabaseClient<Database>;
};
