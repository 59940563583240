import { Box, HStack } from '@chakra-ui/react';

import Icon from './Icon';
import Text from './Text';

const NewBadge = () => {
  return (
    <Box py={1} px={2} backgroundColor="white" borderRadius="48px">
      <HStack width="fit-content" spacing={1.5}>
        <Icon type="NewBadge" />
        <Text fontSize="micro" color="black">
          New
        </Text>
      </HStack>
    </Box>
  );
};

export default NewBadge;
