import { Avatar, AvatarProps, Box, CircularProgress, useTheme } from '@chakra-ui/react';

import { normalizeExperience } from 'utils/user';

import Icon from './Icon';
import Text from './Text';

interface IUserAvatarProps extends AvatarProps {
  experience?: number;
  hideCircularProgress?: boolean;
}

const mapSizeToDimensions = {
  xs: '24px',
  sm: '32px',
  md: '40px',
  big: '58px',
  lg: '76px',
  xl: '96px',
};

const mapProgressSizeToDimensions = {
  xs: '4px',
  sm: '4px',
  md: '8px',
  big: '12px',
  lg: '12px',
  xl: '16px',
};

const mapExpSizeToDimensions = {
  xs: 12,
  sm: 16,
  md: 18,
  big: 24,
  lg: 30,
  xl: 30,
};

const mapFontSizeToDimensions = {
  xs: 'micro',
  sm: 'micro',
  md: 'micro',
  big: 'large',
  lg: 'large',
  xl: 'large',
};

const UserAvatar = ({
  src,
  experience,
  hideCircularProgress = false,
  size = 'md',
  onClick,
  ...props
}: IUserAvatarProps) => {
  const { opacity } = useTheme();

  const dimensions = mapSizeToDimensions[size as string];
  const { normalizedExperience, level } = normalizeExperience(experience || 0);

  const hoverProps = onClick
    ? {
        cursor: 'pointer',
        border: '1px solid',
        borderColor: 'purple.200',
        opacity: opacity.primary,
      }
    : {};

  if (experience === null || experience === undefined) {
    return (
      <Avatar
        src={src}
        width={dimensions}
        height={dimensions}
        ignoreFallback
        onClick={onClick}
        border="1px solid"
        transition="all 0.2s ease-out"
        overflow="hidden"
        _hover={hoverProps}
        {...props}
      />
    );
  }

  return (
    <Box position="relative">
      <Avatar
        src={src}
        width={dimensions}
        height={dimensions}
        ignoreFallback
        onClick={onClick}
        border="1px solid"
        transition="all 0.2s ease-in-out"
        overflow="hidden"
        _hover={hoverProps}
        {...props}
      />
      {!hideCircularProgress && (
        <CircularProgress
          position="absolute"
          top="50%"
          left="50%"
          transform="translate(-50%, -50%)"
          value={normalizedExperience}
          color="orange.200"
          trackColor="gray.700"
          size={`calc(${dimensions} + ${mapProgressSizeToDimensions[size as string]})`}
          thickness="5px"
        />
      )}
      {size !== 'xs' && (
        <Box position="absolute" right={-1} bottom={0}>
          <Box position="relative">
            <Icon type="Experience" size={mapExpSizeToDimensions[size as string]} />
            <Text
              position="absolute"
              left="50%"
              top="50%"
              my="1px"
              transform="translate(-50%, -50%)"
              fontSize={mapFontSizeToDimensions[size as string]}
              fontWeight="semibold"
            >
              {level}
            </Text>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default UserAvatar;
