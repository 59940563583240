import { Box, Divider } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
interface SplitPaneProps {
  leftPaneSize?: number;
  rightPaneSize?: number;
  children: React.ReactNode[];
  onDragStart?: () => void;
  onDragEnd?: () => void;
  onResize?: () => void;
}

const SplitPane: React.FC<SplitPaneProps> = ({
  children,
  leftPaneSize,
  rightPaneSize,
  onDragStart,
  onDragEnd,
  onResize,
}) => {
  const [sizes, setSizes] = useState<number[]>([50, 50]);

  const [isDragging, setIsDragging] = useState<boolean>(false);

  useEffect(() => {
    if (leftPaneSize === rightPaneSize && sizes[0] !== 50 && sizes[1] !== 50) {
      setSizes([50, 50]);
    }
  }, [leftPaneSize, rightPaneSize]);

  const handleDragStart = (index: number) => (event: React.MouseEvent) => {
    setIsDragging(true);
    onDragStart?.();

    let startPos = event.clientX;
    const startSizes = [...sizes];
    document.body.style.userSelect = 'none'; // Prevent text selection during drag

    // Convert the minimum size in pixels to a percentage of the window width
    const minSizePercentage = (2 / window.innerWidth) * 100;

    const onMouseMove = (moveEvent: MouseEvent) => {
      const currentPos = moveEvent.clientX;
      const delta = ((currentPos - startPos) / window.innerWidth) * 100;

      if (index + 1 < sizes.length) {
        const newSize = startSizes[index] + delta;
        const newSizeNext = startSizes[index + 1] - delta;

        // Ensure the new sizes do not drop below the minimum threshold
        if (newSize >= minSizePercentage && newSizeNext >= minSizePercentage) {
          startSizes[index] = newSize;
          startSizes[index + 1] = newSizeNext;
          setSizes([...startSizes]); // Ensure real-time updates by spreading into a new array

          onResize?.();
        }
      }

      startPos = currentPos; // Update startPos for continuous delta calculation
    };

    const onMouseUp = () => {
      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('mouseup', onMouseUp);
      document.body.style.userSelect = ''; // Re-enable text selection
      setIsDragging(false);
      onDragEnd?.();
    };

    document.addEventListener('mousemove', onMouseMove);
    document.addEventListener('mouseup', onMouseUp);
  };

  return (
    <Box display="flex" flexDirection="row" height="100%" width="100%" overflowX="hidden">
      {children.map((child, index) => (
        <React.Fragment key={index}>
          <Box
            flex={`0 0 ${sizes[index]}%`}
            height="100%"
            className={`BDS-SplitPane-horizontal-${index}`}
            overflowX="auto"
            transition={!isDragging ? 'flex 0.3s ease-out' : 'none'}
          >
            {child}
          </Box>
          {index < children.length - 1 && (
            <Divider
              cursor="ew-resize"
              orientation="vertical"
              onMouseDown={handleDragStart(index)}
              zIndex={4}
              opacity={1}
              borderLeftWidth="4px"
              borderTopWidth="100%"
              backgroundColor={isDragging ? 'purple.400' : 'initial'}
              transition="background-color 0.2s ease-in-out"
              position="relative"
              sx={{
                '&::before': {
                  content: '""',
                  position: 'relative',
                  top: 0,
                  left: '-8px',
                  right: '-8px',
                  width: '20px',
                  bottom: 0,
                },
                '&:hover': {
                  backgroundColor: 'purple.400',
                },
              }}
            />
          )}
        </React.Fragment>
      ))}
    </Box>
  );
};

export default SplitPane;
