import { HStack } from '@chakra-ui/react';

import { StatsBox } from 'components/StatsBox';

type MyCollectionsProps = {
  rank: string;
  experience: number;
  likes: number;
};

const MyCollections = ({ rank, experience, likes }: MyCollectionsProps) => {
  return (
    <HStack spacing={3} height="100%" width="100%" pr={3}>
      <StatsBox
        icon="RankingPlace"
        label="Ranking place"
        count={isNaN(parseInt(rank)) ? 0 : Number(rank)}
        showHyphen={rank === '-'}
        width="170px"
      />
      <StatsBox icon="ExperienceBubble" label="Total experience" count={experience} width="170px" />
      <StatsBox icon="LikeHeart" label="Likes received" count={likes} width="170px" />
    </HStack>
  );
};

export default MyCollections;
