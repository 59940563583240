import { Select, Stack, Textarea, useTheme } from '@chakra-ui/react';
import { useSupabaseTypedClient } from 'hooks/reactQuery';
import { useToastWrapper } from 'hooks/useToastWrapper';
import { useState } from 'react';

import Button from 'components/Button';
import Modal from 'components/Modal';
import Text from 'components/Text';
import { useBilling } from 'features/billing/context/BillingProvider';
import { EdgeFunctionName } from 'types/edgeFunctions';
import { IModal } from 'types/frontend';
import { billingInfo, contactInfo } from 'utils/strings';

interface ICancelSubscriptionModal extends IModal {}

const reasons = [
  'Subscription plan is too expensive',
  'The application is not used anymore',
  'The application lacks new content',
  'A job was found',
  'Lack of time',
  'The content is not interesting',
  'Alternative application on the market',
  'Payment methods are inconvenient',
  'Lack of support',
  'Bad user experience',
  'Other feature expectations',
  'Other',
];

export const CancelSubscriptionModal = ({ isOpen, onClose }: ICancelSubscriptionModal) => {
  const theme = useTheme();
  const { toastInfo, toastError } = useToastWrapper();

  const [reason, setReason] = useState<string>();
  const [comment, setComment] = useState<string>();
  const [isProcessing, setIsProcessing] = useState(false);
  const { cacheTimestamp } = useBilling();

  const supabaseClient = useSupabaseTypedClient();

  const handleCancelation = async () => {
    try {
      setIsProcessing(true);
      await supabaseClient.functions.invoke<string>(EdgeFunctionName.SubscriptionCancel, {
        body: { reason, comment },
      });

      cacheTimestamp();

      setIsProcessing(false);
      onClose();

      toastInfo(billingInfo);
    } catch (err) {
      console.error(err);
      toastError(`Cancelation problem. ${contactInfo}`);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={handleCancelation}
      header="Cancel subscription"
      footer={
        <>
          <Button scheme="ghost" onClick={onClose}>
            Close
          </Button>

          <Button scheme="danger" type="submit" isDisabled={!reason} isLoading={isProcessing}>
            Yes, cancel
          </Button>
        </>
      }
    >
      <Stack align="flex-start">
        <Text fontWeight="regular" mb={5}>
          Thank you for all the time you spent with us! 🙇‍♂️
        </Text>
        <Text>Reason for cancelling</Text>

        <Select
          placeholder="Select reason"
          fontSize="small"
          opacity={theme.opacity.secondary}
          value={reason}
          onChange={(e) => setReason(e.target.value)}
        >
          {reasons.map((x) => (
            <option value={x} key={x}>
              {x}
            </option>
          ))}
        </Select>
        <Text pt={2}>
          Anything else you would want to share{' '}
          <Text fontSize="small" as="span" fontWeight="regular" opacity="secondary">
            (optional)
          </Text>
        </Text>
        <Textarea
          resize="none"
          placeholder="Add what you would want to share"
          fontSize="small"
          maxLength={200}
          opacity={theme.opacity.secondary}
          value={comment}
          onChange={(e) => setComment(e.target.value)}
        ></Textarea>
      </Stack>
    </Modal>
  );
};
