import { HStack, useTheme, VStack } from '@chakra-ui/react';

import Icon from 'components/Icon';
import Text from 'components/Text';
import { plans } from 'config/constants/plans';
import { Plan_Type_Enum } from 'types/databaseEnums';

export const LifetimeState = () => {
  const theme = useTheme();

  return (
    <>
      <VStack align="stretch" spacing={6}>
        <VStack align="stretch" spacing={6} shadow="0px 4px 24px 0px rgba(0, 0, 0, 0.24)">
          <VStack
            bg="whiteAlpha.200"
            opacity="8"
            borderRadius={theme.borderRadius}
            p={6}
            align="stretch"
          >
            <div data-sl="mask">
              <HStack align="stretch" justifyContent="space-between">
                <VStack align="stretch">
                  <HStack spacing={3}>
                    <Icon type={plans[Plan_Type_Enum.Lifetime].icon} size={48} />
                    <Text fontSize="xLarge">{plans[Plan_Type_Enum.Lifetime].title}</Text>
                  </HStack>
                  <HStack align="end">
                    <Text fontSize="small" opacity="secondary" pb={1}>
                      {'You have unlimited access to BigDevSoon for life! 🚀'}
                    </Text>
                  </HStack>
                </VStack>
              </HStack>
            </div>
          </VStack>
        </VStack>
      </VStack>
    </>
  );
};
