import { AvatarGroup, HStack, SkeletonCircle } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

import { RecentUser } from 'types/edgeFunctions';
import { ERoute } from 'types/frontend';
import { pluralize } from 'utils/strings';

import Text from './Text';
import UserAvatar from './UserAvatar';

interface IRecentUsersProps {
  data: RecentUser[];
  count: number;
  isLoading?: boolean;
}

const RecentUsers = ({ data, count, isLoading }: IRecentUsersProps) => {
  const navigate = useNavigate();

  if (isLoading) {
    return (
      <HStack spacing={0}>
        <SkeletonCircle size="6" />
        <SkeletonCircle size="6" ml={-3} />
        <SkeletonCircle size="6" ml={-3} />
        <SkeletonCircle size="6" ml={-3} />
        <SkeletonCircle size="6" ml={-3} />
      </HStack>
    );
  }

  return (
    <HStack>
      <AvatarGroup size="md">
        {data.map((user) => (
          <UserAvatar
            key={user.user_id}
            name={user.name}
            src={user.avatar_url}
            size="sm"
            onClick={(e) => {
              e.stopPropagation();
              navigate(`${ERoute.Profile}/${user.screen_name}`);
            }}
            style={{ width: 24, height: 24 }}
          />
        ))}
      </AvatarGroup>
      <Text fontSize="xMicro" fontWeight="regular">
        {count > 0
          ? `${count}${count > 5 ? '+' : ''} ${pluralize('user', count)}  completed`
          : `Be the first one to complete! 🎉`}
      </Text>
    </HStack>
  );
};

export default RecentUsers;
