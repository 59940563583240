import { useGetProjectQuery } from 'hooks/queries/projects/useGetProjectQuery';
import { useEffect } from 'react';
import { Route, Routes, useParams } from 'react-router-dom';

import DevSandboxSkeleton from 'components/DevSandbox/DevSandboxSkeleton';
import { SEO } from 'components/SEO';

import ProjectPlayground from './playground/ProjectPlayground';
import Submit from './playground/Submit';
import ProjectSolution from './solution/ProjectSolution';

const preloadProjectImages = (imageUrls: string[]) => {
  const imagePromises = imageUrls.map((url) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = url;
      img.onload = resolve;
      img.onerror = reject;
    });
  });

  return Promise.all(imagePromises);
};

const ProjectHub = () => {
  const { projectScreenName = '' } = useParams();

  const { data, isPending } = useGetProjectQuery({
    projectScreenName,
  });

  useEffect(() => {
    if (data) {
      const cardScreenshotUrls = data.cards.map((card) => card.screenshot_url || '');

      preloadProjectImages([...cardScreenshotUrls, data.design_screenshot.url].filter(Boolean));
    }
  }, [data]);

  if (!data || isPending) {
    return <DevSandboxSkeleton loadingText="Loading project..." />;
  }

  return (
    <>
      <SEO
        title={`BigDevSoon | ${data.title} project`}
        description={data.description || ''}
        url={`${process.env.REACT_APP_SITE_URL}projects/${data.screen_name}`}
      />
      <Routes>
        {<Route path="submit" element={<Submit project={data!} />} />}
        <Route
          path="solutions/:userScreenName/:title/*"
          element={<ProjectSolution project={data!} />}
        />
        <Route path="*" element={<ProjectPlayground project={data!} />} />
      </Routes>
    </>
  );
};

export default ProjectHub;
