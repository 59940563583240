import { Skeleton, Stack, VStack } from '@chakra-ui/react';

import { useBilling } from 'features/billing/context/BillingProvider';
import { CanceledState } from 'features/billing/state/CanceledState';
import { DefaultState } from 'features/billing/state/DefaultState';
import { FreeState } from 'features/billing/state/FreeState';
import { LifetimeState } from 'features/billing/state/LifetimeState';
import { TerminateState } from 'features/billing/state/TerminateState';
import { Plan_Type_Enum } from 'types/databaseEnums';
import { SubscriptionStatus } from 'types/edgeFunctions';

import SettingsTile from './SettingsTile';

interface ISubscriptionDetailsBilling {
  brand: string;
  last4: string;
  amount: number;
  status: SubscriptionStatus;
  plan: Plan_Type_Enum;
  nextBillingTime: Date;
}

export const SubscriptionDetailsBilling = ({
  brand,
  last4,
  nextBillingTime,
  plan,
  status,
  amount,
}: ISubscriptionDetailsBilling) => {
  const { isWaitingForNewData } = useBilling();

  const getProperState = () => {
    if (isWaitingForNewData) {
      return (
        <Stack>
          <Skeleton height="60px" />
          <Skeleton height="60px" />
          <Skeleton height="40px" />
        </Stack>
      );
    } else if (plan === Plan_Type_Enum.Lifetime) {
      return <LifetimeState />;
    } else if (status === SubscriptionStatus.Terminate) {
      return <TerminateState />;
    } else if (status === SubscriptionStatus.Inactive) {
      return <FreeState />;
    } else if (status === SubscriptionStatus.Canceled) {
      return <CanceledState current_period_end={nextBillingTime} />;
    } else {
      return (
        <DefaultState
          interval={plan}
          brand={brand}
          last4={last4}
          amount={amount}
          currentPeriodEnd={nextBillingTime}
        />
      );
    }
  };

  return (
    <SettingsTile
      title="Subscription details"
      subtitle="Payment and subscription type information."
    >
      <VStack spacing={3} align="stretch">
        {getProperState()}
      </VStack>
    </SettingsTile>
  );
};
