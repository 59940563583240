import { SupabaseClient } from '@supabase/supabase-js';
import { useQueryWithErrorBoundary, useSupabaseTypedClient } from 'hooks/reactQuery';

import { useCurrentUser } from 'auth/CurrentUserProvider';
import { Plan_Type_Enum, User_Project_Status_Enum } from 'types/databaseEnums';
import {
  GetProjectSolutionResponse,
  GetSolutionParams,
  ProjectSolution,
  SummaryReport,
} from 'types/edgeFunctions';
import { TanstackQueryName } from 'types/frontend';
import { Database } from 'types/supabase';

interface IGetProjectSolutionQueryFnParams {
  supabase: SupabaseClient<Database>;
  currentUserId: string;
  solutionUrl: string;
}

const getProjectSolutionQueryFn = async ({
  supabase,
  currentUserId,
  solutionUrl,
}: IGetProjectSolutionQueryFnParams): Promise<ProjectSolution> => {
  // In the past we allowed more than 1 project and can't do maybeSingle() here.
  const solutionsResponse = await supabase
    .from('user_project')
    .select(
      'id, created_at, is_private, html, css, js, design_score, code_score, design_report, code_report, ai_feedback, title, completed_date, screenshot_url, solution_url, repository_url, preview_url, user_profile_view (id, avatar_url, name, screen_name, plan, experience), project (id, title, screen_name)'
    )
    .eq('solution_url', solutionUrl)
    .eq('status', User_Project_Status_Enum.Completed)
    .order('created_at', { ascending: false });

  if (solutionsResponse.error) throw solutionsResponse.error;

  const selectedSolution = solutionsResponse?.data[0];

  const [likes, isLikedByMe, comments] = await Promise.all([
    supabase.rpc('count_likes', { input_entity_id: selectedSolution.id }),
    currentUserId
      ? supabase
          .from('user_like')
          .select('voter_id')
          .eq('voter_id', currentUserId)
          .eq('entity_id', selectedSolution.id)
      : Promise.resolve(undefined),
    supabase
      .from('support_center')
      .select('*', { count: 'exact', head: true })
      .eq('entity_id', selectedSolution.id)
      .not('user_id', 'is', null),
  ]);

  if (likes.error) throw likes.error;
  if (isLikedByMe?.error) throw isLikedByMe.error;
  if (comments.error) throw comments.error;

  const solution = selectedSolution!;
  const project = solution.project!;

  const screenshotUrl = solution.screenshot_url;

  return {
    id: solution.id,
    project_id: project.id || '',
    title: project.title || '',
    is_private: solution.is_private || false,
    solution_title: solution.title || '',
    screen_name: project.screen_name || '',
    live_preview_url: solution.preview_url || '',
    screenshot_url: screenshotUrl || '',
    repository_url: solution.repository_url || '',
    solution_url: solution.solution_url || '',
    created_at: solution.created_at || '',
    completed_date: solution.completed_date || '',
    html: solution.html || '',
    css: solution.css || '',
    js: solution.js || '',
    is_my_solution: currentUserId === solution.user_profile_view!.id,
    user: {
      id: solution.user_profile_view!.id || '',
      avatar_url: solution.user_profile_view!.avatar_url || '',
      name: solution.user_profile_view!.name || '',
      screen_name: solution.user_profile_view!.screen_name || '',
      plan: solution.user_profile_view!.plan as Plan_Type_Enum,
      xp: solution.user_profile_view?.experience || 0,
    },
    likes: likes.data || 0,
    is_liked: (isLikedByMe?.data || []).length > 0,
    comments_count: comments.count || 0,
    design_report: (solution.design_report as SummaryReport[]) || null,
    code_report: (solution.code_report as SummaryReport[]) || null,
    design_score: solution.design_score || null,
    code_score: solution.code_score || null,
    ai_feedback: solution.ai_feedback || '',
  };
};

interface IUseGetProjectSolutionResponse {
  data: GetProjectSolutionResponse | undefined | null;
  isPending: boolean;
}

export const useGetProjectSolutionQuery = ({
  solution_url,
}: GetSolutionParams): IUseGetProjectSolutionResponse => {
  const { id: currentUserId } = useCurrentUser();
  const supabase = useSupabaseTypedClient();

  const { data, isPending } = useQueryWithErrorBoundary({
    queryKey: [TanstackQueryName.GetProjectSolution, solution_url],
    queryFn: async () => {
      const data = await getProjectSolutionQueryFn({
        supabase,
        currentUserId: currentUserId,
        solutionUrl: solution_url,
      });

      return data;
    },
  });

  if (!data) {
    return {
      data: null,
      isPending,
    };
  }

  return {
    data,
    isPending,
  };
};
