import { captureException } from '@sentry/react';
import { Session } from '@supabase/supabase-js';
import { useQueryClient } from '@tanstack/react-query';
import { useSupabaseTypedClient } from 'hooks/reactQuery';
import { useToastWrapper } from 'hooks/useToastWrapper';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import PageLoader from 'components/PageLoader';
import { GITHUB_COOKIE_KEY } from 'config/constants/github';
import { StorageVars } from 'config/constants/storage';
import { ERoute } from 'types/frontend';
import { setCookie } from 'utils/cookies';

type AuthContextData = {
  loading: boolean;
  signOut: () => void;
  session: Session | null;
  userId: string | null;
};

export const AuthContext = React.createContext({} as AuthContextData);

export const AuthProvider = ({ children }) => {
  const currentUserId = localStorage.getItem(StorageVars.BDS_USER_ID);

  const [signingOut, setSigningOut] = useState(false);
  const [session, setSession] = useState<Session | null>(null);
  const [userId, setUserId] = useState<string | null>(currentUserId);
  const [loading, setLoading] = useState(true);

  const supabase = useSupabaseTypedClient();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const { toastSuccess, toastError } = useToastWrapper();

  useEffect(() => {
    const checkIfUserIsOnboarded = async (session: Session | null) => {
      if (session) {
        const sessionUserId = session.user.id;

        if (!userId || userId !== sessionUserId) {
          const { data, error } = await supabase
            .from('user_profile_view')
            .select('id')
            .eq('id', session.user.id)
            .maybeSingle();

          if (error) throw error;

          if (data?.id) {
            localStorage.setItem(StorageVars.BDS_USER_ID, data.id);
            setUserId(data.id);
          }
        }

        setLoading(false);
      } else {
        setLoading(false);
      }
    };

    const checkSession = (session: Session | null) => {
      setSession(session);

      if (session?.provider_token) {
        setCookie(GITHUB_COOKIE_KEY, session?.provider_token);
      }

      checkIfUserIsOnboarded(session);
    };

    supabase.auth.getSession().then(({ data: { session } }) => {
      checkSession(session);
    });

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((event, session) => {
      if (event === 'SIGNED_OUT') {
        localStorage.removeItem(StorageVars.BDS_USER_ID);
        localStorage.removeItem(StorageVars.REACT_QUERY_CACHE);
        // Might be only locally but not sure still, worth to remove
        localStorage.removeItem(StorageVars.LOCAL_SUPABASE_AUTH_TOKEN);
        setSession(null);
        setUserId(null);

        return;
      }

      if (['INITIAL_SESSION', 'USER_UPDATED', 'SIGNED_IN', 'TOKEN_REFRESHED'].includes(event)) {
        checkSession(session);
      }
    });

    return () => subscription.unsubscribe();
  }, []);

  const signOut = async () => {
    try {
      setSigningOut(true);
      await queryClient.invalidateQueries();
      queryClient.removeQueries();
      queryClient.clear();

      await supabase.auth.signOut();
      localStorage.removeItem(StorageVars.BDS_USER_ID);
      localStorage.removeItem(StorageVars.REACT_QUERY_CACHE);
      // Might be only locally but not sure still, worth to remove
      localStorage.removeItem(StorageVars.LOCAL_SUPABASE_AUTH_TOKEN);
      setSession(null);
      setUserId(null);
    } catch (error) {
      captureException(error);

      toastError(`Can't sign out, try again`);
    } finally {
      navigate(ERoute.Projects);
      setSigningOut(false);
      toastSuccess('Signed out successfully');
    }
  };

  if (signingOut) {
    return <PageLoader />;
  }

  return (
    <AuthContext.Provider
      value={{
        loading,
        signOut,
        session,
        userId,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = (): AuthContextData => {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
};
