import { isAfter, parseISO, startOfDay } from 'date-fns';

export const normalizeExperience = (
  experience: number
): { normalizedExperience: number; level: number; requiredExperience: number } => {
  let level = 1;
  let requiredExperience = 100;

  while (experience >= requiredExperience) {
    level++;
    requiredExperience *= 2;
  }

  const min = level === 1 ? 0 : requiredExperience / 2;
  const max = requiredExperience;

  const normalizedExperience = ((experience - min) / (max - min)) * 100;

  return { normalizedExperience, level, requiredExperience };
};

export const hasFirstCardAccessOnly = (createdAt: string) => {
  const startDate = new Date(2024, 6, 2);

  const startOfJuly2 = startOfDay(startDate);
  const createdAtDate = parseISO(createdAt);

  return isAfter(createdAtDate, startOfJuly2) || createdAtDate.getTime() === startOfJuly2.getTime();
};
