import {
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  useTheme,
  HStack,
  Stack,
  IconButton,
  Skeleton,
  VStack,
} from '@chakra-ui/react';
import { useQueryWithErrorBoundary, useSupabaseTypedClient } from 'hooks/reactQuery';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { BigBadge } from 'components/BigBadge';
import Card from 'components/Card';
import GradientTooltip from 'components/GradientTooltip';
import Icon from 'components/Icon';
import Link from 'components/Link';
import Text from 'components/Text';
import UserAvatar from 'components/UserAvatar';
import { useMediaQueries } from 'layout/MediaQueriesProvider';
import { Plan_Type_Enum } from 'types/databaseEnums';
import { TimeFilter, TypeFilter, UserLeaderboard } from 'types/edgeFunctions';
import { ERoute, TanstackQueryName } from 'types/frontend';

const mapDataToLeaderboard = (data: {
  user_id: string;
  avatar_url: string;
  name: string;
  screen_name: string;
  plan: string;
  count: number;
  total_experience: number;
}): UserLeaderboard => ({
  id: data.user_id.toString(),
  avatar_url: data.avatar_url,
  name: data.name,
  screen_name: data.screen_name,
  plan: data.plan as Plan_Type_Enum,
  xp: data.total_experience,
  count: data.count,
});

type AllowedFunctionNames =
  | 'user_experience_daily'
  | 'user_experience_weekly'
  | 'user_experience_monthly'
  | 'user_experience_all_time'
  | 'user_like_daily'
  | 'user_like_weekly'
  | 'user_like_monthly'
  | 'user_like_all_time';

const typeAndTimeToRpc: Record<TypeFilter, Record<TimeFilter, AllowedFunctionNames>> = {
  [TypeFilter.XP]: {
    [TimeFilter.TODAY]: 'user_experience_daily',
    [TimeFilter.WEEKLY]: 'user_experience_weekly',
    [TimeFilter.MONTHLY]: 'user_experience_monthly',
    [TimeFilter.ALL_TIME]: 'user_experience_all_time',
  },
  [TypeFilter.LIKES]: {
    [TimeFilter.TODAY]: 'user_like_daily',
    [TimeFilter.WEEKLY]: 'user_like_weekly',
    [TimeFilter.MONTHLY]: 'user_like_monthly',
    [TimeFilter.ALL_TIME]: 'user_like_all_time',
  },
};

const Leaderboards = () => {
  const [timeFilter, setTimeFilter] = useState<TimeFilter>(TimeFilter.TODAY);
  const [typeFilter, setTypeFilter] = useState<TypeFilter>(TypeFilter.XP);
  const { borderRadius, opacity } = useTheme();

  const { isTabletHome } = useMediaQueries();
  const navigate = useNavigate();
  const supabase = useSupabaseTypedClient();

  const { data, isPending } = useQueryWithErrorBoundary({
    queryKey: [TanstackQueryName.GetLeaderboard, { time: timeFilter, type: typeFilter }],
    queryFn: async () => {
      const { data, error } = await supabase.rpc(typeAndTimeToRpc[typeFilter][timeFilter]);

      if (error) throw error;

      return data;
    },
  });

  const navigateToUserProfile = (e, to: string) => {
    e.stopPropagation();

    navigate(to);
  };

  const renderLeaderboardsUsers = (users: UserLeaderboard[]) => {
    return (
      <Stack spacing={4}>
        {users.map((userLeaderboard, index) => {
          const toUserProfile = `${ERoute.Profile}/${userLeaderboard.screen_name}`;

          return (
            <HStack
              key={userLeaderboard.id}
              justifyContent="space-between"
              spacing={isTabletHome ? 1 : 3}
            >
              <HStack spacing={1}>
                <Text color={index < 3 ? 'purple.200' : 'white'} minWidth="2rem">
                  #{index + 1}
                </Text>
                <HStack
                  onClick={(e) => navigateToUserProfile(e, toUserProfile)}
                  width="fit-content"
                  cursor="pointer"
                >
                  <UserAvatar
                    key={userLeaderboard.id}
                    name={userLeaderboard.name}
                    src={userLeaderboard.avatar_url}
                    onClick={(e) => navigateToUserProfile(e, toUserProfile)}
                    experience={userLeaderboard.xp}
                    size={isTabletHome ? 'sm' : 'md'}
                    hideCircularProgress
                  />
                  <Link to={toUserProfile} color="white">
                    <Text
                      fontSize="small"
                      maxW={
                        userLeaderboard.plan !== Plan_Type_Enum.Free
                          ? ['25vw', '25vw', '25vw', '25vw', '70px', '80px']
                          : ['35vw', '35vw', '35vw', '35vw', '110px', '120px']
                      }
                      isTruncated
                    >
                      {userLeaderboard.name}
                    </Text>
                  </Link>
                  <BigBadge plan={userLeaderboard.plan} showOnlySubscribed size="small" />
                </HStack>
              </HStack>
              <HStack justifyContent="flex-end">
                {typeFilter === TypeFilter.XP && <Icon type="ExperienceBubble" size={20} />}
                {typeFilter === TypeFilter.LIKES && <Icon type="LikeHeart" size={20} />}
                <Text fontSize="small" fontWeight="semibold">
                  {userLeaderboard.count}
                </Text>
              </HStack>
            </HStack>
          );
        })}
      </Stack>
    );
  };

  const renderSkeleton = () => {
    return (
      <Stack spacing={4}>
        {[...Array(10)].map((_, idx) => (
          <Skeleton key={idx} height="40px" />
        ))}
      </Stack>
    );
  };

  const getBorderProps = (flag: boolean) => {
    return flag ? { border: '1px solid', borderColor: 'purple.200' } : {};
  };

  const mappedData = (data || []).map(mapDataToLeaderboard);

  return (
    <Card
      width="100%"
      cardHeaderProps={{ px: 4 }}
      cardBodyProps={{ px: isTabletHome ? 2 : 4 }}
      header={
        <VStack alignItems="flex-start">
          <Text fontSize="xxLarge" opacity="default" fontWeight="semibold">
            Leaderboards
          </Text>
          <HStack spacing={4}>
            <GradientTooltip label="Filter by XP">
              <IconButton
                aria-label="Filter top 10 leaderboards users by experience"
                onClick={() => setTypeFilter(TypeFilter.XP)}
                {...getBorderProps(typeFilter === TypeFilter.XP)}
              >
                <Icon type="ExperienceBubble" size={24} />
              </IconButton>
            </GradientTooltip>
            <GradientTooltip label="Filter by likes">
              <IconButton
                aria-label="Filter top 10 leaderboards users by likes"
                onClick={() => setTypeFilter(TypeFilter.LIKES)}
                {...getBorderProps(typeFilter === TypeFilter.LIKES)}
              >
                <Icon type="LikeHeart" size={24} />
              </IconButton>
            </GradientTooltip>
          </HStack>
        </VStack>
      }
    >
      <Tabs variant="enclosed" width="100%" defaultIndex={0} isLazy>
        <TabList border="none" margin="0">
          <Tab
            _selected={{ background: 'whiteAlpha.200', opacity: 1 }}
            opacity={opacity.secondary}
            margin="0"
            onClick={() => setTimeFilter(TimeFilter.TODAY)}
            px={2}
          >
            <Text>Today</Text>
          </Tab>
          <Tab
            _selected={{ background: 'whiteAlpha.200', opacity: 1 }}
            opacity={opacity.secondary}
            margin="0"
            onClick={() => setTimeFilter(TimeFilter.WEEKLY)}
            px={2}
          >
            <Text>Week</Text>
          </Tab>
          <Tab
            _selected={{ background: 'whiteAlpha.200', opacity: 1 }}
            opacity={opacity.secondary}
            margin="0"
            onClick={() => setTimeFilter(TimeFilter.MONTHLY)}
            px={2}
          >
            <Text>Month</Text>
          </Tab>
          <Tab
            _selected={{ background: 'whiteAlpha.200', opacity: 1 }}
            opacity={opacity.secondary}
            margin="0"
            onClick={() => setTimeFilter(TimeFilter.ALL_TIME)}
          >
            <Text>All</Text>
          </Tab>
        </TabList>
        <TabPanels>
          <TabPanel
            background="whiteAlpha.200"
            borderTopRightRadius={borderRadius}
            borderBottomRadius={borderRadius}
          >
            {isPending ? renderSkeleton() : renderLeaderboardsUsers(mappedData)}
          </TabPanel>
          <TabPanel
            background="whiteAlpha.200"
            borderTopRightRadius={borderRadius}
            borderBottomRadius={borderRadius}
          >
            {isPending ? renderSkeleton() : renderLeaderboardsUsers(mappedData)}
          </TabPanel>
          <TabPanel
            background="whiteAlpha.200"
            borderTopRightRadius={borderRadius}
            borderBottomRadius={borderRadius}
          >
            {isPending ? renderSkeleton() : renderLeaderboardsUsers(mappedData)}
          </TabPanel>
          <TabPanel
            background="whiteAlpha.200"
            borderTopRightRadius={borderRadius}
            borderBottomRadius={borderRadius}
          >
            {isPending ? renderSkeleton() : renderLeaderboardsUsers(mappedData)}
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Card>
  );
};

export default Leaderboards;
