export enum Card_Type_Enum {
  Frontend = 'frontend',
  Backend = 'backend',
  Concept = 'concept',
  Task = 'task',
}

export enum Challenge_Type_Enum {
  DesignChallenge = 'design_challenge',
  PracticeProblem = 'practice_problem',
  BigProject = 'big_project',
}

export enum Experience_Level_Enum {
  Junior = 'junior',
  Regular = 'regular',
  Senior = 'senior',
}

export enum Image_Type_Enum {
  Design = 'design',
  ProjectThumbnail = 'project_thumbnail',
  ProjectDashboardThumbnail = 'project_dashboard_thumbnail',
  ProjectHeaderThumbnail = 'project_header_thumbnail',
  Fullscreen = 'fullscreen',
  ProjectHorizontalThumbnail = 'project_horizontal_thumbnail',
  ProjectSmallHorizontalThumbnail = 'project_small_horizontal_thumbnail',
  ProjectHdThumbnail = 'project_hd_thumbnail',
}

export enum Payment_Provider_Enum {
  Stripe = 'Stripe',
  PayPal = 'PayPal',
}

export enum Plan_Type_Enum {
  Free = 'Free',
  Monthly = 'Monthly',
  Yearly = 'Yearly',
  Lifetime = 'Lifetime',
}

export enum Project_State_Enum {
  Draft = 'draft',
  Published = 'published',
}

export enum Skill_Tag_Enum {
  Security = 'security',
  Visualization = 'visualization',
  Performance = 'performance',
  Testing = 'testing',
  Automation = 'automation',
  Debugging = 'debugging',
  Layout = 'layout',
  Integration = 'integration',
  Routing = 'routing',
  Website = 'website',
  Game = 'game',
  Application = 'application',
  Tool = 'tool',
  Library = 'library',
  Database = 'database',
  Setup = 'setup',
  Rwd = 'rwd',
  Forms = 'forms',
  NodeJs = 'node.js',
  Realtime = 'realtime',
  DataOptimization = 'data_optimization',
}

export enum Support_Type_Enum {
  Help = 'help',
  Review = 'review',
  Feedback = 'feedback',
  Question = 'question',
}

export enum User_Card_Status_Enum {
  NotStarted = 'not_started',
  InProgress = 'in_progress',
  Completed = 'completed',
}

export enum User_Challenge_Status_Enum {
  Started = 'started',
  Completed = 'completed',
}

export enum User_Project_Status_Enum {
  NotStarted = 'not_started',
  InProgress = 'in_progress',
  Completed = 'completed',
}

export enum Practice_Problem_Type_Enum {
  Array = 'Array',
  String = 'String',
  Object = 'Object',
  DataStructure = 'Data Structure',
  Sorting = 'Sorting',
  Math = 'Math',
  Counting = 'Counting',
  Recursion = 'Recursion',
}

export enum User_Practice_Problem_Status_Enum {
  NotStarted = 'not_started',
  InProgress = 'in_progress',
  Completed = 'completed',
}
