import {
  Box,
  Code,
  HStack,
  IconButton,
  ListItem,
  UnorderedList,
  VStack,
  useDisclosure,
} from '@chakra-ui/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { supabaseMutationFn, useSupabaseTypedClient } from 'hooks/reactQuery';
import { getCodeStorageKey, useCodeStorage } from 'hooks/useCodeStorage';
import { useExitAlert } from 'hooks/useExitAlert';
import { useToastWrapper } from 'hooks/useToastWrapper';
import { useRef } from 'react';
import ReactMarkdown from 'react-markdown';
import { useNavigate } from 'react-router-dom';

import { useCurrentUser } from 'auth/CurrentUserProvider';
import DevSandbox from 'components/DevSandbox';
import GenerateCodeSolutionModal from 'components/DevSandbox/GenerateCodeSolutionModal';
import ChatGPT from 'components/DevSandbox/PaneTabs/ChatGPT';
import DiscussionTab from 'components/DevSandbox/PaneTabs/Discussion/DiscussionTab';
import RightSideActions from 'components/DevSandbox/RightSideActions';
import GradientTooltip from 'components/GradientTooltip';
import Icon from 'components/Icon';
import Link from 'components/Link';
import ProjectElement from 'components/ProjectElement';
import { ProtectedElement } from 'components/ProtectedElement';
import Text from 'components/Text';
import {
  DifficultyMarker,
  StatusIconMapper,
  StatusTextMapper,
} from 'features/practice-problems/PracticeProblems';
import { Support_Type_Enum, User_Practice_Problem_Status_Enum } from 'types/databaseEnums';
import { AskChatGptParams, EdgeFunctionName, PracticeProblem } from 'types/edgeFunctions';
import { TanstackQueryName } from 'types/frontend';
import { mapExpToProblem } from 'utils/constants';

import ProblemsDrawer from './ProblemsDrawer';

interface IPracticeProblemPlaygroundProps {
  practiceProblem: PracticeProblem;
}

const PracticeProblemPlayground = ({
  practiceProblem: {
    id,
    title,
    description,
    test_cases,
    function_name,
    screen_name,
    difficulty,
    status,
    discussion_count,
  },
}: IPracticeProblemPlaygroundProps) => {
  const { id: userId } = useCurrentUser();

  const [generatedSolutionCode, setGeneratedSolutionCode] = useCodeStorage({
    key: getCodeStorageKey({
      type: 'problem',
      screenName: screen_name,
      userId,
      code: 'generated-solution-code',
    }),
  });

  const codeEditorRef = useRef<any>(null);

  const navigate = useNavigate();

  const queryClient = useQueryClient();

  const { toastSuccess, toastError } = useToastWrapper();

  const supabase = useSupabaseTypedClient();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const mutation = useMutation<string, string, AskChatGptParams>({
    mutationFn: supabaseMutationFn(({ entityId, type }) =>
      supabase.functions.invoke<string>(EdgeFunctionName.AskChatGpt, {
        body: {
          entityId,
          type,
        } as AskChatGptParams,
      })
    ),
    onSuccess: (data) => {
      setGeneratedSolutionCode(data);
      queryClient.invalidateQueries({ queryKey: [TanstackQueryName.GetCurrentUser, userId] });

      toastSuccess('Code solution generated successfully.');
    },
    onError: () => {
      toastError('Could not generate solution, try again later.');
    },
  });

  useExitAlert({
    shouldShowBrowserExitAlert: mutation.isPending,
  });

  return (
    <>
      <DevSandbox
        ref={codeEditorRef}
        id={`problem-${screen_name}-${userId}`}
        title={title}
        mode="script-practice"
        navigation={{
          leftSide: <ProblemsDrawer />,
          header: (
            <HStack spacing={4}>
              <GradientTooltip
                label={
                  mutation.isPending
                    ? 'Code is generating...'
                    : generatedSolutionCode
                    ? 'Open generated code'
                    : 'Generate code solution'
                }
              >
                <IconButton
                  aria-label="Generate code solution for this problem"
                  onClick={onOpen}
                  colorScheme={generatedSolutionCode ? 'green' : 'gray'}
                  backgroundColor={generatedSolutionCode ? 'green.400' : 'default'}
                  isLoading={mutation.isPending}
                >
                  <Icon type="GenerateCodeSolution" />
                </IconButton>
              </GradientTooltip>
              <ProtectedElement
                scheme="gradient"
                leftIcon={<Icon type="SubmitCode" />}
                onClick={(e) => {
                  const passedTestsCount = codeEditorRef.current?.runTests(e);

                  if (passedTestsCount !== test_cases.tests.length) {
                    toastError('Some of the tests are not passing, fix the code and try again.');

                    return;
                  }

                  navigate('submit');
                }}
              >
                Submit
              </ProtectedElement>
            </HStack>
          ),
          rightSide: <RightSideActions />,
        }}
        codeTabs={{
          js: `function ${function_name}(${test_cases.input_names.join(
            ', '
          )}) {\n // Write your code below\n}`,
          tabNames: ['JS'],
        }}
        paneTabs={{
          tabs: [
            {
              screenName: 'instructions',
              title: 'Instructions',
              icon: <Icon type="CodeInstructions" size={16} />,
              content: (
                <VStack alignItems="flex-start" p={4} spacing={4}>
                  <VStack alignItems="flex-start" width="100%">
                    <HStack justifyContent="space-between" alignItems="center" width="100%" pr={2}>
                      <HStack>
                        <Text fontSize="xxLarge" fontWeight="bold">
                          {title}
                        </Text>
                        <ProjectElement
                          type={
                            status === User_Practice_Problem_Status_Enum.Completed
                              ? 'experienceCompleted'
                              : 'experience'
                          }
                          experience={mapExpToProblem[difficulty]}
                        />
                      </HStack>
                      <HStack>
                        {StatusIconMapper[status]}
                        <Text fontSize="small">{StatusTextMapper[status]}</Text>
                      </HStack>
                    </HStack>
                    <DifficultyMarker difficulty={difficulty} />
                  </VStack>
                  <ReactMarkdown
                    components={{
                      h1: ({ node, ...props }) => <Text fontSize="xLarge" {...props} />,
                      h2: ({ node, ...props }) => <Text fontSize="xLarge" {...props} />,
                      h3: ({ node, ...props }) => <Text fontSize="large" {...props} />,
                      ul: ({ node, ...props }) => <UnorderedList spacing={2} pl={3} {...props} />,
                      li: ({ node, ...props }) => (
                        <ListItem lineHeight="26px" fontWeight="regular" {...props} />
                      ),
                      a: ({ node, ...props }) => <Link {...props} />,
                      p: ({ node, ...props }) => (
                        <Text lineHeight="26px" {...props} fontWeight="regular" />
                      ),
                      code: ({ node, ...props }) => <Code {...props} variant="subtle" />,
                      blockquote: ({ node, ...props }) => (
                        // @ts-ignore uncompatible types
                        <Box
                          borderLeft="2px solid"
                          borderColor="whiteAlpha.600"
                          pl={3}
                          py={1}
                          ml={2}
                          my={3}
                          fontStyle="italic"
                          {...props}
                        />
                      ),
                    }}
                  >
                    {description}
                  </ReactMarkdown>
                </VStack>
              ),
            },
            {
              screenName: 'ai-buddy',
              title: 'AI Buddy',
              icon: <Icon type="CodeChatGpt" size={16} />,
              content: (
                <ChatGPT
                  chatContext={`You are an expert in web development and competitive programming, skilled in solving coding challenges and algorithms in JavaScript. The user is working on a practice problem named ${title} using our built-in code editor. Your role is to assist them by:

                  - Providing guidance based on prompts they use or messages they send to you.
                  - Offering code snippets or partial solutions in the JavaScript language without providing the complete solution.
                  - Giving advice on best practices, algorithm optimization, and efficient coding techniques.
                  - Debugging and reviewing code when users share specific snippets.
                  - Encouraging the user to think through the problem and come up with their own solutions.
                  - Keeping in mind that you only have access to the code snippets they share with you.
                  - IMPORTANT: Do not provide a full solution to the problem, involve user into thinking, brainstorming instead.

                  Please respond with clear, actionable advice, partial code solutions, and insights. Do not provide complete solutions. Return code with markdown for syntax highlighting. Assume users have basic familiarity with programming concepts.`}
                />
              ),
            },
            {
              screenName: 'discussions',
              title: `${
                discussion_count > 0 ? `Discussions (${discussion_count})` : 'Discussions'
              }`,
              icon: <Icon type="Discussion" size={16} />,
              content: (
                <DiscussionTab
                  entityId={id}
                  type={Support_Type_Enum.Question}
                  refetch={() => {
                    queryClient.invalidateQueries({
                      queryKey: [TanstackQueryName.GetPracticeProblem, screen_name],
                    });
                  }}
                />
              ),
            },
          ],
        }}
        testCases={test_cases}
      />
      <GenerateCodeSolutionModal
        name="problem"
        language="javascript"
        code={generatedSolutionCode}
        isOpen={isOpen}
        onClose={onClose}
        isLoading={mutation.isPending}
        onGenerate={() => mutation.mutate({ entityId: id, type: 'generate-problem-solution' })}
      />
    </>
  );
};

export default PracticeProblemPlayground;
