import {
  Box,
  Divider,
  Flex,
  HStack,
  Input,
  Stack,
  Textarea,
  useDisclosure,
  useTheme,
  VStack,
} from '@chakra-ui/react';
import { useSupabaseTypedClient } from 'hooks/reactQuery';
import { useToastWrapper } from 'hooks/useToastWrapper';
import { useState } from 'react';

import { useAuth } from 'auth/AuthProvider';
import { useCurrentUser } from 'auth/CurrentUserProvider';
import Button from 'components/Button';
import Icon from 'components/Icon';
import Modal from 'components/Modal';
import Text from 'components/Text';
import { EdgeFunctionName } from 'types/edgeFunctions';

export const DeleteAccountSettings = () => {
  const [accountName, setAccountName] = useState('');
  const [deleteReason, setDeleteReason] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const supabase = useSupabaseTypedClient();
  const { screenName } = useCurrentUser();

  const theme = useTheme();
  const { toastSuccess, toastError } = useToastWrapper();

  const { signOut } = useAuth();

  const handleAccountDeletion = async () => {
    setIsLoading(true);
    try {
      const { error } = await supabase.functions.invoke(EdgeFunctionName.AccountDelete, {
        body: {
          reason: deleteReason ?? null,
        },
      });

      if (error) throw error;

      await signOut();

      toastSuccess('Account deleted successfully');
    } catch (error: any) {
      toastError(JSON.stringify(error));
    } finally {
      setIsLoading(false);
      onClose();
    }
  };

  const handleTextChange = (ev: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = ev.target.value;
    if (value.length <= 200) {
      setDeleteReason(ev.target.value);
    }
  };

  const chars = deleteReason?.length ?? 0;

  return (
    <>
      <Box borderRadius={theme.borderRadius} w="100%" shadow="0px 4px 24px 0px rgba(0, 0, 0, 0.24)">
        <Flex
          backgroundColor="red.500"
          alignItems="center"
          h={12}
          borderRadius={`${theme.borderRadius} ${theme.borderRadius} 0 0`}
        >
          <Text fontSize="large" ml={4}>
            Danger zone
          </Text>
        </Flex>
        <VStack bg="red.200" p={4} align="flex-start" spacing={4}>
          <Text fontSize="large" color="gray.900" opacity="primary" fontWeight="bold">
            Delete BigDevSoon Account
          </Text>
          <Text fontSize="small" color="gray.900" opacity="primary" fontWeight="regular">
            You’ll get a chance to confirm your choice.
          </Text>

          <Divider bg="gray.900" opacity="0.16" />
          <Button scheme="danger" leftIcon={<Icon type="Danger" size={16} />} onClick={onOpen}>
            Delete account
          </Button>
        </VStack>
      </Box>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        onSubmit={handleAccountDeletion}
        header="Are you sure you want to delete your account?"
        footer={
          <Button
            scheme="danger"
            leftIcon={<Icon type="Danger" size={16} />}
            isDisabled={screenName !== accountName}
            isLoading={isLoading}
            type="submit"
          >
            Delete account
          </Button>
        }
      >
        <Stack spacing={3} mt={2}>
          <Text fontWeight="regular">
            All of your data will be immediately deleted including any active subscription,
            solutions to projects, cards, and projects as well as all of the progress you made so
            far.
          </Text>
          <Text fontWeight="semibold">GitHub repositories created by BigDevSoon will remain.</Text>
          <Text as="ins" fontWeight="regular" pb={2}>
            There is no going back, please be certain.
          </Text>
          <Text>
            Reason for deleting{' '}
            <Text fontSize="small" as="span" fontWeight="regular" opacity="secondary">
              (optional)
            </Text>
          </Text>
          <VStack w="100%">
            <Textarea
              placeholder="Add reason for deleting your account"
              fontSize="small"
              opacity={theme.opacity.secondary}
              value={deleteReason}
              onChange={handleTextChange}
            />
            <Text alignSelf="end" fontSize="small" fontWeight="regular" opacity="secondary">
              {chars}/200
            </Text>
          </VStack>
          <HStack>
            <Text>
              Please type{' '}
              <Text as="span" fontWeight="semibold">
                {screenName}
              </Text>{' '}
              <Text as="span">to confirm.</Text>
            </Text>
          </HStack>
          <Input onChange={(ev) => setAccountName(ev.target.value)} autoFocus />
        </Stack>
      </Modal>
    </>
  );
};
