import { VStack, useTheme, HStack, Box, Image, Tooltip, Skeleton } from '@chakra-ui/react';
import { InfiniteData, useQueryClient } from '@tanstack/react-query';
import { format } from 'date-fns';
import { useInfiniteQueryWithErrorBoundary, useSupabaseTypedClient } from 'hooks/reactQuery';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useCurrentUser } from 'auth/CurrentUserProvider';
import { BigBadge } from 'components/BigBadge';
import Button from 'components/Button';
import Card from 'components/Card';
import Icon from 'components/Icon';
import Link from 'components/Link';
import Text from 'components/Text';
import UserAvatar from 'components/UserAvatar';
import { DATE_FORMAT } from 'config/constants/plans';
import { useMediaQueries } from 'layout/MediaQueriesProvider';
import { Plan_Type_Enum } from 'types/databaseEnums';
import { FeedMessage, FeedMessageType } from 'types/edgeFunctions';
import { ERoute, TanstackQueryName } from 'types/frontend';

const mapFeedMessageTypeToIcon: Record<FeedMessageType, JSX.Element> = {
  [FeedMessageType.PROJECT_DISCUSSION]: <Icon type="CommunityQuestionFeed" />,
  [FeedMessageType.CHALLENGE_DISCUSSION]: <Icon type="CommunityQuestionFeed" />,
  [FeedMessageType.PRACTICE_PROBLEM_DISCUSSION]: <Icon type="CommunityQuestionFeed" />,
  [FeedMessageType.USER_PROJECT_COMPLETED]: <Icon type="SolutionFeed" />,
  [FeedMessageType.PROJECT_CODE_COMMENT]: <Icon type="CommunityQuestionFeed" />,
  [FeedMessageType.USER_CHALLENGE_COMPLETED]: <Icon type="SolutionFeed" />,
  [FeedMessageType.CHALLENGE_CODE_COMMENT]: <Icon type="CommunityQuestionFeed" />,
  [FeedMessageType.CODE_FRAME_COMMENT]: <Icon type="CommunityQuestionFeed" />,
  [FeedMessageType.PROTOTYPE_COMMENT]: <Icon type="CommunityQuestionFeed" />,
  [FeedMessageType.USER_PRACTICE_PROBLEM_COMPLETED]: <Icon type="SolutionFeed" />,
  [FeedMessageType.PRACTICE_PROBLEM_CODE_COMMENT]: <Icon type="CommunityQuestionFeed" />,
  [FeedMessageType.USER_SUBSCRIPTION]: <Icon type="SubscriptionFeed" />,
  [FeedMessageType.USER_TOKEN_TRANSACTION]: <Icon type="Token" />,
};

interface IMessageCardProps {
  message: string;
}

const MessageCard = ({ message }: IMessageCardProps) => {
  const { borderRadius } = useTheme();

  return (
    <Box
      mt={4}
      p={4}
      borderRadius={borderRadius}
      border="1px solid"
      borderColor="purple.200"
      background="linear-gradient(90deg, rgba(214, 188, 250, 0.24) 0%, rgba(251, 211, 141, 0.24) 100%)"
      width="100%"
    >
      {message}
    </Box>
  );
};

interface IFeedCardProps extends FeedMessage {}

const FeedCard = ({
  created_at,
  source_type,
  avatar_url,
  name,
  experience,
  plan,
  screen_name,
  community_question,
  solution_url,
  solution_screenshot_url,
  challenge_solution_screenshot_url,
  challenge_screenshot_url,
  challenge_screen_name,
  challenge_solution_url,
  challenge_title,
  project_screen_name,
  project_title,
  project_screenshot_url,
  practice_problem_screen_name,
  practice_problem_solution_url,
  practice_problem_title,
  tokens_value,
  code_frame_solution_url,
  code_frame_title,
  prototype_solution_url,
  prototype_title,
}: IFeedCardProps) => {
  const { isDesktopNavigation, isMobile } = useMediaQueries();
  const { opacity, borderRadius } = useTheme();
  const navigate = useNavigate();
  const toUserProfile = `${ERoute.Profile}/${screen_name}`;

  const navigateToUserProfile = (e) => {
    e.stopPropagation();

    navigate(toUserProfile);
  };

  const renderFeedText = () => {
    switch (source_type) {
      case FeedMessageType.PROJECT_CODE_COMMENT:
        return (
          <Text fontWeight="regular">
            Commented in the{' '}
            <Link to={`${solution_url as string}/comments`} fontWeight="semibold">
              {project_title}
            </Link>{' '}
            solution.
          </Text>
        );
      case FeedMessageType.CHALLENGE_CODE_COMMENT:
        return (
          <Text fontWeight="regular">
            Commented in the{' '}
            <Link to={`${challenge_solution_url as string}/comments`} fontWeight="semibold">
              {challenge_title}
            </Link>{' '}
            solution.
          </Text>
        );
      case FeedMessageType.PRACTICE_PROBLEM_CODE_COMMENT:
        return (
          <Text fontWeight="regular">
            Commented in the{' '}
            <Link to={`${practice_problem_solution_url as string}/comments`} fontWeight="semibold">
              {practice_problem_title}
            </Link>{' '}
            solution.
          </Text>
        );
      case FeedMessageType.CODE_FRAME_COMMENT: {
        return (
          <Text fontWeight="regular">
            Commented in the{' '}
            <Link to={`${code_frame_solution_url as string}/comments`} fontWeight="semibold">
              {code_frame_title}
            </Link>{' '}
            code frame.
          </Text>
        );
      }
      case FeedMessageType.PROTOTYPE_COMMENT: {
        return (
          <Text fontWeight="regular">
            Commented in the{' '}
            <Link to={`${prototype_solution_url as string}/comments`} fontWeight="semibold">
              {prototype_title}
            </Link>{' '}
            prototype.
          </Text>
        );
      }
      case FeedMessageType.PROJECT_DISCUSSION:
        return (
          <Text fontWeight="regular">
            Chimed in on the discussion in the{' '}
            <Link
              to={`${ERoute.Projects}/${project_screen_name}/discussions`}
              fontWeight="semibold"
            >
              {project_title}
            </Link>{' '}
            project.
          </Text>
        );
      case FeedMessageType.CHALLENGE_DISCUSSION:
        return (
          <Text fontWeight="regular">
            Chimed in on the discussion in the{' '}
            <Link
              to={`${ERoute.Challenges}/${challenge_screen_name}/discussions`}
              fontWeight="semibold"
            >
              {challenge_title}
            </Link>{' '}
            challenge.
          </Text>
        );
      case FeedMessageType.PRACTICE_PROBLEM_DISCUSSION:
        return (
          <Text fontWeight="regular">
            Chimed in on the discussion in the{' '}
            <Link
              to={`${ERoute.PracticeProblems}/${practice_problem_screen_name}/discussions`}
              fontWeight="semibold"
            >
              {practice_problem_title}
            </Link>{' '}
            problem.
          </Text>
        );
      case FeedMessageType.USER_PROJECT_COMPLETED: {
        return (
          <Text fontWeight="regular">
            Submitted{' '}
            <Link to={solution_url || ''} fontWeight="semibold">
              <Tooltip
                p={0}
                borderRadius={borderRadius}
                border="2px solid"
                borderColor="purple.200"
                label={
                  <Image
                    width="200px"
                    height="auto"
                    htmlWidth={200}
                    htmlHeight={150}
                    src={solution_screenshot_url as string}
                    alt={`Solution Card - ${project_title}`}
                    borderRadius={borderRadius}
                  />
                }
              >
                solution
              </Tooltip>
            </Link>{' '}
            to the{' '}
            <Link to={`${ERoute.Projects}/${project_screen_name}`} fontWeight="semibold">
              <Tooltip
                p={0}
                borderRadius={borderRadius}
                border="2px solid"
                borderColor="purple.200"
                label={
                  <Image
                    width="200px"
                    height="auto"
                    htmlWidth={200}
                    htmlHeight={150}
                    src={project_screenshot_url as string}
                    alt={`Project Card - ${project_title}`}
                    borderRadius={borderRadius}
                  />
                }
              >
                {project_title}
              </Tooltip>
            </Link>{' '}
            project.
          </Text>
        );
      }
      case FeedMessageType.USER_CHALLENGE_COMPLETED: {
        return (
          <Text fontWeight="regular">
            Submitted{' '}
            <Link to={challenge_solution_url || ''} fontWeight="semibold">
              <Tooltip
                p={0}
                borderRadius={borderRadius}
                border="2px solid"
                borderColor="purple.200"
                label={
                  <Image
                    width="200px"
                    height="auto"
                    htmlWidth={200}
                    htmlHeight={150}
                    src={challenge_solution_screenshot_url as string}
                    alt={`Solution Card - ${challenge_title}`}
                    borderRadius={borderRadius}
                  />
                }
              >
                solution
              </Tooltip>
            </Link>{' '}
            to the{' '}
            <Link to={`${ERoute.Challenges}/${challenge_screen_name}`} fontWeight="semibold">
              <Tooltip
                p={0}
                borderRadius={borderRadius}
                border="2px solid"
                borderColor="purple.200"
                label={
                  <Image
                    width="200px"
                    height="auto"
                    htmlWidth={200}
                    htmlHeight={150}
                    src={challenge_screenshot_url as string}
                    alt={`Project Card - ${challenge_title}`}
                    borderRadius={borderRadius}
                  />
                }
              >
                {challenge_title}
              </Tooltip>
            </Link>{' '}
            challenge.
          </Text>
        );
      }
      case FeedMessageType.USER_PRACTICE_PROBLEM_COMPLETED: {
        return (
          <Text fontWeight="regular">
            Submitted{' '}
            <Link to={practice_problem_solution_url as string} fontWeight="semibold">
              solution
            </Link>{' '}
            to the{' '}
            <Link
              to={`${ERoute.PracticeProblems}/${practice_problem_screen_name}`}
              fontWeight="semibold"
            >
              {practice_problem_title}
            </Link>{' '}
            problem.
          </Text>
        );
      }
      case FeedMessageType.USER_SUBSCRIPTION:
        return (
          <Box>
            <Text fontWeight="regular" display="inline">
              Became a
            </Text>{' '}
            <BigBadge
              size="small"
              display="inline-block"
              plan={plan as Plan_Type_Enum}
              showOnlySubscribed
            />{' '}
            <Text fontWeight="regular" display="inline">
              subscriber. {!isMobile && '🚀'}
            </Text>
          </Box>
        );
      case FeedMessageType.USER_TOKEN_TRANSACTION:
        return (
          <Box>
            <Text fontWeight="regular" display="inline">
              Got richer with{' '}
              <Text fontWeight="semibold" display="inline">
                {tokens_value}
              </Text>{' '}
              <Icon type="Bigtokens" size={24} display="inline-block" verticalAlign="middle" />{' '}
              <Text fontWeight="bold" display="inline">
                BigTokens.
              </Text>
            </Text>
          </Box>
        );
    }
  };

  return (
    <Card
      width="100%"
      cardHeaderProps={{ p: 0 }}
      cardBodyProps={{ p: 4 }}
      background="whiteAlpha.100"
      position="relative"
    >
      <HStack spacing={4}>
        <UserAvatar
          name={name as string}
          src={avatar_url as string}
          size="big"
          onClick={navigateToUserProfile}
          experience={experience as number}
          hideCircularProgress
        />
        <VStack alignItems="flex-start" pr={isDesktopNavigation ? '20px' : 0}>
          <HStack>
            <Link to={toUserProfile} color="white">
              <Text
                maxW={
                  isDesktopNavigation
                    ? 'fit-content'
                    : plan !== Plan_Type_Enum.Free
                    ? '150px'
                    : '200px'
                }
                isTruncated
                _hover={{ textDecoration: 'underline' }}
              >
                {name}
              </Text>
            </Link>
            <BigBadge plan={plan as Plan_Type_Enum} showOnlySubscribed size="small" />
          </HStack>
          {renderFeedText()}
        </VStack>
      </HStack>
      {!isMobile && (
        <Text
          fontSize="micro"
          fontWeight="regular"
          whiteSpace="nowrap"
          position="absolute"
          right={4}
          top={4}
        >
          {format(new Date(created_at as string), `${DATE_FORMAT} HH:mm`)}
        </Text>
      )}
      <Box position="absolute" top={4} right={4} zIndex={-999} opacity={opacity.secondary}>
        {mapFeedMessageTypeToIcon[source_type as string]}
      </Box>
      {community_question && <MessageCard message={community_question || ''} />}
    </Card>
  );
};

const Feed = () => {
  const supabase = useSupabaseTypedClient();
  const queryClient = useQueryClient();
  const { id: currentUserId } = useCurrentUser();

  const { data, fetchNextPage, hasNextPage, isFetching } = useInfiniteQueryWithErrorBoundary({
    queryKey: [TanstackQueryName.GetUserFeed, currentUserId],
    queryFn: async ({ pageParam }) => {
      const userFeedQuery = supabase
        .from('user_feed_data_view')
        .select('*')
        .order('created_at', { ascending: false })
        .not('user_id', 'is', null)
        .limit(10);

      if (pageParam) {
        userFeedQuery.lt('created_at', pageParam);
      }

      const { data, error } = await userFeedQuery;

      if (error) throw error;

      return data;
    },
    placeholderData: {
      pageParams: [],
      pages: [],
    },
    initialPageParam: null,
    getNextPageParam: (lastPage) => {
      if (lastPage && Array.isArray(lastPage) && lastPage?.length > 0) {
        const lastItemCreatedAt = lastPage[lastPage.length - 1]?.created_at;

        return lastItemCreatedAt;
      }

      return undefined;
    },
  });

  useEffect(() => {
    return () => {
      queryClient.removeQueries({
        queryKey: [TanstackQueryName.GetUserFeed, currentUserId],
      });
    };
  }, []);

  const renderFeeds = () => {
    const communityFeedData = (data as unknown as InfiniteData<FeedMessage[]>) || [];
    const communityFeed =
      communityFeedData.pages.reduce((acc, page) => acc.concat(page), [] as FeedMessage[]) || [];

    const communityDataFeedsLength = communityFeed.length || 0;
    const shouldLoadFeeds = isFetching && communityDataFeedsLength === 0;

    if (shouldLoadFeeds) {
      return [...Array(4)].map((_, idx) => <Skeleton key={idx} width="100%" height="92px" />);
    }

    return communityFeed.map((feed) => (
      <FeedCard key={`${feed.source_type}-${feed.created_at}-${feed.user_id}`} {...feed} />
    ));
  };

  const isLoadingMore = isFetching;
  const hasMoreData =
    hasNextPage &&
    data &&
    // @ts-ignore
    data.pages?.reduce((acc, page) => acc.concat(page), []).length % 10 === 0;

  return (
    <VStack spacing={5} width="100%">
      <Card
        width="100%"
        header={
          <Text fontSize="xxLarge" opacity="default" fontWeight="semibold">
            Community Feed
          </Text>
        }
      >
        <VStack spacing={5}>{renderFeeds()}</VStack>
      </Card>
      {hasMoreData && (
        <Button isLoading={isLoadingMore} onClick={() => fetchNextPage()} mb={1}>
          Load more feeds
        </Button>
      )}
    </VStack>
  );
};

export default Feed;
