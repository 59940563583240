import { VStack, useTheme } from '@chakra-ui/react';
import { ReactNode } from 'react';

import Text from './Text';

interface IProjectSummaryTile {
  text: string;
  children: ReactNode;
}

const ProjectSummaryTile = ({ text, children }: IProjectSummaryTile) => {
  const { borderRadius } = useTheme();
  return (
    <VStack justify="center" bg="whiteAlpha.200" borderRadius={borderRadius} p={4}>
      {children}
      <Text fontSize="small" fontWeight="regular" opacity="secondary">
        {text}
      </Text>
    </VStack>
  );
};

export default ProjectSummaryTile;
