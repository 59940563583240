import { Center, Box } from '@chakra-ui/react';
import { ReactNode } from 'react';

import Logo from 'components/Logo';

import { useMediaQueries } from './MediaQueriesProvider';

interface OnboardingLayoutProps {
  children: ReactNode;
  isOnboarding?: boolean;
}

export const OnboardingLayout = ({ children, isOnboarding }: OnboardingLayoutProps) => {
  const { isOnboardingHorizontal, isDesktopProjects } = useMediaQueries();

  return (
    <Center height="100vh" mx={isDesktopProjects ? 2 : 0}>
      <Box position="absolute" top={0} pt={!isOnboardingHorizontal && isOnboarding ? 3 : 6}>
        <Logo />
      </Box>
      {children}
    </Center>
  );
};
